export const genderMap = {
  MALE: 0,
  FEMALE: 1,
};

export const genderReversMap = {
  0: 'MALE',
  1: 'FEMALE',
};

export const genderToNum = (genderStr) => genderMap[`${genderStr || 'MALE'}`.toUpperCase()];
