import React from 'react';
import styled from 'styled-components';
import { PALETTE } from 'styles/constants';

export const WidgetStyled = styled.div`
  min-width: 182px;
  background-color: ${PALETTE.light};
  border-radius: 5px;
`;

const Widget = ({ children, className }) => (
  <WidgetStyled className={`layout--x layout--y2 ${className}`}>{children}</WidgetStyled>
);

export default Widget;
