import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useRouteMatch, useHistory } from 'react-router-dom';

import { loadFilters } from 'store/filters';
import { setPrevPath } from 'store/applications';
import * as localStorage from 'utils/local-storage';
import {
  LastState,
  DealerMap,
  applicationsRolesMap,
  IN_WORK,
  LIST,
  SEARCH,
  LastStateVisible,
} from 'constants/index';

import * as S from './style';
import { Lostreport } from '../LostReport';

export const Navigation = () => {
  const rootDispatcher = useDispatch();
  const history = useHistory();
  const location = useLocation();
  let { url } = useRouteMatch();

  const isAuth = useSelector((store) => store.user.isAuth);
  const userRole = useSelector((store) => store.user.role);
  const prevPath = useSelector((store) => store.applications.prevPath);
  const isEmptySearch = useSelector((store) => !store.applicFilters.search);

  const [showByRole, setShowByRole] = useState({ lost: false, inWork: false });

  useEffect(() => {
    if (!isAuth || !userRole) {
      return;
    }

    const path = location.pathname.replace(`${url}/`, '');
    const pathRoles = applicationsRolesMap[path];

    if (pathRoles) {
      const rolePass = pathRoles.includes(userRole);
      if (!rolePass) {
        history.push('/applications/list');
      }

      if (prevPath && prevPath !== path) {
        localStorage.removeItem('lastState');
      }

      rootDispatcher(setPrevPath(path));
      rootDispatcher(
        loadFilters({
          states: {
            checked: LastState[path],
            disabled: ![LIST, IN_WORK, SEARCH].includes(path),
            visibleList: LastStateVisible[path],
            url: path,
          },
          dealers: { checked: DealerMap[path] },
        })
      );
    }

    setShowByRole({
      lost: applicationsRolesMap.lost.includes(userRole),
      inWork: applicationsRolesMap['in-work'].includes(userRole),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRole]);

  return (
    <S.Container>
      {showByRole.inWork && <S.Link to={`${url}/in-work`}>Заявки в работе</S.Link>}
      <S.Link to={`${url}/list`}>Список заявок</S.Link>
      {showByRole.lost && (
        <>
          <S.Link to={`${url}/lost`}>
            {'Потерянные заявки'} <Lostreport />
          </S.Link>
        </>
      )}
      {!isEmptySearch && <S.Link to={`${url}/search`}>Результаты поиска</S.Link>}
    </S.Container>
  );
};
