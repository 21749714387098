import { css } from 'styled-components';

export const scroll = (opacity = 0) => css`
  &::-webkit-scrollbar {
    width: 13px;
    height: 13px;
    background: rgba(255, 255, 255, 0.2);
    cursor: pointer;
  }
  &::-webkit-scrollbar-corner {
    background: 0 0;
  }
  &::-webkit-scrollbar-thumb {
    min-height: 20px;
    border: 3px solid transparent;
    background-clip: content-box;
    border-radius: 5px;
    box-shadow: inset 0 0 0 4px rgba(0, 0, 0, ${0.1 + opacity});

    &:hover {
      box-shadow: inset 0 0 0 4px rgba(0, 0, 0, ${0.3 + opacity});
      cursor: pointer;
    }
  }
`;

export const alignY = () => css`
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
`;

export const alignX = () => css`
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
`;

export const align = () => css`
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`;
