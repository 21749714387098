import PropTypes from 'prop-types';

export const carInstance = PropTypes.shape({
  id: PropTypes.number.isRequired,
  brand: PropTypes.string.isRequired,
  model: PropTypes.string.isRequired,
  year: PropTypes.number.isRequired,
  vin: PropTypes.string.isRequired,
  enginePower: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.oneOf([null])])
    .isRequired,
  carIssueDate: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  version: PropTypes.string.isRequired,
  isNew: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]).isRequired,
  price: PropTypes.number.isRequired,
  pts: PropTypes.shape({
    series: PropTypes.string,
    number: PropTypes.string.isRequired,
    issueDate: PropTypes.string.isRequired,
  }).isRequired,
  diagnosticCard: PropTypes.shape({
    beginDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    number: PropTypes.string.isRequired,
  }).isRequired,
});
