import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PALETTE } from 'styles/constants';

export const InputWrapper = styled.div`
  display: flex;
  padding-right: 20px;
  position: relative;
  border: 1px solid ${PALETTE.grey2};
  border-radius: 5px;
  margin: 4px 10px 4px 12px;
`;

export const SearchIcon = styled(FontAwesomeIcon)`
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  color: ${PALETTE.grey2};
`;

export const InnerInput = styled.input`
  width: calc(100% - 10px);
  margin: 5px;
  padding: 0;
  border: none;
  user-select: none;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  box-sizing: border-box;
  background-color: transparent;
  z-index: 1;

  &:focus {
    outline: 0;
    box-shadow: none;
  }
`;
