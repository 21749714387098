import React from 'react';
import PropTypes from 'prop-types';
import { Text } from './style';

export const H2 = (props) => {
  const { children, className } = props;

  return <Text className={className}>{children}</Text>;
};

H2.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
};

H2.defaultProps = {
  children: PropTypes.any,
  className: '',
};
