export const ASSISTANCE_FILE_TYPES = {
  PASSPORT: 'passport',
  DRIVE_LICENCE: 'drive-license',
  PTS: 'pts',
  DIAGNOSTIC_CARD: 'diagnostic-card',
  CERTIFICATE: 'certificate',
  WORK_ORDER: 'work-order',
  VEHICLE_CHECKLIST: 'vehicle-checklist',
  DKP: 'dkp',
};

export const ASSISTANCE_WORKFLOW_STATES = {
  unknown: 'unknown',
  new: 'new',
  waiting_signing_code: 'waiting_signing_code',
  code_confirmed: 'code_confirmed',
  offers_requested: 'offers_requested',
  offers_received: 'offers_received',
  offer_selected: 'offer_selected',
  offers_selected: 'offers_selected',
  offers_declined: 'offers_declined',
  offers_error: 'offers_error',
  agreement_requested: 'agreement_requested',
  agreement_succeed: 'agreement_succeed',
  agreement_declined: 'agreement_declined',
  agreement_error: 'agreement_error',
  issue_requested: 'issue_requested',
  issued: 'issued',
  issue_decline: 'issue_decline',
  issue_error: 'issue_error',
  cancelled: 'cancelled',
  client_decline: 'client_decline',
  declined: 'declined',
  error: 'error',
  cancelation_requested: 'cancelation_requested',
  canceled: 'canceled',
  cancelation_error: 'cancelation_error',
};

export const ASSISTANCE_WORKFLOW_STATES_OFFERS = [
  ASSISTANCE_WORKFLOW_STATES.offers_requested,
  ASSISTANCE_WORKFLOW_STATES.offers_received,
  ASSISTANCE_WORKFLOW_STATES.offer_selected,
  ASSISTANCE_WORKFLOW_STATES.offers_declined,
  ASSISTANCE_WORKFLOW_STATES.offers_error,
  ASSISTANCE_WORKFLOW_STATES.declined,
  ASSISTANCE_WORKFLOW_STATES.error,
];

export const ASSISTANCE_WORKFLOW_STATES_AGREEMENT = [
  ASSISTANCE_WORKFLOW_STATES.agreement_requested,
  ASSISTANCE_WORKFLOW_STATES.agreement_succeed,
  ASSISTANCE_WORKFLOW_STATES.waiting_signing_code,
  ASSISTANCE_WORKFLOW_STATES.code_confirmed,
  ASSISTANCE_WORKFLOW_STATES.agreement_declined,
  ASSISTANCE_WORKFLOW_STATES.agreement_error,
];

export const ASSISTANCE_WORKFLOW_STATES_ISSUE = [
  ASSISTANCE_WORKFLOW_STATES.issue_requested,
  ASSISTANCE_WORKFLOW_STATES.issued,
  ASSISTANCE_WORKFLOW_STATES.issue_decline,
  ASSISTANCE_WORKFLOW_STATES.issue_error,
  ASSISTANCE_WORKFLOW_STATES.cancelation_error,
  ASSISTANCE_WORKFLOW_STATES.canceled,
];

export const ASSISTANCE_WORKFLOW_STATES_NEW = [
  ASSISTANCE_WORKFLOW_STATES.unknown,
  ASSISTANCE_WORKFLOW_STATES.new,
];

export const ASSISTANCE_ENABLED_CARINSTANCE_STATES = new Set([
  ASSISTANCE_WORKFLOW_STATES.new,
  ASSISTANCE_WORKFLOW_STATES.offers_requested,
  ASSISTANCE_WORKFLOW_STATES.offers_received,
  ASSISTANCE_WORKFLOW_STATES.offer_selected,
]);

export const COST_PRODUCT_NAME = 'PARC. Постгарантийная поддержка';
export const COMPANY_CODE_INGOSSTRAH = 'ingosstrah';
export const COMPANY_CODE_INGOS = 'ingos';
export const COST_PRODUCTS_CODES = [COMPANY_CODE_INGOSSTRAH, COMPANY_CODE_INGOS];
