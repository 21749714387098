import * as validationRules from '../vlidator/validations';

export const onBlurDriverBlockHelper = async ({ value, state, driver }) => {
  const keys = Object.keys(value);

  if (!keys.length) {
    return {};
  }

  const key = keys[0];

  try {
    if (validationRules[key]) {
      await validationRules.schemaDriver.validateAt(key, state);
    }

    const values = { id: driver?.id, key, value: state[key] };
    const errors = { id: `driver@${driver.id}`, key };

    return { values, errors };
  } catch (err) {
    if (!err.errors) {
      return {};
    }

    const values = { id: driver.id, key, value: state[key] };
    const errors = { id: `driver@${driver.id}`, key, value: err.errors[0] };

    return { values, errors };
  }
};

export const onBlurBlockHelper = async ({ value, state }) => {
  const keys = Object.keys(value);

  if (!keys.length) {
    return {};
  }

  const key = keys[0];

  try {
    if (validationRules[key]) {
      await validationRules.schemaInsurance.validateAt(key, state);
    }

    const values = { key, value: state[key] };
    const errors = { key };

    return { values, errors };
  } catch (err) {
    if (!err.errors) {
      return {};
    }

    const values = { key, value: state[key] };
    const errors = { key, value: err.errors[0] };

    return { values, errors };
  }
};
