import React, { useEffect, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CarDetail from './CarDetail';
import {
  updateErrors,
  patchCarInstance,
  dropError,
  dropErrors,
  setCarInstance,
  clearScroll,
  clearError as carInstanceClearError,
  changeCarInstance,
  setImage,
  declineDropErrors,
  openCarInstanceDetails,
  closeCarInstanceDetails,
} from 'store/carInstance';
import { clearError as carReferenceClearError, setAllFetch } from 'store/carReference';
import { schemaPatchCarDetail } from './validator/validations';

const CarDetailWrapper = ({
  id,
  onChangeCarModel = () => {},
  onBankChange = () => {},
  onClickIsInCredit = () => {},
  isInCredit,
  creditBanksList,
  creditBankId,
  onClickFullCalc = null,
  isFullCalc = true,
  showRestrictions = false,
  disabledPatch = false,
  ignoredFields = [],
  dropErrorsByMount = true,
  ...rest
}) => {
  const rootDispatch = useDispatch();
  const { path } = useRouteMatch();
  const isCreateNew = useMemo(() => path.includes('/worksheet/create-new'), [path]);

  const { carInstance, carReference } = useSelector((state) => state);
  const hiddenFields = carInstance?.hiddenFields || [];
  const disableFields = carInstance?.disableFields || [];

  useEffect(() => {
    if (dropErrorsByMount) {
      if (carInstance.declineDropErrors) {
        rootDispatch(declineDropErrors(false));
      } else {
        rootDispatch(dropErrors());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const patchHandler = (instance, loadImage = false, phases, phPayload) => {
    if (disabledPatch) {
      rootDispatch(changeCarInstance(instance, id, loadImage, phases, phPayload));
    } else {
      if (schemaPatchCarDetail.isValidSync(instance, { abortEarly: true })) {
        const isCustomer = path.includes('/worksheet/') || isCreateNew;
        rootDispatch(patchCarInstance(instance, id, loadImage, phases, phPayload, isCustomer));
      } else {
        rootDispatch(changeCarInstance(instance, id, loadImage, phases, phPayload));
      }
    }
  };

  const setImageHandler = (model) => {
    rootDispatch(setImage(model));
  };

  const setCarInstanceHandler = (instance) => {
    rootDispatch(setCarInstance(instance));
  };

  const scrollHandler = () => {
    rootDispatch(clearScroll());
  };

  const setFetchAllCarReference = () => {
    rootDispatch(setAllFetch(true));
  };

  const updateErrorsHandler = (key, value = '', catalog = '') => {
    rootDispatch(
      updateErrors(
        catalog
          ? { ...carInstance.errors, [catalog]: { ...carInstance.errors[catalog], [key]: value } }
          : { ...carInstance.errors, [key]: value }
      )
    );
  };

  const changeHandler = (name, catalog = '') => {
    rootDispatch(dropError(name, catalog));
  };

  const clearError = () => {
    rootDispatch(carInstanceClearError());
    rootDispatch(carReferenceClearError());
  };

  const toggleDetailsHandler = (state) => {
    const stateParam = state !== undefined ? state : !carInstance.isOpenDetails;
    rootDispatch(stateParam ? openCarInstanceDetails() : closeCarInstanceDetails());
  };

  return (
    <CarDetail
      id={id}
      hiddenFields={hiddenFields}
      disableFields={disableFields}
      ignoredFields={ignoredFields}
      disabledPatch={disabledPatch || isCreateNew}
      storedCarInstance={carInstance}
      storedCarReference={carReference}
      requiredFields={carInstance?.requiredFields}
      isInCredit={isInCredit}
      creditBanksList={creditBanksList}
      creditBankId={creditBankId}
      clearError={clearError}
      setImage={setImageHandler}
      onChange={changeHandler}
      onPatch={patchHandler}
      onSetCarInstance={setCarInstanceHandler}
      onScroll={scrollHandler}
      updateErrors={updateErrorsHandler}
      setFetchAllCarReference={setFetchAllCarReference}
      onChangeCarModel={onChangeCarModel}
      onClickFullCalc={onClickFullCalc}
      onClickIsInCredit={onClickIsInCredit}
      onBankChange={onBankChange}
      isFullCalc={isFullCalc}
      showRestrictions={showRestrictions}
      isWhiteLoader={!path.includes('/create-new')}
      toggleDetails={toggleDetailsHandler}
      {...rest}
    />
  );
};

export default CarDetailWrapper;
