export const getNewLicenseSeriaNum = (newValue, oldValue) => {
  if (!newValue) {
    return '';
  }

  const firstPartRegexp = /^\d{0,2}$/;
  const secondPartRegexp = /^((\d{0,2})|([А-Яа-яёЁ]{0,2}))$/;
  const thirdPartRegexp = /^\d{0,6}$/;

  const pureValue = newValue.replace(/\s/g, '');

  const firstPart = pureValue.slice(0, 2);
  const secondPart = pureValue.slice(2, 4);
  const thirdPart = pureValue.slice(4);

  if (
    firstPartRegexp.test(firstPart) &&
    secondPartRegexp.test(secondPart) &&
    thirdPartRegexp.test(thirdPart)
  ) {
    return newValue.toUpperCase();
  }

  return oldValue.toUpperCase();
};
