import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { assistanceChangeByKey } from 'store/assistanceAnketa';
import { insAnketaChangeByKey } from 'store/insuranceAnketa';
import Insurer from './Insurer';
import { onBlurBlockHelper } from '../../helpers/blockHelpers';

const fn = () => {};

const InsurerWrapper = ({ anketaState, onScroll = fn, validate = fn }) => {
  const rootDispatch = useDispatch();

  const { scrollTo } = anketaState;
  const { currentMultidrive } = useSelector((store) => store.assistanceCalculation);

  const blurHandler = async ({ value, state }) => {
    const key = Object.keys(value)[0];

    if (key === 'gender') {
      return rootDispatch(assistanceChangeByKey({ key: 'gender', value: value.gender }));
    }

    const { values, errors } = await onBlurBlockHelper({ value, state });

    if (!values && !errors) {
      return;
    }

    rootDispatch(assistanceChangeByKey(values));
    if (['fio', 'bday', 'insurerPhone'].includes(values.key)) {
      rootDispatch(insAnketaChangeByKey(values));
    }

    validate({ value, state });
  };

  return (
    <Insurer
      anketaState={anketaState}
      multidrive={currentMultidrive}
      scrollTo={scrollTo}
      onScroll={onScroll}
      onBlur={blurHandler}
    />
  );
};

export default InsurerWrapper;
