import React from 'react';
import PropTypes from 'prop-types';
import { Text } from './style';

export const TextStyle3 = (props) => {
  const { children, className, text } = props;

  return <Text className={className}>{children || text}</Text>;
};

TextStyle3.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
};

TextStyle3.defaultProps = {
  children: PropTypes.any,
  className: '',
};
