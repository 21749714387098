import * as actions from './constants';
import { initialState } from './initialState';
import { checkModifiedParams, createReadyMadeRisks, createSelectedBankObject } from './helper';
import { EOW_NAMEPLATES, EOW_STATES } from './resources';

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.INS_SET_ID: {
      const { applicationId, clientId, id, workSheetId } = action.payload;

      return { ...state, applicationId, clientId, workSheetId, insuranceId: id };
    }

    case actions.EOW_CALC_SET_APPLICATION: {
      const { applicationId, applicationData } = action.payload;
      const { bankId, isInCredit } = applicationData.applicationParams;
      const rawRisksList = applicationData.risks;

      const { appModifiedAt, needUpdateLastModifiedAt } = checkModifiedParams(
        applicationData.modifiedAt,
        state.lastModifiedAt
      );

      if (rawRisksList?.length) {
        const { risks, offersWithRisks } = createReadyMadeRisks(
          rawRisksList,
          applicationData.offers
        );
        const selectedOffer = applicationData.selectedOffer;
        risks.risksHaveChanged = false;
        applicationData.risksData = risks;
        applicationData.offers = offersWithRisks;

        if (selectedOffer) {
          const selectedOfferInOffersList = applicationData.offers.find(
            (offer) => offer.id === selectedOffer.id
          );
          selectedOffer.risks = selectedOfferInOffersList.risks;
        }
      }

      let nameplate = needUpdateLastModifiedAt ? applicationData.state : state.nameplate;
      if (!nameplate) {
        nameplate =
          applicationData.currentWorkflowState === EOW_STATES.offer_selected
            ? EOW_NAMEPLATES.offer_selected
            : EOW_NAMEPLATES.unknown;
      }

      return {
        ...state,
        applications: {
          ...state.applications,
          [applicationId]: applicationData,
        },
        nameplate,
        lastModifiedAt: needUpdateLastModifiedAt ? appModifiedAt : state.lastModifiedAt,
        isInCredit,
        selectedBank: state.selectedBank ?? createSelectedBankObject(state.insuranceBanks, bankId),
      };
    }

    case actions.EOW_CALC_UPD_APPLICATION: {
      const { applicationId, newData } = action.payload;

      return {
        ...state,
        nameplate:
          newData?.state ??
          // для отображения статуса "Консультация" на agreement_requested.
          // желательно добавить к неймлэйтам статус на этот случай
          // и передавать его в экшен
          (newData.currentWorkflowState === EOW_STATES.agreement_requested
            ? state.nameplate
            : EOW_NAMEPLATES.unknown),
        applications: {
          ...state.applications,
          [applicationId]: {
            ...state.applications[applicationId],
            ...newData,
          },
        },
      };
    }

    case actions.EOW_CALC_SET_SELECTED_OFFER: {
      const { applicationId, offerId, selectedOfferData } = action.payload;
      const currentOffers = state.applications[applicationId].offers.filter(
        (el) => el.id !== offerId
      );

      return {
        ...state,
        applications: {
          ...state.applications,
          [applicationId]: {
            ...state.applications[applicationId],
            selectedOffer: selectedOfferData,
            offers: [{ ...selectedOfferData }, ...currentOffers],
          },
        },
      };
    }

    case actions.EOW_CALC_UPD_APPL_PARAMS: {
      const { applicationId, newData } = action.payload;

      return {
        ...state,
        applications: {
          ...state.applications,
          [applicationId]: {
            ...state.applications[applicationId],
            applicationParams: {
              ...state.applications[applicationId].applicationParams,
              ...newData,
            },
          },
        },
      };
    }

    case actions.EOW_CALC_SET_FETCH: {
      const { applicationId, fetchName, isFetching } = action.payload;

      return {
        ...state,
        fetchStates: {
          ...state.fetchStates,
          [applicationId]: {
            ...(state.fetchStates[applicationId] ?? {}),
            [fetchName]: isFetching,
          },
        },
      };
    }

    case actions.EOW_CALC_SET_MAIN_FETCH: {
      const { fetchName, isFetching } = action.payload;

      return {
        ...state,
        eowMainFetchStates: {
          ...state.eowMainFetchStates,
          [fetchName]: isFetching,
        },
      };
    }

    case actions.EOW_CALC_SET_NAMEPLATE: {
      return {
        ...state,
        nameplate: { ...action.payload },
      };
    }

    case actions.EOW_CALC_SET_DEALER_LEGALS: {
      const { dealerId, legalsList = [] } = action.payload;

      return {
        ...state,
        dealerLegals: {
          currentDealerId: dealerId,
          legalsList,
        },
      };
    }

    case actions.EOW_CALC_SET_POPUP_DATA: {
      const { applicationId, popupData } = action.payload;

      if (popupData === null) {
        const applicationsPopupData = { ...state.applicationsPopupData };
        delete applicationsPopupData[applicationId];
        return {
          ...state,
          applicationsPopupData,
        };
      }

      return {
        ...state,
        applicationsPopupData: {
          ...state.applicationsPopupData,
          [applicationId]: { ...popupData, applicationId },
        },
      };
    }

    case actions.EOW_UPDATE_RISKS: {
      const { applicationId, selectedRisks, insuranceCompanyCode, productId } = action.payload;

      const targetApplication = state.applications[applicationId];
      const offersCopy = [...(targetApplication?.offers ?? [])];
      const targetOffer = offersCopy.find(
        (offer) =>
          offer.insuranceCompanyCode === insuranceCompanyCode && offer.productId === productId
      );

      if (!targetOffer?.risks || !insuranceCompanyCode || !productId) {
        return state;
      }

      const selectedRisksIds = selectedRisks.map((el) => el.id);

      const oldRisks = {};
      selectedRisksIds.forEach((el) => {
        const [id, selectedParam] = el.split('|');
        oldRisks[id] = selectedParam ?? null;
      });

      const updatedRisks = targetOffer.risks.reduce((acc, el) => {
        acc.push({
          ...el,
          isIncluded: el.amount
            ? Number(oldRisks[el.id.split('|')[0]]) === el.amount
            : selectedRisksIds.includes(el.id),
        });
        return acc;
      }, []);

      targetOffer.risks = updatedRisks;

      const selectedOffer = targetApplication.selectedOffer;
      const selectedOfferCopy = selectedOffer ? { ...selectedOffer } : null;

      if (selectedOfferCopy && selectedOfferCopy.id === targetOffer.id) {
        selectedOfferCopy.risks = updatedRisks;
      }

      const updatedRisksList = offersCopy
        .filter((offer) => offer.risks)
        .reduce((acc, offer) => {
          const includedRisks = offer.risks.filter(
            (risk) => risk.isIncluded || risk.type === 'included_risk'
          );
          return [...acc, ...includedRisks];
        }, [])
        .map(({ id: fullIdString }) => {
          const [id, selectedParam = null] = fullIdString.split('|');
          return { id: Number(id), selectedParam };
        });

      const receivedRisksIds = new Set(targetApplication.risksData.receivedRisksIds);
      const risksHaveChanged =
        updatedRisksList.length !== receivedRisksIds.size ||
        updatedRisksList.some(({ id }) => !receivedRisksIds.has(id));

      return {
        ...state,
        applications: {
          ...state.applications,
          [applicationId]: {
            ...targetApplication,
            risksData: {
              ...targetApplication.risksData,
              risksList: updatedRisksList,
              risksHaveChanged,
            },
            offers: offersCopy,
            selectedOffer: selectedOfferCopy,
          },
        },
      };
    }

    case actions.INS_SET_ERROR: {
      const { key, value } = action.payload;
      return { ...state, errors: { ...state.errors, [key]: value } };
    }

    case actions.INS_DROP_ALL: {
      return { ...initialState };
    }

    case actions.INS_DROP_ALL_ERRORS: {
      return { ...state, errors: {} };
    }

    case actions.INS_SET_IS_IN_CREDIT: {
      return { ...state, isInCredit: action.payload };
    }

    case actions.INS_SET_BANKS_LIST: {
      return {
        ...state,
        insuranceBanks: action.payload?.map((el) => ({ id: el.id, value: el.bankName })) ?? [],
      };
    }

    case actions.INS_SET_SELECTED_BANK: {
      return { ...state, selectedBank: action.payload };
    }

    default:
      return state;
  }
}

export function eowCalcSetApplication({ applicationId, applicationData }) {
  return {
    type: actions.EOW_CALC_SET_APPLICATION,
    payload: { applicationId, applicationData },
  };
}

export function eowCalcUpdApplication({ applicationId, newData }) {
  return {
    type: actions.EOW_CALC_UPD_APPLICATION,
    payload: { applicationId, newData },
  };
}

export function eowCalcUpdApplParams(payload) {
  return {
    type: actions.EOW_CALC_UPD_APPL_PARAMS,
    payload: payload,
  };
}

export function eowCalcSetSelectedOffer(payload) {
  return {
    type: actions.EOW_CALC_SET_SELECTED_OFFER,
    payload,
  };
}

export function eowCalcUpdRisks(payload) {
  return {
    type: actions.EOW_UPDATE_RISKS,
    payload,
  };
}

export function insuranceCalcDropErrors() {
  return {
    type: actions.INS_DROP_ALL_ERRORS,
  };
}

export function eowSelectOffer(selectedOfferData, successCallback) {
  return {
    type: actions.EOW_CALC_SELECT_OFFER_REQUEST,
    payload: { selectedOfferData, successCallback },
  };
}

export function eowAgreement(agreementRequestData) {
  return {
    type: actions.EOW_CALC_AGREEMENT_REQUEST,
    payload: { agreementRequestData },
  };
}

export function eowGetPolicy(getPolicyRequestData) {
  return {
    type: actions.EOW_CALC_GET_POLICY_REQUEST,
    payload: { getPolicyRequestData },
  };
}

export function eowClientDecline(applicationId, callback) {
  return {
    type: actions.EOW_CALC_CLIENT_DECLINE_REQUEST,
    payload: { applicationId, callback },
  };
}

export function eowCancellation({
  applicationId,
  successCallback,
  errorCallback,
  finallyCallback,
}) {
  return {
    type: actions.EOW_CALC_CANCELLATION_REQUEST,
    payload: { applicationId, successCallback, errorCallback, finallyCallback },
  };
}

export function eowReloadDocuments(applicationId, finallyCallback) {
  return {
    type: actions.EOW_CALC_RELOAD_DOCS_REQUEST,
    payload: { applicationId, finallyCallback },
  };
}

export function eowRefreshCalculate(dataForCalculate) {
  return {
    type: actions.EOW_CALC_REFRESH_CALC_REQUEST,
    payload: { dataForCalculate },
  };
}

export function eowRefreshCalculationsByAppsIds(applicationsIdsList, callback = () => {}) {
  return {
    type: actions.EOW_CALC_REFRESH_CALCS_BY_APPS_IDS_REQUESTS,
    payload: { applicationsIdsList, callback },
  };
}

export function createNewInsuranceOrder(params) {
  return {
    type: actions.EOW_CALC_ADD_EOW_ORDER_REQUEST,
    payload: { ...params },
  };
}

export function addEOWFile({ applicationId, fileId, documentType, ext }) {
  return { type: actions.EOW_CALC_ADD_FILE, payload: { applicationId, fileId, documentType, ext } };
}

export function delEOWFile({ applicationId, id }) {
  return { type: actions.EOW_CALC_DEL_FILE, payload: { applicationId, id } };
}

export function eowCalcSetFetch(applicationId, fetchName, isFetching) {
  return { type: actions.EOW_CALC_SET_FETCH, payload: { applicationId, fetchName, isFetching } };
}

export function eowCalcSetMainFetch(fetchName, isFetching) {
  return { type: actions.EOW_CALC_SET_MAIN_FETCH, payload: { fetchName, isFetching } };
}

export function eowCalcSetNameplate(nameplate) {
  return { type: actions.EOW_CALC_SET_NAMEPLATE, payload: nameplate };
}

export function eowCalcSetDealerLegals(dealerId, legalsList) {
  // action for reducer
  return { type: actions.EOW_CALC_SET_DEALER_LEGALS, payload: { dealerId, legalsList } };
}

export function eowCalcUpdateDealerLegals(dealerId) {
  // action for sagas
  return { type: actions.EOW_CALC_UPD_DEALER_LEGALS, payload: { dealerId } };
}

export function eowCalcSetPopupData(applicationId, popupData) {
  return { type: actions.EOW_CALC_SET_POPUP_DATA, payload: { applicationId, popupData } };
}

export function insuranceGetPrelist({ applicationId }) {
  return {
    type: actions.INS_REQUEST_PRELIST,
    payload: { applicationId },
  };
}

export function insuranceAllToInitState() {
  return {
    type: actions.INS_DROP_ALL,
  };
}

export function insuranceDeleteId() {
  return {
    type: actions.INS_SET_ID,
    payload: { applicationId: null },
  };
}

export function insuranceSetIsInCredit(payload) {
  return {
    type: actions.INS_SET_IS_IN_CREDIT,
    payload,
  };
}

export function insuranceGetBanksList(payload) {
  return {
    type: actions.INS_GET_BANKS_LIST,
    payload,
  };
}

export function insuranceSetBanksList(payload) {
  return {
    type: actions.INS_SET_BANKS_LIST,
    payload,
  };
}

export function insuranceSetSelectedBank(payload) {
  return {
    type: actions.INS_SET_SELECTED_BANK,
    payload,
  };
}

export function spGetApplicationId(params) {
  return {
    type: actions.INS_REQUEST_BYORDER,
    payload: { ...params },
  };
}

export function eowRecalcRecustomers(params) {
  return {
    type: actions.EOW_CALC_RECALC_RECUSTOMERS,
    payload: { ...params },
  };
}
