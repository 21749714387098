import IMask from 'imask';
import moment from 'moment';

export const InputMask = {
  CAR_NUMBER: [
    {
      mask: 'A NNN BB RRR',
      lazy: false,
      blocks: {
        A: {
          mask: 'a',
          placeholderChar: 'a',
        },
        NNN: {
          mask: '000',
          placeholderChar: '0',
        },
        BB: {
          mask: 'aa',
          placeholderChar: 'a',
        },
        RRR: {
          mask: '000',
          placeholderChar: '0',
        },
      },
    },
  ],
  CAR_NUMBER_NEW: [
    {
      mask: 'A NNN BB RRD',
      lazy: false,
      blocks: {
        A: {
          mask: '$',
          placeholderChar: '_',
          definitions: {
            $: /^[а-яА-ЯёЁ]+$/,
          },
        },
        N: {
          mask: '$',
          placeholderChar: '_',
          definitions: {
            $: /^[0123456789]+$/,
          },
        },
        B: {
          mask: '$',
          placeholderChar: '_',
          definitions: {
            $: /^[а-яА-ЯёЁ]+$/,
          },
        },
        R: {
          mask: '$',
          placeholderChar: '_',
          definitions: {
            $: /^[0123456789]+$/,
          },
        },
        D: {
          mask: '$',
          placeholderChar: ' ',
          definitions: {
            $: /^[0123456789]+$/,
          },
        },
      },
    },
  ],
  CAR_NUMBER_RUS: [
    {
      mask: 'A SSS AA RR[D]',
      lazy: false,
      blocks: {
        A: {
          mask: '$',
          placeholderChar: 'a',
          definitions: {
            $: /^[а-яА-Я]+$/,
          },
        },
        S: {
          mask: '$',
          placeholderChar: '0',
          definitions: {
            $: /^[0123456789]+$/,
          },
        },
        R: {
          mask: '$',
          placeholderChar: '0',
          definitions: {
            $: /^[0123456789]+$/,
          },
        },
        D: {
          mask: '[$]',
          definitions: {
            $: /^[0123456789]+$/,
          },
        },
      },
    },
  ],
  CAR_MASS: [
    {
      mask: Number,
      scale: 0,
      min: 900,
      max: 3500,
    },
  ],
  ENGINE_VOLUME: [
    {
      mask: Number,
      scale: 0,
      min: 500,
      max: 20000,
    },
  ],
  ENGINE_POWER: [
    {
      lazy: true,
      mask: 'FNN',
      blocks: {
        F: {
          mask: '$',
          definitions: {
            $: /^[123456789]+$/,
          },
        },
        N: {
          mask: '$',
          definitions: {
            $: /^[0123456789]+$/,
          },
        },
      },
    },
  ],
  KILOMETERS: [
    {
      lazy: false,
      mask: 'KM',
      blocks: {
        KM: {
          mask: (v) => {
            const _c = /^\d+$/.test(v);
            return _c && v.length <= 6;
          },
        },
      },
    },
  ],
  PRICE: [
    {
      mask: 'RUB',
      blocks: {
        RUB: {
          mask: Number,
          scale: 0,
          signed: false,
          thousandsSeparator: ' ',
          min: 0,
          max: 99999999,
        },
      },
    },
  ],
  PRICE_WITH_PENNY: [
    {
      mask: 'RUB',
      blocks: {
        RUB: {
          mask: Number,
          scale: 2,
          signed: false,
          radix: '.',
          mapToRadix: [',', '.'],
          thousandsSeparator: ' ',
        },
      },
    },
  ],
  STS: [
    {
      mask: '$$$$$$$$$$',
      definitions: { $: /^\w+$/ },
    },
  ],
  STS_SERIES: [
    {
      mask: 'XX YY',
      blocks: {
        XX: {
          mask: '$$',
          definitions: {
            $: /^[0123456789]+$/,
          },
        },
        YY: {
          mask: '$$',
          definitions: {
            $: /^[0123456789А-Я]+$/,
          },
        },
      },
    },
  ],
  STS_NUMBER: [
    {
      mask: '$$$$$$',
      lazy: false,
      definitions: {
        $: /^[0123456789]+$/,
      },
    },
  ],
  STS_SERIES_NUMBER: [
    {
      mask: 'XXYY SSSSSS',
      lazy: false,
      blocks: {
        XX: {
          mask: '$$',
          definitions: {
            $: /^[0123456789]+$/,
          },
        },
        YY: {
          mask: '$$',
          definitions: {
            $: /^[0123456789А-Я]+$/,
          },
        },
        SSSSSS: {
          mask: '$$$$$$',
          definitions: {
            $: /^[0123456789]+$/,
          },
        },
      },
    },
  ],
  PTS: [
    {
      mask: 'NNLLNNNNNN',
      lazy: true,
      blocks: {
        N: {
          mask: '$',
          definitions: {
            $: /^[0123456789]+$/,
          },
        },
        L: {
          mask: '$',
          definitions: {
            $: /^[0123456789а-яА-Я]+$/,
          },
        },
      },
    },
    {
      mask: 'NNNNNNNNNNNNNNN',
      lazy: true,
      blocks: {
        N: {
          mask: '$',
          definitions: {
            $: /^[0123456789]+$/,
          },
        },
      },
    },
  ],
  VIN: [
    {
      mask: '$$$$$$$$$$$$$$$$$$$$$$$$$',
      lazy: true,
      definitions: {
        $: /^[0123456789a-zA-Z]+$/,
      },
    },
  ],
  VIN_BODY: [
    {
      mask: '$$$$$$$$$$$$$$$$$$$$$$$$$',
      lazy: true,
      definitions: {
        $: /^[0123456789a-zA-Z]+$/,
      },
    },
  ],
  VIN_BODY_RIGHT: [
    {
      mask: '$$$$$$$$$$$$$$$$',
      lazy: true,
      definitions: {
        $: /^[0123456789a-zA-Z-]+$/,
      },
    },
  ],
  VIN_CYRILLIC: [
    {
      mask: '$$$$$$$$$$$$$$$$$',
      lazy: true,
      definitions: {
        $: /^[0123456789abcdefghjklmnprstuvwxyzABCDEFGHJKLMNPRSTUVWXYZавекмнорстухАВЕКМНОРСТУХ]+$/,
      },
    },
  ],
  VIN_NEW: [
    {
      mask: '$$$$$$$$$$$$$$$$$',
      lazy: true,
      definitions: {
        $: /^[0123456789abcdefghjklmnprstuvwxyzABCDEFGHJKLMNPRSTUVWXYZ]+$/,
      },
    },
  ],
  GLONAS: [
    {
      mask: '$$$$$$$$$$$$$$$$$$$',
      lazy: false,
      definitions: {
        $: /^[0123456789abcdefghjklmnprstuvwxyzABCDEFGHJKLMNPRSTUVWXYZ]+$/, // there is a whole alphabet without I, O, Q
      },
    },
  ],
  MILEAGE: [
    {
      lazy: true,
      mask: Number,
      scale: 0,
      min: 0,
      max: 1000000,
    },
  ],
  DIAGNOSTIC_NUMBER: [
    {
      mask: '$$$$$$$$$$$$$$$$$$$$$',
      lazy: true,
      definitions: {
        $: /^[0123456789]+$/,
      },
    },
  ],
  INSURANCE_SERIES_NUMBER: [
    {
      mask: 'XXX SSSSSSSSSS',
      lazy: false,
      blocks: {
        XXX: {
          mask: '$$$',
          definitions: {
            $: /^[а-яА-Я]+$/,
          },
        },
        SSSSSSSSSS: {
          mask: '$$$$$$$$$$',
          definitions: {
            $: /^[0123456789]+$/,
          },
        },
      },
    },
  ],
  CAR_YEAR: [
    {
      mask: Date,
      pattern: 'YYYY',
      lazy: true,
      min: new Date(1950, 0, 1),
      max: new Date(moment().format('YYYY'), 0, 1),

      format: function (str) {
        return moment(str).format('YYYY');
      },
      parse: function (str) {
        return moment(str, 'YYYY');
      },

      blocks: {
        YYYY: {
          mask: IMask.MaskedRange,
          from: 1950,
          to: moment().format('YYYY'),
        },
      },
    },
  ],
  DATE: [
    {
      mask: Date,
      pattern: 'DD.MM.YYYY',
      min: new Date(1950, 0, 1),
      max: new Date(2060, 0, 1),
      lazy: false,
      format: function (date) {
        return moment(date).format('DD.MM.YYYY');
      },
      parse: function (str) {
        return moment(str, 'DD.MM.YYYY');
      },

      blocks: {
        YYYY: {
          mask: IMask.MaskedRange,
          from: 1950,
          to: 2030,
        },
        MM: {
          mask: IMask.MaskedRange,
          from: 1,
          to: 12,
        },
        DD: {
          mask: IMask.MaskedRange,
          from: 1,
          to: 31,
        },
      },
    },
  ],
  DATE_AND_TIME: [
    {
      mask: Date,
      pattern: 'DD.MM.YYYY HH:mm',
      min: new Date(1950, 0, 1),
      max: new Date(2060, 0, 1),
      lazy: false,
      format: function (date) {
        return moment(date).format('DD.MM.YYYY HH:mm');
      },
      parse: function (str) {
        return moment(str, 'DD.MM.YYYY HH:mm');
      },

      blocks: {
        YYYY: {
          mask: IMask.MaskedRange,
          from: 1950,
          to: 2030,
        },
        MM: {
          mask: IMask.MaskedRange,
          from: 1,
          to: 12,
        },
        DD: {
          mask: IMask.MaskedRange,
          from: 1,
          to: 31,
        },
        HH: {
          mask: IMask.MaskedRange,
          from: 0,
          to: 23,
        },
        mm: {
          mask: IMask.MaskedRange,
          from: 0,
          to: 59,
        },
      },
    },
  ],
  DATE_BEFORE_TODAY_NEW: [
    {
      mask: Date,
      pattern: 'DD.MM.YYYY',
      min: new Date(
        ...moment()
          .subtract(86, 'years')
          .add(1, 'days')
          .format('YYYY-MM-DD')
          .split('-')
          .map((value, index) => {
            const number = +value;
            return index === 1 ? number - 1 : number;
          })
      ),
      max: new Date(),
      lazy: false,
      format: function (date) {
        return moment(date).format('DD.MM.YYYY');
      },
      parse: function (str) {
        return moment(str, 'DD.MM.YYYY');
      },
      blocks: {
        YYYY: {
          mask: IMask.MaskedRange,
          from: 1000,
          to: 9999,
        },
        MM: {
          mask: IMask.MaskedRange,
          from: 1,
          to: 12,
        },
        DD: {
          mask: IMask.MaskedRange,
          from: 1,
          to: 31,
        },
      },
    },
  ],
  DATE_BEFORE_TODAY: [
    {
      mask: Date,
      min: new Date(1917, 0, 1),
      max: new Date(),
      lazy: false,
      format: function (date) {
        return moment(date).format('DD.MM.YYYY');
      },
      parse: function (str) {
        return moment(str, 'DD.MM.YYYY');
      },
    },
  ],

  DATE_BEFORE_TODAY_CUSTOMER: [
    {
      mask: Date,
      min: new Date(1936, 0, 1),
      max: new Date(),
      lazy: false,
      format: function (date) {
        return moment(date).format('DD.MM.YYYY');
      },
      parse: function (str) {
        return moment(str, 'DD.MM.YYYY');
      },
      hidden: '**.**.****',
    },
  ],
  DATE_AFTER_TODAY: [
    {
      mask: Date,
      // min: new Date(),
      // max: new Date(2090, 0, 1),
      lazy: false,
      // format: function (date) {
      // 	return (date && date.length > 0 && moment(date).format('DD.MM.YYYY')) || '';
      // },
      // parse: function (str) {
      // 	return (str && str.length > 0 && moment(str).format('DD.MM.YYYY')) || '';
      // },
    },
  ],
  CELLPHONE: [
    {
      mask: '+7 (000) 000-00-00',
      definitions: {
        $: /^[0123456789]+$/,
      },
      lazy: false,
    },
  ],
  CELLPHONE_PRIVATE: [
    {
      mask: '+7 (FNN) NNN-NN-NN',
      definitions: {
        N: /^[0123456789]$/,
        F: /^[489]$/,
      },
      lazy: false,
    },
  ],
  CELLPHONE_PRIVATE_NEW: [
    {
      mask: '+7 (FNN) NNN-NN-NN',
      definitions: {
        N: /^[0123456789]$/,
        F: /^[3489]$/,
      },
      lazy: false,
      hidden: '+7 (***) ***-**-**',
    },
  ],
  CELLPHONE_NEW: [
    {
      mask: '+7 (FNN) NNN-NN-NN',
      definitions: {
        N: /^[0123456789]$/,
        F: /^[9]$/,
      },
      lazy: false,
    },
  ],
  SMSCODE: [
    {
      mask: '0000',
      lazy: false,
    },
  ],
  PASSPORT_SERIES: [
    {
      mask: '$$$$',
      lazy: false,
      definitions: {
        $: /^[-0123456789]+$/,
      },
    },
  ],
  PASSPORT_NUMBER: [
    {
      mask: '$$$$$$',
      lazy: false,
      definitions: {
        $: /^[-0123456789]+$/,
      },
    },
  ],
  PASSPORT_DEPARTMENT_CODE: [
    {
      mask: 'XXX-XXX',
      lazy: false,
      blocks: {
        X: {
          mask: '$',
          placeholderChar: '_',
          definitions: {
            $: /^[0123456789]+$/,
          },
        },
      },
    },
  ],
  PASSPORT_DEPARTMENT_CODE2: [
    {
      mask: 'XXX-XXX',
      blocks: {
        X: {
          mask: '$',
          placeholderChar: '_',
          definitions: {
            $: /^[0123456789]+$/,
          },
        },
      },
    },
  ],
  DRIVE_LICENSE_SERIES_NUMBER: [
    {
      name: '2nd_driver',
      mask: 'NNLL NNNNNN',
      lazy: false,
      blocks: {
        N: {
          mask: '$',
          placeholderChar: '_',
          definitions: {
            $: /^[0-9]+$/,
          },
        },
        L: {
          mask: '$',
          placeholderChar: '_',
          definitions: {
            $: /^[а-яА-Я0-9]+$/,
          },
        },
      },
    },
  ],
  CYRYLIC: [{ mask: (v) => /[а-яА-Я-]/.test(v) }],
  LATIN: [{ mask: (v) => /[a-zA-Z-]/.test(v) }],
  AGE: '000',
  YEAR: [
    {
      mask: Date,
      pattern: 'YYYY',
      lazy: true,
      min: new Date(1950, 0, 1),
      max: new Date(moment().format('YYYY'), 0, 1),

      format: function (str) {
        return moment(str).format('YYYY');
      },
      parse: function (str) {
        return moment(str, 'YYYY');
      },

      blocks: {
        YYYY: {
          mask: IMask.MaskedRange,
          from: 1950,
          to: moment().format('YYYY'),
        },
      },
    },
  ],
  EMAIL: [{ mask: (v) => /[a-zA-ZА-Яа-я-@0-9]/.test(v) }],
  INN: [{ mask: '000000000', lazy: false }],
  INN_PRIVATE: [
    {
      name: '2nd_tax',
      mask: 'XXXXXXXXXXXX',
      placeholderChar: 'X',
      definitions: {
        X: /^[0123456789]+$/,
      },
      lazy: false,
    },
  ],
  INN_ORGANIZATION: [
    {
      mask: 'SSSSSSSSSS',
      lazy: false,
      blocks: {
        S: {
          mask: '$',
          placeholderChar: '_',
          definitions: {
            $: /^[0123456789]+$/,
          },
        },
      },
    },
  ],
  INN_IP: [
    {
      mask: 'SSSSSSSSSSSS',
      lazy: false,
      blocks: {
        S: {
          mask: '$',
          placeholderChar: '_',
          definitions: {
            $: /^[0123456789]+$/,
          },
        },
      },
    },
  ],
  INN_SERIES_NUMBER: [
    {
      mask: 'NN SSSSSSSSS',
      lazy: false,
      blocks: {
        N: {
          mask: '$',
          placeholderChar: '_',
          definitions: {
            $: /^[0123456789]+$/,
          },
        },
        S: {
          mask: '$',
          placeholderChar: '_',
          definitions: {
            $: /^[0123456789]+$/,
          },
        },
      },
    },
  ],
  PASSPORT_SERIA_NUMBER: [
    {
      mask: 'XXXX SSSSSS',
      lazy: false,
      blocks: {
        X: {
          mask: '$',
          placeholderChar: '_',
          definitions: {
            $: /^[0123456789]+$/,
          },
        },
        S: {
          mask: '$',
          placeholderChar: '_',
          definitions: {
            $: /^[0123456789]+$/,
          },
        },
      },
    },
  ],
  INDEX: [
    {
      mask: 'XXXXXX',
      lazy: false,
      blocks: {
        X: {
          mask: '$',
          placeholderChar: '_',
          definitions: {
            $: /^[0123456789]+$/,
          },
        },
      },
    },
  ],
  ADDRESS_RUS: [
    {
      mask: '$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$',
      definitions: {
        $: /^[А-Яа-яёЁ,.0-9№(\s)-/:]+$/,
      },
    },
  ],
  NUMBER: [{ mask: (v) => /[0123456789]/.test(v) }],
  NUMBER_MAX_99: [
    {
      mask: Number,
      min: 0,
      max: 99,
    },
  ],
  NUMBER_LOCAL: [
    {
      mask: 'num',
      blocks: {
        num: {
          mask: Number,
          thousandsSeparator: ' ',
        },
      },
    },
  ],
  NUMBER_LOCAL_BUS: [
    {
      mask: 'num',
      blocks: {
        num: {
          mask: Number,
          scale: 0,
          thousandsSeparator: ' ',
          min: 0,
          max: 9999999,
        },
      },
    },
  ],
  ALL: [{ mask: /^.+$/ }],
  ALL_RUS: [
    {
      mask: '$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$',
      definitions: {
        $: /^[-А-Яа-яёЁ,.0-9№(\s)-]+$/,
      },
    },
  ],
  ALL_RUS_LETTERS: [
    {
      mask: '$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$',
      definitions: {
        $: /^[а-яА-ЯёЁ\s]*$/,
      },
    },
  ],
  ALL_RUS_BIRTH_PLACE: [
    {
      mask: '$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$',
      definitions: {
        $: /^[-А-Яа-яёЁ,.0-9№(\s)/-]+$/,
      },
    },
  ],
  RUS_NAME: [
    {
      mask: '$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$',
      definitions: {
        $: /^[а-яА-ЯёЁ\-\s]*$/,
      },
    },
  ],
  RUS_NAME_NEW: [{ mask: /^(|[А-ЯЁа-яё][А-ЯЁа-яё\s-]*)$/ }],
  //eslint-disable-next-line no-useless-escape
  CYRYLIC_AND_LATIN: [{ mask: /^[a-zA-Z\]\[\\\{\}\<\>\|\,\'\"\.\:\;а-яА-ЯёЁ\-\s]+$/ }],
  DATE_START_CAREER: [
    {
      mask: Date,
      max: new Date(),
      pattern: 'MM{.}YYYY',
      lazy: false,
      format: function (date) {
        return moment(date).format('MM.YYYY');
      },
      parse: function (str) {
        return moment(str, 'MM.YYYY');
      },

      blocks: {
        YYYY: {
          mask: IMask.MaskedRange,
          from: 1950,
          to: 2060,
        },
        MM: {
          mask: IMask.MaskedRange,
          from: 1,
          to: 12,
        },
      },
    },
  ],
  ARMY_SERIAL_NUMBER: [
    {
      name: '2nd_military',
      mask: 'AA SSSSSSS',
      lazy: false,
      blocks: {
        A: {
          mask: '$',
          placeholderChar: '_',
          definitions: {
            $: /^[а-яА-Я]+$/,
          },
        },
        S: {
          mask: '$',
          placeholderChar: '_',
          definitions: {
            $: /^[0123456789]+$/,
          },
        },
      },
    },
  ],
  ZPASSPORT_SERIA_NUMBER: [
    {
      name: '2nd_foreign_passport',
      mask: 'SS SSSSSSS',
      lazy: false,
      blocks: {
        S: {
          mask: '$',
          placeholderChar: '_',
          definitions: {
            $: /^[0123456789]+$/,
          },
        },
      },
    },
  ],
  PERCENT: [
    {
      mask: Number,
      min: 0,
      max: 100,
    },
  ],
  PERCENT_WITH_DECIMAL: [
    {
      mask: Number,
      scale: 2,
      radix: '.',
      min: 0,
      max: 100,
    },
  ],
  PERCENT_WITH_DECIMAL_AND_PERCENT: [
    {
      mask: 'N %',
      lazy: false,
      blocks: {
        N: {
          mask: Number,
          scale: 2,
          radix: '.',
          min: 0,
          max: 100,
        },
      },
    },
  ],
  INTEGER: [
    {
      mask: Number,
      scale: 0,
      min: 0,
      max: 999999,
    },
  ],
  INTEGER_ABOVE_ZERO: [
    {
      mask: Number,
      min: 1,
      max: 999999,
    },
  ],
  SNILS: [
    {
      name: '2nd_pension',
      mask: 'NNN-NNN-NNN NN',
      lazy: false,
      blocks: {
        N: {
          mask: '$',
          placeholderChar: '_',
          definitions: {
            $: /^[0123456789]+$/,
          },
        },
      },
    },
  ],
  ANY_CHARACTER_UP_TO_20: [
    {
      mask: (v) => v.length <= 20,
      lazy: false,
    },
  ],
  ANY_CHARACTER_UP_TO_30: [
    {
      mask: (v) => v.length <= 30,
      lazy: false,
    },
  ],
  SMS_CODE: [
    {
      name: 'sms_code',
      mask: 'NNNNNN',
      lazy: false,
      blocks: {
        N: {
          mask: '$',
          placeholderChar: '_',
          definitions: {
            $: /^[0123456789]+$/,
          },
        },
      },
    },
  ],
  HALVA_POLICY_NUMBER: [
    {
      name: 'halva_number',
      mask: 'NNNN-NNNN-NNNN-NNNN',
      lazy: false,
      blocks: {
        N: {
          mask: '$',
          placeholderChar: '*',
          definitions: {
            $: /^[0123456789*]+$/,
          },
        },
      },
    },
  ],
  HALVA_EXPIRE_DATE: [
    {
      name: 'halva_date',
      mask: '2\\0YY-MM',
      lazy: false,
      placeholderChar: '_',
      blocks: {
        YY: {
          mask: (v) => {
            const _c = /^\d{0,2}$/.test(v);
            return _c && (v.length < 2 ? true : Number(v) <= 99);
          },
          lazy: false,
          placeholderChar: '_',
          displayChar: '_',
        },
        MM: {
          mask: (v) => {
            const _c = /^\d{0,2}$/.test(v);
            return _c && (v.length < 2 ? true : Number(v) <= 12);
          },
          lazy: false,
          placeholderChar: '_',
          displayChar: '_',
        },
      },
    },
  ],
  NDS_AMOUNT: [
    {
      mask: 'RUB',
      blocks: {
        RUB: {
          mask: Number,
          scale: 2,
          signed: false,
          radix: '.',
          mapToRadix: [',', '.'],
          thousandsSeparator: ' ',
          min: 0,
          max: 99999999,
        },
      },
    },
  ],
  CAR_PRICE: [
    {
      mask: 'RUB',
      blocks: {
        RUB: {
          mask: Number,
          scale: 2,
          signed: false,
          radix: '.',
          mapToRadix: [',', '.'],
          thousandsSeparator: ' ',
          max: 1000000000,
        },
      },
    },
  ],
  PRODUCT_PRICE: [
    {
      mask: 'RUB',
      blocks: {
        RUB: {
          mask: Number,
          scale: 2,
          signed: false,
          radix: '.',
          mapToRadix: [',', '.'],
          thousandsSeparator: ' ',
          max: 1000000,
        },
      },
    },
  ],
  CAR_CONTRACT_NUMBER: [
    {
      mask: (v) => {
        const _c = /^\S+$/.test(v);
        return _c && v.length <= 20;
      },
      lazy: false,
    },
  ],
};

export const unmaskValue = (value, maskChar = '_', unmaskChar = '') =>
  value.replaceAll(maskChar, unmaskChar).trim();
