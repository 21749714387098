import React from 'react';
import cn from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import NavItem from './NavItem';
import UserItem from './UserItem';
import { userLogout } from 'store/user';
import { logout } from '../../../data-providers/authProvider.js';
import { providerRoles } from 'constants/index';

const dumb = () => {};

const NavDetail = ({
  currFid,
  active = false,
  closeFloat = dumb,
  openFloat = dumb,
  user = {},
  onItemClick = dumb,
}) => {
  const dispatch = useDispatch();
  const role = useSelector((store) => store.user.role || -1);

  const clickExitHandler = () => {
    dispatch(userLogout());
    logout();
    // userLogout();
  };

  //   const ncreateNewClickHandler = () => {
  //     /*
  //     if (parseInt(props.role, 10) !== 90) {
  //       window.open(`${process.env.REACT_APP_STAGE_URL}/#create_new`, '_self');
  //     } else {
  //       window.open(`${process.env.REACT_APP_STAGE_URL}/#calculation`, '_self');
  //     }
  //     */
  //     window.open(`${process.env.REACT_APP_STAGE_URL}/#calculation`, '_self');
  //   };

  //   const onClickToOldEcred = () => {
  //     document.cookie = 'rfront=0; domain=.e-credit.one; path=/;';
  //     window.location.href = `${process.env.REACT_APP_BASE_PROXY_URL}/#applications`;
  //   };

  return (
    <div
      // onClick={detailCloseFloat}
      className={cn(['sidebar-nav__detail', { active }])}
    >
      <div className="nav-group top">
        <NavItem
          onClick={onItemClick}
          onMouseOver={closeFloat}
          childType={null}
          fid="home"
          classList={['home']}
        >
          {/*<LangSwitcher />*/}
        </NavItem>
        {/*<NavItem
          fid="search"
          onMouseOver={closeFloat}
          childType={null}
          classList={['search']}
        >
          <SearchNavItem className="search-nav" allClose={allClose} />
        </NavItem>*/}
        {[90, 91, 3197].includes(parseInt(role, 10)) && (
          <NavItem
            onClick={onItemClick}
            // onMouseOver={closeFloat}
            src={faChevronRight}
            onMouseOver={openFloat}
            label="Создать"
            fid="new"
            active={currFid === 'new'}
            dataTest="createButton"
          />
        )}
        <NavItem
          onClick={onItemClick}
          onMouseOver={openFloat}
          label="Заявки"
          src={faChevronRight}
          fid="orders"
          active={currFid === 'orders'}
        />
        {providerRoles.includes(parseInt(role, 10)) && (
          <NavItem
            onClick={onItemClick}
            onMouseOver={openFloat}
            label="Реквизиты"
            src={faChevronRight}
            fid="provider"
            active={currFid === 'provider'}
          />
        )}
      </div>

      <div className="nav-group middle">
        {/*[91, 3197, 3213, 3406].includes(parseInt(role, 10)) && (
          <NavItem
            onMouseOver={openFloat}
            label="Банки"
            src={faChevronRight}
            fid="banks"
            active={currFid === 'banks'}
          />
        )*/}
      </div>
      <div className="nav-group middle">
        {/*<NavItem
          // onClick={newsClickHandler}
          onMouseOver={closeFloat}
          childType=""
          label="Новости"
          fid="news"
        />
        <NavItem
          onClick={onClickToOldEcred}
          onMouseOver={closeFloat}
          childType=""
          label="Старая система"
          fid="oldecred"
        />*/}
      </div>
      <div className="nav-group bottom">
        {![90].includes(parseInt(role, 10)) && (
          <NavItem
            onClick={onItemClick}
            onMouseOver={openFloat}
            label="Отчеты"
            src={faChevronRight}
            active={currFid === 'reports'}
            fid="reports"
          />
        )}
        {/*![90, 91, 3370].includes(parseInt(props.role, 10)) && <NavItem
              onClick={marketClickHandler}
              onMouseOver={closeFloat}
              childType=""
              label='Маркет'
            fid='market' />*/}
        {/* {[3197, 93, 3213, 3406].includes(parseInt(role, 10)) && (
          <NavItem
            onClick={onItemClick}
            onMouseOver={openFloat}
            label="Настройки"
            src={faChevronRight}
            active={currFid === 'settings'}
            fid="settings"
          />
        )} */}
        <UserItem
          onClick={onItemClick}
          classList={['user']}
          onMouseOver={openFloat}
          src={faChevronRight}
          user={user}
          // userName={userFio}
          // roles={roles}
          role={role}
          fid="user"
          active={currFid === 'user'}
        />

        <NavItem
          onClick={clickExitHandler}
          onMouseOver={closeFloat}
          label="Выйти"
          childType=""
          active={currFid === 'exit'}
          fid="exit"
        />
      </div>
    </div>
  );
};

export default NavDetail;
