import React, { useState } from 'react';
import { pdfjs } from 'react-pdf';
import { Document, Page } from 'react-pdf';
import { Wrapper, View, Control, NavLeft, NavRight, Icon, Info } from './style';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const PDFViewer = ({ src }) => {
  const [numPages, setNumPages] = useState(null);
  const [page, setPage] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <Wrapper height={window.innerHeight}>
      <Control>
        {page > 1 && (
          <NavLeft onClick={() => setPage(page - 1)}>
            <Icon />
          </NavLeft>
        )}
      </Control>
      <View>
        <Document file={src} onLoadSuccess={onDocumentLoadSuccess}>
          <Page pageNumber={page} />
        </Document>
        {numPages && (
          <Info>
            Страница {page} из {numPages}
          </Info>
        )}
      </View>
      <Control>
        {numPages >= page + 1 && (
          <NavRight onClick={() => setPage(page + 1)}>
            <Icon />
          </NavRight>
        )}
      </Control>
    </Wrapper>
  );
};
