import {
  convertDate,
  convertOptionToSysName,
  convertSysNameToSelectOption,
} from 'utils/dataMappers/mappers';

export const personalInfoInitialState = {
  personalInfo: {
    gender: {},
    firstName: '',
    lastName: '',
    middleName: '',
    fullName: '',
    isFioChanged: false,
    prevFirstName: '',
    prevLastName: '',
    prevMiddleName: '',
    prevFullName: '',
    changeFioReason: {},
    familyState: {},
    yearsMarried: 0,
    birthDate: '',
    childrenCount: 0,
    dependentCount: 0,
  },
};

export const personalInfoFromAPIToState = (
  customer,
  { genderList, fioChangeReasonsList, martialStatusesList }
) => {
  const pi = customer.personalInformation;
  return {
    personalInfo: {
      gender: convertSysNameToSelectOption(
        pi.genderSysName === '' ? 'male' : pi.genderSysName,
        genderList
      ),
      lastName: pi.surname,
      firstName: pi.name,
      middleName: pi.middleName,
      fullName: pi.surname
        ? `${pi.surname.length > 0 ? `${pi.surname}${pi.name.length > 0 ? ' ' : ''}` : ''}${
            pi.name.length > 0 ? `${pi.name}${pi.middleName.length > 0 ? ' ' : ''}` : ''
          }${pi.middleName.length > 0 ? `${pi.middleName}` : ''}`
        : '',
      isFioChanged: !!pi.fioWasChange,
      prevFirstName: pi.previousName,
      prevLastName: pi.previousSurname,
      prevMiddleName: pi.previousMiddleName,
      prevFullName: pi.previousSurname
        ? [pi.previousSurname, pi.previousName, pi.previousMiddleName]
            .filter((name) => name)
            .join(' ')
        : '',
      changeFioReason: convertSysNameToSelectOption(
        pi.fioChangeReasonSysName,
        fioChangeReasonsList
      ),
      familyState: convertSysNameToSelectOption(pi.maritalStatusSysName, martialStatusesList),
      yearsMarried: pi.yearsMarried,
      birthDate: convertDate(pi.birthDate),
      childrenCount: pi.childrenCount,
      dependentCount: pi.numberOfDependents,
    },
  };
};

export const personalInfoFromStateToAPI = (person) => {
  const pi = person.personalInfo;
  return {
    personalInformation: {
      genderSysName: convertOptionToSysName(pi.gender),
      name: pi.firstName || '',
      surname: pi.lastName || '',
      middleName: pi.middleName || '',
      fioWasChange: pi.isFioChanged || false,
      previousName: pi.prevFirstName || '',
      previousSurname: pi.prevLastName || '',
      previousMiddleName: pi.prevMiddleName || '',
      fioChangeReasonSysName: convertOptionToSysName(pi.changeFioReason),
      maritalStatusSysName: convertOptionToSysName(pi.familyState),
      yearsMarried: parseInt(pi.yearsMarried) || 0,
      birthDate: pi.birthDate || '',
      childrenCount: parseInt(pi.childrenCount) || 0,
      numberOfDependents: parseInt(pi.dependentCount) || 0,
    },
  };
};
