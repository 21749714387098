import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import OutsideClickHandler from 'react-outside-click-handler';
import PulseLoader from 'react-spinners/PulseLoader';
import {
  Wrapper,
  Selector,
  Label,
  Value,
  InputStyled,
  ChevronSolidStyled,
  DropDown,
  Title,
  Back,
  ChevronStyled,
  Content,
  List,
  Item,
  Text,
  Prompt,
  RightIcon,
  PulseLoaderStyled,
} from './style';

export const Select = (props) => {
  const {
    id,
    name,
    list,
    label,
    disabled,
    required,
    defaultValue,
    search,
    onChange,
    onBlur,
    className,
    error,
    icon,
    style,
    onlyid = true,
    fetch = false,
  } = props;
  const inputEl = useRef(null);
  const [focused, setFocused] = useState(false);
  const [select, setSelect] = useState(null);
  const [alert, setAlert] = useState(false);
  const [subString, setSubString] = useState('');

  const handleBlur = (e) => {
    e.stopPropagation();

    setFocused(false);
    if (required && !defaultValue) {
      setAlert(true);
    }
    onBlur({ [name]: defaultValue });
  };

  const handleChange = (item) => {
    setFocused(false);
    if (onlyid) {
      onChange({ [name]: item.id });
    } else {
      onChange({ [name]: item });
    }
  };

  const handleChangeInput = (e) => {
    setSubString(e.target.value.toLowerCase());
  };

  const selectorClick = () => {
    if (!disabled) {
      setFocused(true);
    }
    if (search) {
      inputEl.current.focus();
    }
  };

  const dropDownRender = () => {
    const content = (
      <DropDown className="anketa_select_dropdown">
        <Title>
          <Back onClick={handleBlur}>
            <ChevronStyled />
            Назад
          </Back>
          {label}
        </Title>
        <Content>
          <List className="anketa_select_dropdown_list">
            {list &&
              list
                .filter((f) =>
                  subString.length > 0 ? f.value.toLowerCase().indexOf(subString) > -1 : true
                )
                .map((item, i) => (
                  <Item key={`select_${i + 1}`} onClick={() => handleChange(item)}>
                    <Text>{item.value}</Text>
                  </Item>
                ))}
          </List>
        </Content>
      </DropDown>
    );

    return content;
  };

  useEffect(() => {
    if (onlyid) {
      if (defaultValue && list) {
        const obj = list.filter((f) => f.id === defaultValue);
        if (obj.length > 0) {
          setSelect(obj[0]);
          if (search) {
            setSubString(obj[0].value);
          }
        }
      } else {
        setSelect(null);
      }
    } else {
      if (defaultValue && list) {
        const obj = list.filter((f) => f.id === defaultValue.id);
        if (obj.length > 0) {
          setSelect(obj[0]);
          if (search) {
            setSubString(obj[0].value);
          }
        }
      } else {
        setSelect(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue, list]);

  useEffect(() => {
    setAlert(!!error);
  }, [error]);

  return (
    <Wrapper id={id} className={className} style={style} focused={focused}>
      <Selector
        id={`${id}_selector`}
        disabled={disabled}
        focused={focused}
        select={select}
        required={!disabled && alert && !focused && !!error}
        onClick={selectorClick}
      >
        {label && (
          <Label
            focused={focused || select}
            defaultValue={select}
            required={!disabled && alert && !focused && !!error}
            className="anketa_select_label"
          >
            {label}
            {required && <sup>*</sup>}
          </Label>
        )}
        {!search && select && list && <Value className="anketa_select_value">{select.value}</Value>}
        {search && list && !disabled && (
          <InputStyled
            className="anketa_select_value"
            type="text"
            ref={inputEl}
            autocomplete="off"
            value={subString || (select && select.value)}
            onChange={handleChangeInput}
          />
        )}
        <RightIcon>{icon ? icon : <ChevronSolidStyled />}</RightIcon>
        {!disabled && error && alert && <Prompt className="select_prompt">{error || ''}</Prompt>}
      </Selector>
      {focused && (
        <OutsideClickHandler onOutsideClick={handleBlur}>{dropDownRender()}</OutsideClickHandler>
      )}
      {fetch && <PulseLoader css={PulseLoaderStyled} size={10} color={'#b5b5b5'} loading={true} />}
    </Wrapper>
  );
};

Select.propTypes = {
  name: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  label: PropTypes.any,
  list: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string,
  icon: PropTypes.any,
  search: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  error: PropTypes.string,
};

Select.defaultProps = {
  name: null,
  disabled: false,
  required: false,
  label: null,
  list: null,
  defaultValue: null,
  icon: null,
  search: false,
  onChange: () => {},
  onBlur: () => {},
  className: '',
  error: null,
};
