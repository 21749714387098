import styled, { css } from 'styled-components';

import { DoubleArrowSvg } from 'assets/img';
import { carouselBreakPoints, carouselType } from './carouselOptions';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const navStyles = css`
  display: flex;
  align-items: center;
  width: 30px;
  padding: 0 5px;
  cursor: pointer;
`;

export const NavLeft = styled.div`
  ${navStyles};
  justify-content: flex-end;
  transform: rotate(180deg);
`;
export const NavRight = styled.div`
  ${navStyles};
  justify-content: flex-end;
`;

export const Icon = styled(DoubleArrowSvg)`
  width: 14px;
  height: 18px;
`;

export const Container = styled.div`
  overflow: hidden;

  ${({ styleByType }) =>
    styleByType === carouselType.RESPONSIVE
      ? css`
          margin: 20px auto 0 auto;

          @media screen and (min-width: ${`${carouselBreakPoints.SMALL}px`}) {
            width: 446px;
          }

          @media screen and (min-width: ${`${carouselBreakPoints.LARGE}px`}) {
            width: 673px;
          }
        `
      : css`
          margin: 2rem auto 0 auto;
        `};
`;
