// this is true stringify method for arguments with brackets []
export const stringifyPartWithBrackets = (params) =>
  Object.keys(params).reduce((acc, stringKey) => {
    if (stringKey.includes('[') && params[stringKey]) {
      return acc + '&' + stringKey + '=' + params[stringKey];
    }

    return acc;
  }, '');

export const getParamsWithoutArgumentsWithBrackets = (params) =>
  Object.keys(params).reduce(
    (acc, stringKey) => {
      if (stringKey.includes('[')) {
        delete acc[stringKey];
      }

      return acc;
    },
    { ...params }
  );

export const createASWRequestParams = (applicationId, { policyStartDate, ...rest }) => ({
  applicationId,
  policyStartDate: policyStartDate?.split('T')?.[0],
  multidrive: false,
  ...rest,
});

export const createEOWRequestParams = createASWRequestParams;
