import React from 'react';
import debounce from 'lodash.debounce';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DEF_CLASS = 'nav-item nav-item--increased-height';
const DEF_ICON_CLASS = 'nav-item__icon';

const clickHandler = ({ id, fid, name }, onClick) =>
  debounce(() => onClick({ id, fid, name }), 300);
const overHandler = ({ id, fid, name }, onOver) => debounce(() => onOver({ id, fid, name }), 50);
const outHandler = ({ id, fid, name }, onOver) => debounce(() => onOver({ id, fid, name }), 50);
const leaveHandler = ({ id, fid, name }, onOver) => debounce(() => onOver({ id, fid, name }), 50);

const currentRoleName = (user) => {
  if (user && user.role && user.roles) {
    const roleName = user.roles.filter((r) => r.id === user.role)[0];
    if (roleName && roleName.name) {
      return roleName.name;
    }
    return '';
  }
  return '';
};

const getCurrentDealerName = ({ dealer, dealers }) => {
  let dealerName = '';
  if (dealers.length) {
    dealerName = dealers.find(({ id }) => id === dealer)?.name || '';
  }

  return dealerName;
};

const UserItem = ({
  id,
  fid, // family-id for menu group
  name,
  onClick = () => {},
  onMouseOver = () => {},
  onMouseLeave = () => {},
  onMouseOut = () => {},
  classList = [],
  childClassList = [],
  childType = 'icon',
  src,
  active,
}) => {
  const user = useSelector((store) => store.user);

  let child = null;
  if (childType === 'img') {
    child = <img src={src} alt="" className={cn([DEF_ICON_CLASS, ...childClassList])} />;
  }
  if (childType === 'icon') {
    child = <FontAwesomeIcon icon={src} className={cn([DEF_ICON_CLASS, ...childClassList])} />;
  }

  return (
    <div
      onClick={clickHandler({ id, fid, name }, onClick)}
      onMouseOver={overHandler({ id, fid, name }, onMouseOver)}
      onMouseOut={outHandler({ id, fid, name, type: 'out' }, onMouseOut)}
      onMouseLeave={leaveHandler({ id, fid, name, type: 'leave' }, onMouseLeave)}
      className={cn([DEF_CLASS, ...classList, { active }])}
    >
      <div className="user__label-container">
        <p className="user__user-name">{user && `${user.lastname} ${user.firstname}`}</p>
        <span className="user__status">{currentRoleName(user)}</span>
        <span className="user__status">{getCurrentDealerName(user)}</span>
      </div>
      {child}
    </div>
  );
};

export default UserItem;
