export const isRequiredRisk = (riskId) => ['2572'].includes(riskId);

export const createRiskItemWithParams = (risk) => [
  {
    ...risk,
    id: `${risk.id}`,
    isIncluded: false,
    isParentItem: true,
    description: 'Выберите размер покрытия',
    disabled: isRequiredRisk(risk.riskId),
  },
  ...risk.params.map((amount) => ({
    ...risk,
    id: `${risk.id}|${amount}`,
    isOptional: true,
    amount,
  })),
];

export const checkModifiedParams = (newTimestamp, currentLastModifiedAt) => {
  const appModifiedAt = new Date(newTimestamp ?? 0).valueOf();
  const needUpdateLastModifiedAt = appModifiedAt > currentLastModifiedAt;
  return { appModifiedAt, needUpdateLastModifiedAt };
};

const createMappedRisks = (rawRisksList) =>
  // создаёт объект с рисками, которые распределены по ключу (переменная key)
  // далее по этому ключу риски будут добавляться в каждый оффер
  rawRisksList
    .map((el) => el)
    .sort((a, b) => b.riskId - a.riskId)
    .reduce((acc, el) => {
      const key = `${el.insuranceCompanyCode}${el.productId}`;
      const hasParams = el.params?.length;

      if (!acc[key]) {
        acc[key] = hasParams
          ? [...createRiskItemWithParams(el)]
          : [{ ...el, id: `${el.id}`, isIncluded: false }];
        return acc;
      }

      const alreadyAddedRisks = acc[key].filter(
        (risk) =>
          risk.riskId === el.riskId &&
          (risk.amount === Number(el.selectedParam) ||
            (!hasParams && risk.riskName === el.riskName))
      );

      if (!alreadyAddedRisks.length) {
        acc[key] = hasParams
          ? [...acc[key], ...createRiskItemWithParams(el)]
          : [...acc[key], { ...el, id: `${el.id}`, isIncluded: false }];
      }

      if (alreadyAddedRisks.length) {
        alreadyAddedRisks.forEach((risk) => {
          risk.isIncluded = true;
          if (isRequiredRisk(risk.riskId) && !risk.amount) {
            risk.disabled = true;
          }
          if (el.type === 'additional_risk') {
            risk.id = `${el.id}`;
            risk.type = el.type;
          }
        });
      }
      return acc;
    }, {});

const createOffersWithRisks = (mappedRisks, offers) => {
  // возвращает новый массив офферов с рисками
  const offersCopy = [...offers];
  offersCopy.forEach((offer) => {
    const offerKeyForRisk = `${offer.insuranceCompanyCode}${offer.productId}`;
    if (mappedRisks[offerKeyForRisk]) {
      offer.risks = mappedRisks[offerKeyForRisk];
    }
  });
  return offersCopy;
};

const createFinalRisksList = (mappedRisksList) => {
  const finalRisks = mappedRisksList
    .filter((el) => el.isIncluded || el.type === 'included_risk')
    .map(({ id: fullIdString }) => {
      const [id, selectedParam = null] = fullIdString.split('|');
      return { id: Number(id), selectedParam };
    })
    .reduce((acc, el) => {
      if (!acc[el.id]) {
        acc[el.id] = el;
      }
      if (!acc[el.id].selectedParam && el.selectedParam) {
        acc[el.id] = el;
      }
      return acc;
    }, {});

  return Object.values(finalRisks);
};

export const createReadyMadeRisks = (rawRisksList, offers) => {
  const mappedRisks = createMappedRisks(rawRisksList);
  const offersWithRisks = createOffersWithRisks(mappedRisks, offers);
  const mappedRisksList = Object.values(mappedRisks).flat();
  const finalRisksList = createFinalRisksList(mappedRisksList);
  const receivedRisksIds = finalRisksList.map(({ id }) => id);
  const risksWithParams = mappedRisksList
    .filter((el) => el.isParentItem)
    .map(({ id }) => Number(id));

  return {
    risks: {
      risksList: finalRisksList,
      receivedRisksIds,
      risksWithParams,
    },
    offersWithRisks,
  };
};

export const createSelectedBankObject = (banksList, bankId) => {
  if (!bankId) {
    return null;
  }

  return banksList.find((el) => el.id === bankId) ?? { id: bankId };
};
