import styled from 'styled-components';

import { PALETTE } from 'styles/constants';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 1600px;
  min-height: 100%;
  padding: 25px;
`;

export const NavigationContainer = styled.div`
  position: sticky;
  top: 0px;
  z-index: 99;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  height: 75px;
  padding-top: 15px;
  margin-top: 10px;
  border-bottom: 1px solid ${PALETTE.grey2};
  background-color: ${PALETTE.white};
`;

export const Search = styled.div`
  width: 345px;
  margin-left: 25px;
`;
