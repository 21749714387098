import React from 'react';
import PropTypes from 'prop-types';

import * as S from './style';

export const InsurerDriverSwitcher = ({
  checked = false,
  disabled,
  onSwitch,
  showDriverCheckbox,
}) => (
  <S.Switch>
    <S.CheckBoxStyled name="insurer" label="Страхователь" checked disabled />
    {showDriverCheckbox && (
      <S.CheckBoxStyled
        name="driver"
        label="Водитель"
        checked={checked}
        disabled={disabled}
        onClick={() => {
          if (!disabled) {
            onSwitch(!checked);
          }
        }}
      />
    )}
  </S.Switch>
);

InsurerDriverSwitcher.propTypes = {
  checked: PropTypes.bool,
  onSwitch: PropTypes.func,
};
