import { getItem } from 'utils/local-storage';

const userIdDecorator = () => {
  const user = getItem('user');
  let userId = (user && user.id) || null;

  return {
    get: function () {
      return userId;
    },
    set: function (value) {
      if (userId === value) {
        return false;
      }

      userId = value;
      return true;
    },
  };
};

export const userIdObject = userIdDecorator();
