import { applyMiddleware, combineReducers, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { customSagas } from './sagas';
import { customReducers } from './reducers';
import { composeWithDevTools } from 'redux-devtools-extension';

const composeEnhancers = composeWithDevTools({
  serialize: true,
});

let store = null;

export default function () {
  if (store) {
    return store;
  }

  const middlewares = [];
  customSagas.forEach(() => {
    middlewares.push(createSagaMiddleware());
  });

  const gobalStore = createStore(
    combineReducers(customReducers),
    composeEnhancers(applyMiddleware(...middlewares))
  );

  customSagas.forEach((saga, i) => {
    middlewares[i].run(saga);
  });

  store = gobalStore;
  return gobalStore;
}
