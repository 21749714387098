export const IGNORED_BANKS = [379];

export const CERTIFICATE_BLOCK_BANKS = [290, 851];

export const DEALS_STATUS = {
  APPROVED: 'Одобрено',
  REQUESTED: 'Запрос банка',
  QUEUE: 'На рассмотрении',
  DECLINE: 'Отказ',
  OPENED: 'Доступные банки для подачи',
};
