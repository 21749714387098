import React, { useState, useEffect, useRef } from 'react';
import animateScrollTo from 'animated-scroll-to';
import PropTypes from 'prop-types';
import { InputMask } from 'utils/inputMask';
import { CONTACTS } from '../../../../constants';

import * as S from './style';

const fn = () => {};

const Contacts = ({
  data,
  scrollTo = null,
  errors = {},
  disabled = false,
  onBlur = null,
  onScroll = fn,
}) => {
  const ref = useRef();

  const [state, setState] = useState({ ...data });
  const [validationErrors, setValidationErrors] = useState({});

  useEffect(() => {
    if (ref && ref.current && scrollTo === CONTACTS.name) {
      animateScrollTo(ref.current);
      onScroll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollTo]);

  useEffect(() => {
    setValidationErrors(errors);
  }, [errors]);

  useEffect(() => {
    setState(data);
  }, [data]);

  const onBlurHandler = async (value) => {
    if (onBlur) {
      onBlur({ value, state });
    }
  };

  return (
    <S.Wrapper ref={ref}>
      <S.Title>Контакты</S.Title>
      <S.Phone
        required
        isControlledComponent
        val={state.insurerPhone}
        name="insurerPhone"
        label="Номер телефона"
        mask={InputMask.CELLPHONE_NEW}
        onBlur={onBlurHandler}
        error={validationErrors?.insurerPhone}
        disabled={disabled}
        onChange={({ insurerPhone }) => {
          setState({
            ...state,
            insurerPhone,
          });
        }}
      />
      <S.Email
        required
        isControlledComponent
        name="insurerEmail"
        label="Емейл"
        val={state.insurerEmail}
        mask={InputMask.EMAIL}
        onBlur={onBlurHandler}
        error={validationErrors?.insurerEmail}
        disabled={disabled}
        onChange={({ insurerEmail }) => {
          setState({
            ...state,
            insurerEmail,
          });
        }}
      />
    </S.Wrapper>
  );
};

Contacts.propTypes = {
  counter: PropTypes.number,
  title: PropTypes.string,
  errors: PropTypes.object,
  data: PropTypes.object,
  onBlur: PropTypes.oneOfType([PropTypes.func, PropTypes.oneOf([null])]),
};

export default Contacts;
