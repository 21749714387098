import styled from 'styled-components';

import { PALETTE } from 'styles/constants';

export const Title1 = styled.h2`
  margin-bottom: 20px;
  font-size: 20px;

  &:last-child {
    margin-bottom: 0px;
  }
  * + & {
    margin-top: 40px;
  }
`;

export const Title2 = styled.h3`
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: 500;

  &:last-child {
    margin-bottom: 0px;
  }
  * + & {
    margin-top: 30px;
  }
`;

export const Title3 = styled.h4`
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 500;

  &:last-child {
    margin-bottom: 0px;
  }
  * + & {
    margin-top: 20px;
  }
`;

export const Paragraph = styled.div`
  margin-bottom: 12px;
  font-size: 14px;

  &:last-child {
    margin-bottom: 0px;
  }
`;

export const Description = styled.div`
  margin: 0px;
  font-size: 12px;
  color: ${PALETTE.grey5};
`;

export const Link = styled.a`
  color: ${PALETTE.blue1};
`;
