import styled from 'styled-components';

import Inp from 'components/primitives/input-new';
import InpMask from 'components/primitives/input-mask-new';
import { DatePicker } from 'components/primitives/datepicker';
import { Select as Sel } from 'components/primitives/iselect-new';
import { Select as SelPortal } from 'components/primitives/select-portal';
import { CheckBox as Check } from 'components/primitives/check-box';

import { PALETTE } from 'styles/constants';

export const Form = styled.div`
  display: grid;
  grid-template-columns: repeat(
    ${(p) => (p.cols ? p.cols : '8')},
    calc((100% - ${(p) => ((p.cols || 8) - 1) * 15}px) / ${(p) => (p.cols ? p.cols : 8)})
  );
  grid-template-rows: repeat(${(p) => (p.rows ? p.rows : '1')}, auto);
  grid-gap: 15px;
`;

export const ButtonRow = styled.div`
  display: flex;

  & > * + * {
    margin-left: 10px;
  }
`;

export const Input = styled(Inp).attrs((props) => ({
  isControlledComponent: true,
  disabled: props.disabled || props.readonly,
}))`
  height: 56px;
  border: 1px solid ${PALETTE.grey2};
  border-radius: 5px;
  border-color: ${(p) => (p.disabled ? PALETTE.grey3 : PALETTE.grey2)};
  background-color: ${(p) => (p.disabled ? PALETTE.grey3 : PALETTE.white)};
  cursor: ${(p) => (p.readonly ? 'default' : 'text')} !important;
  cursor: ${(p) => (p.disabled ? 'not-allowed' : 'text')} !important;

  & * {
    cursor: ${(p) => (p.readonly ? 'default' : 'text')} !important;
    cursor: ${(p) => (p.disabled ? 'not-allowed' : 'text')} !important;
  }
`;

export const Mask = styled(InpMask).attrs((props) => ({
  isControlledComponent: true,
  disabled: props.disabled || props.readonly,
}))`
  height: 56px;
  border: 1px solid ${PALETTE.grey2};
  border-radius: 5px;
  border-color: ${(p) => {
    if (p.error) {
      return PALETTE.red1;
    } else if (p.disabled) {
      return PALETTE.grey3;
    } else {
      return PALETTE.grey2;
    }
  }};
  background-color: ${(p) => (p.disabled ? PALETTE.grey3 : PALETTE.white)};
  cursor: ${(p) => (p.readonly ? 'default' : 'text')} !important;
  cursor: ${(p) => (p.disabled ? 'not-allowed' : 'text')} !important;

  & * {
    cursor: ${(p) => (p.readonly ? 'default' : 'text')} !important;
    cursor: ${(p) => (p.disabled ? 'not-allowed' : 'text')} !important;
  }
`;

export const Date = styled(DatePicker).attrs((props) => ({
  disabled: props.disabled || props.readonly,
}))`
  height: 56px;
  padding: 0px;
  border: 1px solid ${PALETTE.grey2};
  border-radius: 5px;
  border-color: ${(p) => {
    if (p.error) {
      return PALETTE.red1;
    } else if (p.disabled) {
      return PALETTE.grey3;
    } else {
      return PALETTE.grey2;
    }
  }};
  background-color: ${(p) => (p.disabled ? PALETTE.grey3 : PALETTE.white)};
  cursor: ${(p) => (p.readonly ? 'default' : 'pointer')} !important;
  cursor: ${(p) => (p.disabled ? 'not-allowed' : 'pointer')} !important;

  & * {
    cursor: ${(p) => (p.readonly ? 'default' : 'pointer')} !important;
    cursor: ${(p) => (p.disabled ? 'not-allowed' : 'pointer')} !important;
  }
  & .react-datepicker-wrapper {
    height: auto;
    font-size: 0;
  }
  & input {
    font-size: 14px;
  }
  & svg {
    margin-top: -3px;
  }
  & .react-datepicker-popper * {
    cursor: default !important;
    & button,
    .react-datepicker__day[aria-disabled='false'] {
      cursor: pointer !important;
    }
  }
`;

export const Select = styled(Sel).attrs((props) => ({
  onlyid: props.onlyid === false ? false : true,
  disabled: props.disabled || props.readonly,
}))`
  height: 56px;
  cursor: ${(p) => (p.readonly ? 'default' : 'pointer')} !important;
  cursor: ${(p) => (p.disabled ? 'not-allowed' : 'pointer')} !important;

  & * {
    cursor: ${(p) => (p.readonly ? 'default' : 'pointer')} !important;
    cursor: ${(p) => (p.disabled ? 'not-allowed' : 'pointer')} !important;
  }
  & > div:first-child > div:first-child {
    border: 1px solid ${PALETTE.grey2};
    border-color: ${(p) => {
      if (p.error) {
        return PALETTE.red1;
      } else if (p.disabled) {
        return PALETTE.grey3;
      } else {
        return PALETTE.grey2;
      }
    }};
    background-color: ${(p) => (p.disabled ? PALETTE.grey3 : PALETTE.white)};
  }
  & > div:first-child > div + div {
    margin-top: -1px;
    border-top: 1px solid ${PALETTE.grey2};
  }
  & p {
    font-size: 14px;
    line-height: 20px;
  }
  & svg {
    margin-right: -5px;
  }
  & input[type='text'] + svg {
    margin-right: 0px;
  }
  & .select-prompt {
    font-size: 10px;
    top: 57px;
  }
`;

export const SelectPortal = styled(SelPortal).attrs((props) => ({
  onlyid: props.onlyid === false ? false : true,
  disabled: props.disabled || props.readonly,
}))`
  height: 56px;
  cursor: ${(p) => (p.readonly ? 'default' : 'pointer')} !important;
  cursor: ${(p) => (p.disabled ? 'not-allowed' : 'pointer')} !important;

  & * {
    cursor: ${(p) => (p.readonly ? 'default' : 'pointer')} !important;
    cursor: ${(p) => (p.disabled ? 'not-allowed' : 'pointer')} !important;
  }
  & > div:first-child > div:first-child {
    border: 1px solid ${PALETTE.grey2};
    border-color: ${(p) => {
      if (p.error) {
        return PALETTE.red1;
      } else if (p.disabled) {
        return PALETTE.grey3;
      } else {
        return PALETTE.grey2;
      }
    }};
    background-color: ${(p) => (p.disabled ? PALETTE.grey3 : PALETTE.white)};
  }
  & > div:first-child > div + div {
    margin-top: -1px;
    border-top: 1px solid ${PALETTE.grey2};
  }
  & p {
    font-size: 14px;
    line-height: 20px;
  }
  & svg {
    margin-right: -5px;
  }
  & input[type='text'] + svg {
    margin-right: 0px;
  }
  & .select-prompt {
    font-size: 10px;
    top: 57px;
  }
`;

export const CheckBox = styled(Check).attrs((props) => ({
  type: 'default',
  size: 20,
  borderRadius: 5,
  disabled: props.disabled || props.readonly,
}))`
  height: 56px;
  font-size: 15px;
  opacity: 1;
  cursor: ${(p) => (p.readonly ? 'default' : 'pointer')} !important;
  cursor: ${(p) => (p.disabled ? 'not-allowed' : 'pointer')} !important;

  & > * {
    opacity: ${(p) => (p.disabled ? `0.6;` : '1')};
    cursor: ${(p) => (p.readonly ? 'default' : 'pointer')} !important;
    cursor: ${(p) => (p.disabled ? 'not-allowed' : 'pointer')} !important;
  }
`;

export const Button = styled.button.attrs((props) => ({ 'data-test': props.dataTest }))`
  width: ${(props) => (props.ico ? `${props.small ? '40' : '56'}px` : 'auto')};
  height: ${(props) => (props.small ? '40' : '56')}px;
  padding: 0px ${(props) => (props.ico ? '0px;' : `${props.small ? '15' : '30'}px`)};
  border-radius: 5px;
  border: none;
  outline: none;
  cursor: ${(p) => (p.disabled ? 'not-allowed' : 'pointer')} !important;
  color: ${PALETTE.black};
  font-size: 15px;

  &:disabled {
    opacity: 0.3;
  }
  &:hover,
  &:focus-visible {
    filter: brightness(1.1);
  }
  & > svg {
    vertical-align: middle;
    max-width: ${(props) => (props.small ? '20' : '26')}px;
    max-height: ${(props) => (props.small ? '20' : '26')}px;
    margin-right: ${(props) => (props.ico ? '0' : props.small ? '10' : '15')}px;
  }
`;

export const RadionButton = styled(Button)`
  height: 30px;
  padding: 0px 15px;
  border-radius: 16px;
  border: 1px solid ${PALETTE.blue5};
  background-color: ${(props) => (props.checked ? PALETTE.blue5 : 'unset')};
  color: ${(props) => (props.checked ? PALETTE.white : PALETTE.dark)};

  &:hover,
  &:focus-visible {
    filter: brightness(1);
    color: ${(props) => (props.checked ? PALETTE.white : PALETTE.blue5)};
  }
`;

export const BlueButton = styled(Button)`
  background-color: ${PALETTE.blue5};
  color: ${PALETTE.white};
`;

export const LightBlueButton = styled(Button)`
  background-color: ${PALETTE.blue4};

  &:hover,
  &:focus-visible {
    filter: brightness(1.03);
  }
`;

export const GreenButton = styled(Button)`
  background-color: ${PALETTE.green1};
`;

export const RedButton = styled(Button)`
  background-color: ${PALETTE.red3};
  color: ${PALETTE.red1};

  &:hover,
  &:focus-visible {
    filter: brightness(1.05);
  }
`;

export const DarkRedButton = styled(Button)`
  background-color: ${PALETTE.red1};
  color: ${PALETTE.white};

  &:hover,
  &:focus-visible {
    filter: brightness(1.05);
  }
`;

export const LightButton = styled(Button)`
  background-color: ${PALETTE.white};
  border: 1px solid ${PALETTE.grey2};

  &:hover,
  &:focus-visible {
    filter: none;
    background-color: ${PALETTE.grey9};
  }
`;
