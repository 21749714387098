import { takeLatest, call, select, put } from 'redux-saga/effects';
import * as localStorage from 'utils/local-storage';

import * as applicationsProvider from 'data-providers/applicationsProvider';
import { INITIAL_DEALER_ID, ROLES_WITH_SPECIFIC_DEALER } from 'constants/index';
import { resetOptions } from 'utils/common';

import {
  LOAD_FILTERS,
  RELOAD_FILTERS,
  SET_FILTERS,
  ALL_FILTERS_LOADED,
  LOAD_DEALERS,
} from './constants';

const localFilterVerify = (storedFilter, loadedFilter) => {
  const c = loadedFilter.map((filter) => {
    const _filter = storedFilter.value.find((element) => element?.id === filter.id);
    if (_filter) {
      filter.checked = _filter.checked;
    }
    return filter;
  });

  return c;
};

const optionsDecorator = (pure, keyIn, keyOut = 'label') => {
  const options = pure.map((op) => {
    const option = {};
    if (Array.isArray(keyIn)) {
      option[keyOut] = `${op[keyIn[0]]} ${op[keyIn[1]]}`;
    } else {
      option[keyOut] = op[keyIn];
    }
    option.name = `${op.id}`;
    return { ...op, ...option, checked: false };
  });

  return options;
};

const optionsDisabled = (options, isDisabled) => {
  return resetOptions(options, 'disabled', isDisabled);
};

const addPropertyToOptions = (options, settings, newProperty) => {
  const isSettingsNotEmpty = Array.isArray(settings) && settings.length > 0;
  return options.map((op) => {
    op[newProperty] = isSettingsNotEmpty
      ? !!(settings.includes(op.name) || settings[0] === '*')
      : false;
    return { ...op };
  });
};

function* loadFilters({ payload }) {
  try {
    yield put({ type: ALL_FILTERS_LOADED, payload: false });
    yield call(loadKsoMop);
    yield call(loadStates, payload.states);
    yield put({ type: ALL_FILTERS_LOADED, payload: true });
  } catch (err) {
    console.log(err);
  }
}

function* reloadFilters({ payload }) {
  try {
    yield put({ type: ALL_FILTERS_LOADED, payload: false });
    yield call(loadProducts, payload.states.products);
    yield call(loadStates, payload.states);
    yield put({ type: ALL_FILTERS_LOADED, payload: true });
  } catch (err) {
    console.log(err);
  }
}

function* loadDealers() {
  try {
    const postauth = localStorage.getItem('postauth');
    const lsDealers = localStorage.getItem('dealer');
    const lsUser = localStorage.getItem('user');
    const { role } = yield select((store) => store.user);
    let { dealers } = yield select((store) => store.user);

    dealers = optionsDecorator(dealers, 'name');

    // eslint-disable-next-line array-callback-return
    dealers = dealers.map((dealer) => {
      if (postauth) {
        localStorage.removeItem('postauth');

        return { ...dealer, checked: dealer.id === lsUser.dealer };
      }

      const isFirstBoot = lsDealers?.value[0]?.id === INITIAL_DEALER_ID;

      if (ROLES_WITH_SPECIFIC_DEALER.includes(role) && isFirstBoot) {
        return {
          ...dealer,
          checked: dealer.id === lsUser.dealer,
        };
      }

      if (ROLES_WITH_SPECIFIC_DEALER.includes(role)) {
        const stored = lsDealers?.value?.find((op) => op?.id === dealer?.id);

        return { ...dealer, checked: stored ? stored.checked : false };
      }

      // in case !ROLES_WITH_SPECIFIC_DEALER
      return { ...dealer, checked: false };
    });

    yield put({
      type: SET_FILTERS,
      payload: {
        filter: 'dealer',
        value: dealers,
      },
    });
  } catch (err) {
    console.log(err);
  }
}

function* loadStates(params) {
  try {
    const currentDealer = yield select((store) => store.user.dealer);
    let states = yield applicationsProvider.getStateDict({
      currentDealer,
    });

    states = optionsDecorator(states, 'stateSecondName');

    states = addPropertyToOptions(states, params.checked, 'checked');
    states = addPropertyToOptions(states, params.checked, 'inSearch');
    states = addPropertyToOptions(states, params.visibleList, 'visible');

    const isAllOptionsChecked =
      states.filter((op) => op.checked && op.visible).length === params.visibleList.length;
    const allOption = {
      label: 'Выбрать все',
      name: 'all',
      checked: isAllOptionsChecked,
      isCheckedAllInListStatus: isAllOptionsChecked && params.url === 'list',
    };

    states = [allOption, ...states];

    states = optionsDisabled(states, params.disabled);

    yield put({
      type: SET_FILTERS,
      payload: {
        filter: 'lastState',
        value: states,
      },
    });
  } catch (err) {
    console.log(err);
    throw err;
  }
}

function* loadKsoMop() {
  try {
    const userId = yield select((store) => store.user.id);
    if (!userId) {
      return;
    }

    let kso = yield applicationsProvider.getKSODict(userId);
    kso = kso.filter((item) => !item.isHidden && !item.isEcreditStaff);
    kso = optionsDecorator(kso || [], 'name', 'firstname');

    const localFilterKso = localStorage.getItem('ks');
    if (localFilterKso) {
      kso = localFilterVerify(localFilterKso, kso);
    }

    let mop = yield applicationsProvider.getMOPDict(userId);
    mop = mop.filter((item) => !item.isHidden && !item.isEcreditStaff);
    mop = optionsDecorator(mop || [], 'name', 'firstname');

    const localFilterMop = localStorage.getItem('mop');
    if (localFilterMop) {
      mop = localFilterVerify(localFilterMop, mop);
    }

    yield put({ type: SET_FILTERS, payload: { filter: 'ks', value: kso } });
    yield put({ type: SET_FILTERS, payload: { filter: 'mop', value: mop } });
  } catch (err) {
    console.log(err);
    throw err;
  }
}

function* loadProducts(products) {
  try {
    let states = yield select((store) => store.applicFilters.products);
    states = addPropertyToOptions(states, products, 'checked');
    yield put({
      type: SET_FILTERS,
      payload: {
        filter: 'products',
        value: states,
      },
    });
  } catch (err) {
    console.log(err);
    throw err;
  }
}

export function* watchLoadFilters() {
  yield takeLatest(LOAD_FILTERS, loadFilters);
}

export function* watchReloadFilters() {
  yield takeLatest(RELOAD_FILTERS, reloadFilters);
}

export function* watchUserUpdated() {
  yield takeLatest(LOAD_DEALERS, loadDealers);
}
