import request from 'utils/request';
import * as urls from 'constants/url';
import throwCustomError from 'utils/throwCustomError';

export const getEPointsPrograms = async (dealers) => {
  let url = `${process.env.REACT_APP_GATEWAY_URL}${urls.GATEWAY_EPOINTS_PROGRAMS_URL}`;
  dealers.forEach((d, i) => {
    url += `${i === 0 ? '?' : '&'}dealerIds[]=${d.id}`;
  });
  const { data } = await request.get(url).catch(throwCustomError);

  return data;
};
