export const customer = {
  id: 135,
  selfApprove: false,
  contacts: {
    mobilePhone: null,
    homePhone: null,
    email: '',
  },
  personalInformation: {
    name: 'новый',
    surname: 'клиент',
    middleName: '',
    birthDate: '',
    fioWasChange: false,
    previousName: '',
    previousMiddleName: '',
    previousSurname: '',
    fioChangeReasonSysName: '',
    maritalStatusSysName: '',
    yearsMarried: 0,
    childrenCount: 0,
    numberOfDependents: 0,
    genderSysName: '',
  },
  passport: {
    id: 0,
    series: '',
    number: '',
    issueDate: '',
    issuerCode: '',
    issuer: '',
    birthPlace: '',
    changed: false,
    previousSeries: null,
    previousNumber: null,
    previousIssueDate: null,
    previousIssuerCode: null,
    citizenship: '',
  },
  registrationAddress: {
    registrationDate: '',
    address: {
      id: null,
      indexNumber: '',
      regionKladrCode: '',
      regionName: '',
      districtKladrCode: '',
      districtName: '',
      cityKladrCode: '',
      cityName: '',
      settlementKladrCode: '',
      settlementName: '',
      streetKladrCode: '',
      streetName: '',
      streetWithType: '',
      streetType: '',
      streetTypeFull: '',
      houseNumber: '',
      buildingNumber: '',
      apartmentNumber: '',
      longitude: 0,
      latitude: 0,
      created: '',
      matchCheckbox: false,
      regionWithType: '',
      regionType: '',
      regionTypeFull: '',
      districtWithType: '',
      districtType: '',
      districtTypeFull: '',
      cityWithType: '',
      cityType: '',
      cityTypeFull: '',
      settlementWithType: '',
      settlementType: '',
      settlementTypeFull: '',
      houseType: '',
      houseTypeFull: '',
      buildingType: '',
      buildingTypeFull: '',
      apartmentType: '',
      apartmentTypeFull: '',
      regionFiasId: '',
      areaFiasId: '',
      cityFiasId: '',
      settlementFiasId: '',
      streetFiasId: '',
      houseFiasId: '',
      fiasId: '',
      fiasCode: '',
      fiasLevel: '',
      fiasValue: '',
    },
    livingAddressEquals: true,
  },
  livingAddressEquals: true,
  livingAddress: {
    livingFrom: '',
    address: {
      id: null,
      indexNumber: '',
      regionKladrCode: '',
      regionName: '',
      districtKladrCode: '',
      districtName: '',
      cityKladrCode: '',
      cityName: '',
      settlementKladrCode: '',
      settlementName: '',
      streetKladrCode: '',
      streetName: '',
      streetWithType: '',
      streetType: '',
      streetTypeFull: '',
      houseNumber: '',
      buildingNumber: '',
      apartmentNumber: '',
      longitude: 0,
      latitude: 0,
      created: '',
      matchCheckbox: false,
      regionWithType: '',
      regionType: '',
      regionTypeFull: '',
      districtWithType: '',
      districtType: '',
      districtTypeFull: '',
      cityWithType: '',
      cityType: '',
      cityTypeFull: '',
      settlementWithType: '',
      settlementType: '',
      settlementTypeFull: '',
      houseType: '',
      houseTypeFull: '',
      buildingType: '',
      buildingTypeFull: '',
      apartmentType: '',
      apartmentTypeFull: '',
      regionFiasId: '',
      areaFiasId: '',
      cityFiasId: '',
      settlementFiasId: '',
      streetFiasId: '',
      houseFiasId: '',
      fiasId: '',
      fiasCode: '',
      fiasLevel: '',
      fiasValue: '',
    },
  },
  secondDocument: {
    currentSecondDocumentTypeSysName: '',
    internationalPassport: {
      series: '',
      number: '',
      issueDate: '',
      issuer: '',
      expires: '',
    },
    snils: {
      number: '',
    },
    taxRegistration: {
      inn: '',
      series: '',
      number: '',
      issueDate: '',
      issuer: '',
    },
    driverLicense: {
      id: null,
      series: '',
      number: '',
      issueDate: '',
      issuer: '',
      whereGivenOut: '',
      startDate: '',
      previousNumber: '',
      previousSeries: '',
      previousIssueDate: '',
    },
    militaryID: {
      number: '',
      series: '',
      issueDate: '',
      issuer: '',
    },
  },
  partner: null,
  confidants: null,
  employment: {
    typeSysName: '',
    phones: [''],
    isOfficialEmployment: false,
    organization: {
      name: '',
      typeSysName: '',
      formSysName: '',
      inn: '',
      okved: '0',
      ogrn: '0',
      legalAddress: {
        id: null,
        indexNumber: '',
        regionKladrCode: '',
        regionName: '',
        districtKladrCode: '',
        districtName: '',
        cityKladrCode: '',
        cityName: '',
        settlementKladrCode: '',
        settlementName: '',
        streetKladrCode: '',
        streetName: '',
        streetWithType: '',
        streetType: '',
        streetTypeFull: '',
        houseNumber: '',
        buildingNumber: '',
        apartmentNumber: '',
        longitude: 0,
        latitude: 0,
        created: '',
        matchCheckbox: false,
        regionWithType: '',
        regionType: '',
        regionTypeFull: '',
        districtWithType: '',
        districtType: '',
        districtTypeFull: '',
        cityWithType: '',
        cityType: '',
        cityTypeFull: '',
        settlementWithType: '',
        settlementType: '',
        settlementTypeFull: '',
        houseType: '',
        houseTypeFull: '',
        buildingType: '',
        buildingTypeFull: '',
        apartmentType: '',
        apartmentTypeFull: '',
        regionFiasId: '',
        areaFiasId: '',
        cityFiasId: '',
        settlementFiasId: '',
        streetFiasId: '',
        houseFiasId: '',
        fiasId: '',
        fiasCode: '',
        fiasLevel: '',
        fiasValue: '',
      },
      actualAddress: {
        id: null,
        indexNumber: '',
        regionKladrCode: '',
        regionName: '',
        districtKladrCode: '',
        districtName: '',
        cityKladrCode: '',
        cityName: '',
        settlementKladrCode: '',
        settlementName: '',
        streetKladrCode: '',
        streetName: '',
        streetWithType: '',
        streetType: '',
        streetTypeFull: '',
        houseNumber: '',
        buildingNumber: '',
        apartmentNumber: '',
        longitude: 0,
        latitude: 0,
        created: '',
        matchCheckbox: false,
        regionWithType: '',
        regionType: '',
        regionTypeFull: '',
        districtWithType: '',
        districtType: '',
        districtTypeFull: '',
        cityWithType: '',
        cityType: '',
        cityTypeFull: '',
        settlementWithType: '',
        settlementType: '',
        settlementTypeFull: '',
        houseType: '',
        houseTypeFull: '',
        buildingType: '',
        buildingTypeFull: '',
        apartmentType: '',
        apartmentTypeFull: '',
        regionFiasId: '',
        areaFiasId: '',
        cityFiasId: '',
        settlementFiasId: '',
        streetFiasId: '',
        houseFiasId: '',
        fiasId: '',
        fiasCode: '',
        fiasLevel: '',
        fiasValue: '',
      },
      registrationYear: 0,
      employeesCountSysName: '',
      employerIndustryBranchSysName: '',
      actualAddressEquals: false,
      employerManagerFullName: '',
    },
    positionTypeSysName: '',
    positionName: '',
    careerStart: '-0001-11-30',
    careerStartCurrent: '',
    rotationWork: false,
    seniority: '',
    employeeDepartmentTypeSysName: '',
  },
  educationLevelSysName: '',
  finances: {
    incomes: {
      current: '',
      advanced: '',
      basicMonthlyIncomeSpouse: '',
      additionalIncomeSpouse: '',
      confirmationType: {
        current: null,
        advanced: null,
        basicMonthlyIncomeSpouse: null,
        additionalIncomeSpouse: null,
      },
    },
    expenses: {
      commonSpendings: '',
      creditsSpendings: '',
      requiredCreditSpendings: '',
      anyCreditSpendings: '',
      mortgagePaymentSpendings: '',
    },
  },
  property: {
    cars: [],
    estates: [],
  },
  agreement: {
    bki: true,
    digitalSign: true,
    personalData: true,
  },
  livingAddressProperty: {
    realtyStateSysName: '',
    realtyTypeSysName: '',
    realtyObtainingSysName: '',
  },
  registrationAddressProperty: {
    realtyStateSysName: '',
    realtyTypeSysName: '',
    realtyObtainingSysName: '',
  },
};
