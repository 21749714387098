import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation, useRouteMatch, useParams } from 'react-router-dom';
import animateScrollTo from 'animated-scroll-to';
import { ToMainLink } from 'components/modules/Widgets/ToMainLink';
import { UserWidget } from 'components/modules/Widgets/User';
import { CarWidget } from 'components/modules/Widgets/Car';
import { OldLinkWidget } from 'components/modules/Widgets/AnketaOldLink';
import AssistanceWidget from 'components/modules/Widgets/Assistance';
import KaskoWidget from 'components/modules/Widgets/Kasko';
import { AnketaProgress, AnketaProgressProvider } from 'components/modules/Widgets/AnketaProgress';
import { Application } from 'components/modules/Application';
import { Layout } from 'components/modules/Layout';
import { Loader } from 'components/primitives/loader';
import { getProfile } from 'store/anketa';
import { getCarInstanceById, getDealersParams } from 'store/carInstance';
import { assistanceCalcDeleteId, assistanceCalcAllToInitState } from 'store/assistanceCalculation';
import {
  insuranceDeleteId,
  insuranceGetPrelist,
  insuranceAllToInitState,
} from 'store/insuranceCalculation';
import {
  selectAssistanceId,
  selectAssistanceClientId,
  selectCreditId,
  selectCarInstanceId,
} from 'store/assistanceCalculation/selectors';
import {
  ElfSightBannerCommon,
  ElfSightCherryBanner,
  ElfSightFocusGroupPangoCardBanner,
} from 'components/modules/Calculations/components/EOsagoBanner/ElfSightBanner';
// import OmodaKasko from 'components/modules/Widgets/OmodaKasko';
import AssistancePopup from 'components/modules/Calculations/components/AssistancePopup';
import { get } from 'data-providers/common';
import { getWorksheetById } from 'data-providers/applicationsProvider';
import { getCustomer, storeItem } from 'store/startPage';
import { changeActiveDealer } from 'store/user';
import { Cherry, FocusGroupPangoCard } from 'constants/dealerCenters';

import { widgetButtonClickTrack } from 'metrika/eosago';

import { PALETTE } from 'styles/constants';
import ErrorPopup from 'components/primitives/error-popup';

export const ApplicationLayout = () => {
  const assistanceRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const { path } = useRouteMatch();
  const rootDispatch = useDispatch();
  const params = useParams();

  const { documents, currentPersonId } = useSelector((state) => state.anketa);
  const { dealers, dealer: dealerId } = useSelector((state) => state.user);
  const { applicationId: assistanceApplicationId } = useSelector(
    (state) => state.assistanceCalculation
  );
  const { applicationId: insuranceApplicationId } = useSelector(
    (state) => state.insuranceCalculation
  );
  const { error: startPageError, application } = useSelector((state) => state.startPage);
  const { carInstance } = useSelector((state) => state);
  const clientId = useSelector(selectAssistanceClientId);
  const assistanceId = useSelector(selectAssistanceId);
  const carInstanceId = useSelector(selectCarInstanceId);
  const creditId = useSelector(selectCreditId);

  const { dealersParams } = carInstance;

  const dealer = dealers.find((d) => d.id === dealerId);
  const assistanceOrders = (application?.orders || []).filter((o) => o.productId === 'assistance');
  const kaskoOrders = (application?.orders || []).filter((o) => o.productId === 'insurance');

  const [state, setState] = useState(null);
  const [errors, setErrors] = useState(null);
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [firstQueryCarInstance, setFirstQueryCarInstance] = useState(true);
  const [showAssistancePopup, setShowAssistancePopup] = useState(false);
  const [error, setError] = useState(false);

  const { useStartPage } = dealer || {};
  const isAnketa = path === '/worksheet/:id/credit/anketa';
  const isAssistance = path === '/worksheet/:id/assistance/anketa';
  const isInsurance = path === '/worksheet/:id/insurance/anketa';

  const hideToMain =
    (path === '/worksheet/:id/assistance' ||
      path === '/worksheet/:id/insurance' ||
      path === '/worksheet/:id' ||
      path === '/worksheet/create-new' ||
      path === '/worksheet/create-new/service' ||
      path === '/worksheet/create-new/service') &&
    !isAnketa &&
    isAssistance &&
    !isInsurance;

  const assistanceClickHandler = () => {
    if (clientId && assistanceId) {
      history.push(`/worksheet/${clientId}/assistance`);
      widgetButtonClickTrack();

      setTimeout(() => {
        if (assistanceRef?.current) {
          animateScrollTo(assistanceRef.current);
        } else {
          animateScrollTo(400);
        }
      }, 50);
    }
  };

  const kaskoWidgetClickHandler = () => {
    if (params.id) {
      history.push(`/worksheet/${params.id}/insurance`);
      widgetButtonClickTrack();
    } else {
      history.push(`/worksheet/create-new/insurance`);
    }
  };

  const CalculationsWidget = [
    dealer && useStartPage ? (
      <AssistanceWidget orders={assistanceOrders} onClick={assistanceClickHandler} />
    ) : null,
  ];

  const getNewCarInstance = () => {
    if (!dealerId || !carInstanceId || !dealer || !useStartPage) {
      return;
    }

    rootDispatch(getCarInstanceById(carInstanceId));
  };

  // The strange fix of bug from 2021-07-09 14:05 point 2 of ticket OS-223
  // That may do new bugs in another tickets like OS-522
  useEffect(
    () => () => {
      // OS-522 If we has go to the main application page, then we don't need to removing this ids
      if (!window.location.pathname.match(/^\/worksheet(\/\d*)?/)) {
        rootDispatch(assistanceCalcDeleteId());
        rootDispatch(insuranceDeleteId());
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (!assistanceApplicationId) {
      rootDispatch(assistanceCalcAllToInitState());
    }
    if (!insuranceApplicationId) {
      rootDispatch(insuranceAllToInitState());
    }
  }, [creditId, dealerId, dealer, isAnketa]); // eslint-disable-line react-hooks/exhaustive-deps

  /* useEffect(() => {
    if (assistanceApplicationId && useStartPage) {
      rootDispatch(assistanceCalcReqPrelist({ applicationId: assistanceApplicationId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assistanceApplicationId]); */

  useEffect(() => {
    if (insuranceApplicationId && useStartPage) {
      rootDispatch(insuranceGetPrelist({ applicationId: insuranceApplicationId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insuranceApplicationId]);

  useEffect(() => {
    if (
      params.id &&
      location.pathname.includes(`/worksheet/${params.id}`) &&
      !firstQueryCarInstance
    ) {
      getNewCarInstance();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, dealer]);

  useEffect(() => {
    if (!startPageError && currentPersonId === 0) {
      localStorage.removeItem('worksheet');
      localStorage.removeItem('carinstanceId');
      localStorage.removeItem('customerId');

      const workSheetId = params.id;

      getWorksheetById(workSheetId)
        .then((worksheet) => {
          if (worksheet) {
            if (worksheet?.dealer?.id !== dealerId) {
              rootDispatch(changeActiveDealer(worksheet?.dealer?.id));
            }
            if (worksheet.ids?.credit) {
              rootDispatch(getProfile(worksheet.ids?.credit));
            }
            if (worksheet?.carInstance?.id) {
              rootDispatch(getCarInstanceById(worksheet?.carInstance?.id, worksheet?.id));
            }
            if (worksheet?.client?.customerId) {
              rootDispatch(getCustomer({ customerId: worksheet?.client?.customerId }));
            }
            rootDispatch(storeItem(worksheet));
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 403) {
            setError(true);
          }
        });

      setFirstQueryCarInstance(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, currentPersonId, startPageError]);

  useEffect(() => {
    setFirstQueryCarInstance(true);
  }, [params.id, dealerId]);

  useEffect(() => {
    if (dealer?.id && dealer?.id !== dealersParams.dealerId && !isAnketa) {
      rootDispatch(getDealersParams(dealer?.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealer?.id, isAnketa]);

  const leftChildren = [
    ...(dealer && useStartPage && !hideToMain ? [<ToMainLink key="to-main" />] : []),
    ...(isAnketa
      ? [
          <OldLinkWidget
            key="old-link-widget"
            errors={errors}
            selfAgreement={state ? state.persons[state.currentPersonId].selfAgreement : false}
            personalData={
              documents ? documents.some((doc) => doc.type && doc.type.id === 3295) : false
            }
            onRouteOut={setRedirectUrl}
          />,
        ]
      : []),
    <UserWidget key="user" />,
    <CarWidget key="car" />,
  ];
  const rigthChildren = [
    ...(!isAnketa
      ? CalculationsWidget
      : [
          dealer && useStartPage && (
            <KaskoWidget orders={kaskoOrders} onClick={kaskoWidgetClickHandler} />
          ),
          dealer && useStartPage && (
            <AssistanceWidget orders={assistanceOrders} onClick={assistanceClickHandler} />
          ),
          // isAnketa ? <OmodaKasko key="omodaKasko" /> : null,
        ]),
    ...(isAnketa ? [<AnketaProgress key="progress" errors={errors} />] : []),
    Cherry.includes(dealerId) ? (
      <ElfSightCherryBanner onClick={() => setShowAssistancePopup(true)} key="777" />
    ) : FocusGroupPangoCard.includes(dealerId) ? (
      <ElfSightFocusGroupPangoCardBanner onClick={() => setShowAssistancePopup(true)} key="777" />
    ) : (
      <ElfSightBannerCommon onClick={() => setShowAssistancePopup(true)} key="777" />
    ),
  ];

  const mainChildren = (
    <Application
      onErrors={setErrors}
      onChange={setState}
      redirectUrl={redirectUrl}
      onRouteOut={setRedirectUrl}
      isAnketa={isAnketa}
    />
  );

  useEffect(() => {
    if (!documents || documents.lenght === 0) {
      get('doctypes', { perPage: 1000 }).then((data) => {
        rootDispatch({
          type: 'UPLOAD_COMMON',
          payload: {
            documentTypes: data,
          },
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documents]);

  const setCloseModal = () => {
    history.push('/applications/list');
    setError(false);
  };

  return (
    <AnketaProgressProvider>
      <>
        <Layout
          leftChildren={leftChildren}
          rigthChildren={rigthChildren}
          mainChildren={mainChildren || <Loader color={PALETTE.white} show={true} />}
        />
        {showAssistancePopup && <AssistancePopup onClose={() => setShowAssistancePopup(false)} />}
        {error && (
          <ErrorPopup
            onClose={setCloseModal}
            errorMessage={
              <p>
                Кажется у Вас нет доступа к этой странице!
                <br />
                Пожалуйста, проверьте адрес страницы (URL) или перейдите на Список заявок
              </p>
            }
            buttonText={'Список заявок'}
          />
        )}
      </>
    </AnketaProgressProvider>
  );
};
