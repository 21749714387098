import * as actions from './constants';
import initialState from './initialState';

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.CALCULATOR_LOAD_LAST_CALCULATION_DATA: {
      return {
        ...state,
        lastCalculationData: action.payload,
        isFetched: action.payload?.isFetched,
      };
    }

    case actions.CALCULATOR_DROP_LAST_CALCULATION_DATA: {
      return {
        ...state,
        lastCalculationData: null,
        isFetched: false,
      };
    }

    case actions.CALCULATOR_SET_SELECTED_PROGRAM_ID: {
      return {
        ...state,
        lastCalculationData: {
          ...state.lastCalculationData,
          selectedProgramId: action.payload,
        },
      };
    }

    default:
      return state;
  }
}

export function approval(data, customer = null, callback = null) {
  return { type: actions.CALCULATOR_APPROVAL, payload: { data, customer, callback } };
}

export function setLastCalculationData(data) {
  return { type: actions.CALCULATOR_LOAD_LAST_CALCULATION_DATA, payload: data };
}

export function dropLastCalculationData() {
  return { type: actions.CALCULATOR_DROP_LAST_CALCULATION_DATA };
}

export function logPicker(data) {
  return { type: actions.CALCULATOR_PICKER_LOG, payload: data };
}

export function setSelectedProgramId(id) {
  return { type: actions.CALCULATOR_SET_SELECTED_PROGRAM_ID, payload: id };
}
