import React from 'react';
import { NextStatus } from './style';

const Status = ({ className = '', status = {} }) => {
  const { name = '', value = '', color = '' } = status;

  if (!name || !value || !color) {
    return null;
  }

  return <NextStatus className={className} color={`#${color}`} text={value} />;
};

export default Status;
