import { put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  ADD_PERSONA,
  ANKETA_FETCH,
  BRANDS_LOADED,
  CHANGE_CURRENT_PERSONA,
  CHECK_CAR_NUMBER,
  CHECK_NUMBER_FETCH,
  LOAD_ANKETA,
  LOAD_BRANDS,
  LOAD_MODELS,
  MODELS_FETCH,
  MODELS_LOADED,
  SET_CARINFO,
  STORE_ANKETA,
  UPDATE_PERSONA,
  UPDATE_SUBPART,
  UPLOAD_FILE,
  LOAD_FILE,
  SET_APPLICATION,
  ANKETA_SAVING,
  ANKETA_ERROR,
  LOAD_ANKETA_PDF,
  SET_ANKETA_PDF,
  UPLOAD_COMMON,
  SET_IS_PEP_INFO,
} from './constants';
import * as commonProvider from 'data-providers/common';
import * as anketaProvider from 'data-providers/mainAnketaProvider';
import * as documentProvider from 'data-providers/documentProvider';
import * as applicationsProvider from 'data-providers/applicationsProvider';
import { contactsFromAPIToState } from './mappers/contacts';
import { passportFromAPIToState } from './mappers/passport';
import { registrationAddressFromAPIToState } from './mappers/registrationAddress';
import { livingAddressFromAPIToState } from './mappers/livingAddress';
import { livingAddressPropertyFromAPIToState } from './mappers/livingAddressProperty';
import { financeFromAPIToState } from './mappers/finance';
import { secondDocumentFromAPIToState } from './mappers/secondDocument';
import { SET_REFERENCE_BOOKS } from '../referenceBooks/constants';
import { trusteesFromAPIToState } from './mappers/trustees';
import { spouseFromAPIToState } from './mappers/spouse';
import { personalInfoFromAPIToState } from './mappers/personalInfo';
import { propertyFromAPIToState } from './mappers/property';
import { workFromAPIToState } from './mappers/work';
import { detailsFromAPIToState } from './mappers/details';
import { paramsFromAPIToState } from './mappers/applicationParams';

import { generateProfilePayload } from './utils';

function* dataToState(data, referenceBooks, isNew = true) {
  const roles = {};
  for (let item of data) {
    if (roles[item.customer.id]) {
      roles[item.customer.id] = roles[item.customer.id].concat([item.type]);
    } else {
      roles[item.customer.id] = [item.type];
    }
  }

  for (let item of data) {
    //скрываем доверенных лиц
    if (['trustee', 'trustee2', 'spouse', 'driver'].includes(item.type.sysName)) {
      continue;
    }
    if (isNew) {
      yield put({ type: ADD_PERSONA, payload: item.customer.id });
    }

    yield put({
      type: UPDATE_PERSONA,
      payload: {
        id: item.customer.id,
        roles: roles[item.customer.id].map((t) => ({
          id: t.id,
          value: t.sysName,
          label: t.name,
        })),
        selfAgreement: item.customer.selfApprove,
        ...personalInfoFromAPIToState(item.customer, referenceBooks),
        ...contactsFromAPIToState(item.customer, referenceBooks),
        ...passportFromAPIToState(item.customer, referenceBooks),
        ...registrationAddressFromAPIToState(item.customer, referenceBooks),
        ...livingAddressFromAPIToState(item.customer, referenceBooks),
        ...livingAddressPropertyFromAPIToState(item.customer, referenceBooks),
        ...financeFromAPIToState(item.customer, referenceBooks),
        ...secondDocumentFromAPIToState(item.customer, referenceBooks),
        ...trusteesFromAPIToState(item.customer, referenceBooks),
        ...spouseFromAPIToState(item.customer, referenceBooks),
        ...propertyFromAPIToState(item.customer, referenceBooks),
        ...workFromAPIToState(item.customer, referenceBooks),
        ...detailsFromAPIToState(item.details, referenceBooks),
        ...paramsFromAPIToState(item.applicationParams),
        isSavedComplete: false,
      },
    });
  }
}

function* postProfile({ payload }) {
  const { applicationId, persona, referenceBooks, isSignPEP } = payload;

  yield put({ type: ANKETA_ERROR, payload: { values: false } });
  yield put({ type: ANKETA_SAVING, payload: { values: false } });

  const apiData = generateProfilePayload({ applicationId, persona });

  try {
    yield anketaProvider.postProfile(apiData);
    yield put({ type: ANKETA_SAVING, payload: { values: true } });

    if (isSignPEP) {
      const isPEPSignature = yield anketaProvider.isPEPSignature(applicationId);
      const documents = yield documentProvider.loadFiles(applicationId);

      yield put({ type: UPLOAD_COMMON, payload: { documents } });
      yield put({
        type: SET_IS_PEP_INFO,
        payload: {
          isPEPSignature: true,
          otpDocLink: isPEPSignature.links ? isPEPSignature.links : {},
        },
      });
    }

    const data = yield anketaProvider.getProfile(applicationId);
    yield dataToState(data, referenceBooks, false);
  } catch (err) {
    yield put({ type: ANKETA_ERROR, payload: { values: true } });
    console.log(err);
    throw err;
  }
}

function* checkCarNumber({ payload }) {
  try {
    yield put({
      type: CHECK_NUMBER_FETCH,
      payload: { ...payload, values: true },
    });
    const searchType = payload.carNumber.match(/[\wа-я]+/gi) ? 0 : 1;
    const data = yield commonProvider.post('car/info', {
      searchType,
      carNumber: payload.carNumber,
    });

    yield put({ type: SET_CARINFO, payload: { ...payload, car: data } });
    yield loadModels({ payload: { ...payload, brandId: data.brandId } });

    const subpart = {
      ...payload,
      values: {
        car: data,
      },
    };
    yield put({ type: UPDATE_SUBPART, payload: subpart });
  } catch (err) {
    console.log(err);
  } finally {
    yield put({
      type: CHECK_NUMBER_FETCH,
      payload: { ...payload, values: false },
    });
  }
}

function* loadBrands() {
  try {
    let data = yield commonProvider.get('reference/car/brand');
    data = data.map((brand) => ({ ...brand, value: brand.name }));
    yield put({ type: BRANDS_LOADED, payload: data });
  } catch (err) {
    console.log(err);
  }
}

function* loadModels({ payload }) {
  try {
    yield put({ type: MODELS_FETCH, payload: { ...payload, values: true } });
    let data = yield commonProvider.post('reference/car/model', {
      brandId: payload.brandId,
    });
    data = data.map((model) => ({ ...model, value: model.name }));
    yield put({ type: MODELS_LOADED, payload: { ...payload, values: data } });
  } catch (err) {
    console.log(err);
  } finally {
    yield put({ type: MODELS_FETCH, payload: { ...payload, values: false } });
  }
}

function* loadAnketa({ payload }) {
  try {
    yield put({ type: ANKETA_FETCH, payload: { values: false, error: false } });

    const data = yield anketaProvider.getProfile(payload);
    const guides = yield anketaProvider.getGuides();
    const characterPositions = yield anketaProvider.getCharacterPositions();
    const workExpirience = yield anketaProvider.getWorkExpirience();
    const documents = yield documentProvider.loadFiles(payload);
    const documentTypes = yield anketaProvider.getDocumentTypes();
    const anketaTypes = yield anketaProvider.getAnketaTypes(payload);
    const application = yield applicationsProvider.getApplication({
      applicationId: payload,
    });

    if (guides) {
      if (characterPositions) guides.characterPositions = characterPositions;
      if (workExpirience) guides.workExpirience = workExpirience;
    }

    if (!data || !guides || !documents || !documentTypes || !anketaTypes) {
      return;
    }

    yield put({
      type: UPLOAD_COMMON,
      payload: {
        documents,
        documentTypes,
        anketaTypes,
      },
    });

    yield put({ type: SET_REFERENCE_BOOKS, payload: guides });

    const carInstance = {
      ...application.carInstance,
    };

    yield put({
      type: SET_APPLICATION,
      payload: {
        ...application,
        carInstance,
      },
    });

    yield put({
      type: 'car-instance/update',
      payload: { ...carInstance },
    });

    yield dataToState(data, guides);
    //устанавливаем выбранного кастомера
    const borrower = data.find((c) => c.type.sysName === 'borrower');
    if (borrower || data.length > 0) {
      yield put({
        type: CHANGE_CURRENT_PERSONA,
        payload: parseInt(borrower ? borrower.customer.id : data[0].customer.id),
      });
    }

    if (application?.id && application?.dealer?.usePEPAnketa) {
      const isPEPSignature = yield anketaProvider.isPEPSignature(application.id);
      if (isPEPSignature) {
        yield put({
          type: SET_IS_PEP_INFO,
          payload: {
            isPEPSignature: true,
            otpDocLink: isPEPSignature.links ? isPEPSignature.links : {},
          },
        });
      } else
        yield put({
          type: SET_IS_PEP_INFO,
          payload: {
            isPEPSignature: false,
            otpDocLink: {},
          },
        });
    }

    //удаляем дефолтного кастомера
    // yield put({ type: DELETE_PERSONA, payload: 0 });

    yield put({ type: ANKETA_FETCH, payload: { values: true, error: false } });
  } catch (err) {
    yield put({ type: ANKETA_FETCH, payload: { values: true, error: true } });
    console.log(err);
    throw err;
  }
}

function* uploadFiles({ payload }) {
  try {
    const data = yield anketaProvider.uploadFiles(payload);
    if (!data) {
      return;
    }
    return data;
  } catch (err) {
    console.log(err);
    throw err;
  }
}

function* loadFiles({ payload }) {
  try {
    const data = yield documentProvider.loadFiles(payload.applicationId);

    if (!data) {
      return;
    }
    return data;
  } catch (err) {
    console.log(err);
    throw err;
  }
}

function* getPdf({ payload }) {
  try {
    const { applicationId, persona, param } = payload;
    yield put({ type: ANKETA_SAVING, payload: { values: false } });

    const apiData = generateProfilePayload({ applicationId, persona });

    const data = yield anketaProvider.getPdf(applicationId, apiData, param);
    yield put({ type: ANKETA_SAVING, payload: { values: true } });
    if (!data) {
      return;
    }
    yield put({ type: SET_ANKETA_PDF, payload: data.path });
    yield put({ type: SET_ANKETA_PDF, payload: null });
  } catch (err) {
    console.log(err);
    throw err;
  }
}

export function* anketaRecognizeChanel(payload) {
  if (payload.category.id === 'application_files_upload') {
    yield put({ type: ANKETA_FETCH, payload: { values: true } });
  }
}

export function* watchCheckNumber() {
  yield takeLatest(CHECK_CAR_NUMBER, checkCarNumber);
}

export function* watchLoadBrands() {
  yield takeLatest(LOAD_BRANDS, loadBrands);
}

export function* watchLoadModels() {
  yield takeEvery(LOAD_MODELS, loadModels);
}

export function* watchStoreAnketa() {
  yield takeEvery(STORE_ANKETA, postProfile);
}

export function* watchLoadAnketa() {
  yield takeLatest(LOAD_ANKETA, loadAnketa);
}

export function* watchRecognizeFiles() {
  yield takeLatest(UPLOAD_FILE, uploadFiles);
}

export function* watchFiles() {
  yield takeLatest(LOAD_FILE, loadFiles);
}

export function* watchLoadPdf() {
  yield takeEvery(LOAD_ANKETA_PDF, getPdf);
}
