import React from 'react';
import PulseLoader from 'react-spinners/PulseLoader';
import Status from './status';

import * as S from './style';

const PolicyWidget = ({
  title = 'Title',
  state,
  step = 1,
  maxSteps = 4,
  loading = false,
  content = null,
}) => {
  return (
    <S.Wrapper>
      <S.HeaderTop>
        <S.Title>{title}</S.Title>
        <Status status={state} />
      </S.HeaderTop>
      {maxSteps > 0 && (
        <S.StatusLine>
          {Array(maxSteps)
            .fill('')
            .map((value, index) => {
              const key = `${value}${index}`;

              return (
                <S.StatusLineItem key={key}>
                  <S.LineSegment active={step > index} />
                </S.StatusLineItem>
              );
            })}
        </S.StatusLine>
      )}
      <S.Content isHidden={!loading && !content}>
        {loading && (
          <S.LoaderWrapper>
            <PulseLoader color={'#b5b5b5'} size={10} />
          </S.LoaderWrapper>
        )}
        {content}
      </S.Content>
    </S.Wrapper>
  );
};

export default PolicyWidget;
