import React, { useRef, useContext, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { PersonalForm } from './PersonalForm';
import { PassportForm } from './PassportForm';
import { AddressForm } from './AddressForm';
import { TrusteesForm, relTypeFriend } from './TrusteesForm';
import { SecondDocForm } from './SecondDocForm';
import { AnketaProgressContext } from 'components/modules/Widgets/AnketaProgress';
import { FormContainer, FormSubheader, GridRowSubHeader } from '../style';
import { FileUpload } from '../FileUpload';
import { loadFiles } from 'data-providers/documentProvider';
import { isAdmin, isViewClientWithApprovalCheckbox } from 'store/user/selectors';

import { SelfAgreeCheckbox, Mgrid, Docgrid, Title, Multidrive, Anchor, DocsHelp } from './style';
import { v4 as uuidv4 } from 'uuid';

import { realtyStateOwn } from 'store/anketa/mappers/livingAddressProperty';
import { checkChangeValues } from './helpers';
import { deleteDocumetsType, setIsPEPSignature } from 'store/anketa';
import { setOtpId, setTimerStart } from 'store/confirmSMS';
import { getPasportConfidenceErros } from 'utils/dataMappers/mappers';

// const DOC_TEXT_CONF = {
//   'passport': 'Загрузите все страницы, даже незаполненные.',
//   'driving-licence': 'Необходим скан с двух сторон',
// };

const docGridDefault = "'. go go go go go go .''. . . empty_doc empty_doc . . .'";

export const PersonalInfoForm = ({
  data,
  errors,
  params,
  validate,
  onChange,
  forcedValidation,
  anketaSave,
  setFocusAt,
  contentHidden = false,
  disabled = false,
  children,
}) => {
  const id = 'personalInfo';
  const idPassport = 'passport';
  const idTrustees = 'trustees';
  const idSpouse = 'spouse';
  const idLivingAddressProperty = 'livingAddressProperty';
  const idSecondDocument = 'secondDocument';
  const idAddressReg = 'addressReg';
  const idAddressLive = 'addressLive';

  const refs = {
    passport: useRef(null),
    seconddocument: useRef(null),
  };
  const { service } = params;
  const { persons, insurance, currentPersonId } = data;
  const { trustees, livingAddressProperty, personalInfo } = persons[currentPersonId];
  const { showErrors, collapsed, updateCollapsed } = useContext(AnketaProgressContext);
  const rootDispatch = useDispatch();
  const { anketa } = useSelector((state) => state);
  const { documents, application, isPEPSignature } = useSelector((state) => state.anketa);
  const { doctypes } = useSelector((state) => state.referenceBooks);
  const isClientWithApprovalCheckbox = useSelector(isViewClientWithApprovalCheckbox);
  const isUserAdmin = useSelector(isAdmin);

  const appId = Number(anketa.application.id);
  const [uniqueClasses, setUniqueClasses] = useState(null);
  const [docGridTemplate, setDocGridTemplate] = useState(docGridDefault);
  const [pasportConfidenceErros, setPasportConfidenceErros] = useState({});
  const isViewSelfAgreeCheckbox = !service && (isClientWithApprovalCheckbox || isUserAdmin);

  const changeHandlerPersons = ({ values, key, id = currentPersonId }) => {
    const isChangeValues = checkChangeValues(values, key, persons[currentPersonId]);
    if (isChangeValues) {
      if (isPEPSignature) {
        rootDispatch(deleteDocumetsType('personal-data'));
        rootDispatch(setIsPEPSignature(false));
      }
      rootDispatch(setOtpId(null));
      rootDispatch(setTimerStart(0));
    }
    onChange({ insurance, persons: { ...persons, [id]: { ...persons[id], [key]: values } } });
  };

  const changeHandlerInsur = (obj, key) => {
    onChange({ persons, insurance: { ...insurance, [key]: { ...insurance[key], ...obj } } });
  };

  const changeTrustHandler = ({ values, id }) => {
    onChange({
      insurance,
      persons: {
        ...persons,
        [currentPersonId]: { ...persons[currentPersonId], trustees: { ...trustees, [id]: values } },
      },
    });
  };

  const addTrustee = () => {
    const addId = uuidv4();
    const newTrustee = {
      id: addId,
      fullName: '',
      birthDate: '',
      phone: '',
      relationType: relTypeFriend,
      insurance: false,
      drivingLicenseSeriesNumber: '',
      drivingLicenseIssueDate: '',
      drivingLicenseIssuer: '',
      drivingLicenseStartYear: '',
    };
    trustees[addId] = newTrustee;
    changeHandlerPersons({ values: trustees, key: idTrustees });
  };

  useEffect(() => {
    if (Object.keys(trustees).length === 0) {
      addTrustee();
    } else {
      let neetToUpdate = false;
      if (Object.keys(trustees).length > 1) {
        const keys = Object.keys(trustees);
        for (let i = 1; i < keys.length; i++) {
          delete trustees[keys[i]];
        }
        neetToUpdate = true;
      }
      if (!trustees[Object.keys(trustees)[0]].relationType?.id) {
        trustees[Object.keys(trustees)[0]] = {
          ...trustees[Object.keys(trustees)[0]],
          relationType: relTypeFriend,
        };
        neetToUpdate = true;
      }
      if (neetToUpdate) {
        changeHandlerPersons({ values: trustees, key: idTrustees });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trustees]);

  useEffect(() => {
    // Fix problems with input autofocus fileds
    const validateTimeout = setTimeout(() => {
      if (documents) {
        const errors = getPasportConfidenceErros(documents);
        setPasportConfidenceErros(errors);
      }
    }, 1500);

    return () => clearTimeout(validateTimeout);
  }, [documents]);

  useEffect(() => {
    if (!livingAddressProperty?.realtyState?.id && realtyStateOwn) {
      livingAddressProperty.realtyState = { ...realtyStateOwn };
      changeHandlerPersons({ values: livingAddressProperty, key: idLivingAddressProperty });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [livingAddressProperty, realtyStateOwn]);

  const updateFiles = (callback = () => {}) => {
    loadFiles(appId).then((data) => {
      rootDispatch({
        type: 'UPLOAD_COMMON',
        payload: {
          documents: data,
        },
      });
      callback();
    });
  };

  const classOrder = [
    'passport',
    'driving-licence',
    'snils',
    'international-passport',
    'inn',
    'military-id',
    'job-income-docs',
    'pts',
    'foto-customer',
    'customer-other-files',
  ];

  const classCompareFunc = (a, b) => {
    if (classOrder.indexOf(a) === -1) return -1;
    if (classOrder.indexOf(b) === -1) return 0;
    return classOrder.indexOf(a) - classOrder.indexOf(b);
  };

  const getUniqueDocsClassifications = () => {
    if (!documents) return [];
    let classifications = documents.map((d) => d.type.sysName);
    classifications = [...new Set(classifications)]
      .filter((cl) => classOrder.includes(cl))
      .sort(classCompareFunc);
    return classifications;
  };

  const clearPasportConfidenceErros = (path) => {
    const clearValue = Object.keys(pasportConfidenceErros)
      .filter((_path) => _path !== path)
      .reduce((acc, key) => {
        acc[key] = pasportConfidenceErros[key];
        return acc;
      }, {});
    setPasportConfidenceErros(clearValue);
  };

  useEffect(() => {
    if (!uniqueClasses || uniqueClasses.length === 0) {
      setDocGridTemplate(docGridDefault);
    } else {
      let temp = "'. go go go go go go .'";

      let i = 0;
      let str = "'. ";
      for (let cl of uniqueClasses) {
        str += `${cl} ${cl} `;
        i++;
        if (i === 3) {
          i = 0;
          temp += str + ".'";
          str = "'. ";
        }
      }
      if (i === 0) temp += str + "empty_doc empty_doc . . . . .'";
      if (i === 1) temp += str + "empty_doc empty_doc . . .'";
      if (i === 2) temp += str + "empty_doc empty_doc .'";
      setDocGridTemplate(temp);
    }
  }, [uniqueClasses]);

  useEffect(() => {
    if (documents && doctypes) {
      setUniqueClasses(getUniqueDocsClassifications());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documents, doctypes]);

  return (
    <FormContainer id={id}>
      <Mgrid>
        <Title>Анкета и документы</Title>
        {isViewSelfAgreeCheckbox && (
          <SelfAgreeCheckbox
            label="Клиент со своим одобрением"
            onClick={(val) => {
              changeHandlerPersons({
                key: 'selfAgreement',
                values: val,
              });
              updateCollapsed(
                Object.keys(collapsed).reduce((obj, key) => ({ ...obj, [key]: false }), {})
              );
            }}
            checked={persons[currentPersonId] && persons[currentPersonId].selfAgreement}
            dataTest="hisApproval"
          />
        )}
      </Mgrid>
      <GridRowSubHeader>
        <FormSubheader id="upload" style={{ marginTop: '30px' }}>
          {(!uniqueClasses || uniqueClasses.length === 0) &&
          application?.applicationParams?.lastModifiedAt
            ? 'Загрузите документы'
            : 'Документы клиента'}
        </FormSubheader>
      </GridRowSubHeader>
      {/* <FileManagerStyled
        id="upload"
        defaultDoc={DOC_TEXT_CONF}
        exclude={['personal-data']}
        multiple
      /> */}
      <Docgrid style={{ gridTemplateAreas: docGridTemplate }}>
        <DocsHelp>
          Загрузите все страницы паспорта клиента, в качестве второго документа можно загрузить ВУ,
          СНИЛС, Заграничный паспорт или ИНН. Допустимые форматы файлов pdf, jpg, jpeg, png, объём -
          до 6 мб.
        </DocsHelp>
        {documents &&
          uniqueClasses &&
          uniqueClasses.map((cl, i) => {
            const classification = doctypes.filter((dt) => dt.sysName === cl)[0];
            if (!classification) return null;
            return (
              <FileUpload
                key={`${id}_fileupload_${cl}_${i}`}
                id={`${id}_fileupload_${cl}_${i}`}
                style={{ gridArea: cl, marginTop: '15px' }}
                title={
                  classification.value === 'Паспорт гражданина' ? (
                    <span>
                      Паспорт
                      <br />
                      гражданина
                    </span>
                  ) : (
                    classification.value
                  )
                }
                applicationId={appId}
                currentPersonId={currentPersonId}
                onLoadFiles={updateFiles}
                classification={classification}
                doctypes={doctypes}
                disabled={true}
                contentHidden={contentHidden}
              />
            );
          })}
        <FileUpload
          style={{ gridArea: 'empty_doc', marginTop: '15px' }}
          id={`${id}_fileupload_empty`}
          title={'Загрузить документы'}
          applicationId={appId}
          currentPersonId={currentPersonId}
          onLoadFiles={updateFiles}
          multiple={true}
          disabled={disabled || contentHidden}
        />
      </Docgrid>
      {service && (
        <Multidrive
          type="button-rounded"
          label="Мультидрайв"
          checked={insurance.common.multidrive}
          onClick={(bool) => changeHandlerInsur({ multidrive: bool }, 'common')}
          disabled={disabled}
        />
      )}
      <GridRowSubHeader>
        <FormSubheader id="personal-info">Личная информация</FormSubheader>
      </GridRowSubHeader>
      <PersonalForm
        onChange={changeHandlerPersons}
        anketaSave={anketaSave}
        setFocusAt={setFocusAt}
        persona={persons[currentPersonId]}
        params={params}
        errors={errors}
        confidenceErros={pasportConfidenceErros}
        clearConfidenceErros={clearPasportConfidenceErros}
        validate={validate || showErrors.some((key) => key === id) || forcedValidation.includes(id)}
        contentHidden={contentHidden}
        disabled={disabled}
      />
      <Anchor ref={refs.passport} />
      <PassportForm
        onChange={changeHandlerPersons}
        anketaSave={anketaSave}
        setFocusAt={setFocusAt}
        persona={persons[currentPersonId]}
        errors={errors}
        confidenceErros={pasportConfidenceErros}
        clearConfidenceErros={clearPasportConfidenceErros}
        validate={
          validate ||
          showErrors.some((key) => key === id || key === idPassport) ||
          forcedValidation.includes(id) ||
          forcedValidation.includes(idPassport)
        }
        contentHidden={contentHidden}
        disabled={disabled}
      />
      <SecondDocForm
        onChange={changeHandlerPersons}
        anketaSave={anketaSave}
        setFocusAt={setFocusAt}
        persona={persons[currentPersonId]}
        errors={errors}
        showDocTypeSelect={true}
        validate={
          validate ||
          showErrors.some((key) => key === idSecondDocument) ||
          forcedValidation.includes(idSecondDocument)
        }
        contentHidden={contentHidden}
        disabled={disabled}
      />
      <AddressForm
        onChange={changeHandlerPersons}
        anketaSave={anketaSave}
        setFocusAt={setFocusAt}
        persona={persons[currentPersonId]}
        errors={errors}
        confidenceErros={pasportConfidenceErros}
        clearConfidenceErros={clearPasportConfidenceErros}
        validate={
          validate ||
          showErrors.some(
            (key) =>
              key === idAddressReg || key === idAddressLive || key === idLivingAddressProperty
          ) ||
          forcedValidation.includes(idAddressReg) ||
          forcedValidation.includes(idAddressLive) ||
          forcedValidation.includes(idLivingAddressProperty)
        }
        disabled={disabled}
      />
      <TrusteesForm
        onChange={changeTrustHandler}
        onChangeSpouse={changeHandlerPersons}
        anketaSave={anketaSave}
        setFocusAt={setFocusAt}
        persona={persons[currentPersonId]}
        errors={errors}
        spouseForm={personalInfo?.familyState?.id === 77}
        validate={
          validate ||
          showErrors.some((key) => key === idTrustees || key === idSpouse) ||
          forcedValidation.includes(idTrustees) ||
          forcedValidation.includes(idSpouse)
        }
        contentHidden={contentHidden}
        disabled={disabled}
      />

      {children}
    </FormContainer>
  );
};
