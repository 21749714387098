export const financeInitialState = {
  profit: {
    mainprofit: '',
    addedprofit: '',
    spouseprofit: '',
    additionalprofit: '',
  },
  cost: {
    common: '0',
    requiredcost: '0',
    ipotekacost: '0',
    overduecost: '0',
  },
};

export const financeFromAPIToState = (customer) => {
  const finances = customer.finances;

  const incomesConfirmationType = finances.incomes.confirmationType;
  let profitProof = null;
  if (incomesConfirmationType) {
    profitProof =
      incomesConfirmationType.current ||
      incomesConfirmationType.advanced ||
      incomesConfirmationType.basicMonthlyIncomeSpouse ||
      incomesConfirmationType.additionalIncomeSpouse;

    if (profitProof) {
      profitProof = { id: profitProof, sysName: profitProof };
    }
  }

  return {
    profit: {
      mainprofit: finances.incomes.current,
      addedprofit: finances.incomes.advanced,
      spouseprofit: finances.incomes.basicMonthlyIncomeSpouse,
      additionalprofit: finances.incomes.additionalIncomeSpouse,
      profitproof: profitProof,
    },
    cost: {
      requiredcost: finances.expenses.requiredCreditSpendings,
      ipotekacost: finances.expenses.mortgagePaymentSpendings,
      overduecost: finances.expenses.anyCreditSpendings,
      common: finances.expenses.commonSpendings,
      credits: finances.expenses.creditsSpendings,
    },
  };
};

export const financeFromStateToAPI = (person) => {
  const { profit, cost } = person;
  const credits = ((parseFloat(profit.mainprofit) + parseFloat(profit.addedprofit)) / 2).toFixed(0);

  return {
    finances: {
      incomes: {
        current: profit.mainprofit || '',
        advanced: profit.addedprofit || '0',
        basicMonthlyIncomeSpouse: profit.spouseprofit || '',
        additionalIncomeSpouse: profit.additionalprofit || '',
        confirmationType: {
          current: profit.mainprofit && (profit?.profitproof?.sysName || ''),
          advanced: profit.addedprofit && (profit?.profitproof?.sysName || ''),
          basicMonthlyIncomeSpouse: profit.spouseprofit && (profit?.profitproof?.sysName || ''),
          additionalIncomeSpouse: profit.additionalprofit && (profit?.profitproof?.sysName || ''),
        },
      },
      expenses: {
        anyCreditSpendings: cost.overduecost || '',
        commonSpendings: cost.common || '',
        creditsSpendings: credits || '',
        mortgagePaymentSpendings: cost.ipotekacost || '',
        requiredCreditSpendings: cost.requiredcost || '',
      },
    },
  };
};
