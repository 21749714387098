import React, { useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CarForm from './components/CarForm';
import Owner from './components/Owner';
import Insurer from './components/Insurer';
import DriversForm from './components/Drivers';
import { Loader } from 'components/primitives/loader';
import ErrorPopup from 'components/primitives/error-popup';
/* https://tracker.yandex.ru/CREDIT-857
  import {
    schemaInsurance,
    schemaInsuranceIsNotDriver,
    schemaOnlyInsurer,
    schemaOnlyInsurerIsNotDriver,
  } from './vlidator/validations';
*/
import {
  schemaInsuranceNew,
  schemaInsuranceBeforeSave,
  schemaOnlyInsurerIsNotDriver,
  schemaInsuranceBeforeSaveIsNotDriver,
  schemaInsuranceBeforeSaveInsurerIsNotDriver,
} from './vlidator/validations';
import { collectObjectedErrors, correctDriverErrorKey } from './vlidator/vlidator';
import {
  insAnketaDropAllErrors,
  insAnketaSetErrors,
  insAnketaGetCustomersId,
  insAnketaSetNetworkError,
  insAnketaSetError,
  insAnketaDeleteError,
  insAnketaScrollToCar,
  insAnketaClearScroll,
  insAnketaChangeByKey,
  insAnketaSwitchCalcOption,
  insAnketaSetCustomerShort,
  insAnketaSetEnabled,
  insAnketaSetDisabled,
  insAnketaInsurerSetError,
  insAnketaInsurerDeleteError,
  insAnketaInsurerSetErrors,
} from 'store/insuranceAnketa';
import {
  createNewInsuranceOrder,
  eowRecalcRecustomers,
  insuranceGetPrelist,
} from 'store/insuranceCalculation';
import {
  setCarInstance,
  updateErrors,
  getCarInstanceById,
  enableCarInstance,
  disableCarInstance,
  setCarRequiredFields,
  setCarHiddenFields,
  scrollTo,
  openCarInstanceDetails,
} from 'store/carInstance';
import { FIELDS } from 'store/carInstance/constants';
import { getProfile } from 'store/anketa';
import { GLOBAL_ANKETA_CALC_OPTIONS } from 'constants/index';
import { EOW_STATES } from 'store/insuranceCalculation/resources';
import { usePrevious } from 'hooks/usePrevious';

import * as ValidationHelper from './vlidator/vlidator';
import * as PositionHelper from 'utils/documentPosition';
import * as ScrollHelper from './helpers/scrollHelper';
import { isNeedDisableEOWFields } from '../Insurance/helper';
import { createProductData } from '../AssistanceAnketa/helpers/helper';
import * as S from './style';

const fn = () => {};

const carRequiredFields = [
  FIELDS.brand,
  FIELDS.model,
  FIELDS.year,
  FIELDS.price,
  FIELDS.enginePower,
  FIELDS.pts.number,
  FIELDS.pts.issueDate,
  FIELDS.mileage,
];

const InsuranceAnketa = ({ id, validateCarDetail = fn }) => {
  const rootDispatch = useDispatch();
  const history = useHistory();
  const params = useParams();

  const isCashCustomer = window.location.href.includes('/insurance/anketa');

  const { carInstance } = useSelector((state) => state.carInstance);
  const carInstanceId = carInstance?.id;
  const dealerId = useSelector((state) => state.user.dealer);
  const { id: customerId } = useSelector(({ startPage }) => startPage?.customer || {});
  const insuranceCalculation = useSelector((state) => state.insuranceCalculation);
  const storedInsuranceAnketa = useSelector((state) => state.insuranceAnketa);
  const creditId = useSelector((state) => state.startPage?.application?.ids?.credit);
  const orders = useSelector((state) => state.startPage?.application?.orders);
  const insuranceOrders = useMemo(() => {
    return (orders || []).filter((order) => order.productId === 'insurance');
  }, [orders]);
  const anketaCustomerId = useSelector(
    (store) => store.anketa?.application?.generalCustomer?.customer?.id
  );
  const persons = useSelector((state) => state.anketa.persons);

  const { applicationId, applications, fetchPrelist, workSheetId, isInCredit, selectedBank } =
    insuranceCalculation;

  const prevApplicationId = usePrevious(applicationId);
  const hasInsuranceOrder = insuranceOrders.length;
  const hasInsuranceApplication = Object.keys(applications).length;
  const isCreatingNewWorksheet =
    params.id === 'create-new' || (!hasInsuranceOrder && !hasInsuranceApplication);

  const {
    drivers,
    customersFetch,
    currentSavedData,
    saveFetch,
    mainFetch,
    networkError,
    errors,
    isDriver,
    isInsurer,
    kaskoInsurerIsDriver,
    calcOption,
  } = storedInsuranceAnketa;

  const carDetailValidateHiddenFields = useMemo(() => {
    const fields = [];
    if (calcOption === GLOBAL_ANKETA_CALC_OPTIONS[1]) {
      fields.push(FIELDS.vin);
      fields.push(FIELDS.pts.number);
      fields.push(FIELDS.pts.issueDate);
    }
    return fields;
  }, [calcOption]);

  useEffect(() => {
    PositionHelper.scrollTo(0, 0);
    rootDispatch(insAnketaSwitchCalcOption(GLOBAL_ANKETA_CALC_OPTIONS[0]));
    rootDispatch(setCarRequiredFields(carRequiredFields));
    if (carInstance.steeringWheel === 'right') {
      rootDispatch(openCarInstanceDetails());
    }

    const insuranceApplicationId = insuranceOrders[0]?.body?.applicationId;
    const assistanceApplicationId = (orders || []).find((order) => order.productId === 'assistance')
      ?.body?.applicationId;

    const { applicationId, product } = createProductData(
      assistanceApplicationId,
      insuranceApplicationId,
      creditId
    );

    if (applicationId && product) {
      rootDispatch(insAnketaGetCustomersId({ applicationId, product }));
    }

    return () => {
      rootDispatch(setCarRequiredFields([]));
      rootDispatch(setCarHiddenFields([]));
      rootDispatch(enableCarInstance());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fields = [
      FIELDS.permittedMaxWeight,
      FIELDS.engineVolume,
      FIELDS.glonasNumber,
      FIELDS.offerPrice,
    ];
    if (calcOption === GLOBAL_ANKETA_CALC_OPTIONS[1]) {
      fields.push(FIELDS.vin);
      fields.push(FIELDS.pts);
    }
    rootDispatch(setCarHiddenFields(fields));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calcOption]);

  useEffect(() => {
    if (creditId) {
      rootDispatch(getProfile(creditId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creditId]);

  useEffect(() => {
    if (prevApplicationId && applicationId && isCashCustomer) {
      rootDispatch(insuranceGetPrelist({ applicationId }));
    }

    if (applicationId) {
      // Запускает сагу, запрашивающую кастомеров, из ответа получает данные, заполняющие анкету
      rootDispatch(
        insAnketaGetCustomersId({ applicationId, shouldCombineData: !insuranceOrders.length })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationId]);

  useEffect(() => {
    if (anketaCustomerId && !applicationId) {
      rootDispatch(insAnketaSetCustomerShort(anketaCustomerId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anketaCustomerId, applicationId]);

  useEffect(() => {
    if (Object.keys(applications ?? {}).length) {
      const isNeedDisableInsuranceAnketa = isNeedDisableEOWFields(Object.values(applications));

      rootDispatch(isNeedDisableInsuranceAnketa ? insAnketaSetDisabled() : insAnketaSetEnabled());
      rootDispatch(isNeedDisableInsuranceAnketa ? disableCarInstance() : enableCarInstance());
      return;
    }

    const isNeedDisableInsuranceAnketa = insuranceOrders.some(
      (order) => !EOW_STATES.enabledCarInstanceAnketa.has(order.body.applicationState.sysName)
    );

    rootDispatch(isNeedDisableInsuranceAnketa ? insAnketaSetDisabled() : insAnketaSetEnabled());
    rootDispatch(isNeedDisableInsuranceAnketa ? disableCarInstance() : enableCarInstance());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insuranceOrders, storedInsuranceAnketa.isDisabled]);

  // only for new route
  const leaveAnketa = (id) => {
    const _carInstanceId = carInstanceId || localStorage.getItem('carinstanceId');

    if (_carInstanceId) {
      rootDispatch(getCarInstanceById(_carInstanceId));
    }

    history.push(`/worksheet/${id || workSheetId || params.id}/insurance`);
  };

  const validateCustomer = async ({ value, state }, isOwner) => {
    const key = Object.keys(value)[0];

    const dropError = () => {
      const targetAction = isOwner ? insAnketaDeleteError : insAnketaInsurerDeleteError;
      rootDispatch(targetAction({ key }));
    };

    const setError = (currentErrors) => {
      const targetAction = isOwner ? insAnketaSetError : insAnketaInsurerSetError;
      rootDispatch(targetAction({ key, value: currentErrors[key] }));
    };

    const schemaInsurer =
      (isOwner && isDriver) || (!isOwner && kaskoInsurerIsDriver)
        ? schemaInsuranceNew
        : schemaOnlyInsurerIsNotDriver;

    dropError();
    return ValidationHelper.validate(schemaInsurer, state, dropError, setError);
  };

  const scrollHandler = () => {
    rootDispatch(insAnketaClearScroll());
  };

  const validateBeforeSave = async () => {
    rootDispatch(insAnketaDropAllErrors());
    rootDispatch(updateErrors({}));
    try {
      const schemaOwner =
        isDriver && !drivers.length
          ? schemaInsuranceBeforeSave
          : schemaInsuranceBeforeSaveIsNotDriver;

      await schemaOwner.validate(
        {
          anketa: {
            ...storedInsuranceAnketa,
            drivers: storedInsuranceAnketa.drivers.filter((d) => !d.isDeleted),
          },
          calculation: insuranceCalculation,
        },
        { abortEarly: false }
      );
    } catch (err) {
      const objectedErrors = correctDriverErrorKey(drivers, collectObjectedErrors(err));
      const result = {
        ...(objectedErrors?.anketa || {}),
        ...(objectedErrors?.calculation || {}),
      };

      rootDispatch(insAnketaSetErrors({ errors: result }));
      rootDispatch(updateErrors({ selectedBank: objectedErrors?.calculation?.selectedBank }));

      return result;
    }
    if (!isInsurer) {
      const schemaInsurer = kaskoInsurerIsDriver
        ? schemaInsuranceBeforeSave
        : schemaInsuranceBeforeSaveInsurerIsNotDriver;

      try {
        await schemaInsurer.validate(
          {
            anketa: storedInsuranceAnketa.kaskoInsurer,
            calculation: insuranceCalculation,
          },
          { abortEarly: false }
        );
      } catch (err) {
        const objectedErrors = collectObjectedErrors(err);
        const result = {
          ...errors,
          ...(objectedErrors?.anketa || {}),
          ...(objectedErrors?.calculation || {}),
        };

        rootDispatch(insAnketaInsurerSetErrors({ errors: result }));
        rootDispatch(updateErrors({ selectedBank: objectedErrors?.calculation?.selectedBank }));

        return result;
      }
    }
    return null;
  };

  const getCalculationHandler = async () => {
    const isValid = await validateCarDetail(carInstance, fn, [], carDetailValidateHiddenFields);

    const errorObject = await validateBeforeSave();
    const driversIds = drivers.map((driver) => driver.id);
    const blockName = ScrollHelper.getBlockNameByErrors(errorObject, driversIds, []);

    if (!isValid) {
      return;
    }

    if (storedInsuranceAnketa.isDisabled) {
      // Редирект на расчёт, если анкета заблокирована
      history.push(`/worksheet/${workSheetId}/insurance`);
      return;
    }

    if (blockName) {
      rootDispatch(insAnketaScrollToCar(blockName));
      return;
    } else if (errorObject?.selectedBank) {
      rootDispatch(scrollTo('main'));
      return;
    }

    if (errorObject || blockName || !id) {
      return;
    }

    if (!isCreatingNewWorksheet) {
      // Перерасчёт и патч кастомеров, если воркшит уже создан
      rootDispatch(
        eowRecalcRecustomers({
          bankId: selectedBank?.id ?? null,
          isInCredit,
          workSheetId: params.id,
          callback: (workSheetId) => {
            history.push(`/worksheet/${workSheetId}/insurance`);
          },
        })
      );
      return;
    }

    // Полный путь создания воркшита и прочее
    rootDispatch(
      createNewInsuranceOrder({
        carInstanceId,
        dealerId,
        customerId,
        bankId: selectedBank?.id ?? null,
        isInCredit,
        withPatchCustomers: true,
        callback: (workSheetId) => {
          history.push(`/worksheet/${workSheetId}/insurance`);
        },
      })
    );
  };

  const returnSavedDriverLicense = () => {
    const keys = ['licenseSeriaNum', 'licenseDate', 'prevLicenseSeriaNum', 'prevLicenseDate'];

    keys.forEach((key) => {
      if (currentSavedData) {
        rootDispatch(insAnketaChangeByKey({ key, value: currentSavedData[key] }));
      }
      rootDispatch(insAnketaDeleteError({ key }));
    });

    rootDispatch(
      insAnketaChangeByKey({
        key: 'isFirstLicense',
        value: !(currentSavedData?.prevLicenseSeriaNum || currentSavedData?.prevLicenseDate),
      })
    );
  };

  const clearDriverLicense = () => {
    rootDispatch(insAnketaChangeByKey({ key: 'prevLicenseSeriaNum', value: '' }));
    rootDispatch(insAnketaChangeByKey({ key: 'prevLicenseDate', value: '' }));
    rootDispatch(insAnketaDeleteError({ key: 'prevLicenseSeriaNum' }));
    rootDispatch(insAnketaDeleteError({ key: 'prevLicenseDate' }));
  };

  const errorCloseHandler = () => {
    rootDispatch(insAnketaSetNetworkError());
  };

  const closeHandler = () => {
    if (isCashCustomer) {
      return leaveAnketa();
    }

    if (workSheetId) {
      history.push(`/worksheet/${workSheetId}/insurance`);
    }
  };

  if (fetchPrelist === 'pending' || customersFetch === 'pending' || saveFetch || mainFetch) {
    return <Loader show={true} color="white" />;
  }

  return (
    <S.Wrapper>
      <S.CloseButton onClick={closeHandler} />
      <CarForm
        id={id}
        isFullCalc={calcOption === GLOBAL_ANKETA_CALC_OPTIONS[0]}
        onClickFullCalc={({ fullCalculation }) => {
          const itemSwitch = GLOBAL_ANKETA_CALC_OPTIONS[Number(!fullCalculation)];

          rootDispatch(insAnketaSwitchCalcOption(itemSwitch));

          if (itemSwitch === GLOBAL_ANKETA_CALC_OPTIONS[1]) {
            rootDispatch(
              setCarInstance({
                pts: { ...carInstance.pts },
                vin: carInstance.vin,
              })
            );
            rootDispatch(
              updateErrors({
                pts: {
                  series: '',
                  number: '',
                  issueDate: '',
                },
                vin: '',
              })
            );
          }
        }}
      />

      <Owner
        anketaState={storedInsuranceAnketa}
        onScroll={scrollHandler}
        validate={validateCustomer}
        clearDriverLicense={clearDriverLicense}
        returnSavedDriverLicense={returnSavedDriverLicense}
      />

      {!isInsurer && (
        <Insurer
          anketaState={storedInsuranceAnketa}
          onScroll={scrollHandler}
          validate={validateCustomer}
          clearDriverLicense={clearDriverLicense}
          returnSavedDriverLicense={returnSavedDriverLicense}
        />
      )}

      <DriversForm
        anketaState={storedInsuranceAnketa}
        persons={persons}
        onScroll={scrollHandler}
        validateInsurer={validateCustomer}
        returnSavedDriverLicense={returnSavedDriverLicense}
      />

      <S.SubmitButtonsWrapper>
        <S.BlankWidthSpace />
        <S.SubmitButton onClick={getCalculationHandler}>Оформить</S.SubmitButton>
      </S.SubmitButtonsWrapper>
      {!!networkError && <ErrorPopup errorMessage={networkError} onClose={errorCloseHandler} />}
    </S.Wrapper>
  );
};

export default InsuranceAnketa;
