import React from 'react';
import { useSelector } from 'react-redux';
import { UnknownCarPng } from 'assets/img';
import { CarImg } from '../style';

export const CarImage = () => {
  const { carInstance } = useSelector((state) => state.carInstance);

  const imageSrc = carInstance?.image || UnknownCarPng;

  return (
    <>
      <CarImg src={imageSrc} alt="car image" />
    </>
  );
};
