import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Wrapper, Tab, Header, Content } from './style';

export const Tabs = ({ items, activeIndex, className, onChange, style }) => {
  const [currentTabIndex, setCurrentTabIndex] = useState(activeIndex);

  const handleChange = (index) => {
    onChange(index);
    setCurrentTabIndex(index);
  };

  useEffect(() => {
    setCurrentTabIndex(activeIndex);
  }, [activeIndex]);

  return (
    <Wrapper className={className} style={style}>
      <Header>
        {items &&
          items.map((child, index) => (
            <Tab
              className={currentTabIndex === index ? 'active' : ''}
              key={`tab_${index + 1}`}
              active={currentTabIndex === index}
              onClick={() => handleChange(index)}
            >
              {child.header}
            </Tab>
          ))}
      </Header>
      {items &&
        items.map((child, index) => (
          <Content key={`tab_content_${index + 1}`} active={currentTabIndex === index}>
            {child.content}
          </Content>
        ))}
    </Wrapper>
  );
};

Tabs.propTypes = {
  items: PropTypes.array,
  className: PropTypes.string,
  active: PropTypes.number,
  onChange: PropTypes.func,
};

Tabs.defaultProps = {
  items: null,
  className: '',
  active: 0,
  onChange: () => {},
};
