import React from 'react';

import { InView } from 'components/common/InView';
import { Loader } from 'components/primitives/loader';

import { getViewCheckerPage, isShowViewChecker } from './helpers';

import { CheckIco } from '../../style';
import * as S from './style';

const cutStr = (str) => {
  const cut = str.slice(0);

  return {
    equal: str === cut,
    str: str === cut ? str : `${cut}...`,
  };
};

const ListContent = ({
  error,
  isLoading,
  numberDealer,
  hoverDealer,
  arraySearchData,
  getSearchResult,
  stringSearch,
  setNumberCurrentPage,
  mouseEnterHandlerCreator = () => {},
  clickHandlerCreator = () => {},
}) => {
  if (error.isError) {
    return <S.ErrorBlock>{`Error: ${error.stringMessage}`}</S.ErrorBlock>;
  }

  if (isLoading) {
    return <Loader show={true} color="white" />;
  }
  const nextPageHandler = (data) => {
    if (data.page <= 1) {
      return;
    }

    setNumberCurrentPage((prev) => {
      if (data.page <= prev) {
        return prev;
      }

      getSearchResult(stringSearch, data.page);
      return data.page;
    });
  };

  return (
    <>
      {arraySearchData.map((dealer, i) => {
        const cutObj = cutStr(dealer.name);

        return (
          <S.ListItem
            selected={dealer.id === numberDealer}
            withFullText={hoverDealer && hoverDealer.id === dealer.id && !cutObj.equal}
            onClick={clickHandlerCreator(dealer)}
            key={dealer.id}
            onMouseEnter={mouseEnterHandlerCreator(dealer)}
            title={dealer.name}
          >
            <S.DealerName>{cutObj.str}</S.DealerName>
            <CheckIco checked={dealer.id === numberDealer} />
            {isShowViewChecker(i) && (
              <InView data={{ page: getViewCheckerPage(i) }} onShow={nextPageHandler} />
            )}
          </S.ListItem>
        );
      })}
    </>
  );
};

export default ListContent;
