import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, useParams, useRouteMatch } from 'react-router-dom';
import { ApplicationLayout } from 'components/modules/ApplicationLayout';
import { ApplicationClientLayout } from 'components/modules/ApplicationClientLayout';
import { uuidObject } from 'utils/uuid';
import EPointsPopup from '../../common/EPointsPopup';
import * as ePoints from 'store/ePoints';

export const Application = () => {
  const rootDispatch = useDispatch();
  const params = useParams();
  const { path } = useRouteMatch();
  const showEPointsPopup = useSelector((store) => store.ePoints.showEPointsPopup);
  const dealers = useSelector((store) => store.user.dealers);
  const [isCreateNewPage, setIsCreateNewPage] = useState(path.includes('/worksheet/create-new'));
  // eslint-disable-next-line no-unused-vars
  const [sessionId, setSessionId] = useState();
  const [firstTime, setFirstTime] = useState(true);

  useEffect(
    () => () => {
      uuidObject.set();
    },
    []
  );

  useEffect(() => {
    if (params?.id !== sessionId) {
      setSessionId(params?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.id]);

  useEffect(() => {
    if (isCreateNewPage) {
      uuidObject.set();
    }
  }, [isCreateNewPage]);

  useEffect(() => {
    if (sessionId && !isCreateNewPage) {
      uuidObject.set();
      setIsCreateNewPage(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId]);

  useEffect(() => {
    if (firstTime && dealers && dealers.length > 0) {
      setFirstTime(false);
      rootDispatch(ePoints.loadEPointsPrograms());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealers]);

  return (
    <>
      <Switch>
        <Route path={'/worksheet/:id/credit/anketa'} exact>
          <ApplicationLayout />
        </Route>
        <Route
          path={[
            '/worksheet/create-new/calculator',
            '/worksheet/create-new/osago',
            '/worksheet/:id/home',
            '/worksheet/:id/calculator',
            '/worksheet/:id/loan-issue',
            '/worksheet/:id/assistance',
            '/worksheet/:id/insurance',
            '/worksheet/:id',
          ]}
        >
          <ApplicationClientLayout />
        </Route>
      </Switch>
      {showEPointsPopup && <EPointsPopup />}
    </>
  );
};
