export const DRIVER_KEY_MARK = 'driver@';
export const MAX_DRIVERS = 4;
export const DRIVERS_OVERFLOW_ERR_MESSAGE = `Невозможно добавить больше ${MAX_DRIVERS}-х водителей`;

export const PERSONAL_INFO = {
  name: 'personal_info',
  fields: ['fio', 'bday'],
};

export const CONTACTS = {
  name: 'contacts',
  fields: ['insurerPhone', 'insurerEmail'],
};

export const PASSPORT = {
  name: 'passport',
  fields: ['insurerPassportSerialNum', 'insurerPassportDate', 'insurerIssuerCode', 'insurerIssuer'],
};

export const ADDRESS = {
  name: 'address',
  fields: ['insurerIndex', 'insurerAddress'],
};

export const DRIVER_LICENSE = {
  name: 'driver_license',
  fields: ['licenseSeriaNum', 'licenseDate', 'prevLicenseSeriaNum', 'prevLicenseDate'],
};

export const INSURER = {
  name: 'insurer',
  fields: [PERSONAL_INFO, CONTACTS, PASSPORT, ADDRESS, DRIVER_LICENSE],
};

export const DRIVER = {
  name: DRIVER_KEY_MARK,
  fields: [PERSONAL_INFO, DRIVER_LICENSE],
};
