import styled from 'styled-components';
import { PALETTE } from 'styles/constants';

import { BellSvg } from 'assets/img';
import Counter from 'components/primitives/counter';
export const Top = styled.div`
  display: flex;
  justify-content: space-between;
  b {
    display: block;
    max-width: 140px;
    color: ${PALETTE.black};
  }
`;

export const NotificationsBell = styled.div`
  position: relative;
`;

export const BellIconStyled = styled(BellSvg)`
  box-sizing: content-box;
  margin-top: -10px;
  padding: 10px;
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
`;

export const NotificationsCount = styled(Counter)`
  position: absolute;
  right: 4px;
  top: -4px;
  z-index: 2;
  border: 1px solid ${PALETTE.white};
  font-size: 10px;
`;

export const Image = styled.img`
  width: 100%;
  margin-bottom: 25px;
`;

export const Content = styled.div`
  margin-bottom: 25px;

  p {
    display: block;
    margin-top: 5px;
    margin-bottom: 0;
    font-size: 12px;
    color: ${PALETTE.grey1};
    line-height: 18px;
  }
`;
