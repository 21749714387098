import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { genderOptions } from './resources';

import * as S from './style';

export const GenderSelector = ({
  disabled = false,
  defaultValue = 0,
  onChange = () => {},
  value,
}) => {
  const [isShowGenderRadio, toggleRadioGroup] = useState(true);

  const toggleTitle = isShowGenderRadio ? 'Скрыть' : 'Подробнее';

  const checked = value ?? defaultValue;

  const clickHandler = ({ gender }) => {
    if (!disabled) {
      onChange({ gender });
    }
  };

  return (
    <S.GenderRadioContainer>
      {isShowGenderRadio && (
        <S.GenderRadioGroup
          fixed={disabled}
          name="gender"
          items={genderOptions}
          checked={checked}
          onClick={clickHandler}
        />
      )}

      <S.ToggleGenderRadioGroup onClick={() => toggleRadioGroup(!isShowGenderRadio)}>
        {toggleTitle}
      </S.ToggleGenderRadioGroup>
    </S.GenderRadioContainer>
  );
};

GenderSelector.propTypes = {
  disabled: PropTypes.bool,
  defaultValue: PropTypes.number,
  onChange: PropTypes.func,
  value: PropTypes.number,
};
