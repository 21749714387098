import React, { useState } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';

import { SEARCH } from 'constants/index';
import { FilterGreySvg } from 'assets/img';

import { Dealer } from './components/Dealer';
import { Kso } from './components/Kso';
import { Mop } from './components/Mop';

import * as S from './style';

export const Filters = () => {
  const { url } = useRouteMatch();
  const { pathname } = useLocation();
  const [isShowFilters, setIsShowFilters] = useState(false);

  if (pathname === `${url}/${SEARCH}`) {
    return null;
  }

  const toggleFilterHandler = () => {
    setIsShowFilters((prev) => !prev);
  };

  return (
    <S.Container>
      <Dealer isVisible={isShowFilters} />
      <Kso isVisible={isShowFilters} />
      <Mop isVisible={isShowFilters} />

      <S.FilterButton isActive={isShowFilters} onClick={toggleFilterHandler}>
        <FilterGreySvg />
      </S.FilterButton>
    </S.Container>
  );
};
