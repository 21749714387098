import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';

import { Loader } from 'components/primitives/loader';
import { loadDeals, dropDeals } from 'store/deals';
import { PALETTE } from 'styles/constants';

import * as help from '../../helpers';
import { DealsListContainer, DealsWrapper } from './style';
import DealsStatusList from './DealsStatusList';
import { DEALS_STATUS } from './constants';

const DealsList = ({ application }) => {
  const rootDispatch = useDispatch();

  const { id: applicId, carInstance, lastState } = application;
  const applicationId = application?.orders[0].body.applicationId;

  const deals = useSelector((store) => store.deals.dealsStatus[applicId] || {}, shallowEqual);
  const pages = useSelector((store) => store.deals.pages[applicId]);
  const isShowSendButton = lastState.id > 2 && Object.keys(deals).includes('new');
  const [isOpen, setOpen] = useState(false);

  const dealsLength = Object.values(deals).flat().length;

  useEffect(() => {
    setTimeout(() => {
      setOpen(true);
    }, 500);

    return () => {
      rootDispatch(dropDeals({ applicId }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchMoreData = () => {
    if (deals.length < pages.total) {
      let page = 1;
      if (pages) {
        page = pages.page;
      }
      rootDispatch(
        loadDeals({ id: application.id, applicId: application?.ids?.credit, page: page + 1 })
      );
    }
  };
  const isLoaded = deals.length === 0 ? true : false;
  const loadingLabel = pages && deals.length < pages.total ? 'Загрузка...' : '';

  if (!isOpen) {
    return null;
  }

  return (
    <DealsWrapper>
      <DealsListContainer id={`deals-list${applicId}`}>
        <InfiniteScroll
          dataLength={dealsLength}
          next={fetchMoreData}
          hasMore={true}
          loader={<h4>{loadingLabel}</h4>}
          scrollableTarget={`deals-list${applicId}`}
        >
          <DealsStatusList
            status={DEALS_STATUS.APPROVED}
            dealsList={help.getApprovedBanks(deals)}
            color={PALETTE.green1}
            applicationId={applicationId}
            carInstance={carInstance}
          />
          <DealsStatusList
            status={DEALS_STATUS.REQUESTED}
            dealsList={help.getRequestedBanks(deals)}
            color={PALETTE.blue7}
            carInstance={carInstance}
          />
          <DealsStatusList
            status={DEALS_STATUS.QUEUE}
            dealsList={help.getQueueBanks(deals)}
            color={PALETTE.yellow1}
            carInstance={carInstance}
          />
          <DealsStatusList
            status={DEALS_STATUS.DECLINE}
            dealsList={help.getDeclinedBanks(deals)}
            color={PALETTE.red1}
            carInstance={carInstance}
          />
          <DealsStatusList
            status={DEALS_STATUS.OPENED}
            isShowSendButton={isShowSendButton}
            dealsList={help.getOpenedBanks(deals)}
            color={PALETTE.white}
            carInstance={carInstance}
          />
        </InfiniteScroll>
      </DealsListContainer>
      <Loader show={isLoaded} color="white" />
    </DealsWrapper>
  );
};

export default React.memo(DealsList);
