import styled from 'styled-components';
import { PALETTE } from 'styles/constants';

export const Cell = styled.input`
  height: 56px;
  text-align: center;
  width: 44px;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: ${(p) => (p.disabled ? PALETTE.grey3 : p.warning ? PALETTE.red1 : PALETTE.grey2)};
  background-color: ${(p) => (p.disabled ? PALETTE.grey3 : PALETTE.white)};
  cursor: ${(p) => (p.disabled ? 'not-allowed' : 'text')} !important;
  color: ${(p) => (p.warning ? PALETTE.red1 : PALETTE.dark)};

  & + & {
    margin-left: 10px;
  }
  &:focus {
    border-color: ${(p) => (p.disabled ? PALETTE.grey3 : p.warning ? PALETTE.red1 : PALETTE.grey5)};
  }
`;

export const Wrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: stretch;
  font-size: 20px;
  font-weight: 500;
`;
