import * as block from './block/style';
import * as typography from './typography/style';
import * as form from './form/style';

const content = {
  ...block,
  ...typography,
  ...form,
};

export default content;

export const Block = content.Block;
export const SubBlock = content.SubBlock;
export const BorderBlock = content.BorderBlock;
export const BkgBlock = content.BkgBlock;
export const Row = content.Row;
export const Hr = content.Hr;
export const List = content.List;
export const ListItem = content.ListItem;

export const Title1 = content.Title1;
export const Title2 = content.Title2;
export const Title3 = content.Title3;
export const Paragraph = content.Paragraph;
export const Description = content.Description;
export const Link = content.Link;

export const Form = content.Form;
export const ButtonRow = content.ButtonRow;
export const Input = content.Input;
export const Mask = content.Mask;
export const Date = content.Date;
export const Select = content.Select;
export const SelectPortal = content.SelectPortal;
export const CheckBox = content.CheckBox;
export const RadionButton = content.RadionButton;
export const Button = content.Button;
export const BlueButton = content.BlueButton;
export const LightBlueButton = content.LightBlueButton;
export const GreenButton = content.GreenButton;
export const RedButton = content.RedButton;
export const DarkRedButton = content.DarkRedButton;
export const LightButton = content.LightButton;
