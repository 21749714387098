import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { changeActiveDealer, changeActiveRole } from 'store/user';
import { setFilter } from 'store/filters';
import * as metrica from 'metrika/mainpage';

import DealersSearchBlock from './DealersSearchBlock';

import * as S from '../style';

export const UserLink = ({ setShowInfo }) => {
  const history = useHistory();
  const { roles, role, dealer: numberDealer } = useSelector((state) => state.user);

  const dealersOptions = useSelector((store) => store.applicFilters?.dealer || []);
  const ksoOptions = useSelector((store) => store.applicFilters?.ks || []);
  const mopOptions = useSelector((store) => store.applicFilters?.mop || []);
  const defaultDealer = useSelector((store) => store.user.dealer);
  const [roleName, setRoleName] = useState('');

  const dispatch = useDispatch();

  const resetKsoAndMop = () => {
    const newKsoOptions = ksoOptions
      .filter((item) => !!item?.id)
      .map((item) => ({ ...item, checked: false }));

    const newMopOptions = mopOptions
      .filter((item) => !!item?.id)
      .map((item) => ({ ...item, checked: false }));

    dispatch(setFilter({ filter: 'ks', value: newKsoOptions }));
    dispatch(setFilter({ filter: 'mop', value: newMopOptions }));
  };

  const clickDealerHandler = (item) => {
    const options = dealersOptions
      .filter((d) => !!d?.id)
      .map((d) => ({ ...d, checked: String(d.id) === String(item?.id) }));

    dispatch(changeActiveDealer(item.id));
    dispatch(setFilter({ filter: 'dealer', value: options }));
    resetKsoAndMop();

    localStorage.removeItem('worksheet');
    localStorage.removeItem('carinstanceId');
    localStorage.removeItem('customerId');

    metrica.choseUserTrack();

    history.push('/start');
  };

  const clickRolekHandler = (item) => {
    const options = dealersOptions
      .filter((d) => !!d?.id)
      .map((d) => ({ ...d, checked: String(d.id) === String(defaultDealer) }));

    dispatch(changeActiveRole(item.id));
    dispatch(setFilter({ filter: 'dealer', value: options }));
    resetKsoAndMop();

    localStorage.removeItem('worksheet');
    localStorage.removeItem('carinstanceId');
    localStorage.removeItem('customerId');

    metrica.choseUserTrack();

    history.push('/start');
  };

  const getList = (data, onClick) =>
    data.map((item) => (
      <>
        <S.StyledLi key={item.id} onClick={() => onClick(item)} checked={item?.name === roleName}>
          {item.name}
          <S.CheckIco checked={item?.name === roleName} />
        </S.StyledLi>
      </>
    ));

  useEffect(() => {
    if (roles && roles.length > 0) {
      const matchedRole = roles.find((r) => role === r.id);
      if (matchedRole) {
        setRoleName(matchedRole.name);
      } else {
        setRoleName('');
      }
    }
  }, [role, roles]);

  const rolesList = getList(roles, clickRolekHandler);

  return (
    <S.UserLinks>
      <S.InfoWrapper>
        <h5>Роль</h5>
        <ul>{rolesList}</ul>
        <h5>Дилеры</h5>
        <DealersSearchBlock
          numberDealer={Number(numberDealer)}
          clickDealerHandler={clickDealerHandler}
        />
      </S.InfoWrapper>
      <S.BackButton
        onClick={(e) => {
          e.stopPropagation();
          setShowInfo(false);
        }}
      >
        <S.StyledArrowLeft alt={'img :('} />
        Назад
      </S.BackButton>
    </S.UserLinks>
  );
};
