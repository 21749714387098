const DEFAULT_USER_PHONE = '+7 (___) __-__-__';
const DEFAULT_NAME = '';

export const getUserPhone = (contacts) => contacts?.mobilePhone || DEFAULT_USER_PHONE;

export const getUserName = (personalInformation) => {
  if (!personalInformation) {
    return DEFAULT_NAME;
  }

  return `${personalInformation.surname || ''} ${personalInformation.name || ''}`;
};

export const CustomerProvider = (data) => {
  const phone = getUserPhone(data?.contacts);
  const name = getUserName(data?.personalInformation);
  const isPhone = phone !== DEFAULT_USER_PHONE;
  const isActive = name !== DEFAULT_NAME;

  const customerInfo = {
    id: data?.id,
    alt: name,
    name,
    phone,
    customerId: undefined,
    applicationId: undefined,
    isActive,
    isPhone,
  };

  return customerInfo;
};
