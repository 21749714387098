import React from 'react';
import Contacts from './components/Contacts';
import Passport from './components/Passport';
import Address from './components/Address';
import { DriverLicense } from '../Driver/components/DriversLicense';
import { InsurerDriverSwitcher } from './components/OwnerDriverSwitcher';
import { PersonalInfo } from '../PersonalInfo';

import * as Form from '../Form/style';

const fn = () => {};

const Insurer = ({
  anketaState,
  scrollTo,
  onSwitchInsurerIsDriver = fn,
  onSwitchDriverLicense = fn,
  onBlur = fn,
  onScroll = fn,
}) => {
  const innerProps = {
    data: anketaState?.isInsurer ? anketaState : anketaState.kaskoInsurer,
    scrollTo,
    errors: anketaState?.isInsurer ? anketaState.errors : anketaState.kaskoInsurer?.errors,
    disabled: anketaState.isDisabled,
    onBlur,
    onScroll,
  };

  if (anketaState?.kaskoInsurerIsDriver) {
    innerProps.data.isFirstLicense = anketaState.isFirstLicense;
  }

  return (
    <Form.BlockWrapper>
      <Form.Header>Страхователь</Form.Header>

      <InsurerDriverSwitcher
        onSwitch={onSwitchInsurerIsDriver}
        disabled={anketaState.isDisabled}
        checked={anketaState.kaskoInsurerIsDriver}
        showDriverCheckbox={!anketaState.isInsurer}
      />

      <PersonalInfo {...innerProps} title="Личная информация" />
      <Contacts {...innerProps} />
      <Passport {...innerProps} />
      <Address {...innerProps} />

      {anketaState.kaskoInsurerIsDriver && (
        <DriverLicense
          driver={innerProps.data}
          disabled={innerProps.disabled}
          errors={innerProps.errors}
          onBlur={onBlur}
          onSwitchCheckbox={onSwitchDriverLicense}
        />
      )}
    </Form.BlockWrapper>
  );
};

export default Insurer;
