import * as actions from './constants';

const initialState = {
  clientId: undefined,
  loanLoaded: false,
  error: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.LOAN_CREATED: {
      return { ...state, loanLoaded: false, applicationId: action.payload };
    }

    case actions.LOAN_SET_LOADER: {
      return { ...state, loanLoaded: action.payload };
    }

    case actions.EOSP_SET_ERROR: {
      return { ...state, error: action.payload };
    }

    case actions.SP_ENSURANCE_CREATED: {
      return { ...state, ensuranceLoaded: false, customer: action.payload };
    }

    case actions.SP_SET_LOADER: {
      return { ...state, ensuranceLoaded: action.payload };
    }

    case actions.SP_STORE_CUSTOMER: {
      return {
        ...state,
        ensuranceLoaded: false,
        customer: { ...action.payload },
      };
    }
    case actions.SP_DROP_CUSTOMER:
      return {
        ...state,
        customer: undefined,
      };
    case actions.SP_STORE_CLIENTID: {
      return { ...state, clientId: action.payload };
    }
    case actions.SP_STORE_APPLICATION: {
      return { ...state, application: { ...action.payload } };
    }
    case actions.SP_DROP_CLIENT_ID: {
      return { ...state, clientId: undefined, application: undefined };
    }
    case actions.SP_ADD_CREDIT_ID: {
      const application = state?.application || {};
      const ids = application?.ids || {};

      return { ...state, application: { ...application, ids: { ...ids, credit: action.payload } } };
    }
    default:
      break;
  }
  return state;
}

export function assistanceCreateLoan() {
  return {
    type: actions.CREATE_LOAN_APPLICATION,
  };
}

export function assistanceClearError() {
  return {
    type: actions.EOSP_SET_ERROR,
    error: '',
  };
}

export function insuranceCreateLoan() {
  return {
    type: actions.CREATE_LOAN_APPLICATION,
  };
}

export function insuranceClearError() {
  return {
    type: actions.EOSP_SET_ERROR,
    error: '',
  };
}

export function startPageCreateEnsurance() {
  return {
    type: actions.SP_CREATE_ENSURANCE,
  };
}

export function dropPageCreateEnsurance() {
  return {
    type: actions.SP_DROP_CUSTOMER_CAR,
  };
}

export function getCustomer({ customerId }) {
  return {
    type: actions.SP_GET_CUSTOMER,
    payload: { customerId },
  };
}

export function clearCustomer() {
  return {
    type: actions.SP_DROP_CUSTOMER,
  };
}

export function updateCustomer(id, data) {
  return {
    type: actions.SP_UPDATE_CUSTOMER,
    payload: { ...data, id },
  };
}

export function storeItem(application) {
  return {
    type: actions.SP_STORE_APPLICATION,
    payload: application,
  };
}

export function dropClientId() {
  return {
    type: actions.SP_DROP_CLIENT_ID,
  };
}

export function storeClientId(clientId) {
  return {
    type: actions.SP_STORE_CLIENTID,
    payload: clientId,
  };
}

export function addCreditId(creditId) {
  return {
    type: actions.SP_ADD_CREDIT_ID,
    payload: creditId,
  };
}
