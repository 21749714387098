import styled from 'styled-components';

import { SMSVerification } from 'components/common/SMSVerification';
import * as UK from 'components/primitives/content';
import Confirm from 'components/primitives/confirm';

export const Popup = styled(Confirm)`
  & > div {
    width: 500px;
    padding: 40px;
  }

  & > div > svg {
    top: 16px;
    right: 16px;
  }
`;

export const ContentOfPopup = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled(UK.Title1)`
  margin: 0px auto 30px auto;
`;

export const Text = styled(UK.Paragraph)`
  margin: 0px auto 30px auto;
  text-align: center;
`;

export const PhoneVerification = styled(SMSVerification)`
  height: 160px;
  margin-bottom: 10px;
`;
