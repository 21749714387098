import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getCarrotToken } from 'data-providers/carrot';

const insertScript = () => {
  const t = function (n) {
    return function () {
      window.carrotquestasync.push(n, arguments);
    };
  };

  if ('undefined' == typeof window.carrotquest) {
    const e = document.createElement('script');
    e.type = 'text/javascript';
    e.async = !0;
    e.src = '//cdn.carrotquest.app/api.min.js';

    document.getElementsByTagName('head')[0].appendChild(e);

    window.carrotquest = {};
    window.carrotquestasync = [];
    window.carrotquest.settings = {};

    const n = [
      'connect',
      'track',
      'identify',
      'auth',
      'onReady',
      'addCallback',
      'removeCallback',
      'trackMessageInteraction',
    ];

    for (let a = 0; a < n.length; a++) {
      window.carrotquest[n[a]] = t(n[a]);
    }
  }
};

const init = async (user) => {
  if (!window.carrotquest && user.id) {
    insertScript();
    window.carrotquest.connect('49411-80ed39a05a1643389f722ae152');
  } else if (user.id && !user.isDealersFetch) {
    const role = (user.roles || []).find((r) => r.id === user.role)?.name || '';
    const dealer = (user.dealers || []).find((d) => d.id === user.dealer)?.name || '';

    try {
      const res = await getCarrotToken(user.email);
      if (res?.token) {
        window.carrotquest.auth(user.email, res.token);
      }
    } catch (err) {
      console.log(err);
    }

    window.carrotquest.identify({
      '$user_id': user.id,
      '$name': `${user.lastname} ${user.firstname} ${user.middlename}`,
      '$email': user.email,
      'Доступные роли': (user.roles || []).map((r) => r.name).join(', '),
      'Роль': role,
      'Доступные дилеры': (user.dealers || []).map((d) => d.name).join(', '),
      'Дилер': dealer,
    });
  }
};

export const Carrotquest = () => {
  const user = useSelector((store) => store.user);

  useEffect(() => {
    init(user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return <React.Fragment />;
};
