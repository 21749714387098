import React, { useState, useEffect, useRef } from 'react';
import animateScrollTo from 'animated-scroll-to';
import PropTypes from 'prop-types';
import { InputMask } from 'utils/inputMask';
import { FormHeader } from '../../../Form/FormHeader';
import { DRIVER_LICENSE } from '../../../../constants';
import Balloon from 'components/primitives/balloon';

import * as ScrollHelper from '../../../../helpers/scrollHelper';
import * as Helper from './helper';
import * as S from './style';

const fn = () => {};

export const DriverLicense = ({
  driver,
  scrollTo = null,
  errors = {},
  disabled = false,
  onBlur = null,
  onSwitchCheckbox = fn,
  onScroll = fn,
}) => {
  const ref = useRef();
  const tooltipComponentRef = useRef();

  const [state, setState] = useState({ ...driver });
  const [show, setShow] = useState(false);

  const mouseOutHandler = () => {
    setShow(false);
  };

  const mouseOverHandler = () => {
    setShow(true);
  };

  useEffect(() => {
    const scrollToInsurerDriver = driver.isDriver && scrollTo === DRIVER_LICENSE.name;
    const scrollToOnlyDriver =
      scrollTo === ScrollHelper.getDriverBlockName(driver.id, DRIVER_LICENSE.name);

    if (ref && ref.current && (scrollToInsurerDriver || scrollToOnlyDriver)) {
      animateScrollTo(ref.current);
      onScroll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollTo]);

  useEffect(() => {
    setState({ ...driver });
  }, [driver]);

  const onBlurHandler = async (value) => {
    if (onBlur) {
      onBlur({ value, state });
    }
  };

  const seriaNumberChangeHandler = (item) => {
    const key = Object.keys(item)[0];

    setState({ ...state, [key]: Helper.getNewLicenseSeriaNum(item[key], state[key]) });
  };

  const changeHandler = (item) => {
    const key = Object.keys(item)[0];

    setState({ ...state, [key]: item[key] });
  };

  const formatHandler = (value) => {
    const pureValue = Array.from(value)
      .filter((letter) => letter !== ' ')
      .join('');

    return pureValue.length < 5 ? value : `${pureValue.slice(0, 4)} ${pureValue.slice(4)}`;
  };

  return (
    <S.FormContainer ref={ref}>
      <S.HeaderWrapper>
        <FormHeader title={'Водительское удостоверение'} />
      </S.HeaderWrapper>
      <S.SeriaNumber
        required
        val={state.licenseSeriaNum}
        name="licenseSeriaNum"
        label="Серия, номер"
        disabled={disabled}
        error={errors?.licenseSeriaNum}
        onChange={seriaNumberChangeHandler}
        onBlur={onBlurHandler}
        format={formatHandler}
        isControlledComponent
      />
      <S.DateLicense
        required
        isControlledComponent
        unmask={true}
        val={state.licenseDate}
        name="licenseDate"
        label="Дата выдачи"
        disabled={disabled}
        mask={InputMask.DATE_BEFORE_TODAY_NEW}
        onChange={changeHandler}
        onBlur={onBlurHandler}
        error={errors?.licenseDate}
      />
      <S.IsFirstLicenseCheckbox
        name="isFirstLicense"
        label={<span style={{ fontSize: 15 }}>Это первое водительское удостоверение</span>}
        disabled={disabled}
        checked={driver.isFirstLicense}
        size={19}
        borderRadius={5}
        onClick={onSwitchCheckbox}
      />
      {!driver.isFirstLicense && (
        <>
          <S.PrevSeriaNumber
            val={state.prevLicenseSeriaNum}
            name="prevLicenseSeriaNum"
            label="Серия, номер предыдущего удостоверения"
            disabled={disabled}
            error={errors?.prevLicenseSeriaNum}
            onChange={seriaNumberChangeHandler}
            onBlur={onBlurHandler}
            format={formatHandler}
            isControlledComponent
          />
          <S.PrevDateLicense
            required
            isControlledComponent
            unmask={true}
            val={state.prevLicenseDate}
            name="prevLicenseDate"
            label="Дата выдачи первого удостоверения"
            disabled={disabled}
            mask={InputMask.DATE_BEFORE_TODAY_NEW}
            onBlur={onBlurHandler}
            onChange={changeHandler}
            error={errors?.prevLicenseDate}
          />
          <S.QuestionMarkWrapper>
            <S.QuestionMark
              ref={tooltipComponentRef}
              onMouseOut={mouseOutHandler}
              onMouseOver={mouseOverHandler}
            >
              ?
              <Balloon
                componentRef={tooltipComponentRef}
                position={'right'}
                indent={14}
                maxWidth={235}
                padding={5}
                text={[
                  'Если в удостоверении указан только Год начала стажа, то необходимо указать дату: 31.12.',
                  '”Год начала стажа”',
                ]}
                font={['normal 12px Roboto', 'italic 12px Roboto']}
                isShow={show}
                onMouseOver={mouseOverHandler}
              />
            </S.QuestionMark>
          </S.QuestionMarkWrapper>
        </>
      )}
    </S.FormContainer>
  );
};

DriverLicense.propTypes = {
  driver: PropTypes.shape({
    fio: PropTypes.string,
    bday: PropTypes.string,
    gender: PropTypes.number,
    licenseSeriaNum: PropTypes.string,
    licenseDate: PropTypes.string,
    isFirstLicense: PropTypes.bool,
    prevLicenseSeriaNum: PropTypes.string,
    prevLicenseDate: PropTypes.string,
  }),
  errors: PropTypes.object,
  onBlur: PropTypes.oneOfType([PropTypes.func, PropTypes.oneOf([null])]),
  onSwitchCheckbox: PropTypes.func,
};
