import styled from 'styled-components';
import { CloseCrossSvg } from 'assets/img';

export const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.45);
`;

export const Content = styled.div`
  position: relative;
  min-width: 500px;
  height: auto;
  background-color: #fff;
`;

export const Close = styled(CloseCrossSvg)`
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  padding: 11px;
  opacity: 0.8;
  fill: #000;
  cursor: pointer;
  z-index: 1000;

  &:hover {
    opacity: 1;
  }
`;
