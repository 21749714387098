import styled from 'styled-components';
import { PALETTE } from 'styles/constants';

export const MainContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 12px 0px;
  min-height: 72px;
  background-color: ${PALETTE.blue9};
  border-radius: 24px;
  padding-left: 36px;
`;

export const StringContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const BoldRow = styled.div`
  font-size: 17px;
  font-weight: 700;
  line-height: normal;
`;

export const Row = styled.div`
  font-size: 13px;
  font-weight: 400;
`;
export const CloseButton = styled.div`
  display: flex;
  justify-content: center;
  width: 32px;
  aspect-ratio: 1;
  align-items: center;
  background-color: ${PALETTE.white};
  border-radius: 8px;
  margin-right: 20px;
  cursor: pointer;
  svg {
    width: 12px;

    path {
      fill: ${PALETTE.black1};
    }
  }
`;
