import styled from 'styled-components';

import { Link } from 'components/primitives/link';

export const Wrapper = styled(Link)`
  display: flex;
  height: 75px;
  padding: 0px 30px;
  align-items: center;
  border-radius: 5px;
  font-size: 14px;
  line-height: 1.2;
  ${(p) => (p.border ? `border: 1px solid ${p.border};` : ``)}
  ${(p) => (p.color ? `background-color: ${p.color};` : ``)}
  &:hover,
  &:focus {
    color: inherit;
    text-decoration: none;
  }
`;

export const Img = styled.span`
  margin-right: 15px;
  font-size: 0px;
`;
