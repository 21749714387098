import React, { useState, useEffect, useRef } from 'react';
import { registerLocale } from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import moment from 'moment';

import { CalendarSvg } from 'assets/img';

import CustomInput from './components/CustomInput';
import { Wrapper, DatePickerStyled, Label, Prompt, CalendarWrapper } from './style';

const fn = () => {};

export const DatePicker = ({
  name = null,
  className = '',
  disabled = false,
  required = false,
  label = '',
  dateFormat = 'dd.MM.yyyy',
  onChange = fn,
  onBlur = fn,
  error = '',
  value = '',
  minDate = new Date(),
  maxDate = new Date(moment(new Date().toISOString()).add(60, 'days').format('YYYY-MM-DD')),
  offset = [-1, 13],
  autoFocus = true,
  dataTest,
  buttonDataTest,
}) => {
  const customInputRef = useRef(null);

  const [innerValue, setValue] = useState(value);
  const [focused, setFocused] = useState(false);

  const handleBlur = (val) => {
    setFocused(false);
    const format = 'DD.MM.YYYY';
    let newVal = val;
    const newDate = moment(newVal, format);
    if (newDate.isBefore(moment(minDate))) {
      newVal = moment(minDate).format(format);
    }
    if (newDate.isAfter(moment(maxDate))) {
      newVal = moment(maxDate).format(format);
    }

    if (newVal !== val) {
      setValue(newVal);
    }
    onBlur(name ? { [name]: newVal } : newVal);
  };

  const inputHandleChange = (val) => {
    if (val !== value) {
      setValue(val);
      onChange(name ? { [name]: val } : val);
    }
  };

  const handleSelect = (val) => {
    const result = moment(val).format('DD.MM.YYYY');

    setValue(result);
    onBlur(name ? { [name]: result } : result);
  };

  const wrapperClick = () => {
    if (!disabled) {
      if (customInputRef?.current) {
        customInputRef.current.element.click();
      }

      setFocused(true);
    }
  };

  useEffect(() => {
    setValue(value);
    if (!disabled && autoFocus) {
      setFocused(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (focused && customInputRef?.current) {
      customInputRef.current.element.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focused, customInputRef?.current]);

  useEffect(() => {
    registerLocale('ru', ru);
  });

  const momentDate = moment(innerValue, 'DD.MM.YYYY');
  const dateValue =
    innerValue.length === 10 && momentDate.isValid()
      ? new Date(momentDate.format('YYYY-MM-DD'))
      : null;

  return (
    <Wrapper
      required={error && required}
      disabled={disabled}
      className={`this-is-datepicker-wrapper ${className}`}
      focused={focused || !!innerValue}
    >
      <div
        onClick={wrapperClick}
        style={{ position: 'absolute', top: 0, left: 0, height: '100%', width: '100%' }}
      />
      {label && (
        <Label focused={focused || !!innerValue} error={error}>
          {label}
          {required && <sup>*</sup>}
        </Label>
      )}
      <DatePickerStyled
        customInputRef={customInputRef}
        customInput={
          <CustomInput
            onChangeInput={inputHandleChange}
            onBlurInput={handleBlur}
            innerValue={innerValue}
            disabled={disabled}
            ref={customInputRef}
            dataTest={dataTest}
          />
        }
        focused={focused || !!innerValue}
        selected={dateValue}
        showPopperArrow={false}
        onSelect={handleSelect}
        dateFormat={dateFormat}
        minDate={minDate}
        maxDate={maxDate}
        popperPlacement="top-start"
        popperModifiers={[
          {
            name: 'flip',
            options: {
              fallbackPlacements: ['top'],
            },
          },
          {
            name: 'offset',
            options: { offset },
          },
        ]}
        locale="ru"
        disabled={disabled}
      />
      <CalendarWrapper onClick={wrapperClick} data-test={buttonDataTest}>
        <CalendarSvg />
      </CalendarWrapper>
      {error && <Prompt>{error}</Prompt>}
    </Wrapper>
  );
};
