import styled from 'styled-components';
import { Popup as PopupPrimitive } from 'components/primitives/popup';

export const Popup = styled(PopupPrimitive)``;

export const IFrame = styled.iframe`
  position: relative;
`;

export const Content = styled.div`
  position: relative;
  min-width: 500px;
  background-color: #fff;
  overflow: hidden;
  height: 600;
`;
