export const passportPages = [
  '2-3',
  '4-5',
  '6-7',
  '8-9',
  '10-11',
  '12-13',
  '14-15',
  '16-17',
  '18-19',
];

export const drivingLicencePages = ['1', '2'];

export const pasportConfidenceFields = {
  passport_main: {
    surname: { field: 'personalInfo.fullName', confidence: 0.9 },
    first_name: { field: 'personalInfo.fullName', confidence: 0.9 },
    other_names: { field: 'personalInfo.fullName', confidence: 0.9 },
    series_and_number: { field: 'passport.serianumber', confidence: 0.9 },
    date_of_issue: { field: 'passport.issueDate', confidence: 0.9 },
    subdivision_code: { field: 'passport.issuerCode', confidence: 0.9 },
    issuing_authority: { field: 'passport.issuer', confidence: 0.9 },
    place_of_birth: { field: 'passport.birthPlace', confidence: 0.9 },
    date_of_birth: { field: 'personalInfo.birthDate', confidence: 0.9 },
    sex: { field: 'gender', confidence: 0.9 },
  },
  passport_registration: {
    address: { field: 'addressReg.fiasValue', confidence: 0.5 },
    date: { field: 'addressReg.regDate', confidence: 0.5 },
  },
};
