import React from 'react';
import { CarPreloaderGif } from 'assets/img';
import { ButtonStyled, LoaderWrapper, Loader } from './style.js';

export const Button = ({
  loading,
  children,
  disabled,
  fullWidth,
  onClick,
  className,
  type = 'default',
  dataTest,
}) => (
  <ButtonStyled
    disabled={disabled}
    fullWidth={fullWidth}
    type={type}
    className={className}
    data-test={dataTest}
    onClick={onClick}
  >
    {children}
    {loading && (
      <>
        <LoaderWrapper />
        <Loader src={CarPreloaderGif} alt="Загрузка" color="white" />
      </>
    )}
  </ButtonStyled>
);
