import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';

import { Alert } from 'components/primitives/alert';
import { setIsPEPSignature } from 'store/anketa';
import { getPepDoc } from 'data-providers/mainAnketaProvider';

import { SMSVerification } from './SMSVerification';
import DocumentsLinks from './DocumentsLinks';

import * as help from './helpers';
import * as S from './styles';

const DOC_TYPES = {
  'pep-agreement': {
    fullLabel: 'Соглашение клиента об использовании ПЭП',
    shortLabel: 'Соглашение клиента об использовании',
    linkLabel: 'ПЭП',
    type: 'electronic-document',
  },
  'pep-sopd': {
    fullLabel: 'Согласие клиента об Обработке персональных данных',
    shortLabel: 'Согласие клиента об Обработке',
    linkLabel: 'персональных данных',
    type: 'personal-data',
  },
  'pep-bki': {
    fullLabel: 'Согласие БКИ',
    shortLabel: 'Согласие',
    linkLabel: 'БКИ',
    type: 'credit-history-data',
  },
};

export const SignPEP = ({ anketaData, anketaErrors, onSave }) => {
  const rootDispatch = useDispatch();
  const { addToast, removeAllToasts } = useToasts();

  const { isPEPSignature, pdf, otpDocLink } = useSelector((state) => state.anketa || {});

  const [data, setData] = useState({ agreementPEP: true, agreementProc: true, agreementBKI: true });
  const [isDisabled, setIsDisabled] = useState(false);
  const [isVerification, setIsVerification] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const hasAnketaErrors = useMemo(() => help.hasAnketaErrors(anketaErrors), [anketaErrors]);
  const customer = useMemo(() => help.getCustomer(anketaData), [anketaData]);

  const getDocLabel = (type) => {
    if (!isPEPSignature || !otpDocLink[DOC_TYPES[type].type]) {
      return DOC_TYPES[type]?.fullLabel || '';
    }

    return (
      <>
        {DOC_TYPES[type]?.shortLabel || ''}
        <S.DocLink href="#" onClick={(e) => docHandler(e, type)}>
          {DOC_TYPES[type]?.linkLabel || ''}
        </S.DocLink>
      </>
    );
  };

  const docHandler = async (event, type) => {
    event.stopPropagation();
    event.preventDefault();

    if (!anketaData?.application?.id || !DOC_TYPES[type] || !customer) {
      return;
    }

    addToast('Загрузка документа', {
      appearance: 'success',
      autoDismiss: true,
    });

    await getPepDoc(otpDocLink[DOC_TYPES[type].type]);
  };

  const changeHandler = (field) => {
    setData((prev) => ({ ...prev, [field]: !prev[field] }));
  };

  const signHandler = () => {
    if (!isDisabled) {
      setIsVerification(true);
    }
  };

  const verificationCloseHandler = () => {
    setIsVerification(false);
  };

  const verificationSuccessHandler = () => {
    onSave(true);
    rootDispatch(setIsPEPSignature(true));
    setIsVerification(false);
    setIsSuccess(true);
  };

  const closeSuccessPopupHandler = () => {
    setIsSuccess(false);
  };

  useEffect(() => {
    setIsDisabled(
      !data.agreementPEP ||
        !data.agreementProc ||
        !data.agreementBKI ||
        hasAnketaErrors ||
        isPEPSignature
    );
  }, [hasAnketaErrors, data, isPEPSignature]);

  useEffect(() => {
    if (pdf) {
      removeAllToasts();
      help.saveDocument(pdf);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdf]);

  return (
    <>
      <S.Container>
        <S.Title>Подписать Согласие СОПД и БКИ у клиента</S.Title>

        <S.Content>
          <S.CheckBox
            name="agreementPEP"
            label={getDocLabel('pep-agreement')}
            checked={data['agreementPEP']}
            isPEPSignature={isPEPSignature}
            onClick={() => changeHandler('agreementPEP')}
          />
          <S.CheckBox
            name="agreementProc"
            label={getDocLabel('pep-sopd')}
            checked={data['agreementProc']}
            isPEPSignature={isPEPSignature}
            onClick={() => changeHandler('agreementProc')}
          />
          <S.CheckBox
            name="agreementBKI"
            label={getDocLabel('pep-bki')}
            checked={data['agreementBKI']}
            isPEPSignature={isPEPSignature}
            onClick={() => changeHandler('agreementBKI')}
          />
        </S.Content>

        <S.SignButton disabled={isDisabled} onClick={signHandler}>
          Подписать
        </S.SignButton>
        <DocumentsLinks />
      </S.Container>

      {isVerification && (
        <SMSVerification
          appId={anketaData?.application?.id}
          dealerId={anketaData?.application?.dealer?.id}
          customer={customer}
          onClose={verificationCloseHandler}
          onSuccess={verificationSuccessHandler}
        />
      )}

      {isSuccess && (
        <Alert
          title={'Поздравляем! Согласия СОПД и БКИ подписаны успешно'}
          desc={'Продолжите заполнение анкеты'}
          agreeText={'Понятно'}
          onAgree={closeSuccessPopupHandler}
        />
      )}
    </>
  );
};
