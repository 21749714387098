import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { uploadFiles } from 'data-providers/mainAnketaProvider';
import { getRecognizeFile, deleteFile, updateClassify } from 'data-providers/documentProvider';
import { Alert } from 'components/primitives/alert';
import {
  addWaitingFile,
  checkRecognizedFiles,
  removeWaitingFile,
} from 'store/fileRecognizer/actions';
import { useToasts } from 'react-toast-notifications';
import { DragDropStyled, InfoSvgStyled, PageList, PageError } from './style';
import { FileView } from '../FileManager/FileView';
import { ReactTooltipStyled, AreaInfo } from '../../style';
import { passportPages, drivingLicencePages } from 'utils/constants/documents';
import { removeClassifyFile } from 'store/fileRecognizer/actions';
import { openDocumentTrack } from 'metrika/documents';

const validFileFormats = ['JPG', 'JPEG', 'PDF', 'PNG'];

export const FileUpload = ({
  title,
  applicationId,
  currentPersonId,
  onLoadFiles,
  loading,
  classification,
  style,
  multiple = false,
  disabled = false,
  contentHidden = false,
  doctypes,
}) => {
  const [total, setTotal] = useState(0);
  const [process, setProcess] = useState(null);
  const [documentInfo, setDocumentInfo] = useState(null);
  const [intrvalCheck, setIntervalCheck] = useState(null);
  const dragDropRef = useRef();
  const { addToast } = useToasts();
  const { waitingFiles, classifyFiles } = useSelector((state) => state.fileRecognizer);
  const { documents } = useSelector((state) => state.anketa);
  const rootDispatch = useDispatch();
  const [deleteObj, setDeleteObj] = useState(null);
  const [docObj, setDocObj] = useState(null);
  const [classify, setClassify] = useState(false);

  const handleDeleteClick = (obj) => {
    setDeleteObj(obj);
  };

  const handleFileUpload = (validFiles) => {
    const formData = new FormData();
    validFiles.forEach((file) => {
      formData.append('file[]', file);
    });

    const params = {
      entryName: 'customer',
      entryId: currentPersonId,
    };

    formData.append(
      'payload',
      new Blob([JSON.stringify(params)], { type: 'application/json' }),
      'payload.json'
    );

    setProcess(1);
    uploadFiles({ applicationId, formData })
      .then((res) => {
        setProcess(2);
        setTotal(total + res.length);

        res.forEach((r) => rootDispatch(addWaitingFile({ id: r.id, uuid: r.uuid })));
        rootDispatch(checkRecognizedFiles());
        const timer = setInterval(() => {
          getRecognizeFile({ id: res.map((r) => r.uuid) })
            .then((files) => {
              if (files.length > 0) {
                files.forEach(
                  ({ id, status }) =>
                    status === 'PROCESSED' && rootDispatch(removeWaitingFile({ uuid: id }))
                );
              }
            })
            .catch((err) => {
              clearTimeout(timer);
              console.log('error', err);
              addToast('Документы не удалось загрузить, попробуйте еще раз', {
                appearance: 'error',
              });
              setProcess(0);
            });
        }, 5000);
        setIntervalCheck(timer);
      })
      .catch((err) => {
        setProcess(0);
        addToast(err.response.data.error, {
          appearance: 'error',
        });
      });
  };

  useEffect(() => {
    setProcess(loading ? 2 : 0);
  }, [loading]);

  const setFiles = (files) => {
    if (files) {
      if (!files.length) {
        return;
      }

      const validFiles = files.filter((file) => {
        const splittedType = file.type.split('/');

        let type = splittedType[splittedType.length - 1] || '';
        type = type.toUpperCase();

        return validFileFormats.includes(type);
      });

      if (!validFiles.length) {
        addToast('Неверный формат файлов', {
          appearance: 'error',
        });
        setProcess(0);
        setTotal(0);
      } else {
        handleFileUpload(validFiles);
      }
    }
  };

  useEffect(() => {
    if (0 === waitingFiles.length && process === 2) {
      clearInterval(intrvalCheck);
      onLoadFiles(() => {
        setProcess(3);
        setTotal(0);
        addToast('Распознавание документов завершено', {
          appearance: 'success',
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waitingFiles]);

  useEffect(() => {
    if (classification) {
      setProcess(3);
      if (classification.sysName === 'passport' || classification.sysName === 'driving-licence') {
        const pArr = documents.filter((d) => classification.sysName === d.type.sysName);

        if (classification.sysName === 'passport') {
          const pArrExist = passportPages.reduce((arr, page) => {
            if (!pArr.some((p) => page === p.classification.pages)) {
              return [...arr, page];
            }
            return arr;
          }, []);
          setDocumentInfo(
            <AreaInfo>
              <InfoSvgStyled data-tip="" data-for="passportDocInfo" />
              <ReactTooltipStyled id="passportDocInfo" place="bottom">
                <PageList>
                  {passportPages.map((page, p) => (
                    <PageError
                      key={`_p_${p + 1}`}
                      color={pArrExist.some((p) => p === page)}
                      sysName={classification.sysName}
                    >
                      {page}
                    </PageError>
                  ))}
                </PageList>
              </ReactTooltipStyled>
            </AreaInfo>
          );
        }
        if (classification.sysName === 'driving-licence') {
          const pArrExist = ['front', 'back'].reduce((arr, side) => {
            if (
              side === 'front' &&
              !pArr.some((p) => !p.classification.type || p.classification.type.indexOf(side) > -1)
            ) {
              return [...arr, '1'];
            }
            if (
              side === 'back' &&
              !pArr.some((p) => p.classification.type && p.classification.type.indexOf(side) > -1)
            ) {
              return [...arr, '2'];
            }
            return arr;
          }, []);
          setDocumentInfo(
            <AreaInfo>
              <InfoSvgStyled data-tip="" data-for="drivingLicenceDocInfo" />
              <ReactTooltipStyled id="drivingLicenceDocInfo" place="bottom">
                <PageList>
                  {drivingLicencePages.map((page, p) => (
                    <PageError
                      key={`_p_${p + 1}`}
                      color={pArrExist.some((p) => p === page)}
                      sysName={classification.sysName}
                    >
                      {page}
                    </PageError>
                  ))}
                </PageList>
              </ReactTooltipStyled>
            </AreaInfo>
          );
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documents]);

  const agreeDelete = () => {
    if (docObj) setDocObj(null);
    if (deleteObj && deleteObj.type === 'file') {
      handleDeleteFiles(deleteObj.list);
    }
    if (deleteObj && deleteObj.type === 'type') {
      handleDeleteByType();
    }
  };

  const handleDeleteFiles = async (docs) => {
    setDeleteObj(null);
    const ids = docs.map((d) => d.id);
    const otherDocs = documents.filter((d) => !ids.includes(d.id));
    updateFiles(otherDocs);
    await Promise.all(
      [...new Array(docs.length)].map(async (num, i) => {
        await deleteFile(
          Number(applicationId),
          docs[i].id,
          docs[i].entryId,
          classification.sysName
        );
      })
    );
    onLoadFiles(() => {
      setProcess(0);
      setTotal(0);
      addToast('Удаление документов завершено', {
        appearance: 'success',
      });
    });
  };

  const handleDeleteByType = async () => {
    setDeleteObj(null);
    const docs = documents.filter((d) => classification.sysName === d.type.sysName);
    const otherDocs = documents.filter((d) => classification.sysName !== d.type.sysName);
    updateFiles(otherDocs);
    await Promise.all(
      [...new Array(docs.length)].map(async (num, i) => {
        await deleteFile(
          Number(applicationId),
          docs[i].id,
          docs[i].entryId,
          classification.sysName
        );
      })
    );
    onLoadFiles(() => {
      setProcess(0);
      setTotal(0);
      addToast('Удаление документов завершено', {
        appearance: 'success',
      });
    });
  };

  const updateFiles = (data) => {
    if (!data) return;
    rootDispatch({
      type: 'UPLOAD_COMMON',
      payload: {
        documents: data,
      },
    });
  };

  const driverLicenseCompareFunc = (a, b) => {
    if (!a.classification || !a.classification.type) return 1;
    if (!b.classification || !b.classification.type) return -1;

    if (b.classification.type.includes('front')) return 1;
    if (b.classification.type.includes('back')) return -1;
    return 0;
  };

  const passportPagesOrder = [
    'passport_main',
    'passport_main_handwritten',
    'passport_registration_deregistered',
    'passport_registration',
    'passport_registration_handwritten',
    'passport_blank_page',
    'passport_military',
    'passport_marriage',
    'passport_children',
    'passport_previous_docs',
    'passport_zero_page',
    'passport_last_rf',
  ];

  const pagesCompareFunc = (a, b) => {
    if (!a.classification || !a.classification.type) return 1;
    if (!b.classification || !b.classification.type) return -1;

    if (passportPagesOrder.indexOf(a.classification.type) === -1) return -1;
    if (passportPagesOrder.indexOf(b.classification.type) === -1) return 0;
    return (
      passportPagesOrder.indexOf(a.classification.type) -
      passportPagesOrder.indexOf(b.classification.type)
    );
  };

  const showFile = (e) => {
    if (e) e.stopPropagation();
    let docs = documents
      .filter((d) => classification.sysName === d.type.sysName)
      .sort(
        classification.sysName === 'passport'
          ? pagesCompareFunc
          : classification.sysName === 'driving-licence'
          ? driverLicenseCompareFunc
          : undefined
      );
    console.log(docs);
    openDocumentTrack(classification.sysName);
    setDocObj({
      type: classification,
      list: docs,
    });
  };

  useEffect(() => {
    if (classify) {
      showFile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classify]);

  useEffect(() => {
    if (process === 3 && !classification) {
      setProcess(0);
    }
  }, [process, classification]);

  const handleChangeType = (doc, type) => {
    if (classify) setClassify(false);
    const data = updateType(type, [doc.legacyId]);
    updateFiles(data);
    updateClassify(Number(applicationId), doc.id, doc.entryId, type);
  };

  const updateType = (type, ids) => {
    return documents.map((doc) => {
      if (ids.some((id) => id === doc.legacyId)) {
        return { ...doc, type: doctypes.find((d) => d.sysName === type) };
      }
      return doc;
    });
  };

  useEffect(() => {
    if (classifyFiles && classification && classification.sysName === 'customer-other-files') {
      setClassify(true);
      showFile();
      rootDispatch(removeClassifyFile());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classification, classifyFiles]);

  return (
    <div style={style}>
      <DragDropStyled
        title={title}
        ref={dragDropRef}
        process={process}
        small={true}
        multiple={multiple}
        disabled={disabled}
        contentHidden={contentHidden}
        filesLeft={total - waitingFiles.length || 0}
        filesTotal={total}
        onChange={setFiles}
        deleteClick={() => !contentHidden && handleDeleteClick({ type: 'type' })}
        showFile={!contentHidden && showFile}
        documentInfo={documentInfo}
      />
      {docObj && (
        <FileView
          applicationId={applicationId}
          data={docObj}
          onClose={() => {
            setDocObj(null);
            setClassify(false);
          }}
          onDelete={handleDeleteClick}
          docTypes={doctypes}
          onChange={handleChangeType}
          classify={classify}
        />
      )}
      {deleteObj && (
        <Alert
          title="Вы действительно хотите удалить документ?"
          desc="Документ удаляется безвозвратно"
          agreeText="Удалить"
          cancelText="Отмена"
          onAgree={agreeDelete}
          onCancel={() => setDeleteObj(null)}
        />
      )}
    </div>
  );
};
