const initialState = {
  brands: [],
  models: [],
  versions: [],
  brandsFetch: false,
  modelsFetch: false,
  versionsFetch: false,
  allFetch: false,
  error: '',
};

export default initialState;
