import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import * as UK from 'components/primitives/content';

import * as S from './styles';
import * as localStorage from 'utils/local-storage';
import { setAuthCookie, setIncCookies } from 'utils/helpers';
import { login } from 'data-providers/authProvider';

export const LoginFrom = () => {
  const history = useHistory();
  const [error, setError] = useState(null);
  const [data, setData] = useState({ login: '', password: '' });

  const doLogin = () => {
    if (data.login.indexOf('@') > -1 && data.password) {
      login({ login: data.login, password: data.password })
        .then((data) => {
          setAuthCookie(data.token, data.refresh_token);
          setIncCookies(data.cookies);
          localStorage.saveItem('token', {
            token: data.token,
            refresh: data.refresh_token,
            expires: data.expires_in,
          });
          history.push('/applications/list');
        })
        .catch(() => {
          setError({ message: 'Неверное имя пользователя и/или пароль' });
        });
    } else {
      setError({ message: 'Неверный адрес электронной почты' });
    }
  };

  const changeHandler = (event) => {
    setData((prev) => ({ ...prev, ...event }));
    setError(null);
  };

  const handleSubmit = () => {
    doLogin();
  };

  const handleSubmitKey = (e = null) => {
    if (e && e.key === 'Enter') {
      doLogin();
    }
  };

  return (
    <S.Wrapper>
      <S.Logo />

      <S.Form>
        <UK.Input name="login" val={data.login} label="Логин" onChange={changeHandler} />
        <UK.Input
          name="password"
          val={data.password}
          type="password"
          label="Пароль"
          onChange={changeHandler}
          onKeyPress={handleSubmitKey}
        />
        <UK.DarkRedButton onClick={handleSubmit}>Войти</UK.DarkRedButton>
      </S.Form>

      {error && <S.Error>{error?.message || ''}</S.Error>}
    </S.Wrapper>
  );
};
