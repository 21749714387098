import styled from 'styled-components';
import { IMaskInput } from 'react-imask';

export const Input = styled(IMaskInput)`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
`;
