import { checkINN } from 'ru-validation-codes';
import { OkatoNums } from '../constants/okato';
import { getItem } from 'utils/local-storage';
import moment from 'moment';
import sleep from 'utils/sleep';
import axios from 'axios';

const proxyURL = process.env.REACT_APP_DADATA_PROXY_URL;

export const requiredTest = (value) => {
  if (value === null || value === undefined || !value.toString()) {
    return false;
  }
  return true;
};

export const innTest = (value, size = null) => {
  if (size && String(value).length !== size) {
    return false;
  }
  return checkINN(value);
};

export const checkOkato = (num) => {
  if (!num) return false;
  return OkatoNums.includes(num);
};

const gefHeadersForInn = () => {
  const stored = getItem('token');
  var obj = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'accept': 'application/json',
      'Authorization': `Bearer ${stored.token}`,
    },
  };
  return obj;
};

const passportsCache = {};
export const checkPassportNumber = async (seriesNumber /*, attempt = 0*/) => {
  const payload = [];
  if (!seriesNumber || seriesNumber.length < 10) return true;
  if (seriesNumber.length === 10) {
    const series = seriesNumber.substring(0, 4);
    const number = seriesNumber.substring(4, 10);
    payload.push(`${series} ${number}`);
  } else {
    payload.push(seriesNumber);
  }

  if (payload[0] in passportsCache) {
    return passportsCache[payload[0]];
  }
  /*
    const url = `${proxyURL}/clean/passport`;

    try {
      const result = await axios.post(url, payload, gefHeaders());
      if (result.status === 200 && result.data) {
        passportsCache[payload[0]] = result.data[0]?.qc === 0;
        return passportsCache[payload[0]];
      } else {
        if (attempt >= 2) return false;
        await sleep(3000);
        return await checkPassportNumber(seriesNumber, attempt + 1);
      }
    } catch (error) {
      if (attempt >= 2) return false;
      return await checkPassportNumber(seriesNumber, attempt + 1);
    }

   */
  return true;
};

const nameFormat = (name) => {
  if (!name) return name;
  return `${name[0].toUpperCase()}${name.substring(1, name.length).toLowerCase()}`;
};

const encode = (data) => {
  const encoded = Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
  return encoded;
};

const innsCache = {};
export const getInnByPassport = async (anketa, attempt = 0) => {
  if (!anketa.passport || !anketa.personalInfo) return null;
  let seriesNumber = anketa.passport.serianumber;
  if (!seriesNumber || seriesNumber.length < 10) return null;
  const series1 = seriesNumber.substring(0, 2);
  const series2 = seriesNumber.substring(2, 4);
  const number = seriesNumber.substring(4, 10);
  seriesNumber = `${series1} ${series2} ${number}`;

  if (seriesNumber in innsCache) {
    return innsCache[seriesNumber];
  }

  const data = {
    fam: nameFormat(anketa.personalInfo.lastName),
    nam: nameFormat(anketa.personalInfo.firstName),
    otch: nameFormat(anketa.personalInfo.middleName),
    bdate: anketa.personalInfo.birthDate,
    bplace: '',
    doctype: '21',
    docno: seriesNumber,
    docdt: anketa.passport.issueDate,
    c: 'innMy',
    captcha: '',
    captchaToken: '',
  };
  const payload = encode(data);
  const url = `${proxyURL}/inn`;

  try {
    const result = await axios.post(url, payload, gefHeadersForInn());
    if (result.status === 200 && result.data) {
      innsCache[seriesNumber] = result.data.inn;
      return innsCache[seriesNumber];
    } else {
      if (attempt >= 2) return null;
      await sleep(3000);
      return await getInnByPassport(anketa, attempt + 1);
    }
  } catch (error) {
    if (attempt >= 2) return null;
    return await getInnByPassport(anketa, attempt + 1);
  }
};

export const getFiasWithIndex = (values) => {
  if (!values || !values.fiasValue) return '';
  if (!values.isDadataValue) return values.fiasValue;

  if (values.isDadataIndexValue && requiredTest(values.index)) {
    if (!values.fiasValue.includes(values.index)) {
      return `${values.index} ${values.fiasValue}`;
    }
    return values.fiasValue;
  }
  return `${values.fiasValue}`;
};

const dateFormat = 'DD.MM.YYYY';

export const getCareerStart = (careerStartTime, experienceTime, birthDate) => {
  if (!careerStartTime || !experienceTime || !experienceTime.id) return '';

  let now = moment(new Date());
  const cst = moment(careerStartTime, 'DD.MM.YYYY');

  if (experienceTime.sysName === 'less_then_1_year') now = cst;
  else if (experienceTime.sysName === 'from_1_to_3_years') {
    now = now.add(-3, 'y').add(1, 'd');
  } else if (experienceTime.sysName === 'from_3_to_5_years') {
    now = now.add(-5, 'y').add(1, 'd');
  } else if (experienceTime.sysName === 'from_5_to_10_years') {
    now = now.add(-10, 'y').add(1, 'd');
  } else {
    now = now.add(-10, 'y').add(-1, 'd');
  }

  if (now > cst) now = cst;
  if (birthDate) {
    const bd = moment(birthDate, 'DD.MM.YYYY').add(14, 'y');
    if (now < bd) now = bd;
  }

  return now.format(dateFormat);
};

export const getExperienceTime = (workExpirience, careerStart) => {
  if (!careerStart) return {};

  const cs = moment(careerStart, dateFormat);
  const now = moment(new Date());
  var diffYears = now.diff(cs, 'y', true);

  if (diffYears < 1) return workExpirience.find((o) => o.sysName === 'less_then_1_year');
  if (diffYears <= 3) return workExpirience.find((o) => o.sysName === 'from_1_to_3_years');
  if (diffYears <= 5) return workExpirience.find((o) => o.sysName === 'from_3_to_5_years');
  if (diffYears <= 10) return workExpirience.find((o) => o.sysName === 'from_5_to_10_years');
  return workExpirience.find((o) => o.sysName === 'more_then_10_years');
};

export const validateEmail = (email = '') => {
  const res = String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

  return res?.length && res?.length > 0;
};
