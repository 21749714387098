import { fileRecognizerInitialState } from './index';
import {
  ADD_RECOGNIZED_FILE,
  ADD_WAITING_FILE,
  CHECK_RECOGNIZED_FILES,
  REMOVE_RECOGNIZED_FILE,
  REMOVE_WAITING_FILE,
  ADD_CLASSIFY_FILE,
  REMOVE_CLASSIFY_FILE,
} from './constants';

export default function (state = fileRecognizerInitialState, action) {
  switch (action.type) {
    case ADD_RECOGNIZED_FILE: {
      const recognizedFiles = state.recognizedFiles;
      const waitingFiles = state.waitingFiles;

      if (waitingFiles.find((f) => f.id === action.payload.fileId)) {
        return {
          ...state,
          waitingFiles: state.waitingFiles.filter((f) => f.id !== action.payload.fileId),
          recognizedFiles: [],
        };
      } else {
        recognizedFiles.push({ id: action.payload.fileId, uuid: null });
        return {
          ...state,
          recognizedFiles: recognizedFiles,
        };
      }
    }

    case CHECK_RECOGNIZED_FILES: {
      const recognizedFiles = state.recognizedFiles;
      return {
        ...state,
        waitingFiles: state.waitingFiles.filter(
          (f) => !recognizedFiles.some((r) => r.id === f.id || (r.uuid && r.uuid === f.uuid))
        ),
        recognizedFiles: [],
      };
    }

    case REMOVE_RECOGNIZED_FILE: {
      return state;
    }

    case ADD_WAITING_FILE: {
      return { ...state, waitingFiles: state.waitingFiles.concat([action.payload]) };
    }

    case REMOVE_WAITING_FILE: {
      if (state.waitingFiles.length > 0) {
        return {
          ...state,
          waitingFiles: state.waitingFiles.filter((f) => f.uuid !== action.payload.uuid),
        };
      }
      return state;
    }

    case ADD_CLASSIFY_FILE: {
      return { ...state, classifyFiles: true };
    }

    case REMOVE_CLASSIFY_FILE: {
      return { ...state, classifyFiles: false };
    }

    default:
      return state;
  }
}
