import React from 'react';
import PropTypes from 'prop-types';
import Highlighter from 'react-highlight-words';

import * as S from './style';
import * as Helper from './helper';

const fn = () => {};

const DropDown = ({
  highlightValue = '',
  items = [],
  isHidden = false,
  onClick = fn,
  onBlur = fn,
  showAll = false,
}) => {
  if (isHidden || !items.length) {
    return null;
  }

  const itemClickHandler = (item) => () => {
    onClick(item);
  };

  const allItems = items.map((item, i) => {
    return (
      <S.SelectButton
        type="button"
        key={`select_${i + 1}`}
        onClick={(event) => {
          event.stopPropagation();
          itemClickHandler(item)();
        }}
      >
        <Highlighter
          highlightClassName="react-dadata--highlighted"
          searchWords={Helper.getHighlightWords(highlightValue)}
          textToHighlight={item.value}
          autoEscape
        />
      </S.SelectButton>
    );
  });

  return (
    <S.Wrapper showAll={showAll}>
      {allItems}
      <S.PseudoButton type="button" onBlur={onBlur} />
    </S.Wrapper>
  );
};

DropDown.propTypes = {
  isHidden: PropTypes.bool,
  items: PropTypes.array,
  highlightValue: PropTypes.string,
  onClick: PropTypes.func,
};

export default DropDown;
