import styled from 'styled-components';
import { Button as SideBarButton } from '../../../Sidebar/style';
import { CheckSvg, UserPanelArrowDown, UserPanelArrowUp, UserPanelAvatar } from 'assets/img';
import { PALETTE } from 'styles/constants';

export const UserPanel = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  @media (max-height: 850px) {
    height: 80%;
  }
`;
export const WrapUp = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export const WrapDown = styled.div`
  display: flex;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
`;

export const Title = styled.div`
  font-family: Roboto;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.1;
  padding: 5px;
  white-space: pre-wrap;

  & h5 {
    color: ${PALETTE.grey1};
    font-weight: 500;
    font-size: 14px;
  }

  @media (max-height: 1000px) {
    font-size: 0.9rem;

    & h5 {
      font-size: 0.9rem;
    }
  }
`;

export const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  cursor: pointer;

  & img {
    width: 18px;
  }
`;

export const AvatarContainer = styled.div`
  margin-right: 8px;

  & img {
    border-radius: 50%;
    width: 34px;
    height: 34px;
    background-color: ${PALETTE.grey9};
  }
`;

export const Avatar = styled.img``;

export const AvatarSvgStyled = styled(UserPanelAvatar)``;

export const StyledArrowRight = styled(UserPanelArrowUp)`
  width: 15px;
  height: 15px;
  transform: rotate(90deg);

  & path,
  rect {
    fill: ${PALETTE.white};
  }
`;

export const StyledArrowLeft = styled(UserPanelArrowDown)`
  width: 15px;
  height: 15px;
  transform: rotate(90deg);
  margin-right: 4px;

  & path,
  rect {
    fill: ${PALETTE.white};
  }
`;

export const UserLinks = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  width: 100%;
  height: 100%;
  line-height: 18.75px;
  background-color: ${PALETTE.black2};
  padding: 0 16px 0 16px;
  border-radius: 0 16px 16px 0;

  & a,
  h5 {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 9px;
    color: ${PALETTE.white};
  }

  & ul {
    list-style-type: none;
    padding: 0px;
    max-height: 150px;
    padding-right: 10px;
    overflow-y: auto;

    scrollbar-width: thin;
    scrollbar-color: ${PALETTE.grey16} ${PALETTE.black2};
  }

  @media (max-height: 1035px) {
    & a,
    h5 {
      margin-bottom: 6px;
    }
  }

  @media (max-height: 930px) {
    & a,
    h5 {
      font-size: 0.9rem;
    }

    & ul {
      max-height: 100px;
    }
  }
`;

export const InfoWrapper = styled.div``;

export const StyledLi = styled.li`
  display: flex;
  align-items: center;
  padding: 10px 0px;
  font-weight: 400;
  font-size: 16px;
  color: ${PALETTE.grey1};
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);

  ${(p) => (p.checked ? `color: ${PALETTE.white};` : ``)}

  &:hover {
    cursor: pointer;
    color: ${PALETTE.grey14};
  }

  @media (max-height: 1035px) {
    font-size: 0.9rem;
  }
`;
export const CheckIco = styled(CheckSvg)`
  width: 11px;
  height: 11px;
  opacity: ${(p) => (p.checked ? `1` : `0`)};
  margin-left: auto;
  path {
    fill: ${PALETTE.white};
  }
`;
export const BackButton = styled(SideBarButton)`
  width: 100%;
  margin: 5px 0 16px 0;
`;
