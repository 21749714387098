import React, { useState } from 'react';

import * as S from './style';
import * as Helper from './helper';

const fn = () => {};

// structure of item is { id: number, fio: string, kbm: number | null, isActive: bool }

const Bubbles = ({
  items = [],
  onClick = fn,
  clickable = false,
  // showMultidrive = true,
  ...rest
}) => {
  const [allowHover, setAllowHover] = useState(true);

  const commonClickHandler = (evt) => {
    evt.target.blur();
    evt.currentTarget.blur();
  };

  const bubbleClickHandler = (id) => {
    if (clickable) {
      onClick(id);
    }
  };

  const mouseLeaveHandler = () => {
    setAllowHover(true);
  };

  const bubbles = items.map((item) => {
    return (
      <S.Bubble
        type="button"
        key={`${item.id}`}
        isActive={item.isActive}
        onClick={(evt) => {
          setAllowHover(false);
          commonClickHandler(evt);
          bubbleClickHandler(item.id);
        }}
        onMouseLeave={mouseLeaveHandler}
        clickable={clickable}
        allowHover={allowHover}
      >
        <S.BubbleText>{Helper.cutFio(item.fio)}</S.BubbleText>
        <S.BubbleKBM>{Helper.getKBMStr(item.kbm)}</S.BubbleKBM>
      </S.Bubble>
    );
  });

  return (
    <S.Wrapper {...rest}>
      {bubbles}
      {/* {showMultidrive && (
                <S.Bubble disabled>
                    <S.BubbleText>Мультидрайв</S.BubbleText>
                </S.Bubble>
            )} */}
    </S.Wrapper>
  );
};

export default Bubbles;
