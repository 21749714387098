export const carouselBreakPoints = {
  SMALL: '1000',
  LARGE: '1300',
};

export const carouselType = {
  DEFAULT: 0,
  RESPONSIVE: 1,
  FIXED_WIDTH: 2,
};

export const carouselOptions = [
  {
    items: 4,
    controls: false,
    nav: false,
    loop: false,
    gutter: 20,
    responsive: {
      1280: {
        items: 4,
      },
      1440: {
        items: 5,
      },
    },
  },
  {
    nav: false,
    loop: false,
    center: false,
    edgePadding: 0,
    items: 1,
    speed: 400,
    gutter: 20,
    responsive: {
      [carouselBreakPoints.SMALL]: {
        items: 2,
      },
      [carouselBreakPoints.LARGE]: {
        items: 3,
      },
    },
  },
  {
    fixedWidth: false,
    items: 3,
    swipeAngle: false,
    nav: false,
    loop: true,
    center: false,
    edgePadding: 0,
    speed: 400,
    gutter: 20,
  },
];
