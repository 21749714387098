import React, { useEffect } from 'react';
import request from 'utils/authRequest';
import { setAuthCookie, setIncCookies } from '../../../utils/helpers';
import * as localStorage from '../../../utils/local-storage';

export const Session = (props) => {
  const { history } = props;
  const idRe = /\?id=(.*)&/i;
  const targetRe = /target=(.*)/i;
  const id = history.location.search.match(idRe)[1];
  const target = history.location.search.match(targetRe)[1] + history.location.hash;

  useEffect(() => {
    request.get('/session/' + id).then((res) => {
      if (res.data.code === 200) {
        setAuthCookie(res.data.data.token, res.data.data.refresh);
        setIncCookies(res.data.data.cookies);
        localStorage.saveItem('token', res.data.data);
        localStorage.removeItem('blockLoginRedirect');
        window.location = target;
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <React.Fragment />;
};

export default { Session };
