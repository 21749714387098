export const getPosition = (
  position,
  containerWidth,
  containerHeight,
  textWidth,
  maxWidth,
  height,
  indent,
  padding = 10
) => {
  let top, left, right;

  const fullWidth = textWidth + padding * 2 + 4;
  const currentWidth = fullWidth > maxWidth || !fullWidth ? maxWidth : fullWidth;
  const widthDifference = containerWidth - currentWidth;
  const heightDifference = containerHeight - height;

  switch (position) {
    case 'top': {
      top = -height - indent;
      left = widthDifference / 2;
      break;
    }
    case 'left': {
      top = heightDifference / 2;
      left = -currentWidth - indent;
      break;
    }
    case 'right': {
      top = heightDifference / 2;
      right = -currentWidth - indent;
      break;
    }
    case 'bottom': {
      top = containerHeight + indent;
      left = widthDifference / 2;
      break;
    }
    default: {
      top = 0;
      left = 0;
    }
  }

  return { top, left, right, width: currentWidth, height };
};
