import styled, { css } from 'styled-components';

import * as UK from 'components/primitives/content';
import { FormGridRowBase, FormHeader } from '../style';
import { PALETTE } from 'styles/constants';
import { Box } from 'components/primitives/check-box/style';

const checkedBoxStyles = css`
  & svg * {
    fill: ${PALETTE.green4};
  }
  box-shadow: 0 0 6px 0 ${PALETTE.green4};
  border: 1px solid ${PALETTE.green4};
`;

export const Container = styled(FormGridRowBase)`
  grid-template-areas:
    '. hd hd hd hd hd hd .'
    '. cn cn cn cn cn cn .'
    '. sb sb sb sb sb sb .'
    '. dl dl dl dl dl dl .';
  margin: 10px 0px 50px 0px;
`;

export const Title = styled(FormHeader)`
  grid-area: hd;
  margin-bottom: 25px;
  text-align: left;
`;

export const Content = styled.div`
  grid-area: cn;
  margin-bottom: 25px;
`;

export const CheckBox = styled(UK.CheckBox)`
  height: 30px;
  ${(p) =>
    p.isPEPSignature &&
    css`
      & ${Box} {
        ${(p) => p.checked && checkedBoxStyles}
      }
    `}
`;

export const SignButton = styled(UK.BlueButton)`
  grid-area: sb;
`;

export const DocLink = styled(UK.Link)`
  padding-left: 5px;
  padding-right: 5px;
`;

export const DocumentsLinksWrap = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 10px;
  grid-area: dl;
`;
