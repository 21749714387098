import { defaultDriver, defaultInsurer } from './resources';

export const getUniqueCustomerIds = ({ nodes }) =>
  nodes.reduce((acc, node) => {
    const isUniqueCustomerId = !acc.find(
      (customerId) => String(customerId) === String(node.customerId)
    );

    if (isUniqueCustomerId) {
      acc.push(node.customerId);
    }

    return acc;
  }, []);

export const getChangedDrivers = (state, { key, value, id }) => {
  const drivers = [...state.drivers];
  const index = drivers.findIndex((it) => it.id === id);
  const driver = { ...drivers[index] };

  driver[key] = value;
  delete driver['empty'];

  drivers[index] = driver;

  return drivers;
};

export const createNewDriverId = (state, addingId = null) => {
  let id = null;

  if (addingId) {
    id = addingId;
  } else {
    const newDrivers = (state?.drivers || []).filter((d) => d.isNew);
    const highestId = newDrivers.reduce((acc, cur) => (Number(cur.id) > acc ? cur.id : acc), 0);
    return highestId + 1;
  }

  return id;
};

export const createNewDriver = (id, isEmpty = false, driverData = {}) => {
  const addons = isEmpty ? { empty: true } : {};

  return { ...defaultDriver, id, ...addons, ...driverData, isNew: true };
};

export const createInsurer = () => ({ ...defaultInsurer, insurerDadataInfo: {}, errors: {} });

export const createNewDriverFromConfidant = (driverData) => {
  const driver = {
    id: driverData.id,
    fio: driverData.fullName,
    bday: driverData.birthDate.replaceAll('.', ''),
    status: 'confidant',
  };

  return { ...defaultDriver, ...driver };
};
