import React, { useEffect, useState } from 'react';
import * as S from './style';

import { CloseSvg } from 'assets/img';
import { useSelector } from 'react-redux';
import { closeBanners, isBannersOpen } from 'store/mainpage';

export default function BetaTestBanner() {
  const [isShow, setIsShow] = useState(null);
  const { id: userId } = useSelector((state) => state.user);

  useEffect(() => {
    if (userId) {
      const shouldShow = isBannersOpen(userId);
      setIsShow(shouldShow);
    }
  }, [userId]);

  const handleClose = () => {
    closeBanners(userId);
    setIsShow(false);
  };

  return (
    isShow && (
      <S.MainContainer>
        <S.StringContainer>
          <S.BoldRow>Главная страница в режиме бета-тестирования</S.BoldRow>
          <S.Row>
            Некоторые функции могут работать некорректно, и возможны временные сбои. Мы благодарим
            вас за терпение и будем рады вашим отзывам!
          </S.Row>
        </S.StringContainer>
        <S.CloseButton onClick={handleClose}>
          <CloseSvg />
        </S.CloseButton>
      </S.MainContainer>
    )
  );
}
