import React from 'react';
import { useSelector } from 'react-redux';
import { removeExtraSpaces } from 'utils/stringUtils';
import { convertSysNameToSelectOption } from 'utils/dataMappers/mappers';
import { DaData } from 'components/primitives/dadata';

const InputFio = (props) => {
  const {
    className,
    changeHandler,
    structure: [fullName, lastName, firstName, middleName],
    valueGender,
    required = false,
    dataTest,
    ...rest
  } = props;

  const { genderList } = useSelector((state) => state.referenceBooks);

  const getGender = (target) => {
    const selectedGender =
      target?.data?.gender && target.data.gender !== 'UNKNOWN'
        ? target.data.gender.toLowerCase()
        : valueGender?.sysName || 'male';

    return { [`gender`]: convertSysNameToSelectOption(selectedGender, genderList) };
  };

  const getFioData = (target) => {
    if (target.data) {
      return {
        [fullName]: target.value,
        [lastName]: target.data.surname ?? '',
        [firstName]: target.data.name ?? '',
        [middleName]: target.data.patronymic ?? '',
        fromDadata: true,
        ...getGender(target),
      };
    } else {
      const fio = removeExtraSpaces(target.value);
      const [surname = '', name = '', ...partsOfPatronymic] = fio.split(' ');
      const patronymic = partsOfPatronymic.join(' ');
      return {
        [fullName]: target.value,
        [lastName]: surname,
        [firstName]: name,
        [middleName]: patronymic,
        fromDadata: false,
        ...getGender(target),
      };
    }
  };

  return (
    <DaData
      className={className}
      required={required}
      type="fio"
      onChange={(target) => {
        changeHandler(getFioData(target));
      }}
      dataTest={dataTest}
      {...rest}
    />
  );
};

export default InputFio;
