import React from 'react';
import { useToasts } from 'react-toast-notifications';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { isUseNewCalculator, isUsePEP } from 'store/user/selectors';

import { OldLinkContainer, ExtLink, CurrLink } from './style.js';

export const OldLinkWidget = ({ errors, selfAgreement, personalData, onRouteOut }) => {
  const history = useHistory();
  const { addToast, removeAllToasts } = useToasts();

  const { anketaFetch, isPEPSignature } = useSelector((state) => state.anketa);
  const { application } = useSelector((state) => state.startPage);
  const isCalculator = useSelector(isUseNewCalculator);
  const isPEP = useSelector(isUsePEP);

  const clickExtLinkHandler = (tab) => () => {
    if (!anketaFetch) {
      return;
    }
    if (tab === 1 && application?.id && application?.ids?.credit && isCalculator) {
      history.push(`/worksheet/${application.id}/calculator`);
      return null;
    } else if (tab === 3 && !selfAgreement && Object.keys(errors).length > 0) {
      removeAllToasts();
      addToast('В анкете присутствуют ошибки', { appearance: 'warning' });
      return null;
    } else if (tab === 3 && (!personalData || !isPEPSignature) && isPEP) {
      removeAllToasts();
      addToast('Нет документов о согласии', { appearance: 'warning' });
      return null;
    }

    onRouteOut(
      `${process.env.REACT_APP_STAGE_URL}/#application-details-new?app_id=${application.ids.credit}&tab=${tab}`
    );
  };

  return (
    <OldLinkContainer>
      <ExtLink onClick={clickExtLinkHandler(1)}>Рассчитать кредит</ExtLink>
      <CurrLink>Подготовить заявку</CurrLink>
      {/* <ExtLink onClick={clickExtLinkHandler(2)}>
				Старая анкета
			</ExtLink> */}
      <ExtLink onClick={clickExtLinkHandler(3)}>Рассмотрение заявки</ExtLink>
    </OldLinkContainer>
  );
};
