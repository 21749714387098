import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { InputMask } from 'utils/inputMask';
import { schemaTrusteeFullname, schemaSpouseBirthDate } from '../../../../Application/validation';

import { InfoSvg } from 'assets/img';
import { ReactTooltipStyled } from '../../../style';
import { FormSubheader, GridRowSubHeader } from '../../style';
import { FormGridRow, FioTrustee, CellPhone, PhoneInfo, Birthday } from './style';

export const relTypeFriend = {
  id: 745007,
  sysName: 'trustee_relation_friend',
  value: 'Друг / Знакомый',
};

export const TrusteesForm = ({
  onChange,
  onChangeSpouse,
  errors,
  validate,
  spouseForm,
  anketaSave,
  setFocusAt,
  contentHidden = false,
  disabled = false,
}) => {
  const keyComp = 'trustees';
  const keyCompSpouse = 'spouse';
  const [values, setValues] = useState(null);
  const [trusteeId, setTrusteeId] = useState(null);
  const [valuesSpouse, setValuesSpouse] = useState(null);

  const [error, setError] = useState({});
  const [initState, setInitState] = useState(true);
  const [showPhoneInfo, setShowPhoneInfo] = useState(false);
  const { persons, currentPersonId } = useSelector((state) => state.anketa);
  const { updateTrustees } = useSelector((state) => state.anketa.persons[currentPersonId]);
  const persona = persons[currentPersonId];

  const changeHandler = (val) => {
    let stateVal = { ...values };
    if (Array.isArray(val)) {
      val.forEach((value) => {
        stateVal = { ...value, ...stateVal };
      });
    } else {
      stateVal = { ...values, ...val };
    }
    setValues(stateVal);
    onChange({ values: stateVal, id: trusteeId });
  };

  const changeHandlerSpouse = (val) => {
    let stateVal = { ...valuesSpouse };
    if (Array.isArray(val)) {
      val.forEach((value) => {
        stateVal = { ...value, ...stateVal };
      });
    } else {
      stateVal = { ...valuesSpouse, ...val };
    }
    setValuesSpouse(stateVal);
    onChangeSpouse({ values: stateVal, key: keyCompSpouse });
  };

  const validation = (path) => {
    setError({ ...error, [path]: errors[path] || null });
    anketaSave();
  };

  const getError = (path) => {
    if (validate) {
      return errors[path];
    }
    return error[path];
  };

  useEffect(() => {
    if (persona && valuesSpouse !== persona[keyCompSpouse]) {
      setValuesSpouse(persona[keyCompSpouse]);
    }
    if (
      persona &&
      persona.trustees &&
      Object.keys(persona.trustees).length > 0 &&
      values !== persona.trustees[Object.keys(persona.trustees)[0]]
    ) {
      setTrusteeId(Object.keys(persona.trustees)[0]);
      setValues(persona.trustees[Object.keys(persona.trustees)[0]]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [persona, updateTrustees]);

  useEffect(() => {
    if (!spouseForm && persona && values && values.phone) {
      setShowPhoneInfo(values.phone === persona.contacts.mobilePhone);
    }
  }, [spouseForm, persona, values]);

  useEffect(() => {
    if (!!spouseForm && persona && valuesSpouse && valuesSpouse.phone) {
      setShowPhoneInfo(valuesSpouse.phone === persona.contacts.mobilePhone);
    }
  }, [spouseForm, persona, valuesSpouse]);

  useEffect(() => {
    if (!validate) {
      setError(
        Object.keys(error).reduce((obj, key) => ({ ...obj, [key]: errors[key] || null }), {})
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, validate]);

  useEffect(() => {
    if (initState) {
      setInitState(false);
    } else {
      const cleanData = {
        phone: '',
        firstName: '',
        lastName: '',
        middleName: '',
        fullName: '',
        birthDate: '',
      };
      changeHandlerSpouse(cleanData);
      changeHandler(cleanData);
      validation(`${keyComp}[0].fullName`);
      validation(`${keyComp}[0].phone`);
      validation(`${keyCompSpouse}.fullName`);
      validation(`${keyCompSpouse}.phone`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spouseForm]);

  return (
    <>
      <GridRowSubHeader>
        <FormSubheader>{spouseForm ? 'Данные о супруге' : 'Доверенное лицо'}</FormSubheader>
      </GridRowSubHeader>
      <FormGridRow>
        {values && !spouseForm && (
          <>
            <FioTrustee
              id={`${keyComp}_${trusteeId}_fullName`}
              mask={InputMask.RUS_NAME}
              changeHandler={(obj) => {
                if (obj.fromDadata) {
                  schemaTrusteeFullname
                    .validate(obj, { abortEarly: false })
                    .then(() => {
                      setFocusAt(`${keyComp}_${trusteeId}_phone_input`);
                    })
                    .catch(() => {});
                  delete obj.fromDadata;
                }
                changeHandler(obj);
              }}
              tabIndex={'54'}
              valueGender={values.gender}
              required
              defaultValue={values.fullName}
              onBlur={() => validation(`${keyComp}[0].fullName`)}
              error={getError(`${keyComp}[0].fullName`)}
              dataTest="confidant"
              disabled={disabled}
            />
            <>
              <CellPhone
                id={`${keyComp}_${trusteeId}_phone`}
                tabIndex="56"
                required
                name="phone"
                label="Мобильный телефон"
                defaultValue={
                  contentHidden ? InputMask.CELLPHONE_PRIVATE_NEW[0].hidden : values.phone
                }
                val={contentHidden ? InputMask.CELLPHONE_PRIVATE_NEW[0].hidden : values.phone}
                mask={
                  contentHidden
                    ? InputMask.CELLPHONE_PRIVATE_NEW[0].hidden
                    : InputMask.CELLPHONE_PRIVATE_NEW
                }
                onChange={({ phone }) =>
                  phone === '+7 (___) ___-__-__'
                    ? changeHandler({ phone: '' })
                    : changeHandler({ phone })
                }
                onBlur={() => validation(`${keyComp}[0].phone`)}
                error={getError(`${keyComp}[0].phone`)}
                disabled={disabled || contentHidden}
                dataTest="confidantPone"
              />
              <PhoneInfo showPhoneInfo={true}>
                <span style={{ minHeight: '45px' }}>
                  {showPhoneInfo &&
                    !getError(`${keyComp}[0].phone`) &&
                    'Упс! Банки не смогут рассмотреть заявку'}
                </span>
                {showPhoneInfo && !getError(`${keyComp}[0].phone`) && (
                  <>
                    <InfoSvg data-tip="" data-for="trusteePhoneInfo" />
                    <ReactTooltipStyled id="trusteePhoneInfo" place="right">
                      По требованию банков телефон контактного лица
                      <br />
                      не должен совпадать с личным мобильным
                    </ReactTooltipStyled>
                  </>
                )}
              </PhoneInfo>
            </>
          </>
        )}
        {valuesSpouse && spouseForm && (
          <>
            <FioTrustee
              isSpouse={true}
              id={`${keyCompSpouse}_fullName`}
              mask={InputMask.RUS_NAME}
              changeHandler={(obj) => {
                if (obj.fromDadata) {
                  schemaTrusteeFullname
                    .validate(obj, { abortEarly: false })
                    .then(() => {
                      setFocusAt(`${keyCompSpouse}_birthDate_input`);
                    })
                    .catch(() => {});
                  delete obj.fromDadata;
                }
                changeHandlerSpouse(obj);
              }}
              valueGender={valuesSpouse.gender}
              tabIndex={'42'}
              required
              defaultValue={valuesSpouse.fullName}
              onBlur={() => validation(`${keyCompSpouse}.fullName`)}
              error={getError(`${keyCompSpouse}.fullName`)}
              disabled={disabled}
            />
            <Birthday
              id={`${keyCompSpouse}_birthDate`}
              tabIndex="43"
              name="birthDate"
              label="Дата рождения"
              defaultValue={
                contentHidden
                  ? InputMask.DATE_BEFORE_TODAY_CUSTOMER[0].hidden
                  : valuesSpouse.birthDate
              }
              val={
                contentHidden
                  ? InputMask.DATE_BEFORE_TODAY_CUSTOMER[0].hidden
                  : valuesSpouse.birthDate
              }
              mask={
                contentHidden
                  ? InputMask.DATE_BEFORE_TODAY_CUSTOMER[0].hidden
                  : InputMask.DATE_BEFORE_TODAY_CUSTOMER
              }
              unmask={!contentHidden}
              onChange={({ birthDate }) => {
                const date = birthDate.length === 2 ? '' : birthDate;
                changeHandlerSpouse({ birthDate: date });
              }}
              onComplete={(birthDate) => {
                schemaSpouseBirthDate
                  .validate(birthDate, { abortEarly: false })
                  .then(() => {
                    setFocusAt(`${keyCompSpouse}_phone_input`);
                  })
                  .catch(() => {});
              }}
              onBlur={() => validation(`${keyCompSpouse}.birthDate`)}
              error={getError(`${keyCompSpouse}.birthDate`)}
              disabled={disabled || contentHidden}
            />
            <>
              <CellPhone
                isSpouse={true}
                id={`${keyCompSpouse}_phone`}
                tabIndex="46"
                required
                name="phone"
                label="Мобильный телефон"
                defaultValue={
                  contentHidden ? InputMask.CELLPHONE_PRIVATE_NEW[0].hidden : valuesSpouse.phone
                }
                val={contentHidden ? InputMask.CELLPHONE_PRIVATE_NEW[0].hidden : valuesSpouse.phone}
                mask={
                  contentHidden
                    ? InputMask.CELLPHONE_PRIVATE_NEW[0].hidden
                    : InputMask.CELLPHONE_PRIVATE_NEW
                }
                onChange={({ phone }) =>
                  phone === '+7 (___) ___-__-__'
                    ? changeHandlerSpouse({ phone: '' })
                    : changeHandlerSpouse({ phone })
                }
                onBlur={() => validation(`${keyCompSpouse}.phone`)}
                error={getError(`${keyCompSpouse}.phone`)}
                disabled={disabled || contentHidden}
              />
              <PhoneInfo showPhoneInfo={true} isSpouse={true}>
                <span style={{ minHeight: '45px' }}>
                  {showPhoneInfo &&
                    !getError(`${keyCompSpouse}.phone`) &&
                    'Упс! Банки не смогут рассмотреть заявку'}
                </span>
                {showPhoneInfo && !getError(`${keyCompSpouse}.phone`) && (
                  <>
                    <InfoSvg data-tip="" data-for="spousePhoneInfo" />
                    <ReactTooltipStyled id="spousePhoneInfo" place="right">
                      По требованию банков телефон контактного лица
                      <br />
                      не должен совпадать с личным мобильным
                    </ReactTooltipStyled>
                  </>
                )}
              </PhoneInfo>
            </>
          </>
        )}
      </FormGridRow>
    </>
  );
};
