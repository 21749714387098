import bankLogos from 'assets/img/banks';

export const BANKS = [
  {
    name: 'ЮниКредит Банк',
    carfinId: '304',
    cpsId: '304',
    ppsId: '304',
    logo: bankLogos['9'],
    color: '#D00A11',
  },
  {
    name: 'Банк УРАЛСИБ',
    carfinId: '273',
    cpsId: '273',
    ppsId: '273',
    logo: bankLogos['11'],
    color: '#4AB472',
  },
  {
    name: 'Локо-Банк',
    carfinId: '245',
    cpsId: '245',
    ppsId: '245',
    logo: bankLogos['15'],
    color: '#FFEB3B',
  },
  {
    name: 'ВТБ',
    carfinId: '289',
    cpsId: '289',
    ppsId: '289',
    logo: bankLogos['17'],
    color: '#0D2B74',
  },
  {
    name: 'Росбанк Авто',
    carfinId: '837',
    cpsId: '837',
    ppsId: '837',
    logo: bankLogos['35'],
    color: '#E40038',
  },
  {
    name: 'Кредит Европа Банк',
    carfinId: '248',
    cpsId: '248',
    ppsId: '248',
    logo: bankLogos['36'],
    color: '#E2001A',
  },
  {
    name: 'Тойота Банк',
    carfinId: '250',
    cpsId: '250',
    ppsId: '250',
    logo: bankLogos['49'],
    color: '#C60007',
  },
  {
    name: 'Драйв Клик Банк',
    carfinId: '290',
    cpsId: '290',
    ppsId: '290',
    logo: bankLogos['52'],
    color: '#3BAE61',
  },
  {
    name: 'Совкомбанк',
    carfinId: '291',
    cpsId: '291',
    ppsId: '291',
    logo: bankLogos['96'],
    color: '#EC1D23',
  },
  {
    name: 'Банк Союз',
    carfinId: '377',
    cpsId: '377',
    ppsId: '377',
    logo: bankLogos['112'],
    color: '#F36F21',
  },
  {
    name: 'БыстроБанк',
    carfinId: '379',
    cpsId: '379',
    ppsId: '379',
    logo: bankLogos['115'],
    color: '#F78F1F',
  },
  {
    name: 'АбсолютБанк',
    carfinId: '382',
    cpsId: '382',
    ppsId: '382',
    logo: bankLogos['123'],
    color: '#F26422',
  },
  {
    name: 'Авто Финанс Банк',
    carfinId: '255',
    cpsId: '255',
    ppsId: '255',
    logo: bankLogos['141'],
    color: '#4F5964',
  },
  {
    name: 'МС Банк Рус',
    carfinId: '268',
    cpsId: '268',
    ppsId: '268',
    logo: bankLogos['295'],
    color: '#DA1E32',
  },
  {
    name: 'РНКБ Банк',
    carfinId: '534',
    cpsId: '534',
    ppsId: '534',
    logo: bankLogos['393'],
    color: '#00A6A6',
  },
  {
    name: 'Экспо Банк',
    carfinId: '561',
    cpsId: '561',
    ppsId: '561',
    logo: bankLogos['408'],
    color: '#F79D53',
  },
  {
    name: 'Тинькофф Банк',
    carfinId: '661',
    cpsId: '661',
    ppsId: '661',
    logo: bankLogos['474'],
    color: '#FEDD2C',
  },
  {
    name: 'ПримСоц Банк',
    carfinId: '665',
    cpsId: '665',
    ppsId: '665',
    logo: bankLogos['475'],
    color: '#428A37',
  },
  {
    name: 'Банк Зенит',
    carfinId: '673',
    cpsId: '673',
    ppsId: '673',
    logo: bankLogos['479'],
    color: '#0D0942',
  },
  {
    name: 'Ренессанс кредит',
    carfinId: '685',
    cpsId: '685',
    ppsId: '685',
    logo: bankLogos['483'],
    color: '#E34389',
  },
  {
    name: 'Банк Санкт-Петербург',
    carfinId: '693',
    cpsId: '693',
    ppsId: '693',
    logo: bankLogos['491'],
    color: '#E62833',
  },
  {
    name: 'ОТП Банк',
    carfinId: '694',
    cpsId: '694',
    ppsId: '694',
    logo: bankLogos['492'],
    color: '#00674C',
  },
  {
    name: 'Грин Финанс',
    carfinId: '782',
    cpsId: '782',
    ppsId: '782',
    logo: bankLogos['525'],
    color: '#77004F',
  },
  {
    name: 'Открытие',
    carfinId: '797',
    cpsId: '797',
    ppsId: '797',
    logo: bankLogos['535'],
    color: '#00C3F7',
  },
  {
    name: 'Роял Капитал',
    carfinId: '819',
    cpsId: '819',
    ppsId: '819',
    logo: bankLogos['552'],
    color: '#FEDF01',
  },
  {
    name: 'ГЕНБАНК',
    carfinId: '821',
    cpsId: '821',
    ppsId: '821',
    logo: bankLogos['556'],
    color: '#002957',
  },
  {
    name: 'Газпромбанк',
    carfinId: '849',
    cpsId: '849',
    ppsId: '849',
    logo: bankLogos['563'],
    color: '#1F3363',
  },
  {
    name: 'Почта Банк',
    carfinId: '858',
    cpsId: '858',
    ppsId: '858',
    logo: bankLogos['565'],
    color: '#CC003D',
  },
  {
    name: 'Альфа-Банк',
    carfinId: '851',
    cpsId: '851',
    ppsId: '851',
    logo: bankLogos['566'],
    color: '#B52B1B',
  },
  {
    name: 'КВАНТ МОБАЙЛ БАНК',
    carfinId: '852',
    cpsId: '852',
    ppsId: '852',
    logo: bankLogos['567'],
    color: '#FE5000',
  },
  {
    name: 'Руснарбанк',
    carfinId: '857',
    cpsId: '857',
    ppsId: '857',
    logo: bankLogos['568'],
    color: '#0A9AD6',
  },
  {
    name: 'Совком банк',
    carfinId: '862',
    cpsId: '862',
    ppsId: '862',
    logo: bankLogos['96'],
    color: '#EC1D23',
  },
  {
    name: 'BMW Банк',
    carfinId: '677',
    cpsId: '677',
    ppsId: '677',
    logo: bankLogos['480'],
    color: '#0076B3',
  },
  {
    name: 'Мерседес-Бенц Банк Рус',
    carfinId: '781',
    cpsId: '781',
    ppsId: '781',
    logo: bankLogos['487'],
    color: '#46555E',
  },
  {
    name: 'Фольксваген Банк',
    carfinId: '786',
    cpsId: '786',
    ppsId: '786',
    logo: bankLogos['527'],
    color: '#176EB1',
  },
  {
    name: 'Цифра Банк',
    carfinId: '859',
    cpsId: '859',
    ppsId: '859',
    logo: bankLogos['569'],
    color: '#0AE15F',
  },
  {
    name: 'Азиатско-Тихоокеанский Банк',
    carfinId: '867',
    cpsId: '867',
    ppsId: '867',
    logo: bankLogos['571'],
    color: '#FE5000',
  },
  {
    name: 'Автоторгбанк',
    carfinId: '868',
    cpsId: '868',
    ppsId: '868',
    logo: bankLogos['572'],
    color: '#A6CF3A',
  },
  {
    name: 'Первое Мурманское общество взаимного кредита',
    carfinId: '888',
    cpsId: '888',
    ppsId: '888',
    logo: bankLogos['591'],
    color: '#069856',
  },
];
