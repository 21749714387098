export const TabsHeaders = [
  {
    id: 1,
    label: 'Кредит',
    link: '/home',
    routeLinks: ['/worksheet/:id/home', '/worksheet/:id/calculator', '/worksheet/:id/loan-issue'],
  },
  {
    id: 2,
    label: 'Выпустить карту',
    link: '/assistance',
    routeLinks: ['/worksheet/:id/assistance'],
  },
  {
    id: 3,
    label: 'СуперКаско',
    link: '/insurance',
    routeLinks: ['/worksheet/:id/insurance'],
  },
  // CREDIT-72 Hide service tab
  /*
  {
    id: 3,
    label: 'Сервис меню',
    link: '/service',
    routeLinks: ['/worksheet/:id/service'],
  },
  */
];
