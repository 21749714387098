import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-position: 50% 50%;
  background-image: url('${(p) => p.img}');
  background-size: cover;
`;

export const LinkWrapper = styled.a`
  display: block;
  width: 100%;
  height: 100%;
  background-position: 50% 50%;
  background-image: url('${(p) => p.img}');
  background-size: contain;
  background-repeat: no-repeat;
`;
