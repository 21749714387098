import React, { useState, useEffect, useRef, forwardRef } from 'react';
import PulseLoader from 'react-spinners/PulseLoader';
import PropTypes from 'prop-types';
import { tabIndexOnEnter } from 'utils/common';
import { Wrapper, ContentWrapper, InputStyled, Label, Prompt, PulseLoaderStyled } from './style';

const fn = () => {};

export const InputMask = forwardRef((props, ref) => {
  const {
    name,
    label,
    disabled,
    required,
    defaultValue = '',
    val = '',
    mask,
    tabIndex = '',
    unmask,
    onChange = fn,
    onComplete = fn,
    onBlur = fn,
    onFocus = fn,
    onClick = fn,
    onKeyDown = fn,
    tabOnEnter = false,
    id,
    className,
    autoFocus = false,
    error,
    fetch = false,
    placeholder = '',
    hidePlaceholder = true,
    isControlledComponent = false,
    dataTest,
  } = props;
  const inputRef = useRef();
  const [focused, setFocused] = useState(false);
  const [inputString, setInputString] = useState(val || defaultValue || '');

  const currentRef = isControlledComponent ? ref || inputRef : inputRef;

  const handleBlur = (e) => {
    e.stopPropagation();
    setFocused(false);

    onBlur(name ? { [name]: inputString } : inputString);
  };

  const handleChange = (value) => {
    const onlyMaskValue =
      mask[0].blocks &&
      mask[0].pattern &&
      Object.keys(mask[0].blocks).reduce((acc, key) => {
        const replacedString = Array.from(key)
          .map(() => '_')
          .join('');
        const regexp = new RegExp(key, 'g');

        return acc.replace(regexp, replacedString);
      }, mask[0].pattern);

    const unmaskedValue = value !== onlyMaskValue ? value : '';
    if (!unmaskedValue) {
      setInputString(unmaskedValue);
    }

    onChange(name ? { [name]: unmaskedValue } : unmaskedValue);
  };

  const handleComplete = (value) => {
    onComplete(name ? { [name]: value } : value);
  };

  const clickHandler = (e) => {
    onClick(e);
    wrapperClick();
  };

  const keyDownHandler = (e) => {
    if (tabOnEnter) {
      tabIndexOnEnter(e);
    }
    return onKeyDown(e);
  };

  const wrapperClick = () => {
    if (!disabled) {
      if (currentRef && currentRef.current) {
        currentRef.current.element.focus();

        if (isControlledComponent ? !val : !inputString) {
          currentRef.current.element.setSelectionRange(0, 0);
        }
      }

      setFocused(true);
    }
  };

  const handleFocus = (e) => {
    e.stopPropagation();
    wrapperClick();
    onFocus();
  };

  useEffect(() => {
    if (defaultValue !== null && defaultValue.toString().length > 0) {
      setFocused(true);
    }
  }, [defaultValue, placeholder]);

  useEffect(() => {
    setInputString(val);
  }, [val]);

  return (
    <Wrapper
      id={id}
      required={required && error}
      disabled={disabled}
      focused={!!focused}
      onClick={clickHandler}
      className={className}
      error={error}
    >
      <ContentWrapper>
        {label && (
          <Label error={error} isFocused={!!(focused || inputString)}>
            {label}
            {required && <sup>*</sup>}
          </Label>
        )}
        {mask && (
          <InputStyled
            ref={currentRef}
            tabIndex={tabIndex}
            autoFocus={autoFocus}
            mask={mask}
            unmask={unmask}
            onAccept={handleChange}
            onComplete={handleComplete}
            onBlur={handleBlur}
            onFocus={handleFocus}
            onKeyDown={keyDownHandler}
            label={label}
            defaultValue={(defaultValue !== null && defaultValue.toString()) || ''}
            value={isControlledComponent ? val : inputString}
            disabled={disabled}
            placeholder={placeholder}
            ishidden={hidePlaceholder && !inputString && !focused ? 'true' : 'false'}
            data-test={dataTest}
          />
        )}
      </ContentWrapper>
      {!disabled && error && <Prompt>{error ? error : ''}</Prompt>}
      {fetch && <PulseLoader css={PulseLoaderStyled} size={10} color={'#b5b5b5'} loading={true} />}
    </Wrapper>
  );
});

InputMask.propTypes = {
  name: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  label: PropTypes.any,
  defaultValue: PropTypes.any,
  mask: PropTypes.any,
  unmask: PropTypes.bool,
  className: PropTypes.string,
  error: PropTypes.string,
  height: PropTypes.number,
  isControlledComponent: PropTypes.bool,
  onKeyDown: PropTypes.func,
  autoFocus: PropTypes.bool,
};

InputMask.displayName = 'InputMask';

export default InputMask;
