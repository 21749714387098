import React from 'react';
import PropTypes from 'prop-types';
import DriverInfo from './components/DriverInfo';
import { DriverLicense } from './components/DriversLicense';
import { Wrapper } from './style';

export const DriverForm = ({
  counter,
  driver,
  scrollTo,
  onScroll,
  onDelete,
  onBlur,
  onSwitch,
  errors,
  disabled = false,
}) => {
  const innerProps = { driver, scrollTo, errors, onBlur, onScroll, disabled };

  return (
    <Wrapper>
      <DriverInfo {...innerProps} onDelete={onDelete} counter={counter} />
      <DriverLicense {...innerProps} onSwitchCheckbox={onSwitch} />
    </Wrapper>
  );
};

DriverForm.propTypes = {
  counter: PropTypes.number,
  driver: PropTypes.shape({
    driverFio: PropTypes.string,
    driverBday: PropTypes.string,
    driverGender: PropTypes.string,
    licenseSeriaNum: PropTypes.string,
    driverLicenseDate: PropTypes.string,
    isFirstLicense: PropTypes.bool,
    prevLicenseSeriaNum: PropTypes.string,
    prevDriverLicenseDate: PropTypes.string,
  }),
  errors: PropTypes.object,
  onDelete: PropTypes.func,
  onBlur: PropTypes.func,
  onSwitch: PropTypes.func,
};
