import request from 'utils/request';
import throwCustomError from 'utils/throwCustomError';
import * as urls from 'constants/url';
import { configWithHeadersLdJSON } from './common';
import { EOW_NAMEPLATES } from 'store/insuranceCalculation/resources';

export const getOrderById = async (id) => {
  try {
    const { data } = await request.get(`/api/ors/orders/${id}`).catch(throwCustomError);
    return data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const eowAddNewOrder = async ({ carInstanceId, dealerId, customerId }) => {
  try {
    const data = await request
      .post('/api/ors/orders/insurance/add', {
        carInstanceId,
        dealerId,
        customerId,
      })
      .catch(throwCustomError);

    return data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const getApplicationDataRequest = async (applicationId) => {
  try {
    const url = urls.EOW_GET_APPLICATION_URL;

    const { data } = await request
      .post(url, { applicationId }, configWithHeadersLdJSON)
      .catch(throwCustomError);

    if (!data?.application) {
      return {
        applicationParams: {},
        offers: [],
        nameplate: EOW_NAMEPLATES.unknown,
      };
    }

    const applicationData = data.application;

    if (!applicationData.applicationParams) {
      applicationData.applicationParams = {};
    }

    if (!applicationData.offers) {
      applicationData.offers = [];
    }

    if (!applicationData.state?.sysName) {
      applicationData.state = EOW_NAMEPLATES.unknown;
    }

    return applicationData;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const eowCalculateRequest = async (params) => {
  try {
    const url = urls.EOW_CALCULATE_URL;

    const response = await request
      .post(url, params, configWithHeadersLdJSON)
      .catch(throwCustomError);

    return response;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const getDealerLegalsRequest = async (dealerId) => {
  try {
    const { data } = await request.get(urls.GET_DEALER_INFO_URL(dealerId)).catch(throwCustomError);

    return data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const selectOfferRequest = async (params) => {
  try {
    const url = urls.EOW_SELECT_OFFER_URL;

    const { data } = await request
      .post(url, params, configWithHeadersLdJSON)
      .catch(throwCustomError);

    return data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const agreementRequest = async (params) => {
  try {
    const url = urls.EOW_AGREEMENT_URL;

    const { data } = await request
      .post(url, params, configWithHeadersLdJSON)
      .catch(throwCustomError);

    return data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const getPolicyRequest = async (params) => {
  try {
    const url = urls.EOW_GET_POLICY_URL;

    const { data } = await request
      .post(url, params, configWithHeadersLdJSON)
      .catch(throwCustomError);

    return data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const addFileEOWRequest = async (applicationId, fileId, documentType, ext) => {
  try {
    const data = { applicationId, fileId, documentType, ext };
    const res = await request.post(urls.INSURANCE_ADD_FILE_URL, data).catch(throwCustomError);

    return res.data || null;
  } catch (err) {
    console.error(err.message);
    throw err;
  }
};

export const delFileEOWRequest = async (id) => {
  try {
    const data = { id };

    const res = await request.post(urls.INSURANCE_DEL_FILE_URL, data).catch(throwCustomError);

    return res.data || null;
  } catch (err) {
    console.error(err.message);
    throw err;
  }
};

export const eowClientDeclineRequest = async ({ applicationId }) => {
  try {
    const url = urls.EOW_CLIENT_DECLINE_URL;

    const { data } = await request
      .post(url, { applicationId }, configWithHeadersLdJSON)
      .catch(throwCustomError);

    return data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const eowCancellationRequest = async (applicationId) => {
  const url = 'api/insurance/cancelation';
  const res = await request.post(url, { applicationId }).catch(throwCustomError);

  return res.data || null;
};

export const reloadEOWDocumentsRequest = async (applicationId) => {
  const url = 'api/insurance/reload-documents';
  const res = await request.post(url, { applicationId }).catch(throwCustomError);

  return res.data || null;
};

export const getBanksList = async (dealerId, priority = false) => {
  try {
    let url = `api/provider-parameter/dealer-bank?dealerId=${dealerId}`;

    if (priority) {
      url += '&order[bankPriority]=asc&perPage=9999';
    }

    const { data } = await request.get(url).catch(throwCustomError);

    return data;
  } catch (err) {
    console.log('GET BANKS LIST ERROR ***', err);
    throw err;
  }
};

export const getInsuranceCalculationApplicatioIdByOrders = async ({
  carInstanceId,
  dealerId,
  customerId,
}) => {
  try {
    const { data } = await request
      .post('/api/ors/orders/insurance', {
        carInstanceId,
        dealerId,
        customerId,
      })
      .catch(throwCustomError);

    return data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};
