import React, { useState, useEffect, useRef } from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import animateScrollTo from 'animated-scroll-to';

import {
  dropError,
  patchCarInstance,
  changeCarInstance,
  setCarInstance,
  updateErrors,
  clearScroll,
  openCarInstance,
  closeCarInstance,
  setCarHiddenFields,
  setCarDisableFields,
  deleteCarDisableFields,
} from 'store/carInstance';

import * as ValidationHelper from '../../../CarDetail/validator/validationHelper';
import {
  schemaVin,
  schemaCarDetail,
  schemaPatchCarDetail,
} from '../../../CarDetail/validator/validations';

import Tabs from '../Tabs/Tabs';
import { CarImage } from '../../../CarImage';
import * as S from '../../../../style';
import { FIELDS } from 'store/carInstance/constants';
import { BLOCK_CAR_SELECT } from './constants';

export const Calculations = ({ eosagoRef, onChangeCarModel = () => {} }) => {
  const [hiddenFields, setHiddenFields] = useState();
  const rootDispatch = useDispatch();
  const ref = useRef();

  const { id } = useParams();
  const { path } = useRouteMatch();
  const isInsurance = path.includes('/insurance');
  const isAssistance = path.includes('/assistance');

  // eslint-disable-next-line no-unused-vars
  const [showCarImage, setShowCarImage] = useState(!path.includes('/create-new'));
  // eslint-disable-next-line no-unused-vars
  const [currentChangedCarModel, setCurrentChangedCarModel] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [resize, setResize] = useState({});
  const [ignoredFields, setIgnoredFields] = useState([]);

  const { scrollTo, fetch, carInstance, isOpenBlock, errors, isDisable, disableFields } =
    useSelector((state) => state.carInstance);
  const dealStatesByName = useSelector((state) => state.startPage?.application?.dealStatesByName);
  const dealClosed = disableFields.includes(FIELDS.vin);
  const dropCarInstanceErrorsByMount = !isAssistance;

  const { brand, model, offerPrice, isNew } = carInstance;

  let carName = brand && model ? `${brand} ${model}` : '';
  let carPrice = offerPrice ? offerPrice.toLocaleString() : '';
  if (path.includes('/create-new') || isDisable) {
    carName =
      carInstance.brand && carInstance.model ? `${carInstance.brand} ${carInstance.model}` : '';
    carPrice = carInstance.offerPrice ? carInstance.offerPrice.toLocaleString() : '';
  }

  useEffect(() => {
    if (path.includes('/create-new')) {
      rootDispatch(openCarInstance());
      setShowCarImage(false);
      setCurrentChangedCarModel(false);
    } else {
      setShowCarImage(true);
      setCurrentChangedCarModel(false);
    }

    if (path.includes('/calculator')) {
      const hiddenFields = [
        FIELDS.mileage,
        FIELDS.enginePower,
        FIELDS.engineNumber,
        FIELDS.bodyNumber,
        FIELDS.permittedMaxWeight,
        FIELDS.engineVolume,
        FIELDS.glonasNumber,
        FIELDS.pts,
      ];
      const disableFields = [];
      if (!isNew) {
        disableFields.push(FIELDS.version);
      } else {
        rootDispatch(deleteCarDisableFields([FIELDS.version]));
      }
      rootDispatch(setCarDisableFields(disableFields));

      setHiddenFields([...hiddenFields, 'headDetail']);
      setIgnoredFields(hiddenFields);

      return;
    }

    if (isAssistance || isInsurance) {
      const hiddenFields = [FIELDS.offerPrice];
      rootDispatch(deleteCarDisableFields([FIELDS.version]));
      setHiddenFields(hiddenFields);
      setIgnoredFields([...hiddenFields, FIELDS.enginePower]);

      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path, isNew]);

  useEffect(() => {
    const resizeFn = debounce(() => {
      setResize({});
    }, 100);

    window.addEventListener('resize', resizeFn);

    return () => {
      window.removeEventListener('resize', resizeFn);
      rootDispatch(setCarHiddenFields([]));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (scrollTo === BLOCK_CAR_SELECT) {
      rootDispatch(openCarInstance());

      if (ref && ref.current) {
        animateScrollTo(ref.current);
        rootDispatch(clearScroll());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollTo]);

  useEffect(() => {
    if (dealStatesByName?.closed) {
      const fields = [FIELDS.vin];
      rootDispatch(setCarDisableFields(fields));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealStatesByName]);

  const collapseBlockHandler = (state) => {
    rootDispatch(state ? openCarInstance() : closeCarInstance());
  };

  const changeCarModelHandler = () => {
    onChangeCarModel();
    setShowCarImage(true);
    setCurrentChangedCarModel(true);
  };

  const changeVinHandler = (name, catalog = '') => {
    rootDispatch(dropError(name, catalog));
  };

  const updateErrorsHandler = (key, value) => {
    rootDispatch(updateErrors({ ...errors, [key]: value }));
  };

  const validateHandler = async (value, key, innerCarInstance) => {
    try {
      const validatingData = { ...innerCarInstance, [key]: value };

      const storedCarInstance = ValidationHelper.getCarInstanceForValidation(validatingData);

      await schemaCarDetail.validate(storedCarInstance, { abortEarly: false });

      updateErrorsHandler(key, '');

      return true;
    } catch (err) {
      const objectedErrors = ValidationHelper.collectObjectedErrors(err);

      let error = objectedErrors[key];

      if (!error) {
        return true;
      }

      updateErrorsHandler(key, error);

      return false;
    }
  };

  const blurVinHandler = async (items) => {
    rootDispatch(setCarInstance({ ...carInstance, ...items }));

    const instance = { ...JSON.parse(JSON.stringify(carInstance)), ...items };
    const loadImage = undefined;
    const phases = undefined;
    const phPayload = undefined;

    if (!path.includes('/worksheet/create-new')) {
      if (schemaPatchCarDetail.isValidSync(instance, { abortEarly: true })) {
        const isCustomer = path.includes('/worksheet/') || path.includes('/worksheet/create-new');
        rootDispatch(patchCarInstance(instance, id, loadImage, phases, phPayload, isCustomer));
      } else {
        rootDispatch(changeCarInstance(instance, id, loadImage, phases, phPayload));
      }
    } else {
      rootDispatch(changeCarInstance(instance, id, loadImage, phases, phPayload));
    }

    Object.keys(items).forEach((key) => validateHandler(items[key], key, carInstance));

    const schema = items.vin ? schemaVin : null;
    if (schema && !path.includes('/calculator')) {
      schema
        .validate(items, { abortEarly: false })
        .then(() => {
          rootDispatch(openCarInstance());
          rootDispatch(
            setCarInstance({
              ...carInstance,
              ...items,
              checkAutoCode: true,
            })
          );
        })
        .catch(() => {});
    }
  };

  const steeringWheelChangeHandler = (value) => {
    rootDispatch(dropError('vin'));
    rootDispatch(dropError('registrationNumber'));
    const newData = {
      ...carInstance,
      steeringWheel: value ? 'right' : 'left',
      vin: '',
      registrationNumber: '',
      bodyNumber: '',
    };
    rootDispatch(setCarInstance(newData));
    const isCustomer = path.includes('/worksheet/') || path.includes('/worksheet/create-new');
    rootDispatch(patchCarInstance(newData, id, undefined, undefined, undefined, isCustomer));
  };

  const title = carName ? (
    <S.SelectedCar ref={ref}>
      <S.SelectedCarMain>
        <S.CarHeader>
          <S.BrandModelCar>{carName}</S.BrandModelCar>
          {/*
            <S.Version>{(path.includes('/create-new') ? carInstance.version : version) || ''}</S.Version>
          */}
        </S.CarHeader>
        {carPrice && <S.Cost>{carPrice.toLocaleString()} &#8381;</S.Cost>}
      </S.SelectedCarMain>

      <S.SelectedCarDetail
        carInstance={carInstance}
        error={errors}
        isDisabled={isDisable || dealClosed}
        onChange={changeVinHandler}
        onBlur={blurVinHandler}
        withSteeringWheel={isInsurance || isAssistance}
        onSteeringWheelChange={steeringWheelChangeHandler}
      />
    </S.SelectedCar>
  ) : (
    <S.Header>Выберите автомобиль</S.Header>
  );

  return (
    <S.GridStyled>
      <S.CollapsePanelStyled
        collapsed={isOpenBlock}
        onCollapse={collapseBlockHandler}
        isControlledComponent
        isHeaderHidden={fetch}
        title={title}
        topware={
          <S.CarImgWrapper>
            <CarImage />
          </S.CarImgWrapper>
        }
      >
        <S.CarDetailStyled
          id={id}
          customerCarInstance={true}
          showCarNumberField={false}
          hiddenFields={hiddenFields}
          ignoredFields={ignoredFields}
          onChangeCarModel={changeCarModelHandler}
          dropErrorsByMount={dropCarInstanceErrorsByMount}
        />
      </S.CollapsePanelStyled>

      <Tabs eosagoRef={eosagoRef} errors={null} id={id} />
      {/*errorElement*/}
    </S.GridStyled>
  );
};

export default Calculations;
