import { put, select, call, all } from 'redux-saga/effects';
import { frontToBackCustomer } from './frontToBackMapper';
import backToFrontMapper from './backToFrontMapper';

import * as actions from './constants';
import * as insuranceActions from 'store/insuranceCalculation/constants';
import * as carInstanceActions from 'store/carInstance/constants';
import * as carInstanceProvider from 'data-providers/carInstanceProvider';
import * as anketaProvider from 'data-providers/insuranceAnketa';
import { DEFAULT_ERROR_MESSAGE } from 'constants/index';
import { SAVING_EXCEPTIONS } from './resources';

export function* saveCarDetail({ payload: { anketaId, isCashCustomer } }) {
  const { carInstance } = yield select((store) => store.carInstance);

  const updateCarInstance = isCashCustomer
    ? carInstanceProvider.updateCarInstanceCustomer
    : carInstanceProvider.updateCarInstance;

  const newCarInstance = yield call(updateCarInstance, {
    carInstance: {
      ...carInstance,
      price: Number(carInstance.price) || 0,
      enginePower: Number(carInstance.enginePower) || 0,
      engineNumber: carInstance.engineNumber || '',
      bodyNumber: carInstance.bodyNumber || '',
      permittedMaxWeight: Number(carInstance.permittedMaxWeight) || 0,
      engineVolume: Number(carInstance.engineVolume) || 500,
      glonasNumber: carInstance.glonasNumber || '',
    },
    id: anketaId,
  });

  const models = yield select((store) => store.carReference.models);
  const model = models.find((item) => item.name === newCarInstance.model);

  const image = model?.image ? model?.image : null;
  yield put({
    type: carInstanceActions.SET_CAR_INSTANCE,
    payload: { carInstance: { ...newCarInstance, image } },
  });
}

export function* saveInsurer(
  apiMethod = anketaProvider.patchCustomer,
  transformFn = (data) => data
) {
  const anketa = yield select((store) => store.insuranceAnketa);
  const customerBlank = yield select((store) => store.startPage?.customer);

  const { filledCustomers: storedCustomers } = anketa;

  const modelIdx = storedCustomers.findIndex((el) => el.id === anketa.id);
  const filledCustomers = [...storedCustomers];

  filledCustomers[modelIdx] = yield call(
    apiMethod,
    frontToBackCustomer({ ...anketa }, storedCustomers[modelIdx] ?? customerBlank)
  );

  yield put({
    type: actions.INS_ANKETA_NEW_FILLED_CUSTOMERS,
    payload: { filledCustomers: transformFn(filledCustomers) },
  });
}

export function* setActiveInsuranceRoles({ ownerIsDriver, ownerIsInsurer, kaskoInsurerIsDriver }) {
  const {
    applicationId: anketaApplicationId,
    kaskoInsurer,
    drivers,
    filledCustomers,
    nodes = [],
  } = yield select((store) => store.insuranceAnketa);

  const { applicationId: insCalcApplicationId } = yield select(
    (store) => store.insuranceCalculation
  );
  const applicationId = anketaApplicationId ?? insCalcApplicationId;

  const ownerCustomerId = nodes.find((n) => n.roleSysName === 'owner')?.customerId;
  const ownerNodes = nodes.filter((n) => n.customerId === ownerCustomerId);
  yield all(
    ownerNodes.map((node) => {
      const role = node.roleSysName;
      if ((role === 'driver' && !ownerIsDriver) || (role === 'insurer' && !ownerIsInsurer)) {
        return call(anketaProvider.deleteCustomerRole, { applicationId, personRoleId: node.id });
      }
      return null;
    })
  );
  const ownerDriverRole = ownerNodes.find((n) => n.roleSysName === 'driver');
  const ownerInsurerRole = ownerNodes.find((n) => n.roleSysName === 'insurer');
  if (ownerIsDriver && !ownerDriverRole) {
    yield call(anketaProvider.postCustomer, {
      applicationId,
      payload: { customerId: `${ownerCustomerId}`, isActive: true, roleSysName: 'driver' },
    });
  }
  if (ownerIsInsurer && !ownerInsurerRole) {
    yield call(anketaProvider.postCustomer, {
      applicationId,
      payload: { customerId: `${ownerCustomerId}`, isActive: true, roleSysName: 'insurer' },
    });
  }

  if (!ownerIsInsurer) {
    const insurerCustomer = nodes.find(
      (n) => n.roleSysName === 'insurer' && n.customerId !== ownerCustomerId
    );
    if (!insurerCustomer) {
      const { customer } = yield call(anketaProvider.newCustomer, {
        applicationId,
        role: 'insurer',
      });

      yield call(anketaProvider.patchCustomer, {
        ...frontToBackCustomer({ ...kaskoInsurer }, customer),
      });
    }
  } else {
    const insurers = nodes.filter(
      (n) => n.roleSysName === 'insurer' && n.customerId !== ownerCustomerId
    );
    yield all(
      insurers.map((node) => {
        return call(anketaProvider.deleteCustomerRole, { applicationId, personRoleId: node.id });
      })
    );
  }

  if (ownerIsDriver && ownerIsInsurer && !drivers.length) {
    return;
  }

  if (!ownerIsInsurer && kaskoInsurerIsDriver) {
    const { customer } = yield call(anketaProvider.newCustomer, { applicationId, role: 'driver' });

    yield call(anketaProvider.patchCustomer, {
      ...frontToBackCustomer({ ...kaskoInsurer }, customer),
    });
  }

  yield all(
    drivers
      .filter((d) => d.isDeleted)
      .map((d) => {
        const node = nodes.find((n) => n.customerId === String(d.id));
        return call(anketaProvider.deleteCustomerRole, { applicationId, personRoleId: node?.id });
      })
  );

  const addedDrivers = drivers.filter((d) => d.isNew);
  const newDrivers = yield all(
    addedDrivers.map(() => {
      return call(anketaProvider.newCustomer, { applicationId, role: 'driver' });
    })
  );

  yield all(
    drivers
      .filter((d) => !d.isDeleted && !d.isNew)
      .map((driver) => {
        const filledCustomer = filledCustomers.find((c) => c.id === driver.id);
        return call(anketaProvider.patchCustomer, frontToBackCustomer(driver, filledCustomer));
      })
  );
  yield all(
    newDrivers.map(({ customer }, i) => {
      return call(anketaProvider.patchCustomer, frontToBackCustomer(addedDrivers[i], customer));
    })
  );
}

export function* saveActiveStates(apiMethod = anketaProvider.postCustomer) {
  const {
    applicationId: anketaApplicationId,
    id,
    isDriver,
    drivers: interfaceDrivers,
    nodes: preparedNodes,
    filledCustomers,
  } = yield select((store) => store.insuranceAnketa);
  const { multidrive, applicationId: calculationApplicationId } = yield select(
    (store) => store.insuranceCalculation
  );
  const applicationId = anketaApplicationId || calculationApplicationId;
  const drivers = multidrive ? [] : interfaceDrivers;

  const nodes = preparedNodes || [];
  const onlyDriversNodes = nodes.filter((node) => node.roleSysName === 'driver');

  const changedNodes = filledCustomers
    .map((customer) => {
      const newNode = { customerId: `${customer.id}`, roleSysName: 'driver' };
      const foundNode = onlyDriversNodes.find(
        (node) => String(node.customerId) === String(customer.id)
      );
      const isActive = !!foundNode?.isActive;

      // if customer is insurer and isDriver is changed, newNodes.isActive = isDriver
      if (customer.id === id) {
        if (isDriver !== isActive) {
          newNode.isActive = isDriver;
        }
      } else {
        const foundDriver = drivers.find((driver) => driver.id === customer.id);

        // if node exists and driver doesn't exists, node will be active and vice versa
        if (foundNode && isActive !== !!foundDriver) {
          newNode.isActive = !isActive;
        }
      }

      return newNode;
    })
    .filter((node) => node.isActive !== undefined);

  yield all(changedNodes.map((node) => call(apiMethod, { applicationId, payload: node })));

  const newNodes = [...nodes];

  for (const changed of changedNodes) {
    const foundIndex = newNodes.findIndex(
      (node) =>
        String(node.customerId) === String(changed.customerId) && node.roleSysName === 'driver'
    );

    newNodes[foundIndex] = { ...newNodes[foundIndex], ...changed };

    if (foundIndex === -1) {
      newNodes.push(changed);
    }
  }

  yield put({ type: actions.INS_ANKETA_SET_NODES, payload: { nodes: newNodes } });
}

export function* saveNewCustomers(apiMethod = anketaProvider.newCustomer) {
  const {
    filledCustomers,
    applicationId: anketaApplicationId,
    nodes,
    drivers: interfaceDrivers,
  } = yield select((store) => store.insuranceAnketa);
  const { multidrive, applicationId: calculationApplicationId } = yield select(
    (store) => store.insuranceCalculation
  );
  const applicationId = anketaApplicationId || calculationApplicationId;
  const drivers = multidrive ? [] : interfaceDrivers;

  const newCustomers = drivers.filter(
    (driver) =>
      driver.status !== 'confidant' &&
      !filledCustomers.find((customer) => customer.id === driver.id)
  );
  const newDriversFromConfidant = drivers.filter(
    (driver) =>
      driver.status === 'confidant' &&
      !filledCustomers.find((customer) => customer.id === driver.id)
  );

  const emptyCustomers = yield all(
    newCustomers.map(() => call(apiMethod, { applicationId, role: 'driver' }))
  );
  emptyCustomers.sort(({ customer: { id: id1 } }, { customer: { id: id2 } }) =>
    id1 > id2 ? 1 : -1
  );

  let currentFilledCustomers = filledCustomers;
  let currentNodes = nodes;
  let currentDrivers = drivers;

  newCustomers.forEach((customer, index) => {
    const emptyCustomer = emptyCustomers[index];
    const newNode = {
      customerId: `${emptyCustomer.customer.id}`,
      roleSysName: 'driver',
      isActive: true,
    };

    currentFilledCustomers = [...currentFilledCustomers, emptyCustomer.customer];
    currentNodes = [
      ...currentNodes.filter((node) => node.customerId !== newNode.customerId),
      newNode,
    ];
    currentDrivers = currentDrivers.map((driver) =>
      driver.id === customer.id ? { ...driver, id: emptyCustomer.customer.id } : driver
    );
  });

  const driversFromConfidant = yield all(
    newDriversFromConfidant.map((driver) =>
      call(anketaProvider.getCustomer, { customerId: driver.id })
    )
  );

  yield all(
    driversFromConfidant.map((customer) =>
      call(anketaProvider.postCustomer, {
        applicationId,
        payload: { customerId: `${customer.id}`, isActive: true, roleSysName: 'driver' },
      })
    )
  );

  driversFromConfidant.forEach((driver) => {
    const newNode = { customerId: `${driver.id}`, roleSysName: 'driver', isActive: true };

    currentFilledCustomers = [...currentFilledCustomers, driver];
    currentNodes = [
      ...currentNodes.filter((node) => node.customerId !== newNode.customerId),
      newNode,
    ];
  });

  yield put({ type: actions.INS_ANKETA_SET_DRIVERS, payload: { drivers: currentDrivers } });
  yield put({ type: actions.INS_ANKETA_SET_NODES, payload: { nodes: currentNodes } });
  yield put({
    type: actions.INS_ANKETA_NEW_FILLED_CUSTOMERS,
    payload: { filledCustomers: currentFilledCustomers },
  });

  yield put({
    type: actions.INS_ANKETA_SET_CURRENT_SAVED_DATA,
    payload: {
      currentSavedData: backToFrontMapper({
        customers: currentFilledCustomers,
        nodes: currentNodes,
      }).customers,
    },
  });
}

export function* saveDrivers(
  apiMethod = anketaProvider.patchCustomer,
  transformFn = (data) => data
) {
  const anketa = yield select((store) => store.insuranceAnketa);

  const { drivers, filledCustomers, nodes } = anketa;

  const updatedDrivers = [];

  for (const driver of drivers) {
    const model = filledCustomers.find((el) => el.id === driver.id);

    const isActive = !!nodes.filter(
      (node) => String(node.customerId) === String(driver.id) && node.isActive
    ).length;

    if (isActive) {
      updatedDrivers.push(call(apiMethod, frontToBackCustomer({ ...driver }, model)));
    }
  }

  const newDriversCustomers = yield all(updatedDrivers);
  const newFilledCustomers = [...filledCustomers];

  for (const newCustomer of newDriversCustomers) {
    const customerIndex = newFilledCustomers.findIndex((item) => item.id === newCustomer.id);

    if (customerIndex === -1) {
      newFilledCustomers.push(newCustomer);
    } else {
      newFilledCustomers[customerIndex] = newCustomer;
    }
  }

  yield put({
    type: actions.INS_ANKETA_NEW_FILLED_CUSTOMERS,
    payload: { filledCustomers: transformFn(newFilledCustomers) },
  });
}

export function* saveCustomers({ payload: { anketaId, isCashCustomer, throwAfter } }) {
  try {
    yield put({ type: actions.INS_ANKETA_SAVE_SET_FETCH, payload: true });

    const { ownerIsDriver, ownerIsInsurer, kaskoInsurerIsDriver } = yield select(
      (store) => store.insuranceAnketa
    );

    yield put({ type: insuranceActions.INS_SET_MULTIDRIVE, payload: false });

    yield* saveCarDetail({ payload: { anketaId, isCashCustomer } });
    yield* saveInsurer();
    yield* setActiveInsuranceRoles({
      ownerIsDriver,
      ownerIsInsurer,
      kaskoInsurerIsDriver,
    });

    const anketa = yield select((store) => store.insuranceAnketa);

    yield put({
      type: actions.INS_ANKETA_SET_CURRENT_SAVED_DATA,
      payload: {
        currentSavedData: Object.keys(anketa)
          .filter((key) => !SAVING_EXCEPTIONS.includes(key))
          .reduce((acc, key) => ({ ...acc, [key]: anketa[key] }), {}),
      },
    });
    // yield put(getProfile(anketaId));
  } catch (err) {
    console.log('error', err);

    yield put({
      type: actions.INS_ANKETA_SET_NETWORK_ERROR,
      payload: err.customDescription || DEFAULT_ERROR_MESSAGE,
    });

    if (throwAfter) {
      throw err;
    }
  } finally {
    yield put({ type: actions.INS_ANKETA_SAVE_SET_FETCH, payload: false });
  }
}
