import React from 'react';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clearAnketa } from 'store/anketa';
import { assistanceClearAnketa } from 'store/assistanceAnketa';
import { insAnketaClear } from 'store/insuranceAnketa';

const ACTIVE_CLASS = 'report-page-menu__link-active';
const DEF_CLASS = 'submenu-link';

const linksData = [
  {
    title: 'В работе',
    to: '/applications/in-work',
    activeClass: ACTIVE_CLASS,
    excroles: [90],
  },
  {
    title: 'Список',
    to: '/applications/list',
    activeClass: ACTIVE_CLASS,
    excroles: [],
  },
  {
    title: 'Lost',
    to: '/applications/lost',
    activeClass: ACTIVE_CLASS,
    excroles: [90, 91, 3406],
  },
];

let linksELements = (userRole, onClick) =>
  linksData
    .filter((link) => !link.excroles.includes(userRole))
    .map((link, indx) => (
      <NavLink
        key={`${link.to}${indx}`}
        to={link.to}
        onClick={onClick}
        className={DEF_CLASS}
        activeClassName="submenu-link"
      >
        {link.title}
      </NavLink>
    ));

const OrdersLink = () => {
  const rootDispatch = useDispatch();
  const { role } = useSelector((state) => state.user);

  const clickHandler = () => {
    rootDispatch(clearAnketa());
    rootDispatch(assistanceClearAnketa());
    rootDispatch(insAnketaClear());
  };

  const links = linksELements(role, clickHandler);

  return (
    <>
      <div className="nav-group top" style={{ marginTop: 104 }}>
        {links}
      </div>
      <div className="nav-group middle"></div>
      <div className="nav-group bottom"></div>
    </>
  );
};

export default OrdersLink;
