import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { LoaderGif } from 'assets/img';

import {
  WrapperStyled,
  InputStyled,
  Label,
  CloudDragStyled,
  ImgLoader,
  Text,
  Header,
  HeadeDownLoad,
  Desc,
  Error,
  CheckSvgStyled,
  MinusSolidSvgStyled,
} from './style';

export const DragDropArea = forwardRef((props, ref) => {
  const {
    id,
    title = '',
    error = '',
    process = 0,
    name,
    types,
    hideTypeHint = false,
    multiple = false,
    filesLeft = 0,
    filesTotal = 0,
    className,
    onChange,
    small = false,
    clear = false,
    centered = false,
    disabled = false,
    deleteClick = () => {},
    showFile = () => {},
    documentInfo = null,
  } = props;
  const dropRef = useRef(null);
  const inputRef = useRef(null);
  const [onDrag, setOnDrag] = useState(false);

  useEffect(() => {
    if (clear) {
      inputRef.current.value = '';
    }
  }, [clear]);

  const viewTitle =
    String(title).trim().length > 0 ? title : `Загрузите документ${multiple ? 'ы' : ''}`;

  const status = {
    0: (
      <>
        <Label centered={centered}>
          <CloudDragStyled />
          <Text centered={centered}>
            <Header>{viewTitle}</Header>
            {!small && !hideTypeHint && (
              <Desc>
                Загружаются файлы
                {types ? ` в форматах ${types.join(', ')} ` : ' любого формата '}
              </Desc>
            )}
          </Text>
        </Label>
        {error && (
          <Text>
            <Error>{error}</Error>
          </Text>
        )}
      </>
    ),
    1: (
      <Label centered={centered}>
        <ImgLoader alt="preloader" src={LoaderGif} />
        <Text centered={centered}>
          <Header>Загрузка...</Header>
        </Text>
      </Label>
    ),
    2: (
      <Label centered={centered}>
        <ImgLoader alt="preloader" src={LoaderGif} />
        <Text centered={centered}>
          <Header>
            Распознавание...
            <br />
            {!small && `${filesLeft} из ${filesTotal} файлов`}
          </Header>
        </Text>
      </Label>
    ),
    3: (
      <Label centered={centered}>
        <CheckSvgStyled />
        <Text centered={centered}>
          <HeadeDownLoad onClick={showFile}>{title || 'Согласие клиента'}</HeadeDownLoad>
        </Text>
        {documentInfo}
        <MinusSolidSvgStyled
          onClick={(e) => {
            e.stopPropagation();
            deleteClick();
          }}
        />
      </Label>
    ),
  };

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setOnDrag(true);
  };

  const handleDragEnd = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setOnDrag(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setOnDrag(false);
    const files = [...e.dataTransfer.files];
    onChange(name ? { [name]: files } : files);
    e.dataTransfer.clearData();
  };

  const handleSelect = (e) => {
    const files = [...e.target.files];
    onChange(name ? { [name]: files } : files);
    e.target.value = null;
  };

  const wrapperClick = () => inputRef && inputRef.current.click();

  useImperativeHandle(ref, () => ({
    click() {
      inputRef && inputRef.current.click();
    },
  }));

  useEffect(() => {
    const { current } = dropRef;
    current.addEventListener('dragend', handleDragEnd);
    current.addEventListener('dragover', handleDrag);
    current.addEventListener('dragleave', handleDragEnd);
    current.addEventListener('drop', handleDrop);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <WrapperStyled
      id={id}
      ref={dropRef}
      className={className}
      onClick={!disabled ? wrapperClick : undefined}
      dragover={onDrag}
    >
      {status[process]}
      <InputStyled
        ref={inputRef}
        type="file"
        onChange={handleSelect}
        multiple={multiple}
        accept={types.join(', ')}
      />
    </WrapperStyled>
  );
});

DragDropArea.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  types: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
};

DragDropArea.defaultProps = {
  name: null,
  className: '',
  types: ['png', 'jpg', 'pdf'],
  onChange: () => {},
};

DragDropArea.displayName = 'DragDropArea';

export default DragDropArea;
