import styled from 'styled-components';
import { DragDropArea } from 'components/primitives/drag-drop-area';
import { InfoSvg } from 'assets/img';
import { PALETTE } from 'styles/constants';

export const DragDropStyled = styled(DragDropArea)`
  grid-area: fup;
  height: 85px;
  border-radius: 5px;
  border: ${(p) => (p.process !== 3 ? '2px dashed #FFD700' : '1px solid #228B22')};
  background-color: ${(p) =>
    p.process !== 3 ? 'rgba(255, 215, 0, 0.17)' : 'rgba(233, 243, 233, 1)'};
  svg {
    width: 30px;
    margin-right: ;
  }
  cursor: ${(p) => (p.contentHidden ? 'not-allowed' : 'pointer')};
`;

export const InfoSvgStyled = styled(InfoSvg)`
  margin: 5px 0 0 0;
  width: 15px !important;
  height: 15px !important;
  fill: #b5b5b5;
  cursor: pointer;
`;

export const PageList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const PageError = styled.div`
  font-size: 10px;
  color: ${(p) => (p.color && PALETTE.red1) || PALETTE.green1};
  background-color: ${PALETTE.blue4};
  padding: ${(p) => (p.sysName === 'driving-licence' ? '4px 10px' : '4px 5px')};
  margin-right: 5px;
  margin-bottom: 5px;
  border-radius: 5px;

  &:last-child {
    margin-right: 0;
  }
`;
