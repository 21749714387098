import styled from 'styled-components';

import { FONT, PALETTE } from 'styles/constants';

export const Text = styled.p`
  font-family: ${FONT.Roboto};
  font-weight: normal;
  font-style: normal;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: ${PALETTE.black};
`;
