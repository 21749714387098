export const LOAD_NOTIFICATIONS = 'notifications/load';
export const RESET_NOTIFICATIONS = 'notifications/reset';
export const SET_APPS_NOTIFICATIONS = 'notifications/set_apps';
export const SET_UNREAD_COUNT_NOTIFICATIONS = 'notifications/set_unread_count';
export const CALC_UNREAD_COUNT_NOTIFICATIONS = 'notifications/calc_unread_count';
export const FETCH_START_NOTIFICATIONS = 'notifications/fetch_start';
export const FETCH_STOP_NOTIFICATIONS = 'notifications/fetch_stop';
export const ADD_APP_NOTIFICATION = 'notifications/add_app';
export const READ_APP_NOTIFICATION = 'notifications/read_app';
export const SET_APPS_NOTIFICATIONS_MAINPAGE = 'notifications/set_apps_mp';
export const LOAD_NOTIFICATIONS_MAINPAGE = 'notifications/load_mp';
