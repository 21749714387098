import styled from 'styled-components';

import { Status } from 'components/primitives/status';

export const Wrapper = styled(Status)`
  display: inline-block;
  width: auto;
  height: 20px;
  padding: 0px 10px;
  border-radius: 20px;
  line-height: 18px;
  text-overflow: ellipsis;
  text-align: center;
  overflow: hidden;
`;
