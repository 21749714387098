import React, { useEffect, useState } from 'react';
import { FileItem } from './FileItem';
import { Wrapper, Loader, List } from './style';
import { CarPreloaderGif } from 'assets/img';

export const FileList = ({
  onClissify,
  onSelect,
  defaultDoc,
  defaultOnly = false,
  documents,
  docTypes,
  onDelete,
}) => {
  const [groupDoc, setGroupDoc] = useState(null);
  const [groupKeyList, setGroupKeyList] = useState(null);

  const getType = (sysName) => docTypes.find((type) => type.sysName === sysName);

  useEffect(() => {
    if (docTypes && documents) {
      const types = docTypes.map((obj) => obj.sysName);
      if (documents && docTypes) {
        let group = documents.reduce((obj, file) => {
          if (file.type) {
            if (obj[file.type.sysName]) {
              return { ...obj, [file.type.sysName]: [...obj[file.type.sysName], file] };
            }
            return { ...obj, [file.type.sysName]: [file] };
          }
          return obj;
        }, {});
        if (!defaultOnly) {
          const oKey = 'customer-other-files';
          group = Object.keys(group).reduce((obj, key) => {
            if (!types.some((s) => s === key)) {
              return { ...obj, [oKey]: [...(obj[oKey] || []), ...group[key]] };
            }
            return { ...obj, [key]: group[key] };
          }, {});

          if (group[oKey] && groupDoc) {
            const list = groupDoc[oKey]
              ? group[oKey].filter((nDoc) => !groupDoc[oKey].some((oDoc) => oDoc.id === nDoc.id))
              : group[oKey];
            if (list.length > 0) {
              onClissify({ type: getType(oKey), list });
            }
          }
          const list = Object.keys(group).filter(
            (doc) => !Object.keys(defaultDoc).some((ddoc) => ddoc === doc)
          );
          setGroupKeyList(list.length > 0 ? list : null);
        }
        setGroupDoc(group);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documents, docTypes]);

  return (
    <Wrapper single={Object.keys(defaultDoc).length === 1}>
      {!docTypes || !groupDoc ? (
        <Loader src={CarPreloaderGif} color="white" />
      ) : (
        <>
          {Object.keys(defaultDoc).map((sysName, d) => (
            <FileItem
              key={`_doc_${d + 1}`}
              obj={getType(sysName)}
              docs={groupDoc[sysName] || []}
              onSelect={onSelect}
              onDelete={onDelete}
              defaultDoc={defaultDoc}
              defaultOnly={defaultOnly}
            />
          ))}
          {!defaultOnly && groupKeyList && (
            <List>
              {groupKeyList.map((key, k) => (
                <FileItem
                  key={`_docs_${k + 1}`}
                  obj={getType(key)}
                  docs={groupDoc[key] || []}
                  onSelect={onSelect}
                  onDelete={onDelete}
                  defaultDoc={defaultDoc}
                  defaultOnly={defaultOnly}
                />
              ))}
            </List>
          )}
        </>
      )}
    </Wrapper>
  );
};
