import styled, { css } from 'styled-components';
import { FONT, PALETTE } from 'styles/constants';

export const Wrapper = styled.div`
  font-family: ${FONT.Roboto};
  font-weight: normal;
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
  color: ${PALETTE.black};
  width: 100%;
  margin-bottom: 5px;
  color: ${(props) => (props.isRequired ? PALETTE.red1 : PALETTE.grey2)};
  ${(props) =>
    props.isFocused === 'true' &&
    css`
      margin-top: 3px;
      font-size: 12px;
      line-height: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: calc(100% - 15px);
    `}
  ${(props) =>
    props.isDefaultValue &&
    css`
      left: 9px;
      top: 3px;
      font-size: 12px;
      line-height: 14px;
    `}
`;

export const LabelText = styled.span`
  color: ${(props) => (props?.error ? PALETTE.red1 : 'inherit')};
`;
