import React, { useState } from 'react';
import { checkSessionAlive } from 'utils/checkSessionAlive';
import { Loader } from 'components/primitives/loader';

export default function checkAuthWithLoader(WrappedComponent) {
  const CheckAuthWithLoader = (props) => {
    const [loading, setLoading] = useState(false);

    const fetchData = async () => {
      setLoading(true);
      try {
        await checkSessionAlive();
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
      props.onClick();
    };

    return (
      <>
        <Loader show={loading} fixed={true} />

        <WrappedComponent {...props} onClick={() => fetchData()} />
      </>
    );
  };
  CheckAuthWithLoader.displayName = `CheckAuthWithLoader`;

  return CheckAuthWithLoader;
}
