import styled from 'styled-components';

import * as UK from 'components/primitives/content';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div`
  overflow-y: auto;
  flex: 1;
  width: calc(100% + 30px);
  padding: 0px 15px;
  margin: 0px -15px;

  & > * + * {
    margin-top: 10px !important;
  }
`;

export const Title = styled(UK.Title1)`
  margin-bottom: 15px;
  line-height: 1;
`;
