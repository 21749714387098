const { REACT_APP_DADATA_TOKEN } = process.env;

export const DadataType = {
  address: 'address',
  company: 'party',
  bank: 'bank',
  fio: 'fio',
  passport: 'fms_unit',
};

export const DadataHeaders = {
  'accept': 'application/json',
  'authorization': `Token ${REACT_APP_DADATA_TOKEN}`,
  'content-type': 'application/json',
};

// TODO: вынести в REACT_APP перменную, добавить в CI настройку
export const DadataSuggestionsBaseUrl =
  'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/';

// https://confluence.hflabs.ru/pages/viewpage.action?pageId=204669116
export const defaultFioGranular = {
  parts: ['NAME', 'PATRONYMIC', 'SURNAME'],
  gender: 'UNKNOWN', // MALE, FEMALE
};

export const wordsToPass = [
  'г',
  'респ',
  'ул',
  'р-н',
  'село',
  'деревня',
  'поселок',
  'пр-д',
  'пл',
  'к',
  'кв',
  'обл',
  'д',
];
