import styled from 'styled-components';
import { PALETTE } from 'styles/constants';
import * as UK from 'components/primitives/content';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${PALETTE.white};
  padding: 20px;
  border-radius: 20px;
  flex: 1 1 auto;
  width: 100%;
  height: 50vh;
`;

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  justify-items: start;
`;

export const Title = styled(UK.Title1)`
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;

  @media (max-width: 1200px) {
    font-size: 1.1rem;
  }

  @media (max-width: 1100px) {
    font-size: 0.9rem;
  }

  @media (max-width: 1000px) {
    font-size: 0.8rem;
  }
`;
export const SortPanel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 2px solid ${PALETTE.grey9};
`;
