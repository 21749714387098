import styled from 'styled-components';

import { Select, LightButton } from 'components/primitives/content';

import { PALETTE } from 'styles/constants';

export const Container = styled.div`
  display: flex;
  justify-content: end;
  flex: 1;
`;

export const Filter = styled(Select)`
  height: 45px;

  & > div:first-child > div + div {
    width: auto;
    min-width: 100%;
    white-space: nowrap;
  }

  & > div:first-child > div:first-child > div:first-child > div:first-child {
    margin-bottom: 0px;
  }

  & p {
    display: inline;
  }

  & p + p {
    padding-left: 5px;
  }
`;

export const Dealer = styled(Filter)`
  flex: 1;
  max-width: 200px;
`;

export const Worker = styled(Filter)`
  margin-left: 12px;
  flex: 1;
  max-width: 200px;
`;

export const FilterButton = styled(LightButton).attrs({ small: true })`
  height: 45px;
  margin-left: 12px;
  padding: 0px 12px;
  background-color: ${(props) => (props.isActive ? PALETTE.grey9 : PALETTE.white)};

  & > svg {
    margin: 0px;
  }
`;
