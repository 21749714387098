import { put, takeLatest, select } from 'redux-saga/effects';
import * as carReferenceProvider from 'data-providers/carReferenceProvider';
import * as actions from './constants';
import * as Helper from './helper';
import { DEFAULT_ERROR_MESSAGE } from 'constants/index';

export function* getBrands({ payload }) {
  try {
    yield put({
      type: actions.CAR_REFERENCE_SET_BRANDS_FETCH,
      payload: { fetch: true },
    });

    let dealerId = payload.dealerId;
    if (!payload.dealerId) {
      const { dealer } = yield select((store) => store.user);
      dealerId = dealer;
    }

    let vehicleStatus = payload.vehicleStatus;
    if (!vehicleStatus) {
      const isNew = yield select((store) => store.carInstance.carInstance.isNew);
      vehicleStatus = isNew === 1 ? 'new' : 'old';
    }

    const data = yield carReferenceProvider.getBrands({
      dealerId,
      vehicleStatus,
    });

    yield put({
      type: actions.CAR_REFERENCE_LOADED_BRANDS,
      payload: { brands: Helper.convertToArrayOfObjWithValue(data) },
    });
  } catch (err) {
    yield put({
      type: actions.CAR_REFERENCE_SET_ERROR,
      payload: { error: err.customDescription || DEFAULT_ERROR_MESSAGE },
    });
  } finally {
    yield put({
      type: actions.CAR_REFERENCE_SET_BRANDS_FETCH,
      payload: { fetch: false },
    });
  }
}

export function* getModels({ payload }) {
  try {
    if (!payload.brandId) {
      return;
    }
    yield put({
      type: actions.CAR_REFERENCE_SET_MODELS_FETCH,
      payload: { fetch: true },
    });

    let dealerId = payload.dealerId;
    if (!dealerId) {
      const { dealer } = yield select((store) => store.user);
      dealerId = dealer;
    }

    const data = yield carReferenceProvider.getModels({
      ...payload,
      dealerId,
    });

    yield put({
      type: actions.CAR_REFERENCE_LOADED_MODELS,
      payload: { models: Helper.convertToArrayOfObjWithValue(data) },
    });
  } catch (err) {
    yield put({
      type: actions.CAR_REFERENCE_SET_ERROR,
      payload: { error: err.customDescription || DEFAULT_ERROR_MESSAGE },
    });
  } finally {
    yield put({
      type: actions.CAR_REFERENCE_SET_MODELS_FETCH,
      payload: { fetch: false },
    });
  }
}

export function* getVersions({ payload }) {
  try {
    if (!payload.modelId || !payload.brandId) {
      return;
    }
    yield put({
      type: actions.CAR_REFERENCE_SET_VERSIONS_FETCH,
      payload: { fetch: true },
    });

    let dealerId = payload.dealerId;
    if (!dealerId) {
      const { dealer } = yield select((store) => store.user);
      dealerId = dealer;
    }

    const data = yield carReferenceProvider.getVersions({
      ...payload,
      dealerId,
    });

    yield put({
      type: actions.CAR_REFERENCE_LOADED_VERSIONS,
      payload: { versions: Helper.convertToArrayOfObjWithValue(data) },
    });
  } catch (err) {
    yield put({
      type: actions.CAR_REFERENCE_SET_ERROR,
      payload: { error: err.customDescription || DEFAULT_ERROR_MESSAGE },
    });
  } finally {
    yield put({
      type: actions.CAR_REFERENCE_SET_VERSIONS_FETCH,
      payload: { fetch: false },
    });
  }
}

export function* watchGetBrands() {
  yield takeLatest(actions.CAR_REFERENCE_LOAD_BRANDS, getBrands);
}

export function* watchGetModels() {
  yield takeLatest(actions.CAR_REFERENCE_LOAD_MODELS, getModels);
}

export function* watchGetVersions() {
  yield takeLatest(actions.CAR_REFERENCE_LOAD_VERSIONS, getVersions);
}
