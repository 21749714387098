import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import ErrorPopup from 'components/primitives/error-popup';
import BlockMain from './components/BlockMain';
import BlockAutoParams from './components/BlockAutoParams';
import BlockAutoDocs from './components/BlockAutoDocs';
import { Loader } from 'components/primitives/loader';
import { CAR_STATE } from './constants';
import { schemaVin, schemaCarDetail } from './validator/validations';
import { fetchAutocode } from 'data-providers/autocode';
import { FIELDS } from 'store/carInstance/constants';

import * as ValidationHelper from './validator/validationHelper';
import * as S from './style';

const fn = () => {};

const CarDetail = ({
  id: _,
  hiddenFields = [],
  disableFields = [],
  ignoredFields,
  disabledPatch,
  isFullCalc = true,
  isWhiteLoader = false,
  storedCarInstance,
  storedCarReference,
  isInCredit,
  creditBanksList,
  creditBankId,
  setImage = fn,
  clearError = fn,
  onChange = fn,
  onPatch = fn,
  onSetCarInstance = fn,
  onScroll = fn,
  updateErrors = fn,
  setFetchAllCarReference = fn,
  onChangeCarModel = fn,
  onBankChange = fn,
  onClickIsInCredit = fn,
  onClickFullCalc = null,
  showRestrictions = false,
  requiredFields = [],
  toggleDetails,
  ...rest
}) => {
  const {
    errors,
    scrollTo,
    fetch,
    carInstance: carInstanceObj,
    patchIsNew,
    carInfoFetch,
    dealersParams: { newCars, usedCars, fetchDealersParams },
    isDisable,
  } = storedCarInstance;

  const [mileage, setMileage] = useState(String(carInstanceObj?.mileage));
  const { id: userId } = useSelector((state) => state.user);

  const disabledCarState = useMemo(() => {
    const disabledIds = [];

    if (!usedCars || isDisable) {
      disabledIds.push(2);
    }

    if (!newCars || isDisable) {
      disabledIds.push(1);
    }

    return disabledIds;
  }, [newCars, usedCars, isDisable]);

  const error = storedCarInstance.error || storedCarReference.error;
  const carInstance = JSON.parse(JSON.stringify(carInstanceObj));

  const isShowDetails = ![
    'enginePower',
    'engineNumber',
    'bodyNumber',
    'permittedMaxWeight',
    'engineVolume',
    'glonasNumber',
  ].every((field) => hiddenFields.includes(field));

  useEffect(() => {
    const mileage = carInstance?.mileage;
    setMileage(String(mileage ?? 0));
  }, [carInstance.mileage]);

  useEffect(() => {
    if (carInstanceObj.checkAutoCode && carInstance.vin) {
      const schema = carInstance.vin ? schemaVin : null;
      if (schema) {
        schema
          .validate(carInstance, { abortEarly: false })
          .then(async () => {
            try {
              await fetchAutocode({
                isNew: carInstanceObj.isNew,
                frontUserCredmenId: userId,
                searchType: carInstance.vin ? 1 : 2,
                carNumber: carInstance.vin,
                carInstanceId: carInstance.id,
                brandName: carInstance?.brand || '',
              });
            } catch (err) {
              console.log(err);
            }
          })
          .catch(() => {});
      }
      delete carInstanceObj.checkAutoCode;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carInstance.vin, carInstanceObj.checkAutoCode]);

  const setYearHandler = ({ year }) => {
    const emptyTemplate = { beginDate: null, endDate: null, number: '' };
    const withDiagnosticCard = new Date().getFullYear() - year.value >= 4;

    onSetCarInstance({
      ...carInstanceObj,
      diagnosticCard: withDiagnosticCard ? carInstanceObj.diagnosticCard : { ...emptyTemplate },
      year: year.value,
    });
    onPatch({
      ...carInstance,
      diagnosticCard: withDiagnosticCard ? carInstance.diagnosticCard : { ...emptyTemplate },
      year: year.value,
    });
  };

  const validateHandler = async (value, key, catalog = '', innerCarInstance = carInstance) => {
    if (ignoredFields && ignoredFields.includes(key)) {
      return true;
    }

    if (hiddenFields.includes(FIELDS.catalogBlock)) {
      return true;
    }

    try {
      const validatingData = catalog
        ? { ...innerCarInstance, [catalog]: { ...innerCarInstance[catalog], [key]: value } }
        : { ...innerCarInstance, [key]: value };

      const storedCarInstance = ValidationHelper.getCarInstanceForValidation(validatingData);

      await schemaCarDetail.validate(storedCarInstance, { abortEarly: false });

      updateErrors(key, '', catalog);

      return true;
    } catch (err) {
      const objectedErrors = ValidationHelper.collectObjectedErrors(err);

      let error = '';

      if (catalog) {
        if (objectedErrors[catalog]) {
          error = objectedErrors[catalog][key];
        }
      } else {
        error = objectedErrors[key];
      }

      if (!error) {
        return true;
      }

      updateErrors(key, error, catalog);

      return false;
    }
  };

  const blurHandler = (items, catalog = '') => {
    onSetCarInstance(
      catalog
        ? { ...carInstanceObj, [catalog]: { ...carInstanceObj[catalog], ...items } }
        : { ...carInstanceObj, ...items }
    );

    onPatch(
      catalog
        ? { ...carInstance, [catalog]: { ...carInstance[catalog], ...items } }
        : { ...carInstance, ...items }
    );

    Object.keys(items).forEach((key) => {
      if (requiredFields.includes(catalog ? `${catalog}.${key}` : key)) {
        validateHandler(items[key], key, catalog, carInstanceObj);
      }
    });
  };

  const clickCarStateHandler = (value) => {
    const isNew = value.isNew === 1;
    if (disabledCarState.includes(value.isNew)) {
      return;
    }
    const currentYear = new Date().getFullYear() - 1;

    setFetchAllCarReference();
    const dataToPatch = {
      ...(disabledPatch ? carInstanceObj : carInstance),
      brand: carInstance.brand,
      model: '',
      version: '',
      mileage: isNew ? 0 : Number(mileage),
      isNew: Boolean(isNew),
      year: currentYear <= carInstance.year ? carInstance.year : new Date().getFullYear(),
    };
    onPatch(dataToPatch, true, ['brand', 'model', 'version'], {
      vehicleStatus: isNew ? 'new' : 'old',
    });
  };

  if (error) {
    return <ErrorPopup errorMessage={error} onClose={clearError} />;
  }

  const isPtsHidden =
    hiddenFields.includes(FIELDS.pts.number) && hiddenFields.includes(FIELDS.pts.issueDate);
  return (
    <S.Wrapper {...rest}>
      {!fetch && fetchDealersParams === 'done' && (
        <S.GridStyled>
          <S.CarState
            name="isNew"
            type="button-rounded"
            items={CAR_STATE}
            disabledIds={disabledCarState}
            checked={carInstance.isNew ? 1 : 2}
            onClick={clickCarStateHandler}
          />

          <S.Space1 />
          <BlockMain
            {...storedCarReference}
            carInstance={carInstance}
            patchIsNew={patchIsNew}
            hiddenFields={hiddenFields}
            disableFields={disableFields}
            disabledPatch={disabledPatch}
            errors={errors}
            mileage={mileage}
            setMileage={setMileage}
            isFullCalc={isFullCalc}
            scrollTo={scrollTo}
            carInfoFetch={carInfoFetch}
            isInCredit={isInCredit}
            creditBanksList={creditBanksList}
            creditBankId={creditBankId}
            onScroll={onScroll}
            onChange={onChange}
            onChangeCarModel={onChangeCarModel}
            onBlur={blurHandler}
            onPatch={onPatch}
            onSetCarInstance={onSetCarInstance}
            onSetYear={setYearHandler}
            setImage={setImage}
            onBankChange={onBankChange}
            onClickFullCalc={onClickFullCalc}
            onClickIsInCredit={onClickIsInCredit}
            showRestrictions={showRestrictions}
            disabled={isDisable}
          />

          {isShowDetails && (
            <>
              <S.HideButton
                type="button"
                onClick={() => {
                  toggleDetails();
                }}
              >
                {!storedCarInstance.isOpenDetails ? 'Дополнительно' : 'Скрыть'}
              </S.HideButton>
              <S.Space2 height={40} />
              {storedCarInstance.isOpenDetails && (
                <>
                  <BlockAutoParams
                    carInstance={carInstanceObj}
                    hiddenFields={hiddenFields}
                    errors={errors}
                    scrollTo={scrollTo}
                    onScroll={onScroll}
                    onChange={onChange}
                    onBlur={blurHandler}
                    disabled={isDisable}
                    requiredFields={requiredFields}
                  />
                  {!isPtsHidden && isFullCalc && (
                    <>
                      <S.Space3 height={40} />
                      <BlockAutoDocs
                        carInstance={carInstanceObj}
                        hiddenFields={hiddenFields}
                        errors={errors}
                        scrollTo={scrollTo}
                        onScroll={onScroll}
                        onChange={onChange}
                        onBlur={blurHandler}
                        disabled={isDisable}
                        requiredFields={requiredFields}
                      />
                    </>
                  )}
                </>
              )}
            </>
          )}
        </S.GridStyled>
      )}
      {(fetch || fetchDealersParams !== 'done') && (
        <Loader
          className={'CarDetail'}
          show={true}
          color={isWhiteLoader ? 'white' : 'transparent'}
        />
      )}
    </S.Wrapper>
  );
};

export default CarDetail;
