import styled from 'styled-components';
import * as UK from 'components/primitives/content';
import { PALETTE } from 'styles/constants';

export const SearchContainer = styled.div`
  position: relative;
  width: 100%;
  height: 56px;
`;

export const Input = styled(UK.Input)`
  width: 100%;
  height: 100%;
  padding-right: 40px;

  ${(p) => (p.mainPageSearch ? `border: 1px solid ${PALETTE.grey12};` : ``)}

  &:hover {
    ${(p) => (p.mainPageSearch ? `border: 2px solid ${PALETTE.blue5};` : ``)}
  }
`;

export const Button = styled.button`
  position: absolute;
  right: 0px;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 100%;
  padding: 0px;
  cursor: pointer;
  border: none;
  background: none;

  & > svg {
    width: 16px;
    height: 16px;
  }

  & > svg > path + path {
    ${(p) => (p.mainPageSearch ? `fill: ${PALETTE.grey12};` : `fill: ${PALETTE.grey2};`)}
  }

  &:hover > svg > path + path {
    ${(p) => (p.mainPageSearch ? `fill: ${PALETTE.blue5};` : `fill: ${PALETTE.grey5};`)}
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  right: 35px;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 100%;
  padding: 0px;
  cursor: pointer;
  border: none;
  background: none;

  & > svg {
    width: 12px;
    height: 12px;
  }

  & path {
    ${(p) => (p.mainPageSearch ? `fill: ${PALETTE.grey12};` : `fill: ${PALETTE.grey2};`)}
  }

  &:hover path {
    fill: ${PALETTE.red1};
  }
`;
