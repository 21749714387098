export const IS_LOCALHOST = Boolean(
  window.location.hostname.match(/^localhost/) ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

export const sortArrayByConst = (arr, key, constArr) =>
  constArr.map((id) => arr.find((item) => item[key] === id));

export const sortArrayByChecked = (a, b) => {
  if (a.checked < b.checked) {
    return 1;
  }
  if (a.checked > b.checked) {
    return -1;
  }
  return 0;
};

/**
 * Sort array by field.
 * @param {string} field - Field to sort by
 * @param {'asc'|'desc'} direction - Direction of sorting
 */
export const sortArrayBy = (field, direction = 'asc') => {
  return (a, b) => {
    let first = isNaN(a[field]) && isNaN(b[field]) ? a[field].toLowerCase() : a[field];
    let second = isNaN(a[field]) && isNaN(b[field]) ? b[field].toLowerCase() : b[field];

    if (direction === 'asc') {
      if (first < second) {
        return -1;
      }
      if (first > second) {
        return 1;
      }
    } else {
      if (first > second) {
        return -1;
      }
      if (first < second) {
        return 1;
      }
    }
    return 0;
  };
};

export const isEqualArrays = (arr1, arr2) => {
  return JSON.stringify(arr1.slice().sort()) === JSON.stringify(arr2.slice().sort());
};

export const replaceInArray = (arr, condition, newItem) => {
  return arr.map((i) => {
    return condition(i) ? newItem : i;
  });
};

export const resetOptions = (options, resetName, newValue) => {
  return options.map((op) => ({ ...op, [resetName]: newValue }));
};

export const addRgbOpacity = (color, opacity) => {
  const alpha = Math.round(opacity * 255).toString(16);
  return color + (alpha.length < 2 ? `0${alpha}` : alpha);
};

export const roundToDigits = (num, digits = 2, normalize = true) => {
  const localNum = Number(num);
  const result = localNum.toFixed(digits + 1).slice(0, -1);
  return normalize ? Number(result) : result;
};

export const autoScrollVertical = (elementId, yOffset = 0) => {
  const element = document.getElementById(elementId);
  const rect = element.getBoundingClientRect();
  const scrollTop = document.documentElement.scrollTop;
  const targetY = rect.top + scrollTop + yOffset;
  window.scrollTo({
    top: targetY,
    behavior: 'smooth',
  });
};

export const splitString = (inputString = '', maxLength = 100) => {
  const result = [];
  let currentLine = '';

  const splitBrLine = inputString.split('<br>');

  for (let i = 0; i < splitBrLine.length; i++) {
    if (splitBrLine[i].length > maxLength) {
      const oneElementSplit = splitBrLine[i].split(' ');

      for (let j = 0; j < oneElementSplit.length; j++) {
        currentLine += ` ${oneElementSplit[j]}`;
        if (currentLine.length > maxLength || j === oneElementSplit.length - 1) {
          result.push(currentLine.trim());
          currentLine = '';
        }
      }
    } else {
      result.push(splitBrLine[i]);
    }
  }
  return result.join('<br>');
};

export const tabIndexOnEnter = (event) => {
  if (event.key === 'Enter' || event.key === 'Tab') {
    event.preventDefault();
    event.target.blur();
    const currentTabIndex = event.target.getAttribute('tabindex');
    const elements = Array.from(document.querySelectorAll('[tabindex]'));
    const sortElements = elements
      .filter((el) => el.getAttribute('tabindex') > currentTabIndex)
      .sort((a, b) => a.getAttribute('tabindex') - b.getAttribute('tabindex'));
    if (sortElements[0]) {
      sortElements[0].focus();
    }
  }
};

export const secondsToTimerString = (inputSeconds = 0) => {
  const minutes = Math.floor(inputSeconds / 60);
  const minutesStr = `${minutes < 10 ? '0' : ''}${minutes}`;
  const seconds = Math.round(inputSeconds % 60);
  const secondsStr = `${seconds < 10 ? '0' : ''}${seconds}`;

  return `${minutesStr}:${secondsStr}`;
};

export const downloadByUrl = (name, url) => {
  const link = document.createElement('a');
  link.download = name;
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
