import { anketaInsurance } from './resources';
import { GLOBAL_ANKETA_CALC_OPTIONS } from 'constants/index';

export const initialState = {
  ...anketaInsurance,
  errors: {},
  customersFetch: 'wait', // ['wait','pending', 'done'] ? ))
  saveFetch: false,
  mainFetch: false,
  networkError: '',
  scrollTo: null,
  calcOption: GLOBAL_ANKETA_CALC_OPTIONS[0],
  isDisabled: false,
};
