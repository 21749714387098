import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { GridStyled } from './style';

export const Grid = forwardRef(({ children, className }, ref) => {
  return (
    <GridStyled className={className} ref={ref}>
      {children}
    </GridStyled>
  );
});

Grid.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
};

Grid.defaultProps = {
  children: PropTypes.any,
  className: '',
};

Grid.displayName = 'Grid';

export default Grid;
