import { DownloadCloud2Svg } from 'assets/img';
import styled from 'styled-components';
import { PALETTE } from 'styles/constants';

export const FlexWrap = styled.div`
  height: 40%;
  padding-left: 5px;

  &:hover > svg * {
    fill: ${PALETTE.blue5};
  }
`;

export const LostReportIco = styled(DownloadCloud2Svg)`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;
