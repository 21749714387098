export const defaultDriver = {
  id: 1,
  licenseSeriaNum: '',
  licenseDate: '',
  isFirstLicense: true,
  prevLicenseSeriaNum: '',
  prevLicenseDate: '',
  gender: 0,
  bday: '',
  fio: '',
};

export const defaultInsurer = {
  id: null,
  fio: '',
  gender: 0, // TODO: gender enam
  bday: '',
  insurerPhone: '',
  insurerEmail: '',
  insurerPassportSerialNum: '',
  insurerPassportDate: '',
  insurerIssuerCode: '',
  insurerIssuer: '',
  insurerBirthPlace: '',
  insurerIndex: '',
  insurerAddress: '',
  licenseSeriaNum: '',
  licenseDate: '',
  prevLicenseSeriaNum: '',
  prevLicenseDate: '',
  isFirstLicense: true,
  isDriver: true,
  ownerIsDriver: true,
  isInsurer: true,
  ownerIsInsurer: true,
};

export const anketaInsurance = {
  ...defaultInsurer,
  drivers: [],
  filledCustomers: [],
  nodes: null,
  currentSavedData: null,
  insurerDadataInfo: {},
  kaskoInsurer: { ...defaultInsurer, insurerDadataInfo: {}, errors: {} },
  kaskoInsurerIsDriver: false,
  savedInsurer: null,
};

export const SAVING_EXCEPTIONS = [
  'filledCustomers',
  'nodes',
  'currentSavedData',
  'errors',
  'networkError',
  'saveFetch',
];
