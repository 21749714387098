import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { dropApplications } from 'store/applications';
import { clearCarInstance } from 'store/carInstance';
import { resetSortig } from 'store/filters';

import { Content } from './components/Content/Content';

import * as S from './style';

export const MainInfoBlock = () => {
  const rootDispatch = useDispatch();

  useEffect(() => {
    rootDispatch(dropApplications());
    rootDispatch(clearCarInstance());
    rootDispatch(resetSortig());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <S.InfoContainer>
      <Content />
    </S.InfoContainer>
  );
};
