import {
  USER_LOGIN,
  USER_LOGGED_IN,
  USER_LOGOUT,
  USER_LOGGED_OUT,
  USER_LOGIN_ERROR,
  USER_CHECKAUTH,
  CHANGE_LOCALE,
  CHANGE_ACTIVE_DEALER,
  CHANGE_ACTIVE_ROLE,
  UPDATE_USER,
  USER_SET_FETCH,
  USER_DROP_ERROR,
  GET_USER,
  ACTIVE_DEALER_CHANGED,
  ACTIVE_ROLE_CHANGED,
  DEALERS_SET_FETCH,
  GET_DEALERS,
} from './constants';
import { userIdObject } from 'store/notifications/userId';

const initState = {
  id: '',
  isAuth: false, // true, // *** dev only
  fetch: true,
  email: '',
  authError: '',
  locale: 'ru',
  firstname: '',
  lastname: '',
  middlename: '',
  avatar: '',
  role: '',
  dealer: '',
  roles: [],
  permissions: [],
  dealers: [],
  isDealersFetch: false,
  totalDealersNumber: 0,
  loadedDealersNumber: 0,
  awaitSms: false,
  attributes: {},
  tfa: false, // two factor auth
};

export default function (state = initState, action) {
  switch (action.type) {
    case USER_LOGGED_OUT: {
      userIdObject.set('');

      return { ...initState, fetch: false, isAuth: false };
    }
    case USER_LOGGED_IN: {
      userIdObject.set(action.payload.id);

      return {
        ...state,
        isAuth: true,
        authError: '',
        fetch: false,
        ...action.payload,
      };
    }
    case USER_SET_FETCH: {
      return { ...state, fetch: action.payload };
    }
    case USER_DROP_ERROR:
      return { ...state, authError: '' };
    case USER_LOGIN_ERROR: {
      const { authError } = action.payload;
      return { ...state, isAuth: false, authError };
    }
    case UPDATE_USER:
      return {
        ...state,
        ...action.payload,
        attributes: action.payload?.attributes || state.attributes,
      };
    case CHANGE_LOCALE:
      return { ...state, locale: action.payload };
    case ACTIVE_DEALER_CHANGED:
      return { ...state, dealer: action.payload };
    case ACTIVE_ROLE_CHANGED:
      return { ...state, role: action.payload };
    case DEALERS_SET_FETCH: {
      return { ...state, isDealersFetch: action.payload };
    }
    default:
      return state;
  }
}

export function userLogin({ login, password }) {
  return {
    type: USER_LOGIN,
    payload: { login, password },
  };
}

export function getUser() {
  return {
    type: GET_USER,
  };
}

export function userLogout() {
  return {
    type: USER_LOGOUT,
  };
}

export function checkAuth() {
  return {
    type: USER_CHECKAUTH,
  };
}

export function changeLocale(locale) {
  return {
    type: CHANGE_LOCALE,
    payload: locale,
  };
}

export function changeActiveDealer(dealerId) {
  return {
    type: CHANGE_ACTIVE_DEALER,
    payload: dealerId,
  };
}

export function changeActiveRole(roleId) {
  return {
    type: CHANGE_ACTIVE_ROLE,
    payload: roleId,
  };
}

export function userDropError() {
  return {
    type: USER_DROP_ERROR,
  };
}

export function getDealers() {
  return {
    type: GET_DEALERS,
  };
}
