import React from 'react';
import PropTypes from 'prop-types';
import { StatusStyled } from './styles';

export const Status = ({ className = '', color = 'white', text = '', ...rest }) => {
  return (
    <StatusStyled className={className} color={color} {...rest}>
      {text}
    </StatusStyled>
  );
};

Status.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
  text: PropTypes.any,
};
