import styled from 'styled-components';
import CarDetailComponent from '../../../CarDetail';
import CarHeadDetailComponent from '../../../CarDetail/CarHeadDetail';

export const CarDetail = styled(CarDetailComponent)`
  width: 100%;
  margin-bottom: 0;
`;

export const CarHeadDetailWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-areas: '. ch ch ch . . . .';
  margin-bottom: 15px;

  & > div {
    display: grid;
    grid-area: ch;
  }
`;

export const CarHeadDetail = styled(CarHeadDetailComponent)`
  display: flex;

  & > div {
    width: 250px;
  }

  & > button {
    width: 200px;
  }
`;
