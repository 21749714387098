/* eslint-disable no-undef */
import { stringify } from 'query-string';
import request from 'utils/request';

let URL = `${process.env.REACT_APP_API_ENTRYPOINT}`;

if (process.env.REACT_APP_MODE === 'dev') {
  URL = `${process.env.REACT_APP_LOCAL_API_URL}`;
}

export const configWithHeadersLdJSON = {
  headers: {
    'content-type': 'application/ld+json',
    'accept': 'application/ld+json',
  },
};

export const getURL = () => URL;

export const get = async (resource, params = null, options = {}) => {
  try {
    const url = `${URL}/api/${resource}${
      params
        ? `?${stringify(params, {
            arrayFormat: 'bracket',
          })}`
        : ''
    }`;
    const results = await request.get(url, {
      headers: {
        'accept': 'application/json',
        'content-type': 'application/json',
        ...options,
      },
    });
    if (!results || (results.status && results.status !== 200)) {
      return null;
    }
    return results.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const getHydra = async (resource, params = null, options = {}) => {
  try {
    const url = `${URL}${resource}${
      params
        ? `?${stringify(params, {
            arrayFormat: 'bracket',
          })}`
        : ''
    }`;
    const results = await request.get(url, {
      headers: {
        'content-type': 'application/ld+json',
        'accept': 'application/ld+json',
        ...options,
      },
    });
    if (!results || (results.status && results.status !== 200)) {
      return null;
    }
    return results.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const post = async (resource, params = {}, options = {}) => {
  try {
    const url = `${URL}/api/${resource}`;
    const results = await request.post(url, params, {
      headers: {
        'accept': 'application/json',
        'content-type': 'application/json',
        ...options,
      },
    });
    if (!results || (results.status && ![200, 201].includes(results.status))) {
      return null;
    }
    return results.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const patch = async (resource, params = {}, options = {}) => {
  try {
    const url = `${URL}/api/${resource}`;
    const results = await request.patch(url, params, {
      headers: {
        'accept': 'application/json',
        'content-type': 'application/json',
        ...options,
      },
    });
    if (!results || (results.status && results.status !== 200)) {
      return null;
    }
    return results.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const remove = async (resource, params = {}, options = {}) => {
  try {
    const url = `${URL}/api/${resource}`;
    const { status, data } = await request.delete(url, params, {
      headers: {
        'accept': 'application/json',
        'content-type': 'application/json',
        ...options,
      },
    });
    if (status && status !== 204) {
      return null;
    }
    return data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};
