import { checkUserSessionAlive, postRefresh } from 'data-providers/authProvider';
import * as localStorage from 'utils/local-storage';

export const checkSessionAlive = async () => {
  let stored = localStorage.getItem('token');
  const response = await checkUserSessionAlive();
  if (!response.data.success && stored.refresh) {
    await postRefresh(stored.refresh);
  }
};
