class AppError extends Error {
  constructor(message, code = '', type = '', description = '') {
    super(message);
    this.name = this.constructor.name;
    Error.captureStackTrace(this, this.constructor);

    this.code = code;
    this.type = type;
    this.description = description;
  }

  toJson() {
    const { code, type, description, message } = this;
    return {
      code,
      type,
      description,
      error: message,
    };
  }

  publicate() {
    return this.toJson();
  }
}

export default AppError;
