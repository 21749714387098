import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';

import { traefikRequest, agent as request } from 'utils/request';
import * as localStorage from 'utils/local-storage';
import { isTokenExpired } from 'utils/JWTUtils';
import { checkRefreshToken } from 'data-providers/authProvider';
import { uuidObject } from 'utils/uuid';
import sleep from 'utils/sleep';
import { getUser } from 'store/user';

const awaitRefreshing = async (state) => {
  while (state.isRefreshing) {
    await sleep(1000);
  }
  return true;
};

const Interceptors = () => {
  const rootDispatch = useDispatch();

  useEffect(() => {
    const agents = [traefikRequest, request];
    let headers = {
      'Content-Type': 'application/json',
      'accept': 'application/json',
    };
    let state = { isRefreshing: false };

    agents.forEach((agent) => {
      agent.interceptors.request.use(async (config) => {
        await awaitRefreshing(state);
        let stored = localStorage.getItem('token');
        if (stored && isTokenExpired(stored.token)) {
          const res = await checkRefreshToken(state);
          if (res) {
            stored.token = res;
            rootDispatch(getUser());
          }
          state.isRefreshing = false;
        }
        if (stored) {
          config.headers = {
            ...headers,
            ...config.headers,
            'Authorization': `Bearer ${stored.token}`,
            'Correlation-Id': `${uuidObject.get()}`,
          };
        }
        return config;
      });

      agent.interceptors.response.use(
        (response) => {
          const json = JSON.parse(JSON.stringify(response));
          return json;
        },
        async (error) => {
          const { config } = error;
          if (error.response) {
            if (401 === error.response.status) {
              await awaitRefreshing(state);
              const res = await checkRefreshToken(state);
              if (res) {
                config.headers = {
                  ...config.headers,
                  Authorization: `Bearer ${res}`,
                };
                state.isRefreshing = false;
                rootDispatch(getUser());
                return axios(config);
              } else {
                state.isRefreshing = false;
                return Promise.reject();
              }
            } else {
              return Promise.reject(error);
            }
          }
        }
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
};

export default Interceptors;
