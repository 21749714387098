import request from 'utils/request';
import throwCustomError from 'utils/throwCustomError';

//@oldfox dev only
// import { generateMockItems } from '../constants/mock';

export const getCustomersList = async ({ applicationId, product = 'assistance' }) => {
  const url = `/api/${product}/${applicationId}/customers`;

  const { data } = await request.get(url).catch(throwCustomError);

  return data;
};

export const getCustomer = async ({ customerId }) => {
  const url = `/api/customers/${customerId}`;

  const { data } = await request.get(url).catch(throwCustomError);

  return data;
};

export const patchCustomer = async (customer) => {
  try {
    const url = `/api/customers/${customer.id}`;

    const { data } = await request.patch(url, customer).catch(throwCustomError);

    return data;
  } catch (err) {
    console.log('PATCH CUSTOMER ERROR ***', err);
    throw err;
  }
};

export const postCustomer = async ({ applicationId, payload }) => {
  try {
    const url = `/api/assistance/${applicationId}/customers`;

    const { data } = await request.post(url, payload).catch(throwCustomError);

    return data;
  } catch (err) {
    console.log('POST CUSTOMER ERROR ***', err);
    throw err;
  }
};

export const deleteCustomer = async ({ itemId }) => {
  try {
    const url = `/api/product/application/customers/${itemId}`;

    const { data } = await request.delete(url).catch(throwCustomError);

    return data;
  } catch (err) {
    console.log('DELETE CUSTOMER ERROR ***', err);
    throw err;
  }
};

export const newCustomer = async ({ applicationId, role }) => {
  try {
    const url = `/api/assistance/${applicationId}/${role}/new`;

    const { data } = await request.post(url, {}).catch(throwCustomError);

    return data;
  } catch (err) {
    console.log('POST NEW CUSTOMER ERROR ***', err);
    throw err;
  }
};
