import React, { useEffect, useReducer, useRef, useState } from 'react';
import animateScrollTo from 'animated-scroll-to';
import PropTypes from 'prop-types';
import * as CustomPropTypes from '../../customPropTypes';
import { InputMask } from 'utils/inputMask';
import { BLOCK_PARAMETRS, AUTO_PARAMS as auto, GLONAS } from '../../constants';
import { FIELDS } from 'store/carInstance/constants';

import * as S from './style';

const fn = () => {};

const updateData = (instance) => {
  return Object.values(auto).reduce((total, type) => {
    const payload = instance[type];
    return { ...total, [type]: !!payload !== false ? `${payload}` : '' };
  }, {});
};

function reducer(state, { type, payload }) {
  return {
    update: () => ({ ...state, ...updateData(payload) }),
    [auto.enginePower]: () => ({ ...state, [type]: payload }),
    [auto.engineNumber]: () => ({ ...state, [type]: payload.toUpperCase() }),
    [auto.bodyNumber]: () => ({ ...state, [type]: payload.toUpperCase() }),
    [auto.permittedMaxWeight]: () => ({ ...state, [type]: payload }),
    [auto.engineVolume]: () => ({ ...state, [type]: payload }),
    [auto.glonasNumber]: () => ({ ...state, [type]: payload.toUpperCase() }),
    [auto.mileage]: () => ({ ...state, [type]: payload }),
  }[type]();
}

const BlockAutoParams = ({
  carInstance,
  hiddenFields,
  errors,
  scrollTo,
  onScroll = fn,
  onChange = fn,
  onBlur = fn,
  disabled = false,
  requiredFields,
}) => {
  const ref = useRef();

  const [data, dispatch] = useReducer(reducer, carInstance, updateData);
  const [labelGlonas, setLabelGlonas] = useState(
    data[auto.glonasNumber].length ? GLONAS.dataPresent : GLONAS.dataMissing
  );

  useEffect(() => {
    if (ref && ref.current && scrollTo === BLOCK_PARAMETRS.name) {
      animateScrollTo(ref.current);
      onScroll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollTo]);

  useEffect(() => {
    dispatch({ type: 'update', payload: carInstance });
  }, [carInstance, dispatch]);

  const changeHandler = (item) => {
    const type = Object.keys(item)[0];
    const payload = item[type];

    dispatch({ type, payload });
    onChange(type);
  };

  const blurHandler = async (type) => {
    return {
      [auto.enginePower]: () => onBlur({ [type]: +data[type] || null }),
      [auto.engineNumber]: () => onBlur({ [type]: data[type] }),
      [auto.bodyNumber]: () => onBlur({ [type]: data[type] }),
      [auto.permittedMaxWeight]: () => onBlur({ [type]: +data[type] || null }),
      [auto.engineVolume]: () => onBlur({ [type]: data[type] || null }),
      [auto.glonasNumber]: () => onBlur({ [type]: data[type] }),
      [auto.mileage]: () => onBlur({ [type]: +data[type] }),
    }[type]();
  };

  const blurGlonasHandler = async (type) => {
    if (!data[auto.glonasNumber].length) {
      setLabelGlonas(GLONAS.dataMissing);
    }

    await blurHandler(type);
  };

  const focusGlonasHandler = () => {
    setLabelGlonas(GLONAS.dataPresent);
  };

  const errorGlonasNumber = errors[auto.glonasNumber]
    ? `Введено ${data.glonasNumber.length} ${errors[auto.glonasNumber]}`
    : errors[auto.glonasNumber];

  return (
    <S.GridStyled ref={ref}>
      <S.AutoParamsHeader>Параметры автомобиля</S.AutoParamsHeader>
      {!hiddenFields.includes(FIELDS.enginePower) && (
        <S.Power
          required={requiredFields.includes('enginePower')}
          isControlledComponent
          name={auto.enginePower}
          label="Мощность, лc"
          val={data[auto.enginePower]}
          mask={InputMask.ENGINE_POWER}
          error={errors[auto.enginePower]}
          onChange={changeHandler}
          onBlur={() => blurHandler(auto.enginePower)}
          disabled={disabled}
          dataTest="сarPower"
        />
      )}
      {!hiddenFields.includes(FIELDS.engineNumber) && (
        <S.EngineNumber
          isControlledComponent
          name={auto.engineNumber}
          label="Номер двигателя"
          val={data[auto.engineNumber]}
          mask={InputMask.VIN}
          unmask
          error={errors[auto.engineNumber]}
          onChange={changeHandler}
          onBlur={() => blurHandler(auto.engineNumber)}
          disabled={disabled}
          required={requiredFields.includes('engineNumber')}
          dataTest="numberEngine"
        />
      )}
      {!hiddenFields.includes(FIELDS.bodyNumber) && (
        <S.BodyNumber
          isControlledComponent
          name={auto.bodyNumber}
          label="Номер кузова"
          val={data[auto.bodyNumber]}
          mask={
            carInstance.steeringWheel === 'right' ? InputMask.VIN_BODY_RIGHT : InputMask.VIN_BODY
          }
          unmask
          error={errors[auto.bodyNumber]}
          onChange={changeHandler}
          onBlur={() => blurHandler(auto.bodyNumber)}
          disabled={disabled}
          required={requiredFields.includes('bodyNumber')}
          dataTest="numberCarBody"
        />
      )}
      {!hiddenFields.includes(FIELDS.permittedMaxWeight) && (
        <S.Mass
          isControlledComponent
          name={auto.permittedMaxWeight}
          label="Максимальная масса, кг"
          val={data[auto.permittedMaxWeight]}
          mask={InputMask.CAR_MASS}
          unmask
          error={errors[auto.permittedMaxWeight]}
          onChange={changeHandler}
          onBlur={() => blurHandler(auto.permittedMaxWeight)}
          disabled={disabled}
          required={requiredFields.includes('permittedMaxWeight')}
          dataTest="weightMax"
        />
      )}
      {!hiddenFields.includes(FIELDS.engineVolume) && (
        <S.EngineVolume
          isControlledComponent
          name={auto.engineVolume}
          label="Объём двигателя, см³ "
          val={data[auto.engineVolume]}
          mask={InputMask.ENGINE_VOLUME}
          unmask
          error={errors[auto.engineVolume]}
          onChange={changeHandler}
          onBlur={() => blurHandler(auto.engineVolume)}
          disabled={disabled}
          required={requiredFields.includes('engineVolume')}
        />
      )}
      {!hiddenFields.includes(FIELDS.glonasNumber) && (
        <S.GlonasNumber
          isControlledComponent
          name={auto.glonasNumber}
          label={labelGlonas}
          val={data[auto.glonasNumber]}
          mask={InputMask.GLONAS}
          unmask
          error={errorGlonasNumber}
          onChange={changeHandler}
          onBlur={() => blurGlonasHandler(auto.glonasNumber)}
          onFocus={focusGlonasHandler}
          disabled={disabled}
          required={requiredFields.includes('glonasNumber')}
        />
      )}
    </S.GridStyled>
  );
};

BlockAutoParams.propTypes = {
  carInstance: CustomPropTypes.carInstance.isRequired,
  onChange: PropTypes.func,
  onPatch: PropTypes.func,
};

export default BlockAutoParams;
