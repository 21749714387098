import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRouteMatch, Redirect } from 'react-router-dom';
import debounce from 'lodash/debounce';

import { dropApplications, getApplicationsSearch } from 'store/applications';
import { InView } from 'components/common/InView';

import { Header } from './components/Header';
import { ApplicationRow } from './components/ApplicationRow';

import * as help from './helpers.js';
import * as S from './style.js';

export const ApplicationsSearch = () => {
  const rootDispatch = useDispatch();
  const searchMatchObj = useRouteMatch('/applications/search');

  const search = useSelector((store) => store.applicFilters?.search);
  const sorting = useSelector((store) => store.applicFilters?.sorting);
  const user = useSelector((store) => store.user);
  const dealer = useSelector((store) => store.user.dealers.find((d) => d.id === store.user.dealer));
  const { searchFetch, searchIds, searchData } = useSelector((store) => store.applications);

  // eslint-disable-next-line no-unused-vars
  const [_page, setPage] = useState(1);

  const showOpenButton = help.canOpen(dealer, user);
  const isSearch = !!searchMatchObj;

  const loadApplications = useCallback(
    debounce((page) => {
      rootDispatch(getApplicationsSearch({ page }));
    }, 100),
    []
  );

  useEffect(() => {
    if (search && isSearch) {
      setPage(1);
      rootDispatch(dropApplications());
      loadApplications(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sorting, search, isSearch]);

  const nextPageHandler = (data) => {
    if (data.page <= 1) {
      return;
    }

    setPage((prev) => {
      if (data.page <= prev) {
        return prev;
      }

      loadApplications(data.page);
      return data.page;
    });
  };

  if (!search) {
    return <Redirect to="/applications/list" />;
  }

  return (
    <S.Container>
      <S.Table>
        <Header showOpenButton={showOpenButton} />

        {searchIds.map((id, i) => (
          <React.Fragment key={id}>
            {help.isShowViewChecker(i) && (
              <InView data={{ page: help.getViewCheckerPage(i) }} onShow={nextPageHandler} />
            )}

            <ApplicationRow application={searchData[id]} showOpenButton={showOpenButton} />
          </React.Fragment>
        ))}

        {searchFetch && <S.Loader color="none" show={true} />}
      </S.Table>
    </S.Container>
  );
};
