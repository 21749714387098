import React from 'react';

import * as S from './style.js';

export const SortHeadCell = ({ field, children, sortSettings, onSort }) => {
  const direction = sortSettings.field === field ? sortSettings.direction : null;

  const sortHandler = () => {
    let newDirection = 'asc';
    let newField = field;
    if (sortSettings.field === field) {
      switch (sortSettings.direction) {
        case 'asc':
          newDirection = 'desc';
          break;
        case 'desc':
          newDirection = null;
          newField = null;
          break;
        default:
          break;
      }
    }
    onSort(newField, newDirection);
  };

  return (
    <S.Cell onClick={sortHandler}>
      {children}
      <S.SortIcon>
        <S.SortUpIcon direction={direction} />
        <S.SortDownIcon direction={direction} />
      </S.SortIcon>
    </S.Cell>
  );
};
