import { put, select, takeLatest } from 'redux-saga/effects';

import * as provider from 'data-providers/startPage';
import { clearAnketa } from 'store/anketa';
import { assistanceClearAnketa } from 'store/assistanceAnketa';
import { insAnketaClear } from 'store/insuranceAnketa';
import { clearCarInstance } from 'store/carInstance';
import { assistanceCalcAllToInitState } from 'store/assistanceCalculation';
import { insuranceAllToInitState } from 'store/insuranceCalculation';
import { DEFAULT_ERROR_MESSAGE } from 'constants/index';
import { isFillCustomer } from 'utils/helpers';

import { getBlankCarInstance } from '../carInstance/sagas';
import * as actions from './constants';

const mockPayload = {
  clientName: 'Имя',
  clientMiddleName: 'Отчество',
  clientSurname: 'Фамилия',
  //clientPhone: '+7(111)1111111',
  dealerId: '',
  //clientEmail: 'email@email',
};

function* createLoan() {
  try {
    yield put({
      type: actions.LOAN_SET_LOADER,
      payload: true,
    });

    const { dealer } = yield select((store) => store.user);

    yield put(clearCarInstance());
    yield put(clearAnketa());
    yield put(assistanceClearAnketa());
    yield put(assistanceCalcAllToInitState());
    yield put(insAnketaClear());
    yield put(insuranceAllToInitState());

    const { data } = yield provider.createLoan({
      ...mockPayload,
      dealerId: `${dealer}`,
    });

    yield put({
      type: actions.LOAN_CREATED,
      payload: data?.response?.application_id,
    });

    /*
    yield put({
      type: 'LOAD_ANKETA',
      payload: data?.response?.application_id,
    });
    */
  } catch (err) {
    yield put({
      type: actions.EOSP_SET_ERROR,
      payload: err.customDescription || DEFAULT_ERROR_MESSAGE,
    });
  } finally {
    yield put({
      type: actions.LOAN_SET_LOADER,
      payload: false,
    });
  }
}

function* createEnsurance() {
  try {
    yield put({
      type: actions.SP_SET_LOADER,
      payload: true,
    });

    //const { dealer } = yield select((store) => store.user);

    /*
    yield put(clearCarInstance());
    yield put(clearAnketa());
    yield put(eosagoClearAnketa());
    */
    yield dropSp();

    const data = yield provider.createBlancUser();

    if (!isFillCustomer(data.personalInformation)) {
      data.personalInformation.name = '';
      data.personalInformation.surname = '';
      data.personalInformation.middleName = '';
    }

    yield getBlankCarInstance();

    yield put({
      type: actions.SP_ENSURANCE_CREATED,
      payload: data,
    });
    localStorage.setItem('customerId', data.id);
  } catch (err) {
    yield put({
      type: actions.SP_SET_ERROR,
      payload: err.customDescription || DEFAULT_ERROR_MESSAGE,
    });
  } finally {
    yield put({
      type: actions.SP_SET_LOADER,
      payload: false,
    });
  }
}

function* getCustomer({ payload }) {
  try {
    yield put({
      type: actions.SP_SET_LOADER,
      payload: true,
    });

    // OS-546 no need to clean the profile to update client
    // yield put(clearAnketa());
    // yield put(eosagoClearAnketa());

    const data = yield provider.getCustomer(payload.customerId);
    localStorage.setItem('customerId', data.id);

    yield put({
      type: actions.SP_STORE_CUSTOMER,
      payload: data,
    });
  } catch (err) {
    yield put({
      type: actions.SP_SET_ERROR,
      payload: err.customDescription || DEFAULT_ERROR_MESSAGE,
    });
  } finally {
    yield put({
      type: actions.SP_SET_LOADER,
      payload: false,
    });
  }
}

function* updateCustomer({ payload }) {
  try {
    const { name = '', surname = '', middleName = '', mobilePhone = '' } = payload;
    const data = {
      personalInformation: { name, surname, middleName },
      contacts: { mobilePhone },
    };

    yield provider.updateCustomer(payload.id, data);
  } catch (err) {
    // do nothing
  }
}

function* dropSp() {
  try {
    yield put({
      type: actions.SP_SET_LOADER,
      payload: true,
    });

    yield put(clearCarInstance());
    yield put(clearAnketa());
    yield put(assistanceClearAnketa());
    yield put(insAnketaClear());

    yield put({
      type: actions.SP_STORE_CUSTOMER,
      payload: {},
    });

    localStorage.removeItem('carinstanceId');
    localStorage.removeItem('customerId');
  } catch (err) {
    yield put({
      type: actions.SP_SET_ERROR,
      payload: err.customDescription || DEFAULT_ERROR_MESSAGE,
    });
  } finally {
    yield put({
      type: actions.SP_SET_LOADER,
      payload: false,
    });
  }
}

export function* watchInsuranceCreateLoan() {
  yield takeLatest(actions.CREATE_LOAN_APPLICATION, createLoan);
}

export function* watchNewEnsuranceCreate() {
  yield takeLatest(actions.SP_CREATE_ENSURANCE, createEnsurance);
}

export function* watchGetCustomer() {
  yield takeLatest(actions.SP_GET_CUSTOMER, getCustomer);
}

export function* watchUpdateCustomer() {
  yield takeLatest(actions.SP_UPDATE_CUSTOMER, updateCustomer);
}

export function* watchDrop() {
  yield takeLatest(actions.SP_DROP_CUSTOMER_CAR, dropSp);
}
