import styled, { css } from 'styled-components';

import Widget from 'components/primitives/widget';
import Time from 'components/common/Timer';
import { List, ListItem } from 'components/primitives/content';
import { CheckCircleSvg, ClockSvg } from 'assets/img';

import { PALETTE } from 'styles/constants';

export const ProgressContainer = styled(Widget)`
  margin-top: 15px;
  padding: 0;
`;

export const ProgressTitle = styled.h3`
  margin-bottom: 15px;
  padding: 20px 10px 10px;
`;

export const ProgressList = styled(List)`
  padding: 0 10px 20px 10px;

  > li:first-child {
    border-top: none;
    padding-top: 0px;
  }

  > li:last-child {
    border-bottom: none;
    padding-bottom: 0px;
  }
`;

export const ListStateItem = styled(ListItem)`
  margin-left: -10px;
  margin-right: -10px;

  ${({ isOpen }) =>
    isOpen &&
    css`
      color: ${PALETTE.black};
      border-top: 1px solid ${PALETTE.grey2};
      border-bottom: 1px solid ${PALETTE.grey2};
      padding-top: 10px;
      padding-bottom: 10px;
      margin-top: 10px !important;
      margin-bottom: 10px;
    `}

  & + & {
    margin-top: 5px;
  }
`;

export const StateLine = styled.div`
  font-size: 13px;
  font-weight: 500;
  color: ${(p) => p.color};
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ withTimer, color }) => css`
    background: ${withTimer ? PALETTE.yellow4 : 'inherit'};
    padding: ${withTimer ? '10px' : '0 10px'};
    color: ${withTimer ? PALETTE.black : color || 'inherit'};
  `}
`;

export const Timer = styled(Time)`
  font-size: 13px;
`;

export const SubStateList = styled(List)`
  width: 100%;
`;

export const ListCheckItem = styled(ListItem)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-top: 5px !important;
  font-size: 12px;
  line-height: 1.2;
  color: ${({ color }) => color || 'inherit'};
  cursor: ${({ clickEnabled }) => (clickEnabled ? 'pointer' : 'default')};

  ${({ withTimer, color }) => css`
    background: ${withTimer ? PALETTE.yellow4 : 'inherit'};
    padding: ${withTimer ? '10px' : '0 10px'};
    color: ${withTimer ? PALETTE.black : color || 'inherit'};
  `}
`;

export const DoneImg = styled(CheckCircleSvg)`
  width: 13px;
  height: 13px;
  flex-shrink: 0;

  > path {
    fill: ${PALETTE.green1};
  }
`;

export const AwaitImg = styled(ClockSvg)`
  width: 13px;
  height: 13px;
  flex-shrink: 0;
`;

export const ErrorImg = styled(DoneImg)`
  width: 13px;
  height: 13px;
  flex-shrink: 0;

  > path {
    fill: ${PALETTE.red1};
  }
`;
