import { EOW_NAMEPLATES } from './resources';

export const initialState = {
  applications: {},
  errors: {},
  nameplate: EOW_NAMEPLATES.unknown,
  lastModifiedAt: 0,
  fetchStates: {},
  eowMainFetchStates: {},
  applicationsPopupData: {},
  dealerLegals: {
    currentDealerId: null,
    legalsList: [],
  },
  ///
  isInCredit: true,
  insuranceBanks: [],
  selectedBank: null,
  ///
  multidrive: false,
  currentMultidrive: false,
};
