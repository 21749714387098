import styled from 'styled-components';
import { FormGridRowBase } from '../../style';
import { PALETTE } from 'styles/constants';
import {
  AnketaCheckBox,
  AnketaInputMask,
  AnketaSelect,
  AnketaDaData,
  AnketaSelectSearch,
  AreaInfo,
} from '../../../style';

export const FormGridRow = styled(FormGridRowBase)`
  grid-template-areas:
    '. ss ss pos pos pos pos .'
    '. org org org org ph ph is '
    '. . . . . pi pi . '
    '. fo fo to to inn inn .'
    '. indx indx . . . . . '
    '. ua ua ua ua ua ua .'
    '. oy oy op op ch ch .'
    '. fi fi . . . . . '
    '. fa fa fa fa fa fa .'
    '. . . . . wah wah .'
    '. posh posh posh posh ow ow . '
    '. sw sw st st . . .'
    '. . . . . . hide .'
    '. sh sh . . . . .'
    '. shse shse innse innse swse swse .';
`;
export const SocialStatus = styled(AnketaSelect)`
  grid-area: ss;
`;

export const OfficialWork = styled(AnketaCheckBox)`
  grid-area: ow;
`;

export const Organization = styled(AnketaDaData)`
  grid-area: org;
`;

export const OrganisationType = styled(AnketaSelect)`
  grid-area: to;
`;

export const OrganisationForm = styled(AnketaSelect)`
  grid-area: fo;
`;
export const OrganisationInn = styled(AnketaInputMask)`
  grid-area: inn;
`;
export const OrganisationIndex = styled(AnketaInputMask)`
  grid-area: indx;
`;
export const OrganisationAddress = styled(AnketaDaData)`
  grid-area: ua;
`;
export const OrganisationAge = styled(AnketaInputMask)`
  grid-area: oy;
`;
export const OrganisationPpl = styled(AnketaSelect)`
  grid-area: op;
`;
export const AddressMatch = styled(AnketaCheckBox)`
  grid-area: ch;
`;
export const OrganisationIndexFact = styled(AnketaInputMask)`
  grid-area: fi;
`;
export const OrganisationAddressFact = styled(AnketaDaData)`
  grid-area: fa;
`;

export const Phone = styled(AnketaInputMask)`
  grid-area: ph;
`;

export const ShowMoreBtn = styled.span`
  grid-area: hide;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 100.4%;
  text-decoration-line: underline;
  color: #b5b5b5;
  cursor: pointer;
  text-align: right;
  margin-top: -15px;
`;

export const Position = styled(AnketaSelectSearch)`
  grid-area: pos;
`;

export const ShiftMethod = styled(AnketaCheckBox)`
  grid-area: wah;
`;

export const PositionType = styled(AnketaSelect)`
  grid-area: posh;
`;

export const CareerStartTime = styled(AnketaInputMask)`
  grid-area: sw;
`;

export const ExperienceTime = styled(AnketaSelect)`
  grid-area: st;
`;

export const HomePhone = styled(AnketaInputMask)`
  grid-area: sh;
`;

export const HomePhoneSe = styled(AnketaInputMask)`
  grid-area: shse;
`;
export const OrganisationInnSe = styled(AnketaInputMask)`
  grid-area: innse;
`;
export const CareerStartTimeSe = styled(AnketaInputMask)`
  grid-area: swse;
`;

export const PhoneInfo = styled(AreaInfo)`
  display: flex;
  justify-items: space-between;
  grid-area: ${(p) => (p.showPhoneInfo ? 'pi' : 'is')};
  margin-top: ${(p) => (p.showPhoneInfo ? '-24px' : '20px')};
  width: ${(p) => (p.showPhoneInfo ? '100%' : '')};
  span {
    font-size: 12px;
    margin-right: auto;
    color: ${PALETTE.red1};
  }
`;
