export function saveItem(key, value) {
  try {
    const strValue = JSON.stringify(value);
    localStorage.setItem(key, strValue);
  } catch (err) {
    console.error(err);
  }
}

export function getItem(key) {
  try {
    const strValue = localStorage.getItem(key);
    return strValue !== null ? { ...JSON.parse(strValue) } : null;
  } catch (err) {
    console.error(err);
  }
}

export function removeItem(key) {
  localStorage.removeItem(key);
}
