import ym from 'react-yandex-metrika';

const isProd = process.env.NODE_ENV === 'production';

export const notificationClickTrack = () => isProd && ym('reachGoal', 'notifications');

export const applicationOpenTrack = () => isProd && ym('reachGoal', 'requested');

export const dealerFilterTrack = () => isProd && ym('reachGoal', 'dealer');

export const ksoFilterTrack = () => isProd && ym('reachGoal', 'credit');

export const mopFilterTrack = () => isProd && ym('reachGoal', 'sale');

export const phoneClickTrack = () => isProd && ym('reachGoal', 'telephone');

export const searchTrack = () => isProd && ym('reachGoal', 'search');

export const certificateClickTrack = () => isProd && ym('reachGoal', 'certificate');

export const sendClickTrack = () => isProd && ym('reachGoal', 'send');

export const lostClickTrack = () => isProd && ym('reachGoal', 'lost');
