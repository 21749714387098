import styled from 'styled-components';
import { CopySvg } from 'assets/img';
import { PALETTE } from 'styles/constants';

export const Wraper = styled.span`
  display: flex;
  align-items: center;
  line-height: 1;
  cursor: pointer;
  user-select: none;
  color: ${(p) => (p.isCopy ? PALETTE.red1 : 'inherit')};

  & svg * {
    ${(p) => (p.isCopy ? `fill: ${PALETTE.red1};` : '')};
    ${(p) => (p.isCopy ? `opacity: 1;` : '')};
  }

  &:active {
    color: ${PALETTE.red1};
  }

  &:active > svg * {
    fill: ${PALETTE.red1};
    opacity: 1;
  }
`;

export const CopyIco = styled(CopySvg)`
  width: 17.7px;
  height: 17.7px;
  margin-left: 5px;

  &.add-margin-left {
    margin-left: 10px;
  }
`;
