import * as actions from './constants';
import initialState from './initialState';

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.SET_EPOINTS_PROGRAMS:
      return { ...state, ...action.payload };
    case actions.RESET_EPOINTS_PROGRAMS:
      return { ...initialState };
    case actions.TOGGLE_EPOINTS_POPUP:
      return { ...state, showEPointsPopup: action.payload };
    default:
      return state;
  }
}

export function loadEPointsPrograms() {
  return { type: actions.LOAD_EPOINTS_PROGRAMS };
}

export function setEPointsPrograms(programs) {
  return { type: actions.SET_EPOINTS_PROGRAMS, payload: programs };
}

export function resetEPointsPrograms() {
  return { type: actions.RESET_EPOINTS_PROGRAMS };
}

export function toggleEPointPopup(state = false) {
  return { type: actions.TOGGLE_EPOINTS_POPUP, payload: state };
}
