import { convertDate } from 'utils/dataMappers/mappers';

export const abstractDrivingLicenseInitialState = {
  drivingLicenseId: null,
  drivingLicenseSeries: '',
  drivingLicenseNumber: '',
  drivingLicenseSeriesNumber: '',
  drivingLicenseIssuer: '',
  drivingLicenseIssueDate: '',
  drivingLicenseStartYear: '',
  drivingLicenseWhereGivenOut: '',
};

export const abstractDrivingLicenseFromAPIToState = (driverLicense) => ({
  drivingLicenseId: driverLicense.id || null,
  drivingLicenseSeries: driverLicense.series || '',
  drivingLicenseNumber: driverLicense.number || '',
  drivingLicenseSeriesNumber: `${driverLicense.series}${driverLicense.number}` || '',
  drivingLicenseIssuer: driverLicense.issuer || '',
  drivingLicenseIssueDate: convertDate(driverLicense.issueDate) || '',
  drivingLicenseStartYear: driverLicense.startDate || '',
  drivingLicenseWhereGivenOut: driverLicense.whereGivenOut || '',
});

export const abstractDrivingLicenseFromStateToAPI = (object) => ({
  driverLicense: {
    id: object.drivingLicenseId || null,
    issueDate: object.drivingLicenseIssueDate || '',
    issuer: object.drivingLicenseIssuer || '',
    number: object.drivingLicenseNumber || '',
    previousIssueDate: '',
    previousNumber: '',
    series: object.drivingLicenseSeries || '',
    startDate: object.drivingLicenseStartYear || '',
    whereGivenOut: object.drivingLicenseWhereGivenOut || '',
  },
});
