import * as app from './applications/sagas';
import * as filters from './filters/sagas';
import * as user from './user/sagas';
import * as mercury from './mercury/sagas';
import * as notifications from './notifications/sagas';
import * as assistanceAnketa from './assistanceAnketa/sagas';
import * as assistanceCalculation from './assistanceCalculation/sagas';
import * as insurance from './insuranceCalculation/sagas';
import * as insuranceAnketa from './insuranceAnketa/sagas';
import * as confirmSMS from './confirmSMS/sagas';
import * as startPage from './startPage/sagas';
import * as deal from './deal/sagas';
import * as calculator from './calculator/sagas';
import {
  watchGetCarInstance,
  watchPatchCarInstance,
  watchChangeCarInstance,
  watchGetCarInstanceById,
  watchGetDealersParams,
} from './carInstance/sagas';
import * as carReference from './carReference/sagas';
import {
  watchCheckNumber,
  watchLoadBrands,
  watchLoadModels,
  watchStoreAnketa,
  watchLoadAnketa,
  watchRecognizeFiles,
  watchFiles,
  watchLoadPdf,
} from './anketa/sagas';
import * as ePoints from './ePoints/sagas';

const sagas = {
  watchCheckNumber,
  watchLoadBrands,
  watchLoadModels,
  watchStoreAnketa,
  watchLoadAnketa,
  watchRecognizeFiles,
  watchFiles,
  watchLoadPdf,
  // carInstance
  watchGetCarInstance,
  watchPatchCarInstance,
  watchChangeCarInstance,
  watchGetCarInstanceById,
  watchGetDealersParams,
  ...app,
  ...filters,
  ...mercury,
  ...notifications,
  ...user,
  ...assistanceAnketa,
  ...assistanceCalculation,
  ...insurance,
  ...insuranceAnketa,
  ...confirmSMS,
  ...startPage,
  ...deal,
  ...calculator,
  ...ePoints,
  ///watchGetBrands: carReference.watchGetBrands,
  watchGetModels: carReference.watchGetModels,
  watchGetVersions: carReference.watchGetVersions,
};

export const customSagas = Object.values(sagas);
