import React from 'react';
import PropTypes from 'prop-types';
import { CheckBoxStyled, Box, Label, Button } from './style';
import { CheckBlueSvg } from 'assets/img';

/**
 * @param className
 * @param name
 * @param label
 * @param children
 * @param {boolean} disabled
 * @param {boolean} required
 * @param {boolean} checked
 * @param {'default'|'button'|'button-rounded'} type
 * @param onClick
 * @returns {*}
 * @constructor
 */
export const CheckBox = ({
  className,
  name = '',
  label = null,
  children = null,
  disabled = false,
  required = false,
  checked = false,
  type = 'default',
  style,
  size = 15,
  borderRadius = 0,
  onClick = null,
  labelClickOff = false,
  labelClickFunc = null,
  dataTest,
}) => {
  const handleClick = () => {
    onClick && onClick(name ? { [name]: !checked } : !checked);
  };

  if (type === 'default') {
    return (
      <CheckBoxStyled
        onClick={(e) => {
          e.stopPropagation();
          !labelClickOff && !disabled && handleClick();
          labelClickOff && labelClickFunc && labelClickFunc();
        }}
        withoutClick={!onClick}
        required={required}
        className={className}
        style={style}
        data-test={dataTest}
      >
        <Box
          onClick={(e) => {
            if (labelClickOff) {
              e.stopPropagation();
              !disabled && handleClick();
            }
          }}
          checked={checked}
          size={size}
          borderRadius={borderRadius}
          disabled={disabled}
          required={required}
          withoutClick={!onClick}
        >
          {checked && <CheckBlueSvg style={{ width: size }} />}
        </Box>
        {label && (
          <Label
            disabled={disabled && !labelClickFunc}
            withoutClick={!onClick}
            labelClickOff={labelClickOff && !labelClickFunc}
          >
            {label}
          </Label>
        )}
        {children && (
          <Label disabled={disabled} withoutClick={!onClick}>
            {children}
          </Label>
        )}
      </CheckBoxStyled>
    );
  }
  if (type.includes('button')) {
    return (
      <Button
        className={className}
        checked={checked}
        disabled={disabled}
        required={required}
        rounded={type === 'button-rounded'}
        onClick={() => !disabled && handleClick()}
      >
        {label}
        {children}
      </Button>
    );
  }
};

CheckBox.propTypes = {
  name: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  onClick: PropTypes.func,
  label: PropTypes.any,
  children: PropTypes.any,
  checked: PropTypes.bool,
  className: PropTypes.string,
  type: PropTypes.string,
};
