import * as actions from './constants';
import initialState from './initialState';

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.RESET_NOTIFICATIONS: {
      return { ...initialState };
    }

    case actions.SET_APPS_NOTIFICATIONS: {
      return { ...state, apps: { ...action.payload } };
    }

    case actions.SET_APPS_NOTIFICATIONS_MAINPAGE: {
      return { ...state, appsMainPage: { ...action.payload } };
    }

    case actions.SET_UNREAD_COUNT_NOTIFICATIONS: {
      return { ...state, unreadCount: action.payload };
    }

    case actions.FETCH_START_NOTIFICATIONS: {
      return { ...state, fetch: true };
    }

    case actions.FETCH_STOP_NOTIFICATIONS: {
      return { ...state, fetch: false };
    }

    default:
      return state;
  }
}

export function loadNotifications(payload) {
  return { type: actions.LOAD_NOTIFICATIONS, payload };
}

export function loadNotificationsMainPage(payload) {
  return { type: actions.LOAD_NOTIFICATIONS_MAINPAGE, payload };
}

export function resetNotifications() {
  return { type: actions.RESET_NOTIFICATIONS, payload: {} };
}

export function setUnreadCountNotifications(count) {
  return { type: actions.SET_UNREAD_COUNT_NOTIFICATIONS, payload: count };
}

export function calcUnreadCountNotifications() {
  return { type: actions.CALC_UNREAD_COUNT_NOTIFICATIONS, payload: {} };
}

export function setAppsNotifications(notifications) {
  return { type: actions.SET_APPS_NOTIFICATIONS, payload: notifications };
}

export function setAppsNotificationsMainPage(notifications) {
  return { type: actions.SET_APPS_NOTIFICATIONS_MAINPAGE, payload: notifications };
}

export function setFetchNotifications(state) {
  return {
    type: state ? actions.FETCH_START_NOTIFICATIONS : actions.FETCH_STOP_NOTIFICATIONS,
    payload: {},
  };
}

export function addAppNotification(notification) {
  return { type: actions.ADD_APP_NOTIFICATION, payload: notification };
}

export function readAppNotification(appId) {
  return { type: actions.READ_APP_NOTIFICATION, payload: appId };
}
