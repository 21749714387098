import React from 'react';

const ACTIVE_CLASS = 'report-page-menu__link-active';
const DEF_CLASS = 'submenu-link';

const linksData = [
  {
    title: 'Банки',
    to: `${process.env.REACT_APP_STAGE_URL}/#credit_red`,
    activeClass: ACTIVE_CLASS,
  },
  {
    title: 'Рынки',
    to: `${process.env.REACT_APP_STAGE_URL}/#market`,
    activeClass: ACTIVE_CLASS,
  },
];

const links = () =>
  linksData.map((link, id) => (
    <a href={link.to} className={DEF_CLASS} key={id}>
      {link.title}
    </a>
  ));

const OrdersLink = () => {
  return (
    <>
      <div className="nav-group top" style={{ marginTop: 230 }}>
        {links()}
      </div>
      <div className="nav-group middle" />
      <div className="nav-group bottom" />
    </>
  );
};

export default OrdersLink;
