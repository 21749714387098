import styled from 'styled-components';
import { PALETTE } from 'styles/constants';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

export const Banner = styled.div`
  width: calc(100% - 450px);
  height: 100%;
  overflow: hidden;
`;

export const Form = styled.div`
  position: relative;
  display: flex;
  width: 450px;
  height: 100%;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  &:after {
    position: absolute;
    content: '';
    left: -15px;
    top: 0px;
    width: 15px;
    height: 100%;
    box-shadow: 5px 0px 10px ${PALETTE.grey2};
  }
`;
