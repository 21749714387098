import React from 'react';
import ErrorPopup from 'components/primitives/error-popup';
import { ASW_STATES } from 'store/assistanceCalculation/resources';

export const applicationsListSorter = (a, b) => new Date(b.modifiedAt) - new Date(a.modifiedAt);

export const getErrorElement = (errors, onClick = () => {}) =>
  Object.keys(errors).reduce((acc, key) => {
    if (acc) {
      return acc;
    }

    if (errors[key]) {
      return <ErrorPopup onClose={onClick} errorMessage={errors[key]} />;
    }

    return acc;
  }, null);

export const isFetching = (fetchStates) => Object.values(fetchStates).some((isTrue) => isTrue);

export const isNeedDisableASWFields = (appsList) => {
  // функция за один проход по applications определяет необходимость
  // блокировки/разблокировки полей каринстанса и анкеты в ASW
  if (!appsList.length) {
    return false;
  }

  let offersAppsQty = 0;

  for (const application of appsList) {
    if (ASW_STATES.enabledCarInstanceAnketa.has(application.currentWorkflowState)) {
      offersAppsQty++;
      continue;
    }

    if (application.currentWorkflowState === ASW_STATES.client_decline) {
      continue;
    }

    return true;
  }

  return !offersAppsQty;
};

// const exceptionLegalEntities = ['7743578549', '9710000298'];
