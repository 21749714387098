import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setSortig } from 'store/filters';

import * as S from './style.js';

export const SortHeadCell = ({ field, children }) => {
  const rootDispatch = useDispatch();

  const sorting = useSelector(({ applicFilters }) => applicFilters?.sorting || {});
  const direction = Number(sorting[field] || 0) || 0;

  const sortHandler = () => {
    let newDirection = direction + 1;
    newDirection = newDirection > 1 ? -1 : newDirection;
    if (field === 'created') {
      newDirection = newDirection === 0 ? 1 : newDirection;
    }

    rootDispatch(setSortig(field, newDirection));
  };

  return (
    <S.Cell onClick={sortHandler}>
      {children}
      <S.SortIcon>
        <S.SortUpIcon direction={direction} />
        <S.SortDownIcon direction={direction} />
      </S.SortIcon>
    </S.Cell>
  );
};
