import React, { useState } from 'react';
import moment from 'moment';

import ErrorPopup from 'components/primitives/error-popup';
import ClipboardCopy from 'components/common/ClipboardCopy';
import WhatsapLink from 'components/common/WhatsapLink';
import InfoCard from '../InfoCard';
// MAINPAGE-20 Здесь и ниже временно скрыта проверка vin из карточки клиента
// import { Popup } from 'components/primitives/popup';
// import VinCheckContainer from '../../../MainInfoBlock/components/Content/components/LeftInfo/components/VinCheck';

import * as metrica from 'metrika/mainpage';

// import { PALETTE } from 'styles/constants';
import * as S from './style';
import { onlyNumber } from 'utils/stringUtils';

export const Notification = ({ data }) => {
  // const [creditPopup, setCredirPopup] = useState(false);
  const [error, setError] = useState(false);

  return (
    <S.Wrapper>
      <S.Description>
        {moment(data.date).format('DD.MM, HH:mm')}
        &nbsp;&nbsp;|&nbsp;&nbsp;
        <ClipboardCopy
          value={data.applicationId}
          className={'add-margin-left'}
          onClick={() => {
            metrica.applicationNumCopyTrack();
          }}
        >
          {`ID ${data.applicationId}`}
        </ClipboardCopy>
      </S.Description>

      <S.Title>{data.customer}</S.Title>
      <WhatsapLink
        phone={onlyNumber(data.customerPhone)}
        onClick={() => {
          metrica.toWhatsAppTrack();
        }}
      />
      <InfoCard data={data} />

      <a
        href={`${process.env.REACT_APP_STAGE_URL}/#application-details-new?app_id=${
          data.applicationId
        }&tab=${3}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <S.ApproveCreditButton
          onClick={() => {
            metrica.giveCreditButtonTrack();
          }}
        >
          Оформить цифровую сделку
        </S.ApproveCreditButton>
      </a>

      {/* <S.ApproveCreditButton
        onClick={() => {
          setCredirPopup(true);
          metrica.giveCreditButtonTrack();
        }}
      >
        Оформить цифровую сделку
      </S.ApproveCreditButton>

      {creditPopup && (
        <Popup
          onClose={() => setCredirPopup(false)}
          contentStyle={{
            borderRadius: '20px',
            backgroundColor: PALETTE.grey9,
            height: '190px',
            width: '632px',
          }}
        >
          <VinCheckContainer
            title={'Проверь автомобиль'}
            buttonText={'Подтвердить'}
            width={'100%'}
            height={'100%'}
          />
        </Popup>
      )} */}

      {error && <ErrorPopup onClose={() => setError(false)} errorMessage={'Ошибка прав доступа'} />}
    </S.Wrapper>
  );
};
