import {
  abstractAddressFromAPIToState,
  abstractAddressFromStateToAPI,
  abstractAddressInitialState,
  dadataAddressToState,
} from './abstractAddress';
import {
  defaultSelectOption,
  convertOptionToSysName,
  convertSysNameToSelectOption,
} from 'utils/dataMappers/mappers';

export const abstractOrganizationInitialState = {
  name: '',
  nameFull: '',
  inn: '',
  okved: '',
  ogrn: '',

  typeType: defaultSelectOption,
  formType: defaultSelectOption,

  legalAddress: abstractAddressInitialState,
  actualAddress: abstractAddressInitialState,
  actualAddressEquals: true,
  employeesCountType: defaultSelectOption,
  employerIndustryBranchType: defaultSelectOption,
  numYears: 0,
  employerManagerFullName: '',

  nameError: 'notinlist',
  isOrgFromDadata: 0,
};

export const abstractOrganizationFromAPIToState = (
  organization,
  { employerNewTypeList, companyCountList, legalFormList }
) => ({
  name: organization.name,
  nameFull: organization.nameFull,
  employerOpf: organization.employerOpf,
  inn: organization.inn,
  okved: organization.okved,
  ogrn: organization.ogrn,
  typeType: convertSysNameToSelectOption(organization.typeSysName, employerNewTypeList),
  formType: convertSysNameToSelectOption(organization.formSysName, legalFormList),

  legalAddress: abstractAddressFromAPIToState(organization.legalAddress),
  actualAddress: abstractAddressFromAPIToState(organization.actualAddress),
  actualAddressEquals: organization.actualAddressEquals,
  employeesCountType: convertSysNameToSelectOption(
    organization.employeesCountSysName,
    companyCountList
  ),
  employerIndustryBranchType: organization.employerIndustryBranchSysName,
  numYears: organization.registrationYear,
  employerManagerFullName: organization.employerManagerFullName,

  nameError: undefined,
  isOrgFromDadata: organization.isOrgFromDadata ?? 0,
});

export const abstractOrganizationFromStateToAPI = (organization) => ({
  employerOpf: organization.employerOpf,
  name: organization.name || '',
  nameFull: organization.nameFull,
  inn: organization.inn || '',
  okved: organization.okved || '',
  ogrn: organization.ogrn || '',
  typeSysName: convertOptionToSysName(organization.typeType),
  formSysName: convertOptionToSysName(organization.formType),

  legalAddress: abstractAddressFromStateToAPI(organization.legalAddress),
  actualAddress: abstractAddressFromStateToAPI(organization.actualAddress),
  actualAddressEquals: organization.actualAddressEquals,
  employeesCountSysName: convertOptionToSysName(organization.employeesCountType) || '',
  employerIndustryBranchSysName: 'employer-industry-branch-another', //convertOptionToSysName(organization.employerIndustryBranchType),
  registrationYear: parseInt(organization.numYears),
  employerManagerFullName: organization.employerManagerFullName,
  isOrgFromDadata: organization.isOrgFromDadata ?? 0,
});

export const dadataOrganizationToState = (result, { companyCountList }) => {
  if (result) {
    const { data } = result;
    const editedCompanyCountList = companyCountList
      .filter((item) => item.id !== 3326)
      .map((item) => {
        const spliter = item.value.split('-');
        if (item.id === 3325) {
          return { ...item, min: 500, max: 999999 };
        }
        if (item.id === 3327) {
          return { ...item, min: 0, max: 10 };
        }
        if (spliter.length > 0) {
          return {
            ...item,
            min: parseInt(spliter[0]),
            max: parseInt(spliter[1]),
          };
        }
        return item;
      });

    const opf = { ...data.name };

    return 0 !== Object.keys(data).length
      ? {
          name: data.name.short || data.name.short_with_opf || data.name.full,
          nameFull: data.name.full,
          inn: data.inn,
          okved: data.okved,
          ogrn: data.ogrn,
          typeType: defaultSelectOption,
          formType: defaultSelectOption,
          employerOpf: { ...data.opf },
          opf,
          employer_opf: { ...data.opf },

          legalAddress: data.address
            ? dadataAddressToState(data.address.data, data.address.value)
            : abstractAddressInitialState,
          employeesCount: data.employee_count,
          employeesCountType:
            editedCompanyCountList.find(
              (item) => item.min <= data.employee_count && data.employee_count < item.max
            ) || {},
          numYears: new Date().getFullYear() - new Date(data.state.registration_date).getFullYear(),
          actualAddressEquals: true,
          actualAddress: data.address
            ? dadataAddressToState(data.address.data, data.address.value)
            : abstractAddressInitialState,
          employerManagerFullName: (data.management && data.management.name) || data.name.full,
          employerName: opf.short === null ? opf.full : opf.short,
          employerNameFull: opf.full,

          nameError: undefined,
          isOrgFromDadata: 1,
        }
      : null;
  }
  return null;
};
