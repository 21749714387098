import { TabsHeaders } from './resources';

export const getCurrentTab = (path = '') => {
  const i = TabsHeaders.findIndex((tab) => {
    return tab.routeLinks.indexOf(path) >= 0;
  });

  return i >= 0 ? TabsHeaders[i].id : 0;
};

export const createNewParams = (carInstanceId, customerId, dealerId) => ({
  carInstanceId,
  customerId,
  dealerId,
});

export const getOrderStates = (orders = [], deal, assistanceCalculation, insuranceCalculation) => {
  const loanOrder = orders.find((o) => o.productId === 'loan_issue');
  const [assistanceOrder] = orders.filter((o) => o.productId === 'assistance').slice(-1);
  const [insuranceOrder] = orders.filter((o) => o.productId === 'insurance').slice(-1);
  const stateLoan = deal?.state?.value ? deal.state : loanOrder?.body?.applicationState;
  const stateAssistance = assistanceCalculation?.nameplate?.value
    ? assistanceCalculation?.nameplate
    : {
        ...(assistanceOrder?.body?.applicationState || {}),
        name: assistanceOrder?.body?.applicationState?.sysName,
      };
  const stateInsurance = insuranceCalculation?.nameplate?.value
    ? insuranceCalculation?.nameplate
    : {
        ...(insuranceOrder?.body?.applicationState || {}),
        name: insuranceOrder?.body?.applicationState?.sysName,
      };

  return { stateLoan, stateAssistance, stateInsurance };
};
