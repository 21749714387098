import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

const ACTIVE_CLASS = 'report-page-menu__link-active';
const DEF_CLASS = 'submenu-link';

const linksData = [
  {
    title: 'Эффективность продаж',
    to: `${process.env.REACT_APP_REPORTS_URL}/sales-performance`,
    activeClass: ACTIVE_CLASS,
    excroles: [90, 91],
  },
  {
    title: 'Доходность',
    to: `${process.env.REACT_APP_REPORTS_URL}/profit`,
    activeClass: ACTIVE_CLASS,
    excroles: [90, 91],
  },
  {
    title: 'Эффективность сотрудников',
    to: `${process.env.REACT_APP_REPORTS_URL}/employee-performance`,
    activeClass: ACTIVE_CLASS,
    excroles: [90, 91],
  },
  {
    title: 'Эффективность банков',
    to: `${process.env.REACT_APP_REPORTS_URL}/bank-efficiency`,
    activeClass: ACTIVE_CLASS,
    excroles: [90, 91],
  },
  {
    title: 'Сверка продаж',
    to: `${process.env.REACT_APP_REPORTS_URL}/sales-reconciliation`,
    activeClass: ACTIVE_CLASS,
    excroles: [90],
  },
  {
    title: 'Маркетинг',
    to: `${process.env.REACT_APP_REPORTS_URL}/marketing`,
    activeClass: ACTIVE_CLASS,
    excroles: [90, 91],
  },
  {
    title: 'План/факт',
    to: `${process.env.REACT_APP_REPORTS_URL}/plan-and-fact`,
    activeClass: ACTIVE_CLASS,
    excroles: [90, 91],
  },
];

const linksELements = (role) => {
  const solt = Date.now();
  return linksData
    .filter((link) => !link.excroles.includes(role))
    .map((link) => (
      <a key={link.to} href={`${link.to}?${solt}`} className={DEF_CLASS}>
        {link.title}
      </a>
    ));
};

const ReporsLink = ({ userWeight }) => {
  const role = useSelector((store) => store.user.role || -1);
  const [links, setLinks] = useState([]);

  useEffect(() => {
    let $links = linksELements(role);
    if (userWeight === 1) {
      $links = [
        <a href={linksData[4].to} className={DEF_CLASS} key={linksData[4].to}>
          {linksData[4].title}
        </a>,
      ];
    }

    if (userWeight === 100) {
      $links = [
        ...$links,
        <NavLink key={'/admin'} to={'/admin'} className={DEF_CLASS}>
          Админ
        </NavLink>,
      ];
    }

    setLinks($links);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userWeight, setLinks]);

  return (
    <>
      <div className="nav-group top"></div>
      <div className="nav-group middle"></div>
      <div
        className={cn(['nav-group bottom', { 'm-0': links.length === 1 }])}
        style={{
          height: 'unset',
          justifyContent: 'center',
        }}
      >
        {links}
      </div>
    </>
  );
};

export default ReporsLink;
