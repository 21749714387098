export const CREATE_LOAN_APPLICATION = 'insurance-start-page/create-loan';
export const LOAN_CREATED = 'insurance-start-page/loan-created';
export const EOSP_SET_ERROR = 'insurance-start-page/error';
export const LOAN_SET_LOADER = 'insurance-start-page/set-loader';

export const SP_CREATE_ENSURANCE = 'start-page/create-ensurance';
export const SP_DROP_CUSTOMER = 'start-page/drop-customer';
export const SP_DROP_CUSTOMER_CAR = 'start-page/drop-car-customer';
export const SP_ENSURANCE_CREATED = 'start-page/ensurance-created';
export const SP_SET_ERROR = 'start-page/ensurance-error';
export const SP_SET_LOADER = 'start-page/set-loader';
export const SP_GET_CUSTOMER = 'start-page/get-customer';
export const SP_UPDATE_CUSTOMER = 'start-page/update-customer';
export const SP_STORE_CUSTOMER = 'start-page/store-customer';
export const SP_STORE_CLIENTID = 'start-page/store-clientId';
export const SP_STORE_APPLICATION = 'start-page/store-application';
export const SP_DROP_CLIENT_ID = 'start-page/drop-client-id';

export const SP_ADD_CREDIT_ID = 'start-page/add-credit-id';
