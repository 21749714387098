import request from 'utils/request';
import throwCustomError from 'utils/throwCustomError';

export const getCarrotToken = async (email) => {
  const url = `/api/carrot/generate/${email}`;

  try {
    const results = await request
      .get(url, {
        headers: {
          'accept': 'application/json',
          'content-type': 'application/json',
        },
      })
      .catch(throwCustomError);

    if (!results || (results.status && results.status !== 200)) {
      return null;
    }

    return results.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};
