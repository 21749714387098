import styled from 'styled-components';
import { Grid } from 'components/primitives/grid';
import { SubHeader, InputMaskDaDataStyled } from '../../../Form/style';

export const Wrapper = styled(Grid)`
  margin-top: 41px;
  width: 100%;
  overflow: visible;
  grid-template-columns: repeat(8, 1fr);
  grid-template-areas:
    '. title title title title title title .'
    '. index index . . . . .'
    '. address address address address address address .';
`;

export const Title = styled(SubHeader)`
  grid-area: title;
`;

export const Index = styled(InputMaskDaDataStyled)`
  grid-area: index;
  margin-bottom: 15px;
`;

export const AddressInput = styled(InputMaskDaDataStyled)`
  grid-area: address;
  margin-bottom: 15px;
`;
