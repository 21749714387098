import request from 'utils/request';
import throwCustomError from 'utils/throwCustomError';

export const getBrands = async ({ dealerId, vehicleStatus = 'new' }) => {
  const url = `api/dealers/${dealerId}/vehicle/brands?vehicleStatus=${vehicleStatus}`;

  try {
    const results = await request
      .get(url, {
        headers: {
          'accept': 'application/json',
          'content-type': 'application/json',
        },
      })
      .catch(throwCustomError);

    if (!results || (results.status && results.status !== 200)) {
      return null;
    }

    return results.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const getModels = async ({ brandId, dealerId, vehicleStatus = 'new' }) => {
  const url = `api/dealers/${dealerId}/vehicle/brands/${brandId}/models?vehicleStatus=${vehicleStatus}`;

  try {
    const results = await request
      .get(url, {
        headers: {
          'accept': 'application/json',
          'content-type': 'application/json',
        },
      })
      .catch(throwCustomError);

    if (!results || (results.status && ![200, 201].includes(results.status))) {
      return null;
    }

    return results.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const getVersions = async ({ brandId, modelId, dealerId }) => {
  const url = `/api/dealers/${dealerId}/vehicle/brands/${brandId}/models/${modelId}/equipments`;

  try {
    const results = await request
      .get(url, {
        headers: {
          'accept': 'application/json',
          'content-type': 'application/json',
        },
      })
      .catch(throwCustomError);

    if (!results || (results.status && results.status !== 200)) {
      return null;
    }

    return results.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};
