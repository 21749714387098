import * as actions from './constants';
import { initialState } from './initialState';

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.SET_CONFIRM_SMS_CODE_FETCH: {
      return { ...state, confirmSMSCodeFetch: action.payload };
    }

    case actions.SET_INPUT_WARNING: {
      return { ...state, inputWarning: action.payload };
    }
    case actions.SET_TIMER_START: {
      return { ...state, timerStartTime: action.payload };
    }
    case actions.SET_OTP_ID: {
      return { ...state, otpId: action.payload };
    }

    default:
      return state;
  }
}

export function requestConfirmCode(payload) {
  return {
    type: actions.REQUEST_CONFIRM_CODE,
    payload,
  };
}

export function sendConfirmCode(payload) {
  return {
    type: actions.SEND_CONFIRM_CODE,
    payload,
  };
}

export function setIsConfirmSMSCodeFetch(payload) {
  return {
    type: actions.SET_CONFIRM_SMS_CODE_FETCH,
    payload,
  };
}

export function setInputWarning(payload) {
  return {
    type: actions.SET_INPUT_WARNING,
    payload,
  };
}

export function setTimerStart(payload) {
  return {
    type: actions.SET_TIMER_START,
    payload,
  };
}

export function setOtpId(payload) {
  return {
    type: actions.SET_OTP_ID,
    payload,
  };
}
