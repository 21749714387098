export const createErrorObj = ([path, errors]) => {
  let cursor = {};
  const result = {};
  cursor = result;
  const splittedPath = path.split('.');

  if (splittedPath.length === 1) {
    return [splittedPath[0], errors];
  }

  const nestedPath = splittedPath.slice(1);

  let index = 1;

  while (index <= nestedPath.length) {
    if (index === nestedPath.length) {
      cursor[`${nestedPath[index - 1]}`] = errors;
      break;
    }

    cursor[`${nestedPath[index - 1]}`] = {};
    cursor = cursor[`${nestedPath[index - 1]}`];
    index = index + 1;
  }

  return [splittedPath[0], result];
};

export const collectObjectedErrors = (err) => {
  const errors = err.inner.reduce((acc, curr) => {
    if (acc[curr.path]) {
      acc[curr.path] = [...acc[curr.path], ...curr.errors];
    } else {
      acc[curr.path] = [...curr.errors];
    }

    return acc;
  }, {});

  Object.keys(errors).forEach((errorKey) => {
    errors[errorKey] = errors[errorKey][0];
  });

  const objectedErrors = Object.entries(errors).reduce((acc, curr) => {
    const [key, nested] = createErrorObj(curr);

    if (typeof nested === 'string') {
      return { ...acc, [key]: nested };
    }

    return acc[key]
      ? { ...acc, [key]: { ...acc[key], ...nested } }
      : { ...acc, [key]: { ...nested } };
  }, {});

  return objectedErrors;
};

export const correctDriverErrorKey = (drivers, errors) => {
  const correctedErrors = Object.keys(errors).reduce((acc, key) => {
    const isDriverKey = /^drivers\[\d{1}\]/.test(key);
    if (isDriverKey) {
      const matched = /^drivers\[(\d){1}\]/.exec(key);
      if (!matched) {
        return acc;
      }

      const index = matched[1];
      if (index >= drivers.length) {
        return acc;
      }

      return { ...acc, [`driver@${drivers[index].id}`]: errors[key] };
    }

    return { ...acc, [key]: errors[key] };
  }, {});

  return correctedErrors;
};

export const validate = async (schema, data, dropErrors = () => {}, setErrors = () => {}) => {
  try {
    await schema.validate(data, { abortEarly: false });

    dropErrors();
  } catch (err) {
    setErrors(collectObjectedErrors(err));
  }
};
