import * as yup from 'yup';
import moment from 'moment';
import { requiredTest } from 'utils/validationUtils/validators';
import { MESS_REQUIRED_FIELD } from 'utils/validationUtils/messages';
import { MIN_PRICE } from '../constants';

//
const transformToType = (type, defaultValue) => (value) =>
  typeof value === type ? value : defaultValue;

export const customNumber = yup.mixed().transform(transformToType('number', null));
export const customString = yup.string().transform(transformToType('string', ''));

export const brand = customString.test('required', `${MESS_REQUIRED_FIELD} `, requiredTest);
export const model = customString.test('required', `${MESS_REQUIRED_FIELD} `, requiredTest);
export const version = customString.test('required', `${MESS_REQUIRED_FIELD} `, requiredTest);
//

export const enginePower = customNumber
  .test(
    'required',
    MESS_REQUIRED_FIELD,
    (value) => requiredTest(value) && value !== '0' && value !== 0
  )
  .test('incorrect', 'Поле должно содержать 2 или 3 символа', (value) => {
    const enginePower = `${value}`;

    return enginePower.length === 2 || enginePower.length === 3;
  });

export const engineNumber = customString.test('required', MESS_REQUIRED_FIELD, requiredTest);
export const bodyNumber = customString.test('required', MESS_REQUIRED_FIELD, requiredTest);

export const permittedMaxWeight = customNumber
  .test('required', MESS_REQUIRED_FIELD, (value) => {
    return requiredTest(value) && value !== '0' && value !== 0;
  })
  .test('incorrect', 'Значение должно быть от 900 до 3500', (value) => {
    const v = Number(value);
    if (isNaN(v)) return false;
    return v <= 3500 && v >= 900;
  });

export const engineVolume = customString
  .test('required', MESS_REQUIRED_FIELD, requiredTest)
  .test('incorrect', 'Значение должно быть от 500 до 9000', (value) => {
    const v = Number(value);
    if (isNaN(v)) return false;
    return v <= 9000 && v >= 500;
  });

export const glonasNumber = customString.test(
  'incorrect',
  'из 19 символов',
  (value) => value.length < 1 || value.length >= 19
);

export const price = customNumber
  // .test('required', MESS_REQUIRED_FIELD, (value) => value !== null)
  .test(
    'incorrect',
    'Стоимость не может быть меньше 10 000',
    (value) => value !== null && value >= MIN_PRICE
  );

export const vin = customString
  .test('incorrect', 'Некорректный VIN', (value) => {
    const charArray = Array.from(value || '');

    const repeatCount = charArray.reduce(
      (acc, letter) => {
        if (acc.stop) {
          return acc;
        }

        if (acc.currentChar === letter) {
          const repeatCount = ++acc.repeatCount;

          return { currentChar: letter, repeatCount, stop: repeatCount >= 9 };
        }

        return { currentChar: letter, repeatCount: 1, stop: false };
      },
      { currentChar: '', repeatCount: 0, stop: false }
    ).repeatCount;

    const allNumbers = charArray.every((letter) => letter >= '0' && letter <= '9');
    const allLetters = charArray.every(
      (letter) => (letter >= 'A' && letter <= 'Z') || 'АВЕКМНОРСТУХ'.includes(letter)
    );

    return (repeatCount < 9 && !allNumbers && !allLetters) || !value;
  })
  .test('incorrect', 'Дозаполните VIN, должно быть 17 символов', (value) =>
    value ? value.length === 17 : true
  );

export const ptsSeriesRegexp = /^\d\d([А-Яа-яёЁ]{2})|(\d{2})$/;
export const onlyPtsSeries = /^\d\d[А-Яа-яёЁ]{2}$/;

export const number = customString
  .test('required', MESS_REQUIRED_FIELD, requiredTest)
  .test('incorrect', 'Серия ПТС указана неверно', (value) => {
    const strValue = `${value}`;
    const series = strValue.slice(0, 4);

    return ptsSeriesRegexp.test(series) && strValue.length >= 4;
  })
  .test('incorrect', 'Должно содержать 10 или 15 символов', (value) => {
    const strValue = `${value}`;

    return strValue.length === 10 || strValue.length === 15;
  })
  .test('incorrect', 'Серия ПТС указана неверно', (value) => {
    const strValue = `${value}`;
    const series = strValue.slice(0, 4);

    return onlyPtsSeries.test(series) || strValue.length !== 10;
  });

export const simpleDate = customString
  .test('required', MESS_REQUIRED_FIELD, requiredTest)
  .test('incorrect', 'Некорректная дата', function (value) {
    const isValid = value.includes('-') && !value.includes('_');

    return isValid;
  })
  .test('incorrect', 'Дата - не ранее 1 января 1970 года', function (value) {
    const issueDate = moment(value, 'YYYY-MM-DD');
    return moment(issueDate).isAfter(moment(new Date(1970, 0, 1), 'DD.MM.YYYY'));
  })
  .test('incorrect', 'Не может быть больше текущей даты', function (value) {
    const lastValidDate = moment();
    const issueDate = moment(value, 'YYYY-MM-DD');

    const diff = lastValidDate.diff(issueDate);

    return diff >= 0;
  });

export const issueDate = simpleDate.test(
  'incorrect',
  'Не может быть меньше года выпуска авто',
  function (value) {
    let { year } = this.from.at(-1).value;
    if (!year) {
      year = this.parent.year;
    }

    const isValid = moment(value, 'YYYY-MM-DD').isValid();

    return isValid && +value.split('-')[0] >= year;
  }
);

export const mileage = customNumber;

export const schemaCarDetail = yup.object().shape(
  {
    brand,
    model,
    year: customNumber.test('required', 'Укажите год выпуска', (value) => !!+value),
    enginePower,
    engineNumber,
    bodyNumber,
    permittedMaxWeight,
    engineVolume,
    glonasNumber,
    price,
    vin: vin.test('required', MESS_REQUIRED_FIELD, requiredTest),
    mileage: mileage.when('isNew', {
      is: true,
      then: (schema) => schema,
      otherwise: (schema) => {
        return schema.test(
          'required',
          MESS_REQUIRED_FIELD,
          (value) => requiredTest(value) && value !== '0' && value !== 0
        );
      },
    }),
    isNew: yup.bool(),
    isAvailablePts: yup.boolean(),
    pts: yup.object({
      number,
      issueDate,
      year: customNumber,
    }),
  },
  ['vin']
);

export const schemaCarDetailAssistance = yup.object().shape(
  {
    brand,
    model,
    version: customString.when('isNew', {
      is: true,
      then: version,
      otherwise: customString,
    }),
    year: customNumber.test('required', 'Укажите год выпуска', (value) => !!+value),
    price,
    mileage: mileage.when('isNew', {
      is: true,
      then: (schema) => schema,
      otherwise: (schema) => {
        return schema.test(
          'required',
          MESS_REQUIRED_FIELD,
          (value) => requiredTest(value) && value !== '0' && value !== 0
        );
      },
    }),
    bodyNumber: customString.when('steeringWheel', {
      is: (steeringWheel) => steeringWheel === 'right',
      then: customString
        .test('required', MESS_REQUIRED_FIELD, requiredTest)
        .test(
          'incorrect',
          'Минимально допустимое кол-во символов – 7',
          (val = '') => val.length >= 7
        ),
      otherwise: customString,
    }),
    vin: customString.when('steeringWheel', {
      is: (steeringWheel) => steeringWheel === 'right',
      then: customString,
      otherwise: vin.test('required', MESS_REQUIRED_FIELD, requiredTest),
    }),
    pts: yup.object({ year: customNumber }),
    isNew: yup.bool(),
    isAvailablePts: yup.boolean(),
  },
  ['vin']
);

export const schemaCarDetailInsurance = yup.object().shape(
  {
    brand,
    model,
    year: customNumber.test('required', 'Укажите год выпуска', (value) => !!+value),
    price,
    mileage: mileage.when('isNew', {
      is: true,
      then: (schema) => schema,
      otherwise: (schema) => {
        return schema.test(
          'required',
          MESS_REQUIRED_FIELD,
          (value) => requiredTest(value) && value !== '0' && value !== 0
        );
      },
    }),
    enginePower,
    engineNumber,
    bodyNumber: customString.when('steeringWheel', {
      is: (steeringWheel) => steeringWheel === 'right',
      then: customString
        .test('required', MESS_REQUIRED_FIELD, requiredTest)
        .test(
          'incorrect',
          'Минимально допустимое кол-во символов – 7',
          (val = '') => val.length >= 7
        ),
      otherwise: customString.test('required', MESS_REQUIRED_FIELD, requiredTest),
    }),
    permittedMaxWeight,
    engineVolume,
    glonasNumber,
    vin: customString.when('steeringWheel', {
      is: (steeringWheel) => steeringWheel === 'right',
      then: customString,
      otherwise: vin.test('required', MESS_REQUIRED_FIELD, requiredTest),
    }),
    pts: yup.object({
      number,
      issueDate,
      year: customNumber,
    }),
    isNew: yup.bool(),
    isAvailablePts: yup.boolean(),
  },
  ['vin']
);

export const schemaPatchCarDetail = yup.object().shape({
  brand,
  model,
  price,
  year: customNumber.test('required', MESS_REQUIRED_FIELD, requiredTest),
});

export const schemaVin = yup.lazy(() =>
  yup.object().shape({
    vin: vin,
  })
);
