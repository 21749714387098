import styled from 'styled-components';

import { Search } from 'components/primitives/search';

export const Container = styled.div`
  margin-top: 25px;
`;

export const SearchInput = styled(Search)`
  height: 45px;
  margin-bottom: 25px;
  width: 300px;
`;
