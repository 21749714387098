import { BLOCK_MAIN, BLOCK_PARAMETRS, BLOCK_DOCS, BLOCK_CAR_SELECT } from './constants';

export const getBlockNameByFieldsNames = (fields = []) => {
  const blocks = [BLOCK_CAR_SELECT, BLOCK_MAIN, BLOCK_PARAMETRS, BLOCK_DOCS];

  const founded = blocks.find((block) => {
    return block.fields.some((field) => fields.includes(field));
  });

  return founded?.name || null;
};

export const isVin = (value) => (value.length > 9 ? true : false);

export const checkErrors = (errors) => {
  return (
    Object.entries(errors).filter((e) => {
      const [name, value] = e;
      if (['pts', 'diagnosticCard'].indexOf(name) !== -1) {
        return Object.values(value).some((i) => Boolean(i));
      } else {
        return Boolean(value);
      }
    }).length > 0
  );
};
