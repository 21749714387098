export const MAPPER_TYPE_FORM_ORG = {
  new_commercial: [
    'legal_new_type_ltd',
    'legal_new_type_js',
    'legal_new_type_pao',
    'legal_new_type_zao',
    'legal_new_type_ao',
    'legal_new_type_chop',
    'legal_new_type_economic_partnership',
    'legal_new_type_ho',
    'legal_new_type_nao',
    'legal_new_type_kfh',
    'legal_new_type_pko',
    'legal_new_type_separate_subdivision',
    'legal_new_type_pt',
    'legal_new_type_private',
  ],
  new_not_commercial: [
    'legal_new_type_jtg',
    'legal_new_type_ro',
    'legal_new_type_fbo',
    'legal_new_type_oop',
    'legal_new_type_np',
    'legal_new_type_ano',
    'legal_new_type_akyuk',
    'legal_new_type_d',
    'legal_new_type_consumer_cooperative',
    'legal_new_type_kpk',
    'legal_new_type_ovc',
    'legal_new_type_association_union',
    'legal_new_type_presentation_legal_entity',
    'legal_new_type_branch_legal_entity',
    'legal_new_type_pif',
    'legal_new_type_international_organization',
    'legal_new_type_npf',
    'legal_new_type_public_law_company',
  ],
  new_butget: [
    'legal_new_type_gbu',
    'legal_new_type_mbu',
    'legal_new_type_gbou',
    'legal_new_type_mbou',
    'legal_new_type_government_agency',
    'legal_new_type_municipal',
    'legal_new_type_courts',
  ],
  new_unitarnoe: [
    'legal_new_type_unitary_enterprise',
    'legal_new_type_gup',
    'legal_new_type_fgup',
    'legal_new_type_mup',
  ],
  new_gosslugba: [],
  new_ind_predprin: ['legal_new_type_ip', 'legal_new_type_gkfk', 'legal_new_type_kfh'],
};

export const SysNamesToOpf = {
  'Хозяйственное товарищество': [
    '11000',
    '11051',
    '11064',
    '13000',
    '14000',
    '14100',
    '14153',
    '14154',
    '14155',
    '14200',
  ],
  'ХО': ['12000'],
  'АО': ['12200'],
  'ПАО': ['12247'],
  'НАО': ['12267'],
  'ООО': ['12300'],
  'КФХ': ['15300'],
  'ПКО': ['19000'],
  'Потребительский кооператив': [
    '20100',
    '20107',
    '20109',
    '20110',
    '20111',
    '20112',
    '20115',
    '20116',
  ],
  'ЖСК, ТСЖ, Гаражный кооператив': ['20101', '20102', '20103', '20700', '20702', '20716'],
  'КПК': ['20104', '20105', '20106'],
  'ОВС': ['20108'],
  'Фонд, благотворительная организация': ['20121', '70400', '70401', '70403', '70404', '75502'],
  'Общественное объединение, партия': [
    '20200',
    '20201',
    '20202',
    '20210',
    '20211',
    '20217',
    '21100',
    '21200',
  ],
  'Ассоциация, союз': [
    '20600',
    '20601',
    '20603',
    '20604',
    '20605',
    '20606',
    '20607',
    '20608',
    '20611',
    '20612',
    '20613',
  ],
  'Адвокатская контора, юридическая консультация, нотариус': [
    '20609',
    '20610',
    '20615',
    '20616',
    '50200',
    '50201',
    '50202',
  ],
  'Некоммерческое партнерство': ['20614', '20619', '20620'],
  'Представительство юридического лица': ['30001'],
  'Филиал юридического лица': ['30002'],
  'Обособленное подразделение юридического лица': ['30003', '30004'],
  'ПИФ': ['30005'],
  'ПТ': ['30006'],
  'Районные суды, городские суды': ['30008'],
  'Международная Организация': ['40000', '40001', '40002'],
  'ИП': ['50000', '50100', '50102'],
  'ГКФХ': ['50101'],
  'Унитарное предприятие': ['65000', '65100', '65141', '65142', '65143', '65200'],
  'ФГУП': ['65241'],
  'ГУП': ['65242'],
  'МУП': ['65243'],
  'НПФ': ['70402'],
  'Автономная некоммерческая организация': ['71400'],
  'Религиозная организация': ['71500'],
  'Публично-правовая компания': ['71600', '71601', '71602', '71610'],
  'Государственное учреждение': [
    '75000',
    '75100',
    '75101',
    '75103',
    '75104',
    '75200',
    '75201',
    '75203',
    '75204',
    '75300',
  ],
  'Муниципальное учреждение': ['75400', '75401', '75403', '75404'],
  'Частное учреждение': ['75500', '75505'],
};

export const getSysNameByOpf = (opf) => {
  let sysName = 'ПКО';
  if (!opf || opf.length !== 5) return sysName;

  let clearOpf = opf.substring(0, 3) + '00',
    clearSysName = '';
  let finded = false;
  Object.keys(SysNamesToOpf).forEach((key) => {
    if (SysNamesToOpf[key].find((o) => o === opf)) {
      sysName = key;
      finded = true;
    }
    if (SysNamesToOpf[key].find((o) => o === clearOpf)) {
      clearSysName = key;
    }
  });
  return !finded && clearSysName ? clearSysName : sysName;
};

export const daDataURL = {
  ID_PATH: 'https://dadata.ru/api/v2/findById/party',
  INN_PATH: 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/party',
  NAME_PATH: 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/party',
};
