import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { insuranceGetBanksList } from 'store/insuranceCalculation';

import * as S from '../style';

export const DealersBanks = () => {
  const rootDispatch = useDispatch();

  const dealerId = useSelector((state) => state.user?.dealer);
  const { insuranceBanks } = useSelector((store) => store.insuranceCalculation);

  useEffect(() => {
    rootDispatch(insuranceGetBanksList({ dealerId, priority: true }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealerId]);

  return (
    <S.DealersBanksWrapper>
      <S.Title>Мои банки</S.Title>
      {insuranceBanks.map((bank) => (
        <S.BanksContainer key={bank.id}>
          <S.BankItem key={bank.id} id={bank.id} type="pps" title={bank.value} />
          <S.BankName>{bank.value}</S.BankName>
        </S.BanksContainer>
      ))}
    </S.DealersBanksWrapper>
  );
};
