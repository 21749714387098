import React, { useState, useEffect, useContext } from 'react';

import PropTypes from 'prop-types';
import NavFloatDataContext from '../NavFloatDataContext';
import Search from './Search';
import ListContent from './ListContent';

import { getDealersDict } from 'data-providers/authProvider';

import * as S from './style';

const DealersSearchBlock = ({ clickDealerHandler = () => {}, numberDealer }) => {
  const [numberCurrentPage, setNumberCurrentPage] = useState(1);
  const [stringInput, setStringInput] = useState('');
  const [stringSearch, setStringSearch] = useState('');
  const [arraySearchData, setArraySearchData] = useState([]);
  const [numberTotalItems, setNumberTotalItems] = useState(0);
  const [error, setError] = useState({
    stringMessage: 'test',
    isError: false,
  });

  const [fixedListItemCoordinates, setFixedListItemCoordinates] = useState(null);
  const [hoverDealer, setHoverDealer] = useState(null);

  const navFloatDataObj = useContext(NavFloatDataContext);
  const numberDealersOnPage = 5;

  const getSearchResult = (stringSearch = '', numberPage = 1) => {
    getDealersDict({
      'name': stringSearch,
      'page': numberPage,
      'perPage': numberDealersOnPage,
      'order[name]': 'ASC',
    })
      .then((obj) => {
        numberPage === 1
          ? setArraySearchData(obj['hydra:member'])
          : setArraySearchData((prev) => [...prev, ...obj['hydra:member']]);
        setNumberTotalItems(obj['hydra:totalItems']);
      })
      .catch((error) => {
        setError({
          stringMessage: error.message,
          isError: false,
        });
      });
  };

  useEffect(() => {
    setHoverDealer(null);
  }, [navFloatDataObj.active, numberDealer]);

  useEffect(() => {
    setStringInput('');
    setStringSearch('');
  }, [navFloatDataObj.active]);

  useEffect(() => {
    getSearchResult(stringSearch);
    setHoverDealer(null);
    setFixedListItemCoordinates(null);

    /* eslint-disable-next-line */
  }, [stringSearch]);

  return (
    <S.Wrapper>
      <Search
        totalItems={numberTotalItems}
        dealersOnPage={numberDealersOnPage}
        search={stringSearch}
        value={stringInput}
        onChange={(e) => {
          setStringInput(e.target.value.trimLeft());
        }}
        onSearch={(stringValue) => {
          setNumberCurrentPage(1);
          setStringSearch(stringValue);
        }}
      />
      <S.List>
        <ListContent
          error={error}
          hoverDealer={hoverDealer}
          arraySearchData={arraySearchData}
          getSearchResult={getSearchResult}
          stringSearch={stringSearch}
          setNumberCurrentPage={setNumberCurrentPage}
          numberDealer={numberDealer}
          numberCurrentPage={numberCurrentPage}
          fixedListItemCoordinates={fixedListItemCoordinates}
          mouseEnterHandlerCreator={(dealer) => (evt) => {
            setHoverDealer(dealer);

            setFixedListItemCoordinates({
              x: evt.currentTarget.getClientRects()[0].x,
              y: evt.currentTarget.getClientRects()[0].y,
            });
          }}
          clickHandlerCreator={(dealer) => () => {
            clickDealerHandler(dealer);
            setStringInput('');
            setStringSearch('');
          }}
          mouseLeaveHandler={() => {
            setHoverDealer(null);
            setFixedListItemCoordinates(null);
          }}
        />
      </S.List>
    </S.Wrapper>
  );
};

DealersSearchBlock.propTypes = {
  className: PropTypes.string,
  numberDealer: PropTypes.number.isRequired,
  clickDealerHandler: PropTypes.func,
};

export default DealersSearchBlock;
