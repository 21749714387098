export const copy = (text) => {
  const tmp = document.createElement('textarea');

  tmp.innerHTML = text.toString();

  document.body.appendChild(tmp);
  tmp.select();

  document.execCommand('copy');
  tmp.remove();
};
