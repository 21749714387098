import { COMMON_SET } from './constants';

const commonState = {
  carBrand: null,
  carModel: null,
  banks: null,
  loading: false,
  errors: null,
};

export default function (state = commonState, action) {
  switch (action.type) {
    case COMMON_SET:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
