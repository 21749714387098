/* eslint-disable no-undef */
import axios from 'axios';

export const GET = 'get';
export const POST = 'post';
export const PUT = 'put';
export const PATCH = 'patch';
export const DELETE = 'delete';

let URL = `${process.env.REACT_APP_API_ENTRYPOINT}`;
if (process.env.REACT_APP_MODE === 'dev') {
  URL = `${process.env.REACT_APP_LOCAL_API_URL}`;
}

const Agent = (baseURL = URL) => {
  const _agent = axios.create({ baseURL });

  return _agent;
};

export const agent = Agent();
export const traefikRequest = Agent(process.env.REACT_APP_TRAEFIK_URL);

export default agent;
