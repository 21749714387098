import styled from 'styled-components';

import State from 'components/primitives/state';
import { Title2, Description } from 'components/primitives/content';

export const Header = styled.div`
  display: flex;
  align-items: center;
`;

export const Content = styled.div`
  margin-top: 30px;
`;

export const CostContent = styled.div`
  display: flex;
  margin-top: 25px;
  align-items: center;
  flex-wrap: wrap;
`;

export const Title = styled(Title2)`
  flex: 1;
  margin: 0px;
  font-size: 20px;
  line-height: 1;
`;

export const HeadState = styled(State)`
  flex: 0;
  max-width: 60%;
  margin-left: 5px;
`;

export const Cost = styled(Title2)`
  margin: 5px 10px 0px 0px;
  line-height: 1;
`;

export const CostDescription = styled(Description)`
  margin: 5px 0px 0px 0px;
  line-height: 1;
`;
