import { put, select, all, call, takeLatest } from 'redux-saga/effects';
import assignWith from 'lodash/assignWith';

import * as provider from 'data-providers/insuranceAnketa';
import { DEFAULT_ERROR_MESSAGE } from 'constants/index';
import { isFillCustomer } from 'utils/helpers';

import * as actions from './constants';
import * as Helper from './helper';
import backToFrontMapper, { backToFrontCustomer } from './backToFrontMapper';
import { saveCustomers } from './saveHelper';

function* fillInsuranceAnketaCustomer({ payload: { anketaCustomerId: customerId } }) {
  try {
    const customer = yield call(provider.getCustomer, { customerId });
    const mappedInsurer = backToFrontCustomer({ customer });

    yield put({
      type: actions.INS_ANKETA_SET_CUSTOMER,
      payload: { mappedInsurer, filledCustomers: [], applicationId: null },
    });
  } catch (err) {
    console.error('fillInsuranceAnketaCustomer Error:', err);
  }
}

function* getCustomersByApplicationId({ payload: { applicationId, product, shouldCombineData } }) {
  try {
    yield put({ type: actions.INS_ANKETA_SET_CUSTOMERS_FETCH, payload: 'pending' });

    const customers = yield call(provider.getCustomers, { applicationId, product });

    yield put({ type: actions.INS_ANKETA_SET_NODES, payload: { nodes: customers } });

    const allCustomersIds = Helper.getUniqueCustomerIds({ nodes: customers });

    const fullDataCustomers = yield all(
      allCustomersIds.map((customerId) => call(provider.getCustomer, { customerId }))
    );

    fullDataCustomers.forEach((customer) => {
      if (!isFillCustomer(customer.personalInformation)) {
        customer.personalInformation.name = '';
        customer.personalInformation.surname = '';
        customer.personalInformation.middleName = '';
      }
    });

    const insuranceAnketa = yield select((store) => store.insuranceAnketa);
    const {
      customers: mappedCustomers,
      ids: { ownerId, kaskoInsurerId },
    } = backToFrontMapper({ fullDataCustomers, customers });

    const combinedData = shouldCombineData
      ? assignWith({}, mappedCustomers, insuranceAnketa, (a, b) => (!b ? a : undefined))
      : { ...insuranceAnketa, ...mappedCustomers };

    if (!shouldCombineData) {
      combinedData.insurerDadataInfo =
        fullDataCustomers.find((el) => el.id === ownerId)?.registrationAddress?.address ?? {};

      if (kaskoInsurerId) {
        combinedData.kaskoInsurer.insurerDadataInfo =
          fullDataCustomers.find((el) => el.id === kaskoInsurerId)?.registrationAddress?.address ??
          {};
      }
    }

    yield put({
      type: actions.INS_ANKETA_SET_CURRENT_SAVED_DATA,
      payload: { currentSavedData: combinedData },
    });
    yield put({
      type: actions.INS_ANKETA_SET_CUSTOMER,
      payload: { mappedInsurer: combinedData, filledCustomers: fullDataCustomers, applicationId },
    });
  } catch (err) {
    yield put({
      type: actions.INS_ANKETA_SET_NETWORK_ERROR,
      payload: err.customDescription || DEFAULT_ERROR_MESSAGE,
    });
  } finally {
    yield put({ type: actions.INS_ANKETA_SET_CUSTOMERS_FETCH, payload: 'done' });
  }
}

export function* watchFillInsuranceCustomerShort() {
  yield takeLatest(actions.INS_ANKETA_SET_CUSTOMER_SHORT, fillInsuranceAnketaCustomer);
}

export function* watchInsuranceGetCustomers() {
  yield takeLatest(actions.INS_ANKETA_GET_CUSTOMERS_IDS, getCustomersByApplicationId);
}

export function* watchInsuranceUpdateCustomer() {
  yield takeLatest(actions.INS_ANKETA_PATCH_CUSTOMER, saveCustomers);
}
