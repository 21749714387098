import styled from 'styled-components';

import { MainPageLogo, SideBarList, SideBarCalc, SideBarReports, SideBarShield } from 'assets/img';

import { PALETTE } from 'styles/constants';

export const SidebarContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  min-width: 320px;
  padding: 16px 0px 16px 16px;
`;

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 176px;
  height: 45px;
  background-color: ${PALETTE.black2};
  margin: 10px auto 22px;
`;

export const StyledMainPageLogo = styled(MainPageLogo)`
  width: 100%;
  height: 100%;
`;

export const BtnContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 30px;
  gap: 5px;
  z-index: 101;
`;

export const Button = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
  height: 52px;
  padding: 3px 3px 3px 16px;
  border-radius: 8px;
  font-size: 16px;
  line-height: 18.75px;
  color: ${PALETTE.white};
  background-color: ${PALETTE.grey17};
  overflow: hidden;
  cursor: pointer;

  &:hover {
    color: ${PALETTE.white};
    background-color: ${PALETTE.black3};

    &::before {
      opacity: 1;
    }
    &::after {
      background-color: ${PALETTE.black3};
    }
  }

  &::before {
    content: '';
    width: 10px;
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 1;
    background-color: ${PALETTE.red1};
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    border-radius: 0 8px 8px 0;
  }

  &::after {
    content: '';
    width: 10px;
    height: 100%;
    position: absolute;
    left: 2px;
    top: 0px;
    z-index: 2;
    background-color: ${PALETTE.grey17};
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
    border-radius: 7px 0 0 7px;
  }

  @media (max-height: 1000px) {
    height: 45px;
    font-size: 0.9rem;
  }
`;

export const UserPanelButton = styled(Button)`
  padding: 16px 16px 0px 16px;
  width: 100%;
  height: auto;

  &:hover {
    padding-left: 16px;
  }
`;

export const FootContainer = styled.div`
  width: 304px;
`;

export const UserLinkContainer = styled.div`
  position: absolute;
  height: 100%;
  min-width: 410px;
  z-index: 100;
  top: 0;
  left: 0;
`;
export const StyledSideBarList = styled(SideBarList)`
  width: 24px;
  height: 24px;
  margin-right: 8px;
`;

export const StyledSideBarCalc = styled(SideBarCalc)`
  width: 24px;
  height: 24px;
  margin-right: 8px;
`;

export const StyledSideBarReports = styled(SideBarReports)`
  width: 24px;
  height: 24px;
  margin-right: 8px;
`;

export const StyledSideBarShield = styled(SideBarShield)`
  width: 24px;
  height: 24px;
  margin-right: 8px;
`;
