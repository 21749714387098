import React from 'react';

import { Wrapper, Img } from './style';

export const FileLink = ({
  className = '',
  title = '',
  href = '',
  img = null,
  border = '',
  color = '',
  disabled = false,
  onClick = () => {},
}) => {
  return (
    <Wrapper
      className={className}
      href={href}
      color={color}
      border={border}
      disabled={disabled}
      onClick={onClick}
    >
      {img && <Img>{img}</Img>}
      {title}
    </Wrapper>
  );
};

FileLink.displayName = 'FileLink';

export default FileLink;
