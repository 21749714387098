import React from 'react';
import moment from 'moment';

import * as S from './style';

export const Notification = ({ data, onClick }) => {
  const clickHandler = () => {
    onClick(data.applicationId);
  };
  const uniqueBanks = data.bankIds.filter(
    (bankId, index, bankIds) => index === bankIds.lastIndexOf(bankId)
  );
  return (
    <S.Wrapper isRead={data.isRead} onClick={clickHandler}>
      <S.Description>
        {moment(data.date).format('DD.MM в HH:mm')}
        &nbsp;&nbsp;•&nbsp;&nbsp;
        {`ID ${data.applicationId}`}
      </S.Description>

      <S.Title isRead={data.isRead}>{data.customer}</S.Title>

      <S.BankList>
        {uniqueBanks.map((bankId) => (
          <S.BankItem key={bankId} type="pps" id={bankId} />
        ))}
      </S.BankList>

      {data.isRead ? <S.IconReaded /> : <S.IconUnreaded />}
    </S.Wrapper>
  );
};
