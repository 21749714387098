import { put, takeLatest } from 'redux-saga/effects';
import * as actions from './constants';
import * as provider from 'data-providers/assistanceCalculationProvider';

function* requestConfirmCode({ payload }) {
  try {
    yield put({
      type: actions.SET_INPUT_WARNING,
      payload: null,
    });
    yield provider.requestSigning(payload.body);
  } catch (err) {
    console.log(err);
  }
}

function* sendConfirmCode({ payload }) {
  try {
    yield put({
      type: actions.SET_CONFIRM_SMS_CODE_FETCH,
      payload: true,
    });
    yield put({
      type: actions.SET_INPUT_WARNING,
      payload: null,
    });

    const { result } = yield provider.confirmSigning(payload.body);
    if (result?.status !== 'success') {
      yield put({
        type: actions.SET_INPUT_WARNING,
        payload: 'Введен неверный код подтверждения',
      });
    }

    if (payload.callback) {
      yield payload.callback();
    }
  } catch (err) {
    console.log(err);
    yield put({
      type: actions.SET_INPUT_WARNING,
      payload: 'Введен неверный код подтверждения',
    });
  } finally {
    yield put({
      type: actions.SET_CONFIRM_SMS_CODE_FETCH,
      payload: false,
    });
  }
}

export function* watchRequestConfirmCode() {
  yield takeLatest(actions.REQUEST_CONFIRM_CODE, requestConfirmCode);
}

export function* watchSendConfirmCode() {
  yield takeLatest(actions.SEND_CONFIRM_CODE, sendConfirmCode);
}
