import styled from 'styled-components';

import { PALETTE } from 'styles/constants';

export const LampContainer = styled.div`
  position: relative;
  display: inline-block;
  overflow: hidden;
  top: ${(p) => (p.centerElement ? '8px' : '0px')};
`;

export const Lamp = styled.div`
  width: 22px;
  height: 22px;
  display: flex;
  border-radius: 50%;
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;
  line-height: 1px;
  align-items: center;
  justify-content: center;
  position: relative;
  background: ${(p) => p.color || 'inherit'};
  color: ${(p) => p.valueColor};
  ${(p) => (p.color === PALETTE.white ? `border: 1px solid ${PALETTE.grey2};` : '')}
  line-height: 1;

  & svg * {
    fill: ${(p) => (p.color === PALETTE.white ? PALETTE.grey2 : p.valueColor)} !important;
  }
`;

export const State = styled.div`
  height: 22px;
  padding: 0 9px;
  display: inline-flex;
  border-radius: 9px;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  position: relative;
  background: ${(p) => p.color};
  color: ${(p) => p.valueColor};
  ${(p) => (p.color === PALETTE.white ? `border: 1px solid ${PALETTE.grey2};` : '')}
`;

export const SubLabel = styled.div`
  color: ${PALETTE.grey5};
  font-size: 10px;
  margin-top: 3px;
  z-index: 2;
`;

export const StateContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LampTooltip = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-size: 14px;
  border-radius: 8px;
  padding: 16px 20px;
  position: fixed;
  text-align: center;
  width: 100px;
  margin-bottom: 10px;
  position: absolute;
  top: -27px;
  right: -34px;
  background: ${(p) => p.color};
  color: ${(p) => p.valueColor};
  box-shadow: 0px 1px 3px 0px ${PALETTE.grey2};

  &:after {
    content: '';
    display: block;
    border-style: solid;
    position: absolute;
    margin: 0;
    left: 50%;
    bottom: -14px;
    border-width: 7px;
    border-right-color: transparent;
    border-top-color: ${(p) => p.color};
    border-left-color: transparent;
    border-bottom-color: transparent;
  }
`;
