import { INSURER, DRIVER, DRIVER_KEY_MARK } from '../constants';

const getFieldsOfErrorObject = (errorObject = null, disabledErrors = []) => {
  if (!errorObject) {
    return [];
  }

  return Object.keys(errorObject)
    .filter((key) => !!errorObject[key])
    .filter((key) => !disabledErrors.includes(errorObject[key]));
};

const extractNameFromBlock = (fields = [], block = INSURER, prefix = '') => {
  const innerBlock = block.fields.find((element) =>
    element.fields.some((field) => fields.includes(field))
  );

  return innerBlock?.name ? `${prefix}${innerBlock.name}` : null;
};

export const getDriverBlockName = (id, blockName) => `${DRIVER_KEY_MARK}${id}${blockName}`;

export const getBlockNameByErrors = (
  errorObject = null,
  orderedDriversIds = [],
  disabledErrors = ['Обязательное поле']
) => {
  if (!errorObject) {
    return null;
  }

  const fields = getFieldsOfErrorObject(errorObject, disabledErrors);
  const insurerBlockName = extractNameFromBlock(fields);

  if (insurerBlockName) {
    return insurerBlockName;
  }

  const driversIds = fields
    .filter((key) => key.includes(DRIVER_KEY_MARK))
    .filter((key) => !!getFieldsOfErrorObject(errorObject[key], disabledErrors).length)
    .map((key) => +key.replace(DRIVER_KEY_MARK, ''));

  const driverKey = driversIds.length
    ? orderedDriversIds.find((orderedId) => driversIds.includes(orderedId))
    : null;

  if (driverKey) {
    const driverErrorObject = errorObject[`${DRIVER_KEY_MARK}${driverKey}`];
    const fields = getFieldsOfErrorObject(driverErrorObject, disabledErrors);
    const driverBlockName = extractNameFromBlock(fields, DRIVER, `${DRIVER_KEY_MARK}${driverKey}`);

    if (driverBlockName) {
      return driverBlockName;
    }
  }

  return null;
};
