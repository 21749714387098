import styled from 'styled-components';
import { PALETTE } from 'styles/constants';

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  background-color: ${PALETTE.grey9};
  border-radius: 20px;

  @media (max-width: 955px) {
    width: 70%;
  }
`;
