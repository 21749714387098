export const getDefaultValue = () => ({
  currentSecondDocumentTypeSysName: '',
  currentSecondDocumentType: { id: 0, sysName: '', value: '' },
  snils: { id: 0, number: '' },
  driverLicense: { seriesNumber: '', series: '', number: '', issueDate: '' },
  militaryID: { id: 0, series: '', number: '', seriesNumber: '', issueDate: '', issuer: '' },
  internationalPassport: {
    id: 0,
    series: '',
    number: '',
    seriesNumber: '',
    issueDate: '',
    issuer: '',
    expires: '',
  },
  taxRegistration: {
    id: 0,
    inn: '',
    series: '',
    number: '',
    seriesNumber: '',
    issueDate: '',
    issuer: '',
    startDate: '',
    whereGivenOut: '',
  },
});

export const getDocTypeField = (docType) => {
  switch (docType?.sysName) {
    case '2nd_pension':
      return 'snils.number';
    case '2nd_foreign_passport':
      return 'internationalPassport.seriesNumber';
    case '2nd_tax':
      return 'taxRegistration.inn';
    case '2nd_military':
      return 'militaryID.seriesNumber';
    default:
      return 'driverLicense.seriesNumber';
  }
};

export const getDocTypeName = (docType) => {
  switch (docType?.sysName) {
    case '2nd_pension':
      return 'snils';
    case '2nd_foreign_passport':
      return 'internationalPassport';
    case '2nd_tax':
      return 'taxRegistration';
    case '2nd_military':
      return 'militaryID';
    default:
      return 'driverLicense';
  }
};
