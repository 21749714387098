import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { AppNotifications } from '../../../../../AppNotifications';
import VinCheckContainer from './components/VinCheck';
import { СonductionSpeed } from './components/СonductionSpeed';

import { findBanner } from 'utils/findBunner';

import * as S from './style';

export const LeftInfo = () => {
  const dealerId = useSelector((store) => store.user.dealer);
  const [banner, setBanner] = useState(null);

  useEffect(() => {
    let banner = null;
    if (dealerId) {
      banner = findBanner(dealerId);
    }

    setBanner(banner);
  }, [dealerId]);

  return (
    <S.LeftContainer>
      <S.MetricaContainer>
        <VinCheckContainer title={'Проверь автомобиль за 9 секунд'} buttonText={'Проверить'} />
        <СonductionSpeed
          title={'Скорость проведения цифровой сделки'}
          time={['35', '', '13 мин']}
        />
        <СonductionSpeed title={'Минимальное время одобрения'} time={['1', '11', '3 сек']} />
      </S.MetricaContainer>
      {banner}
      <AppNotifications />
    </S.LeftContainer>
  );
};
