import React, { useRef } from 'react';

import { InputMask } from 'utils/inputMask';
import { InputMaskStyled } from '../../../../style';

const fn = () => {};

export const CostField = ({ name, value = '', onBlur = fn, onChange = fn, error, ...rest }) => {
  const ref = useRef();

  const changeHandler = (item) => {
    if (ref && ref.current) {
      ref.current.element.setSelectionRange(0, 0);
    }

    const newVal = item[name].replace(/\s/g, '');
    if (String(value) !== newVal) {
      onChange({ [name]: newVal });
    }
  };

  const blurHandler = (item) => {
    onBlur({ [name]: item[name].replace(/\s/g, '') });
  };

  return (
    <InputMaskStyled
      ref={ref}
      tabIndex="130"
      name={name}
      val={String(value)}
      error={error}
      mask={InputMask.PRICE}
      onBlur={blurHandler}
      onChange={changeHandler}
      disabled={false}
      isControlledComponent
      tabOnEnter={true}
      {...rest}
    />
  );
};
