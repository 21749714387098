import request from 'utils/request';
import HttpError from 'utils/errors/HttpError';
import throwCustomError from 'utils/throwCustomError';

import { customer } from './mockup/customer.mock';
import { carInstance } from './mockup/car-instance.mock';

export const createLoan = async (params) => {
  try {
    const url = 'api/credit/lead';

    const { status, data } = await request.post(url, params).catch(throwCustomError);

    if (status !== 200) {
      throw new HttpError(`status ${status}`);
    }

    return data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const createBlancUser = async () => {
  try {
    const url = 'api/customers/create/blank';

    const { status, data } = await request.get(url).catch(throwCustomError);

    if (status !== 200) {
      throw new HttpError(`status ${status}`);
    }

    return data;
  } catch (err) {
    console.log(err);
    //throw err;
    return { data: customer };
  }
};

export const getCustomer = async (customerId) => {
  try {
    const url = `api/customers/${customerId}`;

    const { status, data } = await request.get(url).catch(throwCustomError);

    if (status !== 200) {
      throw new HttpError(`status ${status}`);
    }

    return data;
  } catch (err) {
    console.log(err);
    //throw err;
    return { data: customer };
  }
};

export const createBlancCar = async (dealerId) => {
  try {
    const url = `api/dealer/${dealerId}/car-instance/blank`;

    const { status, data } = await request.get(url).catch(throwCustomError);

    if (status !== 200) {
      throw new HttpError(`status ${status}`);
    }

    return data;
  } catch (err) {
    console.log(err);
    //throw err;
    return { data: carInstance };
  }
};

export const createWorksheet = async (dealerId, carInstanceId, customerId) => {
  try {
    const data = { dealerId, carInstanceId, customerId };
    const res = await request.post('api/order/loan-approval', data).catch(throwCustomError);

    if (res.status !== 200) {
      throw new HttpError(`status ${res.status}`);
    }

    return res.data;
  } catch (err) {
    console.error(err.message);
    throw err;
  }
};

export const updateCustomer = async (id, data = {}) => {
  try {
    const res = await request.patch(`api/customers/${id}`, { ...data, id }).catch(throwCustomError);
    if (res.status !== 200) {
      throw new HttpError(`status ${res.status}`);
    }
  } catch (err) {
    console.error(err.message);
    throw err;
  }
};
