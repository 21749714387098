export const getYearList = (firstYear) => {
  const LAST_YEAR = new Date().getFullYear();

  const result = [];

  let id = 0;
  let currentYear = LAST_YEAR - id;

  while (currentYear >= firstYear) {
    result.push({ id: id + 1, value: currentYear });

    currentYear = LAST_YEAR - ++id;
  }

  return result;
};

export const findYearId = (yearList, year) => {
  const finded = yearList.find((option) => option.value === year);
  if (finded) {
    return finded;
  }

  return {};
};

export const getItemByValue = (list, value, returnFirst = false) => {
  const founded = list.find((item) => `${item.value}`.toUpperCase() === `${value}`.toUpperCase());

  if (returnFirst) {
    return founded || list[0];
  }

  return founded || {};
};

export const getListIdByValue = (list, value) => {
  const founded = list.find((item) => item.value === value);

  return founded ? founded.id : 0;
};

export const getListValueById = (list, id) => {
  const founded = list.find((item) => item.id === id);

  return founded ? founded.value : '';
};

export const getPriceString = (price) => {
  const isNegative = price < 0;
  const absPrice = Math.abs(price);
  const reversedPriceCharsArray = Array.from(absPrice.toString()).reverse();

  const reversedResult = reversedPriceCharsArray.reduce((acc, char, index, arr) => {
    acc.push(char);

    if (index === 0 || index === arr.length - 1) {
      return acc;
    }

    if ((index + 1) % 3 === 0) {
      acc.push(' ');
    }

    return acc;
  }, []);

  return `${isNegative ? '-' : ''}${reversedResult.reverse().join('')}`;
};

export const getOfferPrice = (price, discount, isSetDiscount) => {
  const offerPrice = Number(price) - (isSetDiscount ? Number(discount) : 0);
  return offerPrice < 0 ? 0 : offerPrice;
};
