export const contactsInitialState = {
  contacts: {
    mobilePhone: '+7 (__) __-__-__',
    email: '',
  },
};

export const contactsFromAPIToState = (customer) => ({
  contacts: { ...customer.contacts },
});

export const contactsFromStateToAPI = (person) => ({
  contacts: { ...person.contacts },
});
