import React from 'react';
import { ToastProvider, useToasts } from 'react-toast-notifications';
import { Wrapper, CloseButton } from './style';
import { CloseSvg } from 'assets/img';

const Notification = ({ appearance, children }) => {
  const { removeAllToasts } = useToasts();

  return (
    <Wrapper status={appearance}>
      <CloseButton onClick={removeAllToasts}>
        <CloseSvg />
      </CloseButton>
      {children}
    </Wrapper>
  );
};

export const Notifications = ({
  placement = 'top-center',
  autoDismiss = true,
  autoDismissTimeout = 3500,
  children,
}) => (
  <ToastProvider
    placement={placement}
    autoDismiss={autoDismiss}
    autoDismissTimeout={autoDismissTimeout}
    components={{ Toast: Notification }}
  >
    {children}
  </ToastProvider>
);
