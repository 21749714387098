import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FileUpload } from './FileUpload';
import { FileList } from './FileList';
import { FileView } from './FileView';
import { Alert } from 'components/primitives/alert';
import { Content, Wrapper } from './style';
import { loadFiles, updateClassify, deleteFile } from 'data-providers/documentProvider';

export const FileManager = ({
  className = '',
  defaultDoc,
  defaultOnly = false,
  useTypeDoc = null,
  exclude = null,
  small,
  multiple = false,
  classification,
}) => {
  const uploadRef = useRef();
  const rootDispatch = useDispatch();
  const { anketa, referenceBooks } = useSelector((state) => state);
  const { persons, currentPersonId, documents, documentTypes } = anketa;
  const { doctypes } = referenceBooks;
  const [loading, setLoading] = useState(false);
  const [docObj, setDocObj] = useState(null);
  const [deleteType, setDeleteType] = useState(null);
  const [paramsUpload, setParamsUpload] = useState(null);
  const [typeDoc, setTypeDoc] = useState(null);
  const [classify, setClassify] = useState(null);
  const appId = Number(anketa.application.id);

  const handleClissify = (obj) => {
    if (obj) {
      setClassify(obj);
      setDocObj(obj);
    }
  };

  const handleCloseView = (obj) => {
    setClassify(obj);
    setDocObj(obj);
  };

  const handleChangeType = (doc, type) => {
    if (classify) {
      const list = classify.list.filter((f) => f.id !== doc.id);
      setClassify(list.length > 0 ? { ...classify, list } : null);
    }
    setDocObj({ ...docObj, list: [] });
    const data = updateType(type, [doc.legacyId]);
    updateFiles(data);
    updateClassify(Number(appId), doc.id, doc.entryId, type);
  };

  const handleDeleteFile = (id, entryId, type, legacyId) => {
    setDocObj({ ...docObj, list: [] });
    setDeleteType(null);
    updateFiles(deteleType(type, [legacyId]));
    deleteFile(Number(appId), id, entryId, type);
  };

  const handleDeleteType = (list, type) => {
    if (list) {
      setDocObj(null);
      setDeleteType(null);
      updateFiles(
        deteleType(
          type,
          list.map((doc) => doc.legacyId)
        )
      );
      Promise.all(
        [...new Array(list.length)].map(async (num, i) => {
          await deleteFile(Number(appId), list[i].id, list[i].entryId, type);
        })
      );
    }
  };

  const agreeDelete = () => {
    if (deleteType && deleteType.type === 'file') {
      handleDeleteFile(
        deleteType.list[0].id,
        deleteType.list[0].entryId,
        deleteType.docType,
        deleteType.list[0].legacyId
      );
    }
    if (deleteType && deleteType.type === 'type') {
      handleDeleteType(deleteType.list, deleteType.docType);
    }
  };

  const deteleType = (type, ids) =>
    documents.reduce((arr, doc) => {
      if (ids.some((id) => id === doc.legacyId)) {
        return arr;
      }
      return [...arr, doc];
    }, []);

  const updateType = (type, ids) =>
    documents.map((doc) => {
      if (ids.some((id) => id === doc.legacyId)) {
        return { ...doc, type: doctypes.find((d) => d.sysName === type) };
      }
      return doc;
    });

  const updateFiles = (data, callback = () => {}) => {
    if (data) {
      rootDispatch({
        type: 'UPLOAD_COMMON',
        payload: {
          documents: data,
        },
      });
    } else {
      setLoading(true);
      loadFiles(appId).then((data) => {
        rootDispatch({
          type: 'UPLOAD_COMMON',
          payload: {
            documents: data,
          },
        });
        setLoading(false);
        callback();
      });
    }
  };

  const setDocType = (type = null) => {
    const obj = {
      entryName: 'customer',
      entryId: currentPersonId,
    };

    if (defaultOnly || type) {
      obj.classification = Object.keys(defaultDoc).reduce((str, key) => {
        if (!str && !documents.some((doc) => doc.type && doc.type.sysName === key)) {
          return key;
        }
        return str;
      }, null);
    }

    setParamsUpload(obj);
  };

  const handleSelect = (obj) => {
    if (obj.list === null) {
      setDocType(obj.type.sysName);
      uploadRef.current.openSelectDoc();
    } else {
      setDocObj(obj);
    }
  };

  useEffect(() => {
    setDocType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (docObj) {
      if (classify) {
        setDocObj({ ...docObj, list: classify.list });
      } else {
        const list = documents.filter(
          (doc) => doc.type && docObj.type && doc.type.sysName === docObj.type.sysName
        );
        setDocObj(list.length > 0 ? { ...docObj, list } : null);
      }
    }
    setDocType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documents]);

  useEffect(() => {
    if (referenceBooks && documentTypes && doctypes) {
      setTypeDoc(
        useTypeDoc ? documentTypes.filter((f) => useTypeDoc.some((s) => s === f.sysName)) : doctypes
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referenceBooks]);

  return (
    <Wrapper className={className}>
      <Content>
        <FileUpload
          ref={uploadRef}
          loading={loading}
          applicationId={appId}
          params={paramsUpload}
          person={persons[currentPersonId]}
          persons={persons}
          onLoadFiles={updateFiles}
          small={small}
          multiple={multiple}
          classification={classification}
        />
        <FileList
          onSelect={handleSelect}
          defaultDoc={defaultDoc}
          documents={
            (exclude &&
              documents &&
              documents.filter(
                (doc) => doc.type && !exclude.some((ex) => ex === doc.type.sysName)
              )) ||
            documents
          }
          defaultOnly={defaultOnly}
          docTypes={typeDoc}
          onDelete={setDeleteType}
          onClissify={handleClissify}
        />
        {docObj && (
          <FileView
            applicationId={appId}
            data={docObj}
            classify={classify}
            onClose={handleCloseView}
            onChange={handleChangeType}
            docTypes={typeDoc}
            onDelete={setDeleteType}
          />
        )}
        {deleteType && (
          <Alert
            title="Вы действительно хотите удалить документ?"
            desc="Документ удаляется безвозвратно"
            agreeText="Удалить"
            cancelText="Отмена"
            onAgree={agreeDelete}
            onCancel={() => setDeleteType(null)}
          />
        )}
      </Content>
    </Wrapper>
  );
};
