import styled from 'styled-components';

export const ButtonContainer = styled.button`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #deeefa;
  background: #deeefa;
  color: #000;
  border-radius: 5px;
  padding: 20px;
  opacity: 1;
  cursor: pointer;
  margin-top: 15px;
  margin-bottom: 50px;

  &:focus,
  &:hover {
    background: #8bd2ff;
  }
`;

export const IconWrapper = styled.span`
  display: block;
  width: 15px;
  height: 15px;
`;

export const Title = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #000000;
  margin-left: 15px;
`;

export const DropDown = styled.div`
  position: absolute;
  top: 60px;
  left: 0;
  min-width: 100px;
  background: #ffffff;
  border: 1px solid #a8a8a8;
  z-index: 1000;
  & > div {
    border-bottom: 1px solid #b5b5b5;
  }
  & > div:last-child {
    border-bottom: 0;
  }
`;

export const DropDownItem = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  color: #000000;
  background: #ffffff;
  curson: pointer;
  & > span {
    margin-left: 10px;
    white-space: nowrap;
  }
  &:hover {
    background: #8bd2ff;
  }
`;
