import * as yup from 'yup';
import moment from 'moment';
import { onlyNumber } from 'utils/stringUtils';
import { checkSnils } from 'ru-validation-codes';
import {
  requiredTest,
  innTest,
  checkOkato,
  checkPassportNumber,
  getCareerStart,
} from 'utils/validationUtils/validators';
import {
  MESS_REQUIRED_FIELD,
  MESS_PHONE,
  MESS_DATE,
  MESS_SERNUMBER,
  MESS_SERNUMBER_MVD,
  MESS_PASSPORT_CODE,
  MESS_BEFORE_14,
  MESS_INDEX,
  MESS_USE_CYRILLIC,
  MESS_NOT_ENOUTH,
  MESS_NEED_TO_CHANGE_PASSPORT,
  MESS_INN_NOT_VALID,
  MESS_SURNAME,
  MESS_NAME,
  MESS_SURNAME_CONTACT,
  MESS_NAME_CONTACT,
  ORG_SAME_PHONE_NUMBER,
  MESS_MAIN_PROFIT_MIN,
} from 'utils/validationUtils/messages';

export const fullNameScheme = yup
  .string()
  .matches(/[^ a-zA-Z]/g, MESS_USE_CYRILLIC)
  .test('required_field', MESS_NAME, function () {
    const { firstName } = this.parent;
    return requiredTest(firstName);
  })
  .test('required_field', MESS_SURNAME, function () {
    const { lastName } = this.parent;
    return requiredTest(lastName);
  })
  .test('required_field', MESS_REQUIRED_FIELD, requiredTest);

export const birthDateScheme = yup
  .mixed()
  .test('incorrect_date', 'Меньше 18 лет', (value) => {
    const cdt = new Date(moment(value, 'DD.MM.YYYY'));
    const ndt = new Date();
    cdt.setFullYear(cdt.getFullYear() + 18);
    return ndt >= cdt;
  })
  .test('incorrect_date', MESS_DATE, (value) => value.length === 10)
  .test('required_field', MESS_REQUIRED_FIELD, (value) => value.length !== 2);

export const mobilePhoneScheme = yup
  .mixed()
  .test('incorrect_date', MESS_PHONE, (value) => value && value.indexOf('_') === -1)
  .test('required_field', MESS_REQUIRED_FIELD, requiredTest);

export const birthPlaceScheme = yup
  .string()
  .matches(/[^ a-zA-Z]/g, MESS_USE_CYRILLIC)
  .test('required_field', MESS_REQUIRED_FIELD, requiredTest);

export const passportSeriaNumberScheme = yup
  .string()
  .required(MESS_REQUIRED_FIELD)
  .matches(/\d{10}/, MESS_SERNUMBER)
  .test('incorrect_serial_number', async function () {
    const { serianumber, issueDate } = this.parent;
    const messNumberError = this.createError({
      path: 'passport.serianumber',
      message: MESS_SERNUMBER,
    });

    // 1234567890 - 12 (okato) 34 (year) 567890 (number)
    const seriesOkato = serianumber.substring(0, 2);
    if (!checkOkato(seriesOkato)) return messNumberError;

    const seriesYear = serianumber.substring(2, 4);
    const parsedYear = parseInt(seriesYear);
    if (isNaN(parsedYear)) return messNumberError;
    const cy = new Date().getFullYear() + 3 - 2000;
    if (parsedYear < 97 && parsedYear > cy) return messNumberError;

    const issueDateMoment = new Date(moment(issueDate, 'DD.MM.YYYY'));
    if (!issueDateMoment) return messNumberError;
    const issueYear = issueDateMoment.getFullYear();
    const fullYear = parseInt(moment(parsedYear, 'YY').format('YYYY'));
    if (fullYear < issueYear - 9 || fullYear > issueYear + 4) return messNumberError;

    const seriesNum = serianumber.substring(4, 10);
    const parsedNum = parseInt(seriesNum);
    if (isNaN(parsedNum)) return messNumberError;
    if (parsedNum < 101 || parsedNum > 999999) return messNumberError;

    if (await checkPassportNumber(serianumber)) return true;
    return this.createError({
      path: 'passport.serianumber',
      message: MESS_SERNUMBER_MVD,
    });
  });

export const passportIssueDateScheme = yup
  .mixed()
  .test('incorrect_date', MESS_BEFORE_14, function () {
    const persona = this.from?.[this.from.length - 1]?.value;
    const { issueDate } = this.parent;
    const { birthDate } = persona.personalInfo;
    const cdt = new Date(moment(birthDate, 'DD.MM.YYYY'));
    const ndt = new Date(moment(issueDate, 'DD.MM.YYYY'));
    cdt.setFullYear(cdt.getFullYear() + 14);
    return ndt >= cdt;
  })
  .test('incorrect_date', MESS_NEED_TO_CHANGE_PASSPORT, function () {
    const persona = this.from?.[this.from.length - 1]?.value;
    const { issueDate } = this.parent;
    const { birthDate } = persona.personalInfo;

    const now = moment().subtract(90, 'days');
    const bd = moment(birthDate, 'DD.MM.YYYY');
    const id = moment(issueDate, 'DD.MM.YYYY');

    const age = now.diff(bd, 'years', true);
    const passportAge = id.diff(bd, 'years', true);

    if (age >= 20 && passportAge < 20) return false;
    if (age >= 45 && passportAge < 45) return false;
    return true;
  })
  .test('incorrect_date', MESS_DATE, (value) => value.length === 10)
  .test('required_field', MESS_REQUIRED_FIELD, (value) => value.length !== 2);

export const passportIssuerCodeScheme = yup
  .string()
  .matches(/\d{3}-\d{3}/, MESS_PASSPORT_CODE)
  .test('required_field', MESS_PASSPORT_CODE, (value) => value.length === 7)
  .test('required_field', MESS_REQUIRED_FIELD, requiredTest);

export const passportIssuerScheme = yup
  .string()
  .matches(/[^ a-zA-Z]/g, MESS_USE_CYRILLIC)
  .test('required_field', MESS_REQUIRED_FIELD, requiredTest)
  .test('required_field', 'Не хватает кода подразделения', function () {
    const { issuerCode } = this.parent;
    if (issuerCode && issuerCode.length < 6) {
      return false;
    }
    return true;
  });

export const schema = yup.lazy((persona) =>
  yup.object().shape({
    documentsPages: yup.object({
      second: yup.object({
        pages: yup.mixed().test('required_field', MESS_REQUIRED_FIELD, (value) => value > 0),
      }),
      passport: yup.object({
        pages: yup.mixed().test('required_field', MESS_REQUIRED_FIELD, (value) => {
          return value !== 0;
        }),
      }),
    }),
    personalInfo: yup.object({
      fullName: fullNameScheme,
      isFioChanged: yup.boolean(),
      prevFullName: yup.mixed().when('isFioChanged', {
        is: true,
        then: yup
          .string()
          .matches(/[^ a-zA-Z]/g, MESS_USE_CYRILLIC)
          .test('required_field', MESS_NAME, function () {
            const { firstName } = this.parent;
            return requiredTest(firstName);
          })
          .test('required_field', MESS_SURNAME, function () {
            const { lastName } = this.parent;
            return requiredTest(lastName);
          })
          .test('required_field', MESS_REQUIRED_FIELD, requiredTest),
      }),
      familyState: yup.object({
        sysName: yup.mixed().test('required_field', MESS_REQUIRED_FIELD, requiredTest),
      }),
      birthDate: birthDateScheme,
      childrenCount: yup.mixed().test('required_field', MESS_REQUIRED_FIELD, requiredTest),
      dependentCount: yup
        .mixed()
        .test('required_field', MESS_REQUIRED_FIELD, requiredTest)
        .test('required_field', 'Кол-во иждивенцев не может быть меньше кол-ва детей', function () {
          const { childrenCount, dependentCount } = this.parent;
          if (dependentCount < childrenCount) {
            return false;
          }
          return true;
        }),
      yearsMarried: yup.mixed().when('familyState', {
        is: () => 'married' === persona.personalInfo.familyState?.sysName,
        then: yup
          .mixed()
          .test('required_field', MESS_REQUIRED_FIELD, requiredTest)
          .test('required_field', 'Кол-во лет в браке больше допустимого', function () {
            const { birthDate, yearsMarried } = this.parent;
            const startMaried = moment(birthDate, 'DD.MM.YYYY').add(16, 'years');
            if (moment().diff(startMaried, 'years') < yearsMarried) {
              return false;
            }
            return true;
          }),
      }),
    }),
    contacts: yup.object({
      mobilePhone: mobilePhoneScheme,
    }),
    passport: yup.object({
      serianumber: passportSeriaNumberScheme,
      issueDate: passportIssueDateScheme,
      issuerCode: passportIssuerCodeScheme,
      issuer: passportIssuerScheme,
      birthPlace: birthPlaceScheme,
      isPassportChanged: yup.boolean(),
      citizenship: yup.boolean().test('required_field', '', (value) => !!value),
    }),
    addressReg: yup.object({
      index: yupAddressIndex,
      fiasValue: yupAddressFiasValue,
      regDate: yup
        .mixed()
        .test('incorrect_date', 'Не ранее даты рождения', function () {
          const { regDate } = this.parent;
          const { birthDate } = persona.personalInfo;
          if (!birthDate) return true;
          const cdt = new Date(moment(birthDate, 'DD.MM.YYYY'));
          const ndt = new Date(moment(regDate, 'DD.MM.YYYY'));
          return ndt >= cdt;
        })
        .test('incorrect_date', MESS_DATE, (value) => value.length === 10)
        .test('required_field', MESS_REQUIRED_FIELD, (value) => value.length !== 2),
      livingAddressEquals: yup.boolean(),
    }),
    addressLive: yup.mixed().when('livingAddressEquals', {
      is: () => !persona.addressReg.livingAddressEquals,
      then: yup.object({
        index: yupAddressIndex,
        fiasValue: yupAddressFiasValue,
      }),
    }),
    livingAddressProperty: yup.object({
      realtyState: yup.object({
        sysName: yup.mixed().test('required_field', MESS_REQUIRED_FIELD, requiredTest),
      }),
    }),
    secondDocument: yup.object({
      currentSecondDocumentType: yup.object({
        sysName: yup.mixed().test('required_field', MESS_REQUIRED_FIELD, requiredTest),
      }),
      driverLicense: yup.mixed().when('currentSecondDocumentType', {
        is: () => '2nd_driver' === persona.secondDocument.currentSecondDocumentType.sysName,
        then: yup.object({
          seriesNumber: yup
            .mixed()
            .test('required_field', MESS_REQUIRED_FIELD, requiredTest)
            .test('required_field', MESS_SERNUMBER, function () {
              const { seriesNumber } = this.parent;
              if (seriesNumber.length < 10) {
                return false;
              }
              return true;
            }),
          issueDate: yup
            .mixed()
            .test('required_field', 'Не может быть выдан раньше 16 лет', function () {
              const { issueDate } = this.parent;
              const { birthDate } = persona.personalInfo;
              if (!birthDate) return true;
              const cdt = new Date(moment(birthDate, 'DD.MM.YYYY'));
              const ndt = new Date(moment(issueDate, 'DD.MM.YYYY'));
              cdt.setFullYear(cdt.getFullYear() + 16);
              return ndt >= cdt;
            })
            .test('incorrect_date', 'Срок дейстия истек', (value) => {
              const currdt = new Date(moment(value, 'DD.MM.YYYY'));
              const sdt = new Date(moment('01.01.2012', 'DD.MM.YYYY'));
              const edt = new Date(moment('31.12.2015', 'DD.MM.YYYY'));
              const nowdt = new Date(moment());
              nowdt.setFullYear(nowdt.getFullYear() - 10);

              return (sdt <= currdt && currdt <= edt) || nowdt <= currdt;
            })
            .test('incorrect_date', MESS_DATE, (value) => value.length === 10)
            .test('required_field', MESS_REQUIRED_FIELD, (value) => value.length !== 2),
        }),
      }),
      internationalPassport: yup.mixed().when('currentSecondDocumentType', {
        is: () =>
          '2nd_foreign_passport' === persona.secondDocument.currentSecondDocumentType.sysName,
        then: yup.object({
          seriesNumber: yup
            .mixed()
            .test('required_field', MESS_REQUIRED_FIELD, requiredTest)
            .test('required_field', MESS_SERNUMBER, function () {
              const { seriesNumber } = this.parent;
              if (seriesNumber.length < 9) {
                return false;
              }
              return true;
            }),
          issueDate: yup
            .mixed()
            .test('required_field', 'Не может быть раньше 12 лет', function () {
              const { issueDate } = this.parent;
              const { birthDate } = persona.personalInfo;
              if (!birthDate) return true;
              const cdt = new Date(moment(birthDate, 'DD.MM.YYYY'));
              const ndt = new Date(moment(issueDate, 'DD.MM.YYYY'));
              return ndt >= cdt;
            })
            .test('incorrect_date', MESS_DATE, (value) => value.length === 10)
            .test('required_field', MESS_REQUIRED_FIELD, (value) => value.length !== 2),
        }),
      }),
      militaryID: yup.mixed().when('currentSecondDocumentType', {
        is: () => '2nd_military' === persona.secondDocument.currentSecondDocumentType.sysName,
        then: yup.object({
          seriesNumber: yup
            .mixed()
            .test('required_field', MESS_REQUIRED_FIELD, requiredTest)
            .test('required_field', MESS_SERNUMBER, function () {
              const { seriesNumber } = this.parent;
              if (seriesNumber.length < 9) {
                return false;
              }
              return true;
            }),
        }),
      }),
      taxRegistration: yup.mixed().when('currentSecondDocumentType', {
        is: () => '2nd_tax' === persona.secondDocument.currentSecondDocumentType.sysName,
        then: yup.object({
          inn: yup
            .mixed()
            .test('required_field', MESS_REQUIRED_FIELD, requiredTest)
            .test('required_field', MESS_NOT_ENOUTH, function () {
              const { inn } = this.parent;
              if (inn.length < 12) {
                return false;
              }
              return true;
            })
            .test('required_field', MESS_INN_NOT_VALID, function () {
              return innTest(this.parent?.inn);
            }),
        }),
      }),
      snils: yup.mixed().when('currentSecondDocumentType', {
        is: () => '2nd_pension' === persona.secondDocument.currentSecondDocumentType.sysName,
        then: yup.object({
          number: yup
            .mixed()
            .test('required_field', MESS_REQUIRED_FIELD, requiredTest)
            .test('required_field', MESS_NOT_ENOUTH, function () {
              const { number } = this.parent;
              if (number.length < 14) {
                return false;
              }
              return true;
            })
            .test('required_field', 'Проверьте правильность номера СНИЛС', function () {
              const { number } = this.parent;
              return checkSnils(number);
            }),
        }),
      }),
    }),
    spouse: yup.mixed().when('familyState', {
      is: () => 'married' === persona.personalInfo.familyState?.sysName,
      then: yup.object({
        fullName: yupTrusteeFullname,
        birthDate: yupSpouseBirthDate,
        phone: yup
          .mixed()
          .test('incorrect_date', MESS_PHONE, (value) => value && value.indexOf('_') === -1)
          .test('required_field', MESS_REQUIRED_FIELD, requiredTest),
      }),
    }),
    trustees: yup.mixed().when('persona.trustees', {
      is: () =>
        'married' !== persona.personalInfo.familyState?.sysName &&
        Object.keys(persona.trustees).length > 0,
      then: yup.array().of(
        yup.object({
          fullName: yupTrusteeFullname,
          phone: yup
            .mixed()
            .test('incorrect_date', MESS_PHONE, (value) => onlyNumber(value).length === 11)
            .test('incorrect_date', MESS_PHONE, (value) => value && value.indexOf('_') === -1)
            .test('required_field', MESS_REQUIRED_FIELD, requiredTest),
          relationType: yup.object({
            sysName: yup.mixed().test('required_field', MESS_REQUIRED_FIELD, requiredTest),
          }),
        })
      ),
    }),
    work: yup.object({
      educationType: yup.object({
        sysName: yup.mixed().test('required_field', MESS_REQUIRED_FIELD, requiredTest),
      }),
      employmentType: yup.object({
        sysName: yup.mixed().test('required_field', MESS_REQUIRED_FIELD, requiredTest),
      }),
      organization: yup
        .mixed()
        .when('employmentType', {
          is: () =>
            persona.work.employmentType.id === 0 ||
            ![
              'unemployedMilitaryRetired',
              'unemployedRetiree',
              'not_working',
              'employment-type-another',
            ].includes(persona.work.employmentType.sysName),
          then: yup.object({
            name: yup
              .string()
              .matches(/[^ a-zA-Z]/g, MESS_USE_CYRILLIC)
              .test('required_field', MESS_REQUIRED_FIELD, requiredTest)
              .test(
                'name_error',
                'Выберите название организации из выпадающего списка',
                function () {
                  return this.parent?.isOrgFromDadata === 1;
                }
              )
              .test(
                'name_error',
                'Вы не можете выбрать эту организацию, она ликвидирована',
                function () {
                  return this.parent?.nameError !== 'liquidated';
                }
              ),
            typeType: yup.object({
              sysName: yup.mixed().test('required_field', MESS_REQUIRED_FIELD, requiredTest),
            }),
            formType: yup.object({
              sysName: yup.mixed().test('required_field', MESS_REQUIRED_FIELD, requiredTest),
            }),
            inn: yup
              .mixed()
              .test('required_field', MESS_REQUIRED_FIELD, requiredTest)
              .test('required_field', MESS_INN_NOT_VALID, function () {
                return innTest(
                  this.parent?.inn,
                  persona.work.employmentType.sysName === 'individual-entrepreneur-3' ? 12 : null
                );
              }),
            legalAddress: yup.object({
              index: yupAddressIndex,
              fiasValue: yupAddressFiasValue,
            }),
            actualAddressEquals: yup.boolean(),
            actualAddress: yup.mixed().when(['actualAddressEquals'], {
              is: false,
              then: yup.object({
                index: yupAddressIndex,
                fiasValue: yupAddressFiasValue,
              }),
            }),
            numYears: yup.mixed().test('required_field', MESS_REQUIRED_FIELD, requiredTest),
            employeesCountType: yup.object({
              sysName: yup.mixed().test('required_field', MESS_REQUIRED_FIELD, requiredTest),
            }),
          }),
        })
        .when('employmentType', {
          is: () => persona.work.employmentType.sysName === 'employment-type-another',
          then: yup.object({
            inn: yup
              .mixed()
              .test('required_field', MESS_REQUIRED_FIELD, requiredTest)
              .test('required_field', MESS_INN_NOT_VALID, function () {
                return innTest(this.parent?.inn, 12);
              }),
          }),
        }),
      organizationPhone: yup.mixed().when('employmentType', {
        is: () =>
          persona.work.employmentType.id === 0 ||
          ![
            'unemployedMilitaryRetired',
            'unemployedRetiree',
            'not_working',
            'employment-type-another',
          ].includes(persona.work.employmentType.sysName),
        then: yup
          .mixed()
          .test('incorrect_date', MESS_PHONE, (value) => onlyNumber(value).length === 11)
          .test('incorrect_date', MESS_PHONE, (value) => value && value.indexOf('_') === -1)
          .test('required_field', MESS_REQUIRED_FIELD, requiredTest)
          .test('required_field', ORG_SAME_PHONE_NUMBER, function () {
            if (
              !persona ||
              !persona.work ||
              !persona.work.employmentType ||
              !persona.work.employmentType.sysName ||
              persona.work.employmentType.sysName === 'individual-entrepreneur-3'
            )
              return true;

            const { organizationPhone } = this.parent;
            const { mobilePhone } = persona.contacts;
            return organizationPhone !== mobilePhone;
          }),
      }),
      positionName: yup.mixed().when('employmentType', {
        is: () =>
          persona.work.employmentType.id === 0 ||
          ![
            'unemployedMilitaryRetired',
            'unemployedRetiree',
            'not_working',
            'employment-type-another',
          ].includes(persona.work.employmentType.sysName),
        then: yup
          .string()
          .matches(/[^ a-zA-Z]/g, MESS_USE_CYRILLIC)
          .test('required_field', MESS_REQUIRED_FIELD, requiredTest),
      }),
      careerStartTime: yup.mixed().when('employmentType', {
        is: () =>
          persona.work.employmentType.id === 0 ||
          !['unemployedMilitaryRetired', 'unemployedRetiree', 'not_working'].includes(
            persona.work.employmentType.sysName
          ),
        then: yup
          .mixed()
          .test('required_field', 'Стаж не может начинаться ранее 14 лет', function () {
            const { careerStartTime } = this.parent;
            const { birthDate } = persona.personalInfo;
            if (!birthDate) return true;
            const cdt = new Date(moment(birthDate, 'DD.MM.YYYY'));
            const ndt = new Date(moment(careerStartTime, 'DD.MM.YYYY'));
            cdt.setFullYear(cdt.getFullYear() + 14);
            return ndt >= cdt;
          })
          .test('incorrect_date', MESS_DATE, (value) => value.length === 10)
          .test('required_field', MESS_REQUIRED_FIELD, requiredTest),
      }),
      experienceTime: yup.mixed().when('employmentType', {
        is: () =>
          persona.work.employmentType.id === 0 ||
          ![
            'unemployedMilitaryRetired',
            'unemployedRetiree',
            'not_working',
            'employment-type-another',
          ].includes(persona.work.employmentType.sysName),
        then: yup
          .mixed()
          .test('required_field', 'Стаж не может начинаться ранее 14 лет', function () {
            const { careerStartTime, experienceTime } = this.parent;
            const { birthDate } = persona.personalInfo;
            if (!birthDate) return true;
            if (!careerStartTime && experienceTime.sysName === 'less_then_1_year') {
              return true;
            }
            const cs = getCareerStart(careerStartTime, experienceTime, birthDate);
            const cdt = new Date(moment(birthDate, 'DD.MM.YYYY'));
            const ndt = new Date(moment(cs, 'DD.MM.YYYY'));
            cdt.setFullYear(cdt.getFullYear() + 14);
            return ndt >= cdt;
          })
          .test('required_field', MESS_REQUIRED_FIELD, requiredTest),
      }),
      homePhone: yup.mixed().when('employmentType', {
        is: () =>
          persona.work.employmentType.id === 0 ||
          [
            'unemployedMilitaryRetired',
            'unemployedRetiree',
            'not_working',
            'employment-type-another',
          ].includes(persona.work.employmentType.sysName),
        then: yup
          .mixed()
          .test('incorrect_date', MESS_PHONE, (value) => (value && value.length > 2) || true)
          .test('incorrect_date', MESS_PHONE, (value) => onlyNumber(value).length === 11)
          .test('incorrect_date', MESS_PHONE, (value) => value && value.indexOf('_') === -1)
          .test('required_field', MESS_REQUIRED_FIELD, requiredTest),
      }),
    }),
    profit: yup.object({
      mainprofit: yup
        .mixed()
        .test('min_value', MESS_MAIN_PROFIT_MIN, (value) => value > 999)
        .test('required_field', MESS_REQUIRED_FIELD, requiredTest),
    }),
    cars: yup.mixed().when('persona.cars', {
      is: () => Object.keys(persona.cars).length > 0,
      then: yup.array().of(
        yup.object({
          carNumber: yup
            .string()
            .required(MESS_REQUIRED_FIELD)
            .matches(/.{8,9}/, 'Неверный номер')
            .test('required_field', MESS_REQUIRED_FIELD, requiredTest),
          brand: yup.mixed().test('required_field', MESS_REQUIRED_FIELD, requiredTest),
          model: yup.mixed().test('required_field', MESS_REQUIRED_FIELD, requiredTest),
          year: yup.mixed().test('required_field', MESS_REQUIRED_FIELD, requiredTest),
          method: yup.object({
            sysName: yup.mixed().test('required_field', MESS_REQUIRED_FIELD, requiredTest),
          }),
        })
      ),
    }),
    details: yup.object({
      taxCountries: yup.mixed().when(['hasForeignRelations', 'isCurrentTaxOnly'], {
        is: (hasForeignRelations, isCurrentTaxOnly) => hasForeignRelations && !isCurrentTaxOnly,
        then: yup
          .string()
          .matches(/[^ a-zA-Z]/g, MESS_USE_CYRILLIC)
          .required(MESS_REQUIRED_FIELD),
      }),
      foreignTaxNumber: yup.mixed().when(['hasForeignRelations', 'isCurrentTaxOnly'], {
        is: (hasForeignRelations, isCurrentTaxOnly) => hasForeignRelations && !isCurrentTaxOnly,
        then: yup.mixed().test('required_field', MESS_REQUIRED_FIELD, requiredTest),
      }),
      typeOfRelationToPublicPerson: yup
        .mixed()
        .when(['hasForeignRelations', 'hasRelationToPublicPerson'], {
          is: (hasForeignRelations, hasRelationToPublicPerson) =>
            hasForeignRelations && hasRelationToPublicPerson,
          then: yup.object({
            sysName: yup.mixed().test('required_field', MESS_REQUIRED_FIELD, requiredTest),
          }),
        }),
      jobPosition: yup.mixed().when(['hasForeignRelations', 'hasRelationToPublicPerson'], {
        is: (hasForeignRelations, hasRelationToPublicPerson) =>
          hasForeignRelations && hasRelationToPublicPerson,
        then: yup.mixed().test('required_field', MESS_REQUIRED_FIELD, requiredTest),
      }),
      jobOrganizationName: yup.mixed().when(['hasForeignRelations', 'hasRelationToPublicPerson'], {
        is: (hasForeignRelations, hasRelationToPublicPerson) =>
          hasForeignRelations && hasRelationToPublicPerson,
        then: yup.mixed().test('required_field', MESS_REQUIRED_FIELD, requiredTest),
      }),
    }),
    sign: yup.object({
      'personal-data': yup.object({
        type: yup.object({
          sysName: yup.mixed().test('required_field', MESS_REQUIRED_FIELD, requiredTest),
        }),
      }),
    }),
  })
);

export const schemaAddressFiasValue = yup.lazy(() =>
  yup.object().shape({
    fiasValue: yupAddressFiasValue,
  })
);

export const schemaOrgInn = yup.lazy(() =>
  yup.object().shape({
    inn: yupInn,
  })
);

export const schemaOrgInnOther = yup.lazy(() =>
  yup.object().shape({
    inn: yupInnOther,
  })
);

export const schemaOrgPhone = yup.lazy(() =>
  yup.object().shape({
    organizationPhone: yupOrgPhone,
  })
);

export const schemaTrusteeFullname = yup.lazy(() =>
  yup.object().shape({
    fullName: yupTrusteeFullname,
  })
);

export const schemaSpouseBirthDate = yup.lazy(() =>
  yup.object().shape({
    birthDate: yupSpouseBirthDate,
  })
);

const yupAddressFiasValue = yup
  .string()
  .test('required_field', MESS_REQUIRED_FIELD, requiredTest)
  .matches(/[^ a-zA-Z]/g, MESS_USE_CYRILLIC)
  .test('required_field', 'Не хватает индекса', function () {
    const { index } = this.parent;
    if (index.length === 0 || index.length < 6) {
      return false;
    }
    return true;
  })
  .test('required_field', 'Не хватает номера дома', function () {
    const { houseNumber } = this.parent;
    if ((houseNumber && houseNumber.length === 0) || !houseNumber) {
      return false;
    }
    return true;
  })
  .test('incorrect_field', 'Выберите адрес из списка', function () {
    const { isDadataValue = false, fiasId } = this.parent;
    return isDadataValue && fiasId;
  });

const yupAddressIndex = yup
  .string()
  .matches(/\d{6}/, 'Неверный индекс')
  .test('required_field', MESS_INDEX, (value) => value.length === 6)
  .test('required_field', MESS_REQUIRED_FIELD, requiredTest);

const yupInn = yup
  .mixed()
  .test('required_field', MESS_REQUIRED_FIELD, requiredTest)
  .test('required_field', MESS_INN_NOT_VALID, function () {
    return innTest(this.parent?.inn, null);
  });

const yupInnOther = yup
  .mixed()
  .test('required_field', MESS_REQUIRED_FIELD, requiredTest)
  .test('required_field', MESS_INN_NOT_VALID, function () {
    return innTest(this.parent?.inn, 12);
  });

const yupOrgPhone = yup
  .mixed()
  .test('incorrect_date', MESS_PHONE, (value) => onlyNumber(value).length === 11)
  .test('incorrect_date', MESS_PHONE, (value) => value && value.indexOf('_') === -1)
  .test('required_field', MESS_REQUIRED_FIELD, requiredTest);

const yupTrusteeFullname = yup
  .string()
  .matches(/[^ a-zA-Z]/g, MESS_USE_CYRILLIC)
  .test('required_field', MESS_NAME_CONTACT, function () {
    const { firstName } = this.parent;
    return requiredTest(firstName);
  })
  .test('required_field', MESS_SURNAME_CONTACT, function () {
    const { lastName } = this.parent;
    return requiredTest(lastName);
  })
  .test('required_field', MESS_REQUIRED_FIELD, requiredTest);

const yupSpouseBirthDate = yup
  .mixed()
  .test('incorrect_date', 'Меньше 16 лет', (value) => {
    if (value === 0) {
      return true;
    }
    const cdt = new Date(moment(value, 'DD.MM.YYYY'));
    const ndt = new Date();
    cdt.setFullYear(cdt.getFullYear() + 16);
    return ndt >= cdt;
  })
  .test('incorrect_date', MESS_DATE, (value) => {
    return value === 0 || value.length === 10;
  });
