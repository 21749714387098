import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Toggle, Wrapper, Label } from './style';

export const Switch = ({
  className = '',
  name = '',
  active = false,
  disabled = false,
  children = null,
  options = null,
  onChange = () => {},
}) => {
  const [activeState, setActiveState] = useState(active);
  const handleClick = () => {
    setActiveState(!activeState);
    onChange(name ? { [name]: !activeState } : !activeState);
  };
  return (
    <Wrapper className={className} disabled={disabled} onClick={handleClick}>
      {options && (
        <Label active={!activeState} double={!!options[1]}>
          {options[0].value}
        </Label>
      )}
      <Toggle active={activeState} disabled={disabled} alwaysActive={options.length === 2} />
      {children ||
        (options && options[1] && (
          <Label active={activeState} double={!!options[1]}>
            {children || options[1].value}
          </Label>
        ))}
    </Wrapper>
  );
};

Switch.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.any,
  options: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
};
