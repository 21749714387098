const fn = () => {};

export const DadataGetSuggestions = ({ list, query, prepareResult = fn }) => {
  const regexp = new RegExp((query?.toLowerCase() || '').replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&'));

  const result = {
    query,
    suggestions: query ? list.filter((item) => regexp.test(item.value.toLowerCase())) : list,
  };

  prepareResult(result);

  return result;
};
