import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { openCarInstanceDetails, closeCarInstanceDetails } from 'store/carInstance';

import * as Form from '../Form/style';
import * as S from './style';

const CarForm = ({ id, isFullCalc = true, onClickFullCalc = null }) => {
  const rootDispatch = useDispatch();
  const carInstance = useSelector((state) => state?.carInstance?.carInstance || {});

  useEffect(() => {
    const enginePower = Number(carInstance?.enginePower || 0);
    const ptsNumber = String(carInstance?.pts?.number || '').trim();
    const ptsDate = String(carInstance?.pts?.issueDate || '').trim();

    if (isNaN(enginePower) || enginePower <= 0 || !ptsNumber || !ptsDate) {
      rootDispatch(openCarInstanceDetails());
    } else {
      rootDispatch(closeCarInstanceDetails());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carInstance?.id]);

  return (
    <Form.BlockWrapper>
      <Form.Header>Автомобиль</Form.Header>
      <S.CarDetail
        id={id}
        disabledPatch
        isFullCalc={isFullCalc}
        onClickFullCalc={onClickFullCalc}
      />
    </Form.BlockWrapper>
  );
};

export default CarForm;
