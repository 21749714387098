import React from 'react';

import Widget from 'components/primitives/widget';
import { Header, Content, CostContent, Title, HeadState, Cost, CostDescription } from './style';

const Product = ({
  className = '',
  title = '',
  state = {},
  cost = '',
  description = '',
  children = null,
}) => {
  return (
    <Widget className={className}>
      <Header>
        <Title>{title}</Title>
        <HeadState title={state?.value} color={state?.color} />
      </Header>

      {cost && (
        <CostContent>
          <Cost>{cost}</Cost>
          <CostDescription>{description}</CostDescription>
        </CostContent>
      )}

      {children && <Content>{children}</Content>}
    </Widget>
  );
};

Product.displayName = 'Product';

export default Product;
