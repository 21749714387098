import React from 'react';

import { SortHeadCell } from './components/SortHeadCell';
import * as S from '../../style.js';

export const Header = ({ showOpenButton }) => {
  return (
    <S.HeadRow showOpenButton={showOpenButton}>
      <S.HeadCell>Дилер</S.HeadCell>
      <S.HeadCell>КСО</S.HeadCell>
      <SortHeadCell field="created">Дата заявки</SortHeadCell>
      <S.HeadCell>Клиент</S.HeadCell>
      <S.HeadCell>Кредит</S.HeadCell>
      <S.HeadCell txtCenter>Карты / КАСКО</S.HeadCell>

      {showOpenButton && <S.HeadCell />}
    </S.HeadRow>
  );
};
