import styled from 'styled-components';

import { PALETTE } from 'styles/constants';
import * as UK from 'components/primitives/content';

export const Container = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  width: 100%;
  left: 0px;
  bottom: 0px;
  z-index: 999;
  padding: 30px 100px;
  background-color: ${PALETTE.grey9};
  border-top: 7px solid ${PALETTE.red1};
`;

export const Title = styled.h2`
  margin: 0px 50px 0px 0px;
  font-size: 24px;
  font-weight: 500;
  line-height: 30px;
`;

export const Text = styled.p`
  margin: 0px 50px 0px 0px;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
`;

export const Button = styled(UK.DarkRedButton)`
  height: 60px;
  font-size: 18px;
  margin-left: auto;
`;
