import * as actions from './constants';
import { initialState } from './initialState';
import { checkModifiedParams } from './helper';
import { ASW_NAMEPLATES, ASW_STATES } from './resources';

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.ASSISTANCE_CALC_SET_ID: {
      const { applicationId, clientId, id, workSheetId } = action.payload;

      return { ...state, applicationId, clientId, workSheetId, assistanceId: id };
    }

    case actions.ASW_CALC_SET_APPLICATION: {
      const { applicationId, applicationData } = action.payload;

      const { appModifiedAt, needUpdateLastModifiedAt } = checkModifiedParams(
        applicationData.modifiedAt,
        state.lastModifiedAt
      );

      let nameplate = needUpdateLastModifiedAt ? applicationData.state : state.nameplate;
      if (!nameplate) {
        nameplate =
          applicationData.currentWorkflowState === ASW_STATES.offer_selected
            ? ASW_NAMEPLATES.offer_selected
            : ASW_NAMEPLATES.unknown;
      }

      return {
        ...state,
        applications: {
          ...state.applications,
          [applicationId]: applicationData,
        },
        nameplate,
        lastModifiedAt: needUpdateLastModifiedAt ? appModifiedAt : state.lastModifiedAt,
      };
    }

    case actions.ASW_CALC_UPD_APPLICATION: {
      const { applicationId, newData } = action.payload;

      return {
        ...state,
        nameplate:
          newData?.state ??
          // для отображения статуса "Консультация" на agreement_requested.
          // желательно добавить к неймлэйтам статус на этот случай
          // и передавать его в экшен
          (newData.currentWorkflowState === ASW_STATES.agreement_requested
            ? state.nameplate
            : ASW_NAMEPLATES.unknown),
        applications: {
          ...state.applications,
          [applicationId]: {
            ...state.applications[applicationId],
            ...newData,
          },
        },
      };
    }

    case actions.ASW_CALC_SET_SELECTED_OFFER: {
      const { applicationId, offerId, selectedOfferData } = action.payload;
      const currentOffers = state.applications[applicationId].offers.filter(
        (el) => el.id !== offerId
      );

      return {
        ...state,
        applications: {
          ...state.applications,
          [applicationId]: {
            ...state.applications[applicationId],
            selectedOffer: selectedOfferData,
            offers: [{ ...selectedOfferData }, ...currentOffers],
          },
        },
      };
    }

    case actions.ASW_CALC_UPD_APPL_PARAMS: {
      const { applicationId, newData } = action.payload;

      return {
        ...state,
        applications: {
          ...state.applications,
          [applicationId]: {
            ...state.applications[applicationId],
            applicationParams: {
              ...state.applications[applicationId].applicationParams,
              ...newData,
            },
          },
        },
      };
    }

    case actions.ASW_CALC_SET_FETCH: {
      const { applicationId, fetchName, isFetching } = action.payload;

      return {
        ...state,
        fetchStates: {
          ...state.fetchStates,
          [applicationId]: {
            ...(state.fetchStates[applicationId] ?? {}),
            [fetchName]: isFetching,
          },
        },
      };
    }

    case actions.ASW_CALC_SET_MAIN_FETCH: {
      const { fetchName, isFetching } = action.payload;

      return {
        ...state,
        aswMainFetchStates: {
          ...state.aswMainFetchStates,
          [fetchName]: isFetching,
        },
      };
    }

    case actions.ASW_CALC_SET_NAMEPLATE: {
      return {
        ...state,
        nameplate: { ...action.payload },
      };
    }

    case actions.ASW_CALC_SET_DEALER_LEGALS: {
      const { dealerId, legalsList = [] } = action.payload;

      return {
        ...state,
        dealerLegals: {
          currentDealerId: dealerId,
          legalsList,
        },
      };
    }

    case actions.ASW_CALC_SET_POPUP_DATA: {
      const { applicationId, popupData } = action.payload;

      if (popupData === null) {
        const applicationsPopupData = { ...state.applicationsPopupData };
        delete applicationsPopupData[applicationId];
        return {
          ...state,
          applicationsPopupData,
        };
      }

      return {
        ...state,
        applicationsPopupData: {
          ...state.applicationsPopupData,
          [applicationId]: { ...popupData, applicationId },
        },
      };
    }

    case actions.ASSISTANCE_CALC_SET_ERROR: {
      const { key, value } = action.payload;
      return { ...state, errors: { ...state.errors, [key]: value } };
    }

    case actions.ASSISTANCE_CALC_DROP_ALL: {
      return { ...initialState };
    }

    case actions.ASSISTANCE_CALC_DROP_ALL_ERRORS: {
      return { ...state, errors: {} };
    }

    default:
      return state;
  }
}

export function aswCalcSetApplication({ applicationId, applicationData }) {
  return {
    type: actions.ASW_CALC_SET_APPLICATION,
    payload: { applicationId, applicationData },
  };
}

export function aswCalcUpdApplication({ applicationId, newData }) {
  return {
    type: actions.ASW_CALC_UPD_APPLICATION,
    payload: { applicationId, newData },
  };
}

export function aswCalcUpdApplParams(payload) {
  return {
    type: actions.ASW_CALC_UPD_APPL_PARAMS,
    payload: payload,
  };
}

export function aswCalcSetSelectedOffer(payload) {
  return {
    type: actions.ASW_CALC_SET_SELECTED_OFFER,
    payload,
  };
}

export function assistanceCalcAllToInitState() {
  return {
    type: actions.ASSISTANCE_CALC_DROP_ALL,
  };
}

export function assistanceCalcDropErrors() {
  return {
    type: actions.ASSISTANCE_CALC_DROP_ALL_ERRORS,
  };
}

export function aswSelectOffer(selectedOfferData, successCallback) {
  return {
    type: actions.ASW_CALC_SELECT_OFFER_REQUEST,
    payload: { selectedOfferData, successCallback },
  };
}

export function aswAgreement(agreementRequestData) {
  return {
    type: actions.ASW_CALC_AGREEMENT_REQUEST,
    payload: { agreementRequestData },
  };
}

export function aswGetPolicy(getPolicyRequestData) {
  return {
    type: actions.ASW_CALC_GET_POLICY_REQUEST,
    payload: { getPolicyRequestData },
  };
}

export function aswClientDecline(applicationId, callback) {
  return {
    type: actions.ASW_CALC_CLIENT_DECLINE_REQUEST,
    payload: { applicationId, callback },
  };
}

export function aswCancellation(applicationId, successCallback, errorCallback) {
  return {
    type: actions.ASW_CALC_CANCELLATION_REQUEST,
    payload: { applicationId, successCallback, errorCallback },
  };
}

export function aswRefreshCalculate(dataForCalculate) {
  return {
    type: actions.ASW_CALC_REFRESH_CALC_REQUEST,
    payload: { dataForCalculate },
  };
}

export function aswRefreshCalculationsByAppsIds(applicationsIdsList, callback = () => {}) {
  return {
    type: actions.ASW_CALC_REFRESH_CALCS_BY_APPS_IDS_REQUESTS,
    payload: { applicationsIdsList, callback },
  };
}

export function createNewAssistanceOrder(params) {
  return {
    type: actions.ASW_CALC_ADD_ASW_ORDER_REQUEST,
    payload: { ...params },
  };
}

export function addASWFile({ applicationId, fileId, fileType, ext }) {
  return { type: actions.ASW_CALC_ADD_FILE, payload: { applicationId, fileId, fileType, ext } };
}

export function delASWFile({ applicationId, id: fileId }) {
  return { type: actions.ASW_CALC_DEL_FILE, payload: { applicationId, fileId } };
}

export function aswCalcSetFetch(applicationId, fetchName, isFetching) {
  return { type: actions.ASW_CALC_SET_FETCH, payload: { applicationId, fetchName, isFetching } };
}

export function aswCalcSetMainFetch(fetchName, isFetching) {
  return { type: actions.ASW_CALC_SET_MAIN_FETCH, payload: { fetchName, isFetching } };
}

export function aswCalcSetNameplate(nameplate) {
  return { type: actions.ASW_CALC_SET_NAMEPLATE, payload: nameplate };
}

export function aswCalcSetDealerLegals(dealerId, legalsList) {
  // action for reducer
  return { type: actions.ASW_CALC_SET_DEALER_LEGALS, payload: { dealerId, legalsList } };
}

export function aswCalcUpdateDealerLegals(dealerId) {
  // action for sagas
  return { type: actions.ASW_CALC_UPD_DEALER_LEGALS, payload: { dealerId } };
}

export function aswCalcSetPopupData(applicationId, popupData) {
  return { type: actions.ASW_CALC_SET_POPUP_DATA, payload: { applicationId, popupData } };
}

export function assistanceCalcDeleteId() {
  return {
    type: actions.ASSISTANCE_CALC_SET_ID,
    payload: { applicationId: null },
  };
}
