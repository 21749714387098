import { select } from 'redux-saga/effects';
import * as carReferences from '../carReference/sagas';
import { PHASES } from './constants';

const Loader = {
  brand: carReferences.getBrands,
  model: carReferences.getModels,
  version: carReferences.getVersions,
};

export const getIdAndValueFromCarDictionary = (list, instanceValue) => {
  let needSaveInstance = false;
  let id = null;
  let value = null;

  if (instanceValue) {
    const find = list.find(
      (item) => `${item.value}`.trim().toUpperCase() === `${instanceValue}`.trim().toUpperCase()
    );

    if (find) {
      id = find.id;
      value = find.value;
    }
  } else {
    needSaveInstance = true;
  }

  return { id, needSaveInstance, value };
};

export function* loadCarDictionaries(phases = PHASES, payload, instance) {
  let _payload = { ...payload };
  let _needSaveInstance = false;
  const _instance = JSON.parse(JSON.stringify(instance));

  for (let key of phases) {
    yield Loader[key]({ payload: _payload });
    const list = yield select((store) => store.carReference[`${key}s`]);

    const {
      id: nextId,
      value,
      needSaveInstance,
    } = getIdAndValueFromCarDictionary(list, _instance[key]);

    _payload = { ..._payload, [`${key}Id`]: nextId };
    _needSaveInstance = needSaveInstance;
    _instance[key] = value;
  }

  return { instance: _instance, _needSaveInstance };
}
