const DEFAULT_USER_PHONE = '+7 (___) __-__-__';
const DEFAULT_NAME = '';

const getUserPhone = (data) => data?.insurerPhone || DEFAULT_USER_PHONE;

const getUserName = (fio) => {
  if (!fio) {
    return DEFAULT_NAME;
  }

  const [surname, name] = fio.split(' ');

  return `${surname || ''} ${name || ''}`;
};

export const insurerProvider = (data) => {
  const phone = getUserPhone(data);
  const name = getUserName(data?.fio);
  const isPhone = phone !== DEFAULT_USER_PHONE;
  const isActive = name !== DEFAULT_NAME;

  const insurerInfo = {
    id: data?.id,
    alt: name,
    name,
    phone,
    customerId: undefined,
    applicationId: undefined,
    isActive,
    isPhone,
  };

  return insurerInfo;
};
