import styled, { css } from 'styled-components';
import { PALETTE } from 'styles/constants';

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Bubble = styled.button`
  margin: 0;
  margin-right: 10px;
  margin-bottom: 8px;
  padding: 12px 15px;
  border: 1px solid ${PALETTE.grey2};
  border-radius: 20px;
  background-color: white;
  height: 40px;
  color: ${PALETTE.grey2};
  ${(props) =>
    props.disabled &&
    css`
      border: 1px solid ${PALETTE.grey7};
    `}
  ${(props) =>
    props.isActive &&
    css`
      background-color: ${PALETTE.blue2};
      color: ${PALETTE.black};
      border-color: ${PALETTE.blue2};
    `}
    ${(props) => {
    if (props.clickable) {
      return css`
        cursor: pointer;
        &:hover,
        &:focus {
          ${props.allowHover &&
          css`
            filter: brightness(1.1);
            background-color: ${PALETTE.blue2};
            color: ${PALETTE.black};
            border-color: ${PALETTE.blue2};
          `}
        }
      `;
    }

    return '';
  }}
`;

export const BubbleText = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
`;

export const BubbleKBM = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  margin-left: 4px;
`;
