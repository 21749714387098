import axios from 'axios';
const { REACT_APP_DADATA_TOKEN } = process.env;

export const getFio = async (string) => {
  const results = await axios.post(
    'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/fio',
    {
      count: 1,
      query: string,
    },
    {
      headers: {
        'accept': 'application/json',
        'authorization': `Token ${REACT_APP_DADATA_TOKEN}`,
        'content-type': 'application/json',
      },
    }
  );
  if (results && results.status && [200].indexOf(results.status) === -1) {
    return results;
  } else {
    return (results.data.suggestions && results.data.suggestions[0]) || null;
  }
};

export const getAdress = async (string) => {
  const results = await axios.post(
    'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address',
    {
      count: 1,
      query: string,
    },
    {
      headers: {
        'accept': 'application/json',
        'authorization': `Token ${REACT_APP_DADATA_TOKEN}`,
        'content-type': 'application/json',
      },
    }
  );
  if (results && results.status && [200].indexOf(results.status) === -1) {
    return null;
  } else {
    return (results.data.suggestions && results.data.suggestions[0]) || null;
  }
};
