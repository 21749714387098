export const Cherry = [
  2785, 2405, 2954, 611, 2392, 2711, 675, 462, 2971, 394, 393, 3156, 1750, 1635, 320, 3010, 2656,
  1827, 1634, 308, 2473, 564, 1103, 2477, 2459, 350, 2476, 3285, 2185, 2703, 3220, 1837, 2512, 2855,
  3015, 2963, 375, 388, 1747, 3284, 2181, 2466, 2182, 284, 2465, 1593, 2052, 3374, 2913, 3191, 2475,
  2897, 3226, 2874, 1626, 945, 2699, 2968, 490, 278, 2402, 3626, 3327, 2453, 3287, 269, 3699, 2881,
  1700, 670, 2282, 2716, 1953, 3063, 292, 2970, 2480, 2183, 2296, 473, 2379, 1437, 552, 3372, 1838,
  1913, 1081, 926, 2467, 3147, 2499, 1753, 1749, 1836, 2334, 2834, 2853, 3661, 378, 1759, 475, 238,
  3762, 1050, 1748, 2319, 1102, 2880, 2840, 3286, 2972, 261, 262, 2165, 2321, 1954, 2147, 2818,
  3325, 2434, 1765, 267, 3294, 2436, 2657, 1690, 366, 319, 2754, 2390, 2934, 1914, 2717, 1701, 276,
  310, 3157, 1052, 1760, 3006, 3328, 3264, 2169, 236, 232, 3549, 2150, 266, 241, 1742, 244, 245,
  265, 230, 3711, 1790, 2902, 3263, 1669, 233, 271, 1779, 2998, 3326, 1339, 1847, 1691, 1682, 2403,
  2380, 2375, 2493, 2502, 2906, 3243, 1956, 2395, 2428, 2870, 1841, 3812, 3789, 3799, 3831, 3787,
  2909, 2148, 2154, 1758, 2166, 2369, 1926, 2651, 285, 929, 2180, 1740, 231, 411, 1763, 2269, 819,
  2854, 1104, 426, 2151, 2152, 2142, 1977, 280, 264, 291, 1324, 1925, 2179, 2442, 2712, 2518, 3089,
  3091, 2784, 3240, 1920,
];

export const FocusGroupPangoCard = [2920, 1053, 2898, 2879, 3253, 2952, 1738];

export const CheryNew = new Set([
  230, 232, 233, 236, 238, 241, 244, 245, 261, 262, 265, 266, 267, 269, 271, 276, 278, 284, 292,
  308, 310, 319, 320, 350, 366, 375, 378, 388, 393, 394, 462, 473, 475, 490, 552, 564, 611, 670,
  675, 926, 945, 1050, 1052, 1053, 1081, 1102, 1103, 1339, 1437, 1593, 1626, 1634, 1635, 1669, 1682,
  1690, 1691, 1700, 1701, 1738, 1740, 1742, 1747, 1748, 1749, 1750, 1753, 1758, 1759, 1760, 1765,
  1779, 1790, 1827, 1836, 1837, 1838, 1841, 1847, 1913, 1914, 1920, 1953, 1954, 1956, 2052, 2147,
  2150, 2154, 2165, 2166, 2169, 2179, 2181, 2182, 2183, 2185, 2282, 2296, 2319, 2321, 2334, 2375,
  2379, 2380, 2390, 2392, 2395, 2402, 2403, 2405, 2428, 2434, 2436, 2442, 2459, 2465, 2466, 2467,
  2473, 2475, 2476, 2477, 2480, 2493, 2499, 2502, 2512, 2518, 2656, 2657, 2699, 2703, 2711, 2712,
  2716, 2717, 2754, 2784, 2785, 2818, 2834, 2840, 2853, 2855, 2870, 2874, 2879, 2880, 2881, 2897,
  2898, 2902, 2906, 2909, 2913, 2920, 2934, 2952, 2954, 2963, 2968, 2970, 2971, 2972, 2998, 3006,
  3010, 3015, 3063, 3089, 3091, 3147, 3156, 3157, 3191, 3214, 3220, 3226, 3240, 3243, 3253, 3263,
  3264, 3284, 3285, 3286, 3287, 3294, 3325, 3326, 3327, 3328, 3372, 3374, 3461, 3523, 3549, 3587,
  3597, 3600, 3626, 3661, 3675, 3680, 3681, 3682, 3699, 3749, 3762, 3787, 3789, 3799, 3812, 3831,
  3851, 3852, 3864, 3876, 3897, 3898, 3904, 3922, 3934, 3936, 3941, 4038,
]);

export const PangoNew = new Set([
  3695, 4173, 3774, 3793, 3821, 4107, 3590, 3685, 3540, 3541, 3561, 3537, 3868, 3900, 3510, 3488,
  4106, 3522, 3839, 4203, 3479, 3672, 3673, 3676, 3686, 4150, 4190, 4204, 3693, 3930, 3980, 3798,
  3791, 3866, 4367, 3768, 3582, 3983, 3940, 3480, 3968, 3436, 3819, 3491, 3923, 4237, 3668, 3525,
  3476, 3548, 3466, 4149, 4151, 4233, 4364, 3472, 3719, 4236, 3486, 3721, 3487, 3492, 3493, 3992,
  3508, 3977, 3771, 3765, 3812, 3813, 4243, 3454, 3555, 3891, 3893, 3678, 3857, 3953, 4007, 3870,
  3787, 3677, 3828, 4247, 3578, 3770, 3936, 3471, 3490, 3467, 3468, 3982, 3483, 4252, 3477, 3692,
  3588, 3862, 3789, 4246, 3516, 3858, 3904, 3928, 3856, 4366, 3485, 3484, 3499, 4283, 3962, 3924,
  3449, 3448, 3581, 3585, 4102, 3803, 3933, 4207, 3450, 3451, 3826, 3557, 4186, 3892, 3999, 3452,
  3688, 3544, 4199, 3814, 3795, 3528, 3580, 3589, 3670, 3498, 3794, 3517, 3954, 3955, 3956, 3957,
  4425, 3905, 3918, 3909, 3502, 3846, 3847, 3848, 3849, 3850, 3851, 3852, 4232, 3514, 4209, 3881,
  3882, 3689, 3501, 3748, 4123, 3816, 3461, 3863, 4454, 3463, 3462, 4256, 4257, 3804, 4279, 3914,
  3591, 3592, 3593, 3594, 3595, 3596, 3599, 3600, 3601, 4396, 4463, 3799, 4248, 4260, 4043, 3853,
  3822, 4245, 3691, 3873, 3831, 4078, 3511, 3674, 3966, 3455, 3747, 3453, 3603, 3690, 3547, 3925,
  3987, 3796, 3797, 3584, 3823, 4121, 3560, 3720, 3917, 3775, 4039, 4040, 4037, 3495, 3554, 3527,
  3751, 3752, 3539, 3542, 3543, 3442, 3764, 3969, 3915, 3960, 4075, 3713, 3474, 3667, 3704, 3967,
  3473, 3523, 3524, 4022, 3664, 3722, 4210, 3531, 3772, 4202, 4006, 3481, 3605, 3827, 3916, 3533,
  3536, 3665, 3534, 3586, 3606, 3981, 3535, 3478, 3447, 3975, 3526, 3929, 3444, 3861, 3976, 4400,
  4436, 4439, 3837, 3978, 3443, 3446, 3445, 3836, 3459, 3829, 3830, 3767, 3865, 3682, 3843, 4251,
  4277, 3681, 4073, 3469, 3470, 3734, 4278, 3869, 3876, 3877, 3996, 3598, 4033, 3766, 3808, 3737,
  4196, 3529, 3530, 3669, 3680, 3888, 3941, 4071, 3818, 3820, 3708, 3709, 3710, 3738, 4284, 3441,
  3546, 3666, 3464, 3521, 3838, 4021, 3934, 3935, 3749, 4074, 4122, 3575, 3576, 3577, 3579, 4438,
  3587, 3942, 3500, 4258, 4259, 3910, 3913, 3986, 3573, 3571, 3572, 3583, 4099, 4103, 4104, 3494,
  3854, 3679, 3505, 3792, 3457, 3753, 4234, 3458, 3456, 3859, 3860, 3489, 3503, 4254, 3482, 3497,
  3504, 3475, 3460, 3901, 3465, 3513, 3824, 3562, 3563, 3564, 3565, 3566, 3567, 3568, 3569, 4460,
  4461, 3801, 3515, 3855, 3974, 4205, 4455, 3717, 4087, 4462, 3570, 3806, 3872, 3597, 3894, 3815,
  4067, 4206, 3675, 3817, 4440, 3538, 3878, 3879, 3496, 3998, 3705, 4070,
]);

export const OmodaNew = new Set([
  3067, 3068, 3069, 3070, 3071, 3072, 3074, 3075, 3076, 3077, 3078, 3079, 3080, 3081, 3082, 3083,
  3084, 3087, 3088, 3092, 3093, 3095, 3096, 3097, 3098, 3099, 3100, 3101, 3102, 3103, 3104, 3105,
  3107, 3108, 3110, 3111, 3113, 3114, 3116, 3118, 3119, 3120, 3121, 3122, 3123, 3124, 3125, 3126,
  3129, 3130, 3131, 3133, 3134, 3135, 3136, 3137, 3138, 3139, 3140, 3144, 3148, 3149, 3150, 3151,
  3152, 3154, 3158, 3159, 3160, 3162, 3163, 3165, 3166, 3168, 3169, 3171, 3172, 3173, 3174, 3175,
  3177, 3180, 3181, 3182, 3184, 3185, 3186, 3187, 3192, 3194, 3195, 3215, 3217, 3218, 3223, 3247,
  3248, 3249, 3250, 3254, 3255, 3271, 3276, 3277, 3278, 3281, 3282, 3283, 3289, 3290, 3300, 3312,
  3313, 3314, 3315, 3316, 3356, 3399, 3403, 3404, 3613, 3614, 3622, 3702, 3711, 3712, 3715, 3716,
  3733, 3735, 3825, 3875, 3902, 3907, 3921, 3943, 3959, 3961, 3989, 4000, 4001, 4027, 4053, 4065,
  4066, 4083, 4085, 4089, 4096, 4097, 4098, 4100, 4111, 4118, 4136, 4139, 4147, 4174, 4175, 4178,
  4183, 4187, 4357, 4431, 4433, 4466, 4473,
]);

export const ExeedNew = new Set([
  2478, 2486, 2488, 2501, 2503, 2504, 2519, 2538, 2653, 2667, 2700, 2701, 2705, 2706, 2709, 2710,
  2713, 2715, 2752, 2772, 2774, 2779, 2783, 2806, 2811, 2822, 2823, 2829, 2843, 2851, 2866, 2867,
  2868, 2896, 2900, 2908, 2923, 2927, 2940, 2949, 2997, 3009, 3016, 3020, 3023, 3024, 3044, 3045,
  3059, 3117, 3132, 3176, 3208, 3216, 3221, 3241, 3244, 3245, 3246, 3251, 3261, 3262, 3279, 3308,
  3309, 3324, 3329, 3330, 3336, 3337, 3338, 3339, 3340, 3343, 3344, 3345, 3346, 3371, 3375, 3379,
  3381, 3391, 3393, 3402, 3410, 3411, 3412, 3416, 3417, 3419, 3422, 3423, 3425, 3550, 3662, 3663,
  3696, 3697, 3743, 3769, 3874, 3990, 4095, 4108, 4119, 4137, 4140, 4176, 4432, 4493, 4530,
]);

export const JaecooNew = new Set([
  4213, 4214, 4215, 4216, 4217, 4218, 4219, 4220, 4221, 4222, 4223, 4224, 4225, 4226, 4227, 4228,
  4229, 4230, 4231, 4239, 4261, 4262, 4263, 4264, 4265, 4266, 4267, 4268, 4269, 4271, 4272, 4273,
  4276, 4282, 4285, 4286, 4287, 4288, 4289, 4290, 4291, 4292, 4293, 4294, 4295, 4296, 4297, 4298,
  4299, 4300, 4301, 4302, 4303, 4304, 4305, 4306, 4307, 4308, 4309, 4310, 4311, 4312, 4313, 4314,
  4315, 4317, 4319, 4320, 4321, 4322, 4323, 4324, 4325, 4326, 4327, 4328, 4329, 4330, 4331, 4332,
  4333, 4334, 4335, 4336, 4337, 4338, 4339, 4340, 4341, 4342, 4343, 4344, 4345, 4349, 4350, 4351,
  4352, 4353, 4354, 4355, 4356, 4358, 4359, 4360, 4365, 4368, 4369, 4381, 4384, 4385, 4387, 4388,
  4389, 4390, 4391, 4392, 4394, 4395, 4397, 4398, 4399, 4401, 4402, 4403, 4404, 4405, 4406, 4407,
  4408, 4409, 4410, 4411, 4412, 4413, 4414, 4415, 4417, 4419, 4421, 4423, 4429, 4435, 4465, 4483,
  4485,
]);

export const EbannerGroupTwo = new Set([
  4522, 4520, 4523, 4444, 2460, 4850, 4553, 4024, 3267, 4424, 1200, 3269, 3268, 5073, 580, 4570,
  2341, 4077, 579, 3553, 578, 1408, 489, 1394, 4629, 4630, 2366, 1551, 3377, 2364, 2363, 2487, 2365,
  4581, 2720, 2719, 1252, 1067, 1068, 1253, 3291, 1255, 1259, 1260, 3348, 1264, 3302, 1262, 396,
  395, 4171, 4164, 4170, 4162, 4172, 4165, 4166, 4168, 4169, 4167, 4163, 3650, 4052, 3649, 3655,
  3653, 3654, 3658, 3656, 3651, 3652, 4045, 4859, 3659, 5011, 4564, 4562, 2437, 4565, 2485, 4814,
  2739, 4663, 4712, 2153, 3995, 3213, 2721,
]);

export const CarCheck = [
  2995, 4446, 3618, 4145, 3628, 2994, 2342, 4631, 2308, 383, 4563, 2343, 4691, 389, 4643, 429, 4991,
  4626, 2008, 2344, 4891, 5041, 4885, 2311, 3651, 4644, 4824, 4802, 2153, 4642, 3058, 1134, 1021,
  4719, 2511, 3656, 4964, 4420, 4803, 2410, 1761, 4518, 4783, 4844, 4889, 4990, 2992, 2743, 4274,
  1023, 2299, 4516, 1388, 2323, 2306, 4624, 4633, 4841, 4597, 3845, 3292, 5073, 3625, 5044, 4212,
  5062, 1017, 4156, 1014, 4372, 5014, 1002, 3973, 1141, 2978, 4079, 1358, 3945, 2345, 3228, 1357,
  3236, 4764, 3027, 2387,
];
