export const abstractAddressInitialState = {
  index: '',
  region: '',
  area: '',
  city: '',
  street: '',
  house: '',
  building: '',
  apartment: '',

  apartmentNumber: '',
  apartmentType: '',
  buildingType: '',
  buildingTypeFull: '',
  cityFiasId: '',
  cityKladrCode: '',
  cityName: '',
  cityType: '',
  cityTypeFull: '',
  created: '',
  districtKladrCode: '',
  districtName: '',
  districtType: '',
  districtTypeFull: '',
  districtWithType: '',
  fiasCode: '',
  fiasId: '',
  fiasLevel: '',
  fiasValue: '',
  houseFiasId: '',
  houseType: '',
  houseTypeFull: '',
  id: null,
  latitude: 0.0,
  longitude: 0.0,
  matchCheckbox: '',
  regionFiasId: '',
  regionKladrCode: '',
  regionName: '',
  regionType: '',
  regionTypeFull: '',
  settlementFiasId: '',
  settlementKladrCode: '',
  settlementName: '',
  settlementType: '',
  settlementTypeFull: '',
  settlementWithType: '',
  streetFiasId: '',
  streetKladrId: '',
  streetType: '',
  streetTypeFull: '',
  streetWithType: '',
  areaFiasId: '',
  areaKladrId: '',
  areaType: '',
  areaTypeFull: '',
  areaWithType: '',

  isDadataValue: false,
  isDadataIndexValue: false,
};

export const abstractAddressFromAPIToState = (address) => ({
  index: address.indexNumber,
  region: address.regionWithType,
  area: address.area,
  city: address.cityWithType,
  streetName: address.streetName,
  houseNumber: address.houseNumber,
  building: address.buildingNumber,
  apartment: address.apartmentTypeFull,

  apartmentNumber: address.apartmentNumber,
  apartmentType: address.apartmentType,
  buildingType: address.buildingType,
  buildingTypeFull: address.buildingTypeFull,
  cityFiasId: address.cityFiasId,
  cityKladrCode: address.cityKladrCode,
  cityName: address.cityName,
  cityType: address.cityType,
  cityTypeFull: address.cityTypeFull,
  created: address.created,
  districtKladrCode: address.districtKladrCode,
  districtName: address.districtName,
  districtType: address.districtType,
  districtTypeFull: address.districtTypeFull,
  districtWithType: address.districtWithType,
  fiasCode: address.fiasCode,
  fiasId: address.fiasId,
  fiasLevel: address.fiasLevel,
  fiasValue: address.fiasValue,
  houseFiasId: address.houseFiasId,
  houseType: address.houseType,
  houseTypeFull: address.houseTypeFull,
  id: address.id,
  latitude: address.latitude,
  longitude: address.longitude,
  matchCheckbox: address.matchCheckbox,
  regionFiasId: address.regionFiasId,
  regionKladrCode: address.regionKladrCode,
  regionName: address.regionName,
  regionType: address.regionType,
  regionTypeFull: address.regionTypeFull,
  settlementFiasId: address.settlementFiasId,
  settlementKladrCode: address.settlementKladrCode,
  settlementName: address.settlementName,
  settlementType: address.settlementType,
  settlementTypeFull: address.settlementTypeFull,
  settlementWithType: address.settlementWithType,
  streetFiasId: address.streetFiasId,
  streetKladrId: address.streetKladrCode,
  streetType: address.streetType,
  streetTypeFull: address.streetTypeFull,
  streetWithType: address.streetWithType,
  areaFiasId: address.areaFiasId,
  areaKladrId: address.areaKladrCode,
  areaType: address.areaType,
  areaTypeFull: address.areaTypeFull,
  areaWithType: address.areaWithType,

  isDadataValue: address.fiasId && true,
  isDadataIndexValue: address.indexNumber && true,
});

export const abstractAddressFromStateToAPI = (address) => {
  const data = {
    indexNumber: address.index || '',
    regionWithType: address.region || '',
    area: address.area || '',
    cityWithType: address.city || '',
    streetName: address.streetName || '',
    houseNumber: address.houseNumber || '',
    buildingNumber: address.building || '',
    apartmentTypeFull: address.apartment || '',

    apartmentNumber: address.apartmentNumber || '',
    apartmentType: address.apartmentType || '',
    buildingType: address.buildingType || '',
    buildingTypeFull: address.buildingTypeFull || '',
    cityFiasId: address.cityFiasId || '',
    cityKladrCode: address.cityKladrCode || '',
    cityName: address.cityName || '',
    cityType: address.cityType || '',
    cityTypeFull: address.cityTypeFull || '',
    created: address.created || '',
    districtKladrCode: address.districtKladrCode || '',
    districtName: address.districtName || '',
    districtType: address.districtType || '',
    districtTypeFull: address.districtTypeFull || '',
    districtWithType: address.districtWithType || '',
    fiasCode: address.fiasCode || '',
    fiasId: address.fiasId || '',
    fiasLevel: address.fiasLevel || '',
    fiasValue: address.fiasValue || '',
    houseFiasId: address.houseFiasId || '',
    houseType: address.houseType || '',
    houseTypeFull: address.houseTypeFull || '',
    id: address.id || null,
    latitude: address.latitude ? parseFloat(address.latitude) : 0,
    longitude: address.longitude ? parseFloat(address.latitude) : 0,
    matchCheckbox: address.matchCheckbox || false,
    regionFiasId: address.regionFiasId || '',
    regionKladrCode: address.regionKladrCode || '',
    regionName: address.regionName || '',
    regionType: address.regionType || '',
    regionTypeFull: address.regionTypeFull || '',
    settlementFiasId: address.settlementFiasId || '',
    settlementKladrCode: address.settlementKladrCode || '',
    settlementName: address.settlementName || '',
    settlementType: address.settlementType || '',
    settlementTypeFull: address.settlementTypeFull || '',
    settlementWithType: address.settlementWithType || '',
    streetFiasId: address.streetFiasId || '',
    streetKladrCode: address.streetKladrId || '',
    streetType: address.streetType || '',
    streetTypeFull: address.streetTypeFull || '',
    streetWithType: address.streetWithType || '',
    areaFiasId: address.areaFiasId || '',
    areaKladrCode: address.areaKladrId || '',
    areaType: address.areaType || '',
    areaTypeFull: address.areaTypeFull || '',
    areaWithType: address.areaWithType || '',
  };
  return JSON.parse(JSON.stringify(data).replace(/" "/g, '""'));
};

export const dadataAddressToState = (data, value) =>
  data
    ? {
        index: (data.postal_code && data.postal_code) || '',
        regionWithType: (data.region_with_type && data.region_with_type) || '',
        region: (data.region_with_type && data.region_with_type) || '',
        cityWithType: (data.city_with_type && data.city_with_type) || '',
        city: (data.city_with_type && data.city_with_type) || '',
        building: (data.block && data.block) || '',
        districtName: (data.area && data.area) || '',
        districtType: (data.area_type && data.area_type) || '',
        districtTypeFull: (data.area_type_full && data.area_type_full) || '',

        apartmentNumber: (data.flat && data.flat) || '',
        apartmentTypeFull: (data.flat_type_full && data.flat_type_full) || '',
        apartmentType: (data.flat_type && data.flat_type) || '',
        buildingType: (data.block_type && data.block_type) || '',
        buildingTypeFull: (data.block_type_full && data.block_type_full) || '',
        cityFiasId: (data.city_fias_id && data.city_fias_id) || '',
        cityKladrCode: (data.city_kladr_id && data.city_kladr_id) || '',
        cityName: (data.city && data.city) || '',
        cityType: (data.city_type && data.city_type) || '',
        cityTypeFull: (data.city_type_full && data.city_type_full) || '',
        districtKladrCode: (data.area_kladr_id && data.area_kladr_id) || '',
        districtWithType: (data.area_with_type && data.area_with_type) || '',
        fiasCode: (data.fias_code && data.fias_code) || '',
        fiasId: (data.fias_id && data.fias_id) || '',
        fiasLevel: (data.fias_level && data.fias_level) || '',
        fiasValue: value,
        houseNumber: (data.house && data.house) || (data.stead && data.stead) || '',
        houseFiasId:
          (data.house_fias_id && data.house_fias_id) ||
          (data.stead_fias_id && data.stead_fias_id) ||
          '',
        houseType:
          (data.house_type && data.house_type) || (data.stead_type && data.stead_type) || '',
        houseTypeFull:
          (data.house_type_full && data.house_type_full) ||
          (data.stead_type_full && data.stead_type_full) ||
          '',
        id: (data.geoname_id && data.geoname_id) || '',
        latitude: data.geo_lat ? parseFloat(data.geo_lat) : 0.0,
        longitude: data.geo_lon ? parseFloat(data.geo_lon) : 0.0,
        regionFiasId: (data.region_fias_id && data.region_fias_id) || '',
        regionKladrCode: (data.region_kladr_id && data.region_kladr_id) || '',
        regionName: (data.region && data.region) || '',
        regionType: (data.region_type && data.region_type) || '',
        regionTypeFull: (data.region_type_full && data.region_type_full) || '',
        settlementFiasId: (data.settlement_fias_id && data.settlement_fias_id) || '',
        settlementKladrCode: (data.settlement_kladr_id && data.settlement_kladr_id) || '',
        settlementName: (data.settlement && data.settlement) || '',
        settlementType: (data.settlement_type && data.settlement_type) || '',
        settlementTypeFull: (data.settlement_type_full && data.settlement_type_full) || '',
        settlementWithType: (data.settlement_with_type && data.settlement_with_type) || '',
        streetName: (data.street && data.street) || '',
        streetFiasId: (data.street_fias_id && data.street_fias_id) || '',
        streetKladrId: (data.street_kladr_id && data.street_kladr_id) || '',
        streetKladrCode: (data.street_kladr_id && data.street_kladr_id) || '',
        streetType: (data.street_type && data.street_type) || '',
        streetTypeFull: (data.street_type_full && data.street_type_full) || '',
        streetWithType: (data.street_with_type && data.street_with_type) || '',
        area: (data.area && data.area) || '',
        areaFiasId: (data.area_fias_id && data.area_fias_id) || '',
        areaKladrId: (data.area_kladr_id && data.area_kladr_id) || '',
        areaType: (data.area_type && data.area_type) || '',
        areaTypeFull: (data.area_type_full && data.area_type_full) || '',
        areaWithType: (data.area_with_type && data.area_with_type) || '',

        isDadataValue: true,
        isDadataIndexValue: data.postal_code && data.postal_code && true,
      }
    : abstractAddressInitialState;

export const addressToState = (value) => ({
  ...abstractAddressInitialState,
  fiasValue: value,
  isDadataValue: false,
});

export const indexToState = (value) => ({
  index: value,
  isDadataIndexValue: false,
});
