import styled from 'styled-components';
import { PALETTE } from 'styles/constants';

export const Wrapper = styled.div`
  position: relative;
  display: block;
  width: 49%;
  border: 1px solid ${PALETTE.grey1};
  border-left-width: 0;
  border-right-width: 0;

  > div:not(:last-child) {
    border-bottom: ${(p) => (!p.single ? `1px solid ${PALETTE.grey1}` : 0)};
  }
`;

export const Loader = styled.img`
  display: block;
  height: 100px;
  width: 100px;
  margin: auto;
  margin-top: 50px;
`;

export const List = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;

  > div {
    width: 50%;

    &:not(:last-child) {
      border-bottom: 1px solid ${PALETTE.grey1};
    }
  }
`;
