import styled from 'styled-components';
import { CheckBox } from 'components/primitives/check-box';

export const Switch = styled.div`
  grid-area: switch;
  display: flex;
  justify-content: center;
`;

export const CheckBoxStyled = styled(CheckBox)`
  margin: 0 12px;
`;
