import React, { useState, useCallback } from 'react';
import debounce from 'lodash/debounce';

import * as S from './style';

export const Search = ({ onSearch }) => {
  const [searchValue, setSearchValue] = useState('');

  const debouncedSearchHandler = useCallback(
    debounce((val) => {
      onSearch(val);
    }, 300),
    []
  );

  const searchHandler = (value) => {
    setSearchValue(value);
    debouncedSearchHandler(value);
  };

  return (
    <S.SearchInput
      placeholder="поиск по юр. лицу, ИНН или КПП"
      onChange={searchHandler}
      val={searchValue}
      withClearButton={false}
    />
  );
};
