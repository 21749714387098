import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import TinySlider from 'tiny-slider-react';
import { Icon, Wrapper, Container, NavLeft, NavRight } from './styles';
import { carouselOptions, carouselType } from './carouselOptions';

export const Carousel = ({
  className = '',
  count = null,
  children = null,
  activeIndex = null,
  type = null,
  settings = {},
  onClick = () => {},
  onChange = () => {},
  onIndexChanged = () => {},
}) => {
  let slider = null;
  const onGoTo = (dir) => {
    if (slider.slider) {
      slider.slider.goTo(dir);
    }
  };

  useEffect(() => {
    if (activeIndex !== null) {
      onGoTo(activeIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (activeIndex !== null) {
      onGoTo(activeIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex]);

  return (
    <Wrapper className={className}>
      {activeIndex > 0 && (
        <NavLeft onClick={() => onGoTo('prev')}>
          <Icon />
        </NavLeft>
      )}

      <Container styleByType={type}>
        <TinySlider
          settings={{ ...carouselOptions[carouselType.DEFAULT], ...settings }}
          ref={(ts) => (slider = ts)}
          onClick={onClick}
          onTransitionEnd={onChange}
          onIndexChanged={onIndexChanged}
        >
          {children}
        </TinySlider>
      </Container>
      {count && count > activeIndex + 1 && (
        <NavRight onClick={() => onGoTo('next')}>
          <Icon />
        </NavRight>
      )}
    </Wrapper>
  );
};

Carousel.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  settings: PropTypes.object,
};
