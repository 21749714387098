export const DEALS_LOADED = 'DEALS_LOADED';
export const DEALS_ADDED = 'DEALS_ADDED';
export const DEALS_LOAD = 'DEALS_LOAD';
export const DEALS_SET_FETCH = 'DEALS_SET_FETCH';
export const SELECT_DEAL = 'SELECT_DEAL';
export const DROP_DEAL = 'DROP_DEAL';
export const SET_PAGINATOR = 'SET_PAGINATOR';
export const LOAD_DEALS = 'LOAD_DEALS';
export const UPDATE_DEAL = 'UPDATE_DEAL';
export const DEAL_COMMISSIONS_FETCHING = 'DEAL_COMMISSIONS_FETCHING';
export const DEAL_COMMISSIONS_LOADED = 'DEAL_COMMISSIONS_LOADED';
export const DEALS_ADDITIONAL_DATA_LOADED = 'DEALS_ADDITIONAL_DATA_LOADED';
export const DEALS_ADDITIONAL_DATA_ADDED = 'DEALS_ADDITIONAL_DATA_ADDED';
