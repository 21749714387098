import styled from 'styled-components';
import { FormGridRowBase } from '../../style';
import { AnketaCheckBox, AnketaInputMask } from '../../../style';

export const FormGridRow = styled(FormGridRowBase)`
  grid-template-areas: '. p1 p1 p2 p2 ch1 ch1 .';
`;

export const MainProfit = styled(AnketaInputMask)`
  grid-area: p1;
`;

export const AddedProfit = styled(AnketaInputMask)`
  grid-area: p2;
`;

export const ProofCheckbox = styled(AnketaCheckBox)`
  grid-area: ch1;
`;
