import styled from 'styled-components';
import { Grid } from 'components/primitives/grid';
import { InputMaskStyled, InputMaskDaDataStyled } from '../Form/style';

export const FormContainer = styled(Grid)`
  margin-top: 30px;
  width: 100%;
  overflow: visible;
  grid-template-columns: repeat(8, 1fr);
  grid-template-areas:
    '. header header header header . . .'
    '. fio fio fio fio fio fio .'
    '. gender gender gender gender gender gender .'
    '. bday bday . . . . .';
`;

export const HeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  grid-area: header;
`;

export const FioInput = styled(InputMaskDaDataStyled)`
  margin-bottom: 15px;
  grid-area: fio;
`;

export const GenderSelectorWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 15px;
  grid-area: gender;
`;

export const BdayInput = styled(InputMaskStyled)`
  margin-bottom: 15px;
  grid-area: bday;
`;
