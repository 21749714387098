import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { isNotificationsSupported } from 'utils/notification';

const askNotificationPermission = () => {
  if (isNotificationsSupported() && Notification && Notification.permission === 'default') {
    Notification.requestPermission();
  }
};

export const Permissions = () => {
  const location = useLocation();
  const isAuth = useSelector(({ user }) => !!user?.id);

  useEffect(() => {
    if (isAuth) {
      return;
    }

    askNotificationPermission();
  }, [location.pathname, isAuth]);

  return null;
};
