import { StorageFactory } from './StorageFactory';
import * as JWTUtils from './JWTUtils';

const ACCESS_TOKEN_NAME = 'ACCESS_TOKEN';
const ACCESS_TOKEN_EXP = 'ACCESS_TOKEN_EXP';
const ACCESS_TOKEN_ROLE = 'ACCESS_TOKEN_ROLE';

export const ROLE_USER = 'ROLE_USER';
export const ROLE_CUSTOMER = 'ROLE_CUSTOMER';
export const ROLE_EMPLOYEE = 'ROLE_EMPLOYEE';
export const ROLE_BRANCH_MANAGER = 'ROLE_BRANCH_MANAGER';
export const ROLE_COMPANY_MANAGER = 'ROLE_COMPANY_MANAGER';
export const ROLE_COMPANY_ADMIN = 'ROLE_COMPANY_ADMIN';
export const ROLE_ADMIN = 'ROLE_ADMIN';
export const ROLE_DEALER = 'ROLE_DEALER';

export const getAccessToken = () => StorageFactory().getItem(ACCESS_TOKEN_NAME);
export const getAccessTokenFromRequest = (req) => {
  if (ACCESS_TOKEN_NAME in req.cookies) {
    return req.cookies[ACCESS_TOKEN_NAME];
  }
  return null;
};

export const setToken = (access_token /*, refresh_token */) => {
  StorageFactory().setItem(ACCESS_TOKEN_NAME, access_token);
  StorageFactory().setItem(ACCESS_TOKEN_EXP, JWTUtils.getTokenExpTimestamp(access_token));
};

export const deleteToken = () => {
  StorageFactory().removeItem(ACCESS_TOKEN_NAME);
  StorageFactory().removeItem(ACCESS_TOKEN_EXP);
};

export const setRole = (access_token) => {
  StorageFactory().setItem(ACCESS_TOKEN_ROLE, getTokenClaim(access_token, 'roles'));
};

export const deleteRole = () => {
  StorageFactory().removeItem(ACCESS_TOKEN_ROLE);
};

export const getRole = () => StorageFactory().getItem(ACCESS_TOKEN_ROLE);

export const isGranted = (role, permissions = null) => {
  const rolesAvailable = [
    ROLE_USER,
    ROLE_CUSTOMER,
    ROLE_EMPLOYEE,
    ROLE_BRANCH_MANAGER,
    ROLE_COMPANY_MANAGER,
    ROLE_COMPANY_ADMIN,
    ROLE_ADMIN,
  ];
  try {
    let result = false;
    const roles = JSON.parse(permissions || getRole());
    if (ROLE_DEALER !== role) {
      const minKey = rolesAvailable.indexOf(role);

      if (Array.isArray(roles)) {
        roles.forEach((r) => {
          if (rolesAvailable.indexOf(r) >= minKey) {
            result = true;
          }
        });
      }
    } else {
      result = roles.indexOf(role) > -1;
    }
    return result;
  } catch (e) {
    return false;
  }
};

export const isTokenValid = (token) => (token ? !JWTUtils.isTokenExpired(token) : false);

export const getTokenClaim = (token, claim) => JWTUtils.getTokenPayload(token)[claim];

export const getTokenPayload = (token) => JWTUtils.getTokenPayload(token);

export const isLoggedIn = (isLoggedIn) =>
  isLoggedIn === 1 || isLoggedIn === true || isLoggedIn === 'yes' || isLoggedIn === 'true';
