import React from 'react';
import { Wrapper, LabelText } from './style';

const Label = ({ label, isFocused, isSelect, isAlert, error, isDisabled, isRequired }) => {
  if (!label) {
    return null;
  }

  return (
    <Wrapper
      isFocused={isFocused || isSelect}
      isDefaultValue={isSelect}
      isRequired={!isDisabled && !isSelect && isAlert}
    >
      <LabelText error={error}>{label}</LabelText>
      {isRequired && <sup>*</sup>}
    </Wrapper>
  );
};

export default Label;
