import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Wrapper, InputStyled, Label, Prompt } from './style';

const sanitaze = (val) => (val.length > 0 && val.trim()) || '';

const Input = ({
  name = '',
  label = '',
  className = '',
  defaultValue = '',
  val = '',
  type = 'text',
  error = '',
  placeholder = '',
  disabled = false,
  required = false,
  noSanitaze = false,
  isControlledComponent = false,
  onClick = () => {},
  onChange = () => {},
  onFocus = () => {},
  onBlur = () => {},
  onKeyPress = () => {},
  format = (value) => value,
  dataTest,
}) => {
  const inputRef = useRef();

  const [focused, setFocused] = useState(false);
  const [inputString, setInputString] = useState(val || defaultValue || '');

  const handleBlur = (e) => {
    const { value } = e.target;

    setFocused(false);
    if (noSanitaze) {
      onBlur(name ? { [name]: value } : value);
    } else {
      onBlur(name ? { [name]: sanitaze(value) } : sanitaze(value));
    }
  };

  const handleChange = (e) => {
    const { value } = e.target;

    if (value !== undefined) {
      if (noSanitaze) {
        onChange(name ? { [name]: value } : value);
      } else {
        onChange(name ? { [name]: sanitaze(value) } : sanitaze(value));
      }
    }
  };

  const wrapperClick = (e) => {
    if (!disabled) {
      inputRef && inputRef.current.focus();
      setFocused(true);
      onClick(e);
      onFocus(e);
    }
  };

  useEffect(() => {
    setInputString(val);
  }, [val]);

  useEffect(() => {
    if (!disabled && defaultValue) {
      setFocused(true);
    }
  }, [defaultValue, disabled]);

  return (
    <Wrapper
      required={required && error}
      disabled={disabled}
      focused={focused}
      onClick={wrapperClick}
      className={className}
    >
      {label && (
        <Label focused={!!(focused || inputString)} required={!disabled && required && error}>
          {label}
          {required && <sup>*</sup>}
        </Label>
      )}
      <InputStyled
        ref={inputRef}
        label={label}
        type={type}
        disabled={disabled}
        focused={!!(focused || inputString)}
        value={format(isControlledComponent ? val : inputString)}
        onFocus={wrapperClick}
        onChange={handleChange}
        onBlur={handleBlur}
        onKeyPress={onKeyPress}
        placeholder={!label ? placeholder : ''}
        data-test={dataTest}
      />
      {!disabled && error && <Prompt>{error ? error : ''}</Prompt>}
    </Wrapper>
  );
};

Input.propTypes = {
  name: PropTypes.string,
  label: PropTypes.any,
  defaultValue: PropTypes.string,
  val: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  error: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  isControlledComponent: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyPress: PropTypes.func,
};

export default Input;
