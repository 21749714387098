import React, { useState, useEffect } from 'react';
import { AlertSvg, CheckSvg } from 'assets/img';
import { PALETTE } from 'styles/constants';
import { passportPages, drivingLicencePages } from 'utils/constants/documents';
import {
  Doc,
  Img,
  Header,
  Title,
  SizeFile,
  Comment,
  IconMinusStyled,
  PageList,
  PageError,
} from './style';

const STATUS_CONF = {
  false: {
    icon: <CheckSvg />,
    color: PALETTE.red1,
    text: '',
  },
  true: {
    icon: <CheckSvg />,
    color: PALETTE.green1,
    text: '',
  },
  null: {
    icon: <AlertSvg />,
    color: PALETTE.red1,
    colorText: PALETTE.grey1,
    text: '',
  },
};

export const FileItem = (props) => {
  const { docs = null, obj = null, onSelect, onDelete, defaultDoc, defaultOnly } = props;
  const [status, setStatus] = useState(STATUS_CONF['null']);
  const [pages, setPages] = useState([]);
  const [pagesExist, setPagesExist] = useState([]);

  const setComment = (status) =>
    status.text.length > 0
      ? status.text
      : obj && Object.keys(defaultDoc).includes(obj.sysName)
      ? defaultDoc[obj.sysName]
      : '';

  const handleClick = (e, page = false) => {
    e.stopPropagation();
    if (obj && docs.length > 0 && !page) {
      onSelect({ type: obj, list: docs });
    } else {
      onSelect({ type: obj, list: null });
    }
  };

  const handleDelete = (e) => {
    e.stopPropagation();
    setPagesExist([]);
    onDelete({
      type: 'type',
      list: docs,
      docType: obj.sysName,
    });
  };

  useEffect(() => {
    if (docs && obj) {
      let stat =
        STATUS_CONF[
          docs.length > 0 ? !docs.some((doc) => !(doc.recognition.percent === 100)) : null
        ];
      if (obj.sysName === 'passport') {
        setPages(passportPages);
        const pArr = docs.filter((doc) => obj.sysName === doc.type.sysName);
        const pArrExist = passportPages.reduce((arr, page) => {
          if (!pArr.some((p) => page === p.classification.pages)) {
            return [...arr, page];
          }
          return arr;
        }, []);
        setPagesExist(pArrExist);
      }
      if (obj.sysName === 'driving-licence') {
        setPages(drivingLicencePages);
        const pArr = docs.filter((doc) => obj.sysName === doc.type.sysName);
        const pArrExist = ['front', 'back'].reduce((arr, side) => {
          if (
            side === 'front' &&
            !pArr.some((p) => !p.classification.type || p.classification.type.indexOf(side) > -1)
          ) {
            return [...arr, '1'];
          }
          if (
            side === 'back' &&
            !pArr.some((p) => p.classification.type && p.classification.type.indexOf(side) > -1)
          ) {
            return [...arr, '2'];
          }
          return arr;
        }, []);
        setPagesExist(pArrExist);
      }
      setStatus(stat);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docs && docs.length && obj]);

  return (
    <Doc>
      <Header onClick={handleClick} defaultDoc={defaultDoc[obj.sysName]}>
        <Img color={status.color}>{status.icon}</Img>
        <Title>{obj && obj.value}</Title>
        {obj && docs.length > 0 && defaultDoc[obj.sysName] && (
          <SizeFile>
            <IconMinusStyled onClick={handleDelete} />
          </SizeFile>
        )}
      </Header>
      {obj && docs.length > 0 ? (
        <PageList>
          {pages &&
            pages.map((page, p) => (
              <PageError
                key={`_p_${p + 1}`}
                color={pagesExist.some((p) => p === page)}
                sysName={obj.sysName}
              >
                {page}
              </PageError>
            ))}
        </PageList>
      ) : (
        <Comment color={(!defaultOnly && (status.colorText || status.color)) || PALETTE.grey1}>
          {setComment(status)}
        </Comment>
      )}
    </Doc>
  );
};
