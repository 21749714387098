import { FIELDS } from 'store/carInstance/constants';
import { MIN_PRICE } from '../constants';

export const createErrorObj = ([path, errors]) => {
  let cursor = {};
  const result = {};
  cursor = result;
  const splittedPath = path.split('.');

  if (splittedPath.length === 1) {
    return [splittedPath[0], errors];
  }

  const nestedPath = splittedPath.slice(1);

  let index = 1;

  while (index <= nestedPath.length) {
    if (index === nestedPath.length) {
      cursor[`${nestedPath[index - 1]}`] = errors;
      break;
    }

    cursor[`${nestedPath[index - 1]}`] = {};
    cursor = cursor[`${nestedPath[index - 1]}`];
    index = index + 1;
  }

  return [splittedPath[0], result];
};

export const collectObjectedErrors = (err) => {
  const errors = err.inner.reduce((acc, curr) => {
    if (acc[curr.path]) {
      acc[curr.path] = [...acc[curr.path], ...curr.errors];
    } else {
      acc[curr.path] = [...curr.errors];
    }

    return acc;
  }, {});

  Object.keys(errors).forEach((errorKey) => {
    errors[errorKey] = errors[errorKey][0];
  });

  const objectedErrors = Object.entries(errors).reduce((acc, curr) => {
    const [key, nested] = createErrorObj(curr);

    if (typeof nested === 'string') {
      return { ...acc, [key]: nested };
    }

    return acc[key]
      ? { ...acc, [key]: { ...acc[key], ...nested } }
      : { ...acc, [key]: { ...nested } };
  }, {});

  return objectedErrors;
};

export const getCarInstanceForValidation = (carInstance, hiddenFields = []) => {
  let price = carInstance?.price === 0 ? null : carInstance.price;

  if (hiddenFields.includes(FIELDS.price)) {
    price = MIN_PRICE;
  }

  const isPtsHidden =
    hiddenFields.includes(FIELDS.pts.number) && hiddenFields.includes(FIELDS.pts.issueDate);

  return {
    ...carInstance,
    price,
    vin: hiddenFields.includes(FIELDS.vin) ? '1234567890123456X' : carInstance.vin,
    isAvailablePts: !isPtsHidden,
    pts: {
      number: carInstance?.pts?.number || '',
      issueDate: carInstance?.pts?.issueDate || '',
      year: carInstance.year,
    },
  };
};

export const createErrorsFilter = (objectedErrors, ignoredErrors = [], exceptions = []) => {
  const filterFunc = (errorKey) => {
    const errorItem = objectedErrors[errorKey];

    if (typeof errorItem === 'string' && !exceptions.includes(errorKey)) {
      return !ignoredErrors.includes(errorItem);
    }

    return !!Object.keys(errorItem).filter(createErrorsFilter(errorItem, ignoredErrors)).length;
  };

  return filterFunc;
};
