import styled from 'styled-components';

import { FONT, PALETTE } from 'styles/constants';

export const Text = styled.h2`
  font-family: ${FONT.Roboto};
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: ${PALETTE.black};
`;
