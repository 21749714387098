import React, { useEffect, useState } from 'react';
import { Link } from 'components/primitives/link';
import { Modal } from 'components/primitives/modal';
import { ToolTip } from 'components/primitives/tooltip';
import { CarPreloaderGif, CloseSvg } from 'assets/img';
import OutsideClickHandler from 'react-outside-click-handler';
import { getURL } from 'data-providers/common';
import { checkImagesLoaded } from 'utils/helpers';
import { PlaceholderPng, MinusSolidSvg, EditSvg, DownloadCloudSvg } from 'assets/img';
import { PDFViewer } from './PDFViewer';
import { mergeFiles } from 'data-providers/mainAnketaProvider';
import { loadDocumentTrack } from 'metrika/documents';

import {
  Wrapper,
  CloseButton,
  FileWrapper,
  Header,
  Title,
  TitleChangeType,
  ChangeType,
  TypesSelect,
  SaveType,
  CancelType,
  Desc,
  Controls,
  ControlButton,
  ControlMenu,
  MenuButton,
  View,
  ControlView,
  CarouselStyled,
  List,
  ImgWrapper,
  ImgThumb,
  ImgZoomWrapper,
  ImgZoom,
  Icon,
  NavLeft,
  NavRight,
  Loader,
} from './style';

const MIME_TYPE = ['image/png', 'image/jpeg'];

export const FileView = ({
  data,
  classify = false,
  onClose,
  onChange = () => {},
  onDelete,
  docTypes = [],
}) => {
  const { type, list } = data;
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [changeType, setChangeType] = useState(classify);
  const [docsImg, setDocsImg] = useState(null);
  const [docType, setDocType] = useState(type);
  const [page, setPage] = useState(0);
  const [pageSecond, setPageScond] = useState(0);

  useEffect(() => {
    if (list) {
      const getMergeFiles = async () => {
        let withImg = [];

        if (list.length) {
          withImg = list.map((doc) => ({
            ...doc,
            imgUrl: `${getURL()}/applications/${doc.applicationId}/documents/${doc.id}`,
            mergedUrl: '',
          }));
        }

        setDocsImg(withImg);
        checkImagesLoaded(withImg.map((d) => d.imgUrl)).then(() => {
          setTimeout(() => {
            setImagesLoaded(true);
          }, 500);
        });
      };

      getMergeFiles();
    } else {
      onClose(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);

  const controls = [
    {
      name: 'Изменить тип документа',
      icon: <EditSvg />,
      func: () => setChangeType(true),
      hidden: docTypes?.length === 0,
    },
    {
      name: 'Скачать',
      icon: <DownloadCloudSvg />,
      func: async () => {
        if (list.length) {
          if (list.length > 1) {
            const filePath = await mergeFiles({
              id: list[0].applicationId,
              type: docType.sysName,
              format: 'pdf',
            });
            const mergedUrl = `${getURL()}${filePath.fileURL}`;
            window.open(mergedUrl, '_blank');
          } else {
            const fileUrl = `${getURL()}/files/${list[0].id}`;
            window.open(fileUrl, '_blank');
          }
          loadDocumentTrack(docType.sysName);
        }
      },
    },
    {
      name: 'Удалить',
      icon: <MinusSolidSvg />,
      menu: (
        <OutsideClickHandler onOutsideClick={() => setShowMenu(false)}>
          <ControlMenu>
            {list.length > 1 && (
              <MenuButton
                onClick={() =>
                  onDelete({
                    type: 'file',
                    list: [docsImg[page]],
                    docType: docType.sysName,
                  })
                }
              >
                Удалить страницу
              </MenuButton>
            )}
            <MenuButton
              onClick={() =>
                onDelete({
                  type: 'type',
                  list: docsImg,
                  docType: docType.sysName,
                })
              }
            >
              Удалить документ
            </MenuButton>
          </ControlMenu>
        </OutsideClickHandler>
      ),
      func: () => setShowMenu(true),
    },
  ];

  const sliderOptions = {
    nav: false,
    loop: false,
    center: true,
  };

  const listOptions = {
    ...sliderOptions,
    gutter: 10,
    edgePadding: 0,
    items: 6,
    responsive: {
      1280: {
        items: 6,
      },
      1440: {
        items: 6,
      },
    },
  };

  return (
    <Modal position="fixed">
      <Wrapper>
        <CloseButton onClick={() => onClose(null)}>
          <CloseSvg />
        </CloseButton>
        <FileWrapper>
          {!imagesLoaded ? (
            <Loader src={CarPreloaderGif} color="white" />
          ) : (
            <>
              <Header control={changeType}>
                {!changeType ? (
                  <>
                    <Title>{type.value || (docsImg[page] && docsImg[page].type.value)}</Title>
                    <Desc>{`Страница ${
                      docsImg[page] &&
                      ((docsImg[page].classification.pages.length > 0 &&
                        docsImg[page].classification.pages) ||
                        page + 1)
                    }`}</Desc>
                  </>
                ) : (
                  <>
                    {classify && (
                      <TitleChangeType>
                        Нам не удалось распознать документы.
                        <br />
                        Пожалуйста, выберите вручную тип документа для каждого изображения
                      </TitleChangeType>
                    )}
                    {docTypes?.length > 0 && (
                      <ChangeType>
                        <TypesSelect
                          name="type"
                          defaultValue={docType}
                          label="Тип документа"
                          list={docTypes}
                          onlyid={false}
                          onChange={({ type }) => setDocType(type)}
                        />
                        <SaveType
                          type="primary"
                          onClick={() => {
                            !classify && setChangeType(false);
                            onChange(docsImg[page], docType.sysName);
                          }}
                        >
                          Сохранить
                        </SaveType>
                        <CancelType onClick={() => setChangeType(false)}>Отмена</CancelType>
                      </ChangeType>
                    )}
                  </>
                )}
              </Header>
              <Controls>
                {!changeType &&
                  controls.map(
                    (control, c) =>
                      !control.hidden && (
                        <ToolTip text={control.name} key={`_control_${c + 1}`} position="bottom">
                          <ControlButton onClick={() => control.func({ doc: docsImg[page] })}>
                            <Link>{control.icon}</Link>
                            {showMenu && control.menu && control.menu}
                          </ControlButton>
                        </ToolTip>
                      )
                  )}
              </Controls>
              {docsImg && docsImg[page] && MIME_TYPE.includes(docsImg[page].mimeType) && (
                <View other={changeType && docType.id === 3381} classify={classify}>
                  <ControlView>
                    {page > 0 && (
                      <NavLeft onClick={() => setPage(page - 1)}>
                        <Icon />
                      </NavLeft>
                    )}
                  </ControlView>
                  <ImgZoomWrapper>
                    {docsImg.length > 0 && MIME_TYPE.includes(docsImg[page].mimeType) && (
                      <ImgZoom src={docsImg[page].imgUrl} />
                    )}
                  </ImgZoomWrapper>
                  <ControlView>
                    {docsImg.length > page + 1 && (
                      <NavRight onClick={() => setPage(page + 1)}>
                        <Icon />
                      </NavRight>
                    )}
                  </ControlView>
                </View>
              )}
              {docsImg && docsImg[page] && 'application/pdf' === docsImg[page].mimeType && (
                <PDFViewer src={`${docsImg[page].imgUrl}?preview=1`} />
              )}
              <List>
                {docsImg && docsImg.length > 0 && (
                  <CarouselStyled
                    settings={listOptions}
                    activeIndex={pageSecond}
                    count={docsImg && docsImg.length}
                    onIndexChanged={({ index }) => setPageScond(index)}
                  >
                    {docsImg &&
                      docsImg.map((doc, n) => (
                        <ImgWrapper key={n}>
                          {MIME_TYPE.includes(doc.mimeType) ? (
                            <ImgThumb src={doc.imgUrl} onClick={() => setPage(n)} />
                          ) : (
                            <ImgThumb src={PlaceholderPng} onClick={() => setPage(n)} />
                          )}
                        </ImgWrapper>
                      ))}
                  </CarouselStyled>
                )}
              </List>
            </>
          )}
        </FileWrapper>
      </Wrapper>
    </Modal>
  );
};
