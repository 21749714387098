import * as yup from 'yup';

import * as fields from 'components/modules/Calculations/components/CarDetail/validator/validations';
import { MESS_REQUIRED_FIELD } from 'utils/validationUtils/messages';
import { requiredTest } from 'utils/validationUtils/validators';

import { getFilesDkp, getFilesPts } from '../../helpers';

export const carInstanceScheme = yup.lazy(() =>
  yup.object(
    {
      brand: fields.brand,
      model: fields.model,
      version: yup.mixed().when('isNew', {
        is: true,
        then: fields.version,
        otherwise: fields.customString,
      }),
      price: fields.price,
      year: fields.customNumber,
      engineVolume: fields.engineVolume,
      enginePower: fields.enginePower.test(
        'incorrect',
        'Значение не должно быть меньше 20',
        (value) => {
          const enginePower = Number(value || 0);
          return enginePower >= 20;
        }
      ),
      engineNumber: fields.engineNumber,
      bodyNumber: fields.customString
        .test('required', MESS_REQUIRED_FIELD, (vin) => requiredTest(vin))
        .when('steeringWheel', {
          is: (steeringWheel) => steeringWheel === 'right',
          then: fields.customString.test(
            'incorrect',
            'Минимально допустимое кол-во символов – 7',
            (val = '') => val.length >= 7
          ),
          otherwise: fields.bodyNumber,
        }),
      permittedMaxWeight: fields.permittedMaxWeight,
      pts: yup.object({ number: fields.number, issueDate: fields.simpleDate }),
      vin: yup.mixed().when(['steeringWheel'], {
        is: (steeringWheel) => steeringWheel === 'left',
        then: fields.vin.test('required', MESS_REQUIRED_FIELD, (vin) => requiredTest(vin)),
        otherwise: fields.customString,
      }),
      mileage: fields.customNumber.when(['isNew'], (isNew, schema) => {
        if (isNew) {
          return schema;
        } else {
          return schema
            .test(
              'required',
              MESS_REQUIRED_FIELD,
              (value) => requiredTest(value) && value !== '0' && value !== 0
            )
            .test(
              'incorrect',
              'Введите значение между 1001 и 1000000',
              (value) => value >= 1001 && value <= 1000000
            );
        }
      }),
    },
    ['vin']
  )
);

export const carInstanceManualDealScheme = yup.lazy(() =>
  yup.object({
    brand: fields.brand,
    model: fields.model,
    price: fields.price,
    year: fields.customNumber,
    engineVolume: fields.customString.test(
      'incorrect',
      'Значение должно быть от 500 до 9000',
      (value) => {
        if (!value || value === '0') {
          return true;
        }
        const v = Number(value);
        if (isNaN(v)) return false;
        return v <= 9000 && v >= 500;
      }
    ),
    enginePower: fields.customNumber
      .test('incorrect', 'Поле должно содержать 2 или 3 символа', (value) => {
        if (!value) {
          return true;
        }
        const enginePower = `${value}`;
        return enginePower.length === 2 || enginePower.length === 3;
      })
      .test('incorrect', 'Значение не должно быть меньше 20', (value) => {
        if (!value) {
          return true;
        }
        const enginePower = Number(value || 0);
        return enginePower >= 20;
      }),
    engineNumber: fields.customString,
    bodyNumber: fields.customString,
    permittedMaxWeight: fields.customNumber.test(
      'incorrect',
      'Значение должно быть от 900 до 3500',
      (value) => {
        if (!value) {
          return true;
        }
        const v = Number(value);
        if (isNaN(v)) return false;
        return v <= 3500 && v >= 900;
      }
    ),
    vin: fields.vin.test('required', MESS_REQUIRED_FIELD, (vin) => requiredTest(vin)),
  })
);

export const carMainScheme = yup.lazy(() =>
  yup.object({
    brand: fields.brand,
    model: fields.model,
    price: fields.price,
    year: fields.customNumber,
    engineVolume: fields.engineVolume,
    enginePower: fields.enginePower,
    engineNumber: fields.engineNumber,
    bodyNumber: fields.bodyNumber,
    permittedMaxWeight: fields.permittedMaxWeight,

    mileage: fields.customNumber.when(['isNew'], (isNew, schema) => {
      if (isNew) {
        return schema;
      } else {
        return schema.test(
          'required',
          MESS_REQUIRED_FIELD,
          (value) => requiredTest(value) && value !== '0' && value !== 0
        );
      }
    }),
  })
);

export const carMainShortScheme = yup.lazy(() =>
  yup.object({
    brand: fields.brand,
    model: fields.model,
    price: fields.price,
    year: fields.customNumber,
  })
);

export const policeRecipientScheme = yup
  .object({
    id: yup.number().test('required_field', MESS_REQUIRED_FIELD, requiredTest),
    ndsFlag: yup.mixed().test('required_field', MESS_REQUIRED_FIELD, requiredTest),
    ndsAmount: yup
      .mixed()
      .when(
        ['ndsFlag', '$bankSpecificRequiredFields'],
        (ndsFlag, bankSpecificRequiredFields = [], schema) => {
          if (ndsFlag && bankSpecificRequiredFields.includes('ndsAmount')) {
            return schema.test('required', MESS_REQUIRED_FIELD, (value) => requiredTest(value));
          } else {
            return schema;
          }
        }
      ),
  })
  .nullable()
  .test('required_field', MESS_REQUIRED_FIELD, requiredTest);

export const policeScheme = yup.lazy(() =>
  yup.object().shape({
    contractNumber: yup.mixed().test('required_field', MESS_REQUIRED_FIELD, requiredTest),
    // Временно скрываем дату ДКП в форме авто CREDIT-3095
    // contractDate: fields.simpleDate,
    invoiceNumber: yup
      .mixed()
      .when('$bankSpecificRequiredFields', (bankSpecificRequiredFields = [], schema) => {
        if (bankSpecificRequiredFields.includes('invoiceNumber')) {
          return schema.test('required_field', MESS_REQUIRED_FIELD, requiredTest);
        } else {
          return schema;
        }
      }),
    invoiceDate: yup
      .mixed()
      .when('$bankSpecificRequiredFields', (bankSpecificRequiredFields = [], schema) => {
        if (bankSpecificRequiredFields.includes('invoiceDate')) {
          return fields.simpleDate;
        } else {
          return schema;
        }
      }),
    recipient: policeRecipientScheme,
  })
);

export const policeShortScheme = yup.lazy(() =>
  yup.object().shape({
    // Временно скрываем дату ДКП в форме авто CREDIT-3095
    // contractDate: fields.customString
    //   .test('incorrect', 'Некорректная дата', function (value) {
    //     if (!value) {
    //       return true;
    //     }
    //     return value.includes('-') && !value.includes('_');
    //   })
    //   .test('incorrect', 'Дата - не ранее 1 января 1970 года', function (value) {
    //     if (!value) {
    //       return true;
    //     }
    //     const issueDate = moment(value, 'YYYY-MM-DD');
    //     return moment(issueDate).isAfter(moment(new Date(1970, 0, 1), 'DD.MM.YYYY'));
    //   })
    //   .test('incorrect', 'Не может быть больше текущей даты', function (value) {
    //     if (!value) {
    //       return true;
    //     }
    //     const lastValidDate = moment();
    //     const issueDate = moment(value, 'YYYY-MM-DD');
    //
    //     const diff = lastValidDate.diff(issueDate);
    //
    //     return diff >= 0;
    //   }),
  })
);

export const filesScheme = yup.lazy(() =>
  yup
    .array()
    .test('not_dkp_exist', 'ДКП не загружен', function (value) {
      const files = getFilesDkp(value);
      return files && files.length > 0
        ? true
        : this.createError({
            message: 'ДКП не загружен',
            path: 'dkp',
          });
    })
    .test('not_pts_exist', 'ПТС не загружен', function (value) {
      const files = getFilesPts(value);
      return files && files.length > 0
        ? true
        : this.createError({
            message: 'ПТС не загружен',
            path: 'pts',
          });
    })
);

export const filesShortScheme = yup.array();
