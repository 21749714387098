import React from 'react';
import Contacts from './components/Contacts';
import Passport from './components/Passport';
import Address from './components/Address';
import { PersonalInfo } from '../PersonalInfo';

import * as Form from '../Form/style';

const fn = () => {};

const Insurer = ({ anketaState, scrollTo, onBlur = fn, onScroll = fn }) => {
  const innerProps = {
    data: anketaState,
    scrollTo,
    errors: anketaState.errors,
    disabled: anketaState.isDisabled,
    onBlur,
    onScroll,
  };

  return (
    <Form.BlockWrapper>
      <Form.Header>Анкета Клиента</Form.Header>

      <PersonalInfo {...innerProps} title="Личная информация" />
      <Contacts {...innerProps} />
      <Passport {...innerProps} />
      <Address {...innerProps} />
    </Form.BlockWrapper>
  );
};

export default Insurer;
