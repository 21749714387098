import React, { useState } from 'react';
import moment from 'moment';

import * as S from './style';

export default function DealItem({ data }) {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleButtonClick = (index) => {
    setActiveIndex(index);
  };

  const allUniqueBanks = data.bankIds.filter(
    (bankId, index, bankIds) => index === bankIds.lastIndexOf(bankId)
  );

  const allOnlineBanks = data.onlineBanksIds;

  const notOnlineBanks = allUniqueBanks.filter((item) => allOnlineBanks.indexOf(item) === -1);

  const sortedBanks = [...allOnlineBanks, ...notOnlineBanks];

  return (
    <a
      href={`${process.env.REACT_APP_STAGE_URL}/#application-details-new?app_id=${
        data.applicationId
      }&tab=${3}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <S.Container>
        <S.Wrapper>
          <S.InfoWrapper>
            <S.Date>{moment(data.date).format('DD.MM.YYYY')}</S.Date>
            <S.Title>{data.customer}</S.Title>
          </S.InfoWrapper>
          <S.BanksContainer>
            {sortedBanks.map((bank, index) => (
              <S.BanksInfoButton
                key={`${bank}_${index}`}
                active={index === activeIndex}
                onClick={() => handleButtonClick(index)}
              >
                <S.LogoIco type="pps" id={bank} />
              </S.BanksInfoButton>
            ))}
          </S.BanksContainer>
        </S.Wrapper>
      </S.Container>
    </a>
  );
}
