import styled from 'styled-components';
import Widget from 'components/primitives/widget';

export const OldLinkContainer = styled(Widget)``;

export const ExtLink = styled.a`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  display: inline-block;
  width: 100%;
  margin: 7px 0px;
  &:hover {
    color: #000000;
  }
`;

export const CurrLink = styled.a`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  position: relative;
  display: inline-block;
  width: 100%;
  margin: 7px 0px 7px 14px;

  &:before {
    content: '';
    position: absolute;
    display: block;
    height: 7px;
    width: 7px;
    left: -14px;
    top: 0;
    border-left: solid 7px #7ebfed;
    border-top: solid 7px transparent;
    border-bottom: solid 7px transparent;
  }
  &:hover {
    color: #000000;
  }
`;
