import React, { useState, useEffect, useRef } from 'react';
import animateScrollTo from 'animated-scroll-to';
import moment from 'moment';
import { InputMask } from 'utils/inputMask';
import { BLOCK_DOCS } from '../../constants';
import { FIELDS } from 'store/carInstance/constants';

import * as S from './style';

const fn = () => {};

const BlockAutoDocs = ({
  carInstance,
  hiddenFields = [],
  errors,
  scrollTo,
  onScroll = fn,
  onChange = fn,
  onBlur = fn,
  disabled = false,
  requiredFields,
}) => {
  const ref = useRef();

  const { series, number, issueDate } = carInstance?.pts || {};

  const convertDate = (date) => {
    let result = '';

    if (date) {
      const momentDate = moment(date, 'YYYY-MM-DD');

      if (!momentDate.isValid()) {
        return '';
      }

      result = date.includes('.') ? date : momentDate.format('DD.MM.YYYY');
    }

    if (result === 'Invalid date') {
      return '';
    }

    return result;
  };

  const convertedIssueDate = convertDate(issueDate);

  const [ptsSeriesNumber, setPtsSeriesNumber] = useState(`${series || ''}${number || ''}`);
  const [ptsIssueDate, setPtsIssueDate] = useState(convertedIssueDate);

  useEffect(() => {
    if (ref && ref.current && scrollTo === BLOCK_DOCS.name) {
      animateScrollTo(ref.current);
      onScroll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollTo]);

  useEffect(() => {
    setPtsSeriesNumber(`${series || ''}${number || ''}`);
    setPtsIssueDate(convertedIssueDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carInstance]);

  const ptsChangeHandler = (item) => {
    setPtsSeriesNumber(item.number.toUpperCase());
    onChange('number', 'pts');
  };

  const ptsDateChangeHandler = (item) => {
    setPtsIssueDate(item.issueDate);
    onChange('issueDate', 'pts');
  };

  const ptsBlurHandler = async ({ number }) => {
    onBlur({ number }, 'pts');
  };

  const ptsDateBlurHandler = async ({ issueDate }) => {
    const date = moment(issueDate, 'DD.MM.YYYY', true);
    let newDate = carInstance?.pts?.issueDate;
    if (date.isValid() && issueDate.includes('.') && !issueDate.includes('_')) {
      newDate = date.format('YYYY-MM-DD');
    }
    onBlur({ issueDate: newDate }, 'pts');
  };

  let seriesNumberPtsError = `${errors.pts?.series || ''}${errors.pts?.number || ''}`;

  seriesNumberPtsError =
    seriesNumberPtsError === 'Должно содержать 10 или 15 символов'
      ? `${seriesNumberPtsError}. Сейчас ${ptsSeriesNumber.length}`
      : seriesNumberPtsError;

  const isPtsHidden =
    hiddenFields.includes(FIELDS.pts.number) && hiddenFields.includes(FIELDS.pts.issueDate);

  return (
    <S.GridStyled ref={ref}>
      <S.AutoDocsHeader>Документы на автомобиль</S.AutoDocsHeader>
      {!isPtsHidden && (
        <>
          {!hiddenFields[FIELDS.pts.number] && (
            <S.Pts
              required={requiredFields.includes('pts.number')}
              isControlledComponent
              name="number"
              label="Серия, номер ПТС / ЭПТС"
              val={ptsSeriesNumber}
              mask={InputMask.PTS}
              error={seriesNumberPtsError}
              onChange={ptsChangeHandler}
              onBlur={ptsBlurHandler}
              disabled={disabled}
              dataTest="numberPTS"
            />
          )}
          {!hiddenFields[FIELDS.pts.issueDate] && (
            <S.PtsDate
              required={requiredFields.includes('pts.issueDate')}
              name="issueDate"
              label="Дата выдачи ПТС / ЭПТС"
              value={ptsIssueDate}
              minDate={new Date(1970, 0, 1)}
              maxDate={new Date()}
              disabled={disabled}
              error={errors.pts.issueDate}
              onChange={ptsDateChangeHandler}
              onBlur={ptsDateBlurHandler}
              autoFocus={false}
              buttonDataTest="datePTS_EPTS"
            />
          )}
        </>
      )}
    </S.GridStyled>
  );
};

export default BlockAutoDocs;
