import React from 'react';
import PropTypes from 'prop-types';

import * as S from './style';

export const RoleSwitcher = ({
  disabled,
  onSwitchDriver,
  onSwitchInsurer,
  checkedDriver = false,
  checkedInsurer,
}) => (
  <S.Switch>
    <S.CheckBoxStyled name="owner" label="Собственник" checked disabled />
    <S.CheckBoxStyled
      name="insurer"
      label="Страхователь"
      checked={checkedInsurer}
      disabled={disabled}
      onClick={() => {
        if (!disabled) {
          onSwitchInsurer(!checkedInsurer);
        }
      }}
    />
    <S.CheckBoxStyled
      name="driver"
      label="Водитель"
      checked={checkedDriver}
      disabled={disabled}
      onClick={() => {
        if (!disabled) {
          onSwitchDriver(!checkedDriver);
        }
      }}
    />
  </S.Switch>
);

RoleSwitcher.propTypes = {
  checked: PropTypes.bool,
  onSwitch: PropTypes.func,
};
