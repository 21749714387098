import React from 'react';
import PropTypes from 'prop-types';
import { WrapperStyled, BackgroundStyled, WrapperContent, ContentStyled } from './style';

export const Modal = ({ position = 'absolute', children, className, onClick }) => (
  <WrapperStyled className={className} onClick={onClick} position={position}>
    <BackgroundStyled />
    <WrapperContent>
      <ContentStyled position={position}>{children}</ContentStyled>
    </WrapperContent>
  </WrapperStyled>
);

Modal.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

Modal.defaultProps = {
  children: PropTypes.any,
  className: '',
  onClick: () => {},
};
