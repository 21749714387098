import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';

import { ApplicationsList, ApplicationsSearch } from 'components/modules/Applications';
import { ApplicationNotifications } from 'components/modules/ApplicationNotifications';
import EPointsPopup from 'components/common/EPointsPopup';
import { dropApplications } from 'store/applications';
import { clearCarInstance } from 'store/carInstance';
import { resetSortig } from 'store/filters';
import * as ePoints from 'store/ePoints';
import { findBanner } from 'utils/findBunner';

import { Navigation } from './components/Navigation';
import { Filters } from './components/Filters';
import { Search } from './components/Search';

import * as S from './style';

export const Applications = () => {
  const rootDispatch = useDispatch();
  const dealerId = useSelector((store) => store.user.dealer);
  const dealers = useSelector((store) => store.user.dealers);
  const showEPointsPopup = useSelector((store) => store.ePoints.showEPointsPopup);
  const [banner, setBanner] = useState(null);
  const [firstTime, setFirstTime] = useState(true);

  useEffect(() => {
    rootDispatch(dropApplications());
    rootDispatch(clearCarInstance());
    rootDispatch(resetSortig());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let banner = null;
    if (dealerId) {
      banner = findBanner(dealerId);
    }

    setBanner(banner);
  }, [dealerId]);

  useEffect(() => {
    if (firstTime && dealers && dealers.length > 0) {
      setFirstTime(false);
      rootDispatch(ePoints.loadEPointsPrograms());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealers]);

  return (
    <S.Container>
      {banner}
      <S.NavigationContainer>
        <S.Filters>
          <Navigation />
          <Filters />
        </S.Filters>
        <S.Search>
          <Search />
        </S.Search>
      </S.NavigationContainer>
      <S.ListContainer>
        <Switch>
          <Route path={['/applications/in-work', '/applications/list', '/applications/lost']}>
            <ApplicationsList />
          </Route>
          <Route path={'/applications/search'}>
            <ApplicationsSearch />
          </Route>
          <Route path={'/applications'}>
            <Redirect to={'/applications/list'} />
          </Route>
        </Switch>
        <S.NotificationContainer>
          <ApplicationNotifications />
        </S.NotificationContainer>
      </S.ListContainer>
      {showEPointsPopup && <EPointsPopup />}
    </S.Container>
  );
};
