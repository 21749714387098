import Cookie from 'js-cookie';

const setCookie = (name, value, noExpires = false) => {
  const cookie = [
    `${name}=${value}`,
    `domain=${process.env.REACT_APP_COOKIE_DOMAIN}`,
    'path=/',
    'secure',
  ];

  if (noExpires) {
    cookie.push(`expires=${new Date(2147483647 * 1000).toUTCString()}`);
  }

  document.cookie = cookie.join('; ');
};

export const filters = {
  money: ({ number = 0, locale = 'ru-RU', currency }) => {
    if (!number) {
      return;
    }
    return new Intl.NumberFormat(locale, {
      style: currency ? 'currency' : undefined,
      currency: currency,
    }).format(number);
  },
};

export const formatRgbColor = (color) => (String(color).indexOf('#') === 0 ? color : `#${color}`);

export const setDealerCookie = (dealerId) => {
  setCookie('currentDealerId', dealerId, true);
};

export const getDealerCookie = () => Cookie.get('currentDealerId');

export const getRoleCookie = () => Cookie.get('currentRoleId');

export const setRoleCookie = (roleId, roleSysName) => {
  setCookie('currentRoleId', roleId, true);
  if (roleSysName) {
    setCookie('currentRoleSysName', roleSysName, true);
  }
};

export const setAuthCookie = (token, refresh) => {
  setCookie('token', token);
  setCookie('auth', token);
  setCookie('refresh-token', refresh);
  setCookie('rfront', 1);
};

export const clearAuthCookie = () => {
  Cookie.remove('token');
  Cookie.remove('auth');
  Cookie.remove('refresh-token');
  Cookie.remove('rfront');
};

export const setIncCookies = (cookies) => {
  const params = {
    domain: process.env.REACT_APP_COOKIE_DOMAIN,
    path: '/',
    expires: 40,
    secure: true,
  };
  cookies.forEach((cookie) => {
    Cookie.set(cookie.Name, cookie.Value, { ...params });
  });
};

export const deleteCookies = () => {
  Object.keys(Cookie.get()).forEach(function (cookieName) {
    Cookie.remove(cookieName);
  });
};

export const setServerCookies = (cookies) => {
  (cookies || []).forEach((item) => {
    if (!item.HttpOnly) {
      Cookie.set(item.Name, item.Value, {
        domain: item.Domain,
        path: item.Path,
        expires: item.Expires,
        secure: item.Secure,
      });
    }
  });
};
export const checkImageLoaded = (path) => {
  const url = path.indexOf(',') > -1 ? path.splic(',')[0] : path;
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => resolve({ url, status: 'ok' });
    img.onerror = () => resolve({ url, status: 'error' });
    img.src = url;
  });
};

export const checkImagesLoaded = async (paths) =>
  await Promise.all(paths.map((path) => checkImageLoaded(path)));

export const getParameterByName = function (name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[[\]]/g, '\\$&');
  let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) {
    return null;
  }
  if (!results[2]) {
    return '';
  }
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const sendPostMessage = ({ type, payload, frame, target }) => {
  if (!frame) return;
  console.log('Post message sent to DOS', { type, payload });
  const targetDomain =
    target || getParameterByName('post_message_url') || process.env.REACT_APP_KASAGO_URL;
  frame.postMessage({ type, payload }, targetDomain);
};

export const postMessageListener = (event) => {
  const targetDomain = getParameterByName('post_message_url') || process.env.REACT_APP_KASAGO_URL;

  // Create IE + others compatible event handler
  const eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent';
  const eventBus = window[eventMethod];
  const messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message';

  // Listen to message from parent website
  eventBus(messageEvent, function (e) {
    if (e.origin !== targetDomain) {
      return;
    }
    event(e && e.data);
  });
};

export const validateObj = (obj = {}, keyPath = []) => {
  if (!Object.keys(obj).length || !keyPath.length) return;
  let validatedPath = { ...obj };
  // eslint-disable-next-line array-callback-return
  return !keyPath.some((i) => {
    if (!validatedPath[i]) return true;
    validatedPath = validatedPath[i];
  });
};

export const formatBytes = (bytes, decimals = 1) => {
  if (!+bytes) return '0 бт';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['бт', 'кб', 'мб', 'гб', 'тб', 'пб', 'эб', 'зб', 'йб'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const SECOND_DOC_TRUST = [
  'driving-licence',
  'snils',
  'inn',
  'international-passport',
  'military-id',
];

export const updatePages = (documents, documentTypes) => {
  return ['second', 'passport'].reduce((obj, key) => {
    const pages = documents
      .filter(
        (doc) =>
          (key === 'passport' && doc.type && key === doc.type.sysName) ||
          (key === 'second' &&
            doc.type &&
            documentTypes
              .filter((f) => SECOND_DOC_TRUST.some((e) => e === f.sysName))
              .some((d) => doc.type.sysName === d.sysName))
      )
      .reduce((count) => {
        if (key === 'passport') {
          return 1;
        }
        if (key === 'second') {
          return count + 1;
        }
        return count;
      }, 0);
    return {
      ...obj,
      [key]: { pages },
    };
  }, {});
};

export const isFillCustomer = (personalInformation) => {
  const { surname = '', name = '' } = personalInformation || {};
  const fullName = [surname, name].join(' ').trim();

  return fullName.toLowerCase() !== 'фамилия имя';
};
