import styled from 'styled-components';
import { PALETTE } from 'styles/constants';

export const PopupStyled = styled.div`
  position: fixed;
  width: 240px;
  height: 120px;
  left: 60px;
  top: calc(50% - 60px);
  z-index: 1000000;
  display: flex;
  justify-content: center;
  align-items: baseline;
  flex-direction: column;
  background: rgb(245, 246, 250);
  border: 1px solid rgb(220, 221, 229);
  border-radius: 8px;
  :before {
    content: ' ';
    height: 0;
    position: absolute;
    width: 0;
    left: -12px;
    border: 6px solid transparent;
    border-right-color: rgb(220, 221, 229);
  }
`;

export const Points = styled.div`
  margin: 10px;
  height: 24px;
  padding: 0 10px;
  border-radius: 12px;
  font-size: 14px;
  line-height: 24px;
  text-align: left;
  background: ${PALETTE.red1};
  color: white;
`;
