import { useEffect, useRef, useState } from 'react';

export const useTimer = (duration) => {
  const timer = useRef(null);
  const [seconds, setSeconds] = useState(duration);

  const stop = () => {
    if (timer.current) {
      clearInterval(timer.current);
    }
  };

  const start = () => {
    stop();
    setSeconds(duration);
    timer.current = setInterval(() => {
      setSeconds((prev) => (prev <= 0 ? 0 : prev - 1));
    }, 1000);
  };

  useEffect(() => {
    return stop;
  }, []);

  useEffect(() => {
    if (seconds <= 0) {
      stop();
    }
  }, [seconds]);

  return { seconds, start, stop };
};
