import styled from 'styled-components';
import { SearchSvg } from 'assets/img';
import { PALETTE } from 'styles/constants';
import * as Primitive from 'components/primitives/pagination';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 40vh;

  @media (max-height: 1035px) {
    height: 36vh;
  }

  @media (max-height: 825px) {
    height: 32vh;
  }

  @media (max-height: 770px) {
    height: 29vh;
  }
`;

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 10px;
  height: 40px;
  border-radius: 8px;
  background-color: ${PALETTE.grey16};
  margin-top: 5px;
  margin-bottom: 5px;

  @media (max-height: 930px) {
    height: 30px;
  }

  @media (max-height: 795px) {
    height: 26px;
  }
`;

export const Input = styled.input`
  border-width: 0;
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  height: 20px;
  color: ${PALETTE.white};
  width: calc(100% - 10px);
  background-color: ${PALETTE.grey16};

  &:hover,
  &:focus,
  &:active {
    border-width: 0;
    outline: 0;
    box-shadow: none;
  }

  @media (max-height: 930px) {
    height: 16px;
  }

  @media (max-height: 795px) {
    height: 14px;
  }
`;

export const Button = styled(SearchSvg)`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  height: 100%;
  width: 100%;
  margin: 5px 0px;
  overflow-y: auto;
  padding-right: 10px;

  scrollbar-width: thin;
  scrollbar-color: ${PALETTE.grey16} ${PALETTE.black2};
`;

export const ListItem = styled.button`
  display: flex;
  align-items: center;
  text-align: left;
  font-weight: 400;
  font-size: 16px;
  white-space: nowrap;
  padding: 14px 0px;
  border: 0px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  outline: none;
  color: ${PALETTE.grey1};
  background-color: inherit;
  ${(p) => (p.selected ? `color: ${PALETTE.white};` : ``)}

  &:hover {
    cursor: pointer;
    color: ${PALETTE.grey14};
  }

  @media (max-height: 1035px) {
    font-size: 0.9rem;
  }
`;

export const DealerName = styled.div`
  margin-right: 10px;
`;

export const ErrorBlock = styled.div`
  width: 160px;
  min-height: 100px;
  background: #fcc;
  color: red;
  border: 1px solid red;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const StyledPagination = styled(Primitive.Pagination)`
  margin: 0 auto;
`;
