export const appParamsInitialState = {
  applicationParams: {
    lastModifiedAt: null,
    source: 'ecredit_v4',
  },
};

export const paramsFromAPIToState = (params) => ({
  applicationParams: {
    lastModifiedAt: params.lastModifiedAt,
    source: params.source,
  },
});

export const paramsFromStateToAPI = (person) => {
  const params = person.applicationParams;
  return {
    applicationParams: {
      lastModifiedAt: params.lastModifiedAt,
      source: params.source,
    },
  };
};
