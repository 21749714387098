import styled, { css } from 'styled-components';
import BankLogo from 'components/common/BankLogo';
import * as UK from 'components/primitives/content';
import { PALETTE } from 'styles/constants';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 32px 20px 20px;
  max-width: calc(100% / 3);
  background-color: ${PALETTE.white};
  border-bottom: 2px solid ${PALETTE.grey9};
  border-right: 2px solid ${PALETTE.grey9};
`;

export const Title = styled(UK.Title3)`
  font-weight: 600;
  font-size: 16px;
  text-transform: capitalize;
  color: ${PALETTE.dark2};
`;

export const Description = styled(UK.Description)`
  display: flex;
  padding-bottom: 4px;
  align-items: center;
  justify-content: start;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  flex-wrap: wrap;

  & a {
    margin-left: auto;
    color: ${PALETTE.grey5};
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const DigitalBankList = styled.div`
  display: flex;
  margin-right: 20px;
`;

export const BankItemContainer = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  margin-right: 5px;
  padding: 5px;
  height: 35px;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: ${PALETTE.blue5};
    transition: all 0.2s ease-in-out;
  }

  &:hover div {
    display: flex;
  }
`;

export const BankName = styled.div`
  display: none;
  height: 23px;
  font-size: 12px;
  padding: 2px;
  margin-right: 5px;
  color: ${PALETTE.white};
`;

export const BankItem = styled(BankLogo)`
  width: 23px;
  height: 23px;
`;

export const RightSideContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 35px;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  padding: 10px;
  margin-right: 10px;
  background-color: ${PALETTE.blue7};
  color: ${PALETTE.white};
  border-radius: 5px;
  cursor: pointer;
`;

export const ApproveCreditButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 0px;
  width: 100%;
  min-height: 40px;
  border: 1px solid ${PALETTE.red5};
  border-radius: 8px;
  background-color: ${PALETTE.white};
  color: ${PALETTE.red5};
  margin: 16px auto 0px;
  margin-top: auto;
  cursor: pointer;

  &:hover {
    background-color: ${PALETTE.red5};
    color: ${PALETTE.white};
  }
  &:active {
    background-color: ${PALETTE.red6};
    color: ${PALETTE.white};
    border-color: ${PALETTE.red6};
  }
  ${(props) =>
    props.disabled &&
    css`
      background-color: ${PALETTE.grey14};
      color: ${PALETTE.grey15};
      border: 1px solid ${PALETTE.grey14};
      cursor: not-allowed;

      &:hover {
        background-color: ${PALETTE.grey14};
        color: ${PALETTE.grey15};
      }

      &:active {
        background-color: ${PALETTE.grey14};
        color: ${PALETTE.grey15};
        border-color: ${PALETTE.grey14};
        transform: none;
      }
    `}
`;
