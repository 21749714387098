import styled from 'styled-components';
import { Grid } from 'components/primitives/grid';
import { SubHeader, InputMaskStyled, InputMaskDaDataStyled } from '../../../Form/style';

export const Wrapper = styled(Grid)`
  margin-top: 41px;
  width: 100%;
  overflow: visible;
  grid-template-columns: repeat(8, 1fr);
  grid-template-areas:
    '. title title title title title title .'
    '. series series date date department department .'
    '. issuesby issuesby issuesby issuesby issuesby issuesby .'
    '. birthplace birthplace birthplace birthplace . . .';
`;

export const Title = styled(SubHeader)`
  grid-area: title;
`;

export const SeriesAndNumber = styled(InputMaskStyled)`
  grid-area: series;
  margin-bottom: 15px;
`;

export const DateOfIssue = styled(InputMaskStyled)`
  grid-area: date;
  margin-bottom: 15px;
`;

export const DepartmentCode = styled(InputMaskDaDataStyled)`
  grid-area: department;
  margin-bottom: 15px;
`;

export const IssuesBy = styled(InputMaskDaDataStyled)`
  grid-area: issuesby;
  margin-bottom: 15px;
`;

export const BirthPlace = styled(InputMaskStyled)`
  grid-area: birthplace;
  margin-bottom: 15px;
`;
