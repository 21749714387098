export const prepareFio = (fio) => {
  const [surname, name] = fio?.split(' ');

  return `${surname || ''} ${name || ''}`.trim();
};

export const prepareNodes = (nodes) => {
  return nodes && nodes?.length && nodes?.filter
    ? nodes
        .filter((node) => node?.roleSysName === 'driver')
        .map((node) => ({ customerId: node.customerId }))
    : [];
};

export const prepareCustomers = (filledCustomers) => {
  return filledCustomers && filledCustomers?.length && filledCustomers?.map
    ? filledCustomers
        .map((customer) => ({
          id: customer.id,
          fio: [
            customer.personalInformation?.surname || '',
            customer.personalInformation?.name || '',
          ]
            .join(' ')
            .trim(),
          kbm: customer.secondDocument?.driverLicense?.kbm || null,
        }))
        .filter((customer) => customer.id)
    : [];
};

export const prepareDrivers = (drivers) => {
  const preparedDrivers = drivers?.map
    ? drivers.map((driver) => ({
        id: driver.id,
        fio: prepareFio(driver.fio),
        isDeleted: driver.isDeleted,
      }))
    : [];

  return preparedDrivers.filter((driver) => driver.id);
};

export const prepareData = (anketaState) => {
  const { nodes, filledCustomers, drivers } = anketaState;

  return {
    nodes: prepareNodes(nodes),
    customers: prepareCustomers(filledCustomers),
    drivers: prepareDrivers(drivers),
  };
};

const getDriverData = ({ fio = '', isDeleted = false }) => ({ fio: fio.trim(), isDeleted });

export const createByPreparedData = ({ nodes, customers, drivers }) => {
  const driversIds = drivers.map(({ id }) => id);

  return nodes
    .map((node) => {
      const id = +node.customerId;
      const customer = customers?.find((item) => item.id === id);

      if (!customer) {
        return { filtered: true };
      }

      const { fio: storedFio, kbm } = customer;

      const isActive = driversIds.includes(id);
      const { fio, isDeleted } = isActive
        ? getDriverData(drivers.find((item) => item.id === id))
        : { fio: storedFio };

      if (!fio) {
        return { filtered: true };
      }

      return isActive
        ? { id, fio, isActive, isDeleted, kbm }
        : { id, fio: storedFio, isActive, kbm };
    })
    .filter((item) => !item.filtered);
};
