export const EOW_CALC_SET_APPLICATION = 'EOW_CALC_SET_APPLICATION';
export const EOW_CALC_UPD_APPLICATION = 'EOW_CALC_UPD_APPLICATION';
export const EOW_CALC_UPD_APPL_PARAMS = 'EOW_CALC_UPD_APPL_PARAMS';
export const EOW_CALC_SET_SELECTED_OFFER = 'EOW_CALC_SET_SELECTED_OFFER';
export const EOW_CALC_UPD_OFFER = 'EOW_CALC_UPD_OFFER';
export const EOW_CALC_AGREEMENT = 'EOW_CALC_AGREEMENT';

export const EOW_CALC_SELECT_OFFER_REQUEST = 'EOW_CALC_SELECT_OFFER_REQUEST';
export const EOW_CALC_AGREEMENT_REQUEST = 'EOW_CALC_AGREEMENT_REQUEST';
export const EOW_CALC_GET_POLICY_REQUEST = 'EOW_CALC_GET_POLICY_REQUEST';

export const EOW_CALC_CLIENT_DECLINE_REQUEST = 'EOW_CALC_CLIENT_DECLINE_REQUEST';
export const EOW_CALC_REFRESH_CALC_REQUEST = 'EOW_CALC_REFRESH_CALC_REQUEST';
export const EOW_CALC_REFRESH_CALCS_BY_APPS_IDS_REQUESTS =
  'EOW_CALC_REFRESH_CALCS_BY_APPS_IDS_REQUESTS';
export const EOW_CALC_ADD_EOW_ORDER_REQUEST = 'EOW_CALC_ADD_EOW_ORDER_REQUEST';
export const EOW_CALC_CANCELLATION_REQUEST = 'EOW_CALC_CANCELLATION_REQUEST';
export const EOW_CALC_RELOAD_DOCS_REQUEST = 'EOW_CALC_RELOAD_DOCS_REQUEST';

export const EOW_CALC_ADD_FILE = 'EOW_CALC_ADD_FILE';
export const EOW_CALC_DEL_FILE = 'EOW_CALC_DEL_FILE';

export const EOW_CALC_SET_FETCH = 'EOW_CALC_SET_FETCH';
export const EOW_CALC_SET_MAIN_FETCH = 'EOW_CALC_SET_MAIN_FETCH';
export const EOW_CALC_SET_NAMEPLATE = 'EOW_CALC_SET_NAMEPLATE';

export const EOW_CALC_SET_DEALER_LEGALS = 'EOW_CALC_SET_DEALER_LEGALS';
export const EOW_CALC_UPD_DEALER_LEGALS = 'EOW_CALC_UPD_DEALER_LEGALS';

export const EOW_CALC_SET_POPUP_DATA = 'EOW_CALC_SET_POPUP_DATA';

export const EOW_SET_RISKS = 'EOW_SET_RISKS';
export const EOW_UPDATE_RISKS = 'EOW_UPDATE_RISKS';

export const INS_REQUEST_PRELIST = 'insurance/calculation/prelist';
export const INS_SET_ID = 'insurance/calculation/set-id';
export const INS_SET_ERROR = 'insurance/calculation/set-error';

export const INS_DROP_ALL_ERRORS = 'insurance/calculation/drop-all-errors';
export const INS_DROP_ALL = 'insurance/calculation/drop-all';

export const INS_SET_IS_IN_CREDIT = 'insurance/calculation/set-is-in-credit';
export const INS_GET_BANKS_LIST = 'insurance/calculation/get-banks-list';
export const INS_SET_BANKS_LIST = 'insurance/calculation/set-banks-list';
export const INS_SET_SELECTED_BANK = 'insurance/calculation/set-selected-bank';

export const INS_REQUEST_BYORDER = 'insurance/calculation/request-by-order';
export const INS_SET_MULTIDRIVE = 'insurance/calculation/set-multidrive';

export const EOW_CALC_RECALC_RECUSTOMERS = 'EOW_CALC_RECALC_RECUSTOMERS';
