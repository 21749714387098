import styled from 'styled-components';
import { SmsSvg, PhoneSvg } from 'assets/img';

export const AvatarWrapper = styled.div`
  width: 120px;
  height: 120px;
  margin: 0 auto;
`;

export const UserWidgetName = styled.div`
  font-size: 14px;
  line-height: 18px;
  color: ${({ active }) => (active ? '#000000' : '#A8A8A8')};
`;

export const UserWidgetPhone = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  font-size: 12px;
  svg {
    &:not(:last-child) {
      margin-right: 10px;
    }
  }

  div {
    color: ${({ active }) => (active ? '#000000' : '#A8A8A8')};
  }
`;

export const UserWidgetActions = styled.ul`
  list-style: none;
  margin: 20px 0 0;
  padding: 0;
  font-size: 12px;
  li {
    margin: 0;
    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }
`;

export const SmsSvgStyled = styled(SmsSvg)`
  visibility: ${({ active }) => (active ? 'visible' : 'hidden')};
`;

export const PhoneSvgStyled = styled(PhoneSvg)`
  visibility: ${({ active }) => (active ? 'visible' : 'hidden')};
`;

export const Link = styled.a`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  color: #808080;
  cursor: ${(p) => (p.active ? 'pointer' : 'default')};
  text-decoration: ${({ underline }) => (underline ? 'underline' : '')};

  &:hover {
    color: ${({ underline }) => (underline ? '#000' : '#808080')};
    text-decoration: ${({ underline }) => (underline ? 'underline' : '')};
  }
`;

export const Id = styled.div`
  font-size: 12px;
  line-height: 14px;
  color: #a8a8a8;
  margin-top: 5px;
  margin-bottom: 15px;
`;
