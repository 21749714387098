import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams, useLocation, useRouteMatch } from 'react-router-dom';

import Widget from 'components/primitives/widget';
import UserWidgetAvatar from 'components/modules/Calculations/components/Loan/components/UserWidgetAvatar';
import { InputMask } from 'utils/inputMask';
import WhatsapLink from 'components/common/WhatsapLink';
import { phoneClickTrack } from 'metrika/applications';
import ClipboardCopy from 'components/common/ClipboardCopy';

import { useCustomerProvider } from './providers';
import {
  AvatarWrapper,
  UserWidgetActions,
  UserWidgetName,
  UserWidgetPhone,
  Link,
  Id,
} from './style';

export const UserWidget = ({ className = '', ...rest }) => {
  const params = useParams();
  const history = useHistory();
  const location = useLocation();
  const { path } = useRouteMatch();

  const lastCalculationData = useSelector((store) => store.calculator?.lastCalculationData);
  const dealId = useSelector((store) => store.deal?.id);
  const { anketaFetch, currentPersonId, persons } = useSelector((state) => state.anketa);
  const { customer: startPageCustomer, application: startPageApplication } = useSelector(
    (state) => state.startPage
  );

  const isApplication = path.includes('worksheet');
  const isNewOsageCredit = window.location.href.includes('/create-new');
  const isAssistance = path.includes('/assistance');
  const isInsurance = path.includes('/insurance');

  const selectAnketa = (state) => {
    if (path.includes('/assistance')) {
      return state.assistanceAnketa;
    } else if (path.includes('/insurance')) {
      return state.insuranceAnketa;
    }
  };

  const additionalProduct = useSelector(selectAnketa);

  const customer = useMemo(() => startPageCustomer || {}, [startPageCustomer]);
  const application = useMemo(() => startPageApplication || {}, [startPageApplication]);

  const role = useSelector((state) => state.user.role || -1);
  const [contentHidden, setContentHidden] = useState(false);

  const person = persons[currentPersonId];
  const isAnketaAvailable = Boolean(lastCalculationData || dealId);

  const { name, phone, isActive, isPhone } = useCustomerProvider(location, {
    anketaArgs: { anketaFetch, data: person },
    customerArgs: customer,
    additionalProduct,
  });

  const isShow = !!application?.ids?.credit;

  const id = application?.ids?.credit;

  const aplicationLinkShow =
    isApplication &&
    params.id &&
    application?.ids?.credit &&
    !isNewOsageCredit &&
    !path.includes('anketa');

  const onCLickHandler = () => {
    if (isAnketaAvailable) {
      history.push(`/worksheet/${params.id}/credit/anketa`);
    }
  };

  const phoneHandler = () => {
    phoneClickTrack();
  };

  useEffect(() => {
    setContentHidden([3406].includes(role));
  }, [role]);

  return (
    <Widget className={className} {...rest}>
      <AvatarWrapper>
        <UserWidgetAvatar avatarSize={120} iconSize={32} canEdit={!isAssistance && !isInsurance} />
      </AvatarWrapper>
      {isShow && <Id>{<ClipboardCopy value={id || ''}>{`ID: ${id || ''}`}</ClipboardCopy>}</Id>}
      <UserWidgetName active={isActive}>
        {name.split(' ').map((p, i) => (
          <React.Fragment key={p + i}>
            {p}
            <br />
          </React.Fragment>
        ))}
      </UserWidgetName>

      <UserWidgetPhone active={isPhone}>
        <WhatsapLink
          phone={contentHidden ? InputMask.CELLPHONE_PRIVATE_NEW[0].hidden : phone}
          onClick={phoneHandler}
        />
      </UserWidgetPhone>
      <UserWidgetActions>
        {aplicationLinkShow && (
          <li key="anketa">
            <Link onClick={onCLickHandler} active={isAnketaAvailable} underline={isAnketaAvailable}>
              Анкета клиента
            </Link>
          </li>
        )}
      </UserWidgetActions>
    </Widget>
  );
};
