import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import OutsideClickHandler from 'react-outside-click-handler';
import PulseLoader from 'react-spinners/PulseLoader';

import Tooltip from 'components/primitives/tooltip-new';

import Label from './components/Label';
import Value from './components/Value';
import Input from './components/Input';
import { toSelectValue } from './helper';

import {
  Wrapper,
  Selector,
  ChevronSolidStyled,
  DropDown,
  Content,
  List,
  Item,
  Text,
  Descr,
  Prompt,
  RightIcon,
  PulseLoaderStyled,
  SelectorContent,
  Check,
  CheckAllItem,
} from './style';

export const Select = ({
  id,
  name,
  list,
  label,
  disabled,
  required,
  defaultValue = null,
  value = null,
  title = null,
  search,
  onChange,
  onBlur,
  className,
  error,
  icon,
  style,
  multiselect = false,
  onlyid = true,
  fetch = false,
  onSearch = null,
  checked = false,
  checkedAll = false,
}) => {
  const [focused, setFocused] = useState(false);
  const [select, setSelect] = useState([]);
  const [alert, setAlert] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [isShowTooltip, setShowTooltip] = useState(false);

  const defVal = toSelectValue(defaultValue);
  const isShowCheckAll = Boolean(checkedAll && multiselect);

  const handleMouseEnter = () => {
    if (title) {
      setShowTooltip(true);
    }
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  const handleSearch = (item) => {
    if (searchString.length === 0) {
      return true;
    }

    if (onSearch) {
      return onSearch(searchString, item);
    }
    return item.value.toLowerCase().indexOf(searchString.toLowerCase()) > -1;
  };

  const handleBlur = (e) => {
    if (!focused) {
      return;
    }

    e.stopPropagation();
    setFocused(false);
    setSearchString('');

    if (defVal.length === 0 && required) {
      setAlert(true);
    }

    if (onlyid) {
      onBlur({
        [name]: multiselect ? select.map((s) => s?.id) : select[0]?.id,
      });
    } else {
      onBlur({ [name]: multiselect ? select : select[0] });
    }
  };

  const handleChange = (item) => {
    if (item.isParentItem) {
      return;
    }
    setAlert(false);
    if (!multiselect) {
      setFocused(false);
      setSearchString('');
    }

    let newSelection = [item];
    if (multiselect) {
      if (select.find((i) => i.id === item.id)) {
        newSelection = select.filter((s) => s.id !== item.id);
      } else {
        newSelection = [...select, item];
      }
    }

    setSelect(newSelection);

    if (onlyid) {
      onChange({
        [name]: multiselect ? newSelection.map((s) => s.id) : newSelection[0].id,
      });
    } else {
      onChange({ [name]: multiselect ? newSelection : newSelection[0] });
    }
  };

  const handleChangeAll = () => {
    setAlert(false);

    let newSelection = [];
    if (select.length !== list.length) {
      newSelection = [...list];
    }

    setSelect(newSelection);

    if (onlyid) {
      onChange({ [name]: newSelection.map((s) => s.id) });
    } else {
      onChange({ [name]: newSelection });
    }
  };

  const handleChangeInput = (e) => {
    setSearchString(e.target.value);
  };

  const selectorClick = () => {
    if (!disabled) {
      setFocused(!focused);
      setSearchString('');
    }
  };

  useEffect(() => {
    if (onlyid) {
      if (defVal.length > 0 && list) {
        const obj = list.filter((f) => defVal.find((dv) => dv === f.id) !== undefined);
        if (obj.length > 0) {
          setSelect(obj);
        }
      } else {
        setSelect([]);
      }
    } else {
      if (defVal.length > 0 && list) {
        const obj = list.filter((f) => !!defVal.find((dv) => dv.id === f.id));
        if (obj.length > 0) {
          setSelect(obj);
        }
      } else {
        setSelect([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue, list]);

  useEffect(() => {
    if (value) {
      setSelect(toSelectValue(value));
    }
  }, [value]);

  useEffect(() => {
    if (error) {
      setAlert(true);
    }
  }, [error]);

  return (
    <Wrapper
      id={id}
      className={className}
      style={style}
      focused={focused}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {!!title && <Tooltip isShow={isShowTooltip} text={title} indent={10} />}

      <OutsideClickHandler onOutsideClick={handleBlur}>
        <Selector
          disabled={disabled}
          focused={focused.toString()}
          select={select}
          required={!disabled && !select && alert}
          onClick={selectorClick}
        >
          <SelectorContent>
            <Label
              label={label}
              isFocused={focused}
              isSelect={!!select}
              isRequired={!!required}
              isDisabled={!!disabled}
              isAlert={alert}
              error={error}
            />
            <Value select={select} isHidden={!!(!select || !list)} />
            {!disabled && alert && <Prompt className="select-prompt">{error || ''}</Prompt>}
          </SelectorContent>
          <RightIcon>{icon ? icon : <ChevronSolidStyled />}</RightIcon>
        </Selector>
        {focused && (
          <DropDown>
            <Content>
              <Input
                searchString={searchString}
                isHidden={!search || !list || disabled}
                onChange={handleChangeInput}
              />

              {isShowCheckAll && (
                <CheckAllItem>
                  <Check
                    checked={select.length === list.length}
                    onClick={handleChangeAll}
                    disabled={!list || disabled}
                  >
                    <Text>Выбрать все</Text>
                  </Check>
                </CheckAllItem>
              )}

              <List>
                {list &&
                  list.filter(handleSearch).map((item, i) => (
                    <Item
                      key={`select_${i + 1}`}
                      selected={!checked && !!select.find((s) => s.id === item.id)}
                      onClick={() => handleChange(item)}
                      isOptional={item.isOptional}
                      isParentItem={item.isParentItem}
                    >
                      {checked && (!item.isParentItem || item.disabled) ? (
                        <Check
                          checked={!!select.find((s) => s.id === item.id) || item.disabled}
                          onClick={() => handleChange(item)}
                          disabled={item.disabled}
                        >
                          <Text>{item.value}</Text>
                          {item.description && <Descr>{item.description}</Descr>}
                        </Check>
                      ) : (
                        <>
                          <Text>{item.value}</Text>
                          {item.description && <Descr>{item.description}</Descr>}
                        </>
                      )}
                    </Item>
                  ))}
              </List>
            </Content>
          </DropDown>
        )}
        {fetch && (
          <PulseLoader css={PulseLoaderStyled} size={10} color={'#b5b5b5'} loading={true} />
        )}
      </OutsideClickHandler>
    </Wrapper>
  );
};

Select.propTypes = {
  name: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  label: PropTypes.any,
  list: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string,
  icon: PropTypes.any,
  search: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  error: PropTypes.string,
};

Select.defaultProps = {
  name: null,
  disabled: false,
  required: false,
  label: null,
  list: null,
  defaultValue: null,
  icon: null,
  search: false,
  onChange: () => {},
  onBlur: () => {},
  className: '',
  error: null,
};
