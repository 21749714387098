const DEFAULT_USER_PHONE = '+7 (___) __-__-__';
const DEFAULT_NAME = '';

export const getUserPhone = (person) =>
  (person && person.contacts && person.contacts.mobilePhone) || DEFAULT_USER_PHONE;

export const getUserName = (person) =>
  (person &&
    person.personalInfo &&
    `${person.personalInfo.lastName} ${person.personalInfo.firstName}`) ||
  DEFAULT_NAME;

export const AnketaProvider = (data) => {
  const phone = getUserPhone(data);
  const name = getUserName(data);
  const isPhone = phone !== DEFAULT_USER_PHONE;
  const isActive = name !== DEFAULT_NAME;

  const customerInfo = {
    alt: name,
    name,
    phone,
    customerId: undefined,
    applicationId: undefined,
    isActive,
    isPhone,
  };

  return customerInfo;
};
