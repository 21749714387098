import React from 'react';
import { Backdrop, Img } from './style';

export const Loader = ({ className = 'default_loader', show, color, fixed = false }) => {
  if (show) {
    return (
      <Backdrop color={color} className={className} fixed={fixed}>
        <Img alt="preloader" src="car-preloader.gif"></Img>
      </Backdrop>
    );
  }

  return null;
};
