import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
`;

export const Header = styled.div`
  width: 100%;
  display: inline-flex;
  flex-direction: row;
`;

export const Tab = styled.div`
  width: 100%;
  justify-content: space-between;
  cursor: pointer;
`;

export const Content = styled.div`
  display: ${(p) => (p.active ? 'block' : 'none')};
`;
