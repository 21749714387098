export const addParamsToUrl = (url, params = {}) => {
  try {
    const urlObj = new URL(url);
    Object.keys(params).forEach((name) => {
      if (params[name] !== undefined && params[name] !== null) {
        urlObj.searchParams.set(name, params[name]);
      }
    });

    return urlObj.href;
  } catch (_e) {
    return url;
  }
};

export const extractParamsFromUrl = (url, params = []) => {
  const retParams = {};

  try {
    const urlObj = new URL(url);
    params.forEach((name) => {
      retParams[name] = urlObj.searchParams.get(name);
      urlObj.searchParams.delete(name);
    });

    return { url: urlObj.href, params: retParams };
  } catch (_e) {
    return { url, params: retParams };
  }
};
