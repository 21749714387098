import styled from 'styled-components';

import * as UK from 'components/primitives/content';
import { PALETTE } from 'styles/constants';
import { RefreshSvg } from 'assets/img';
import { CelluarInput } from '../CelluarInput';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Input = styled(CelluarInput)`
  margin-bottom: 15px;
`;

export const Error = styled(UK.Paragraph)`
  margin-bottom: 15px;
  color: ${PALETTE.red1};
  text-align: center;
`;

export const Resend = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > * {
    margin: 0px;
  }
`;

export const ResendLink = styled(UK.Link)`
  color: ${PALETTE.black1};
  display: flex;
  align-items: center;
  line-height: 18px;
`;

export const RefreshIcon = styled(RefreshSvg)`
  width: 18px;
  height: 18px;
  margin-right: 8px;
`;
