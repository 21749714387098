import { MNEMONICS_HTML } from 'constants/index';

export const handleCommentary = (comment) => {
  let result = comment;
  const mnemonics = Object.keys(MNEMONICS_HTML);

  for (let i = 0; i < mnemonics.length; i++) {
    const key = mnemonics[i];
    if (result.includes(key)) {
      result = result.replaceAll(`${key}`, MNEMONICS_HTML[key]);
    }
  }
  return result.replaceAll('&amp;', '&');
};
