import request from 'utils/request';

export const fetchAds = async (application_id) => {
  try {
    const baseUrl = `${process.env.REACT_APP_GATEWAY_URL}`;
    let res = await request.post(
      `${baseUrl}/api/v1/application/${application_id}/send`,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      }
    );

    return res;
  } catch (err) {
    console.error(err.message);
  }
};
