import styled from 'styled-components';
import BankLogo from 'components/common/BankLogo';
import { GreenLabel, YellowLabel, RedLabel } from 'assets/img';
import { PALETTE } from 'styles/constants';

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
`;

export const LogoIco = styled(BankLogo)`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin: 0 10px;
`;

export const BanksInfoButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  border-bottom: ${(props) => (props.active ? `2px solid ${PALETTE.red1}` : 'inherit')};
`;

export const BanksLogoWraper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid ${PALETTE.grey9};
  margin-top: 24px;
  margin-bottom: 16px;
  overflow-x: auto;
  scrollbar-width: thin;
  scrollbar-color: ${PALETTE.grey11} ${PALETTE.white};
  cursor: grab;

  &.dragging {
    cursor: grabbing;
  }
`;

export const BanksInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;

export const LabelWrapper = styled.div`
  width: 14px;
  height: 14px;
  margin-left: 5px;
`;

export const StyledGreenLabel = styled(GreenLabel)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledYellowLabel = styled(YellowLabel)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledRedLabel = styled(RedLabel)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ShowBanksButton = styled(BanksInfoButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${PALETTE.white};
`;

export const ShowBanksLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin: 0 10px;
  background-color: ${PALETTE.grey11};
`;
