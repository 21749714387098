import React, { useEffect, useState, useCallback } from 'react';

import { getAppVersion } from 'data-providers/versionAppProvider';

import * as S from './style';

const CHECK_TIMEOUT = 1000 * 60 * 2;

export const AppVersionReloader = () => {
  const [versionData, setVersionData] = useState(null);
  const [isShowSoftUpdate, setIsShowSoftUpdate] = useState(false);

  const onCheckVersion = useCallback(async () => {
    const newVersionData = await getAppVersion();
    const oldVersionData = versionData;

    if (!newVersionData) {
      return;
    }
    if (oldVersionData && oldVersionData.version === newVersionData.version) {
      return;
    }

    setVersionData(newVersionData);
    if (!oldVersionData) {
      return;
    }

    if (newVersionData.update === 'hard') {
      onRealod();
      return;
    }

    if (newVersionData.update === 'soft') {
      setIsShowSoftUpdate(true);
      return;
    }
  }, [versionData]);

  const onRealod = () => {
    window.location.reload();
  };

  useEffect(() => {
    onCheckVersion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const intervalId = setInterval(onCheckVersion, CHECK_TIMEOUT);
    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onCheckVersion]);

  if (!isShowSoftUpdate) {
    return null;
  }

  return (
    <S.Container>
      <S.Title>
        Система
        <br />
        обновилась
      </S.Title>
      <S.Text>
        Мы стали лучше!
        <br />
        Сохраните данные и обновите платформу
      </S.Text>

      <S.Button onClick={onRealod}>Обновить</S.Button>
    </S.Container>
  );
};
