export const MESS_REQUIRED_FIELD = 'Обязательное поле';
export const MESS_EMAIL = 'Некорректный адрес';
export const MESS_INDEX = 'Некорректный индекс';
export const MESS_PHONE = 'Некорректный телефон';
export const MESS_DATE = 'Упс! Такой даты на планете Земля не существует';
export const MESS_SERNUMBER = 'Некорректная серия или номер';
export const MESS_PASSPORT_CODE = 'Некорректный формат кода';
export const MESS_BEFORE_14 = 'Не ранее 14 лет';
export const MESS_NEED_TO_CHANGE_PASSPORT = 'Паспорт подлежит замене';
export const MESS_USE_CYRILLIC = 'Используйте буквы русского алфавита';
export const MESS_NOT_ENOUTH = 'Неполные данные';
export const MESS_NOT_VALID = 'Некорректные данные';
export const MESS_INN_NOT_VALID = 'Проверьте правильность номера ИНН';
export const MESS_SERNUMBER_MVD = 'Паспорт не найден в реестре МВД';
export const MESS_SURNAME = 'Укажите фамилию';
export const MESS_NAME = 'Укажите имя';
export const MESS_SURNAME_CONTACT = 'Укажите фамилию контактного лица';
export const MESS_NAME_CONTACT = 'Укажите имя контактного лица';
export const ORG_SAME_PHONE_NUMBER = 'Телефон организации<br>не должен совпадать с мобильным';
export const MESS_MAIN_PROFIT_MIN = 'Основной доход должен быть не менее 1000 рублей';
