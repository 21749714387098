import React from 'react';
import * as S from './style';

const fn = () => {};

const ErrorPopup = ({ errorMessage = 'Ошибка', buttonText = 'Ok', onClose = fn, ...rest }) => {
  return (
    <S.PopupStyled onClose={onClose} {...rest}>
      <S.Wrapper>
        <S.Title>Ой, что-то пошло не так</S.Title>
        <S.Body>{errorMessage}</S.Body>
        <S.ButtonOk onClick={onClose}>{buttonText}</S.ButtonOk>
      </S.Wrapper>
    </S.PopupStyled>
  );
};

export default ErrorPopup;
