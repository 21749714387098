import React, { useState, useEffect } from 'react';
import { InputMask, unmaskValue } from 'utils/inputMask';

import { isVin } from './helper';
import { InputMaskStyled, SteeringWheelCheckbox } from './style';

const CarHeadDetail = ({
  className = '',
  carInstance = {},
  error = null,
  isDisabled = false,
  onChange = () => {},
  onBlur = () => {},
  withSteeringWheel = false,
  onSteeringWheelChange = () => {},
}) => {
  const [value, setValue] = useState(carInstance.vin || '');

  useEffect(() => {
    setValue(carInstance.vin || '');
  }, [carInstance.vin]);

  const changeHandler = (event) => {
    let vin = '';
    let regNumber = '';
    const val = unmaskValue(event.vin);

    if (isVin(val)) {
      vin = val;
    } else {
      regNumber = val;
    }

    setValue((vin || regNumber).toUpperCase());
    onChange('vin');
  };

  const blurHandler = (event) => {
    const val = unmaskValue(event.vin);
    onBlur({ vin: val });
  };

  const clickHandler = (e) => {
    e.stopPropagation();
  };

  const steeringWheelChangeHandler = ({ steeringWheel }) => {
    onSteeringWheelChange(steeringWheel);
  };

  return (
    <div className={className}>
      <InputMaskStyled
        isControlledComponent
        name="vin"
        label="VIN автомобиля"
        val={value}
        mask={InputMask.VIN_NEW}
        unmask
        error={error?.vin}
        onClick={clickHandler}
        onChange={changeHandler}
        onBlur={blurHandler}
        disabled={isDisabled || carInstance.steeringWheel === 'right'}
        dataTest="carVin"
      />

      {withSteeringWheel && (
        <SteeringWheelCheckbox
          name="steeringWheel"
          checked={carInstance.steeringWheel !== 'left'}
          label="Праворульный авто"
          onClick={steeringWheelChangeHandler}
          disabled={isDisabled}
        />
      )}
    </div>
  );
};

CarHeadDetail.displayName = 'CarHeadDetail';

export default CarHeadDetail;
