import styled from 'styled-components';
import { InnerGrid, BlockHeader, InputMaskStyled } from '../../style';

export const GridStyled = styled(InnerGrid)`
  grid-template-areas:
    'aph aph aph aph aph aph'
    'cp cp en en bn bn'
    'mas mas ev ev gn gn'
    'ml ml . . . .';

  grid-area: params;
`;

export const AutoParamsHeader = styled(BlockHeader)`
  grid-area: aph;
`;

export const Power = styled(InputMaskStyled)`
  grid-area: cp;
`;

export const EngineNumber = styled(InputMaskStyled)`
  grid-area: en;
`;

export const BodyNumber = styled(InputMaskStyled)`
  grid-area: bn;
`;

export const Mass = styled(InputMaskStyled)`
  grid-area: mas;
`;

export const EngineVolume = styled(InputMaskStyled)`
  grid-area: ev;
`;

export const GlonasNumber = styled(InputMaskStyled)`
  grid-area: gn;
`;
