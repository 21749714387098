export const getNodePosition = (node) => {
  if (!node) {
    return null;
  }

  return node.getBoundingClientRect();
};

export const getNodePageY = (node) => window.pageYOffset + getNodePosition(node).top;

export const scrollTo = (x, y) => {
  window.scrollTo(x, y);
};

export const scrollToNodeY = (node, indent = 0) => {
  if (!node) {
    throw new TypeError('Не передан элемент в утилиту scrollToNodeY');
  }

  scrollTo(0, getNodePageY(node) + indent);
};
