import {
  DEALS_LOADED,
  DEALS_ADDED,
  DEALS_SET_FETCH,
  SELECT_DEAL,
  DROP_DEAL,
  SET_PAGINATOR,
  LOAD_DEALS,
  UPDATE_DEAL,
  DEAL_COMMISSIONS_LOADED,
  DEAL_COMMISSIONS_FETCHING,
  DEALS_ADDITIONAL_DATA_LOADED,
  DEALS_ADDITIONAL_DATA_ADDED,
} from './constants';

const initState = {
  selected: {},
  data: {},
  dealsStatus: {},
  pages: {}, // {applicId[int]:{page[int], total[int], perPage[int]}}
  states: {},
  fetch: false,
  commissionsFetching: false,
};

const setSelected = (state, { applicId, dealId }) => {
  // может быть выбран только один
  const selected = { ...state.selected };
  selected[applicId] = dealId;
  return selected;
};

const setCommissions = (state, { applicId, commissions }) => {
  const data = { ...state.data };

  Object.keys(commissions).forEach((dealId) => {
    if (undefined !== data[applicId]) {
      const dealIndex = data[applicId].findIndex(
        (element) => parseInt(element.id) === parseInt(dealId)
      );

      if (dealIndex > -1) {
        data[applicId][dealIndex].commissions = commissions[dealId];
      }
    }
  });

  return data;
};

const setPaginator = (state, { applicId, paginator }) => {
  const data = { ...state.pages };
  data[applicId] = paginator;
  return data;
};

const drop = (state, { applicId }) => {
  const data = { ...state.data };
  const selected = { ...state.selected };
  const pages = { ...state.pages };
  const dealsStatus = { ...state.dealsStatus };

  delete data[applicId];
  delete selected[applicId];
  delete pages[applicId];
  delete dealsStatus[applicId];

  return { data, selected, pages, dealsStatus };
};

const updateDeal = (state, { applicationId, deal }) => {
  const stored = { ...state.data };
  const application = stored[`${applicationId}`];
  const index = application.findIndex((d) => `${d.id}` === `${deal.id}`);
  application.splice(index, 1, deal);
  return { ...stored, [`${applicationId}`]: application };
};

export default function (state = initState, action) {
  switch (action.type) {
    case DEALS_LOADED:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.applicId]: [...action.payload.deals],
        },
      };
    case DEALS_ADDED:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.applicId]: [
            ...(state.data[action.payload.applicId] || []),
            ...action.payload.deals,
          ],
        },
      };
    case DEALS_ADDITIONAL_DATA_LOADED:
      return {
        ...state,
        dealsStatus: {
          ...state.dealsStatus,
          [action.payload.applicId]: { ...action.payload.dealsList },
        },
      };
    case DEALS_ADDITIONAL_DATA_ADDED:
      return {
        ...state,
        dealsStatus: {
          ...state.dealsStatus,
          [action.payload.applicId]: {
            ...(state.dealsStatus[action.payload.applicId] || {}),
            ...action.payload.dealsList,
          },
        },
      };
    case DEALS_SET_FETCH:
      return { ...state, fetch: action.payload };
    case DEAL_COMMISSIONS_FETCHING:
      return { ...state, commissionsFetching: action.payload };
    case SELECT_DEAL:
      return { ...state, selected: setSelected(state, action.payload) };
    case DROP_DEAL:
      // eslint-disable-next-line no-case-declarations
      const dropped = drop(state, action.payload);
      return { ...state, ...dropped };
    case SET_PAGINATOR:
      return { ...state, pages: setPaginator(state, action.payload) };
    case UPDATE_DEAL:
      return { ...state, data: updateDeal(state, action.payload) };
    case DEAL_COMMISSIONS_LOADED:
      return { ...state, data: setCommissions(state, action.payload) };
    default:
      return state;
  }
}

export function selectDeal({ applicId, dealId }) {
  return {
    type: SELECT_DEAL,
    payload: { applicId, dealId },
  };
}

export function dropDeals({ applicId, dealId }) {
  return {
    type: DROP_DEAL,
    payload: { applicId, dealId },
  };
}

export function loadDeals({ applicId, page, id }) {
  return {
    type: LOAD_DEALS,
    payload: { applicId, page, id },
  };
}

export function updateDeals({ applicationId, dealId, data }) {
  return {
    type: UPDATE_DEAL,
    payload: { applicationId, dealId, data },
  };
}
