import { createSelector } from 'reselect';

const catalogSelector = (store) => store.applications.catalog;

export const statusMapSelector = createSelector(catalogSelector, (catalog) =>
  catalog['hydra:member'].reduce(
    (acc, catalogItem) => acc.set(catalogItem?.state || '', catalogItem?.stateSecondName || ''),
    new Map()
  )
);
