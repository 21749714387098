import React, { useEffect } from 'react';
import { Switch, Route, useRouteMatch, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import {
  startPageCreateEnsurance,
  dropPageCreateEnsurance,
  assistanceClearError,
  insuranceClearError,
  getCustomer,
  dropClientId,
} from 'store/startPage';
import { assistanceCalcAllToInitState } from 'store/assistanceCalculation';
import { spGetApplicationId, insuranceAllToInitState } from 'store/insuranceCalculation';
import { resetDeal } from 'store/deal';
import { getCarInstanceById } from 'store/carInstance';
import ErrorPopup from 'components/primitives/error-popup';

import Calculation from './components/Calculation/Calculation';
import AssistanceAnketa from '../AssistanceAnketa';
import InsuranceAnketa from '../InsuranceAnketa';
import WithValidateCarDetail from '../WithValidateCarDetail';
import {
  schemaCarDetailAssistance,
  schemaCarDetailInsurance,
} from 'components/modules/Calculations/components/CarDetail/validator/validations';

const Client = ({ eosagoRef, onChangeCarModel = () => {} }) => {
  const reduxDispatch = useDispatch();
  const { ensuranceLoaded } = useSelector((store) => store.startPage);

  let location = useLocation();
  let { path, params } = useRouteMatch();
  const isNewMode = location.pathname.includes('create-new');

  const dealerId = useSelector((state) => state.user.dealer);
  const carInstanceId = useSelector((state) => state.carInstance?.carInstance?.id);
  const customerId = useSelector((state) => state.startPage?.customer?.id);

  const { error: startPageError } = useSelector((state) => state.startPage);

  useEffect(
    () => () => {
      if (!path.includes('/worksheet/create-new')) {
        reduxDispatch(dropPageCreateEnsurance());
      }
      reduxDispatch(assistanceCalcAllToInitState());
      reduxDispatch(insuranceAllToInitState());
      reduxDispatch(resetDeal());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    const truePath = path.includes('/worksheet/:id/insurance') && !isNaN(params?.id);

    if (truePath && dealerId && carInstanceId && customerId) {
      reduxDispatch(spGetApplicationId({ dealerId, carInstanceId, customerId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealerId, customerId, carInstanceId]);

  useEffect(() => {
    if (path.includes('/worksheet/create-new')) {
      localStorage.removeItem('carinstanceId');
      localStorage.removeItem('customerId');
      localStorage.removeItem('worksheet');
      reduxDispatch(dropClientId());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  useEffect(() => {
    if (!dealerId) {
      return;
    }

    if (isNewMode && !carInstanceId && !customerId) {
      !ensuranceLoaded && reduxDispatch(startPageCreateEnsurance());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNewMode, dealerId, carInstanceId, customerId]);

  useEffect(() => {
    if (!dealerId) {
      return;
    }

    if (isNewMode) {
      return;
    }

    if (!customerId) {
      const _customerId = localStorage.getItem('customerId');

      if (_customerId) {
        reduxDispatch(getCustomer({ customerId: _customerId }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId, dealerId, isNewMode]);

  useEffect(() => {
    if (!dealerId) {
      return;
    }

    if (isNewMode) {
      return;
    }

    if (!customerId) {
      const _carInstanceId = localStorage.getItem('carinstanceId');

      if (_carInstanceId) {
        reduxDispatch(getCarInstanceById(_carInstanceId));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealerId, isNewMode]);

  if (startPageError) {
    return (
      <ErrorPopup
        errorMessage={startPageError}
        onClose={() => {
          reduxDispatch(assistanceClearError());
          reduxDispatch(insuranceClearError());
        }}
      />
    );
  }

  return (
    <Switch>
      <Route exact path={'/worksheet/:id/assistance/anketa'}>
        <WithValidateCarDetail
          WrappedComponent={AssistanceAnketa}
          props={{ id: customerId }}
          schema={schemaCarDetailAssistance}
        />
      </Route>
      <Route exact path={'/worksheet/:id/insurance/anketa'}>
        <WithValidateCarDetail
          WrappedComponent={InsuranceAnketa}
          schema={schemaCarDetailInsurance}
          props={{ id: customerId }}
        />
      </Route>
      <Route
        path={[
          '/worksheet/create-new/insurance',
          '/worksheet/create-new/calculator',
          '/worksheet/:id/home',
          '/worksheet/:id/calculator',
          '/worksheet/:id/loan-issue',
          '/worksheet/:id/assistance',
          '/worksheet/:id/insurance',
          '/worksheet/:id',
        ]}
        exact
      >
        <Calculation eosagoRef={eosagoRef} onChangeCarModel={onChangeCarModel} />
      </Route>
    </Switch>
  );
};

export default Client;
