import React from 'react';

import VinCheckInput from '../../VinCheckInput';

import * as S from '../style';

export default function VinCheckContainer({ title, width, height, buttonText }) {
  return (
    <S.WineCheckWraper width={width} height={height}>
      <VinCheckInput title={title} buttonText={buttonText} />
    </S.WineCheckWraper>
  );
}
