export const INSURANCE_WORKFLOW_STATES = {
  unknown: 'unknown',
  new: 'new',
  offers_requested: 'offers_requested',
  offers_received: 'offers_received',
  offer_selected: 'offer_selected',
  offers_selected: 'offers_selected',
  offers_declined: 'offers_declined',
  offers_error: 'offers_error',
  agreement_requested: 'agreement_requested',
  agreement_received: 'agreement_received',
  agreement_succeed: 'agreement_succeed',
  agreement_declined: 'agreement_declined',
  agreement_error: 'agreement_error',
  waiting_signing_code: 'waiting_signing_code',
  code_confirmed: 'code_confirmed',
  issue_requested: 'issue_requested',
  issued: 'issued',
  issue_decline: 'issue_decline',
  issue_error: 'issue_error',
  reload_documents_requested: 'reload_documents_requested',
  reload_documents_error: 'reload_documents_error',
  client_decline: 'client_decline',
  declined: 'declined',
  error: 'error',
  cancelation_requested: 'cancelation_requested',
  canceled: 'canceled',
  cancelation_error: 'cancelation_error',
};
