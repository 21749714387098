import React from 'react';
import { YMInitializer } from 'react-yandex-metrika';

export const Metrika = () => {
  if (process.env.NODE_ENV === 'production') {
    return (
      <YMInitializer
        accounts={[97832288]}
        options={{
          webvisor: true,
          clickmap: true,
          trackLinks: true,
          accurateTrackBounce: true,
        }}
        version="2"
      />
    );
  } else {
    return null;
  }
};
