import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { AddLightBlue, PersonSvg } from 'assets/img';

import * as S from './style';

export const AddDriverButton = ({ persons = {}, creditId, title = ``, onClick = () => {} }) => {
  const ref = useRef();

  const trustees =
    persons && creditId && creditId in persons ? Object.values(persons[creditId].trustees) : [];
  const [showDropDown, setShowDropDown] = useState(false);
  const drivers = useSelector((state) => state.insuranceAnketa?.drivers);
  const nodes = useSelector((state) => state.insuranceAnketa?.nodes);

  const personsArr = [];
  for (let trustee of trustees) {
    let finded = false;
    if (nodes) {
      for (let node of nodes) {
        if (String(node.customerId) === String(trustee.id)) {
          finded = true;
          break;
        }
      }
    }
    if (finded === false && drivers) {
      for (let driver of drivers) {
        if (String(driver.id) === String(trustee.id)) {
          finded = true;
          break;
        }
      }
    }
    if (finded === false) {
      personsArr.push(trustee);
    }
  }

  personsArr.sort(({ fullName: fio1 }, { fullName: fio2 }) => {
    if (fio1 < fio2) {
      return -1;
    }
    if (fio1 > fio2) {
      return 1;
    }
    return 0;
  });

  const clickHandler = (evt, driver = null) => {
    evt.target.blur();

    if (ref?.current) {
      ref.current.blur();
    }

    onClick(driver);
  };

  const buttonClick = (evt) => {
    evt.target.blur();
    setShowDropDown(!showDropDown);
  };

  const buttonContainerClickHandler = (evt) => {
    if (personsArr.length > 0) {
      buttonClick(evt);
      return;
    }

    clickHandler(evt);
  };

  return (
    <S.ButtonContainer onClick={buttonContainerClickHandler} ref={ref}>
      <S.IconWrapper>
        <AddLightBlue />
      </S.IconWrapper>
      <S.Title>{title}</S.Title>
      {showDropDown && personsArr.length > 0 && (
        <S.DropDown>
          {personsArr.map((person) => {
            return (
              <S.DropDownItem
                key={person.id}
                onClick={(evt) => {
                  clickHandler(evt, person);
                }}
              >
                <PersonSvg />
                <span>{person.fullName}</span>
              </S.DropDownItem>
            );
          })}
          <S.DropDownItem onClick={clickHandler}>
            <PersonSvg />
            <span>Новый водитель</span>
          </S.DropDownItem>
        </S.DropDown>
      )}
    </S.ButtonContainer>
  );
};

AddDriverButton.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
};
