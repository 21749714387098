import styled from 'styled-components';

import { FONT, PALETTE } from 'styles/constants';

export const Text = styled.h1`
  font-family: ${FONT.Roboto};
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: ${PALETTE.black};
`;
