const localeCompareForFirstRussianYo = (secondLowerCaseLetter) => {
  if (secondLowerCaseLetter === 'ё') {
    return 0;
  }
  if (secondLowerCaseLetter <= 'е') {
    return 1; // ё > е-а
  }

  return -1;
};

export const dealersSort = ({ id: id1, name: name1 }, { id: id2, name: name2 }) => {
  // support only english and russian locale
  let index = 0;
  let compareResult = 0;

  while (!(index >= name1.length || index >= name2.length)) {
    const letter1 = name1[index].toLowerCase();
    const letter2 = name2[index].toLowerCase();

    if (letter1 === 'ё') {
      compareResult = localeCompareForFirstRussianYo(letter2);
      break;
    }
    if (letter2 === 'ё') {
      compareResult = -localeCompareForFirstRussianYo(letter1);
      break;
    }

    if (letter1 < letter2) {
      compareResult = -1;
      break;
    }
    if (letter1 > letter2) {
      compareResult = 1;
      break;
    }

    index++;
  }

  return compareResult || id1 - id2;
};
