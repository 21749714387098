import * as actions from './constants';
import { initialState } from './initialState';

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.SET_VIN_INFO_FROM_MERCURY: {
      return { ...state, vinInfo: action.payload };
    }

    default:
      return state;
  }
}

export function setVinInfoFromMercury(payload) {
  return {
    type: actions.SET_VIN_INFO_FROM_MERCURY,
    payload,
  };
}

export const isBannersOpen = (userId) => {
  const closedBanners = JSON.parse(localStorage.getItem(actions.STORAGE_KEY)) || [];
  return !closedBanners.includes(userId);
};

export const closeBanners = (userId) => {
  const closedBanners = JSON.parse(localStorage.getItem(actions.STORAGE_KEY)) || [];
  if (!closedBanners.includes(userId)) {
    closedBanners.push(userId);
    localStorage.setItem(actions.STORAGE_KEY, JSON.stringify(closedBanners));
  }
};

export const openBanners = (userId) => {
  let closedBanners = JSON.parse(localStorage.getItem(actions.STORAGE_KEY)) || [];
  closedBanners = closedBanners.filter((id) => id !== userId);
  localStorage.setItem(actions.STORAGE_KEY, JSON.stringify(closedBanners));
};
