export const deleteGaps = (priceString = '') => priceString.replace(/\s/g, '');

export const convertPriceToString = (price = '') => {
  const stringNumber = `${price}`;
  const [whole, rest] = stringNumber.split('.');

  return Array.from(whole)
    .reverse()
    .reduce((acc, letter, index, array) => {
      acc.push(letter);

      if (index % 3 === 2 && index !== array.length - 1) {
        acc.push(' ');
      }

      return acc;
    }, [])
    .reverse()
    .join('')
    .concat(rest ? `.${rest}` : '');
};

export const getPriceWithPenny = (price = '') => {
  const stringNumber = `${Math.ceil(Number(price) || 0)}`;
  const penny = stringNumber.slice(-2);
  const rubles = stringNumber.slice(0, -2);

  return { rubles: convertPriceToString(rubles.length === 0 ? '0' : rubles), penny };
};

export const convertCoinsToString = (coins = 0, penny = true) => {
  if (penny) {
    return new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(
      Number(coins / 100).toFixed(2)
    );
  } else {
    return `${Math.trunc(Number(coins)).toLocaleString('ru-RU')} ₽`;
  }
};

export const cutPrice = (price = 0) => {
  const cost = Number(price) || 0;
  return cost > 1000
    ? { price: Math.floor(cost / 1000), label: 'т.руб.' }
    : { price: cost, label: 'руб.' };
};
