import { v4 as uuidv4 } from 'uuid';

const uuidDecorator = () => {
  let uuid = uuidv4();

  return {
    get: function () {
      return uuid;
    },
    set: function () {
      uuid = uuidv4();
    },
  };
};

export const uuidObject = uuidDecorator();
