import { genderReversMap } from './genderHelper';
import * as MapperHelper from './mapperHelper';

export const frontToBackCustomer = (fc, model = {}) => {
  model.personalInformation = {
    ...model?.personalInformation,
    ...MapperHelper.fioSplit(fc.fio),
    genderSysName: genderReversMap[fc.gender]?.toLowerCase() ?? genderReversMap[0],
    birthDate: MapperHelper.dateFormmatter(fc.bday, true),
  };

  model.contacts = {
    ...model?.contacts,
    mobilePhone: fc.insurerPhone,
    email: fc.insurerEmail,
  };

  model.passport = {
    ...model?.passport,
    ...MapperHelper.passportSplit(fc.insurerPassportSerialNum),
    issueDate: MapperHelper.dateFormmatter(fc.insurerPassportDate, true),
    issuerCode: fc.insurerIssuerCode,
    issuer: fc.insurerIssuer,
    birthPlace: fc.insurerBirthPlace,
  };

  model.registrationAddress = {
    ...model?.registrationAddress,
    address: {
      ...model?.registrationAddress?.address,
      ...fc.insurerDadataInfo,
      indexNumber: fc.insurerIndex,
      fiasValue: fc.insurerAddress ?? '',
      buildingNumber: fc.insurerDadataInfo?.building,
    },
  };

  model.secondDocument = model?.secondDocument || {};

  model.secondDocument = {
    ...model?.secondDocument,
    currentSecondDocumentTypeSysName:
      model?.secondDocument.currentSecondDocumentTypeSysName || '2nd_driver',
  };

  const { series = '', number = '' } = MapperHelper.splitDriverLicense(fc.licenseSeriaNum) || {};
  const { series: previousSeries = '', number: previousNumber = '' } =
    MapperHelper.splitDriverLicense(fc.prevLicenseSeriaNum) || {};

  const issueDate = MapperHelper.dateFormmatter(fc.licenseDate, true) || '';
  const startDate = MapperHelper.dateFormmatter(fc.prevLicenseDate, true) || '';

  const isDriver = !Object.prototype.hasOwnProperty.call(fc, 'isDriver') || fc.isDriver;

  if (isDriver) {
    model.secondDocument.driverLicense = {
      ...model.secondDocument.driverLicense,
      series,
      number,
      issueDate,
      previousSeries,
      previousNumber,
      previousIssueDate: startDate,
      startDate,
      id: null,
    };
  }

  return model;
};
