/* eslint-disable no-undef */
import React from 'react';
import { NavLink } from 'react-router-dom';
import PulseLoader from 'react-spinners/PulseLoader';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { assistanceCalcAllToInitState } from 'store/assistanceCalculation';
import { insuranceAllToInitState } from 'store/insuranceCalculation';
import { isUseNewCalculator } from 'store/user/selectors';
import { clearCustomer, storeItem } from 'store/startPage';
import { clearCarInstance } from 'store/carInstance';
import { dropLastCalculationData } from 'store/calculator';
import { resetDeal } from 'store/deal';

const ACTIVE_CLASS = 'report-page-menu__link-active';
const DEF_CLASS = 'submenu-link';

const clearData = (dispatch) => {
  dispatch(resetDeal());
  dispatch(clearCarInstance());
  dispatch(clearCustomer());
  dispatch(dropLastCalculationData());
  localStorage.removeItem('carinstanceId');
  localStorage.removeItem('customerId');
  dispatch(insuranceAllToInitState());
  dispatch(assistanceCalcAllToInitState());
};

const linksData = [
  {
    title: 'Кредит',
    to: `${process.env.REACT_APP_STAGE_URL}/#calculation`,
    activeClass: ACTIVE_CLASS,
    isUseNewCalculator: false,
    roles: [90],
    dataTest: 'createCredit',
  },
  {
    title: 'Кредит',
    to: `${process.env.REACT_APP_STAGE_URL}/#create_new`,
    activeClass: ACTIVE_CLASS,
    isUseNewCalculator: false,
    roles: [91, 3197],
    dataTest: 'createCredit',
  },
  {
    title: 'Кредит',
    to: `/worksheet/create-new/calculator`,
    activeClass: ACTIVE_CLASS,
    isUseNewCalculator: true,
    roles: [90, 91, 3197],
    dataTest: 'createCredit',
    beforeClick: (link, dispatch) => {
      clearData(dispatch);
    },
  },
];

const OrdersLink = ({ onClick }) => {
  const history = useHistory();
  const reduxDispatch = useDispatch();
  const { ensuranceLoaded } = useSelector((store) => store.startPage);
  const userRole = useSelector((store) => store.user.role);
  const dealer = useSelector((store) => store.user.dealers.find((d) => d.id === store.user.dealer));
  const useCalc = useSelector(isUseNewCalculator);

  const { useStartPage: isShowStartPage } = dealer || {};
  const grantNewLane = [91, 3197].includes(userRole);

  const onClickHandler = (link) => (e) => {
    e.preventDefault();
    if (link.beforeClick) {
      link.beforeClick(link, reduxDispatch);
    }
    onClick(link.to);
  };

  const onTabClickHandler = (e, isInsurance) => {
    e.preventDefault();
    reduxDispatch(insuranceAllToInitState());
    reduxDispatch(assistanceCalcAllToInitState());
    reduxDispatch(storeItem([]));
    clearData(reduxDispatch);
    history.push(`/worksheet/create-new/${isInsurance ? 'insurance' : 'assistance'}`);
  };

  const linksElements = (userRole) =>
    linksData
      .filter((link) => link.isUseNewCalculator === useCalc)
      .filter((link) => link.roles.includes(userRole))
      .map((link, indx) => {
        if (indx === 0) {
          return (
            <a
              onClick={onClickHandler(link)}
              className={DEF_CLASS}
              key={indx}
              href={link.to}
              data-test={link.dataTest}
            >
              {link.title}
            </a>
          );
        }
        return (
          <NavLink
            key={`${link.to}${indx}`}
            to={link.to}
            className={DEF_CLASS}
            data-test={link.dataTest}
          >
            {link.title}
          </NavLink>
        );
      });

  /*
  useEffect(() => {
    if (loanLoaded && applicationId) {
      history.push(`/worksheet/${applicationId}`);
    }
  }, [loanLoaded, applicationId]);
  */

  return (
    <>
      <div className="nav-group top" style={{ marginTop: 52 }}>
        {linksElements(userRole)}
        {isShowStartPage && grantNewLane && (
          <>
            <a
              href="/worksheet/create-new"
              onClick={onTabClickHandler}
              className={DEF_CLASS}
              style={{ position: 'relative' }}
            >
              {ensuranceLoaded ? (
                <PulseLoader size={10} color={'#b5b5b5'} loading={true} />
              ) : (
                'Выпустить карту'
              )}
            </a>
            <a
              href="/worksheet/create-new"
              onClick={(e) => {
                onTabClickHandler(e, true);
              }}
              className={DEF_CLASS}
              style={{ position: 'relative' }}
            >
              {ensuranceLoaded ? (
                <PulseLoader size={10} color={'#b5b5b5'} loading={true} />
              ) : (
                'Выпустить КАСКО'
              )}
            </a>
          </>
        )}
      </div>
      <div className="nav-group middle"></div>
      <div className="nav-group bottom"></div>
    </>
  );
};

export default OrdersLink;
