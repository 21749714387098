import React, { useEffect, useState, useRef, useImperativeHandle, forwardRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { uploadFiles } from 'data-providers/mainAnketaProvider';
import { getRecognizeFile } from 'data-providers/documentProvider';
import {
  addWaitingFile,
  checkRecognizedFiles,
  removeWaitingFile,
} from 'store/fileRecognizer/actions';
import { useToasts } from 'react-toast-notifications';
import { DragDropStyled } from './style';

const validFileFormats = ['JPG', 'JPEG', 'PDF', 'PNG'];

export const FileUpload = forwardRef(
  (
    { applicationId, params, onLoadFiles, loading, small, multiple = false, classification },
    ref
  ) => {
    const [files, setFiles] = useState(null);
    const [total, setTotal] = useState(0);
    const [process, setProcess] = useState(null);
    const [intrvalCheck, setIntervalCheck] = useState(null);
    const dragDropRef = useRef();
    const { addToast } = useToasts();
    const waitingFiles = useSelector((state) => state.fileRecognizer.waitingFiles);
    const rootDispatch = useDispatch();

    const handleFileUpload = (validFiles) => {
      const formData = new FormData();
      validFiles.forEach((file) => {
        formData.append('file[]', file);
      });

      if (classification) {
        params.classification = classification;
      }

      formData.append(
        'payload',
        new Blob([JSON.stringify(params)], { type: 'application/json' }),
        'payload.json'
      );

      setProcess(1);
      uploadFiles({ applicationId, formData })
        .then((res) => {
          setProcess(2);
          setTotal(total + res.length);

          res.forEach((r) => rootDispatch(addWaitingFile({ id: r.id, uuid: r.uuid })));
          rootDispatch(checkRecognizedFiles());
          const timer = setInterval(() => {
            getRecognizeFile({ id: res.map((r) => r.uuid) })
              .then((files) => {
                if (files.length > 0) {
                  files.forEach(
                    ({ id, status }) =>
                      status === 'PROCESSED' && rootDispatch(removeWaitingFile({ uuid: id }))
                  );
                }
              })
              .catch((err) => {
                clearTimeout(timer);
                console.log('error', err);
                addToast('Документы не удалось загрузить, попробуйте еще раз', {
                  appearance: 'error',
                });
                setProcess(0);
                setFiles(null);
              });
          }, 5000);
          setIntervalCheck(timer);
        })
        .catch((err) => {
          setProcess(0);
          addToast(err.response.data.error, {
            appearance: 'error',
          });
          setFiles(null);
        });
    };

    useImperativeHandle(ref, () => ({
      openSelectDoc() {
        dragDropRef.current.click();
      },
    }));

    useEffect(() => {
      setProcess(loading ? 2 : 0);
    }, [loading]);

    useEffect(() => {
      if (files) {
        if (!files.length) {
          return;
        }

        const validFiles = files.filter((file) => {
          const splittedType = file.type.split('/');

          let type = splittedType[splittedType.length - 1] || '';
          type = type.toUpperCase();

          return validFileFormats.includes(type);
        });

        if (!validFiles.length) {
          addToast('Неверный формат файлов', {
            appearance: 'error',
          });
          setProcess(0);
          setFiles(null);
          setTotal(0);
        } else {
          handleFileUpload(validFiles);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [files]);

    useEffect(() => {
      if (0 === waitingFiles.length && process === 2) {
        clearInterval(intrvalCheck);
        onLoadFiles(null, () => {
          setProcess(0);
          setFiles(null);
          setTotal(0);
          addToast('Распознавание документов завершено', {
            appearance: 'success',
          });
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [waitingFiles]);

    return (
      <DragDropStyled
        ref={dragDropRef}
        process={process}
        small={small}
        multiple={multiple}
        filesLeft={total - waitingFiles.length || 0}
        filesTotal={total}
        onChange={setFiles}
        clear={!files}
      />
    );
  }
);

FileUpload.displayName = 'FileUpload';

export default FileUpload;
