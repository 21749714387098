import styled, { css } from 'styled-components';
import { LightningSvg } from 'assets/img';

const positions = {
  left: css`
    right: 105%;
    top: 50%;
    transform: translateY(-50%);
  `,
  right: css`
    left: 105%;
    top: 50%;
    transform: translateY(-50%);
  `,
  top: css`
    bottom: 105%;
    left: 50%;
    transform: translateX(-50%);
  `,
  bottom: css`
    top: 105%;
    left: 50%;
    transform: translateX(-50%);
  `,
};

export const Wrap = styled.div`
  position: relative;
  display: inline-block;
`;

export const NewFeatureIcon = styled(LightningSvg)`
  position: absolute;
  ${({ position }) => positions[position]};
  z-index: 1;
`;
