import React from 'react';
import PropTypes from 'prop-types';
import Highlighter from 'react-highlight-words';

import * as S from './style';
import * as Helper from './helper';

const fn = () => {};

const DropDown = ({
  type,
  highlightValue = '',
  items = [],
  isHidden = false,
  onClick = fn,
  onBlur = fn,
}) => {
  if (isHidden || !items.length) {
    return null;
  }

  const itemClickHandler = (item) => () => {
    if (type === 'fio') {
      const { name, surname, patronymic } = item.data;

      const mainValue = [surname, name, patronymic].filter((el) => el).join(' ');

      onClick({ ...item, value: mainValue || item.value });
    } else {
      onClick(item);
    }
  };

  const allItems = items.map((item, i) => {
    let addon = '';

    if (type === 'passport') {
      addon = ` (${item.data.code}) `;
    }

    return (
      <S.SelectButton type="button" key={`select_${i + 1}`} onClick={itemClickHandler(item)}>
        <Highlighter
          highlightClassName="react-dadata--highlighted"
          searchWords={Helper.getHighlightWords(highlightValue)}
          textToHighlight={item.value + addon}
          autoEscape
        />
      </S.SelectButton>
    );
  });

  return (
    <S.Wrapper>
      {allItems}
      <S.PseudoButton type="button" onBlur={onBlur} />
    </S.Wrapper>
  );
};

DropDown.propTypes = {
  isHidden: PropTypes.bool,
  items: PropTypes.array,
  highlightValue: PropTypes.string,
  onClick: PropTypes.func,
};

export default DropDown;
