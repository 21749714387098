import React, { useState, useMemo, useEffect } from 'react';
import take from 'lodash/take';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import PulseLoader from 'react-spinners/PulseLoader';

import { Pagination } from 'components/primitives/pagination';
import { Header } from './components/Header';
import { sortArrayBy } from 'utils/common';
import { PALETTE } from 'styles/constants';
import * as S from './style.js';

const PER_PAGE = 10;

const List = ({ recipients = [], isFetching }) => {
  const [sortSettings, setSortSettings] = useState({ field: null, direction: null });
  const [pagination, setPagination] = useState({ page: 1, pages: 1, perPage: PER_PAGE });

  const sortChangeHndler = (field, direction) => {
    setSortSettings({ field, direction });
  };

  const sortedRecipients = useMemo(() => {
    const newRecipient = [...recipients];
    if (sortSettings.field) {
      return newRecipient.sort(sortArrayBy(sortSettings.field, sortSettings.direction));
    }
    return newRecipient;
  }, [recipients, sortSettings.field, sortSettings.direction]);

  useEffect(() => {
    const pages = Math.ceil(sortedRecipients.length / PER_PAGE);
    setPagination({ page: 1, pages, perPage: PER_PAGE });
  }, [sortedRecipients.length]);

  const recipientsPage = useMemo(() => {
    return take(
      sortedRecipients.slice((pagination.page - 1) * pagination.perPage),
      pagination.perPage
    );
  }, [sortedRecipients, pagination.page, pagination.perPage]);

  const pageChangeHandler = (page) => {
    setPagination((prev) => ({ ...prev, page }));
  };

  if (isFetching) {
    return (
      <S.LoaderWrapper>
        <PulseLoader size={12} color={PALETTE.grey2} loading={true} />
      </S.LoaderWrapper>
    );
  }

  return (
    <S.Container>
      <S.Table>
        <Header sortSettings={sortSettings} onSortChange={sortChangeHndler} />
        {sortedRecipients.length > 0 ? (
          recipientsPage.map((recipient) => (
            <S.Row key={recipient.id}>
              <S.Cell>{recipient.nameShort}</S.Cell>
              {/*<S.Cell></S.Cell>*/}
              <S.Cell>{recipient.legalInn}</S.Cell>
              <S.Cell>{recipient.bankName}</S.Cell>
              <S.Cell>{recipient.bic}</S.Cell>
              <S.Cell>{recipient.accountNumber}</S.Cell>
              {/*<S.Cell></S.Cell>*/}
              {/*<S.Cell>{recipient.bankName}</S.Cell>*/}
            </S.Row>
          ))
        ) : (
          <S.EmptyRow>По вашему запросу ничего не найдено</S.EmptyRow>
        )}
      </S.Table>
      {pagination.pages > 1 && (
        <S.PaginationWrapper>
          <Pagination
            toFirst={<S.PageIcon icon={faAngleLeft} />}
            toLast={<S.PageIcon icon={faAngleRight} />}
            visiblePages="5"
            pages={pagination.pages}
            currentPage={pagination.page}
            onClick={pageChangeHandler}
            ItemElement={S.PageItem}
          />
        </S.PaginationWrapper>
      )}
    </S.Container>
  );
};

export default List;
