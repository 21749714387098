export const ASW_CALC_SET_APPLICATION = 'ASW_CALC_SET_APPLICATION';
export const ASW_CALC_UPD_APPLICATION = 'ASW_CALC_UPD_APPLICATION';
export const ASW_CALC_UPD_APPL_PARAMS = 'ASW_CALC_UPD_APPL_PARAMS';
export const ASW_CALC_SET_SELECTED_OFFER = 'ASW_CALC_SET_SELECTED_OFFER';
export const ASW_CALC_AGREEMENT = 'ASW_CALC_AGREEMENT';

export const ASW_CALC_SELECT_OFFER_REQUEST = 'ASW_CALC_SELECT_OFFER_REQUEST';
export const ASW_CALC_AGREEMENT_REQUEST = 'ASW_CALC_AGREEMENT_REQUEST';
export const ASW_CALC_GET_POLICY_REQUEST = 'ASW_CALC_GET_POLICY_REQUEST';

export const ASW_CALC_CLIENT_DECLINE_REQUEST = 'ASW_CALC_CLIENT_DECLINE_REQUEST';
export const ASW_CALC_REFRESH_CALC_REQUEST = 'ASW_CALC_REFRESH_CALC_REQUEST';
export const ASW_CALC_REFRESH_CALCS_BY_APPS_IDS_REQUESTS =
  'ASW_CALC_REFRESH_CALCS_BY_APPS_IDS_REQUESTS';
export const ASW_CALC_ADD_ASW_ORDER_REQUEST = 'ASW_CALC_ADD_ASW_ORDER_REQUEST';
export const ASW_CALC_CANCELLATION_REQUEST = 'ASW_CALC_CANCELLATION_REQUEST';

export const ASW_CALC_ADD_FILE = 'ASW_CALC_ADD_FILE';
export const ASW_CALC_DEL_FILE = 'ASW_CALC_DEL_FILE';

export const ASW_CALC_SET_FETCH = 'ASW_CALC_SET_FETCH';
export const ASW_CALC_SET_MAIN_FETCH = 'ASW_CALC_SET_MAIN_FETCH';
export const ASW_CALC_SET_NAMEPLATE = 'ASW_CALC_SET_NAMEPLATE';

export const ASW_CALC_SET_DEALER_LEGALS = 'ASW_CALC_SET_DEALER_LEGALS';
export const ASW_CALC_UPD_DEALER_LEGALS = 'ASW_CALC_UPD_DEALER_LEGALS';

export const ASW_CALC_SET_POPUP_DATA = 'ASW_CALC_SET_POPUP_DATA';

export const ASSISTANCE_CALC_SET_ID = 'assistance/calculation/set-id';
export const ASSISTANCE_CALC_SET_ERROR = 'assistance/calculation/set-error';

export const ASSISTANCE_CALC_DROP_ALL_ERRORS = 'assistance/calculation/drop-all-errors';
export const ASSISTANCE_CALC_DROP_ALL = 'assistance/calculation/drop-all';
