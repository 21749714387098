import {
  TOGGLE_OPEN,
  SEARCH_APPLICATIONS_LOAD,
  APPLICATIONS_LOADED,
  APPLICATIONS_ADDED,
  SEARCH_APPLICATIONS_LOADED,
  SEARCH_APPLICATIONS_ADDED,
  APPLICATIONS_LOAD,
  APPLICATION_RELOAD,
  APPLICATIONS_SET_FETCH,
  APPLICATIONS_SET_SEARCH_FETCH,
  UPDATE_APPLICATION_ITEM,
  EXPAND_APPLICATION,
  RELOAD_EXPANDED_APPLICATION,
  DROP_APPLICATIONS,
  POST_APPLICATION_COMMENT_STORE,
  SET_APPLICATION_PREVPATH,
  APPLICATIONS_CLOSE_REASONS_CATALOG_LOADED,
  GET_DETAIL_PARAMS,
  CHANGE_DETAIL_PARAMS,
  CREDIT_APPROVEDS_DATA,
  CREDIT_APPROVED_DATA_LOAD,
  CAR_INSTANCE_DATA,
  CAR_INSTANCE_DATA_LOAD,
} from './constants';

const initState = {
  selected: [],
  opened: [],
  ids: [],
  data: [],
  fetch: false,
  searchData: [],
  searchIds: [],
  searchFetch: false,
  prevPath: '',
  catalog: null,
  detailParams: {},
  creditAprovedData: [],
  carInstanceData: {},
};

const setter = (state, key, value) => {
  const index = state[key].findIndex((val) => val === value);
  const _temp = [...state[key]];
  if (index === -1) {
    _temp.push(value);
  } else {
    _temp.splice(index, 1);
  }

  return _temp;
};

const mapApplicationList = (apps) =>
  apps.reduce((acc, curr) => {
    acc[curr.id] = curr;
    return acc;
  }, {});

const mapApplicationIds = (apps) => apps.map(({ id }) => id);

export default function (state = initState, action) {
  switch (action.type) {
    case TOGGLE_OPEN:
      return { ...state, opened: setter(state, 'opened', action.id) };
    case APPLICATIONS_LOADED:
      return {
        ...state,
        data: mapApplicationList(action.payload),
        ids: mapApplicationIds(action.payload),
        fetch: false,
      };
    case APPLICATIONS_ADDED:
      return {
        ...state,
        data: { ...state.data, ...mapApplicationList(action.payload) },
        ids: [...state.ids, ...mapApplicationIds(action.payload)],
        fetch: false,
      };
    case SEARCH_APPLICATIONS_LOADED:
      return {
        ...state,
        searchData: mapApplicationList(action.payload),
        searchIds: mapApplicationIds(action.payload),
        searchFetch: false,
      };
    case SEARCH_APPLICATIONS_ADDED:
      return {
        ...state,
        searchData: { ...state.searchData, ...mapApplicationList(action.payload) },
        searchIds: [...state.searchIds, ...mapApplicationIds(action.payload)],
        searchFetch: false,
      };
    case APPLICATIONS_SET_FETCH:
      return { ...state, fetch: action.payload };
    case APPLICATIONS_SET_SEARCH_FETCH:
      return { ...state, searchFetch: action.payload };
    case UPDATE_APPLICATION_ITEM:
      return {
        ...state,
        data: { ...state.data, [action.payload.id]: action.payload },
      };

    case POST_APPLICATION_COMMENT_STORE:
      // eslint-disable-next-line no-case-declarations
      if (action?.payload?.applicationId) {
        const data = { ...state.data };
        data[action.payload.applicationId].comment = action.payload?.comment;
        return { ...state, data: data };
      }

      return state;

    case SET_APPLICATION_PREVPATH:
      return { ...state, prevPath: action.payload };

    case DROP_APPLICATIONS:
      return { ...initState, catalog: state.catalog };

    case APPLICATIONS_CLOSE_REASONS_CATALOG_LOADED:
      return { ...state, catalog: action.payload };

    case CHANGE_DETAIL_PARAMS:
      return {
        ...state,
        detailParams: { ...state.detailParams, [action.payload.dealId]: action.payload.data },
      };

    case CREDIT_APPROVED_DATA_LOAD:
      return { ...state, creditAprovedData: action.payload };

    case CAR_INSTANCE_DATA_LOAD:
      return {
        ...state,
        carInstanceData: {
          ...state.carInstanceData,
          [action.payload.dealId]: action.payload.data,
        },
      };

    default:
      return state;
  }
}

export function toggleOpen(id) {
  return {
    type: TOGGLE_OPEN,
    id,
  };
}

// dealer need only for firs inWork load
export const getApplicationsList = (payload) => ({
  type: APPLICATIONS_LOAD,
  payload,
});

export const reloadApplication = (creditAppId) => ({
  type: APPLICATION_RELOAD,
  payload: { creditAppId },
});

export const getApplicationsSearch = (payload) => ({
  type: SEARCH_APPLICATIONS_LOAD,
  payload,
});

export const expandApplication = (id) => ({
  type: EXPAND_APPLICATION,
  payload: { id, isReload: false },
});

export const reloadExpandedApplication = (id) => ({
  type: RELOAD_EXPANDED_APPLICATION,
  payload: { id, isReload: true },
});

export const dropApplications = () => ({
  type: DROP_APPLICATIONS,
});

export const setPrevPath = (payload) => ({
  type: SET_APPLICATION_PREVPATH,
  payload,
});

export const getDetailParams = (payload) => ({
  type: GET_DETAIL_PARAMS,
  payload,
});

export const creditApproveds = (payload) => ({
  type: CREDIT_APPROVEDS_DATA,
  payload,
});

export const creditApprovedsLoad = (payload) => ({
  type: CREDIT_APPROVED_DATA_LOAD,
  payload,
});

export const getCarInstanceData = (payload) => ({
  type: CAR_INSTANCE_DATA,
  payload,
});
