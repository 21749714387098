export const sortOffersByPriority = (a, b) => {
  if (
    (a?.title || '').toLowerCase() === 'comfort 1' ||
    (a.productName || '').toLowerCase() === 'chery 1 год'
  ) {
    return -1;
  }
  if (
    (b?.title || '').toLowerCase() === 'comfort 1' ||
    (b.productName || '').toLowerCase() === 'chery 1 год'
  ) {
    return 1;
  }
  return 0;
};

export const applicationsListSorter = (a, b) => new Date(b.modifiedAt) - new Date(a.modifiedAt);

export const ordersListMapper = (order) => ({
  ...order.body,
  id: order.body.applicationId,
  orderId: order.body.id,
  state: order.body.applicationState,
});
