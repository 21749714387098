/* eslint-disable no-case-declarations */
import { v4 as uuidv4 } from 'uuid';
import {
  UPLOAD_COMMON,
  UPLOAD_ERRORS,
  ADD_PERSONA,
  UPDATE_PERSONA,
  DELETE_PERSONA,
  UPDATE_PERSONA_BY_KEY,
  CHANGE_CURRENT_PERSONA,
  ADD_TRUSTEES,
  CHANGE_ROLE,
  UPDATE_SUBPART,
  ADD_SUBPART,
  DELETE_SUBPART,
  CHECK_CAR_NUMBER,
  SET_CARINFO,
  LOAD_BRANDS,
  BRANDS_LOADED,
  LOAD_MODELS,
  MODELS_LOADED,
  MODELS_FETCH,
  CHECK_NUMBER_FETCH,
  DELETE_TRUSTEES,
  ANKETA_FETCH,
  STORE_ANKETA,
  SET_SAVED_STATUS,
  LOAD_ANKETA,
  ANKETA_SAVING,
  ANKETA_ERROR,
  UPLOAD_FILE,
  LOAD_FILE,
  SET_APPLICATION,
  LOAD_ANKETA_PDF,
  SET_ANKETA_PDF,
  CLEAR_ANKETA,
  SET_SHOW_SIGN_DOCUMENTS,
  SET_IS_PEP_SIGNATURE,
  DELETE_DOC_TYPE,
  initPersonState,
  SET_IS_PEP_INFO,
} from './constants';
import { contactsInitialState } from './mappers/contacts';
import { passportInitialState } from './mappers/passport';
import { registrationAddressInitialState } from './mappers/registrationAddress';
import { livingAddressInitialState } from './mappers/livingAddress';
import { livingAddressPropertyInitialState } from './mappers/livingAddressProperty';
import { financeInitialState } from './mappers/finance';
import { secondDocumentInitialState } from './mappers/secondDocument';
import { trusteesInitialState } from './mappers/trustees';
import { spouseInitialState } from './mappers/spouse';
import { personalInfoInitialState } from './mappers/personalInfo';
import { propertyInitialState } from './mappers/property';
import { workInitialState } from './mappers/work';
import { appParamsInitialState } from './mappers/applicationParams';

const initState = {
  anketaFetch: false,
  saved: true,
  hasBarrowerRole: true,
  currentPersonId: 0,
  brands: [],
  models: [],
  banks: [],
  documents: [],
  documentTypes: [],
  anketaTypes: [],
  application: null,
  persons: {
    0: {
      id: 0,
      created: new Date().valueOf(),
      roles: [{ id: 0, value: 'borrower', label: 'Заемщик' }],
      selfAgreement: false,
      ...personalInfoInitialState,
      ...contactsInitialState,
      ...passportInitialState,
      ...registrationAddressInitialState,
      ...livingAddressInitialState,
      ...livingAddressPropertyInitialState,
      ...financeInitialState,
      ...secondDocumentInitialState,
      ...trusteesInitialState,
      ...spouseInitialState,
      ...propertyInitialState,
      ...workInitialState,
      ...appParamsInitialState,

      citizenship: {
        ruOnlyTax: true,
        ruOnly: true,
      },
      isSavedComplete: false,
    },
  },
  insurance: {
    common: {
      new: true,
      multidrive: false,
      usageMonthsPerYear: 12,
      brandId: null,
      modelId: null,
      configId: null,
      year: '',
      policyStartDate: '',
      policyStartDate_osago: '',
      policyStartDate_kasko: '',
      bodyNumber: '',
      chassisNumber: '',
      transmission: '',
      vin: '',
      carNumber: '',
      creditCarBank: '',
      ogrn: '',
      creditCar: true,
      registrationAddress: '',
      registrationKladr: '',
      registration: '',
      params: {
        key: 'lhkasdlkh134lkh123lkk',
      },
    },
    osago: {
      carDocument: {
        date: '',
        documentType: 1,
        series: '',
        number: '',
      },
      diagnosticCard: {
        series: '',
        number: '',
        from: '',
        to: '',
        type: 0,
      },
    },
    kasko: {
      stealing: true,
      prolongation: true,
      previousContractNumber: '',
      previousPolicyFromOtherInsCompanyExpirationDate: '',
      previousPolicyScan: '',
      kilometers: '',
      cost: '',
      usageRegion: '',
      enginePower: '',
      useAntiTheftSystem: true,
      antiTheftSystemModel: '',
      taxi: false,
      autolaunch: false,
      theft: true,
      damage: true,
      dateRun: '',
      gap: true,
      tires: true,
      accident: true,
      paintwork: true,
      fallenObjects: true,
      evacuation: true,
      selfIgnition: true,
      disaster: true,
      referenceCollect: true,
      noReference: true,
    },
  },
  otpDocLink: {},
  error: false,
  offers: [],
  pdf: null,
  showSignDocuments: true,
  isPEPSignature: false,
};

const updateByKey = (state, { id, key, values }) => {
  const updPersons = { ...state.persons };
  updPersons[id][key] = values;
  return updPersons;
};

const deepUpdate = (state, { id, subId, key, values, deepKey }) => ({
  ...state,
  persons: {
    ...state.persons,
    [id]: {
      ...state.persons[id],
      [key]: {
        ...state.persons[id][key],
        [subId]: {
          ...state.persons[id][key][subId],
          [deepKey]: values,
        },
      },
    },
  },
});

export default function (state = initState, action) {
  switch (action.type) {
    case UPLOAD_COMMON:
      return { ...state, ...action.payload };
    case UPLOAD_ERRORS: {
      return { ...state, ...action.payload };
    }
    case ADD_PERSONA:
      const addedId = action.payload;
      const clonedInitState = JSON.parse(JSON.stringify(initPersonState));
      return {
        ...state,
        persons: {
          ...state.persons,
          [addedId]: { ...clonedInitState, id: addedId },
        },
      };
    case UPDATE_PERSONA:
      const updatedPersons = { ...state.persons };
      updatedPersons[action.payload.id] = action.payload;
      return { ...state, persons: updatedPersons };
    case UPDATE_PERSONA_BY_KEY:
      return { ...state, persons: updateByKey(state, action.payload) };
    case DELETE_PERSONA: {
      const delPersons = { ...state.persons };
      delete delPersons[action.payload];
      return { ...state, persons: delPersons };
    }
    case CHANGE_CURRENT_PERSONA: {
      let delPersons = { ...state.persons };
      delPersons = { [action.payload]: delPersons[action.payload] };
      return { ...state, currentPersonId: action.payload, persons: delPersons };
    }
    case ADD_TRUSTEES:
      let trustees = state.persons[action.payload].trustees;
      const addId = uuidv4();
      trustees = { ...trustees, [addId]: { id: addId } };
      return {
        ...state,
        persons: {
          ...state.persons,
          [action.payload]: {
            ...state.persons[action.payload],
            trustees,
          },
        },
      };
    case DELETE_TRUSTEES:
      let dtrustees = state.persons[action.payload.id].trustees;
      delete dtrustees[action.payload.subId];
      return {
        ...state,
        persons: {
          ...state.persons,
          [action.payload.id]: {
            ...state.persons[action.payload.id],
            trustees: dtrustees,
          },
        },
      };
    case UPDATE_SUBPART:
      let subpart = state.persons[action.payload.id][action.payload.key];
      subpart = {
        ...subpart,
        [action.payload.subId]: {
          ...subpart[action.payload.subId],
          ...action.payload.values,
        },
      };
      return {
        ...state,
        persons: {
          ...state.persons,
          [action.payload.id]: {
            ...state.persons[action.payload.id],
            [action.payload.key]: subpart,
          },
        },
      };
    case ADD_SUBPART:
      let subparts = state.persons[action.payload.id][action.payload.key];
      const subId = uuidv4();
      subparts = { ...subparts, [subId]: { id: subId } };
      return {
        ...state,
        persons: {
          ...state.persons,
          [action.payload.id]: {
            ...state.persons[action.payload.id],
            [action.payload.key]: subparts,
          },
        },
      };
    case DELETE_SUBPART:
      let dsubparts = state.persons[action.payload.id][action.payload.key];
      delete dsubparts[action.payload.subId];
      return {
        ...state,
        persons: {
          ...state.persons,
          [action.payload.id]: {
            ...state.persons[action.payload.id],
            [action.payload.key]: dsubparts,
          },
        },
      };
    case CHANGE_ROLE:
      return {
        ...state,
        persons: updateByKey(state, action.payload),
        hasBarrowerRole: action.payload.hasBarrowerRole,
      };
    case BRANDS_LOADED:
      return { ...state, brands: action.payload };
    case MODELS_LOADED:
      return deepUpdate(state, {
        ...action.payload,
        key: 'cars',
        deepKey: 'models',
      });
    case SET_CARINFO:
      return deepUpdate(state, {
        ...action.payload,
        key: 'cars',
        deepKey: 'car',
      });
    case MODELS_FETCH:
      return deepUpdate(state, {
        ...action.payload,
        key: 'cars',
        deepKey: 'modelsFetch',
      });
    case CHECK_NUMBER_FETCH:
      return deepUpdate(state, {
        ...action.payload,
        key: 'cars',
        deepKey: 'numberFetch',
      });
    case STORE_ANKETA: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case ANKETA_FETCH:
      return {
        ...state,
        anketaFetch: action.payload.values,
        error: action.payload.error,
      };
    case ANKETA_SAVING:
      return { ...state, saved: action.payload.values };
    case ANKETA_ERROR:
      return { ...state, error: action.payload.values };
    case SET_SAVED_STATUS:
      return {
        ...state,
        persons: {
          ...state.persons,
          [action.payload.id]: {
            ...state.persons[action.payload.id],
            isSavedComplete: action.payload.values,
          },
        },
      };
    case SET_APPLICATION: {
      return {
        ...state,
        application: action.payload,
      };
    }
    case SET_ANKETA_PDF: {
      return {
        ...state,
        pdf: action.payload,
      };
    }
    case CLEAR_ANKETA: {
      return initState;
    }
    case SET_SHOW_SIGN_DOCUMENTS: {
      return {
        ...state,
        showSignDocuments: action.payload,
      };
    }
    case SET_IS_PEP_SIGNATURE: {
      return {
        ...state,
        isPEPSignature: action.payload,
      };
    }
    case DELETE_DOC_TYPE: {
      return {
        ...state,
        documents: state.documents.filter((doc) => doc.type.sysName !== action.payload),
      };
    }
    case SET_IS_PEP_INFO: {
      return {
        ...state,
        isPEPSignature: action.payload.isPEPSignature,
        otpDocLink: action.payload.otpDocLink,
      };
    }
    default:
      return state;
  }
}

export const updateCommon = (payload) => ({
  type: UPLOAD_COMMON,
  payload,
});

export const updateErrors = (payload) => ({
  type: UPLOAD_ERRORS,
  payload,
});

export const addPersona = (payload) => ({
  type: ADD_PERSONA,
  payload,
});

export const updatePersona = (persona) => ({
  type: UPDATE_PERSONA,
  payload: persona,
});

export const updatePersonaByKey = ({ values, key, id }) => ({
  type: UPDATE_PERSONA_BY_KEY,
  payload: { values, key, id },
});

export const deletePersona = ({ id }) => ({
  type: DELETE_PERSONA,
  payload: id,
});

export const changeCurrentPerson = (id) => ({
  type: CHANGE_CURRENT_PERSONA,
  payload: id,
});

export const addTrustees = (id) => ({
  type: ADD_TRUSTEES,
  payload: id,
});

export const deleteTrustees = (id) => ({
  type: DELETE_TRUSTEES,
  payload: id,
});

export const changeRole = ({ id, key, values, hasBarrowerRole }) => ({
  type: CHANGE_ROLE,
  payload: { id, key, values, hasBarrowerRole },
});

export const updateSubPart = ({ id, key, values, subId }) => ({
  type: UPDATE_SUBPART,
  payload: { id, key, values, subId },
});

export const addSubPart = ({ id, key, values, subId }) => ({
  type: ADD_SUBPART,
  payload: { id, key, values, subId },
});

export const deleteSubPart = ({ id, key, subId }) => ({
  type: DELETE_SUBPART,
  payload: { id, key, subId },
});

export const checkCarNumber = ({ id, key, subId, carNumber, values }) => ({
  type: CHECK_CAR_NUMBER,
  payload: { id, key, subId, carNumber, values },
});

export const loadBrands = () => ({
  type: LOAD_BRANDS,
});

export const loadModels = ({ brandId, id, key, subId, values }) => ({
  type: LOAD_MODELS,
  payload: { brandId, id, key, subId, values },
});

export const postProfile = (payload) => ({
  type: STORE_ANKETA,
  payload,
});

export const getProfile = (payload) => ({
  type: LOAD_ANKETA,
  payload,
});

export const uploadFiles = (payload) => ({
  type: UPLOAD_FILE,
  payload,
});

export const loadFiles = (payload) => ({
  type: LOAD_FILE,
  payload,
});

export const loadApplication = (payload) => ({
  type: SET_APPLICATION,
  payload,
});

export const loadProfilePDF = (payload) => ({
  type: LOAD_ANKETA_PDF,
  payload,
});

export const setProfilePDF = (payload) => ({
  type: SET_ANKETA_PDF,
  payload,
});

export const clearAnketa = () => ({
  type: CLEAR_ANKETA,
});

export const setShowSignDocuments = (payload) => ({
  type: SET_SHOW_SIGN_DOCUMENTS,
  payload,
});

export const setIsPEPSignature = (isPEPSignature) => ({
  type: SET_IS_PEP_SIGNATURE,
  payload: isPEPSignature,
});

export const deleteDocumetsType = (type) => ({
  type: DELETE_DOC_TYPE,
  payload: type,
});
