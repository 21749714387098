import styled from 'styled-components';
import { PALETTE, Z_INDEXES } from 'styles/constants';

import { FormGridRowBase } from '../style';
import { Button } from 'components/primitives/button';
import { Switch } from 'components/primitives/switch';
import { TextStyle2 } from 'components/primitives/typography/textStyles/textStyle2';
import { H4 } from 'components/primitives/typography/headings/h4';
import { FileManager } from 'components/modules/MainAnketa/components/FileManager';
import { Link } from 'components/primitives/link';
import * as UK from 'components/primitives/content';
import { PrinterGreenSvg } from 'assets/img';

import { AnketaInputMask } from '../../style';

export const GridStyled = styled(FormGridRowBase)`
  background-color: ${PALETTE.white};
  grid-template-areas:
    '. rb rb rb rb rb rb .'
    '. ds ds ds ds ds ds .'
    '. lc lc lc lc lc lc .'
    '. . pm pm sb sb . .'
    '. cb ci ci cc cc cc .'
    '. yy yy yy . . . .'
    '. go go go go go go .'
    '. bl bl bl bl bl bl .'
    '. al al al fup fup fup .'
    '. fu fu fu fu fu fu .'
    '. fa fa fa fa fa fa .'
    '. go2 go2 go2 go2 go2 go2 .';

  > div {
    opacity: ${(p) => (p.disabled ? 0.3 : 1)};

    &:first-child {
      opacity: 1;
    }
  }
`;

export const TypeSignSwitch = styled(Switch)`
  grid-area: rb;
  margin: 30px 0;
`;

export const Description = styled.div`
  grid-area: ds;
  background: #f7f7f7;
  border: 1px solid #b5b5b5;
  margin-bottom: 30px;
  padding: 30px 63px;
  font-size: 12px;
  line-height: 16px;
  text-align: center;

  > strong {
    font-weight: bold;
  }
`;

export const ListCheckBox = styled.div`
  grid-area: lc;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  color: ${PALETTE.black};

  > div {
    margin-bottom: 18px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const PhoneMask = styled(AnketaInputMask)`
  grid-area: pm;
`;

export const CodeInput = styled(AnketaInputMask)`
  grid-area: ci;
`;

export const Cancel = styled(Button)`
  grid-area: cb;
  height: 45px;
  margin-bottom: 15px;
  font-size: 14px;
  text-align: right;
  padding: 0;
  border: 1px solid ${PALETTE.grey2};
  color: ${PALETTE.green1};
`;

export const SendButton = styled(Button)`
  grid-area: sb;
  height: 45px;
  margin-bottom: 30px;
  font-size: 14px;
  padding: 0;
`;

export const ResendCodeButton = styled(Button)`
  grid-area: yy;
  font-size: 12px;
  padding: 0;
  height: 25px;
  margin-bottom: 15px;
`;

export const FieldTip = styled(TextStyle2)`
  grid-area: cc;
  color: ${PALETTE.grey1};
`;

export const DocSignHelp = styled.span`
  grid-area: go;
  font-weight: 400;
  font-size: 12px;
  margin: 19px 0 19px 0;
  text-align: center;
`;

export const DocSignHelp2 = styled.span`
  grid-area: go2;
  font-weight: 400;
  font-size: 12px;
  margin: 19px 0 30px 0;
  text-align: center;
`;

export const LinkContainer = styled.div`
  display: flex;
  grid-area: go2;
  margin: 19px 0 30px 0;
  justify-content: center;
`;

export const DocLink = styled(UK.Link)`
  width: 150px;
  margin: 0px 20px;
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  color: ${PALETTE.black1};

  &:first-child {
    text-align: right;
  }

  &:last-child {
    text-align: left;
  }
`;

export const H4Complete = styled(H4)`
  grid-area: go;
  color: ${PALETTE.green1};
  text-align: center;
`;

export const ButtonList = styled.div`
  grid-area: bl;
  margin-bottom: 53px;
  display: flex;
  align-items: center;
  justify-content: center;

  > div:not(:last-child) {
    margin-right: 50px;
  }
`;

export const ButtonItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`;

export const CircleIcon = styled.div`
  width: 60px;
  border-radius: 50%;
  border: 1px solid ${PALETTE.green1};
  text-align: center;
  padding: 16px 0;
  margin-bottom: 5px;

  svg {
    display: block;
    margin: auto;
    width: 52px;
    height: 20px;
  }
`;

export const LinkStyled = styled(Link)`
  display: flex;
  vertical-align: middle;
  align-items: center;

  > svg {
    &:first-child {
      margin-right: 12px;
    }

    &:last-child {
      fill: ${PALETTE.grey1};
      margin-left: 5px;
      transform: rotate(90deg);
    }
  }
`;

export const FileUploadStyledPerson = styled(FileManager)`
  height: 100%;
  grid-area: fu;
  margin-bottom: ${({ isSelfAgreement }) => (isSelfAgreement ? '30px' : 0)};
`;

export const FileUploadStyledAnketa = styled(FileManager)`
  height: 100%;
  grid-area: fa;
`;

export const ControlMenu = styled.div`
  position: absolute;
  width: 138px;
  top: 70px;
  left: -25px;
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
  background: ${PALETTE.white};
  border: 1px solid ${PALETTE.grey2};
  z-index: ${Z_INDEXES.modalWindow + 50};

  button:last-child {
    margin-bottom: 0;
  }
`;

export const MenuButton = styled(Button)`
  padding: 0;
  height: 20px;
  margin-bottom: 8px;
  font-size: 12px;
`;

export const AgreementLoadArea = styled.div`
  grid-area: al;
  height: 85px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #e9f3e9;
  border: 1px solid #228b22;
  box-sizing: border-box;
  border-radius: 5px;
  cursor: ${(p) => (p.disabled ? 'not-allowed' : 'pointer')};
`;

export const PrinterGreenSvgStyled = styled(PrinterGreenSvg)`
  height: 30px;
  width: 30px;
  margin-right: 15px;
`;
