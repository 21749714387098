import { convertOptionToSysName, convertSysNameToSelectOption } from 'utils/dataMappers/mappers';

export const realtyStateOwn = {
  id: 3393,
  sysName: 'realty-state-own',
  value: 'Личная собственность',
};

export const realtyStateWithRelatives = {
  id: 3394,
  sysName: 'realty-state-lives-with-relatives',
  value: 'Собственность родственников',
};

export const livingAddressPropertyInitialState = {
  livingAddressProperty: {
    realtyObtaining: {},
    realtyState: realtyStateOwn,
    realtyType: {},
  },
};

export const livingAddressPropertyFromAPIToState = (
  customer,
  { realtyObtainingList, realtyStateList, realtyTypeList }
) => {
  const livingAddressProperty = customer.livingAddressProperty;
  return {
    livingAddressProperty: {
      realtyObtaining: convertSysNameToSelectOption(
        livingAddressProperty.realtyObtainingSysName,
        realtyObtainingList
      ),
      realtyState: convertSysNameToSelectOption(
        livingAddressProperty.realtyStateSysName,
        realtyStateList
      ),
      realtyType: convertSysNameToSelectOption(
        livingAddressProperty.realtyTypeSysName,
        realtyTypeList
      ),
    },
  };
};

export const livingAddressPropertyFromStateToAPI = (person) => {
  const livingAddressProperty = person.livingAddressProperty;

  return {
    livingAddressProperty: {
      realtyObtainingSysName: convertOptionToSysName(livingAddressProperty.realtyObtaining),
      realtyStateSysName: convertOptionToSysName(livingAddressProperty.realtyState),
      realtyTypeSysName: convertOptionToSysName(livingAddressProperty.realtyType),
    },
  };
};
