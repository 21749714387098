import moment from 'moment';

export const fioJoin = ({ surname = '', name = '', middleName = '' }) =>
  [surname, name, middleName].filter((it) => it).join(' ');

export const fioSplit = (fio = '') => {
  const [surname = '', name = '', middleName = '', ...other] = fio.split(' ').filter((it) => it);

  return { surname, name, middleName: [middleName, ...other].join(' ') };
};

export const passportSplit = (seriesNumber = '') => ({
  series: seriesNumber.slice(0, 4),
  number: seriesNumber.slice(4),
});

export const dateFormmatter = (date, back = false) => {
  if (!date) {
    return '';
  }

  let result;

  if (back) {
    result = moment(date, 'DDMMYYYY').format('YYYY-MM-DD');
  } else {
    if (date.includes('.')) {
      result = moment(date, 'DD.MM.YYYY').format('DDMMYYYY');
    } else {
      result = moment(date, 'YYYY-MM-DD').format('DDMMYYYY');
    }
  }

  return result;
};

export const splitDriverLicense = (seriesNumber = '') => {
  const pureValue = Array.from(seriesNumber)
    .filter((letter) => letter !== ' ')
    .join('');

  const series = pureValue.slice(0, 4);
  const number = pureValue.slice(4);

  return { series, number };
};
