import React from 'react';

import { useLoadPlatform } from 'utils/helperElfSight';

export const ElfSightBannerCommon = () => {
  useLoadPlatform();
  return <div className="elfsight-app-0df51c6f-7a58-4a79-8e93-9a6f1202d336" />;
};

export const ElfSightBannerLoanIssue = () => {
  useLoadPlatform();
  return <div className="elfsight-app-4021ee35-b9c7-44c1-a1ab-9ef39a3d3a21" />;
};

export const ElfSightBannerCarCheck = () => {
  useLoadPlatform();
  return <div className="elfsight-app-fdb42361-b6e6-4e34-9faf-e02667086e3c" />;
};

export const ElfSightCherryBanner = () => {
  useLoadPlatform();
  return <div className="elfsight-app-29fe923a-a5f1-4d2e-8fc3-abc7272dff97" />;
};

export const ElfSightFocusGroupPangoCardBanner = () => {
  useLoadPlatform();
  return <div className="elfsight-app-7794f050-e194-4397-a09c-8aa0024e2384" />;
};

export const ElfSightSuperKaskoBanner = () => {
  useLoadPlatform();
  return <div className="elfsight-app-dc250773-fc93-4645-a5c2-d598706395fa" />;
};
