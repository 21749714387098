import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getDealers, getUser } from 'store/user';

import { Sidebar } from './components/Sidebar/Sidebar';
import { MainInfoBlock } from './components/MainInfoBlock/MainInfoBlock';
import { UpperContent } from './components/MainInfoBlock/components/UpperContent/UpperContent';

import * as S from './style';

export const MainPage = () => {
  const rootDispatch = useDispatch();

  const role = useSelector(({ user }) => user.role || -1);

  useEffect(() => {
    rootDispatch(getUser());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (role !== -1) {
      rootDispatch(getDealers());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role]);

  return (
    <S.Container>
      <Sidebar />
      <S.MainBlockWraper>
        <UpperContent />
        <MainInfoBlock />
      </S.MainBlockWraper>
    </S.Container>
  );
};
