import React from 'react';

import { DealersBanks } from './components/DealersBanks';

import * as S from './style';

export const RightInfo = () => {
  return (
    <S.RightContainer>
      <DealersBanks />
    </S.RightContainer>
  );
};
