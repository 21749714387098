import styled from 'styled-components';
import Widget from 'components/primitives/widget';
import { Status } from 'components/primitives/status';
import { H3 } from 'components/primitives/typography/headings/h3';
import { PALETTE } from 'styles/constants';

export const Wrapper = styled(Widget)`
  padding: 20px 10px 20px;
  background-color: ${PALETTE.grey3};
  border-radius: 5px;
`;

export const HeaderTop = styled.div`
  display: flex;
`;

export const Title = styled(H3)`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 18px;
  color: #000000;
  margin-top: 0;
  margin-bottom: 0;
`;

export const NextStatus = styled(Status)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 18px;
  margin-left: auto;
  padding: 5px 20px;
  border-radius: 20px;
  text-align: center;
  max-width: 60%;
`;

export const StatusLine = styled.ul`
  display: flex;
  align-items: flex-end;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
`;

export const StatusLineItem = styled.li`
  width: 100%;
  &:first-child div {
    border-radius: 8px 0 0 8px;
  }
  &:last-child div {
    border-radius: 0 8px 8px 0;
  }
  &:not(:last-child) {
    margin-right: 2px;
  }
`;

export const LineSegment = styled.div`
  width: 100%;
  height: 5px;
  background-color: ${(p) => (p.active ? PALETTE.grey2 : PALETTE.white)};
`;

export const Content = styled.div`
  display: ${(props) => (props.isHidden ? 'none' : 'flex')};
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 30px;
`;

export const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 90px;
`;
