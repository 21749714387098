import React from 'react';
import { Wrapper } from './style';

const Value = ({ isHidden, select }) => {
  if (isHidden) {
    return null;
  }

  let title = select[0]?.riskName || select[0]?.value || '';
  if (title) {
    const more = select.length - 1;
    if (more > 0) {
      title = `${title} (ещё +${more})`;
    }
  }

  return <Wrapper>{title}</Wrapper>;
};

export default Value;
