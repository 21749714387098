import { SET_APP_ERROR, CLEAR_APP_ERROR } from './constants';

const initialState = { error: null };

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_APP_ERROR:
      return { ...state, error: action.payload };
    case CLEAR_APP_ERROR:
      return { ...state, error: null };
    default:
      return state;
  }
}
