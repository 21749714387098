import { contactsFromStateToAPI } from './mappers/contacts';
import { passportFromStateToAPI } from './mappers/passport';
import { registrationAddressFromStateToAPI } from './mappers/registrationAddress';
import { livingAddressFromStateToAPI } from './mappers/livingAddress';
import { livingAddressPropertyFromStateToAPI } from './mappers/livingAddressProperty';
import { financeFromStateToAPI } from './mappers/finance';
import { secondDocumentFromStateToAPI } from './mappers/secondDocument';
import { trusteesFromStateToAPI } from './mappers/trustees';
import { spouseFromStateToAPI } from './mappers/spouse';
import { personalInfoFromStateToAPI } from './mappers/personalInfo';
import { propertyFromStateToAPI } from './mappers/property';
import { workFromStateToAPI } from './mappers/work';
import { detailsFromStateToAPI } from './mappers/details';
import { paramsFromStateToAPI } from './mappers/applicationParams';

import { postProfile } from 'data-providers/mainAnketaProvider';

export const generateCustomer = (persona) => {
  return {
    id: persona.id,
    selfApprove: persona.selfAgreement,
    ...personalInfoFromStateToAPI(persona),
    ...contactsFromStateToAPI(persona),
    ...passportFromStateToAPI(persona),
    ...registrationAddressFromStateToAPI(persona),
    ...livingAddressFromStateToAPI(persona),
    ...livingAddressPropertyFromStateToAPI(persona),
    ...financeFromStateToAPI(persona),
    ...secondDocumentFromStateToAPI(persona),
    ...trusteesFromStateToAPI(persona),
    ...spouseFromStateToAPI(persona),
    ...propertyFromStateToAPI(persona),
    ...workFromStateToAPI(persona),
  };
};

export const generateProfilePayload = ({ applicationId, persona }) => {
  const apiData = {
    applicationId,
    roles: persona.roles.map((r) => r.value),
    customer: generateCustomer(persona),
    ...detailsFromStateToAPI(persona),
    ...paramsFromStateToAPI(persona),
  };

  return apiData;
};

export const anketaToAPI = ({ applicationId, persona }) => {
  const apiData = generateProfilePayload({ applicationId, persona });
  const result = postProfile(apiData);
  if (result) console.log('anketa saved');
};
