import React from 'react';
import PropTypes from 'prop-types';
import * as Primitive from '../../../../primitives/pagination';

const Pagination = ({ totalItems, dealersOnPage, currentPage, onClick = () => {} }) => {
  const numberPages =
    Math.floor(totalItems / dealersOnPage) + Number(totalItems % dealersOnPage > 0);

  if (numberPages < 2) {
    return null;
  }

  return (
    <Primitive.Pagination
      className="DealersSearchBlock__pagination"
      toFirst={<span>&lt;</span>}
      toLast={<span>&gt;</span>}
      pages={numberPages}
      currentPage={currentPage}
      onClick={onClick}
      leftShift={'115px'}
    />
  );
};

Pagination.propTypes = {
  totalItems: PropTypes.number.isRequired,
  dealersOnPage: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onClick: PropTypes.func,
};

export default Pagination;
