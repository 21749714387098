import styled from 'styled-components';

import { PALETTE } from 'styles/constants';
import { Loader as CommonLoader } from 'components/primitives/loader';

export const Container = styled.div`
  position: relative;
  flex: 1;

  &:before {
    content: '';
    position: sticky;
    display: block;
    top: 75px;
    width: 100%;
    height: 40px;
    margin-bottom: -40px;
    z-index: 9;
    background-color: ${PALETTE.white};
  }
`;

export const Table = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border: 1px solid ${PALETTE.grey2};
  border-radius: 8px;
`;

export const Row = styled.div`
  display: grid;
  min-height: 75px;
  grid-template-columns: 0.6fr 0.4fr 0.4fr 0.9fr minmax(145px, 0.45fr) minmax(120px, 0.3fr) ${(p) =>
      p.showOpenButton ? '110px' : ''};
  padding: 0px 10px;
  line-height: 1.3;
  position: relative;
  align-items: center;

  * + & {
    border-top: 1px solid ${PALETTE.grey2};
  }
`;

export const HeadRow = styled(Row)`
  position: sticky;
  top: 100px;
  z-index: 9;
  margin: -1px;
  border: 1px solid ${PALETTE.grey2};
  background-color: ${PALETTE.grey9};

  &:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
`;

export const Cell = styled.div`
  display: flex;
  padding: 16px 10px;
  text-align: ${(p) => (p.txtCenter ? 'center' : 'left')};
  font-weight: ${(p) => (p.txtBold ? '500' : '400')};
  text-transform: ${(p) => (p.txtCap ? 'capitalize' : 'none')};
  cursor: ${(p) => (p.isClickable ? 'pointer' : 'default')};
  color: ${(p) => (p.isGrey ? PALETTE.grey5 : PALETTE.dark)};
  z-index: ${(p) => (p.zIndex ? '2' : 'auto')};
`;

export const HeadCell = styled(Cell)`
  color: ${PALETTE.grey5};
`;

export const Loader = styled(CommonLoader)`
  position: static;
  width: 100%;
  border-top: 1px solid ${PALETTE.grey2};

  & > img {
    width: 150px;
    margin: -15px 0px;
  }
`;
