import {
  convertDate,
  convertOptionToSysName,
  convertSysNameToSelectOption,
  isCustomerMerried,
} from 'utils/dataMappers/mappers';
import {
  abstractDrivingLicenseFromAPIToState,
  abstractDrivingLicenseFromStateToAPI,
} from './abstractDrivingLicense';

export const trusteesInitialState = {
  trustees: {},
};

export const trusteesFromAPIToState = (customer, { trusteeRelations, genderList }) => {
  if (customer.confidants.length > 0) {
    let trustees = customer.confidants.reduce(
      (obj, t) => ({
        ...obj,
        [t.id]: {
          gender: convertSysNameToSelectOption(
            t.genderSysName === '' ? 'male' : t.genderSysName,
            genderList
          ),
          id: t.id,
          firstName: t.firstName || '',
          lastName: t.lastName || '',
          middleName: t.middleName || '',
          fullName: `${
            t.lastName.length > 0 ? `${t.lastName}${t.firstName.length > 0 ? ' ' : ''}` : ''
          }${t.firstName.length > 0 ? `${t.firstName}${t.middleName.length > 0 ? ' ' : ''}` : ''}${
            t.middleName.length > 0 ? `${t.middleName}` : ''
          }`,
          birthDate: convertDate(t.birthDate) || '',
          phone: t.contacts.mobilePhone || '',
          relationType: convertSysNameToSelectOption(t.relationSysName, trusteeRelations) || '',
          insurance: t.insurance || false,
          ...abstractDrivingLicenseFromAPIToState(t.driverLicense),
        },
      }),
      {}
    );
    const keys = Object.keys(trustees);
    if (keys.length > 1) {
      trustees = {
        [keys[0]]: trustees[keys[0]],
      };
    }
    return {
      trustees,
    };
  } else {
    if (isCustomerMerried(customer)) {
      return { trustees: {} };
    } else {
      return {
        trustees: {
          temp: {
            id: 'temp',
            fullName: '',
            birthDate: '',
            phone: '',
            relationType: { id: 0, sysName: '' },
            insurance: false,
            drivingLicenseSeriesNumber: '',
            drivingLicenseIssueDate: '',
            drivingLicenseIssuer: '',
            drivingLicenseStartYear: '',
          },
        },
      };
    }
  }
};

export const trusteesFromStateToAPI = (person) => {
  let trustees = Object.keys(person.trustees).map((id) => {
    const t = person.trustees[id];
    return {
      genderSysName: convertOptionToSysName(t.gender) || 'male',
      id: t.id || null,
      firstName: t.firstName || '',
      lastName: t.lastName || '',
      middleName: t.middleName || '',
      birthDate: t.birthDate || '',
      contacts: {
        mobilePhone: t.phone || '',
      },
      relationSysName: convertOptionToSysName(t.relationType),
      insurance: t.insurance || false,
      ...abstractDrivingLicenseFromStateToAPI(t),
    };
  });
  if (trustees.length > 1) {
    trustees = [trustees[0]];
  }
  return {
    confidants: trustees,
  };
};
