import styled from 'styled-components';
import { Button } from 'components/primitives/button';
import {
  SendMessageSvg,
  ClipSvg,
  PrintSvg,
  CommentBankSvg,
  DifferenceIcoSvg,
  SentBankIcoSvg,
  SentBankOkIcoSvg,
  InfoLightSvg,
} from 'assets/img';
import { PALETTE } from 'styles/constants';
import BankLogo from 'components/common/BankLogo';
import { addRgbOpacity } from 'utils/common';

export const CellWrapper = styled.div`
  padding: 0 10px;
  display: flex;
  justify-content: ${(p) => p.align};
  align-items: center;
  overflow: ${(p) => p.overflow};
`;

export const Cell = styled.div`
  color: ${(p) => p.textColor};
  text-align: ${(p) => p.textAlign};
  font-size: 12px;
  display: flex;
  justify-content: ${(p) => p.align};
  width: 100%;
  text-transform: ${(p) => p.textTransform};
`;

export const Application = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${PALETTE.grey2};
`;

export const DetailContainer = styled.div`
  background: ${PALETTE.grey9};
  overflow: hidden;
  padding-bottom: 16px;
  position: relative;
  z-index: 1;
  top: -15px;
`;

export const Row = styled.div`
  font-style: normal;
  display: grid;
  grid-template-columns: 3% 16% 12% 12% 22% 14% 5% 5% 11%;
  align-items: center;
  grid-gap: 1px;
  position: relative;
  background: ${(p) => (p.selected ? '#DEEEFA' : 'white')};
`;

export const DetailRow = styled(Row)`
  font-family: Roboto;
  color: ${addRgbOpacity(PALETTE.black1, 0.4)};
  height: 56px;
  grid-template-columns: ${(p) =>
    p.hasCarYear
      ? '16.5% 8% 10% 7% 7% 8.2% 10% 9.2% 9.2% 9.2% 1fr'
      : '16.5% 9.15% 11.15% 8.15% 8% 9.15% 11.15% 10.65% 10.65% 1fr'};
  font-size: 14px;
  border-top: 1px solid ${PALETTE.grey11};
`;

export const DetailItemParamsRow = styled(DetailRow)`
  color: ${addRgbOpacity(PALETTE.black1, 0.7)};
  height: 100%;
  border-radius: 8px;
  border: 1px solid ${PALETTE.grey11};
  margin: 0px 12px;
  padding: 5px 0px;
`;

export const DetailCell = styled.div`
  display: flex;
  align-items: center;
  padding-left: 20px;
  line-height: 1.2;
  position: relative;
  font-weight: ${({ boldPrice, boldInitialMoney, boldPeriod, boldRate, boldDealerSum }) =>
    boldPrice || boldInitialMoney || boldPeriod || boldRate || boldDealerSum ? 'bold' : 'normal'};
`;

export const CarDetailCell = styled(DetailCell)`
  display: flex;
  flex-direction: column;
  align-items: start;
  padding-left: 10px;
  line-height: inherit;
`;

export const DetailItemRow = styled(Row)`
  padding: 0px 10px 16px 10px;
  color: ${PALETTE.black1};
  grid-template-columns: 0.6fr 0.4fr 0.4fr 0.9fr minmax(145px, 0.45fr) minmax(120px, 0.3fr) ${(p) =>
      p.showOpenButton ? '110px' : ''};
  border-bottom: 1px solid ${PALETTE.grey11};
  background: ${PALETTE.white};
  margin-bottom: 16px;
  grid-gap: 0px;
`;

export const DetailCellWrapper = styled.div`
  padding: 0px 10px;
  display: flex;
  align-items: ${({ align }) => align};
  font-size: ${(p) => (p.mediumText ? '16px' : 'inherit')};
  flex-direction: row;
  ${({ border }) => (border ? `border: solid 1px ${PALETTE.grey2}` : 'border: none')};
`;

export const DetailItemWraper = styled.div`
  flex-direction: cell;
  line-height: 1.5;
  padding: 0px 10px;

  & ${DetailCellWrapper} {
    padding: 0px;
  }
`;

export const DetailMopWraper = styled(DetailItemWraper)`
  grid-column: 2/4;
`;
export const DetailVinWraper = styled(DetailItemWraper)`
  grid-column: 5/7;
`;

export const DetailPhoneWraper = styled(DetailItemWraper)`
  grid-column: 4/4;
`;

export const DetailCellLabel = styled.div`
  color: ${addRgbOpacity(PALETTE.black1, 0.5)};
  padding-right: 6px;
`;

export const DetailCellValue = styled.div`
  word-break: ${(p) => (p.wordBreak ? ' break-all' : 'inherit')};
  color: ${(p) => (p.black ? PALETTE.dark : addRgbOpacity(PALETTE.black1, 0.4))};
  text-transform: ${(p) => (p.txtCap ? 'capitalize' : 'none')};
`;

export const DetailItemCarName = styled(DetailCellValue)`
  padding: 0px 10px;
  font-size: 16px;
`;

export const LampContainer = styled.div`
  position: relative;
  // height: 18px;
  // width: 18px;
  ${({ noMargin }) => (noMargin ? '' : 'margin-right: 10px;')}
`;

export const Lamp = styled.div`
  width: 18px;
  height: 18px;
  display: flex;
  border-radius: 50%;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  align-items: center;
  justify-content: center;
  position: relative;
  background: ${({ color }) => color};
  color: ${({ valueColor }) => valueColor};
  ${({ color }) => (color === '#FFFFFF' ? 'border: 1px solid #a8a8a8;' : '')}
`;

export const State = styled.div`
  height: 18px;
  padding: 0 9px;
  display: inline-flex;
  border-radius: 9px;
  font-weight: normal;
  font-size: 10px;
  line-height: 16px;
  text-align: center;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  position: relative;
  background: ${({ color }) => color};
  color: ${({ valueColor }) => valueColor};
  ${({ color }) => (color === '#FFFFFF' ? 'border: 1px solid #a8a8a8;' : '')}
`;

export const SubLabel = styled.div`
  color: rgb(168, 168, 168);
  width: 100%;
  text-align: center;
  font-size: 10px;
  line-height: 16px;
  margin-top: 4px;
  height: 18px;
`;

export const StateContainer = styled.div`
  max-height: 22px;
  min-height: 18px;
  display: flex;
  flex-direction: column;
`;

export const LampTooltip = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-size: 10px;
  border-radius: 7px;
  padding: 2px 5px;
  position: relative;
  text-align: center;
  width: 100px;
  margin-bottom: 10px;
  position: absolute;
  top: -27px;
  right: -34px;
  background: ${({ color }) => color};
  color: ${({ valueColor }) => valueColor};
  -webkit-box-shadow: 0px 0px 2px 0px rgba(50, 50, 50, 0.4);
  -moz-box-shadow: 0px 0px 2px 0px rgba(50, 50, 50, 0.4);
  box-shadow: 0px 0px 2px 0px rgba(50, 50, 50, 0.4);

  &:after {
    content: '';
    display: block;
    border-style: solid;
    position: absolute;
    margin: 0;
    left: 50%;
    bottom: -14px;
    border-width: 7px;
    border-right-color: transparent;
    border-top-color: ${({ color }) => color};
    border-left-color: transparent;
    border-bottom-color: transparent;
  }
`;

export const SemaphoreWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  width: 127px;
`;

export const PopoverContainer = styled.div`
  background: ${(props) => props.backgroundColor || 'white'};
  border: 1px solid #a8a8a8;
  width: 250px;
  min-height: ${({ height }) => height}px;
  padding: 20px;
  color: black;
  font-style: normal;
  font-weight: normal;
  position: absolute;
  font-size: 12px;
  ${({ position }) => (position === 'top' ? 'bottom: 23px' : 'top: 23px')};
  left: -125px;
  z-index: 100;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(50, 50, 50, 0.4);
  -moz-box-shadow: 0px 0px 5px 0px rgba(50, 50, 50, 0.4);
  box-shadow: 0px 0px 5px 0px rgba(50, 50, 50, 0.4);

  &:after {
    content: '';
    display: block;
    border-style: solid;
    position: absolute;
    margin: 0;
    left: 50%;
    ${({ position }) => (position === 'top' ? 'bottom: -14px' : 'top: -14px')};
    border-width: 7px;
    border-right-color: transparent;
    border-top-color: ${({ position }) => (position === 'top' ? '#a8a8a8' : 'transparent')};
    border-left-color: transparent;
    border-bottom-color: ${({ position }) => (position === 'top' ? 'transparent' : '#a8a8a8')};
  }
`;

export const PopoverLabel = styled.div`
  display: inline-block;
  border-radius: 5px;
  width: max-content;
  padding: 2px 5px;
  font-size: 10px;
  background: ${({ color }) => color};
  color: ${({ valueColor }) => valueColor};
`;

export const PopoverItem = styled.span`
  display: inline-block;
  width: 100%;
  margin-bottom: 5px;
`;

export const PopoverFlexItem = styled.span`
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;

  * {
    white-space: nowrap;
  }
  * + * {
    margin-left: 1rem;
  }
`;

export const PopoverInner = styled.div`
  overflow-y: auto;
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
`;

export const GridWraper = styled.div`
  display: grid;
  margin: 9px 12px 0px;
  border-radius: 8px;
  border: 1px solid ${PALETTE.grey11};
  overflow: hidden;
  > :first-child {
    padding-left: 20px;
    border-top: none;
  }
`;

export const DealItemApprovedContainer = styled(DetailRow)`
  height: 48px;
  color: rgba(24, 24, 26, 0.7);

  order: 1;
  &::before {
    content: '';
    width: 3px;
    height: 120%;
    position: absolute;
    left: 0px;
    top: -5px;
    z-index: 1;
    ${({ color }) =>
      color === PALETTE.white
        ? `
      background: ${PALETTE.grey1};
    `
        : `
      background: ${color};
    `}
  }
  > :last-child {
    padding-right: 5px;
  }
`;

export const DealItemStatusContainer = styled(DealItemApprovedContainer)`
  height: 100%;
  min-height: 44px;
  padding: 5px 0px;
  color: rgba(24, 24, 26, 0.7);
  grid-template-columns: 16.5% 1fr;
  order: 2;
`;

export const BankLogoIcon = styled(BankLogo)`
  width: 20px;
  height: 20px;
  margin-right: 8px;
  border-radius: 50%;
`;

export const DealersProduct = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
`;

export const Bank = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: black;
  margin-bottom: 5px;
`;

export const Product = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: black;
`;

export const DealerProfit = styled.div`
  display: flex;
  flex-direction: column;
  width: 20%;
`;

export const DealerProfitTotal = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: black;
`;

export const DealerProfitLabel = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #a8a8a8;
`;

export const DealStatus = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10%;
`;

export const DealsListContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 450px;
  overflow-y: auto;
  position: relative;
  width: 100%;
`;

export const DealsWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
`;

export const SAUContainer = styled.div`
  border-top: 1px solid #b5b5b5;
  padding-right: 20px;
  padding-left: 20px;
  display: flex;
  align-items: center;
`;

export const SAUTextarea = styled.textarea`
  margin-top: 14px;
  // max-width: 560px;
  flex-grow: 1;
  font-size: 16px;
  line-height: 19px;
  border: 0;
  resize: none;
`;

export const SAUButton = styled(Button)`
  padding: 7px;
  width: 30px;
  height: 30px;
  color: white;
  background: #228b22;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    margin-right: 0;
  }
`;

export const SAUButtonPure = styled(SAUButton)`
  background: ${PALETTE.white};
  border: none;
  border: 0;
  color: #228b22;
`;

export const ClipImg = styled(ClipSvg)`
  path {
    fill: #a8a8a8;
  }
`;

export const SendMessageImg = styled(SendMessageSvg)`
  width: 13px;
  height: 13px;

  path {
    fill: ${PALETTE.white};
  }
`;

export const ApplicCommentContainer = styled.div`
  display: flex;
  position: relative;
  padding: 7px;
  background-color: ${PALETTE.white};
  margin: 16px 12px 0px;
  border-radius: 8px;
`;

export const ApplicCommentTextarea = styled.textarea`
  height: 100%;
  background-color: ${PALETTE.grey9};
  color: rgba(24, 24, 26, 0.7);
  flex-grow: 1;
  padding: 14px 16px;
  border-radius: 5px;
  font-size: 16px;
  border: 0;
  resize: none;
  font-style: normal;
  font-weight: normal;
  &::placeholder {
    color: rgba(24, 24, 26, 0.4);
  }
`;

export const ApplicOpenButton = styled(Button)`
  border: 1px solid #b5b5b5;
  color: #a8a8a8;
  height: 32px;
  width: 80px;
  padding: 0;
  font-size: 14px;
`;

export const StatusDatetime = styled.div`
  text-align: right;
  color: ${({ position }) => (position === 'left' ? '#A8A8A8' : 'white')};
  font-size: 10px;
  line-height: 150%;
`;

export const KsoMopWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Kso = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  text-align: center;
`;

export const Mop = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  text-align: center;
`;

export const ApplicCreator = styled.span`
  color: #a8a8a8;
  display: block;
  // background: #e4e4e4;
  // padding: 0 3px;
`;

export const ApplicCreatorAsterisk = styled.span`
  color: black;
  display: block;
  margin-left: 5px;
`;

export const CreatorTitle = styled.span`
  text-align: left;
`;

export const DealStatusWrapper = styled(DealItemApprovedContainer)`
  display: flex;
  width: 100%;
  height: 50px;
  padding-right: 20px;
  justify-content: space-between;
  align-items: center;
`;

export const StatusElementWraper = styled.div`
  display: flex;
  align-items: center;
  ${(p) => (p.printHiden ? 'visibility: hidden' : 'visibility: visible')};
  cursor: pointer;
  &:active {
    stroke: ${PALETTE.green3};
  }
`;

export const DifferenceIco = styled(DifferenceIcoSvg)`
  width: 10px;
  height: 10px;
  margin-left: 5px;
  margin-bottom: 2px;
`;

export const StatusWraper = styled(StatusElementWraper)`
  height: 26px;
  border-radius: 100px;
  font-size: 15px;
  background-color: ${({ color }) => color};
  margin: 12px 0px;
  padding: 0px 12px;
  cursor: default;
  ${({ color }) =>
    color === PALETTE.white
      ? `
      border: 1px solid ${PALETTE.grey1};
      color: ${PALETTE.grey5};
    `
      : `
      border: 1px solid transparent;
      color: ${PALETTE.white};
    `}
  :last-child {
    padding-right: 12px;
  }
`;

export const PrintIco = styled(PrintSvg)`
  width: 15px;
  height: 15px;
  margin-left: 10px;
`;

export const CommentBankIco = styled(CommentBankSvg)`
  width: 15px;
  height: 13.5px;
  margin-right: 10px;
`;

export const WerticalWraper = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  line-height: 1;
  height: 100%;
  padding: 5px 0px;
`;

export const DetailParamCell = styled.span`
  padding-left: 20px;
  word-break: break-word;

  &:first-child {
    align-self: start;
  }
  &:last-child {
    align-self: end;
  }
`;

export const SentBankIco = styled(SentBankIcoSvg)`
  width: 22px;
  height: 22px;
  margin-right: 6px;
  position: absolute;
`;

export const SentBankOkIco = styled(SentBankOkIcoSvg)`
  width: 22px;
  height: 22px;
  margin-right: 6px;
  position: absolute;
`;

export const TextWraper = styled.div`
  margin-left: 26px;
`;
export const InfoIco = styled(InfoLightSvg)`
  width: 12px;
  height: 12px;
  margin-left: 5px;
  margin-bottom: 2px;
`;

export const ProductInfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
`;
export const ProductInfoWrap = styled.div`
  width: 200px;
`;
export const ProductInfoTitle = styled.div`
  font-weight: bold;
  padding-bottom: 15px;
  padding-top: 5px;
`;
