import React from 'react';
import * as B from 'components/pages/Applications/components/Banner';
import * as DC from 'constants/dealerCenters';

export const findBanner = (dealerId) => {
  //save indexing
  const dealerGroup = [
    DC.CheryNew,
    DC.PangoNew,
    DC.OmodaNew,
    DC.ExeedNew,
    DC.JaecooNew,
    DC.EbannerGroupTwo,
  ];
  const bannrList = {
    0: <B.CherryBanner />,
    1: <B.PangoBanner />,
    2: <B.OmodaBanner />,
    3: <B.ExeedBanner />,
    4: <B.JaecooBanner />,
    5: <B.EbannerGroupTwo />,
  };

  for (let i = 0; i < dealerGroup.length; i++) {
    if (dealerGroup[i].has(dealerId)) {
      return bannrList[i];
    }
  }
  return <B.Banner />;
};
