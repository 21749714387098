import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { AddDriverButton } from './components/AddDriverButton';
import { DriverForm } from '../Driver/index';
import { BlockWrapper, Header } from '../Form/style';
import {
  insAnketaChangeDriver,
  insAnketaSetError,
  insAnketaDeleteError,
  insAnketaChangeByKey,
  insAnketaAddDriver,
  insAnketaAddConfidant,
  insAnketaDeleteDriver,
} from 'store/insuranceAnketa';
import { DRIVERS_OVERFLOW_ERR_MESSAGE, MAX_DRIVERS } from '../../constants';

import * as BubbleHelper from '../../../DriversBubbles/helper';
import { onBlurDriverBlockHelper, onBlurBlockHelper } from '../../helpers/blockHelpers';
import { BubblesWrapper, Bubbles, MultidriveNotification } from './style';

const fn = () => {};

const DriversForm = ({ anketaState, persons, onScroll = fn, validateInsurer = fn }) => {
  const rootDispatch = useDispatch();
  const { addToast, toastStack } = useToasts();

  const {
    drivers,
    errors,
    isDisabled: disabled,
    isDriver,
    kaskoInsurerIsDriver,
    scrollTo,
    currentSavedData,
  } = anketaState;
  const { currentMultidrive } = useSelector((state) => state.insuranceCalculation);

  const currentDrivers = drivers.filter((d) => !d.isDeleted);
  const allDriversLimit = MAX_DRIVERS - (Number(isDriver) || Number(kaskoInsurerIsDriver));

  const createToast = () => {
    if (!toastStack.length) {
      addToast(DRIVERS_OVERFLOW_ERR_MESSAGE, { appearance: 'success', autoDismiss: false });
    }
  };

  // methods for one driver

  const getBlurHandler = ({ driver }) => {
    if (driver.isDriver) {
      return async ({ value, state }) => {
        const key = Object.keys(value)[0];

        if (key === 'gender') {
          return rootDispatch(insAnketaChangeByKey({ key: 'gender', value: value.gender }));
        }

        const { values, errors: blurHandlerErrors } = await onBlurBlockHelper({ value, state });

        if (!values && !blurHandlerErrors) {
          return;
        }

        rootDispatch(insAnketaChangeByKey(values));

        validateInsurer({ value, state });
      };
    }

    return async ({ value, state }) => {
      const key = Object.keys(value)[0];

      if (key === 'gender') {
        return rootDispatch(
          insAnketaChangeDriver({ id: driver.id, key: 'gender', value: value.gender })
        );
      }

      const { values, errors: blurHandlerErrors } = await onBlurDriverBlockHelper({
        value,
        state,
        driver,
      });

      if (!values && !blurHandlerErrors) {
        return;
      }

      rootDispatch(insAnketaChangeDriver(values));

      if (Object.keys(blurHandlerErrors).length === 1) {
        rootDispatch(insAnketaDeleteError(blurHandlerErrors));
      } else {
        rootDispatch(insAnketaSetError(blurHandlerErrors));
      }
    };
  };

  const getSwitchHandler = ({ driver }) => {
    const keys = ['prevLicenseSeriaNum', 'prevLicenseDate'];

    return async ({ isFirstLicense }) => {
      if (isFirstLicense) {
        rootDispatch(
          insAnketaChangeDriver({ id: driver?.id, key: 'prevLicenseSeriaNum', value: '' })
        );
        rootDispatch(insAnketaChangeDriver({ id: driver?.id, key: 'prevLicenseDate', value: '' }));
        rootDispatch(
          insAnketaDeleteError({ id: `driver@${driver?.id}`, key: 'prevLicenseSeriaNum' })
        );
        rootDispatch(insAnketaDeleteError({ id: `driver@${driver?.id}`, key: 'prevLicenseDate' }));
      } else {
        const savedDriver = currentSavedData?.drivers.find((saved) => saved.id === driver?.id);

        keys.forEach((key) => {
          const value = savedDriver ? savedDriver[key] : '';

          rootDispatch(insAnketaChangeDriver({ id: driver?.id, key, value }));
          rootDispatch(insAnketaDeleteError({ id: `driver@${driver?.id}`, key }));
        });
      }

      rootDispatch(
        insAnketaChangeDriver({ id: driver?.id, key: 'isFirstLicense', value: isFirstLicense })
      );
    };
  };

  const addDriverHandler = (driver) => {
    if (currentDrivers.length >= allDriversLimit) {
      return createToast();
    }

    if (driver) {
      return rootDispatch(insAnketaAddConfidant(driver));
    }

    rootDispatch(insAnketaAddDriver());
  };

  const deleteDriverHandler = (id) => () => {
    rootDispatch(insAnketaDeleteDriver({ id }));
  };

  const bubbles = BubbleHelper.createBubblesArray(anketaState);

  const driversArray = currentDrivers.map((driver, index) => {
    const onDelete = deleteDriverHandler(driver.id);

    return (
      <DriverForm
        key={driver.id}
        driver={driver}
        scrollTo={scrollTo}
        counter={index + 1}
        errors={errors[`driver@${driver.id}`]}
        disabled={disabled}
        onScroll={onScroll}
        onDelete={onDelete}
        onBlur={getBlurHandler({ driver })}
        onSwitch={getSwitchHandler({ driver })}
      />
    );
  });

  return (
    <BlockWrapper>
      <Header style={{ marginBottom: 20 }}>Водители</Header>
      {currentMultidrive && (
        <MultidriveNotification>
          Полис оформляется на условиях допуска к управлению транспортным средством неограниченного
          круга лиц.
        </MultidriveNotification>
      )}
      {!currentMultidrive && (
        <>
          <BubblesWrapper>
            <Bubbles items={bubbles} clickable={false} showMultidrive={false} />
          </BubblesWrapper>
          {driversArray}
          {!disabled && (
            <AddDriverButton
              persons={persons}
              creditId={anketaState.id}
              onClick={addDriverHandler}
              title={'Добавить водителя'}
            />
          )}
        </>
      )}
    </BlockWrapper>
  );
};

export default DriversForm;
