import styled from 'styled-components';

export const Backdrop = styled.div`
  position: ${({ fixed }) => (fixed ? `fixed` : 'absolute')};
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background: ${({ color }) => color || 'rgba(240, 240, 240, 0.8)'};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

export const Img = styled.img`
  max-width: 100%;
  height: auto;
  display: block;
`;
