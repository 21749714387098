import { takeLatest, select, put } from 'redux-saga/effects';

import { updateCustomer, createWorksheet } from 'data-providers/startPage';
import * as provider from 'data-providers/calculatorProvider';

import { SET_APP_ERROR } from 'store/app/constants';
import * as actions from './constants';

function* approval({ payload }) {
  const callback = (res, worksheet) => {
    if (payload.callback) {
      payload.callback(res, worksheet);
    }
  };

  const carInstanceId = yield select((state) => state.carInstance?.carInstance?.id);
  const dealerId = yield select((state) => state.user.dealer);
  const { id: customerId } = yield select(({ startPage }) => startPage?.customer || {});
  const orders = yield select(({ startPage }) => startPage?.application?.orders || []);
  if (!customerId) {
    callback(false);
    return;
  }

  try {
    if (payload.customer) {
      const {
        name = 'Имя',
        surname = 'Фамилия',
        middleName = '',
        mobilePhone = '',
      } = payload.customer;

      const customerData = {
        personalInformation: { name, surname, middleName },
        contacts: { mobilePhone },
      };

      yield updateCustomer(customerId, customerData);
    }

    const approvalReqData = {
      ...payload.data,
      client_data: {
        front_user_credmen_id: payload?.customer?.front_user_credmen_id,
        manager_name: payload?.customer?.manager_name,
      },
    };
    let worksheetData;
    const creditOrder = orders.find((o) => o.productId === 'credit');
    if (!creditOrder) {
      worksheetData = yield createWorksheet(dealerId, carInstanceId, customerId);
      approvalReqData.applicationId = Number(worksheetData?.order?.applicationId);
    }
    const data = yield provider.approval(approvalReqData);
    callback(data, worksheetData);
  } catch (_e) {
    console.error(_e);
    yield put({
      type: SET_APP_ERROR,
      payload:
        'При сохранении расчета возникла ошибка. Обновите страницу и попробуйте повторно сохранить расчет.',
    });
    callback(null);
  }
}

function* logPicker({ payload }) {
  if (!payload?.id || payload?.calculation?.change_id === undefined) {
    return;
  }

  const changeId = Number(payload.calculation.change_id);
  if (isNaN(changeId)) {
    return;
  }

  yield provider.logPicker(payload.id, changeId + 9);
}

export function* watchApproval() {
  yield takeLatest(actions.CALCULATOR_APPROVAL, approval);
}

export function* watchLogPicker() {
  yield takeLatest(actions.CALCULATOR_PICKER_LOG, logPicker);
}
