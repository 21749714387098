import styled from 'styled-components';
import { Grid } from 'components/primitives/grid';
import { SubHeader, InputMaskStyled } from '../../../Form/style';

export const Wrapper = styled(Grid)`
  margin-top: 41px;
  width: 100%;
  overflow: visible;
  grid-template-columns: repeat(8, 1fr);
  grid-template-areas:
    '. title title title title title title .'
    '. phone phone email email email email .';
`;

export const Title = styled(SubHeader)`
  grid-area: title;
`;

export const Phone = styled(InputMaskStyled)`
  grid-area: phone;
  margin-bottom: 15px;
`;

export const Email = styled(InputMaskStyled)`
  grid-area: email;
  margin-bottom: 15px;
`;
