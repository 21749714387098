import { PALETTE } from 'styles/constants';
import { SCROLL_SELECTORS } from 'constants/deal';

export const stateList = [
  {
    title: 'Ознакомление',
    state: 0,
    view: true,
    open: false,
    timer: false,
    color: PALETTE.green1,
    sub: [],
  },
  {
    title: 'Подготовка сделки',
    state: 0,
    view: true,
    open: false,
    timer: false,
    color: PALETTE.green1,
    sub: [
      {
        title: 'Автомобиль',
        state: 0,
        view: true,
        timer: false,
        color: PALETTE.green1,
        scrollTo: SCROLL_SELECTORS.car,
      },
      {
        title: 'Реквизиты',
        state: 0,
        view: true,
        timer: false,
        color: PALETTE.green1,
        scrollTo: SCROLL_SELECTORS.requisites,
      },
    ],
  },
  {
    title: 'Согласование сделки',
    state: 0,
    view: true,
    open: false,
    timer: false,
    color: PALETTE.green1,
    sub: [
      {
        title: 'Сделка передана в банк',
        state: 0,
        view: true,
        timer: false,
        color: PALETTE.green1,
      },
      {
        title: 'Сделка на согласовании',
        state: 0,
        view: true,
        timer: false,
        color: PALETTE.green1,
      },
      {
        title: 'Отказ банка',
        state: 0,
        view: false,
        timer: false,
        color: PALETTE.red1,
      },
      {
        title: 'Запрос банка',
        state: 0,
        view: false,
        timer: false,
        color: PALETTE.green1,
      },
      {
        title: 'Сделка одобрена банком',
        state: 0,
        view: true,
        timer: false,
        color: PALETTE.green1,
      },
      {
        title: 'Запрошены документы',
        state: 0,
        view: true,
        timer: false,
        color: PALETTE.green1,
      },
      {
        title: 'Запрос информации',
        state: 0,
        view: false,
        timer: false,
        color: PALETTE.blue1,
      },
    ],
  },
  {
    title: 'Оформление сделки',
    state: 0,
    view: true,
    open: false,
    timer: false,
    color: PALETTE.green1,
    sub: [
      {
        title: 'Документы получены',
        state: 0,
        view: true,
        timer: false,
        color: PALETTE.green1,
      },
      {
        title: 'Заявление отправлено',
        state: 0,
        view: true,
        timer: false,
        color: PALETTE.green1,
      },
      {
        title: 'Заявление клиента отправлено в банк',
        state: 0,
        view: false,
        timer: false,
        color: PALETTE.green1,
      },
      {
        title: 'Запрос банка',
        state: 0,
        view: false,
        timer: false,
        color: PALETTE.green1,
      },
      {
        title: 'Банк проверяет документы',
        state: 0,
        view: true,
        timer: false,
        color: PALETTE.green1,
      },
    ],
  },
  {
    title: 'Выдача кредита',
    state: 0,
    view: true,
    open: false,
    timer: false,
    color: PALETTE.green1,
    sub: [
      {
        title: 'Получено гарантийное письмо',
        state: 0,
        view: true,
        timer: false,
        color: PALETTE.green1,
      },
    ],
  },
  {
    title: 'Закрыта',
    state: 0,
    view: true,
    open: false,
    timer: false,
    color: PALETTE.green1,
    sub: [],
  },
  {
    title: 'Отказ клиента',
    state: 0,
    view: false,
    open: false,
    timer: false,
    color: PALETTE.red1,
    sub: [],
  },
];
