import plural from 'plural-ru';

export const getPdfScheduleRequestData = (raw, params, carName, userName, dealerName) => {
  const paymentTable = raw?.calculation?.payment_table || [];
  const paymentTableHalfLength = Math.ceil(paymentTable.length / 2);
  const paymentTableLeft = paymentTable.slice(0, paymentTableHalfLength);
  const paymentTableRight = paymentTable.slice(paymentTableHalfLength);
  return {
    type: 'payment-schedule',
    data: {
      dealer_name: dealerName,
      manager_name: userName,
      car_name: carName,
      car_credit_price: raw.calculation.car_credit_price,
      contract_price: raw.calculation.contract_price,
      car_price_discounted: raw.calculation.car_price_discounted,
      bank_title: raw.bank_title,
      title: raw.title,
      interest_rate: raw.interest_rate,
      term: raw.period || params.period,
      initial_fee_money: raw.calculation.initial_fee_money,
      credit_amount: raw.calculation.credit_amount,
      payment: raw.calculation.payment,
      overpayment: raw.calculation.overpayment,
      payment_table: paymentTableLeft,
      payment_table_right: paymentTableRight,
    },
  };
};

export const getPdfLoanRequestData = (raw, params, carName, userName, dealerName) => {
  const payment =
    raw.calculation.payment2 === 0 ? raw.calculation.payment : raw.calculation.payment2;
  const payment2 =
    raw.calculation.payment2 === 0 ? raw.calculation.payment2 : raw.calculation.payment;
  return {
    type: 'loan-agreement',
    data: {
      dealer_name: dealerName,
      manager_name: userName,
      car_name: carName,
      car_credit_price: raw.calculation.car_credit_price,
      car_price_discounted: raw.calculation.car_price_discounted,
      contract_price: raw.calculation.contract_price,
      equipment_price: raw.calculation.equipment_price,
      initial_fee_money: raw.calculation.initial_fee_money,
      credit_amount: raw.calculation.credit_amount,
      overpayment: raw.calculation.overpayment,
      car_discount: raw.calculation.car_discount,
      term: raw.period || params.period,
      bank_dops: raw.calculation.bank_dops,
      dealer_service_detailed: getDealerServices(params.others_in_application),
      title: raw.title,
      interest_rate: raw.interest_rate,
      interest_rate_subside: raw.interest_rate_subside,
      bank_title: raw.bank_title,
      payment: payment,
      payment2: payment2,
      kasko_price: raw.calculation.kasko_price,
      kasko_term: getTermTitle(raw.calculation.kasko_term),
      life_price: raw.calculation.life_price,
      life_term: getTermTitle(raw.calculation.life_term),
      gap_price: raw.calculation.gap_price,
      gap_term: getTermTitle(raw.calculation.gap_term),
      dealer_service_price: params.other_price,
      dealer_service_to_credit: getPaymentType(params.other_price_to_credit),
      gap_insurance_to_credit: getPaymentType(params.gap_insurance_to_credit),
      dealer_discount: 0,
      tradein: 0,
      contract_price_gos_subside: 0,
      gos_subside_credit_amount: 0,
    },
  };
};

export const getCarName = (carInstance) => {
  let result = '';
  if (carInstance?.brand) {
    result += carInstance.brand;
  }

  if (carInstance?.model) {
    result += ` ${carInstance.model}`;
  }

  if (carInstance?.version) {
    result += ` ${carInstance.version}`;
  }

  return result;
};

export const getTermTitle = (term) => {
  if (term === 0) {
    return 'На весь срок кредита';
  } else {
    return plural(term, '%d месяц', '%d месяца', '%d месяцев');
  }
};

export const getDealerServices = (services = []) => {
  return services.map((service) => {
    return {
      title: service.name,
      price: service.price,
    };
  });
};

export const getPaymentType = (type) => {
  switch (type) {
    case 1:
      return 'В кредит';
    case 0:
      return 'Наличные';
    default:
      return null;
  }
};

export const getUserName = (user) => {
  let result = '';
  if (user.lastname) {
    result = user.lastname;
  }
  if (user.firstname) {
    result += ` ${user.firstname}`;
  }

  return result;
};

export const getPdfBlob = (str) => {
  const binary = atob(str.replace(/\s/g, ''));
  const len = binary.length;
  const buffer = new ArrayBuffer(len);
  const view = new Uint8Array(buffer);
  for (let i = 0; i < len; i++) {
    view[i] = binary.charCodeAt(i);
  }
  return new Blob([view], { type: 'application/pdf' });
};
