import React, { useEffect, useState } from 'react';

import * as Clipboard from 'utils/clipboard';

import { CopyIco, Wraper } from './style';

export default function ClipboardCopy({ value, children, className }) {
  const [isCopy, setIsCopy] = useState(false);

  useEffect(() => {
    let timeoutId = null;
    if (isCopy) {
      timeoutId = setTimeout(() => setIsCopy(false), 500);
    }

    return () => clearTimeout(timeoutId);
  }, [isCopy]);

  const handleCopyClick = () => {
    if (value) {
      setIsCopy(true);
      Clipboard.copy(value);
    }
  };

  return (
    <Wraper onClick={handleCopyClick} isCopy={isCopy}>
      {children}
      <CopyIco className={className} />
    </Wraper>
  );
}
