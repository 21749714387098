import moment from 'moment';

export const hourFormat = (seconds) => {
  const m = Math.floor(seconds / 60);
  const s = seconds - m * 60;
  const format = (n) => n.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });

  return `${format(m)}:${format(s)}`;
};

export const addYearsWithoutLastDay = (dateString, years = 1) => {
  const isValidDateFormat =
    typeof dateString === 'string' && /\d{2}[-–—.]\d{2}[-–—.]\d{4}/.test(dateString);
  if (!isValidDateFormat) {
    return console.error('addYearsWithoutLastDay has called with invalid format');
  }
  const DATE_FORMAT = 'DD.MM.YYYY';
  return moment(dateString, DATE_FORMAT)
    .add(years, 'years')
    .subtract(1, 'days')
    .format(DATE_FORMAT);
};
