import moment from 'moment';

import { isNotificationsSupported } from 'utils/notification';
import { getBank } from 'components/common/BankLogo/helper';

export const usePushAppNotification = (data, onClick = () => {}) => {
  if (!isNotificationsSupported() || !Notification || Notification.permission !== 'granted') {
    return null;
  }

  let closeTimeout = null;

  const time = moment(data.date).format('DD.MM в HH:mm');
  const bank = getBank(data.bankIds[0], 'pps');
  const bankName = bank ? `Банк: ${bank.name}\n` : '';

  const push = new Notification(`Заявка одобрена для ${data.customer}`, {
    body: `${bankName}Заявка: ${data.applicationId}\nКлиент: ${data.customer}\nВремя: ${time}`,
    icon: bank ? bank.logo : '/img/notification.png',
    tag: data.applicationId,
    silent: true,
  });

  if (!push) {
    return null;
  }

  push.onshow = () => {
    closeTimeout = setTimeout(() => push.close(), 5000);
  };

  push.onclick = () => {
    clearTimeout(closeTimeout);
    push.close();
    window.focus();
    onClick();
  };

  return push;
};
