import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import * as metrica from 'metrika/mainpage';

import * as S from './style';

export const UserPanel = ({ setShowInfo }) => {
  const user = useSelector((store) => store.user);
  const { dealer, dealers, role, roles } = useSelector((store) => store.user);
  const [roleName, setRoleName] = useState('');
  const [dealerName, setDealerName] = useState('');

  useEffect(() => {
    if (roles && roles.length > 0) {
      const matchedRole = roles.find((r) => role === r.id);
      if (matchedRole) {
        setRoleName(matchedRole.name);
      } else {
        setRoleName('');
      }
    }
  }, [role, roles]);

  useEffect(() => {
    if (dealers && dealers.length > 0) {
      const matchedDealer = dealers.find((item) => dealer === item.id);
      if (matchedDealer) {
        setDealerName(matchedDealer.name);
      } else {
        setDealerName('');
      }
    }
  }, [dealer, dealers]);

  return (
    <S.UserPanel
      onClick={() => {
        setShowInfo(true);
        metrica.userPanelButtonTrack();
      }}
    >
      <S.WrapUp>
        <S.AvatarContainer>
          {user.avatar ? (
            <S.Avatar
              src={user.avatar}
              alt={'img :('}
              title={`${user.lastname} ${user.firstname}`}
            />
          ) : (
            <S.AvatarSvgStyled title={`${user.lastname} ${user.firstname}`} />
          )}
        </S.AvatarContainer>
        <S.Title>
          {user.firstname} {user.lastname.charAt(0)}. <br />
        </S.Title>
        <S.Button>
          <S.StyledArrowRight alt={'img :('} />
        </S.Button>
      </S.WrapUp>
      <S.WrapDown>
        <S.Title>
          <h5>{roleName}</h5>
          <h5>{dealerName}</h5>
        </S.Title>
      </S.WrapDown>
    </S.UserPanel>
  );
};
