import styled, { css } from 'styled-components';
import { PALETTE } from 'styles/constants';

export const Wrapper = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  padding: 0;
  margin-left: ${({ leftShift }) => leftShift || 0};
`;

export const Item = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  min-width: 25px;
  color: ${(p) => (p.active ? PALETTE.black : PALETTE.grey1)};

  cursor: ${(p) => (p.active ? 'default' : 'pointer')};
  padding: 0 8px;
  font-size: 14px;
  line-height: 16px;
  will-change: transform;
  transform-origin: center;
  transition: transform 20ms linear;
  user-select: none;
  transform: ${(p) => (p.active ? 'scale(1.02)' : 'scale(1.01)')};
  overflow: hidden;
  ${(p) =>
    p.clear &&
    css`
      border: 0;
      color: inherit;
    `};
  &:not(:last-child) {
    margin-right: 10px;
  }
  ${(p) =>
    p.disabled &&
    css`
      pointer-events: none;
      cursor: default;
      opacity: 0.7;
    `}
  ${(p) =>
    !p.disabled &&
    !p.active &&
    css`
      &:hover {
        background-color: ${(p) => (!p.clear ? PALETTE.grey4 : 'transparent')};
        color: ${PALETTE.black};
      }
      &:active {
        transform: scale(0.94);
      }
    `}
`;
