import { ASW_NAMEPLATES } from './resources';

export const initialState = {
  applications: {},
  errors: {},
  nameplate: ASW_NAMEPLATES.unknown,
  lastModifiedAt: 0,
  fetchStates: {},
  aswMainFetchStates: {},
  applicationsPopupData: {},
  dealerLegals: {
    currentDealerId: null,
    legalsList: [],
  },
};
