import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import { PALETTE } from 'styles/constants';

export const Container = styled.nav`
  display: flex;
  height: 60px;
  margin-right: 25px;
`;

export const Link = styled(NavLink)`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0px 15px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  color: ${PALETTE.dark};
  border-bottom: 3px solid transparent;

  &.active {
    color: ${PALETTE.red1};
    border-bottom-color: ${PALETTE.red1};

    svg * {
      fill: ${PALETTE.red1};
    }
    &:has(svg:hover) {
      color: ${PALETTE.red1};
    }
  }
  &:has(svg:hover) {
    color: ${PALETTE.dark};
  }

  & + & {
    margin-left: 10px;
  }
`;
