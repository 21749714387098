import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { uploadFiles } from 'data-providers/mainAnketaProvider';
import { getRecognizeFile, deleteFile } from 'data-providers/documentProvider';
import { Alert } from 'components/primitives/alert';
import {
  addWaitingFile,
  checkRecognizedFiles,
  removeWaitingFile,
} from 'store/fileRecognizer/actions';
import { useToasts } from 'react-toast-notifications';
import { DragDropStyled } from './style';
import { FileView } from '../../FileManager/FileView';

const validFileFormats = ['JPG', 'JPEG', 'PDF', 'PNG'];

export const FileUpload = ({
  applicationId,
  currentPersonId,
  onLoadFiles,
  loading,
  classification,
  title,
  disabled = false,
  contentHidden = false,
}) => {
  const [files, setFiles] = useState(null);
  const [total, setTotal] = useState(0);
  const [process, setProcess] = useState(null);
  const [intrvalCheck, setIntervalCheck] = useState(null);
  const dragDropRef = useRef();
  const { addToast } = useToasts();
  const waitingFiles = useSelector((state) => state.fileRecognizer.waitingFiles);
  const { documents } = useSelector((state) => state.anketa);
  const rootDispatch = useDispatch();
  const [deleteType, setDeleteType] = useState(null);
  const [docObj, setDocObj] = useState(null);

  const handleDeleteClick = () => {
    setDeleteType({
      type: 'type',
      list: documents.filter((d) => classification === d.type.sysName),
      docType: classification,
    });
  };

  const handleFileUpload = (validFiles) => {
    const formData = new FormData();
    validFiles.forEach((file) => {
      formData.append('file[]', file);
    });

    const params = {
      entryName: 'customer',
      entryId: currentPersonId,
      classification: classification,
    };

    formData.append(
      'payload',
      new Blob([JSON.stringify(params)], { type: 'application/json' }),
      'payload.json'
    );

    setProcess(1);
    uploadFiles({ applicationId, formData })
      .then((res) => {
        setProcess(2);
        setTotal(total + res.length);

        res.forEach((r) => rootDispatch(addWaitingFile({ id: r.id, uuid: r.uuid })));
        rootDispatch(checkRecognizedFiles());
        const timer = setInterval(() => {
          getRecognizeFile({ id: res.map((r) => r.uuid) })
            .then((files) => {
              if (files.length > 0) {
                files.forEach(
                  ({ id, status }) =>
                    status === 'PROCESSED' && rootDispatch(removeWaitingFile({ uuid: id }))
                );
              }
            })
            .catch((err) => {
              clearTimeout(timer);
              console.log('error', err);
              addToast('Документы не удалось загрузить, попробуйте еще раз', {
                appearance: 'error',
              });
              setProcess(0);
              setFiles(null);
            });
        }, 5000);
        setIntervalCheck(timer);
      })
      .catch((err) => {
        setProcess(0);
        addToast(err.response.data.error, {
          appearance: 'error',
        });
        setFiles(null);
      });
  };

  useEffect(() => {
    setProcess(loading ? 2 : 0);
  }, [loading]);

  useEffect(() => {
    if (files) {
      if (!files.length) {
        return;
      }

      const validFiles = files.filter((file) => {
        const splittedType = file.type.split('/');

        let type = splittedType[splittedType.length - 1] || '';
        type = type.toUpperCase();

        return validFileFormats.includes(type);
      });

      if (!validFiles.length) {
        addToast('Неверный формат файлов', {
          appearance: 'error',
        });
        setProcess(0);
        setFiles(null);
        setTotal(0);
      } else {
        handleFileUpload(validFiles);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  useEffect(() => {
    if (0 === waitingFiles.length && process === 2) {
      clearInterval(intrvalCheck);
      onLoadFiles(() => {
        setProcess(3);
        setFiles(null);
        setTotal(0);
        addToast('Распознавание документов завершено', {
          appearance: 'success',
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waitingFiles]);

  useEffect(() => {
    if (documents && documents.find((d) => classification === d.type.sysName)) {
      setProcess(3);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documents]);

  const agreeDelete = () => {
    if (deleteType && deleteType.type === 'type') {
      handleDeleteType(deleteType.list, deleteType.docType);
      if (docObj) setDocObj(null);
    }
  };

  const handleDeleteType = async (list, type) => {
    if (list) {
      setDeleteType(null);
      const otherDocs = documents.filter((d) => classification !== d.type.sysName);
      updateFiles(otherDocs);
      setProcess(0);
      setFiles(null);
      setTotal(0);
      await Promise.all(
        [...new Array(list.length)].map(async (num, i) => {
          await deleteFile(Number(applicationId), list[i].id, list[i].entryId, type);
        })
      );
      addToast('Удаление документов завершено', {
        appearance: 'success',
      });
    }
  };

  const updateFiles = (data) => {
    if (data) {
      rootDispatch({
        type: 'UPLOAD_COMMON',
        payload: {
          documents: data,
        },
      });
    }
  };

  const showFile = (e) => {
    e.stopPropagation();
    setDocObj({
      type: { id: 3295, sysName: classification },
      list: documents.filter((d) => classification === d.type.sysName),
    });
  };

  return (
    <>
      <DragDropStyled
        ref={dragDropRef}
        process={process}
        small={true}
        multiple={false}
        disabled={disabled}
        filesLeft={total - waitingFiles.length || 0}
        filesTotal={total}
        onChange={setFiles}
        clear={!files}
        deleteClick={() => !contentHidden && handleDeleteClick()}
        showFile={(e) => !contentHidden && showFile(e)}
        contentHidden={contentHidden}
        title={title}
        centered={true}
      />
      {docObj && (
        <FileView
          applicationId={applicationId}
          data={docObj}
          onClose={() => setDocObj(null)}
          onDelete={handleDeleteClick}
        />
      )}
      {deleteType && (
        <Alert
          title="Вы действительно хотите удалить документ?"
          desc="Документ удаляется безвозвратно"
          agreeText="Удалить"
          cancelText="Отмена"
          onAgree={agreeDelete}
          onCancel={() => setDeleteType(null)}
        />
      )}
    </>
  );
};
