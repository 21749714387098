import React from 'react';
import plural from 'plural-ru';
import cloneDeep from 'lodash/cloneDeep';
import PolicyWidget from 'components/primitives/policy';
import { declineData, errorData, newData, clearState } from './constants';
import { INSURANCE_WORKFLOW_STATES } from 'constants/insurance';
import { sortOffersByPriority } from '../Assistance/helper';
import { getPriceWithPenny } from 'utils/convertPrice';
import * as S from './style';

const fn = () => {};

const KaskoWidget = ({
  state,
  title = '',
  cost = '',
  isRedButton = false,
  offers,
  onClick = fn,
}) => {
  const convertedCost = `${getPriceWithPenny(cost).rubles} ₽`;
  const offersToShow = 2;
  const sortedOffers = cloneDeep(offers ?? []).sort(sortOffersByPriority);
  const renderOffers = sortedOffers.slice(0, offersToShow);
  const moreOffers = sortedOffers.length > offersToShow ? sortedOffers.length - offersToShow : 0;

  const kaskoStatuses = {
    [INSURANCE_WORKFLOW_STATES.new]: newData,
    [INSURANCE_WORKFLOW_STATES.offers_requested]: {
      step: 1,
      content: null,
      loading: true,
    },
    [INSURANCE_WORKFLOW_STATES.offers_received]: {
      step: 1,
      content: (
        <>
          {renderOffers.map((offer) => (
            <S.Offer key={offer.id}>
              {!!offer.result && <S.Cost>{`${getPriceWithPenny(offer.result).rubles} ₽`}</S.Cost>}
              <S.CompanyName>
                {offer.title || offer.companyName || offer.insuranceCompanyName}
              </S.CompanyName>
            </S.Offer>
          ))}
          {moreOffers ? (
            <S.Offer>
              {plural(moreOffers, '+%d предложение', '+%d предложения', '+%d предложений')}
            </S.Offer>
          ) : null}

          <S.Button onClick={onClick}>Показать предложения</S.Button>
        </>
      ),
      loading: false,
    },
    [INSURANCE_WORKFLOW_STATES.offer_selected]: {
      step: 2,
      content: (
        <>
          {!!cost && <S.Cost>{convertedCost}</S.Cost>}
          {!!title && <S.CompanyName>{title}</S.CompanyName>}
          <S.Button isRedButton={isRedButton} onClick={onClick}>
            Выпустить
          </S.Button>
        </>
      ),
      loading: false,
    },
    [INSURANCE_WORKFLOW_STATES.agreement_requested]: {
      step: 2,
      content: null,
      loading: true,
    },
    [INSURANCE_WORKFLOW_STATES.agreement_succeed]: {
      step: 3,
      content: (
        <>
          {!!cost && <S.Cost>{convertedCost}</S.Cost>}
          {!!title && <S.CompanyName>{title}</S.CompanyName>}
          <S.Button isRedButton={isRedButton} onClick={onClick}>
            Выпустить
          </S.Button>
        </>
      ),
      loading: false,
    },
    [INSURANCE_WORKFLOW_STATES.issue_requested]: {
      step: 3,
      content: null,
      loading: true,
    },
    [INSURANCE_WORKFLOW_STATES.issued]: {
      step: 4,
      content: (
        <>
          {!!cost && <S.Cost>{convertedCost}</S.Cost>}
          {!!title && <S.CompanyName>{title}</S.CompanyName>}
        </>
      ),
      loading: false,
    },
    [INSURANCE_WORKFLOW_STATES.offers_declined]: declineData,
    [INSURANCE_WORKFLOW_STATES.declined]: declineData,
    [INSURANCE_WORKFLOW_STATES.agreement_declined]: declineData,
    [INSURANCE_WORKFLOW_STATES.issue_decline]: declineData,
    [INSURANCE_WORKFLOW_STATES.client_decline]: declineData,
    [INSURANCE_WORKFLOW_STATES.offers_error]: errorData,
    [INSURANCE_WORKFLOW_STATES.error]: errorData,
    [INSURANCE_WORKFLOW_STATES.agreement_error]: errorData,
    [INSURANCE_WORKFLOW_STATES.issue_error]: errorData,
  };

  const emptyData = {
    step: 0,
    content: (
      <>
        <S.Offer>
          <S.Cost>~ 48 580 ₽</S.Cost>
          <S.CompanyName withGap>Совкомбанк Страхование</S.CompanyName>
        </S.Offer>
        <S.Button onClick={onClick}>Выпустить</S.Button>
      </>
    ),
    loading: false,
  };

  const kaskoStatusData = kaskoStatuses[state.sysName] || emptyData;
  // The status can't be show if the state of policy is new
  const kaskoState = state?.sysName !== INSURANCE_WORKFLOW_STATES.new ? state : clearState;
  if (!kaskoState.name) {
    kaskoState.name = kaskoState.sysName;
  }

  return (
    <PolicyWidget
      title={'КАСКО'}
      state={kaskoState}
      step={kaskoStatusData.step}
      loading={kaskoStatusData.loading}
      content={kaskoStatusData.content}
    />
  );
};

export default KaskoWidget;
