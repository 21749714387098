import styled from 'styled-components';
import { Grid } from 'components/primitives/grid';
import { Popup } from 'components/primitives/popup';
import { Button } from 'components/primitives/button';

export const PopupStyled = styled(Popup)`
  & > div {
    width: 664px;
    min-height: 315px;
  }
  z-index: 9999;
`;

export const Wrapper = styled(Grid)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-top: 30px;
  padding-bottom: 20px;
  grid-template-columns: repeat(6, 1fr);
  gap: 20px;
`;

export const Title = styled.div`
  grid-column: 2 / -2;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 37px;
  text-align: center;
  color: #000;
`;

export const Body = styled.div`
  grid-column: 2 / -2;
  margin-left: auto;
  margin-right: auto;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #000000;
  overflow: hidden;
`;

export const ButtonOk = styled(Button)`
  grid-column: 3 / -3;
  background: #7ebfed;
  border-radius: 5px;
  height: 60px;
`;
