import { put, call, takeLatest, select } from 'redux-saga/effects';
import * as actions from './constants';
import * as authProvider from 'data-providers/authProvider';
import * as carInstanceProvider from 'data-providers/carInstanceProvider';
import schema from './validation';
import { loadCarDictionaries } from './carDictionariesHelper';
import { DEFAULT_ERROR_MESSAGE } from 'constants/index';
import { setAllFetch } from '../carReference';

const getBrandAndModelIds = (carReference, brand, model) => {
  const { brands, models } = carReference;

  const brandId = brands.find(({ name }) => name.toLowerCase() === brand?.toLowerCase())?.id;
  const modelId = models.find(({ name }) => name.toLowerCase() === model?.toLowerCase())?.id;

  return { brandId, modelId };
};

function* getCarInstanceSaga({ payload }) {
  try {
    yield put({ type: actions.SET_FETCH_CAR_INSTANCE, payload: true });

    const originalCarInstance = yield call(carInstanceProvider.getCarInstance, {
      id: payload.id,
    });

    const action = { type: actions.SET_CAR_INSTANCE };

    if (originalCarInstance instanceof Array) {
      throw new TypeError('carInstance is not array, but api call returned array');
    }

    yield schema
      .validate(originalCarInstance)
      .then((carInstance) => {
        const carInstanceWithParsedEnginePower = {
          ...carInstance,
          enginePower: carInstance.enginePower,
        };

        action.payload = {
          carInstance: carInstanceWithParsedEnginePower,
        };
      })
      .catch((err) => {
        throw err;
      });

    const { dealer: dealerId } = yield select((store) => store.user);
    const vehicleStatus = originalCarInstance.isNew ? 'new' : 'old';
    let { instance } = yield loadCarDictionaries(
      ['brand', 'model', 'version'],
      { dealerId, vehicleStatus },
      action.payload.carInstance
    );

    const carReference = yield select((store) => store.carReference);

    const { brandId, modelId } = getBrandAndModelIds(carReference, instance.brand, instance.model);

    if (!brandId) {
      instance.brand = '';
    }

    if (!brandId || !modelId) {
      instance.model = '';
      instance.version = '';
    }

    const models = yield select((store) => store.carReference.models);
    const model = models.find((item) => item.name === instance.model);

    instance.image = model?.image ? model?.image : null;

    action.payload.carInstance = instance;

    yield put({
      type: actions.SET_CAR_INSTANCE,
      payload: { carInstance: action.payload.carInstance },
    });

    yield put({ type: actions.DROP_ERRORS_CAR_INSTANCE });
    yield put(action);
  } catch (err) {
    yield put({
      type: actions.SET_ERROR_CAR_INSTANCE,
      payload: err.customDescription || DEFAULT_ERROR_MESSAGE,
    });
  } finally {
    yield put({ type: actions.SET_FETCH_CAR_INSTANCE, payload: false });
  }
}

function* patchCarInstanceSaga({ payload }) {
  try {
    yield put({
      type: actions.SET_PATCHIG_CAR_INSTANCE,
      payload: true,
    });

    const oldIsNew = yield select((store) => store.carInstance.carInstance.isNew);

    if (oldIsNew !== payload.carInstance.isNew) {
      yield put({ type: actions.PATCH_IS_NEW, payload: true });
    }

    const { id, loadImage = false, phases = [], phPayload } = payload;

    let carInstance = JSON.parse(
      JSON.stringify({
        ...payload.carInstance,
        image: phases.includes('model') ? null : payload.carInstance.image,
      })
    );

    yield put({
      type: actions.SET_CAR_INSTANCE,
      payload: { carInstance: { ...carInstance } },
    });

    if (phases.length) {
      const isVersionChange = phases.includes('version');

      const { dealer: dealerId } = yield select((store) => store.user);
      const vehicleStatus = carInstance.isNew ? 'new' : 'old';
      let { instance } = yield loadCarDictionaries(
        phases,
        { dealerId, ...phPayload, vehicleStatus },
        JSON.parse(JSON.stringify(payload.carInstance))
      );

      if (isVersionChange) {
        const versions = yield select((store) => store.carReference.versions);
        const version = versions.find((item) => item.value === instance.version);
        instance.price = Number(version?.price) || Number(instance.price) || 0;
        instance.enginePower = Number(version?.engine) || Number(instance.enginePower) || 0;
      }

      carInstance = { ...carInstance, ...instance };
    }

    const validCarInstance = yield new Promise((resolve) => {
      schema
        .validate(carInstance)
        .then((_carInstance) => {
          resolve(_carInstance);
        })
        .catch((err) => {
          throw err;
        });
    });

    let newCarInstance;
    if (payload.customerCarInstance) {
      newCarInstance = yield call(carInstanceProvider.updateCarInstanceCustomer, {
        carInstance: validCarInstance,
        id,
      });
    } else {
      newCarInstance = yield call(carInstanceProvider.updateCarInstance, {
        carInstance: validCarInstance,
        id,
      });
    }

    if (!newCarInstance) {
      throw new Error('somthing went wrong [update car instance fail]');
    }

    newCarInstance.image = carInstance.image || '';

    if (loadImage) {
      const models = yield select((store) => store.carReference.models);
      const model = models.find((item) => item.name === carInstance.model);

      newCarInstance.image = model?.image ? model?.image : null;
    }
    yield put({
      type: actions.SET_CAR_INSTANCE,
      payload: { carInstance: { ...newCarInstance } },
    });
    yield put({
      type: actions.SET_PATCHIG_CAR_INSTANCE,
      payload: false,
    });
    yield put(setAllFetch(false));
  } catch (err) {
    yield put({
      type: actions.SET_ERROR_CAR_INSTANCE,
      payload: err.customDescription || DEFAULT_ERROR_MESSAGE,
    });
    yield put({
      type: actions.SET_PATCHIG_CAR_INSTANCE,
      payload: false,
    });
  } finally {
    yield put({ type: actions.PATCH_IS_NEW, payload: false });
  }
}

function* changeCarInstanceSaga({ payload }) {
  try {
    yield put({
      type: actions.SET_PATCHIG_CAR_INSTANCE,
      payload: true,
    });

    const oldIsNew = yield select((store) => store.carInstance.carInstance.isNew);

    if (oldIsNew !== payload.carInstance.isNew) {
      yield put({ type: actions.PATCH_IS_NEW, payload: true });
    }

    const { loadImage = false, phases = [], phPayload } = payload;

    let carInstance = JSON.parse(
      JSON.stringify({
        ...payload.carInstance,
        image: phases.includes('model') ? null : payload.carInstance.image,
      })
    );

    if (phases.length) {
      const isVersionChange = phases.includes('version');

      const { dealer: dealerId } = yield select((store) => store.user);
      const vehicleStatus = carInstance.isNew ? 'new' : 'old';
      let { instance } = yield loadCarDictionaries(
        phases,
        { dealerId, ...phPayload, vehicleStatus },
        JSON.parse(JSON.stringify(payload.carInstance))
      );

      if (isVersionChange) {
        const versions = yield select((store) => store.carReference.versions);
        const version = versions.find((item) => item.value === instance.version);
        instance.price = Number(version?.price) || Number(instance.price) || 0;
        instance.enginePower = Number(version?.engine) || Number(instance.enginePower) || 0;
      }

      carInstance = { ...carInstance, ...instance };
    }

    if (loadImage) {
      const models = yield select((store) => store.carReference.models);
      const model = models.find((item) => item.name === carInstance.model);

      carInstance.image = model?.image ? model?.image : null;
    }

    yield put({
      type: actions.SET_CAR_INSTANCE,
      payload: { carInstance: { ...carInstance } },
    });
    yield put({
      type: actions.SET_PATCHIG_CAR_INSTANCE,
      payload: false,
    });
    yield put(setAllFetch(false));
  } catch (err) {
    yield put({
      type: actions.SET_ERROR_CAR_INSTANCE,
      payload: err.customDescription || DEFAULT_ERROR_MESSAGE,
    });
    yield put({
      type: actions.SET_PATCHIG_CAR_INSTANCE,
      payload: false,
    });
  } finally {
    yield put({ type: actions.PATCH_IS_NEW, payload: false });
  }
}

/*
function* validateCarInstance({ payload }) {
  try {
    const { carInstance: originalCarInstance, id } = payload;

    const validCarInstance = yield new Promise((resolve) => {
      schema
        .validate(originalCarInstance)
        .then((carInstance) => {
          resolve(carInstance);
        })
        .catch((err) => {
          throw err;
        });
    });
  } catch (err) {
    yield put({ type: actions.SET_ERROR_CAR_INSTANCE, payload: true });
  }
}
*/

export function* getBlankCarInstance() {
  try {
    const { dealer: dealerId } = yield select((store) => store.user);
    yield put({ type: actions.SET_FETCH_CAR_INSTANCE, payload: true });

    // eslint-disable-next-line
    let originalCarInstance = (originalCarInstance = yield call(
      carInstanceProvider.getBlankCarInstance,
      dealerId
    ));

    const action = { type: actions.SET_CAR_INSTANCE };

    yield schema
      .validate(originalCarInstance)
      .then((carInstance) => {
        const carInstanceWithParsedEnginePower = {
          ...carInstance,
          enginePower: carInstance.enginePower,
        };

        action.payload = {
          carInstance: carInstanceWithParsedEnginePower,
        };
      })
      .catch((err) => {
        throw err;
      });

    const vehicleStatus = originalCarInstance.isNew ? 'new' : 'old';
    let { instance } = yield loadCarDictionaries(
      ['brand', 'model', 'version'],
      { dealerId, vehicleStatus },
      action.payload.carInstance
    );

    const models = yield select((store) => store.carReference.models);
    const model = models.find((item) => item.name === instance.model);

    instance.image = model?.image ? model?.image : null;

    action.payload.carInstance = instance;

    // if (needSaveInstance) {
    //   yield patchCarInstanceSaga({
    //     payload: {
    //       carInstance: action.payload.carInstance,
    //     },
    //   });
    // }

    yield put(action);
    yield put({
      type: actions.SET_CAR_INSTANCE,
      payload: { carInstance: action.payload.carInstance },
    });
    yield put(setAllFetch(false));

    localStorage.setItem('carinstanceId', action?.payload?.carInstance?.id);
  } catch (err) {
    yield put({
      type: actions.SET_ERROR_CAR_INSTANCE,
      payload: err.customDescription || DEFAULT_ERROR_MESSAGE,
    });
  } finally {
    yield put({ type: actions.SET_FETCH_CAR_INSTANCE, payload: false });
  }
}

export function* getCarInstance({ payload }) {
  try {
    yield put({ type: actions.SET_FETCH_CAR_INSTANCE, payload: true });

    let originalCarInstance = yield call(carInstanceProvider.getCarInstanceById, payload.id);

    if (!originalCarInstance) {
      // беда беда катострофа
    }

    const action = { type: actions.SET_CAR_INSTANCE };

    yield schema
      .validate(originalCarInstance)
      .then((carInstance) => {
        const carInstanceWithParsedEnginePower = {
          ...carInstance,
          enginePower: carInstance.enginePower,
        };

        action.payload = {
          carInstance: carInstanceWithParsedEnginePower,
        };
      })
      .catch((err) => {
        throw err;
      });

    const { dealer: dealerId } = yield select((store) => store.user);
    const vehicleStatus = originalCarInstance.isNew ? 'new' : 'old';
    let { instance } = yield loadCarDictionaries(
      ['brand', 'model', 'version'],
      { dealerId, vehicleStatus },
      action.payload.carInstance
    );

    const carReference = yield select((store) => store.carReference);

    const { brandId, modelId } = getBrandAndModelIds(carReference, instance.brand, instance.model);

    if (!brandId) {
      instance.brand = '';
    }

    if (!brandId || !modelId) {
      instance.model = '';
      instance.version = '';
    }

    const models = yield select((store) => store.carReference.models);
    const model = models.find((item) => item.name === instance.model);

    instance.image = model?.image ? model?.image : null;

    action.payload.carInstance = instance;

    // if (needSaveInstance) {
    //   yield patchCarInstanceSaga({
    //     payload: {
    //       carInstance: action.payload.carInstance,
    //       id: payload.id,
    //     },
    //   });
    // }

    yield put(action);

    localStorage.setItem('carinstanceId', action?.payload?.carInstance?.id);
  } catch (err) {
    yield put({
      type: actions.SET_ERROR_CAR_INSTANCE,
      payload: err.customDescription || DEFAULT_ERROR_MESSAGE,
    });

    // yield getBlankCarInstance();
  } finally {
    yield put({ type: actions.SET_FETCH_CAR_INSTANCE, payload: false });
  }
}

function* getDealersParams({ payload }) {
  try {
    yield put({ type: actions.SET_FETCH_DEALERS_PARAMS, payload: 'pending' });

    const dealersParams = yield authProvider.getDealersParams(payload.currentDealerId);

    yield put({
      type: actions.CHANGE_DEALERS_PARAMS,
      payload: {
        dealersParams: { ...dealersParams, dealerId: payload.currentDealerId },
      },
    });
  } catch (err) {
    yield put({
      type: actions.SET_ERROR_CAR_INSTANCE,
      payload: err.customDescription || DEFAULT_ERROR_MESSAGE,
    });
  } finally {
    yield put({ type: actions.SET_FETCH_DEALERS_PARAMS, payload: 'done' });
  }
}

export function* watchGetCarInstance() {
  yield takeLatest(actions.GET_CAR_INSTANCE, getCarInstanceSaga);
}

export function* watchPatchCarInstance() {
  yield takeLatest(actions.PATCH_CAR_INSTANCE, patchCarInstanceSaga);
}

export function* watchChangeCarInstance() {
  yield takeLatest(actions.CHANGE_CAR_INSTANCE, changeCarInstanceSaga);
}

export function* watchGetCarInstanceById() {
  yield takeLatest(actions.GET_CAR_INSTANCE_BY_ID, getCarInstance);
}

export function* watchGetDealersParams() {
  yield takeLatest(actions.GET_DEALERS_PARAMS, getDealersParams);
}
