import { wordsToPass } from '../../resources';

export const getHighlightWords = (query = '') => {
  if (!query) {
    return [];
  }

  const words = `${query}`.replace(',', '').split(' ');
  const filteredWords = words.filter((word) => wordsToPass.indexOf(word) < 0);

  return filteredWords;
};
