import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import animateScrollTo from 'animated-scroll-to';
import PropTypes from 'prop-types';
import { InputMask } from 'utils/inputMask';
import { ADDRESS } from '../../../../constants';
import { dadataAddressToState } from 'store/anketa/mappers/abstractAddress';
import { insAnketaSetKaskoInsurerDadataInfo } from 'store/insuranceAnketa';

import * as S from './style';

const fn = () => {};

const Address = ({
  data,
  disabled = false,
  scrollTo = null,
  errors = {},
  onBlur = null,
  onScroll = fn,
}) => {
  const ref = useRef();
  const rootDispatch = useDispatch();

  const [state, setState] = useState({ ...data });
  const [selectState, setSelectState] = useState({
    insurerIndex: data.insurerIndex,
    insurerAddress: data.insurerAddress,
  });
  const [validationErrors, setValidationErrors] = useState({});

  useEffect(() => {
    if (ref && ref.current && scrollTo === ADDRESS.name) {
      animateScrollTo(ref.current);
      onScroll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollTo]);

  useEffect(() => {
    setValidationErrors(errors);
  }, [errors]);

  useEffect(() => {
    setState(data);
    setSelectState({
      insurerIndex: data.insurerIndex,
      insurerAddress: data.insurerAddress,
    });
  }, [data]);

  const onBlurHandler = async () => {
    if (onBlur) {
      onBlur({ value: { insurerIndex: state.insurerIndex }, state });
      onBlur({ value: { insurerAddress: state.insurerAddress }, state });
    }
  };

  const selectHandler = async (item) => {
    const key = Object.keys(item)[0];
    const value = item[key];

    const insurerIndex = value.data.postal_code;
    const insurerAddress = value.query;

    setSelectState({
      insurerIndex: insurerIndex || selectState.insurerIndex,
      insurerAddress: insurerAddress || selectState.insurerAddress,
    });

    rootDispatch(insAnketaSetKaskoInsurerDadataInfo(dadataAddressToState(value.data)));

    if (onBlur) {
      onBlur({ value: { insurerIndex }, state: { ...state, insurerIndex } });
      onBlur({ value: { insurerAddress }, state: { ...state, insurerAddress } });
    }
  };

  return (
    <S.Wrapper ref={ref}>
      <S.Title>Адрес регистрации</S.Title>
      <S.Index
        required
        unmask
        type="address"
        name="insurerIndex"
        label="Индекс"
        disabled={disabled}
        mask={InputMask.INDEX}
        value={selectState.insurerIndex}
        onSelect={selectHandler}
        onBlur={onBlurHandler}
        error={validationErrors?.insurerIndex}
        valueBuilder={(item) => item?.data?.postal_code || item.innerValue}
        onChange={({ insurerIndex }) => {
          if (insurerIndex.data) {
            setState({
              ...state,
              insurerAddress: insurerIndex.query,
              insurerIndex: insurerIndex.value,
            });
          } else {
            setState({
              ...state,
              insurerIndex: insurerIndex.value,
            });
          }
        }}
      />
      <S.AddressInput
        required
        unmask
        type="address"
        name="insurerAddress"
        label="Адрес"
        disabled={disabled}
        mask={InputMask.ADDRESS_RUS}
        onSelect={selectHandler}
        onBlur={onBlurHandler}
        value={selectState.insurerAddress}
        error={validationErrors?.insurerAddress}
        onChange={({ insurerAddress }) => {
          if (insurerAddress.data) {
            setState({
              ...state,
              insurerAddress: insurerAddress.value,
              insurerIndex: insurerAddress.data.postal_code || state.insurerIndex,
            });
          } else {
            setState({
              ...state,
              insurerAddress: insurerAddress.value,
            });
          }
        }}
      />
    </S.Wrapper>
  );
};

Address.propTypes = {
  counter: PropTypes.number,
  title: PropTypes.string,
  errors: PropTypes.object,
  data: PropTypes.object,
  onBlur: PropTypes.oneOfType([PropTypes.func, PropTypes.oneOf([null])]),
};

export default Address;
