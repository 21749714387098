import styled from 'styled-components';
import { CollapsePanel } from 'components/primitives/collapse-panel';

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 0 0 0;
  border: 2px solid #7ebfed;
  width: 100%;
  margin-bottom: 30px;
`;

export const FormPanel = styled(CollapsePanel)`
  width: 100%;

  > div:first-child {
    align-items: center;
    justify-content: center;

    > div:first-child {
      width: auto;
      margin-left: -28px;
    }
  }
`;

export const FormHeader = styled.h3`
  width: 100%;
  text-align: center;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #000000;
  margin: 0;
`;

export const FormRowBase = styled.div`
  width: 100%;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
`;

export const FormSubheader = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 110%;
  color: #000000;
  text-align: left;
  margin-top: 30px;
  margin-bottom: 15px;
  grid-area: h;
  display: flex;

  > div {
    margin-left: 20px;
  }
`;

export const FormGridRowBase = styled.div`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-column-gap: 20px;
`;

export const GridRowSubHeader = styled(FormGridRowBase)`
  grid-template-areas: '. h h h h h h .';
`;
