import styled from 'styled-components';
import { Radio } from 'components/primitives/radio';
import { FONT } from 'styles/constants';

export const GenderRadioContainer = styled.div`
  width: 100%;
  padding: 10px 0;
  display: grid;
  grid-column-gap: 20px;
  overflow: initial;
  grid-template-columns: repeat(6 1fr);
  grid-template-areas: 'rd rd rd rd btn btn';
`;

export const GenderRadioGroup = styled(Radio)`
  grid-area: rd;
`;

export const ToggleGenderRadioGroup = styled.button`
  font-family: ${FONT.Roboto};
  margin: 0;
  padding: 0;
  border: 0;
  background-color: #fff;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-align: right;
  text-decoration-line: underline;
  color: #a8a8a8;
  cursor: pointer;
  grid-area: btn;
  &:focus {
    text-decoration-line: none;
  }
`;
