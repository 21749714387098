import styled from 'styled-components';
import { Button } from 'components/primitives/button';

export const ToMain = styled(Button)`
  width: 100%;
  height: 60px;
  margin-bottom: 180px;
  color: #000000;
  font-size: 14px;
  padding: 0;
  margin-bottom: 0px;
  background: #deeefa;
  border: 1px solid #7ebfed;
  border-radius: 5px;
`;
