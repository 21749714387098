import React from 'react';

import PropTypes from 'prop-types';

import * as S from './style';

const Search = ({
  value = '',
  search = '',
  totalItems = 1,
  dealersOnPage = 5,

  onChange = () => {},
  onSearch = () => {},
}) => {
  const numberPages =
    Math.floor(totalItems / dealersOnPage) + Number(totalItems % dealersOnPage > 0);

  if (!search && numberPages < 2) {
    return null;
  }

  return (
    <S.SearchContainer>
      <S.Input
        value={value}
        onChange={onChange}
        placeholder="Введите название ДЦ"
        onKeyDown={({ key }) => {
          if (key === 'Enter') {
            onSearch(value);
          }
        }}
      />
      <S.Button
        onClick={() => {
          onSearch(value);
        }}
      />
    </S.SearchContainer>
  );
};

Search.propTypes = {
  value: PropTypes.string,
  search: PropTypes.string,
  totalItems: PropTypes.number,
  dealersOnPage: PropTypes.number,
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
};

export default Search;
