import { standartWhitespaceAndDashes } from 'utils/stringUtils';

export const convertIssuerCode = (number) => {
  const _number = String(number);
  const code = _number.slice(0, 3) + '-' + _number.slice(3);
  return code;
};

export const convertBirthPlaceVal = (val) => {
  return {
    ...val,
    birthPlace: standartWhitespaceAndDashes(val.birthPlace),
  };
};

export const checkChangeValues = (val, key, persons) => {
  const requriedFielsd = [
    'fullName',
    'mobilePhone',
    'birthDate',
    'serianumber',
    'issuer',
    'issuerCode',
  ];
  return requriedFielsd.some((field) => val[field] !== persons[key][field]);
};
