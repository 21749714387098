import styled from 'styled-components';
import { PALETTE } from 'styles/constants';

export const Wrapper = styled.div`
  position: relative;
  border-radius: 5px;
  background-color: ${PALETTE.white};
  min-width: 324px;
  width: 664px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  text-align: center;
  border: 1px solid
    ${(p) => {
      switch (true) {
        case p.status === 'success':
          return `${PALETTE.green1}`;
        case p.status === 'error':
          return `${PALETTE.yellow1}`;
        default:
          return `${PALETTE.red1}`;
      }
    }};
`;

export const CloseButton = styled.div`
  position: absolute;
  top: 7px;
  right: 9px;
  cursor: pointer;
  z-index: 10;

  svg {
    width: 12px;

    path {
      fill: ${PALETTE.grey1};
    }
  }
`;
