import styled, { css } from 'styled-components';

import { FONT, PALETTE } from 'styles/constants';

export const Text = styled.a`
  font-family: ${FONT.Roboto};
  font-weight: normal;
  font-style: normal;
  font-size: 12px;
  line-height: 14px;
  color: ${PALETTE.black};
  cursor: pointer;
  text-decoration-line: underline;

  ${(p) =>
    p.disabled &&
    css`
      cursor: not-allowed;
      color: ${PALETTE.grey2};
    `};
`;
