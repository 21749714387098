import React from 'react';
import ErrorPopup from 'components/primitives/error-popup';
import { EOW_STATES } from 'store/insuranceCalculation/resources';

export const applicationsListSorter = (a, b) => new Date(b.modifiedAt) - new Date(a.modifiedAt);

export const isFetching = (fetchStates) => Object.values(fetchStates).some((isTrue) => isTrue);

export const isNeedDisableEOWFields = (appsList) => {
  // функция за один проход по applications определяет необходимость
  // блокировки/разблокировки полей каринстанса и анкеты в EOW
  if (!appsList.length) {
    return false;
  }

  let offersAppsQty = 0;

  for (const application of appsList) {
    if (EOW_STATES.enabledCarInstanceAnketa.has(application.currentWorkflowState)) {
      offersAppsQty++;
      continue;
    }

    if (application.currentWorkflowState === EOW_STATES.client_decline) {
      continue;
    }

    return true;
  }

  return !offersAppsQty;
};

export const getErrorElement = (errors, onClick = () => {}) =>
  Object.keys(errors).reduce((acc, key) => {
    if (acc) {
      return acc;
    }

    if (errors[key]) {
      return <ErrorPopup onClose={onClick} errorMessage={errors[key]} />;
    }

    return acc;
  }, null);

export const transformState = (state) => {
  return {
    value: state.value,
    name: state.sysName,
    color: state.color,
  };
};

export const isLoading = (data) => {
  return (
    data.fetchPrelist === 'pending' ||
    data.fetchCalculationList === 'pending' ||
    data.saveParamsFetch === 'pending' ||
    data.selectOfferFetch ||
    data.policyQueryFetch ||
    data.agreementDraftFetch ||
    data.customerDeclineFetch === 'pending' ||
    data.newCalculationFetch === 'pending' ||
    data.customersFetch === 'pending'
  );
};

export const isEnabledCar = (state) =>
  ['new', 'offers_requested', 'offers_received', 'offer_selected'].includes(state?.name);
