import React from 'react';

import { BlueButton, RedButton } from 'components/primitives/content';
import * as S from './style';

const fn = () => {};

const Confirm = ({
  className = '',
  title = '',
  description = '',
  confirmText = '',
  declineText = '',
  isShow = false,
  onConfirm = fn,
  onDecline = fn,
  hideConfirm = false,
  hideDecline = false,
  hideCloseBtn = false,
  confirmRed = false,
  confirmDisabled = false,
}) => {
  const declineHandler = () => onDecline();
  const confirmHandler = () => onConfirm();

  if (!isShow) {
    return null;
  }

  const ConfirmButton = confirmRed ? RedButton : BlueButton;

  return (
    <S.PopupStyled className={className} onClose={declineHandler} hideCloseBtn={hideCloseBtn}>
      {title && <S.Title>{title}</S.Title>}
      {description && <S.Description>{description}</S.Description>}

      {(!hideDecline || !hideConfirm) && (
        <S.ButtonWrapper>
          {!hideDecline && (
            <S.DeclineButton onClick={declineHandler}>{declineText || 'Отмена'}</S.DeclineButton>
          )}
          {!hideConfirm && (
            <ConfirmButton disabled={confirmDisabled} onClick={confirmHandler}>
              {confirmText || 'Продолжить'}
            </ConfirmButton>
          )}
        </S.ButtonWrapper>
      )}
    </S.PopupStyled>
  );
};

export default Confirm;
