import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadNotificationsMainPage, resetNotifications } from 'store/notifications';

import { Loader } from 'components/primitives/loader';
import { Notification } from './components/Notification';
import { EmptyState } from './components/EmptyState';

import * as S from './style';

export const AppNotifications = ({ className }) => {
  const rootDispatch = useDispatch();

  const { id: userId } = useSelector((state) => state.user);
  const isLoading = useSelector((state) => state.notifications.fetch);
  const list = useSelector((state) => state.notifications.appsMainPage);
  const [sortedList, setSortedList] = useState([]);

  const now = new Date();
  const lastDay = new Date(now);

  useEffect(() => {
    lastDay.setDate(lastDay.getDate() - 1);
    const filteredList = Object.values(list).filter((item) => new Date(item.date) > lastDay);
    const sortedList = filteredList.sort(
      (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
    );

    setSortedList(sortedList);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);

  useEffect(() => {
    if (userId) {
      rootDispatch(loadNotificationsMainPage({ userId, isEmailBank: 0 }));
    } else {
      rootDispatch(resetNotifications());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  return (
    <S.Wrapper className={className}>
      <S.SortPanel>
        <S.Title>Одобренные клиенты</S.Title>
      </S.SortPanel>

      {isLoading ? (
        <Loader color="none" show={true} fixed={false} />
      ) : (
        <S.Container>
          {sortedList.map((data) => (
            <Notification key={data.id} data={data} />
          ))}

          {sortedList.length === 0 && <EmptyState />}
        </S.Container>
      )}
    </S.Wrapper>
  );
};
