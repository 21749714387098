import styled from 'styled-components';
import { PALETTE } from 'styles/constants';

export const Container = styled.div`
  display: flex;
  height: 100%;
  min-height: 770px;
  // Пока действует базовый layout, что бы скрыть место под sidebar
  margin-left: -52px;
  background-color: ${PALETTE.black2};
`;

export const MainBlockWraper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4px 4px 4px 16px;
  background-color: ${PALETTE.black2};
  flex: 1 1 auto;
  width: 75%;
`;
