import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { setSearch } from 'store/filters';
import { searchTrack } from 'metrika/applications';

import * as S from './style';

export const Search = () => {
  const search = useSelector(({ applicFilters }) => applicFilters.search);
  const rootDispatch = useDispatch();
  const history = useHistory();

  const searchHandler = (value) => {
    rootDispatch(setSearch(value));
    searchTrack();
    history.push('/applications/search');
  };

  return (
    <S.SearchInput
      placeholder="поиск по ID, фамилии, телефону, VIN"
      onSearch={searchHandler}
      val={search}
      dataTest="searchByAttributes"
    />
  );
};
