import { defaultDriver } from './resources';

export const getUniqueCustomerIds = ({ nodes }) =>
  nodes.reduce((acc, node) => {
    const isUniqueCustomerId = !acc.find(
      (customerId) => String(customerId) === String(node.customerId)
    );

    if (isUniqueCustomerId) {
      acc.push(node.customerId);
    }

    return acc;
  }, []);

export const getChangedDrivers = (state, { key, value, id }) => {
  const drivers = [...state.drivers];
  const index = drivers.findIndex((it) => it.id === id);
  const driver = { ...drivers[index] };

  driver[key] = value;
  delete driver['empty'];

  drivers[index] = driver;

  return drivers;
};

export const createNewDriverId = (state, addingId = null) => {
  let id = null;

  if (addingId) {
    id = addingId;
  } else {
    /*
            Создает уникальный id водителя от 1 до 5
            Получившийся id водителя не будет равен id предыдущих водителей,
            и также не будет равен id страхователя
            Всё это будет работать только если количество водителей не переваливает за 5,
            иначе новый id будет равен null
        */
    for (let i = 1; i <= 5; i++) {
      if (!state.drivers.find((driver) => `${driver.id}` === `${i}`) && `${state.id}` !== `${i}`) {
        id = i;
        break;
      }
    }
  }

  return id;
};

export const createNewDriver = (id, isEmpty = false, driverData = {}) => {
  const addons = isEmpty ? { empty: true } : {};

  return { ...defaultDriver, id, ...addons, ...driverData };
};

export const createNewDriverFromConfidant = (driverData) => {
  const driver = {
    id: driverData.id,
    fio: driverData.fullName,
    bday: driverData.birthDate.replaceAll('.', ''),
    status: 'confidant',
  };

  return { ...defaultDriver, ...driver };
};
