import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { UserPanel } from './components/UserPanel/UserPanel';
import { UserLink } from './components/UserPanel/UserSidebar/UserLink';

import * as metrica from 'metrika/mainpage';

import './style.css';
import * as S from './style';

export const Sidebar = () => {
  const user = useSelector((store) => store.user);
  const [showUserInfo, setShowUserInfo] = useState(false);

  return (
    <S.SidebarContainer>
      <S.BtnContainer>
        <S.LogoContainer
          onClick={() => {
            window.location.reload();
          }}
          style={{ cursor: 'pointer' }}
        >
          <S.StyledMainPageLogo />
        </S.LogoContainer>
        <Link to="/worksheet/create-new/calculator">
          <S.Button onClick={metrica.calculatorButtonTrack}>
            <S.StyledSideBarCalc />
            Расчитать кредит
          </S.Button>
        </Link>
        <Link to="/worksheet/create-new/insurance">
          <S.Button onClick={metrica.issueInsuranceButtonTrack}>
            <S.StyledSideBarShield />
            Рассчитай КАСКО
          </S.Button>
        </Link>
        <Link to="/applications/in-work">
          <S.Button onClick={metrica.listButtonTrack}>
            <S.StyledSideBarList />
            Список заявок
          </S.Button>
        </Link>
        {String(user?.role) !== '90' && (
          <a
            href={`${process.env.REACT_APP_REPORTS_URL}/sales-reconciliation`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <S.Button onClick={metrica.reportsButtonTrack}>
              <S.StyledSideBarReports />
              Отчеты
            </S.Button>
          </a>
        )}
      </S.BtnContainer>

      <TransitionGroup component={null}>
        {!showUserInfo ? (
          <CSSTransition key="userInfo" timeout={400} classNames="fade" component={null}>
            <S.FootContainer>
              <S.UserPanelButton>
                <UserPanel setShowInfo={setShowUserInfo} />
              </S.UserPanelButton>
            </S.FootContainer>
          </CSSTransition>
        ) : (
          <CSSTransition key="userLink" timeout={400} classNames="fade" component={null}>
            <S.UserLinkContainer>
              <UserLink setShowInfo={setShowUserInfo} currFid={'user'} />
            </S.UserLinkContainer>
          </CSSTransition>
        )}
      </TransitionGroup>
    </S.SidebarContainer>
  );
};
