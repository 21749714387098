import React, { forwardRef } from 'react';
import { InputMask } from 'utils/inputMask';

import moment from 'moment';

import * as S from './style';

const fn = () => {};

const CustomInput = forwardRef((props, ref) => {
  const {
    innerValue = '',
    disabled,
    onChange,
    onClick,
    onChangeInput = fn,
    onBlurInput = fn,
    dataTest,
  } = props;

  const changeHandler = (value) => {
    const valueWithDots = Array.from(value).reduce((acc, letter, index) => {
      if (index === 2 || index === 4) {
        return acc + '.' + letter;
      }

      return acc + letter;
    }, '');

    onChangeInput(valueWithDots);

    const momentDate = moment(value, 'DDMMYYYY');

    if (value.length === 8 && momentDate.isValid()) {
      onChange({ target: { value: momentDate.format('DD.MM.YYYY') } });
    }
  };

  const blurHandler = () => {
    onBlurInput(innerValue);
  };

  const filteredValue = innerValue.replace(/\./g, '');

  return (
    <S.Input
      ref={ref}
      name={'input'}
      mask={InputMask.DATE}
      unmask
      value={filteredValue}
      onAccept={changeHandler}
      onBlur={blurHandler}
      onClick={onClick}
      disabled={disabled}
      data-test={dataTest}
    />
  );
});

CustomInput.displayName = 'CustomInput';

export default CustomInput;
