import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Wrapper, List } from './style';
import { getDealersDict } from '../../../../../data-providers/authProvider';
import NavFloatDataContext from '../../NavFloatDataContext';
import Search from './Search';
import ListContent from './ListContent';
import Pagination from './Pagination';
import './style.scss';

const DealersSearchBlock = ({ className = '', clickDealerHandler = () => {}, numberDealer }) => {
  const [numberCurrentPage, setNumberCurrentPage] = useState(1);
  const [stringInput, setStringInput] = useState('');
  const [stringSearch, setStringSearch] = useState('');
  const [arraySearchData, setArraySearchData] = useState([]);
  const [numberTotalItems, setNumberTotalItems] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({
    stringMessage: 'test',
    isError: false,
  });

  const [fixedListItemCoordinates, setFixedListItemCoordinates] = useState(null); // { x: number, y: number }
  const [hoverDealer, setHoverDealer] = useState(null);

  const navFloatDataObj = useContext(NavFloatDataContext);
  const numberDealersOnPage = 10;

  const getSearchResult = (stringSearch = '', numberPage = 1) => {
    setIsLoading(true);

    getDealersDict({
      'name': stringSearch,
      'page': numberPage,
      'perPage': numberDealersOnPage,
      'order[name]': 'ASC',
    })
      .then((obj) => {
        setArraySearchData(obj['hydra:member']);
        setNumberTotalItems(obj['hydra:totalItems']);
      })
      .catch((error) => {
        setError({
          stringMessage: error.message,
          isError: false,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const scrollNode = navFloatDataObj.refNavFloat.current;

    const listener = () => {
      setHoverDealer(null);
      setFixedListItemCoordinates(null);
    };

    scrollNode.addEventListener('scroll', listener);

    return () => {
      scrollNode.removeEventListener('scroll', listener);
    };
    /* eslint-disable-next-line */
  }, []);

  useEffect(() => {
    setHoverDealer(null);
  }, [navFloatDataObj.active, numberDealer]);

  useEffect(() => {
    setStringInput('');
    setStringSearch('');
  }, [navFloatDataObj.active]);

  useEffect(() => {
    getSearchResult(stringSearch);
    setHoverDealer(null);
    setFixedListItemCoordinates(null);
    /* eslint-disable-next-line */
  }, [stringSearch]);

  return (
    <Wrapper className={className}>
      <Search
        totalItems={numberTotalItems}
        dealersOnPage={numberDealersOnPage}
        search={stringSearch}
        value={stringInput}
        marginSide={'30px'}
        onChange={({ target: { value } }) => {
          setStringInput(value.trimLeft());
        }}
        onSearch={(stringValue) => {
          setNumberCurrentPage(1);
          setStringSearch(stringValue);
        }}
      />
      <List>
        <ListContent
          error={error}
          isLoading={isLoading}
          hoverDealer={hoverDealer}
          arraySearchData={arraySearchData}
          numberDealer={numberDealer}
          fixedListItemCoordinates={fixedListItemCoordinates}
          mouseEnterHandlerCreator={(dealer) => (evt) => {
            setHoverDealer(dealer);

            setFixedListItemCoordinates({
              x: evt.currentTarget.getClientRects()[0].x,
              y: evt.currentTarget.getClientRects()[0].y,
            });
          }}
          clickHandlerCreator={(dealer) => () => {
            clickDealerHandler(dealer);
          }}
          mouseLeaveHandler={() => {
            setHoverDealer(null);
            setFixedListItemCoordinates(null);
          }}
        />
      </List>
      <Pagination
        totalItems={numberTotalItems}
        currentPage={numberCurrentPage}
        dealersOnPage={numberDealersOnPage}
        onClick={(numberPage) => {
          setNumberCurrentPage(numberPage);
          getSearchResult(stringSearch, numberPage);
        }}
      />
    </Wrapper>
  );
};

DealersSearchBlock.propTypes = {
  className: PropTypes.string,
  numberDealer: PropTypes.number.isRequired,
  clickDealerHandler: PropTypes.func,
};

export default DealersSearchBlock;
