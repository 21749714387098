import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import debounce from 'lodash/debounce';

import { dropApplications, getApplicationsList } from 'store/applications';
import { reloadFilters, resetSortig, filterUntouch } from 'store/filters';
import { InView } from 'components/common/InView';
import {
  LastState,
  LastStateVisible,
  IN_WORK,
  LIST,
  SEARCH,
  LastStateProducts,
} from 'constants/index';

import { Header } from './components/Header';
import { ApplicationRow } from './components/ApplicationRow';

import * as help from './helpers.js';
import * as S from './style.js';

export const ApplicationsList = () => {
  const rootDispatch = useDispatch();
  const { pathname } = useLocation();

  const { fetch, ids: dataIds, data: applications } = useSelector((store) => store.applications);
  const user = useSelector((store) => store.user);
  const dealer = useSelector((store) => store.user.dealers.find((d) => d.id === store.user.dealer));
  const filtersFetch = useSelector((store) => store.applicFilters.fetch);
  const filtersSorting = useSelector((store) => store.applicFilters?.sorting);
  const filtersTouch = useSelector((store) => store.applicFilters?.touch || false);

  // eslint-disable-next-line no-unused-vars
  const [_page, setPage] = useState(1);

  const currentPath = pathname.substring(pathname.lastIndexOf('/') + 1);
  const showOpenButton = help.canOpen(dealer, user);

  const loadApplications = useCallback(
    debounce((page) => {
      rootDispatch(getApplicationsList({ page }));
    }, 500),
    []
  );

  const reloadApplications = () => {
    setPage(1);
    rootDispatch(dropApplications());
    rootDispatch(filterUntouch());
    loadApplications(1);
  };

  useEffect(() => {
    const statesChecked = LastState[currentPath];
    const isDisabled = ![LIST, IN_WORK, SEARCH].includes(currentPath);
    const statesVisible = LastStateVisible[currentPath];
    const products = LastStateProducts[currentPath];

    const params = {
      states: {
        checked: statesChecked,
        disabled: isDisabled,
        visibleList: statesVisible,
        url: currentPath,
        products: products,
      },
    };

    rootDispatch(dropApplications());
    rootDispatch(reloadFilters(params));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPath]);

  useEffect(() => {
    rootDispatch(resetSortig());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.role, user?.dealer]);

  useEffect(() => {
    if (filtersTouch) {
      reloadApplications();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersTouch]);

  useEffect(() => {
    if (filtersFetch && user?.role && user?.dealer) {
      reloadApplications();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersFetch, filtersSorting, user?.role, user?.dealer]);

  const nextPageHandler = (data) => {
    if (data.page <= 1) {
      return;
    }

    setPage((prev) => {
      if (data.page <= prev) {
        return prev;
      }

      loadApplications(data.page);
      return data.page;
    });
  };

  return (
    <S.Container>
      <S.Table>
        <Header showOpenButton={showOpenButton} />
        {dataIds.map((id, i) => (
          <React.Fragment key={id}>
            {help.isShowViewChecker(i) && (
              <InView data={{ page: help.getViewCheckerPage(i) }} onShow={nextPageHandler} />
            )}
            <ApplicationRow application={applications[id]} showOpenButton={showOpenButton} />
          </React.Fragment>
        ))}
        {fetch && <S.Loader color="none" show={true} />}
      </S.Table>
    </S.Container>
  );
};
