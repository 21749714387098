import styled from 'styled-components';
import * as UK from 'components/primitives/content';
import { PALETTE } from 'styles/constants';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  &:hover {
    background-color: ${PALETTE.grey9};
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px 10px;
  border-bottom: 2px solid ${PALETTE.grey9};
`;

export const Title = styled(UK.Title3)`
  font-weight: 400;
  font-size: 14px;
  color: ${PALETTE.dark2};
  margin: 0 10px 0 24px;
`;

export const Date = styled.div`
  font-size: 12px;
  line-height: 13px;
  margin: auto 0;
  color: ${PALETTE.grey13};
`;
