/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import PulseLoader from 'react-spinners/PulseLoader';
import OutsideClickHandler from 'react-outside-click-handler';
import { InputMask } from 'utils/inputMask';
import { DadataGetSuggestions } from './helper';
import DropDown from './components/DropDown';

import * as S from './style';

const fn = () => {};

// TODO: Заменить useState на useReducer
export const SelectSearch = ({
  id,
  name,
  label,
  disabled,
  required = false,
  defaultValue = '',
  value = '',
  list = [],
  mask = InputMask.ALL,
  unmask,
  onChange = fn,
  onBlur = fn,
  onSelect = fn,
  onCloseDropDown = fn,
  //showAlert = fn,
  className,
  error,
  errorMessage,
  placeholder = '',
  valueBuilder = null,
  // debug, always open
  showSuggestionsForce = true,
  closeDropDownAfterOutsideClick = true,
  outerInputRef = null,
  newState = null,
  fetch = false,
  showAll = false,
  sort = false,
  dataTest,
}) => {
  const inputRef = useRef();

  const currentInputRef = outerInputRef || inputRef;

  // const [ firstRender, setFirstRender ] = useState(false);
  const [focused, setFocused] = useState(false);
  const [data, setData] = useState({ query: '', suggestions: list });

  const [innerValue, setInnerValue] = useState(defaultValue);

  // const [ prevValue, setPrevValue ] = useState(defaultValue);

  // useEffect(() => {
  // 	if (!focused && !show && firstRender) {
  // 		showAlert();
  // 	}
  // }, [focused, show]);

  // useEffect(() => {
  // 	setFirstRender(true);
  // }, []);

  // useEffect(() => {
  // 	if (fetch) {
  // 		setInnerValue('');
  // 	}
  // }, [fetch])

  useEffect(() => {
    setData({ query: '', suggestions: list });
  }, [list]);

  useEffect(() => {
    if (newState) {
      const keys = Object.keys(newState);

      keys.forEach((key) => {
        const setStateMethodName = `set${Array.from(key)
          .map((letter, i) => (i === 0 ? letter.toUpperCase() : letter))
          .join('')}`;
        // eslint-disable-next-line no-eval
        eval(setStateMethodName)(newState[key]);
      });
    }
  }, [newState]);

  useEffect(() => {
    setInnerValue(value);
  }, [value]);

  const receiveResult = (result) => {
    const { query, suggestions } = result;

    setData({ query, suggestions });
    // setShow(true);
  };

  useEffect(() => {
    if (!showSuggestionsForce) {
      // setShow(false);
      onCloseDropDown();
    }

    DadataGetSuggestions({
      query: innerValue,
      list,
      prepareResult: receiveResult,
    });

    // const changeObj = { query: innerValue, value: innerValue, data: null };

    // onChange(name ? { [name]: changeObj } : changeObj);

    // // setPrevValue(innerValue);
    // setInnerValue(innerValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [innerValue]);

  const wrapperClick = () => {
    if (!disabled) {
      currentInputRef && currentInputRef.current && currentInputRef.current.element.focus();
      setFocused(true);
      //setInnerValue('');
    }
  };

  const onAccept = (str) => {
    setInnerValue(str);
  };

  const handleBlur = () => {
    const blurObj = { query: innerValue };

    onBlur(name ? { [name]: blurObj } : blurObj);
  };

  const onOutsideClick = () => {
    setInnerValue(value);

    if (focused) {
      // if (!focused) {
      // 	showAlert();
      // }

      setFocused(false);
    }

    if (closeDropDownAfterOutsideClick) {
      // setShow(false);
      onCloseDropDown();
    }
  };

  const handleSelected = (item) => {
    const obj = { innerValue, query: item.value, ...item };

    if (valueBuilder) {
      obj.value = valueBuilder(obj);
    }

    setInnerValue(obj.value);
    // setPrevValue(obj.value);

    onCloseDropDown();
    onChange(name ? { [name]: { ...obj } } : { ...obj });
    onSelect(name ? { [name]: { ...obj } } : { ...obj });

    setFocused(false);
    currentInputRef && currentInputRef.current && currentInputRef.current.element.blur();
  };

  const keyDownHandler = (evt) => {
    if (focused && evt?.key === 'Tab') {
      setFocused(false);
    }
  };

  return (
    <S.Wrapper
      id={`${id}_wrapper`}
      required={!innerValue && !focused && required && error}
      error={!innerValue && !focused && error}
      disabled={disabled}
      focused={focused}
      onClick={wrapperClick}
      className={className}
    >
      <OutsideClickHandler onOutsideClick={onOutsideClick}>
        <S.MainContent>
          <S.Label
            className="anketa_label"
            focused={innerValue || focused}
            required={!innerValue && !focused && !disabled && required && error}
            isHidden={!label}
          >
            {label}
            {required && <sup>*</sup>}
          </S.Label>
          <S.InputStyled
            className="anketa-value"
            ref={currentInputRef}
            mask={mask}
            unmask={unmask}
            onAccept={onAccept}
            onBlur={handleBlur}
            onKeyDown={keyDownHandler}
            label={label}
            defaultValue={`${defaultValue}`}
            value={innerValue}
            disabled={disabled}
            placeholder={placeholder}
            focused={`${innerValue || focused}`}
            ishidden={`${!focused && !innerValue && !placeholder}`}
            data-test={dataTest}
          />
          <DropDown
            showAll={showAll}
            isHidden={!focused || (!innerValue && !showAll)}
            items={(data.suggestions.length === 0 && showAll ? list : data.suggestions).sort(
              (a, b) => (sort ? (a.value > b.value ? 1 : -1) : 0)
            )}
            highlightValue={innerValue}
            onClick={handleSelected}
            // onBlur={showAlert}
          />
        </S.MainContent>

        <S.Prompt isHidden={!(!innerValue && !focused && error)}>{errorMessage || error}</S.Prompt>

        {!!fetch && (
          <PulseLoader css={S.PulseLoaderStyled} size={10} color={'#b5b5b5'} loading={true} />
        )}
      </OutsideClickHandler>
    </S.Wrapper>
  );
};

SelectSearch.propTypes = {
  name: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  label: PropTypes.any,
  defaultValue: PropTypes.any,
  mask: PropTypes.any,
  unmask: PropTypes.bool,
  className: PropTypes.string,
};
