import React, { forwardRef } from 'react';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import { InnerInput, InputWrapper, SearchIcon } from './style';

const Input = forwardRef(({ searchString, onChange, isHidden }, ref) => {
  if (isHidden) {
    return null;
  }

  return (
    <InputWrapper>
      <InnerInput
        type="text"
        autocomplete="off"
        value={searchString}
        onChange={onChange}
        ref={ref}
      />
      <SearchIcon icon={faSearch} />
    </InputWrapper>
  );
});

Input.displayName = 'Input';

export default Input;
