import React from 'react';
// import ym from 'react-yandex-metrika';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';
import { changeActiveDealer, changeActiveRole } from 'store/user';
import './user.scss';
import DealersSearchBlock from './DealersSearchBlock';
import { setFilter } from 'store/filters';

const DEF_CLASS = 'submenu-link';

const getList = (data, flag, onClick) =>
  data.map((item) => (
    <li
      className={cn(['sidenav-user__item', { active: item.id === flag }])}
      key={item.id}
      onClick={() => onClick(item)}
    >
      {item.name}
    </li>
  ));

const UserLink = () => {
  const history = useHistory();
  const {
    roles,
    role,
    dealer: numberDealer, // id
  } = useSelector((state) => state.user);

  const dealersOptions = useSelector((store) => store.applicFilters?.dealer || []);
  const ksoOptions = useSelector((store) => store.applicFilters?.ks || []);
  const mopOptions = useSelector((store) => store.applicFilters?.mop || []);
  const defaultDealer = useSelector((store) => store.user.dealer);

  const dispatch = useDispatch();

  const resetKsoAndMop = () => {
    const newKsoOptions = ksoOptions
      .filter((item) => !!item?.id)
      .map((item) => ({ ...item, checked: false }));

    const newMopOptions = mopOptions
      .filter((item) => !!item?.id)
      .map((item) => ({ ...item, checked: false }));

    dispatch(setFilter({ filter: 'ks', value: newKsoOptions }));
    dispatch(setFilter({ filter: 'mop', value: newMopOptions }));
  };

  const clickDealerHandler = (item) => {
    const options = dealersOptions
      .filter((d) => !!d?.id)
      .map((d) => ({ ...d, checked: String(d.id) === String(item?.id) }));

    dispatch(changeActiveDealer(item.id));
    dispatch(setFilter({ filter: 'dealer', value: options }));
    resetKsoAndMop();

    localStorage.removeItem('worksheet');
    localStorage.removeItem('carinstanceId');
    localStorage.removeItem('customerId');

    history.push('/applications');
  };

  const clickRolekHandler = (item) => {
    const options = dealersOptions
      .filter((d) => !!d?.id)
      .map((d) => ({ ...d, checked: String(d.id) === String(defaultDealer) }));

    dispatch(changeActiveRole(item.id));
    dispatch(setFilter({ filter: 'dealer', value: options }));
    resetKsoAndMop();

    localStorage.removeItem('worksheet');
    localStorage.removeItem('carinstanceId');
    localStorage.removeItem('customerId');

    history.push('/applications');
  };

  const rolesList = getList(roles, role, clickRolekHandler);
  // const dealersList = getList(arrayDealers, numberDealer, clickDealerHandler, t);

  const onTrainigClick = (e) => {
    e.preventDefault();
    // process.env.NODE_ENV === 'production' && ym('reachGoal', 'education');
    window.open(process.env.REACT_APP_BANNER_URL, '_blank');
  };

  return (
    <div className="sidenav-user--wrapper">
      <div className="sidenav-user">
        {91 === role && (
          <a href={process.env.REACT_APP_BANNER_URL} className={DEF_CLASS} onClick={onTrainigClick}>
            Обучение
          </a>
        )}
        <a href={'https://auto.e-credit.one/#profile'} className={DEF_CLASS}>
          Профиль
        </a>
        <h5>Роль</h5>
        <ul className="sidenav-user__list">{rolesList}</ul>
        <h5>Дилеры</h5>
        <DealersSearchBlock numberDealer={numberDealer} clickDealerHandler={clickDealerHandler} />
      </div>
    </div>
  );
};

export default UserLink;
