/* eslint-disable no-undef */
import React, { useEffect } from 'react';
import { Switch, Route, useHistory, Redirect } from 'react-router-dom';
import { Metrika } from 'metrika';
import { JssProvider } from 'react-jss';
import Helmet from 'react-helmet';
import { hot } from 'react-hot-loader';
import { getItem, saveItem } from 'utils/local-storage';
import 'moment/locale/ru';

import config from './config/config';
import { Notifications } from './components/common/Notifications';
import PageLayout from './layout/PageLayout';
import { Permissions } from './components/common/Permissions';
import { UserNotifications } from './components/common/UserNotifications';
import { AppVersionReloader } from './components/common/AppVersionReloader';
import { Auth } from './components/pages/Auth';
import { Applications } from './components/pages/Applications';
import { Application } from './components/pages/Application';
import Provider from './components/pages/Provider';
import Interceptors from 'interceptors';
import { ErrorBoundary } from './components/common/ErrorBoundary';
import { Session } from './components/pages/Session';
import Upload from './components/pages/Upload';
import { isTokenValid } from './utils/AuthUtils';
import { MainPage } from 'components/pages/MainPage/MainPage';

const App = () => {
  const history = useHistory();
  const stored = getItem('token');

  useEffect(() => {
    if (history) {
      if (
        !history.location.pathname.startsWith('/upload') &&
        (!stored || !isTokenValid(stored?.refresh)) &&
        '/session' !== history.location.pathname
      ) {
        history.push('/login');
      }
      if ('/session' === history.location.pathname) {
        saveItem('blockLoginRedirect', true);
      }
    }
  }, [stored, history]);

  return (
    <JssProvider>
      <Notifications>
        <ErrorBoundary>
          <Helmet
            title={config.themeConfig.defaultTitle}
            link={[{ rel: 'shortcut icon', href: config.themeConfig.favicon }]}
          />
          <Interceptors />
          <Switch>
            <Route path="/login">
              {isTokenValid(stored?.refresh) ? <Redirect to="/applications" /> : <Auth />}
            </Route>
            <Route path="/start">
              <PageLayout setSidebar={false}>
                <MainPage />
              </PageLayout>
            </Route>
            <Route path="/applications">
              <PageLayout>
                <Applications />
              </PageLayout>
            </Route>
            <Route path="/worksheet">
              <PageLayout>
                <Application />
              </PageLayout>
            </Route>
            <Route path="/session">
              <Session />
            </Route>
            <Route path="/provider">
              <PageLayout>
                <Provider />
              </PageLayout>
            </Route>
            <Route path="/upload/:type/:id/:token">
              <Upload />
            </Route>
            <Route path="/">
              <Redirect to={`${isTokenValid(stored?.refresh) ? '/applications' : '/login'}`} />
            </Route>
          </Switch>
          <Permissions />
          <UserNotifications />
          <Metrika />
        </ErrorBoundary>
      </Notifications>
      <AppVersionReloader />
    </JssProvider>
  );
};
export default process.env.NODE_ENV === 'development' ? hot(module)(App) : App;
