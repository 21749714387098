import React, { useState } from 'react';
import { Switch, Route } from 'react-router-dom';

import Providers from 'components/modules/Providers';
import * as S from './style';
import { Navigation } from './components/Navigation';
import { Search } from './components/Search';

const Provider = () => {
  const [searchString, setSearchString] = useState('');

  return (
    <S.Container>
      <S.NavigationContainer>
        <Navigation />
        <S.Search>
          <Search onSearch={setSearchString} />
        </S.Search>
      </S.NavigationContainer>
      <Switch>
        <Route path={['/provider/all', '/provider/car', '/provider/insurance']}>
          <Providers search={searchString} />
        </Route>
      </Switch>
    </S.Container>
  );
};

export default Provider;
