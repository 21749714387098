import ym from 'react-yandex-metrika';

const isProd = process.env.NODE_ENV === 'production';

export const calculatorButtonTrack = () => isProd && ym('reachGoal', 'рассчитать кредит');

export const issueInsuranceButtonTrack = () => isProd && ym('reachGoal', 'рассчитать Каско');

export const listButtonTrack = () => isProd && ym('reachGoal', 'список заявок');

export const reportsButtonTrack = () => isProd && ym('reachGoal', 'отчеты');

export const returnAllButtonTrack = () => isProd && ym('reachGoal', 'верните все как было');

export const userPanelButtonTrack = () => isProd && ym('reachGoal', 'пользователь');

export const checkVinButtonTrack = () => isProd && ym('reachGoal', 'проверить vin');

export const giveCreditButtonTrack = () => isProd && ym('reachGoal', 'выдай кредит');

export const toMainPageTrack = () => isProd && ym('reachGoal', 'Логотип еКредит');

export const toWhatsAppTrack = () => isProd && ym('reachGoal', 'Переход по номеру телефона');

export const applicationNumCopyTrack = () =>
  isProd && ym('reachGoal', 'Копирование номера заявки ');

export const confirmVinButtonTrack = () => isProd && ym('reachGoal', 'Подтвердить ');

export const searchHistoryButtonTrack = () => isProd && ym('reachGoal', 'история поиска по VIN');

export const choseUserTrack = () => isProd && ym('reachGoal', 'выбор пользователя и ДЦ');
