export const selectCarInstanceId = (state) =>
  state.carInstance?.carInstance && state.carInstance?.carInstance.id
    ? state.carInstance?.carInstance?.id
    : state.startPage?.application?.carInstance?.id;

export const selectCustomerId = (state) =>
  state.startPage?.customer?.id || state.startPage?.application?.customer?.customerId;

export const selectDealerId = (state) => {
  let id;

  // If the application is already exist, is not new
  if (state.startPage?.application?.id) {
    id = state.startPage.application?.dealer?.id;
  }
  if (!id) {
    id = state.user?.dealer;
  }

  return id;
};

export const selectAssistanceId = (state) =>
  state.assistanceCalculation?.assistanceId || state.startPage?.application?.ids?.insurance;

export const selectCreditId = (state) => state.startPage?.application?.ids?.credit;

export const selectAssistanceClientId = (state) =>
  state.assistanceCalculation?.clientId ||
  state.startPage?.clientId ||
  state.startPage?.application?.client?.id;
