import {
  convertDate,
  convertOptionToSysName,
  convertSysNameToSelectOption,
} from 'utils/dataMappers/mappers';
import {
  abstractDrivingLicenseFromAPIToState,
  abstractDrivingLicenseFromStateToAPI,
  abstractDrivingLicenseInitialState,
} from './abstractDrivingLicense';

export const spouseInitialState = {
  spouse: {
    gender: {},
    firstName: '',
    lastName: '',
    middleName: '',
    fullName: '',
    isFioChanged: false,
    fioChangedReason: '',
    prevFirstName: '',
    prevLastName: '',
    prevMiddleName: '',
    prevFullName: '',
    birthDate: '',
    birthPlace: '',

    phone: '',

    insurance: '',
    ...abstractDrivingLicenseInitialState,

    employmentType: '',
    employmentOrganizationName: '',
    employmentPosition: '',
    relation: '',
    familyState: 'married',
  },
};

export const spouseFromAPIToState = (customer, { spouseSocialStatusList, genderList }) => {
  const spouse = customer.partner;
  const isFioChanged =
    '' !== spouse.previousLastName
      ? `${spouse.lastName} ${spouse.firstName} ${spouse.middleName}` !==
        `${spouse.previousLastName} ${spouse.previousFirstName} ${spouse.previousMiddleName}`
      : false;
  return {
    spouse: {
      gender: convertSysNameToSelectOption(
        spouse.genderSysName === '' ? 'male' : spouse.genderSysName,
        genderList
      ),
      firstName: spouse.firstName,
      lastName: spouse.lastName,
      middleName: spouse.middleName,
      fullName: `${
        spouse.lastName.length > 0
          ? `${spouse.lastName}${spouse.lastName.length > 0 ? ' ' : ''}`
          : ''
      }${
        spouse.firstName.length > 0
          ? `${spouse.firstName}${spouse.middleName.length > 0 ? ' ' : ''}`
          : ''
      }${spouse.middleName.length > 0 ? `${spouse.middleName}` : ''}`,
      isFioChanged: isFioChanged,
      fioChangedReason: spouse.nameChangingReasonSysName,
      prevFirstName: spouse.previousFirstName,
      prevLastName: spouse.previousLastName,
      prevMiddleName: spouse.previousMiddleName,
      prevFullName: spouse.previousLastName
        ? `${spouse.previousLastName.length > 0 && `${spouse.previousLastName} `} ${
            spouse.previousFirstName.length > 0 && `${spouse.previousFirstName} `
          } ${spouse.previousMiddleName.length > 0 && `${spouse.previousMiddleName} `}`
        : '',
      birthDate: convertDate(spouse.birthDate),
      birthPlace: spouse.birthPlace,

      phone: spouse.contacts.mobilePhone || '',

      insurance: spouse.insurance || false,
      ...abstractDrivingLicenseFromAPIToState(spouse.driverLicense),

      employmentType: convertSysNameToSelectOption(
        spouse.spouseEmploymentSysName,
        spouseSocialStatusList
      ),
      employmentOrganizationName: spouse.jobOrganizationName,
      employmentPosition: spouse.jobPosition,
      relation: spouse.relationSysName,
      familyState: spouse.familyStateSysName,
    },
  };
};

export const spouseFromStateToAPI = (person) => {
  const spouse = person.spouse;
  return {
    partner: {
      genderSysName: convertOptionToSysName(spouse.gender) || 'male',
      firstName: spouse.firstName || '',
      lastName: spouse.lastName || '',
      middleName: spouse.middleName || '',
      nameIsChanged: spouse.isFioChanged || '',
      nameChangingReasonSysName: spouse.fioChangedReason || '',
      previousFirstName: spouse.prevFirstName || '',
      previousLastName: spouse.prevLastName || '',
      previousMiddleName: spouse.prevMiddleName || '',
      birthDate: spouse.birthDate || '',
      birthPlace: spouse.birthPlace || '',

      contacts: {
        mobilePhone: spouse.phone || '',
      },

      insurance: spouse.insurance || false,
      ...abstractDrivingLicenseFromStateToAPI(spouse),

      spouseEmploymentSysName: convertOptionToSysName(spouse.employmentType),
      jobOrganizationName: spouse.employmentOrganizationName || '',
      jobPosition: spouse.employmentPosition || '',
      relationSysName: spouse.relation,
      familyStateSysName: spouse.familyState,
    },
  };
};

/*
const map = (customer) => {
    return {
        ...contactsFromAPIToState(customer)
    }
}
 */
