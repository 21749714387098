import React from 'react';
import { Wrapper, Text } from './style';

export const ToolTip = ({
  text = null,
  position = 'top',
  color = null,
  children,
  className = '',
}) => (
  <Wrapper className={className} color={color}>
    {children}
    {text && (
      <Text position={position} color={color}>
        {text}
      </Text>
    )}
  </Wrapper>
);
