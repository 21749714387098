export const BREAKPOINTS = {
  medium: '768px',
  large: '1366px',
  xlarge: '1920px',
};

export const FONT = {
  Roboto: "Roboto, 'Source Sans Pro', Helvetica, sans-serif",
};

export const TYPOGRAPHY = {
  fontFamily: "'Roboto', sans-serif",
};

export const PALETTE = {
  primary: '#cd2626',
  secondary: '#00a6b7',
  green1: '#228B22',
  green2: '#E8F3E8',
  green3: '#d8f6d8',
  green4: '#24AD39',
  green5: '#DBF2E2',
  green6: '#01A438',
  yellow1: '#FFD700',
  yellow2: '#FFFFEC',
  yellow3: '#FFFFB3',
  yellow4: '#fff3cc',
  red1: '#CD2626',
  red2: '#FFB4B4',
  red3: '#ffc7c7',
  red4: '#efcbc9',
  red5: '#E33E3E',
  red6: '#B42222',
  red7: '#971C1C',
  blue1: '#0175C9',
  blue2: '#7EBFED',
  blue3: 'rgba(126, 191, 237, 0.15)',
  blue4: '#DEEEFA',
  blue5: '#37a4f2',
  blue6: '#0155CC',
  blue7: '#0156CC',
  blue8: '#123585',
  blue9: '#E1EEFC',
  black: '#000000',
  black1: '#18181A',
  black2: '#1C1C1C',
  black3: '#151515',
  white: '#FFFFFF',
  grey1: '#A8A8A8',
  grey2: '#B5B5B5',
  grey3: '#F7F7F7',
  grey4: '#E5E5E5',
  grey5: '#808080',
  grey6: '#E3E3E3',
  grey7: '#CCCCCC',
  grey8: '#4A4A4A',
  grey9: '#F5F6FA',
  grey10: '#5d5d5f',
  grey11: '#dcdee5',
  grey12: '#252526',
  grey13: '#767D87',
  grey14: '#E6E6E6',
  grey15: '#CECECE',
  grey16: '#4A4A51',
  grey17: '#2F2F33',
  light: '#f9f9f9',
  dark: '#121212',
  dark2: '#313131',
  tableHeader: '#f0f0f0',
  textGrey: '#e0e0e0',
  modalBg: '#f4f4f4',
  aside: '#28373e',
  link: '#afb7c3',
  orange1: '#f9e8dc',
  orange2: '#f27d2a',
  orange3: '#ff9800',
};

export const Z_INDEXES = {
  min: 1,
  // header: '2000',
  // menu: '1000',
  modalWindow: '9000',
  // firstLevel: '500',
  // overBackdrop: '5000',
  // footer: '900',
  maxIndex: '5000',
};
