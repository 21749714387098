import React, { useState } from 'react';
import { getFileApplications } from 'data-providers/applicationsProvider';
import { downloadByUrl } from 'utils/common';
import { ClipLoader } from 'react-spinners';
import { PALETTE } from 'styles/constants';
import { lostClickTrack } from 'metrika/applications';
import { LostReportIco, FlexWrap } from './style';

const loader = <ClipLoader size={20} color={PALETTE.black1} loading={true} />;

export const Lostreport = () => {
  const [load, setLoad] = useState(false);

  const loadReportHandler = async (e) => {
    e.preventDefault();
    lostClickTrack();
    setLoad(true);
    const report = await getFileApplications();
    if (!report) {
      setLoad(false);
      return;
    }
    downloadByUrl(report.name, report.link);
    setLoad(false);
  };

  return <FlexWrap>{load ? loader : <LostReportIco onClick={loadReportHandler} />}</FlexWrap>;
};
