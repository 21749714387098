import AppError from './AppError';

class HttpError extends AppError {
  constructor(code = 500, description = '') {
    super();

    this.code = code;
    this.type = 'http-error';
    this.description = `${code} ${description}`;
  }
}

export default HttpError;
