/* eslint-disable no-undef */
import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import OutsideClickHandler from 'react-outside-click-handler';
import { Wrapper, ReactDadataStyled, Label, Prompt } from './style';
import { InputMask } from 'components/primitives/input-mask-a';

export const DaData = forwardRef((props, ref) => {
  const {
    id,
    type = 'addresss',
    name,
    label,
    disabled,
    required,
    defaultValue,
    resultsCount = 20,
    className,
    onChange,
    onBlur,
    payloadModifier,
    error,
    forceOpenList = false,
    format = (value) => value,
    debounce = 500,
    mask = null,
    unmask = false,
    daDataClosed = false,
    dataTest,
  } = props;
  const inputRef = useRef();
  const inputMaskRef = useRef();
  const [focused, setFocused] = useState(false);
  const [value, setValue] = useState({ value: '' });
  const [openList, toggleOpenList] = useState(forceOpenList);

  useEffect(() => {
    if (forceOpenList) {
      toggleOpenList(true);
    }
  }, [forceOpenList]);

  const handleBlur = () => {
    if (focused) {
      setFocused(false);
      onBlur(name ? { [name]: value } : value);
    }
  };

  const handleChange = (obj) => {
    let val = obj;
    if (typeof obj === 'string') {
      val = { value: obj };
    }
    if (openList) {
      toggleOpenList(false);
    }
    onChange(name ? { [name]: val } : val);
  };

  const wrapperClick = () => {
    if (!disabled) {
      setFocused(true);
      inputRef && inputRef.current && inputRef.current.focus();
    }
  };

  useImperativeHandle(ref, () => ({
    focus() {
      inputRef.current.focus();
    },
  }));

  useEffect(() => {
    if (!disabled && defaultValue) {
      setValue({ value: defaultValue });
      //setFocused(true);
      toggleOpenList(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  const CustomInput = (props) => {
    const onChange = (ev) => {
      if (mask) {
        if (ev !== undefined && value !== null && ev.toString() !== value.value.toString()) {
          setValue({ value: ev });
          handleChange(name ? { [name]: ev } : ev);
          props.onChange({ target: { value: ev } });
        }
      } else {
        try {
          const val = { value: format(ev.target.value) };
          setValue(val);
          handleChange(name ? { [name]: val } : val);
          props.onChange(ev);
        } catch {
          // todo
        }
      }
    };

    return !mask ? (
      <input
        ref={inputRef}
        {...props}
        id={`${id}_input`}
        onChange={onChange}
        value={value.value}
        onFocus={() => setFocused(true)}
        data-test={dataTest}
        disabled={disabled}
      />
    ) : (
      <InputMask
        id={id}
        {...props}
        className="anketa_dadata_input_mask"
        ref={inputMaskRef}
        mask={mask}
        unmask={unmask}
        required={required && !!error}
        label={label}
        defaultValue={value.value}
        val={value.value}
        onChange={onChange}
        onBlur={daDataClosed ? props.onBlur : handleBlur}
        disabled={disabled}
        error={error}
        dataTest={dataTest}
      />
    );
  };

  return (
    <Wrapper
      id={id}
      required={required && error}
      disabled={disabled}
      focused={!mask && (focused || value.value)}
      onClick={wrapperClick}
      className={className}
      mask={mask}
    >
      <OutsideClickHandler onOutsideClick={handleBlur}>
        <ReactDadataStyled
          showNote={false}
          type={type}
          mask={mask}
          focused={focused}
          count={resultsCount}
          query={value.value}
          token={process.env.REACT_APP_DADATA_TOKEN}
          onChange={handleChange}
          onIdleOut={handleChange}
          payloadModifier={payloadModifier}
          forceOpenList={openList}
          customInput={CustomInput}
          debounce={debounce}
        />
        {!mask && (
          <>
            {label && (
              <Label
                focused={focused || value.value}
                required={required && error}
                onClick={wrapperClick}
              >
                {label}
                {required && <sup>*</sup>}
              </Label>
            )}
            {error && (
              <Prompt className="select_prompt">{error ? error : 'Обязательное поле'}</Prompt>
            )}
          </>
        )}
      </OutsideClickHandler>
    </Wrapper>
  );
});

DaData.propTypes = {
  type: PropTypes.string,
  payloadModifier: PropTypes.objectOf(PropTypes.any),
  name: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  label: PropTypes.any,
  defaultValue: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  error: PropTypes.string,
};

DaData.defaultProps = {
  defaultValue: '',
  payloadModifier: null,
  type: 'off',
  name: null,
  disabled: false,
  required: false,
  label: '',
  className: '',
  onChange: () => {},
  onBlur: () => {},
  error: null,
};
