import React from 'react';

import { useLoadPlatform } from 'utils/helperElfSight';

import * as S from './style';

export const Banner = () => {
  useLoadPlatform();

  return (
    <S.Container>
      <div className="elfsight-app-6140c724-c084-4647-8266-06e0bce80279" />
    </S.Container>
  );
};

export const CherryBanner = () => {
  useLoadPlatform();

  return (
    <S.Container>
      <div className="elfsight-app-238d18fa-1e23-4cb4-b959-cad046308905" />
    </S.Container>
  );
};

export const PangoBanner = () => {
  useLoadPlatform();

  return (
    <S.Container>
      <div className="elfsight-app-25f3c207-ebd1-4f4e-9c21-02818720ed56" />
    </S.Container>
  );
};

export const OmodaBanner = () => {
  useLoadPlatform();

  return (
    <S.Container>
      <div className="elfsight-app-adb77c39-1ffb-4dcc-9bbc-76480e1c4534" />
    </S.Container>
  );
};

export const ExeedBanner = () => {
  useLoadPlatform();

  return (
    <S.Container>
      <div className="elfsight-app-c61dbf8c-ed96-44b2-8656-9d6f1a462a21" />
    </S.Container>
  );
};

export const JaecooBanner = () => {
  useLoadPlatform();

  return (
    <S.Container>
      <div className="elfsight-app-515afb71-bca3-46d0-a587-b609864383dd" />
    </S.Container>
  );
};

export const EbannerGroupTwo = () => {
  useLoadPlatform();

  return (
    <S.Container>
      <div className="elfsight-app-429aad6a-1208-4087-ba60-82af227232d4" />
    </S.Container>
  );
};
