import { put, select, call, takeLatest } from 'redux-saga/effects';
import * as actions from './constants';
import * as provider from 'data-providers/ePoints';
import { getDealers } from '../user/selectors';

function* loadEPointsPrograms() {
  try {
    let dealers = yield select(getDealers);
    if (dealers && dealers.length > 0) {
      const programs = yield call(provider.getEPointsPrograms, dealers);
      yield put({
        type: actions.SET_EPOINTS_PROGRAMS,
        payload: programs,
      });
    }
  } catch (err) {
    console.error('loadEPointsPrograms Error:', err);
  }
}

export function* watchLoadEPointsPrograms() {
  yield takeLatest(actions.LOAD_EPOINTS_PROGRAMS, loadEPointsPrograms);
}
