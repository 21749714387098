import * as actions from './constants';
import initialState from './initialState';

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.CAR_REFERENCE_LOADED_BRANDS: {
      const { brands } = action.payload;

      return { ...state, brands };
    }

    case actions.CAR_REFERENCE_LOADED_MODELS: {
      const { models } = action.payload;

      return { ...state, models };
    }

    case actions.CAR_REFERENCE_LOADED_VERSIONS: {
      const { versions } = action.payload;

      return { ...state, versions };
    }

    case actions.CAR_REFERENCE_SET_BRANDS_FETCH: {
      const { fetch } = action.payload;

      return { ...state, brandsFetch: fetch };
    }

    case actions.CAR_REFERENCE_SET_MODELS_FETCH: {
      const { fetch } = action.payload;

      return { ...state, modelsFetch: fetch };
    }

    case actions.CAR_REFERENCE_SET_VERSIONS_FETCH: {
      const { fetch } = action.payload;

      return { ...state, versionsFetch: fetch };
    }

    case actions.CAR_REFERENCE_SET_ALL_FETCH: {
      const { fetch } = action.payload;

      return { ...state, allFetch: fetch };
    }

    case actions.CAR_REFERENCE_SET_ERROR: {
      const { error } = action.payload;

      return { ...state, error };
    }

    default:
      return state;
  }
}

export function loadBrands(dealerId) {
  return {
    type: actions.CAR_REFERENCE_LOAD_BRANDS,
    payload: dealerId,
  };
}

export function loadModels({ brandId, dealerId = null, vehicleStatus }) {
  return {
    type: actions.CAR_REFERENCE_LOAD_MODELS,
    payload: { brandId, dealerId, vehicleStatus },
  };
}

export function loadVersions({ brandId, modelId, dealerId = null }) {
  return {
    type: actions.CAR_REFERENCE_LOAD_VERSIONS,
    payload: { brandId, modelId, dealerId },
  };
}

export function clearError() {
  return {
    type: actions.CAR_REFERENCE_SET_ERROR,
    payload: { error: '' },
  };
}

export function setAllFetch(fetch) {
  return {
    type: actions.CAR_REFERENCE_SET_ALL_FETCH,
    payload: { fetch },
  };
}
