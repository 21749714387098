import { convertDate } from 'utils/dataMappers/mappers';
import * as DateConverter from 'utils/convertDateUtils';

export const passportInitialState = {
  passport: {
    id: 0,
    isPassportChanged: false,
    serianumber: '',
    series: '',
    number: '',
    issueDate: '',
    issuerCode: '',
    issuer: '',
    citizenship: '',
    birthPlace: '',
  },
  oldPassport: {
    serianumber: '',
    issuerCode: '',
    series: '',
    number: '',
    issueDate: '',
    issuer: '',
  },
};

export const passportFromAPIToState = (customer) => {
  const passport = customer.passport;

  return {
    passport: {
      id: passport.id || null,
      isPassportChanged: passport.changed || false,
      serianumber: passport.series ? `${passport.series}${passport.number}` : '',
      series: passport.series || '',
      number: passport.number || '',
      issueDate: (passport.issueDate && convertDate(passport.issueDate)) || '',
      issuerCode: passport.issuerCode || '',
      issuer: passport.issuer || '',
      citizenship: true,
      birthPlace: passport.birthPlace || '',
    },
    oldPassport: {
      serianumber: passport.previousSeries
        ? `${passport.previousSeries}${passport.previousNumber}`
        : '',
      issuerCode: passport.previousIssuerCode || '',
      issuer: '',
      series: passport.previousSeries || '',
      number: passport.previousNumber || '',
      issueDate: (passport.previousIssueDate && convertDate(passport.previousIssueDate)) || '',
    },
  };
};

export const passportFromStateToAPI = (person) => {
  const passport = person.passport;
  const oldPassport = person.oldPassport;

  return {
    passport: {
      id: passport.id,
      citizenship: '',
      series: passport.series,
      number: passport.number,
      issueDate: DateConverter.convertToBackEndFormat(passport.issueDate),
      issuerCode: passport.issuerCode,
      issuer: passport.issuer,
      birthPlace: passport.birthPlace,
      changed: passport.isPassportChanged,
      previousSeries: oldPassport.series,
      previousNumber: oldPassport.number,
      previousIssueDate: DateConverter.convertToBackEndFormat(oldPassport.issueDate),
      previousIssuerCode: oldPassport.issuerCode,
    },
  };
};
