import styled from 'styled-components';

import { FONT, PALETTE } from 'styles/constants';

export const Text = styled.h3`
  font-family: ${FONT.Roboto};
  font-weight: normal;
  font-style: normal;
  font-size: 16px;
  line-height: 19px;
  color: ${PALETTE.black};
`;
