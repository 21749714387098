import React, { useEffect, useState, useRef } from 'react';
import { Cell, Wrap } from './style';

export const CelluarInput = ({
  className,
  qty = 4,
  disabled = false,
  warning = false,
  onChange = () => {},
}) => {
  const [input, setInput] = useState([]);
  const inputRefs = Array.from({ length: qty }, useRef);

  const fillField = (event, index) => {
    if (event.keyCode === 8) {
      event.stopPropagation();
      event.preventDefault();
      updateInput(index, '');
    } else {
      let key = event.key.replace(/\D/g, '');
      if (key !== '') {
        updateInput(index, key);
      }
    }
  };

  const updateInput = (index, key) => {
    setInput((prevInput) => {
      const newInput = [...prevInput];
      newInput[index - 1] = key;
      return newInput;
    });
    goto(key ? index + 1 : index - 1);
  };

  const paste = (event) => {
    let pasted = event.clipboardData.getData('text');
    pasted = pasted.replace(/\D/g, '');
    pasted = pasted.substring(0, qty);
    if (pasted) {
      setInput(pasted.split(''));
      onChange(input.join(''));
    }
  };

  useEffect(() => {
    if (input.length > 0) {
      onChange(input.join(''));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input]);

  useEffect(() => {
    if (warning) {
      setInput([]);
      goto(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warning]);

  useEffect(() => {
    setTimeout(() => inputRefs[0].current.focus(), 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goto = (fieldNumber) => {
    if (!fieldNumber || fieldNumber > qty) {
      fieldNumber = 1;
    }
    const el = inputRefs[fieldNumber - 1].current;
    el.focus();
  };

  return (
    <Wrap className={className}>
      {Array.from({ length: qty }).map((_, index) => (
        <Cell
          key={index}
          type="text"
          maxLength="1"
          onKeyDown={(event) => fillField(event, index + 1)}
          warning={warning}
          disabled={disabled}
          value={input[index] != null ? input[index] : ''}
          onPaste={(event) => paste(event)}
          ref={inputRefs[index]}
        />
      ))}
    </Wrap>
  );
};
