import {
  convertDate,
  convertOptionToSysName,
  convertSysNameToSelectOption,
} from 'utils/dataMappers/mappers';

export const driverLicenseModel = {
  id: 0,
  changed: false,
  issueDate: '',
  issuer: '',
  series: '',
  number: '',
  seriesNumber: '',
  previousIssueDate: '',
  whereGivenOut: '',
  isFirst: true,
};

const internationalPassportModel = {
  id: 0,
  series: '',
  number: '',
  seriesNumber: '',
  issueDate: '',
  issuer: '',
  expires: '',
};

const militaryIDModel = {
  id: 0,
  series: '',
  number: '',
  seriesNumber: '',
  issueDate: '',
  issuer: '',
};

const snilsModel = {
  id: 0,
  number: '',
};

const taxRegistrationModel = {
  id: 0,
  inn: '',
  series: '',
  number: '',
  seriesNumber: '',
  issueDate: '',
  issuer: '',
  startDate: '',
  whereGivenOut: '',
};

export const secondDocumentInitialState = {
  secondDocument: {
    currentSecondDocumentType: {},
    driverLicense: driverLicenseModel,
    internationalPassport: internationalPassportModel,
    militaryID: militaryIDModel,
    snils: snilsModel,
    taxRegistration: taxRegistrationModel,
  },
};

export const secondDocumentFromAPIToState = (customer, { secondDocumentTypesList }) => {
  const sd = customer.secondDocument;
  const driverLicenseIssueDate = convertDate(sd.driverLicense.issueDate);
  let isFirst = true;
  let driverLicenseStartDate = convertDate(sd.driverLicense.startDate);

  if (
    driverLicenseStartDate.length > 0 &&
    driverLicenseIssueDate.length > 0 &&
    driverLicenseStartDate !== driverLicenseIssueDate
  ) {
    isFirst = false;
  } else {
    driverLicenseStartDate = convertDate(sd.driverLicense.issueDate);
  }

  return {
    secondDocument: {
      currentSecondDocumentType: convertSysNameToSelectOption(
        sd.currentSecondDocumentTypeSysName,
        secondDocumentTypesList
      ),
      driverLicense: sd.driverLicense
        ? {
            ...sd.driverLicense,
            series: sd.driverLicense.series || '',
            number: sd.driverLicense.number || '',
            seriesNumber: `${sd.driverLicense.series}${sd.driverLicense.number}`,
            issueDate: convertDate(sd.driverLicense.issueDate),
            previousIssueDate: convertDate(sd.driverLicense.previousIssueDate),
            startDate: driverLicenseStartDate,
            isFirst,
          }
        : driverLicenseModel,
      internationalPassport: sd.internationalPassport
        ? {
            ...sd.internationalPassport,
            series: sd.internationalPassport.series || '',
            number: sd.internationalPassport.number || '',
            seriesNumber: `${sd.internationalPassport.series}${sd.internationalPassport.number}`,
            issueDate: convertDate(sd.internationalPassport.issueDate),
            expires: convertDate(sd.internationalPassport.expires),
          }
        : internationalPassportModel,
      militaryID: sd.militaryID
        ? {
            ...sd.militaryID,
            series: sd.militaryID.series || '',
            number: sd.militaryID.number || '',
            seriesNumber: `${sd.militaryID.series}${sd.militaryID.number}`,
            issueDate: convertDate(sd.militaryID.issueDate),
            issuer: sd.militaryID.issuer,
          }
        : militaryIDModel,
      snils: sd.snils
        ? {
            ...sd.snils,
          }
        : snilsModel,
      taxRegistration: sd.taxRegistration
        ? {
            ...sd.taxRegistration,
            series: sd.internationalPassport.series || '',
            number: sd.internationalPassport.number || '',
            seriesNumber: `${sd.taxRegistration.series}${sd.taxRegistration.number}`, //todo (sd.internationalPassport.series.length > 0 && `${sd.taxRegistration.series} ${sd.taxRegistration.number}`) || '',
            issueDate: convertDate(sd.taxRegistration.issueDate),
          }
        : taxRegistrationModel,
    },
  };
};

export const secondDocumentFromStateToAPI = (person) => {
  const sd = person.secondDocument;
  const docType = convertOptionToSysName(sd.currentSecondDocumentType);
  return {
    secondDocument: {
      currentSecondDocumentType: sd.currentSecondDocumentType,
      currentSecondDocumentTypeSysName: docType,
      driverLicense: docType === '2nd_driver' ? sd.driverLicense : driverLicenseModel,
      snils: docType === '2nd_pension' ? sd.snils : snilsModel,
      internationalPassport:
        docType === '2nd_foreign_passport' ? sd.internationalPassport : internationalPassportModel,
      militaryID: docType === '2nd_military' ? sd.militaryID : militaryIDModel,
      taxRegistration: docType === '2nd_tax' ? sd.taxRegistration : taxRegistrationModel,
    },
  };
};
