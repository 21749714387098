import styled from 'styled-components';
import { FormGridRowBase } from '../../style';
import {
  AnketaCheckBox,
  AnketaInputMask,
  AnketaSelect,
  AnketaDaData,
  AnketaFormHeader,
} from '../../../style';

export const FormGridRow = styled(FormGridRowBase)`
  grid-template-areas:
    '. head head head head . . .'
    '. adrs adrs adrs adrs rd rd .'
    '. indx indx . . . . . '
    '. h h h h h h .'
    '. adrsLive adrsLive adrsLive adrsLive indxLive indxLive .'
    '. type type type rs rs rs .';
`;

export const FormHeader = styled(AnketaFormHeader)`
  grid-area: head;
  grid-template-areas: '. . . . . . . .';
`;

export const FormPropertyRow = styled(FormGridRowBase)`
  grid-template-areas: '. lastate lastate lastate lastate . . .';
`;

export const Index = styled(AnketaInputMask)`
  grid-area: indx;
`;

export const Address = styled(AnketaDaData)`
  grid-area: adrs;
`;

export const IndexLive = styled(AnketaInputMask)`
  grid-area: indxLive;
`;

export const AddressLive = styled(AnketaDaData)`
  grid-area: adrsLive;
`;

export const RegDate = styled(AnketaInputMask)`
  grid-area: rd;
`;

export const PlaceType = styled(AnketaSelect)`
  grid-area: type;
`;

export const LivingAddressType = styled(AnketaSelect)`
  grid-area: latype;
`;

export const LivingAddressState = styled(AnketaSelect)`
  grid-area: lastate;
`;

export const LivingAddressObtaining = styled(AnketaSelect)`
  grid-area: laobtaining;
`;

export const MatchPlace = styled(AnketaCheckBox)`
  grid-area: ch;
`;

export const LivingAddressEqualsToogle = styled(AnketaCheckBox)`
  grid-area: type;
`;

export const RealtyStateToogle = styled(AnketaCheckBox)`
  grid-area: rs;
`;

export const Subheader = styled.div``;
