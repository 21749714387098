import React from 'react';

import { INSURANCE_WORKFLOW_STATES } from 'constants/insurance';
import { KaskoIcon } from 'assets/img';

import { ProductLampItem } from '../../../ProductLampItem';

const visibleStates = [
  INSURANCE_WORKFLOW_STATES.offers_requested,
  INSURANCE_WORKFLOW_STATES.offers_received,
  INSURANCE_WORKFLOW_STATES.offer_selected,
  INSURANCE_WORKFLOW_STATES.offers_selected,
  INSURANCE_WORKFLOW_STATES.agreement_requested,
  INSURANCE_WORKFLOW_STATES.agreement_succeed,
  INSURANCE_WORKFLOW_STATES.issue_requested,
  INSURANCE_WORKFLOW_STATES.issued,
  INSURANCE_WORKFLOW_STATES.offers_error,
  INSURANCE_WORKFLOW_STATES.issue_error,
  INSURANCE_WORKFLOW_STATES.client_decline,
  INSURANCE_WORKFLOW_STATES.cancelation_requested,
  INSURANCE_WORKFLOW_STATES.canceled,
  INSURANCE_WORKFLOW_STATES.cancelation_error,
];

export const KaskoLampItem = ({ product = {} }) => {
  const { applicationState = {}, selectedOffer = {}, policy = {} } = product;

  const viewOffers = [];
  switch (applicationState.sysName) {
    case INSURANCE_WORKFLOW_STATES.offer_selected:
    case INSURANCE_WORKFLOW_STATES.offers_selected:
    case INSURANCE_WORKFLOW_STATES.agreement_requested:
      viewOffers.push({
        type: 'КАСКО',
        companyName: selectedOffer.insuranceCompanyName || '',
        programName: selectedOffer.title || '',
        price: selectedOffer.amount || 0,
      });
      break;
    case INSURANCE_WORKFLOW_STATES.agreement_succeed:
    case INSURANCE_WORKFLOW_STATES.issue_requested:
    case INSURANCE_WORKFLOW_STATES.issued:
      viewOffers.push({
        type: 'КАСКО',
        companyName: policy.insuranceCompanyName || '',
        programName: policy.title || '',
        price: policy.amount || 0,
      });
      break;
    default: // no default
  }

  return (
    <ProductLampItem
      state={applicationState.sysName}
      title={applicationState.value}
      color={applicationState.color}
      Icon={KaskoIcon}
      offers={viewOffers}
      visibleStates={visibleStates}
    />
  );
};
