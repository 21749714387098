import React from 'react';
import { ReactTooltipStyled } from 'components/modules/MainAnketa/style';
import { PALETTE } from 'styles/constants';
import { InfoIco, ProductInfoRow, ProductInfoTitle, ProductInfoWrap } from './style';
import { convertValueToStandartNumber } from '../../helpers';

const FULL_PRODUCT_NAME = {
  ppi: 'Страхование жизни',
  kasko: 'КАСКО',
  gap: 'GAP',
  other: 'Доп. продукты',
};

export default function ProductInfo({ productList, id, bankProduct = false }) {
  const productItemList = Object.keys(productList);
  return (
    <>
      <InfoIco data-tip="" data-for={id} />
      <ReactTooltipStyled id={id} place="top" arrowColor={PALETTE.grey1}>
        <ProductInfoWrap>
          <ProductInfoTitle>
            {`Финансовые продукты ${bankProduct ? 'банка' : 'дилера'}`}
          </ProductInfoTitle>
          {productItemList.map((item, index) => (
            <ProductInfoRow key={index}>
              <span>{FULL_PRODUCT_NAME[item]}</span>
              <span>{convertValueToStandartNumber(productList[item])} ₽</span>
            </ProductInfoRow>
          ))}
        </ProductInfoWrap>
      </ReactTooltipStyled>
    </>
  );
}
