import styled, { css } from 'styled-components';
import { PALETTE, Z_INDEXES } from 'styles/constants';

export const ButtonStyled = styled.button`
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  padding: 0 45px;
  border-radius: 0;
  background-color: transparent;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  text-transform: none;
  cursor: pointer;
  border-width: 0;
  white-space: nowrap;

  svg {
    display: inline-block;
    vertical-align: middle;
  }

  &:focus {
    outline: 0;
  }

  &:active,
  &:focus {
    opacity: 0.8;
  }

  &:hover {
    opacity: 0.9;
    cursor: pointer;
  }

  ${(p) =>
    p.fullWidth &&
    css`
      display: flex;
      width: 100%;
    `}

  ${(p) =>
    p.disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.5;
      &:hover {
        opacity: 0.5;
        cursor: not-allowed;
      }
    `}
  
  ${(p) =>
    p.type === 'inline' &&
    css`
      background-color: transparent;
      border-color: transparent;
      text-decoration: underline;
    `}
  
  ${(p) =>
    p.type === 'primary' &&
    css`
      background-color: ${PALETTE.green1};
      border-color: ${PALETTE.green1};
      color: ${PALETTE.white};
    `}
  
   ${(p) =>
    p.type === 'outline' &&
    css`
      background-color: transparent;
      border: 1px solid ${PALETTE.green1};
    `}
`;

export const LoaderWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${PALETTE.grey1};
  z-index: ${Z_INDEXES.min};
`;

export const Loader = styled.img`
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 34px;
  width: 70px;
  transform: translate(-50%, -50%);
  z-index: ${Z_INDEXES.min + 1};
`;
