export const CAR_REFERENCE_LOAD_BRANDS = 'CAR_REFERENCE_LOAD_BRANDS';
export const CAR_REFERENCE_LOAD_MODELS = 'CAR_REFERENCE_LOAD_MODELS';
export const CAR_REFERENCE_LOAD_VERSIONS = 'CAR_REFERENCE_LOAD_VERSIONS';
export const CAR_REFERENCE_LOADED_BRANDS = 'CAR_REFERENCE_LOADED_BRANDS';
export const CAR_REFERENCE_LOADED_MODELS = 'CAR_REFERENCE_LOADED_MODELS';
export const CAR_REFERENCE_LOADED_VERSIONS = 'CAR_REFERENCE_LOADED_VERSIONS';
export const CAR_REFERENCE_SET_BRANDS_FETCH = 'CAR_REFERENCE_SET_BRANDS_FETCH';
export const CAR_REFERENCE_SET_MODELS_FETCH = 'CAR_REFERENCE_SET_MODELS_FETCH';
export const CAR_REFERENCE_SET_VERSIONS_FETCH = 'CAR_REFERENCE_SET_VERSIONS_FETCH';
export const CAR_REFERENCE_SET_ALL_FETCH = 'CAR_REFERENCE_SET_ALL_FETCH';
export const CAR_REFERENCE_SET_ERROR = 'CAR_REFERENCE_SET_ERROR';
