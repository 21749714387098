export const initialState = {
  vinInfo: {
    userId: null,
    title: '',
    message: '',
    data: {
      carScore: {
        placements: {
          isActive: false,
          lastPlacementDate: null,
          lastPlacementIsCrashed: false,
          lastPlacementPrice: null,
          minPrice: null,
          number: null,
          placementsWithCrashesNumber: null,
          relevanceDate: null,
        },
        hash: '',
        vin: '',
        isCompleted: false,
        scoringId: null,
        avgMarketPrice: null,
        maxPrice: null,
        taxi: false,
        carsharing: false,
        pledges: false,
        seriousDamage: false,
        restrictions: false,
      },
      errorInfo: null,
      userId: null,
    },
    status: '',
    createdAt: '',
  },
};
