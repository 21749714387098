import styled, { css } from 'styled-components';
import { FONT, PALETTE, BREAKPOINTS } from 'styles/constants';

import { ChevronSolidSvg, ChevronSvg } from 'assets/img';
import { TextStyle1 } from 'components/primitives/typography/textStyles/textStyle1';
import { TextStyle3 } from 'components/primitives/typography/textStyles/textStyle3';
import { alignY } from 'styles/mixins';
import { Button } from 'components/primitives/button';

export const Wrapper = styled.div`
  position: ${(p) => (p.focused ? 'static' : 'relative')};
  font-family: ${FONT.Roboto};
  font-weight: normal;
  font-style: normal;
  background-color: ${PALETTE.white};

  @media screen and (min-width: ${BREAKPOINTS.medium}) {
    position: relative;
  }
`;

export const Selector = styled.div`
  display: flex;
  align-items: center;
  height: 44px;
  cursor: pointer;
  overflow: hidden;
  border: 1px solid ${(p) => (p.required ? PALETTE.red1 : PALETTE.grey2)};

  border-radius: 5px;
  border-bottom-left-radius: ${(p) => (p.focused ? '0px' : '5px')};
  border-bottom-right-radius: ${(p) => (p.focused ? '0px' : '5px')};

  ${(p) =>
    p.disabled &&
    css`
      cursor: not-allowed;
      border-width: 0;
      background-color: ${PALETTE.grey3};
    `}

  ${(p) =>
    (p.focused || p.select) &&
    css`
      display: block;
    `}

	svg {
    transform: ${(p) => (p.focused ? 'rotate(180deg)' : 'rotate(0)')};
    path {
      fill: ${PALETTE.grey1};
    }
  }
`;

export const Label = styled.div`
  font-family: ${FONT.Roboto};
  font-weight: normal;
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
  color: ${PALETTE.black};
  width: 100%;
  margin-left: 9px;
  color: ${(p) => (p.required ? PALETTE.red1 : PALETTE.grey2)};
  cursor: ${(p) => (p.disabled ? 'not-allowed' : 'pointer')};

  ${(p) =>
    p.focused &&
    css`
      margin-top: 3px;
      font-size: 12px;
      line-height: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: calc(100% - 15px);
    `}

  ${(p) =>
    p.defaultValue &&
    css`
      left: 9px;
      top: 3px;
      font-size: 12px;
      line-height: 14px;
    `}
`;

export const Value = styled(TextStyle1)`
  width: max-content;
  position: absolute;
  width: 100%;
  top: 22px;
  left: 9px;
  margin: 0;
  white-space: nowrap;
  color: ${(p) => (p.required ? PALETTE.red1 : PALETTE.dark)};
  width: calc(100% - 35px);
  overflow: hidden;
  text-overflow: ellipsis;

  ${(p) =>
    p.focused &&
    css`
      left: 9px;
      top: 3px;
      font-size: 12px;
      line-height: 14px;
    `}
`;

export const InputStyled = styled.input`
  position: absolute;
  top: 0;
  height: 43px;
  line-height: 43px;
  padding: 25px 9px 9px 9px;
  user-select: none;
  border-width: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  box-sizing: border-box;
  cursor: pointer;
  background-color: transparent;
  z-index: 1;

  &:focus {
    outline: 0;
    box-shadow: none;
  }
`;

export const RightIcon = styled.div`
  position: absolute;
  right: 15px;
  ${alignY()};
  cursor: pointer;
`;

export const ChevronSolidStyled = styled(ChevronSolidSvg)`
  cursor: pointer;
  transition: all 0.1s ease-out;
`;

export const DropDown = styled.div`
  position: absolute;
  top: 56px;
  width: 100%;
  height: calc(100% - 56px);
  box-sizing: border-box;
  border: 1px solid ${PALETTE.grey2};
  border-top: 0;
  overflow-x: hidden;
  background-color: ${PALETTE.white};
  z-index: 100 !important;

  @media screen and (min-width: ${BREAKPOINTS.medium}) {
    height: auto;
    max-height: 300px;
    top: 56px;
  }
`;

export const Title = styled.div`
  position: relative;
  font-size: 16px;
  display: block;
  width: 100%;
  height: 57px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: ${PALETTE.grey3};

  @media screen and (min-width: ${BREAKPOINTS.medium}) {
    display: none;
  }
`;

export const Back = styled(Button)`
  position: absolute;
  top: 0;
  left: 0;
  height: 57px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 110px;
  padding-left: 0;
  padding-right: 0;
`;

export const ChevronStyled = styled(ChevronSvg)`
  margin-left: 10px;
  margin-right: 15px;
  transform: rotate(180deg);

  path {
    fill: ${PALETTE.grey1} !important;
  }
`;

export const Content = styled.div`
  height: calc(100% - 57px);
  overflow: auto;
  left: 0;
  top: 0;

  @media screen and (min-width: ${BREAKPOINTS.medium}) {
    top: 43px;
    height: 100%;
  }
`;

export const List = styled.ul`
  height: 100%;
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

export const Item = styled.li`
  width: 100%;
  box-sizing: border-box;
  padding: 10px 0px 10px 10px;
  font-size: 16px;
  min-height: 22px;
  background-color: ${PALETTE.white};
  cursor: pointer;

  p {
    margin: 0px;
  }

  &:hover {
    background-color: #e7e7e7;
  }

  @media screen and (min-width: ${BREAKPOINTS.medium}) {
    font-size: 12px;
  }
`;

export const Text = styled.p``;

export const Prompt = styled(TextStyle3)`
  width: max-content;
  position: absolute;
  left: 0;
  top: 46px;
  margin: 0;
  color: ${PALETTE.red1};
`;

export const PulseLoaderStyled = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #d8d8d875;
  display: flex;
  justify-content: center;
  align-items: center;
`;
