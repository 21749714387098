import { genderToNum } from './genderHelper';
import * as MapperHelper from './mapperHelper';

const getPersonalInfo = (customer) => ({
  fio: customer.personalInformation
    ? MapperHelper.fioJoin(customer.personalInformation)
    : MapperHelper.fioJoin({
        surname: customer.personalInfo.lastName,
        name: customer.personalInfo.firstName,
        middleName: customer.personalInfo.middleName,
      }),
  gender: genderToNum(
    customer?.personalInformation?.genderSysName || customer?.personalInfo?.gender?.sysName
  ),
  bday: MapperHelper.dateFormmatter(
    customer?.personalInformation?.birthDate || customer?.personalInfo?.birthDate
  ),
});

const getContacts = (customer) => ({
  insurerPhone: customer.contacts.mobilePhone,
  insurerEmail: customer.contacts.email,
});

const getPassport = (customer) => ({
  insurerPassportSerialNum: `${customer.passport.series}${customer.passport.number}`,
  insurerPassportDate: MapperHelper.dateFormmatter(customer.passport.issueDate),
  insurerIssuerCode: customer.passport.issuerCode,
  insurerIssuer: customer.passport.issuer,
  insurerBirthPlace: customer.passport.birthPlace,
});

const getAddress = (customer) => ({
  insurerIndex: customer.registrationAddress.address.indexNumber,
  insurerAddress: customer.registrationAddress.address.fiasValue,
});

const getDriverLicense = (customer) => {
  const issueDate = customer?.secondDocument?.driverLicense?.issueDate || '';
  const startDate = customer?.secondDocument?.driverLicense?.startDate || '';

  const seriesNumber = `${customer?.secondDocument?.driverLicense?.series || ''}${
    customer?.secondDocument?.driverLicense?.number
      ? ` ${customer?.secondDocument?.driverLicense?.number}`
      : ''
  }`;
  const prevSeriesNumber = `${customer?.secondDocument?.driverLicense?.previousSeries || ''}${
    customer?.secondDocument?.driverLicense?.previousNumber
      ? ` ${customer?.secondDocument?.driverLicense?.previousNumber}`
      : ''
  }`;

  const licenseSeriaNum = seriesNumber;
  const prevLicenseSeriaNum = seriesNumber === prevSeriesNumber ? '' : prevSeriesNumber;

  const licenseDate = MapperHelper.dateFormmatter(issueDate) || '';
  const prevLicenseDate =
    startDate === issueDate ? '' : MapperHelper.dateFormmatter(startDate) || '';
  const isFirstLicense = !(prevLicenseSeriaNum || prevLicenseDate);

  return {
    licenseSeriaNum,
    licenseDate,
    prevLicenseSeriaNum,
    prevLicenseDate,
    isFirstLicense,
  };
};

/* insurer data

  id,

  fio,
  gender,
  bday,

  insurerPhone,
  insurerEmail,

  insurerPassportSerialNum,
  insurerPassportDate,
  insurerIssuerCode,
  insurerIssuer,

  insurerIndex,
  insurerAddress,

  licenseSeriaNum,
  licenseDate,
  prevLicenseSeriaNum,
  prevLicenseDate,
  isFirstLicense,

  isDriver,
*/
export const backToFrontCustomer = ({ customer }) => ({
  id: customer.id,
  ...getPersonalInfo(customer),
  ...getContacts(customer),
  ...getPassport(customer),
  ...getAddress(customer),
  ...getDriverLicense(customer),
});

/* driver data
	
  id,

  fio,
  gender,
  bday,

  licenseSeriaNum,
  licenseDate,
  prevLicenseSeriaNum,
  prevLicenseDate,
  isFirstLicense,
*/
export const backToFrontDriver = ({ customer }) => ({
  id: customer.id,
  ...getPersonalInfo(customer),
  ...getDriverLicense(customer),
});

const getRoleCustomerId = (customers, role) =>
  Number(customers.find((customer) => customer.roleSysName === role)?.customerId);

const getMappedFullDataCustomer = (fullDataCustomers, customerId) => {
  const targetCustomer = fullDataCustomers.find(({ id }) => id === customerId);

  if (!targetCustomer) {
    return null;
  }

  return backToFrontCustomer({
    customer: targetCustomer,
  });
};

const backToFrontMapper = ({ fullDataCustomers, customers }) => {
  const owner_customerId = getRoleCustomerId(customers, 'owner');
  const insurer_customerId = getRoleCustomerId(customers, 'insurer');
  const ownerIsInsurer = insurer_customerId === owner_customerId;
  const ownerIsDriver = customers.some(
    (el) => el.roleSysName === 'driver' && Number(el.customerId) === owner_customerId
  );
  const ownerFullData = getMappedFullDataCustomer(fullDataCustomers, owner_customerId);

  const mappedCustomers = { insurer: {} };
  mappedCustomers.owner = { ...ownerFullData, isDriver: ownerIsDriver, isInsurer: ownerIsInsurer };

  let insurerUniqueDataString;
  let driverWhoIsInsurer;

  const allDriversIds = customers
    .filter((customer) => customer.roleSysName === 'driver')
    .map(({ customerId }) => Number(customerId));

  const getCustomerUniqueDataString = ({ fio, bday }) => `${fio}${bday}`;

  if (!ownerIsInsurer) {
    mappedCustomers.insurer = getMappedFullDataCustomer(fullDataCustomers, insurer_customerId);
    mappedCustomers.insurer.errors = {};
  }

  insurerUniqueDataString = getCustomerUniqueDataString(mappedCustomers.insurer);

  driverWhoIsInsurer = fullDataCustomers.find(
    (fullCustomer) =>
      allDriversIds.includes(fullCustomer.id) &&
      insurerUniqueDataString ===
        getCustomerUniqueDataString(backToFrontCustomer({ customer: fullCustomer }))
  );

  mappedCustomers.owner.kaskoInsurerIsDriver = !!driverWhoIsInsurer;

  const genuineDriversIds = allDriversIds.filter((driverId) =>
    driverWhoIsInsurer
      ? driverId !== owner_customerId && driverId !== driverWhoIsInsurer.id
      : driverId !== owner_customerId
  );

  mappedCustomers.drivers = fullDataCustomers
    .filter((el) => genuineDriversIds.includes(el.id))
    .map((el) => backToFrontDriver({ customer: el }));

  return {
    customers: {
      ...mappedCustomers.owner,
      kaskoInsurer: mappedCustomers.insurer,
      drivers: mappedCustomers.drivers,
    },
    ids: {
      ownerId: mappedCustomers.owner.id,
      kaskoInsurerId: mappedCustomers.insurer?.id,
    },
  };
};

export default backToFrontMapper;
