import React, { useState, useRef } from 'react';

import { PALETTE } from 'styles/constants';

import { LampPopover } from './components/LampPopover';
import { Lamp, LampTooltip, LampContainer, State, SubLabel, StateContainer } from './style';

export const LampItem = ({
  className,
  value = null,
  color = PALETTE.white,
  tooltip = '',
  popover = null,
  popoverColor,
  label = '',
  pure = false,
  showState = false,
  subLabel = '',
  showPopoverCounter = true,
}) => {
  const [showAttr, setShowAttr] = useState({ show: false, position: { top: 0, left: 0 } });
  const lRef = useRef();

  const valueColor =
    color === PALETTE.yellow1 || color === PALETTE.white ? PALETTE.dark : PALETTE.white;

  const infoColor = ((color) => {
    if (color === PALETTE.white) {
      return PALETTE.grey1;
    }

    if (color === 'red') {
      return PALETTE.red1;
    }

    return color;
  })(color);

  const mainColor = color === 'red' ? PALETTE.red1 : color;

  const mouseOverHandler = (e) => {
    e.stopPropagation();
    const rect = e.target.getBoundingClientRect();
    // const position = rect.top + window.pageYOffset - document.documentElement.clientTop;
    setShowAttr({ show: true, position: rect });
  };

  let _label = value;
  let info = null;
  if (tooltip && showAttr.show) {
    info = (
      <LampTooltip valueColor={valueColor} color={infoColor}>
        {tooltip}
      </LampTooltip>
    );
  }

  if (popover && showAttr.show) {
    info = (
      <LampPopover
        label={label}
        color={infoColor}
        backgroundColor={popoverColor}
        value={popover}
        position={showAttr.position}
      />
    );
  }

  if (popover && popover.length > 0 && !pure) {
    _label = showPopoverCounter ? `${popover.length}` : _label;
  }

  if (popover && popover.length > 0 && pure) {
    _label = label;
  }

  return (
    <LampContainer
      className={className}
      onMouseLeave={() => setShowAttr({ ...showAttr, show: false })}
      centerElement={subLabel}
    >
      {showState ? (
        <StateContainer>
          <State
            ref={lRef}
            onMouseOver={mouseOverHandler}
            color={mainColor}
            valueColor={valueColor}
          >
            {label}
          </State>
          {subLabel && <SubLabel>{subLabel}</SubLabel>}
        </StateContainer>
      ) : (
        <Lamp ref={lRef} onMouseOver={mouseOverHandler} color={mainColor} valueColor={valueColor}>
          {_label}
        </Lamp>
      )}
      {info}
    </LampContainer>
  );
};
