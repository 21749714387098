import React, { useRef } from 'react';
import debounce from 'lodash.debounce';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HoverNotification from 'components/primitives/hover-notification';

const DEF_CLASS = 'nav-button';
const DEF_CHILD_CLASS = 'nav-button__child';
const clickHandler =
  ({ id, fid, name }, onClick) =>
  () =>
    onClick({ id, fid, name });
const eventHandler = ({ id, fid, name }, callBack) =>
  debounce(() => callBack({ id, fid, name }), 0);

const NavButton = ({
  id,
  fid, // family-id for menu group
  name,
  onClick = () => {},
  onMouseOver = () => {},
  onMouseLeave = () => {},
  onMouseOut = () => {},
  classList = [],
  childClassList = [],
  childType,
  src,
  active,
  children,
  hoverText = '',
  dataTest = '',
}) => {
  const ref = useRef(null);
  let child = null;
  if (childType === 'img') {
    child = <img src={src} alt="" className={cn([DEF_CHILD_CLASS, ...childClassList])} />;
  }
  if (childType === 'icon') {
    child = <FontAwesomeIcon icon={src} className={cn([DEF_CHILD_CLASS, ...childClassList])} />;
  }

  return (
    <div
      ref={ref}
      onClick={clickHandler({ id, fid, name }, onClick)}
      onMouseOver={eventHandler({ id, fid, name }, onMouseOver)}
      onMouseOut={eventHandler({ id, fid, name }, onMouseOut)}
      onMouseLeave={eventHandler({ id, fid, name }, onMouseLeave)}
      className={cn([DEF_CLASS, ...classList, { active }])}
      data-test={dataTest}
    >
      {child || children}
      {hoverText && <HoverNotification componentRef={ref} text={hoverText} />}
    </div>
  );
};

export default NavButton;
