import {
  ADD_RECOGNIZED_FILE,
  ADD_WAITING_FILE,
  REMOVE_RECOGNIZED_FILE,
  REMOVE_WAITING_FILE,
  CHECK_RECOGNIZED_FILES,
  ADD_CLASSIFY_FILE,
  REMOVE_CLASSIFY_FILE,
} from './constants';

export const addRecognizedFile = (file) => ({
  type: ADD_RECOGNIZED_FILE,
  payload: file,
});

export const removeRecognizedFile = (id) => ({
  type: REMOVE_RECOGNIZED_FILE,
  payload: id,
});

export const addClassifyFile = () => ({
  type: ADD_CLASSIFY_FILE,
});

export const removeClassifyFile = () => ({
  type: REMOVE_CLASSIFY_FILE,
});

export const addWaitingFile = (id) => ({
  type: ADD_WAITING_FILE,
  payload: id,
});

export const removeWaitingFile = (id) => ({
  type: REMOVE_WAITING_FILE,
  payload: id,
});

//если ответ из меркури приехал раньше резолва загрузки
export const checkRecognizedFiles = () => ({
  type: CHECK_RECOGNIZED_FILES,
});
