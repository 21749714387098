import React from 'react';

import * as S from './style';

const AssistancePopup = ({ onClose = () => {} }) => {
  return (
    <S.Popup onClose={onClose} contentStyle={{ overflow: 'hidden', height: 600 }}>
      <S.IFrame
        style={{ top: -80 }}
        src="https://forms.amocrm.ru/mtxzrc"
        width="600px"
        height="680px"
      ></S.IFrame>
    </S.Popup>
  );
};

export default AssistancePopup;
