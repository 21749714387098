import md5 from 'blueimp-md5';

import { traefikRequest, agent as request } from 'utils/request';
import throwCustomError from 'utils/throwCustomError';
import * as urls from 'constants/url';

export const getDealById = async (id) => {
  try {
    const res = await traefikRequest
      .get(urls.DEAL_GET_URL(id), {
        headers: {
          'content-type': 'application/json',
          'accept': 'application/json',
        },
      })
      .catch(throwCustomError);

    return res.data || {};
  } catch (err) {
    console.error(err.message);
    throw err;
  }
};

export const getShortDealById = async (id) => {
  try {
    const res = await traefikRequest
      .get(urls.DEAL_GETSHORT_URL(id), {
        headers: {
          'content-type': 'application/json',
          'accept': 'application/json',
        },
      })
      .catch(throwCustomError);

    return res.data || {};
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const getCreditRecipientList = async (carInstanceId, dealerId) => {
  try {
    const res = await request
      .get(urls.DEAL_CREDIT_RECIPIENT_URL(carInstanceId, dealerId), {
        headers: {
          'content-type': 'application/json',
          'accept': 'application/json',
        },
      })
      .catch(throwCustomError);

    return res.data || [];
  } catch (err) {
    console.error(err.message);
    throw err;
  }
};

export const getServiceRecipientList = async (id, carInstanceId) => {
  try {
    const res = await request
      .get(urls.DEAL_SERVICE_RECIPIENT_URL(id, carInstanceId), {
        headers: {
          'content-type': 'application/json',
          'accept': 'application/json',
        },
      })
      .catch(throwCustomError);

    return res.data || [];
  } catch (err) {
    console.error(err.message);
    throw err;
  }
};

export const addFile = async (id, fileId, typeId, ext) => {
  try {
    const data = { applicationId: id, fileId, typeId, ext };
    const res = await traefikRequest
      .post(urls.DEAL_ADD_FILE_URL(), data, {
        headers: {
          'content-type': 'application/json',
          'accept': 'application/json',
        },
      })
      .catch(throwCustomError);

    return res.data || null;
  } catch (err) {
    console.error(err.message);
    throw err;
  }
};

export const addFiles = async (applicationId, files) => {
  try {
    const data = {
      applicationId,
      files: files.map((f) => ({
        fileId: f.id,
        typeId: f.type,
        ext: f.ext,
      })),
    };
    const res = await traefikRequest
      .post(urls.DEAL_ADD_FILES_URL(), data, {
        headers: {
          'content-type': 'application/json',
          'accept': 'application/json',
        },
      })
      .catch(throwCustomError);

    return res.data || null;
  } catch (err) {
    console.error(err.message);
    throw err;
  }
};

export const deleteFile = async (fileIds) => {
  try {
    const data = { fileIds };
    await traefikRequest
      .post(urls.DEAL_DEL_FILE_URL(), data, {
        headers: {
          'content-type': 'application/json',
          'accept': 'application/json',
        },
      })
      .catch(throwCustomError);
  } catch (err) {
    console.error(err.message);
    throw err;
  }
};

export const approvalDeal = async (id, data = {}) => {
  try {
    await traefikRequest
      .post(
        urls.DEAL_APPROVAL_URL(),
        { applicationId: id, ...data },
        {
          headers: {
            'content-type': 'application/json',
            'accept': 'application/json',
          },
        }
      )
      .catch(throwCustomError);
  } catch (err) {
    console.error(err.message);
    throw err;
  }
};

export const confirmManualFinanced = async (id) => {
  try {
    await traefikRequest
      .post(
        urls.DEAL_CONFIRM_MANUAL_FINANCED_URL(),
        { applicationId: id },
        {
          headers: {
            'content-type': 'application/json',
            'accept': 'application/json',
          },
        }
      )
      .catch(throwCustomError);
  } catch (err) {
    console.error(err.message);
    throw err;
  }
};

export const confirmDeal = async (id) => {
  try {
    await traefikRequest
      .post(
        urls.DEAL_CONFIRM_URL(),
        { applicationId: id },
        {
          headers: {
            'content-type': 'application/json',
            'accept': 'application/json',
          },
        }
      )
      .catch(throwCustomError);
  } catch (err) {
    console.error(err.message);
    throw err;
  }
};

export const reapprovalDeal = async (id, data = {}) => {
  try {
    await traefikRequest
      .post(
        urls.DEAL_REAPPROVAL_URL(),
        { applicationId: id, ...data },
        {
          headers: {
            'content-type': 'application/json',
            'accept': 'application/json',
          },
        }
      )
      .catch(throwCustomError);
  } catch (err) {
    console.error(err.message);
    throw err;
  }
};

export const docApprovalDeal = async (id, data = {}) => {
  try {
    await traefikRequest
      .post(
        urls.DEAL_DOC_APPROVAL_URL(),
        { applicationId: id, ...data },
        {
          headers: {
            'content-type': 'application/json',
            'accept': 'application/json',
          },
        }
      )
      .catch(throwCustomError);
  } catch (err) {
    console.error(err.message);
    throw err;
  }
};

export const finApprovalDeal = async (id, data = {}) => {
  try {
    await traefikRequest
      .post(
        urls.DEAL_FIN_APPROVAL_URL(),
        { applicationId: id, ...data },
        {
          headers: {
            'content-type': 'application/json',
            'accept': 'application/json',
          },
        }
      )
      .catch(throwCustomError);
  } catch (err) {
    console.error(err.message);
    throw err;
  }
};

export const declineDeal = async (id, comment) => {
  try {
    await traefikRequest
      .post(
        urls.DEAL_DECLINE_URL(),
        { applicationId: id, comment: comment },
        {
          headers: {
            'content-type': 'application/json',
            'accept': 'application/json',
          },
        }
      )
      .catch(throwCustomError);
  } catch (err) {
    console.error(err.message);
    throw err;
  }
};

export const dealRecalculationDesired = async (id) => {
  try {
    await traefikRequest
      .post(
        urls.DEAL_RECALCULATION_DESIRED_URL(),
        { applicationId: id },
        {
          headers: {
            'content-type': 'application/json',
            'accept': 'application/json',
          },
        }
      )
      .catch(throwCustomError);
  } catch (err) {
    console.error(err.message);
    throw err;
  }
};

export const dealChangeManualFinalParams = async (id) => {
  try {
    await traefikRequest
      .post(
        urls.DEAL_CHANGE_MANUAL_FINAL_PARAMS(),
        { applicationId: id },
        {
          headers: {
            'content-type': 'application/json',
            'accept': 'application/json',
          },
        }
      )
      .catch(throwCustomError);
  } catch (err) {
    console.error(err.message);
    throw err;
  }
};

export const closeDeal = async (id) => {
  try {
    await traefikRequest
      .post(
        urls.DEAL_CLOSE_URL(),
        { applicationId: id },
        {
          headers: {
            'content-type': 'application/json',
            'accept': 'application/json',
          },
        }
      )
      .catch(throwCustomError);
  } catch (err) {
    console.error(err.message);
    throw err;
  }
};

export const requestDocumentFinanced = async (id) => {
  try {
    await traefikRequest
      .post(
        urls.DEAL_REQUEST_DOCUMENT_FINANCED_URL(),
        { applicationId: id },
        {
          headers: {
            'content-type': 'application/json',
            'accept': 'application/json',
          },
        }
      )
      .catch(throwCustomError);
  } catch (err) {
    console.error(err.message);
    throw err;
  }
};

export const getDealRequestedParams = async (id) => {
  try {
    const res = await request
      .get(urls.DEAL_GET_REQUESTED_PARAMS(id), {
        headers: {
          'content-type': 'application/json',
          'accept': 'application/json',
        },
      })
      .catch(throwCustomError);

    return res.data || [];
  } catch (err) {
    console.error(err.message);
    throw err;
  }
};

export const getDealRequestedTerms = async (id) => {
  try {
    const res = await request
      .get(urls.DEAL_GET_REQUESTED_TERMS(id), {
        headers: {
          'content-type': 'application/json',
          'accept': 'application/json',
        },
      })
      .catch(throwCustomError);

    return res.data || [];
  } catch (err) {
    console.error(err.message);
    throw err;
  }
};

export const getDealFinancedParams = async (id) => {
  try {
    const res = await request
      .get(urls.DEAL_GET_FINANCED_PARAMS(id), {
        headers: {
          'content-type': 'application/json',
          'accept': 'application/json',
        },
      })
      .catch(throwCustomError);

    return res.data || [];
  } catch (err) {
    console.error(err.message);
    throw err;
  }
};

export const getDealApprovedParams = async (id) => {
  try {
    const res = await request
      .get(urls.DEAL_GET_APPROVED_PARAMS(id), {
        headers: {
          'content-type': 'application/json',
          'accept': 'application/json',
        },
      })
      .catch(throwCustomError);

    return res.data || [];
  } catch (err) {
    console.error(err.message);
    throw err;
  }
};

export const getDealFinalParams = async (id) => {
  try {
    const res = await request
      .get(urls.DEAL_GET_FINAL_PARAMS(id), {
        headers: {
          'content-type': 'application/json',
          'accept': 'application/json',
        },
      })
      .catch(throwCustomError);

    return res.data || [];
  } catch (err) {
    console.error(err.message);
    throw err;
  }
};

export const confirmFinalParams = async (id, dealId, dealData) => {
  try {
    const data = { applicationId: id, dealId, ...dealData };
    const hash = md5(JSON.stringify(data));

    await traefikRequest
      .post(
        urls.DEAL_CONFIRM_FINAL_PARAMS_URL(),
        { ...data, hash },
        {
          headers: {
            'content-type': 'application/json',
            'accept': 'application/json',
          },
        }
      )
      .catch(throwCustomError);
  } catch (err) {
    console.error(err.message);
    throw err;
  }
};

export const saveFinalParams = async (id, dealId, dealData) => {
  try {
    const data = { applicationId: id, dealId, ...dealData };
    const hash = md5(JSON.stringify(data));

    await traefikRequest
      .post(
        urls.DEAL_SAVE_FINAL_PARAMS_URL(),
        { ...data, hash },
        {
          headers: {
            'content-type': 'application/json',
            'accept': 'application/json',
          },
        }
      )
      .catch(throwCustomError);
  } catch (err) {
    console.error(err.message);
    throw err;
  }
};

export const confirmManualFinalParams = async (id, dealId, dealData) => {
  try {
    const data = { applicationId: id, dealId, ...dealData };
    const hash = md5(JSON.stringify(data));

    await traefikRequest
      .post(
        urls.DEAL_CONFIRM_MANUAL_FINAL_PARAMS_URL(),
        { ...data, hash },
        {
          headers: {
            'content-type': 'application/json',
            'accept': 'application/json',
          },
        }
      )
      .catch(throwCustomError);
  } catch (err) {
    console.error(err.message);
    throw err;
  }
};

export const confirmRecalculationDesired = async (id, dealId, dealData) => {
  try {
    const data = { applicationId: id, dealId, ...dealData };
    const hash = md5(JSON.stringify(data));

    await traefikRequest
      .post(
        urls.DEAL_CONFIRM_RECALCULATION_DESIRED_URL(),
        { ...data, hash },
        {
          headers: {
            'content-type': 'application/json',
            'accept': 'application/json',
          },
        }
      )
      .catch(throwCustomError);
  } catch (err) {
    console.error(err.message);
    throw err;
  }
};

export const requestConfirmSms = async (applicationId) => {
  try {
    const data = { applicationId };

    await traefikRequest
      .post(
        urls.DEAL_REQUEST_CONFIRM_SMS,
        { ...data },
        {
          headers: {
            'content-type': 'application/json',
            'accept': 'application/json',
          },
        }
      )
      .catch(throwCustomError);
  } catch (err) {
    console.error(err.message);
    throw err;
  }
};

export const confirmSmsCode = async (applicationId, otpCode) => {
  try {
    const data = { applicationId, otpCode };

    await traefikRequest
      .post(
        urls.DEAL_CONFIRM_CODE_VERIVICATION,
        { ...data },
        {
          headers: {
            'content-type': 'application/json',
            'accept': 'application/json',
          },
        }
      )
      .catch(throwCustomError);
  } catch (err) {
    console.error(err.message);
    throw err;
  }
};

export const logData = async (applicationId, logData) => {
  try {
    const data = { applicationId, data: logData };

    await traefikRequest
      .post(
        urls.DEAL_LOG_URL(),
        { ...data },
        {
          headers: {
            'content-type': 'application/json',
            'accept': 'application/json',
          },
        }
      )
      .catch(throwCustomError);
  } catch (err) {
    console.error(err.message);
    throw err;
  }
};

export const getRequiredDocumentTypes = async (applicationId) => {
  try {
    const res = await traefikRequest
      .get(
        urls.DEAL_GET_REQUIRED_DOCUMENT_TYPES(applicationId),
        {},
        {
          headers: {
            'content-type': 'application/json',
            'accept': 'application/json',
          },
        }
      )
      .catch(throwCustomError);

    return res.data || [];
  } catch (err) {
    console.error(err.message);
    throw err;
  }
};

export const checkDealDocuments = async (applicationId) => {
  try {
    const data = { applicationId };
    const res = await traefikRequest
      .post(
        urls.DEAL_CHECK_DOCUMENTS(applicationId),
        { ...data },
        {
          headers: {
            'content-type': 'application/json',
            'accept': 'application/json',
          },
        }
      )
      .catch(throwCustomError);

    return res.data || [];
  } catch (err) {
    console.error(err.message);
    throw err;
  }
};

export const patchCustomer = async (customer) => {
  try {
    const url = `/api/customers/${customer.id}`;

    const { data } = await request.patch(url, customer).catch(throwCustomError);

    return data;
  } catch (err) {
    console.log('PATCH CUSTOMER ERROR', err);
    throw err;
  }
};

export const getDealTimers = async (applicationId) => {
  try {
    const res = await traefikRequest
      .get(
        urls.GET_DEAL_TIMER(applicationId),
        {},
        {
          headers: {
            'content-type': 'application/json',
            'accept': 'application/json',
          },
        }
      )
      .catch(throwCustomError);

    return res.data || null;
  } catch (err) {
    console.error(err.message);
    throw err;
  }
};
