import styled from 'styled-components';
import BankLogo from 'components/common/BankLogo';
import * as UK from 'components/primitives/content';
import { PALETTE } from 'styles/constants';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  cursor: pointer;

  &:hover {
    background-color: ${PALETTE.grey9};
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
  border-bottom: 2px solid ${PALETTE.grey9};
`;

export const InfoWrapper = styled.div`
  display: flex;
  min-width: 200px;
`;

export const Title = styled(UK.Title3)`
  font-weight: 500;
  font-size: 14px;
  color: ${PALETTE.dark2};
  margin: 0 10px 0 24px;
`;

export const Date = styled.div`
  font-size: 12px;
  line-height: 13px;
  margin: auto 0;
  color: ${PALETTE.grey13};
`;

export const BanksContainer = styled.div`
  display: flex;
  display: flex;
  max-width: 150px;
  margin-right: 5px;
  overflow-x: auto;
  scrollbar-width: thin;
  scrollbar-color: ${PALETTE.grey11} ${PALETTE.white};
`;

export const BanksInfoButton = styled.div``;

export const LogoIco = styled(BankLogo)`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: 0 5px;
`;
