import { BANKS } from './constants';

export const getBankLogo = (id, type) => {
  return getBank(id, type)?.logo;
};

export const getBankName = (id, type) => {
  return getBank(id, type)?.name;
};

export const getBankColor = (id, type) => {
  return getBank(id, type)?.color;
};

export const getBank = (id, type) => {
  const typeId = `${type}Id`;
  return BANKS.find((bank) => bank[typeId] === String(id));
};
