import styled from 'styled-components';
import { SubHeader } from '../style';

export const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
`;

export const Header = styled(SubHeader)``;

export const ActionButton = styled.div`
  width: 20px;
  height: 20px;
  display: block;
  margin-left: 70px;
  margin-bottom: 15px;
  cursor: pointer;
`;
