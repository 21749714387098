import styled from 'styled-components';
import { FormGridRowBase } from '../../style';
import { AnketaInputMask, AnketaFormHeader } from '../../../style';

export const FormGridRow = styled(FormGridRowBase)`
  grid-template-areas:
    '. head head head head . . .'
    '. sdin sdin date date is . .';
`;

export const FormHeader = styled(AnketaFormHeader)`
  grid-area: head;
  grid-template-areas: '. . . . . . . .';
`;

export const SecDocInput = styled(AnketaInputMask)`
  grid-area: sdin;
`;

export const DriverLicenseDate = styled(AnketaInputMask)`
  grid-area: date;
`;

export const PassportDate = styled(AnketaInputMask)`
  grid-area: date;
`;
