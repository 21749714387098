import React from 'react';
import { convertTel } from 'utils/convertTelephone';
import * as S from './style';

export default function WhatsapLink({ phone = '', onClick = () => {} }) {
  const clearPhone = phone.replace(/\D/g, '');
  const phoneHref = `https://wa.me/${clearPhone}`;
  const convertPhone = phone.length < 12 ? convertTel(phone) : phone;

  return (
    <S.Wraper>
      <S.WatsapLogo />
      <S.PhoneLink
        href={phoneHref}
        title={convertPhone}
        target="_blank"
        onClick={onClick}
        disabled={clearPhone.length !== 11}
      >
        {convertPhone}
      </S.PhoneLink>
    </S.Wraper>
  );
}
