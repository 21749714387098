import styled from 'styled-components';

import { PALETTE } from 'styles/constants';

export const PopoverContainer = styled.div`
  background: ${(p) => p.backgroundColor || 'white'};
  border: 1px solid ${PALETTE.grey2};
  width: 250px;
  min-height: ${(p) => p.height}px;
  padding: 16px 20px;
  color: ${PALETTE.dark};
  font-style: normal;
  font-weight: normal;
  position: fixed;
  border-radius: 8px;
  font-size: 14px;
  top: ${(p) => p.position?.top || 0}px;
  left: ${(p) => p.position?.left || 0}px;
  margin-top: ${(p) => (p.position?.height || 0) + 10}px;
  margin-left: ${(p) => (p.position?.width || 0) / 2 - 125}px;
  z-index: 999;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  box-shadow: 0px 1px 3px 0px ${PALETTE.grey2};
  text-align: left;

  &:after {
    content: '';
    display: block;
    border-style: solid;
    position: absolute;
    margin: 0;
    left: 50%;
    top: -14px;
    margin-left: -7px;
    border-width: 7px;
    border-right-color: transparent;
    border-top-color: transparent;
    border-left-color: transparent;
    border-bottom-color: ${PALETTE.grey2};
  }
`;

export const PopoverLabel = styled.div`
  display: inline-block;
  height: 22px;
  padding: 0 9px;
  display: inline-flex;
  border-radius: 9px;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  background: ${(p) => p.color};
  color: ${(p) => p.valueColor};
`;

export const PopoverItem = styled.span`
  display: inline-block;
  width: 100%;

  & + & {
    margin-top: 5px;
  }
`;

export const PopoverInner = styled.div`
  overflow-y: auto;
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
`;
