import {
  convertDate,
  convertOptionToSysName,
  convertSysNameToSelectOption,
} from 'utils/dataMappers/mappers';
import {
  abstractOrganizationFromAPIToState,
  abstractOrganizationFromStateToAPI,
  abstractOrganizationInitialState,
} from './abstractOrganization';
import { getCareerStart, getExperienceTime } from 'utils/validationUtils/validators';

export const educationTypeHigh = {
  id: 24,
  sysName: 'high',
  value: 'Высшее',
};

export const employerTypeIndividual = {
  id: 3247,
  sysName: 'individual-entrepreneur-3',
  value: 'Индивидуальный предприниматель',
};

export const characterPositionTypeManager = {
  id: 3837,
  sysName: 'new_org-manager',
  value: 'Руководитель предприятия',
};

export const workInitialState = {
  work: {
    employmentType: {},
    educationType: educationTypeHigh,
    isOfficialWork: true,
    organization: abstractOrganizationInitialState,
    organizationPhone: '',
    positionName: '',
    positionType: '',
    isShiftMethod: '',
    experienceTime: '',
    careerStartTime: '',
    employeeDepartmentTypeSysName: '',
    characterPositionType: {},
    homePhone: '',
  },
};

export const workFromAPIToState = (
  customer,
  {
    educationList,
    employmentTypeList,
    // positionTypeList,
    employerNewTypeList,
    employmentFormList,
    characterPositionList,
    companyCountList,
    legalFormList,
    workExpirience,
  }
) => {
  const emp = customer.employment;
  const { homePhone } = customer.contacts;

  return {
    work: {
      employmentType: convertSysNameToSelectOption(emp.typeSysName, employmentTypeList),
      educationType: convertSysNameToSelectOption(customer.educationLevelSysName, educationList),
      isOfficialWork: true,
      organization: abstractOrganizationFromAPIToState(emp.organization, {
        employerNewTypeList,
        employmentFormList,
        companyCountList,
        legalFormList,
      }),
      // organizationPhone: emp.phones.organizationPhone,
      organizationPhone: emp.phones.length ? emp.phones[0] : '',
      positionName: emp.positionName,
      // positionType: convertSysNameToSelectOption(emp.positionTypeSysName, positionTypeList),
      characterPositionType: convertSysNameToSelectOption(
        emp.employeeDepartmentTypeSysName,
        characterPositionList
      ),
      isShiftMethod: emp.rotationWork,
      careerStartTime: convertDate(emp.careerStartCurrent),
      experienceTime: getExperienceTime(workExpirience, convertDate(emp.careerStart)),
      homePhone: homePhone || '',
    },
  };
};

export const workFromStateToAPI = (person) => {
  let w = person.work;
  const c = person.contacts;
  const type = w.employmentType.sysName;

  if (['employment-type-another', 'unemployedRetiree', 'not_working'].includes(type)) {
    const isAnother = type === 'employment-type-another';
    w = {
      ...workInitialState.work,
      employmentType: w.employmentType,
      educationType: w.educationType,
      homePhone: w.homePhone,
      careerStartTime: isAnother ? w.careerStartTime : workInitialState.work.careerStartTime,
      organization: {
        ...abstractOrganizationInitialState,
        inn: isAnother ? w.organization.inn : abstractOrganizationInitialState.inn,
      },
    };
  } else {
    w = { ...w, homePhone: '' };
  }

  return {
    educationLevelSysName: convertOptionToSysName(w.educationType),
    employment: {
      typeSysName: convertOptionToSysName(w.employmentType),
      organization: abstractOrganizationFromStateToAPI(w.organization),
      phones: w.organizationPhone ? [w.organizationPhone] : [],
      isOfficialEmployment: w.isOfficialWork,
      positionName: w.positionName || '',
      // positionTypeSysName: convertOptionToSysName(w.positionType),
      employeeDepartmentTypeSysName: convertOptionToSysName(w.characterPositionType),
      rotationWork: false,
      careerStartCurrent: w.careerStartTime || '',
      careerStart: getCareerStart(
        w.careerStartTime,
        w.experienceTime,
        person.personalInfo ? person.personalInfo.birthDate : null
      ),
    },
    contacts: {
      ...c,
      homePhone: w.homePhone || '',
    },
  };
};
