import React from 'react';

import { PALETTE } from 'styles/constants';

import { PopoverContainer, PopoverLabel, PopoverInner, PopoverItem } from './style';

const MIN_HEIGHT = 15;
const MAX_HEIGHT = 136;

export const LampPopover = ({
  label,
  color,
  backgroundColor,
  position = { top: 0, left: 0 },
  value = [],
  children,
}) => {
  const valueColor = color === PALETTE.yellow1 ? PALETTE.black : PALETTE.white;
  const items = value.map((val, index) => <PopoverItem key={`${val}-${index}`}>{val}</PopoverItem>);

  return (
    <PopoverContainer
      position={position}
      backgroundColor={backgroundColor}
      height={items.length > 1 ? MAX_HEIGHT : MIN_HEIGHT}
    >
      <PopoverLabel color={color} valueColor={valueColor}>
        {label}
      </PopoverLabel>
      {(items.length || children) && <PopoverInner>{items || children}</PopoverInner>}
    </PopoverContainer>
  );
};
