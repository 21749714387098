import React, { useEffect, useRef } from 'react';
import { Modal } from 'components/primitives/modal';
import { CloseSvg } from 'assets/img';
import { PseudoButton, CloseButton, Agree, Controls, Title, Text, Wrapper, Cancel } from './style';

export const Alert = ({
  title = null,
  desc = null,
  children,
  agreeText = null,
  cancelText = null,
  onAgree = () => {},
  onCancel = () => {},
  onClose = null,
  className,
  attention = false,
  agreeDataTest,
  isAgreeDisabled = false,
}) => {
  const ref = useRef();

  useEffect(() => {
    if (ref?.current) {
      /* eslint-disable-next-line */
      ref.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref?.current]);

  return (
    <Modal position="fixed" className={className}>
      <Wrapper>
        <PseudoButton type="button" tabIndex={-1} ref={ref} />
        {onClose && (
          <CloseButton onClick={onClose}>
            <CloseSvg />
          </CloseButton>
        )}
        {title && <Title>{title}</Title>}
        {children || (
          <Text>{desc ? desc.split('<br>').map((s, i) => <p key={`desc_${i}`}>{s}</p>) : ''}</Text>
        )}
        <Controls>
          {cancelText && <Cancel onClick={onCancel}>{cancelText}</Cancel>}
          {agreeText && (
            <Agree
              type="primary"
              attention={attention}
              onClick={onAgree}
              dataTest={agreeDataTest}
              disabled={isAgreeDisabled}
            >
              {agreeText}
            </Agree>
          )}
        </Controls>
      </Wrapper>
    </Modal>
  );
};
