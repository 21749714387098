import React from 'react';

import { LeftInfo } from './components/LeftInfo/LeftInfo';
import { RightInfo } from './components/RightInfo/RightInfo';

import * as S from './style';

export const Content = () => {
  return (
    <S.Container>
      <LeftInfo />
      <RightInfo />
    </S.Container>
  );
};
