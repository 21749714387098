import React from 'react';
import moment from 'moment';

import ClipboardCopy from 'components/common/ClipboardCopy';

import * as S from './style';

export default function SearchItem({ data }) {
  return (
    <S.Container>
      <S.Wrapper>
        <S.Date>{moment(data.date).format('DD.MM.YYYY')}</S.Date>
        <S.Title>VIN: {data.vin}</S.Title>
        <ClipboardCopy value={data.vin} className={'add-margin-left'} />
      </S.Wrapper>
    </S.Container>
  );
}
