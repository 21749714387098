import styled, { css } from 'styled-components';

import { PALETTE } from 'styles/constants';

export const Button = styled.button`
  margin: 20px auto 0px auto;
  background: ${PALETTE.blue4};
  border: 1px solid ${PALETTE.blue2};
  box-sizing: border-box;
  border-radius: 5px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  width: 160px;
  min-height: 34px;
  transition: all 0.5s ease;
  cursor: pointer;
  ${(props) =>
    props.isRedButton &&
    css`
      background: rgba(205, 38, 38, 0.45);
      border-color: ${PALETTE.red1};
    `}
  &:hover {
    background: ${PALETTE.blue2};
    ${(props) =>
      props.isRedButton &&
      css`
        background: ${PALETTE.red1};
      `}
  }
`;

export const Cost = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 1;
  color: ${(props) => (props.isGray ? PALETTE.grey1 : PALETTE.black)};
  white-space: nowrap;
`;

export const CompanyName = styled.div`
  margin-top: ${(props) => (props.withGap ? '5px' : '0')};
  font-size: 12px;
  line-height: 1;
  color: ${PALETTE.grey1};
`;

export const Offer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  font-size: 12px;
  line-height: 1;
  color: ${PALETTE.grey1};
  margin-bottom: 10px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;
