import React, { useState, useEffect, useRef } from 'react';
import animateScrollTo from 'animated-scroll-to';
import PropTypes from 'prop-types';
import { InputMask } from 'utils/inputMask';
import { FormHeader } from '../Form/FormHeader';
// CREDIT-1175
// import { GenderSelector } from '../GenderSelector';
import { genderToNum } from '../../helpers/genderHelper';
import { Alert } from 'components/primitives/alert';
import { fioWithMiddlenameRegexp, fioRegexp } from '../../vlidator/regexps';
import { PERSONAL_INFO } from '../../constants';
import { removeExtraSpaces } from 'utils/stringUtils';

import * as S from './style';

export const PersonalInfo = ({
  disabled = false,
  counter = 1,
  onDelete = null,
  onBlur = () => {},
  onScroll = () => {},
  scrollTo = null,
  title = '',
  data,
  errors = {},
}) => {
  const ref = useRef();

  const headerTitle = title || `Водитель #${counter}`;

  const [state, setState] = useState({ ...data });

  const [showAlert, setShowAlert] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});

  useEffect(() => {
    if (ref && ref.current && scrollTo === PERSONAL_INFO.name) {
      animateScrollTo(ref.current);
      onScroll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollTo]);

  useEffect(() => {
    setState({ ...data });
  }, [data]);

  useEffect(() => {
    setValidationErrors(errors);
  }, [errors]);

  const onBlurHandler = async (value) => {
    onBlur({ state, value });
  };

  const alertAgreeHandler = () => {
    setShowAlert(false);

    const newFio = `${state.fio.trim()}`;
    const newState = { ...state, fio: newFio };

    setState(newState);

    onBlur({ value: { fio: newFio }, state: newState });
  };

  const alertCancelHandler = () => {
    setShowAlert(false);
  };

  const handleSelect = ({ fio }) => {
    const iGender = fio?.data?.gender || (state.gender ? 'FEMALE' : 'MALE') || 'MALE';

    setState({ ...state, fio: fio.query, gender: genderToNum(iGender) });

    onBlur({ state: { ...state, fio: fio.query }, value: { fio: fio.query } });
    onBlur({
      state: { ...state, gender: genderToNum(iGender) },
      value: { gender: genderToNum(iGender) },
    });
  };

  return (
    <S.FormContainer ref={ref}>
      <S.HeaderWrapper>
        <FormHeader title={headerTitle} onAction={onDelete} />
      </S.HeaderWrapper>

      <S.FioInput
        disabled={disabled}
        required
        name="fio"
        type="fio"
        error={!!validationErrors.fio}
        errorMessage={validationErrors.fio}
        label="Фамилия, имя, отчество"
        mask={InputMask.RUS_NAME}
        onBlur={onBlurHandler}
        value={state.fio}
        onChange={({ fio }) => {
          setState({ ...state, fio: removeExtraSpaces(fio.value) });
        }}
        onSelect={handleSelect}
        showAlert={() => {
          if (!fioWithMiddlenameRegexp.test(state.fio) && fioRegexp.test(state.fio)) {
            setShowAlert(true);
          }
        }}
      />

      {/* CREDIT-1175 */}
      {/*<S.GenderSelectorWrapper>*/}
      {/*<GenderSelector*/}
      {/*disabled={disabled}*/}
      {/*value={state.gender}*/}
      {/*onChange={({ gender }) => {*/}
      {/*setState({ ...state, gender });*/}
      {/*onBlur({ value: { gender }, state });*/}
      {/*}}*/}
      {/*/>*/}
      {/*</S.GenderSelectorWrapper>*/}

      <S.BdayInput
        disabled={disabled}
        required
        isControlledComponent
        name="bday"
        unmask={true}
        label="Дата рождения"
        mask={InputMask.DATE_BEFORE_TODAY_NEW}
        onBlur={onBlurHandler}
        error={validationErrors?.bday}
        val={state.bday}
        onChange={({ bday }) => {
          setState({ ...state, bday });
        }}
      />
      {showAlert && (
        <Alert
          title={'Заполните ФИО чётко в соответствии с паспортом'}
          desc={'Если у вас отсутствует отчество, нажмите ОК'}
          agreeText={'ОК'}
          cancelText={'Отмена'}
          onAgree={alertAgreeHandler}
          onCancel={alertCancelHandler}
        />
      )}
    </S.FormContainer>
  );
};

PersonalInfo.propTypes = {
  disabled: PropTypes.bool,
  counter: PropTypes.number,
  title: PropTypes.string,
  errors: PropTypes.object,
  data: PropTypes.shape({
    fio: PropTypes.string,
    gender: PropTypes.number,
    bday: PropTypes.string,
  }),
  onDelete: PropTypes.oneOfType([PropTypes.func, PropTypes.oneOf([null])]),
  onBlur: PropTypes.func,
};
