import React, { useState } from 'react';

import ParamsCell from '../ParamsCell';
import ScrollContainer from './ScrollContainer';

import * as S from './style';
import { convertCoinsToString } from 'utils/convertPrice';

export default function InfoCard({ data }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [hideBanks, setHideBanks] = useState(true);

  const handleButtonClick = (index) => {
    setActiveIndex(index);
  };

  const allUniqueBanks = data.bankIds.filter(
    (bankId, index, bankIds) => index === bankIds.lastIndexOf(bankId)
  );

  const allOnlineBanks = data.onlineBanksIds;

  const notOnlineBanks = allUniqueBanks.filter((item) => allOnlineBanks.indexOf(item) === -1);

  const sortedBanks = [...allOnlineBanks, ...notOnlineBanks];

  const showedBanks = 4;

  const labelsObj = {
    green: (
      <S.LabelWrapper>
        <S.StyledGreenLabel />
      </S.LabelWrapper>
    ),
    yellow: (
      <S.LabelWrapper>
        <S.StyledYellowLabel />
      </S.LabelWrapper>
    ),
    red: (
      <S.LabelWrapper>
        <S.StyledRedLabel />
      </S.LabelWrapper>
    ),
  };

  const percentsObj = {
    year: data.carApproved.yearDiff > 0 ? labelsObj.red : labelsObj.green,
    creditSum:
      data.creditApproved.creditSumDiffPercent >= -4.99
        ? labelsObj.green
        : data.creditApproved.creditSumDiffPercent >= -14.99 &&
          data.creditApproved.creditSumDiffPercent <= -5
        ? labelsObj.yellow
        : data.creditApproved.creditSumDiffPercent <= -15
        ? labelsObj.red
        : null,
    downPayment:
      data.creditApproved.downPaymentDiffPercent === 0
        ? labelsObj.green
        : data.creditApproved.downPaymentDiffPercent <= 4.99
        ? labelsObj.yellow
        : labelsObj.red,
    monthlyPayment:
      data.creditApproved.monthlyPaymentDiffPercent <= 4.99
        ? labelsObj.green
        : data.creditApproved.monthlyPaymentDiffPercent <= 9.99
        ? labelsObj.yellow
        : labelsObj.red,
    creditRate:
      data.creditApproved.creditRateDiff <= 1
        ? labelsObj.green
        : data.creditApproved.creditRateDiff <= 2.9
        ? labelsObj.yellow
        : labelsObj.red,
  };

  convertCoinsToString(data.creditRequested.creditSum, false);

  return (
    <S.InfoContainer>
      {sortedBanks.length > showedBanks ? (
        hideBanks ? (
          <S.BanksLogoWraper>
            {sortedBanks.slice(0, showedBanks).map((bank, index) => (
              <S.BanksInfoButton
                key={`${bank}_${index}`}
                active={index === activeIndex}
                onClick={() => handleButtonClick(index)}
              >
                <S.LogoIco type="pps" id={bank} />
              </S.BanksInfoButton>
            ))}
            <S.ShowBanksButton onClick={() => setHideBanks(false)}>
              <S.ShowBanksLogo>+{sortedBanks.length - showedBanks}</S.ShowBanksLogo>
            </S.ShowBanksButton>
          </S.BanksLogoWraper>
        ) : (
          <ScrollContainer>
            {!hideBanks &&
              sortedBanks.map((bank, index) => (
                <S.BanksInfoButton
                  key={`${bank}_${index}`}
                  active={index === activeIndex}
                  onClick={() => handleButtonClick(index)}
                >
                  <S.LogoIco type="pps" id={bank} />
                </S.BanksInfoButton>
              ))}
            <S.ShowBanksButton onClick={() => setHideBanks(true)}>
              <S.ShowBanksLogo>⬅</S.ShowBanksLogo>
            </S.ShowBanksButton>
          </ScrollContainer>
        )
      ) : (
        <S.BanksLogoWraper>
          {sortedBanks.map((bank, index) => (
            <S.BanksInfoButton
              key={`${bank}_${index}`}
              active={index === activeIndex}
              onClick={() => handleButtonClick(index)}
            >
              <S.LogoIco type="pps" id={bank} />
            </S.BanksInfoButton>
          ))}
        </S.BanksLogoWraper>
      )}

      <S.BanksInfo>
        <ParamsCell
          label={'Марка, модель, год авто'}
          model={
            data.carRequested.brand && data.carRequested.model
              ? `${data.carRequested.brand} ${data.carRequested.model}`
              : ''
          }
          requested={
            data.carRequested.year && data.carRequested.year !== data.carApproved.year
              ? data.carRequested.year
              : ''
          }
          approved={data.carApproved.year || '-'}
          colorLabel={percentsObj.year}
        />
        <ParamsCell
          label={'Стоимость авто'}
          approved={
            data.carRequested.cost ? convertCoinsToString(data.carRequested.cost, false) : '-'
          }
        />
        <ParamsCell
          label={'Сумма кредита'}
          requested={
            data.creditRequested.creditSum &&
            data.creditRequested.creditSum !== data.creditApproved.creditSum
              ? convertCoinsToString(data.creditRequested.creditSum, false)
              : ''
          }
          approved={
            data.creditApproved.creditSum
              ? convertCoinsToString(data.creditApproved.creditSum, false)
              : '-'
          }
          colorLabel={percentsObj.creditSum}
        />
        <ParamsCell
          label={'ПВ'}
          requested={
            data.creditRequested.downPayment &&
            data.creditRequested.downPayment !== data.creditApproved.downPayment
              ? convertCoinsToString(data.creditRequested.downPayment, false)
              : ''
          }
          approved={
            data.creditApproved.downPayment
              ? convertCoinsToString(data.creditApproved.downPayment, false)
              : '-'
          }
          colorLabel={percentsObj.downPayment}
        />
        <ParamsCell
          label={'Ежемесячный платеж'}
          requested={
            data.creditRequested.monthlyPayment &&
            data.creditRequested.monthlyPayment !== data.creditApproved.monthlyPayment
              ? convertCoinsToString(data.creditRequested.monthlyPayment, false)
              : ''
          }
          approved={
            data.creditApproved.monthlyPayment
              ? convertCoinsToString(data.creditApproved.monthlyPayment, false)
              : '-'
          }
          colorLabel={percentsObj.monthlyPayment}
        />
        <ParamsCell
          label={'Ставка'}
          requested={
            data.creditRequested.creditRate &&
            data.creditRequested.creditRate !== data.creditApproved.creditRate
              ? `${data.creditRequested.creditRate} %`
              : ''
          }
          approved={data.creditApproved.creditRate ? `${data.creditApproved.creditRate} %` : '-'}
          colorLabel={percentsObj.creditRate}
        />
      </S.BanksInfo>
    </S.InfoContainer>
  );
}
