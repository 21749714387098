import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setFilter, filterTouch } from 'store/filters';
import { isEqualArrays } from 'utils/common';
import { ksoFilterTrack } from 'metrika/applications';

import * as help from '../../helpers';
import * as S from '../../style';

export const Kso = ({ isVisible = true }) => {
  const rootDispatch = useDispatch();
  const userId = useSelector((store) => store.user?.id || '');
  const storeKses = useSelector((store) => store.applicFilters?.ks || []);
  const filtersFetch = useSelector((store) => store.applicFilters.fetch);

  const [isChanged, setIsChanged] = useState(false);

  const kses = useMemo(() => help.getWorkers(storeKses, userId), [storeKses, userId]);

  const changeHandler = (event) => {
    const ids = event['kso'] || [];
    if (isEqualArrays(ids, help.getSelectedIds(kses))) {
      return;
    }

    setIsChanged(true);

    const newKses = help.setItemsSelection(storeKses, ids);
    rootDispatch(setFilter({ filter: 'ks', value: newKses }));
    rootDispatch(filterTouch());
    ksoFilterTrack();
  };

  useEffect(() => {
    if (!filtersFetch) {
      setIsChanged(false);
    }
  }, [filtersFetch]);

  if (!isVisible && !isChanged) {
    return null;
  }

  return (
    <S.Worker
      name="kso"
      label="КСО"
      list={kses}
      defaultValue={help.getSelectedIds(kses)}
      multiselect={true}
      search={true}
      checked={true}
      checkedAll={true}
      fetch={!filtersFetch}
      onBlur={changeHandler}
    />
  );
};
