import styled from 'styled-components';
import { Search2Svg as SearchSvg } from 'assets/img';

export const Wrapper = styled.div`
  margin-bottom: auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

export const SearchContainer = styled.div`
  background: #ffffff;
  border: 1px solid #b5b5b5;
  padding: 8px 10px;
  margin-left: ${({ marginSide }) => marginSide || 0};
  margin-right: ${({ marginSide }) => marginSide || 0};
  max-height: 30px;
  display: flex;
`;

export const Input = styled.input`
  border-width: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  height: 14px;
  color: #a8a8a8;
  width: calc(100% - 10px);
  &:hover,
  &:focus,
  &:active {
    border-width: 0;
    outline: 0;
    box-shadow: none;
  }
`;

export const Button = styled(SearchSvg)`
  width: 14px;
  height: 14px;
  cursor: pointer;
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
`;

export const ListItem = styled.button`
  border: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 34px;
  outline: none;
  padding: 0.5rem 2rem;
  color: ${({ selected }) => (selected ? '#cd2626' : 'black')};
  font-weight: ${({ selected }) => (selected ? 500 : 400)};
  background-color: white;
  cursor: pointer;
  text-align: left;
  &:hover {
    background: #fbe1e1;
  }
`;

export const FixedListItem = styled.button`
  position: fixed;
  top: ${(props) => props.y}px;
  left: ${(props) => props.x}px;
  border: none;
  height: 34px;
  background: #fdd;
  outline: none;
  cursor: pointer;
  text-align: left;
  padding: 8px;
  border-bottom: ${(props) => (props.selected ? '1px solid red' : '1px solid transparent')};
`;

export const SelectedListItem = styled.button`
  width: 100%;
  outline: none;
  padding: 8px;
  margin-top: 5px;
  margin-bottom: 5px;
  background: #fdd;
  border: none;
  border-bottom: 1px solid red;
`;

export const ErrorBlock = styled.div`
  width: 160px;
  min-height: 100px;
  background: #fcc;
  color: red;
  border: 1px solid red;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;
