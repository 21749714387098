import React, { useEffect, useState } from 'react';

import { useTimer } from 'hooks/useTimer';
import { secondsToTimerString } from 'utils/common';
import * as UK from 'components/primitives/content';

import * as S from './style';

const fn = () => {};

export const ERRORS_LIST = {
  codeError: 'Введён неверный код подтверждения',
  otherError: (
    <>
      Произошла ошибка. <br />
      Обратитесь, пожалуйста, в техническую поддержку.
    </>
  ),
  limitError: (
    <>
      Код введён неверно более 5 раз. <br />
      Запросите SMS повторно по истечении 1 минуты
    </>
  ),
};

export const SMSVerification = ({
  className,
  codeSize = 4,
  resendTimeout = 60,
  disabled = false,
  hasError = false,
  onVerification = fn,
  onResend = fn,
  onSuccess = fn,
}) => {
  const [isRequested, setIsRequested] = useState(false);
  const [error, setError] = useState(hasError ? 'codeError' : null);
  const timer = useTimer(resendTimeout);

  const isViewResendButton = timer.seconds === 0;

  const changeHandler = async (value) => {
    setError(null);
    if (value.length === codeSize) {
      setIsRequested(true);
      const result = await onVerification(value);
      setIsRequested(false);

      if (result && typeof result === 'boolean') {
        onSuccess(value);
      } else {
        if (result && result.otherError) {
          setError(result.type);
        } else {
          setError('codeError');
        }
      }
    }
  };

  const resendCodeHandler = (e) => {
    e.preventDefault();
    setError(null);
    timer.start();
    onResend();
  };

  useEffect(() => {
    timer.start();
    return () => timer.stop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (hasError) {
      setError('codeError');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasError]);

  return (
    <S.Wrapper className={className}>
      <S.Input
        qty={codeSize}
        disabled={disabled || isRequested}
        warning={error}
        onChange={changeHandler}
      />

      {error && <S.Error>{ERRORS_LIST[error]}</S.Error>}

      {!disabled && (
        <S.Resend>
          {isViewResendButton ? (
            <UK.Paragraph>
              <S.ResendLink
                href="/request-code"
                rel="noopener noreferrer"
                onClick={resendCodeHandler}
              >
                <S.RefreshIcon />
                Отправить код повторно
              </S.ResendLink>
            </UK.Paragraph>
          ) : (
            <>
              <UK.Paragraph>Запросить код повторно можно через</UK.Paragraph>
              <UK.Title2>{secondsToTimerString(timer.seconds)}</UK.Title2>
            </>
          )}
        </S.Resend>
      )}
    </S.Wrapper>
  );
};
