import axios from 'axios';
import { DadataSuggestionsBaseUrl, DadataType, DadataHeaders } from './resources';

// const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay));

export const DadataGetSuggestions = async ({
  type,
  query,
  count = 10,
  queryParams = {},
  prepareResult = () => {},
}) => {
  let result = null;

  try {
    if (!DadataType[type]) {
      throw new Error(`unknown dadata type ${type}`);
    }

    // await sleep(5000);

    const url = `${DadataSuggestionsBaseUrl}${DadataType[type]}`;

    const response = await axios.post(
      url,
      { count, query, ...queryParams },
      { headers: { ...DadataHeaders } }
    );

    const { data, status } = response;

    if (status !== 200) {
      throw new Error(`dadata return error ${status}`);
    }

    result = { query, suggestions: data.suggestions };
  } catch (error) {
    result = { query, suggestions: null };
  }

  prepareResult(result);

  return result;
};
