import styled, { css } from 'styled-components';
import { FONT, PALETTE, Z_INDEXES } from 'styles/constants';
import ReactDadataBox from 'patches/react-dadata-box';
import { TextStyle3 } from 'components/primitives/typography/textStyles/textStyle3';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  font-family: ${FONT.Roboto};
  font-weight: normal;
  font-style: normal;
  height: 44px;
  border: ${(p) => (p.mask ? 0 : '1px')} solid ${(p) => (p.required ? PALETTE.red1 : PALETTE.grey2)};
  position: relative;
  cursor: pointer;
  min-width: 80px;

  > div {
    width: 100%;
  }

  .react-dadata__suggestions {
    position: absolute;
    top: 44px;
    z-index: ${Z_INDEXES.maxIndex};
  }

  ${(p) =>
    p.disabled &&
    css`
      cursor: not-allowed;
      border-width: 0;
      background-color: ${PALETTE.grey3};
    `}

  ${(p) =>
    p.focused &&
    css`
      display: block;
    `}
`;

export const ReactDadataStyled = styled(ReactDadataBox)`
  &.react-dadata input {
    position: absolute;
    height: 43px;
    line-height: 43px;
    padding: 25px 9px 9px 9px;
    border-width: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    box-sizing: border-box;
    cursor: ${(p) => (p.disabled ? 'not-allowed' : 'pointer')};
    background-color: ${(p) => (p.disabled ? 'transparent' : 'transparent')};
    color: ${PALETTE.dark};

    &:focus {
      outline: 0;
      box-shadow: none;
    }
  }
`;

export const Label = styled.div`
  font-family: ${FONT.Roboto};
  font-weight: normal;
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
  color: ${PALETTE.black};
  width: 100%;
  margin-left: 9px;
  color: ${(p) => (p.required ? PALETTE.red1 : PALETTE.grey2)};
  ${(p) =>
    p.focused &&
    css`
      font-size: 12px;
      line-height: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: calc(100% - 15px);
    `}
`;

export const Prompt = styled(TextStyle3)`
  width: max-content;
  position: absolute;
  left: 0;
  top: 46px;
  margin: 0;
  color: ${PALETTE.red1};
`;
