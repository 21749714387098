import styled, { css } from 'styled-components';
import { PALETTE } from 'styles/constants';
import { TextStyle1 } from 'components/primitives/typography/textStyles/textStyle1';

export const Wrapper = styled(TextStyle1)`
  margin: 0;
  white-space: nowrap;
  color: ${(props) => (props.required ? PALETTE.red1 : PALETTE.dark)};
  overflow: hidden;
  text-overflow: ellipsis;

  ${(props) =>
    props.focused &&
    css`
      left: 9px;
      top: 3px;
      font-size: 12px;
      line-height: 14px;
    `}
`;
