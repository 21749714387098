import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import debounce from 'lodash/debounce';

import { getCreditRecipientList } from 'data-providers/dealProvider';
import List from './components/List';
import * as S from './style';

const Providers = ({ search }) => {
  const { pathname } = useLocation();
  const dealerId = useSelector((store) => store?.user?.dealer);
  const [isFetching, setIsFetching] = useState(true);
  const [recipients, setRecipients] = useState([]);
  const [accountNumber, setAccountNumber] = useState('');
  const [accountNumberSearch, setAccountNumberSearch] = useState('');

  const filterCode = useMemo(() => {
    let code = null;
    if (pathname.includes('car')) {
      code = 'DEALER';
    }
    if (pathname.includes('insurance')) {
      code = 'INSUR';
    }
    return code;
  }, [pathname]);

  const filteredRecipients = useMemo(() => {
    let filtered = [...recipients];
    if (filterCode) {
      filtered = filtered.filter((i) => i.code === filterCode);
    }
    if (accountNumberSearch) {
      filtered = filtered.filter((i) => {
        const acc = i.accountNumber || '';
        const ending = acc.substring(acc.length - 4);
        return ending.includes(accountNumberSearch);
      });
    }
    if (search) {
      filtered = filtered.filter((i) => {
        const searchLower = search.toLowerCase();
        return (
          (i?.name || '').toLowerCase().includes(searchLower) ||
          (i?.nameShort || '').toLowerCase().includes(searchLower) ||
          (i?.legalInn || '').toLowerCase().includes(searchLower)
        );
      });
    }
    return filtered;
  }, [recipients, filterCode, accountNumberSearch, search]);

  useEffect(() => {
    if (dealerId) {
      setIsFetching(true);
      getCreditRecipientList(null, dealerId)
        .then((data) => {
          setRecipients(data);
        })
        .finally(() => {
          setIsFetching(false);
        });
    }
  }, [dealerId]);

  const accountNumberChangeHandler = (val) => {
    setAccountNumber(val);
    accountSearchHandler(val);
  };

  const accountSearchHandler = useCallback(
    debounce((val) => {
      setAccountNumberSearch(val);
    }, 300),
    []
  );

  return (
    <S.Container>
      <S.SearchInput
        placeholder="Введите последние 4 цифры р/с"
        val={accountNumber}
        withClearButton={false}
        onChange={accountNumberChangeHandler}
      />
      <List recipients={filteredRecipients} isFetching={isFetching} />
    </S.Container>
  );
};

export default Providers;
