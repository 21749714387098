// НЕ ИСПОЛЬЗОВАТЬ КАК ПРОВАЙДЕР ДЛЯ REACT_ADMIN !!!
import { stringify } from 'query-string';
import axios from 'axios';
import { agent as authRequest } from 'utils/authRequest';
import { agent as appRequest } from 'utils/request';
import HttpError from 'utils/errors/HttpError';
import throwCustomError from 'utils/throwCustomError';
import { DEALERS_URL } from 'constants/url';
import * as Helper from './helper';
import * as localStorage from 'utils/local-storage';
import { clearAuthCookie, setAuthCookie, setServerCookies } from 'utils/helpers';
import handler401 from 'utils/handler401';

let authUrl = `${process.env.REACT_APP_API_ENTRYPOINT}`;
if (process.env.REACT_APP_MODE === 'dev') {
  authUrl = `${process.env.REACT_APP_LOCAL_API_URL}`;
}

export const login = async (params) => {
  try {
    const { status, data } = await authRequest.post('/auth/login', params);
    if (status !== 200 || !data?.token) {
      throw new HttpError(`status ${status}`);
    }

    return data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const getCurrentUser = async () => {
  try {
    const { status, data } = await authRequest.get(`/auth/user`);
    if (status !== 200) {
      throw new HttpError(`status ${status}`);
    }

    return data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const getDealersDict = async (params) => {
  try {
    const url = `${DEALERS_URL}?${stringify(
      Helper.getParamsWithoutArgumentsWithBrackets(params)
    )}${Helper.stringifyPartWithBrackets(params)}`;
    const { status, data } = await appRequest.get(url, {
      headers: {
        'content-type': 'application/ld+json',
        'accept': 'application/ld+json',
      },
    });
    if (status !== 200) {
      throw new HttpError(`status ${status}`);
    }

    return data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const getDealerUsersDict = async ({ params, dealerId }) => {
  try {
    if (dealerId !== 0 && !dealerId) {
      throw new Error('missed params dealerId');
    }
    const url = `${DEALERS_URL}/${dealerId}/users?${stringify(params)}`;
    const { status, data } = await appRequest.get(url);
    if (status !== 200) {
      throw new HttpError(`status ${status}`);
    }

    return data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const getDealersParams = async (dealerId) => {
  try {
    const url = `${DEALERS_URL}/${dealerId}/options`;
    const { status, data } = await appRequest.get(url).catch(throwCustomError);

    if (status !== 200) {
      throw new HttpError(`status ${status}`);
    }

    return data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const getDealer = async (dealerId) => {
  try {
    const url = `${DEALERS_URL}/${dealerId}`;
    const { status, data } = await appRequest.get(url).catch(throwCustomError);

    if (status !== 200) {
      throw new HttpError(`status ${status}`);
    }

    return data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const postRefresh = async (refreshToken) => {
  const url = `${authUrl}/auth/refresh`;
  try {
    const { status, data } = await axios.post(
      url,
      { refresh_token: refreshToken },
      { headers: { 'accept': 'application/json', 'content-type': 'application/json' } }
    );

    if (status !== 200 || !data.token) {
      return null;
    }

    const token = {
      token: data.token,
      refresh: data.refresh_token,
    };

    clearAuthCookie();
    setAuthCookie(token.token, token.refresh);
    setServerCookies(data?.cookies);
    localStorage.saveItem('token', token);
    return token.token;
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const checkRefreshToken = async (state) => {
  state.isRefreshing = true;
  const stored = localStorage.getItem('token');
  if (stored && stored.refresh) {
    const res = await postRefresh(stored.refresh);
    if (res) {
      return res;
    }
  }
  handler401();
  return null;
};

export const logout = () => {
  try {
    authRequest.get('/auth/logout');
    handler401();
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const checkUserSessionAlive = async () => {
  try {
    const url = `${process.env.REACT_APP_API_ENTRYPOINT}/auth/alive`;

    const data = await appRequest.get(url, {
      headers: {
        'accept': 'application/json',
        'content-type': 'application/json',
      },
      withCredentials: true,
    });
    return data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const saveDealerId = async (dealerId) => {
  try {
    const url = `api/user/set-state`;

    const { status, data } = await authRequest.post(url, {
      dealerId,
    });

    if (status !== 200) {
      throw new HttpError(`status ${status}`);
    }

    return data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const saveRoleId = async (roleId) => {
  try {
    const url = `api/user/set-state`;

    const { status, data } = await authRequest.post(url, {
      roleId,
    });

    if (status !== 200) {
      throw new HttpError(`status ${status}`);
    }

    return data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};
