import styled from 'styled-components';
import InputFio from 'components/primitives/input-fio';

export const DaDataInput = styled(InputFio)`
  height: 56px;
  border-radius: 5px;
  .select_prompt {
    top: 58px;
  }
  .anketa_select_value {
    padding-top: 30px !important;
  }
  .anketa_dadata_input_mask {
    height: 56px;
    border-radius: 5px;
    .anketa_select_value {
      padding-top: 34px !important;
    }
  }
`;
