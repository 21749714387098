import { deleteGaps } from 'utils/convertPrice';

const convertNdsAmount = (amount) => {
  if (!amount) {
    return null;
  }
  return Number(deleteGaps(String(amount)));
};

export const getRecalculationParams = (data, requiredFields = []) => {
  const invoiceNumberRequired = requiredFields.includes('invoiceNumber');
  const invoiceDateRequired = requiredFields.includes('invoiceDate');
  const ndsAmountRequired = requiredFields.includes('ndsAmount');
  const ret = {
    services: [],
    salesParams: {
      email: '',
      contractNumber: String(data?.credit?.contractNumber || ''),
      contractDate: String(data?.credit?.contractDate || '') || null,
      invoiceNumber: (invoiceNumberRequired && String(data?.credit?.invoiceNumber || '')) || null,
      invoiceDate: (invoiceDateRequired && String(data?.credit?.invoiceDate || '')) || null,
      recipientId: data?.credit?.recipient?.id ? String(data?.credit?.recipient?.id) : null,
      ndsFlag: Number(data?.credit?.recipient?.ndsFlag) === 0 ? 0 : 1,
      ndsAmount:
        ndsAmountRequired && Number(data?.credit?.recipient?.ndsFlag)
          ? convertNdsAmount(data?.credit?.recipient?.ndsAmount)
          : null,
    },
  };

  if (data?.dealSigningDate) {
    ret.salesParams.dealSigningDate = data.dealSigningDate;
  }

  (data?.services).forEach((s) => {
    let ndsFlag = Number(s?.recipient?.ndsFlag) === 1 ? 1 : 0;
    let ndsAmount = ndsFlag ? convertNdsAmount(s?.recipient?.ndsAmount) : null;
    let recipientId = s?.recipient?.id ? String(s?.recipient?.id) : null;
    let policyNumber = String(s?.polisNumber || '') || null;
    let invoiceNumber = (invoiceNumberRequired && String(s?.invoiceNumber || '')) || null;
    let invoiceDate = (invoiceDateRequired && String(s?.invoiceDate || '')) || null;
    if (!s.bankProductFlag) {
      if (!s.inCredit) {
        ndsFlag = 0;
        ndsAmount = null;
        recipientId = null;
        policyNumber = s?.type?.name === 'insurance_kasko' ? policyNumber : null;
        invoiceNumber = null;
        invoiceDate = null;
      }
      if (!ndsAmountRequired || !ndsFlag) {
        ndsAmount = null;
      }
    }
    ret.services.push({
      serviceId: Number(s?.serviceId || 0) || null,
      type: String(s?.type?.name || ''),
      policyNumber,
      expiration: s?.expiration ? String(s?.expiration) : null,
      recipientId,
      ndsFlag,
      ndsAmount,
      term: Number(s?.term) || null,
      invoiceNumber,
      invoiceDate,
      internal: s.internal || false,
    });
  });

  return ret;
};
