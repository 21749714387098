import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { InputMask } from 'utils/inputMask';
import { CheckSvg, AlertSvg } from 'assets/img';
import {
  FormGridRow,
  SeriaNumber,
  PassportDate,
  IssuerCode,
  Issuer,
  BirthdayPlace,
  Birthday,
  Gender,
  FormSubheaderPassportMVD,
  FormHeader,
} from './style';
import { AnketaChevronStyled, ReactTooltipStyled, AreaInfo } from '../../../style';
import { isDocumentExists, isPasportConfidenceCorrect } from 'utils/dataMappers/mappers';
import { MESS_SERNUMBER_MVD } from 'utils/validationUtils/messages';
import * as help from '../helpers';

export const PassportForm = ({
  onChange,
  errors,
  persona,
  validate,
  anketaSave,
  setFocusAt,
  confidenceErros,
  clearConfidenceErros,
  contentHidden = false,
  disabled = false,
}) => {
  const keyComp = 'passport';
  const keyCompPersonal = 'personalInfo';
  const [values, setValues] = useState(null);
  const [valuesPersonal, setValuesPersonal] = useState(null);
  const [error, setError] = useState({});
  const { genderList } = useSelector((state) => state.referenceBooks);
  const { documents } = useSelector((state) => state.anketa);
  const [showMore, setShowMore] = useState(false);

  const changeHandler = (val) => {
    let stateVal = { ...values };
    if (Array.isArray(val)) {
      val.forEach((value) => {
        stateVal = { ...value, ...stateVal };
      });
    } else {
      stateVal = { ...values, ...val };
    }
    setValues(stateVal);
    onChange({ values: stateVal, id: persona.id, key: keyComp });
  };

  const changeHandlerPersonal = (val, withSave = false) => {
    let stateVal = { ...valuesPersonal };
    if (Array.isArray(val)) {
      val.forEach((value) => {
        stateVal = { ...value, ...stateVal };
      });
    } else {
      stateVal = { ...valuesPersonal, ...val };
    }
    setValuesPersonal(stateVal);
    onChange({ values: stateVal, id: persona.id, key: keyCompPersonal });

    if (withSave) anketaSave();
  };

  const validation = (path) => {
    setError({ ...error, [path]: errors[path] || null });
    clearConfidenceErros(path);
    anketaSave();
  };

  const getMvdError = () => {
    return errors['passport.serianumber'] === MESS_SERNUMBER_MVD ? MESS_SERNUMBER_MVD : false;
  };

  const getError = (path) => {
    if (validate || getMvdError()) {
      return errors[path] || error[path];
    }
    return error[path];
  };

  useEffect(() => {
    setError((prev) => ({ ...prev, ...confidenceErros }));
  }, [confidenceErros]);

  useEffect(() => {
    if (persona && values !== persona[keyComp]) {
      setValues(persona[keyComp]);
    }
    if (persona && valuesPersonal !== persona[keyCompPersonal]) {
      setValuesPersonal(persona[keyCompPersonal]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [persona]);

  const isPassportError = () => {
    if (!errors) return false;
    const errs = Object.keys(errors).map((f) => f.replaceAll(/\[\d\]/gu, ''));
    return (
      (validate &&
        errs.some(
          (ef) => ef.includes('passport.issueDate') || ef.includes('passport.serianumber')
        )) ||
      errors['passport.serianumber'] === MESS_SERNUMBER_MVD
    );
  };

  useEffect(() => {
    if (documents && isDocumentExists(documents) && values && errors) {
      if (isPassportError()) {
        setShowMore(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  useEffect(() => {
    const passCorrect = isPasportConfidenceCorrect(documents);
    if (passCorrect !== null) {
      setShowMore(!passCorrect);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documents]);

  useEffect(() => {
    if (!validate) {
      setError(
        Object.keys(error).reduce(
          (obj, key) => ({ ...obj, [key]: errors[key] || confidenceErros[key] || null }),
          {}
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, validate]);

  useEffect(() => {
    if (showMore && contentHidden) {
      setShowMore(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showMore, contentHidden]);

  return (
    <>
      {values && (
        <FormGridRow id={keyComp}>
          <FormHeader
            onClick={() => !contentHidden && setShowMore(!showMore)}
            collapsed={contentHidden || !showMore}
          >
            <AnketaChevronStyled className="chevron" />
            <h3>Паспорт</h3>
            {!disabled && (
              <>
                {isPasportConfidenceCorrect(documents) &&
                !(errors && Object.keys(errors).some((e) => e.includes('passport'))) ? (
                  <>
                    <AreaInfo>
                      <CheckSvg data-tip="" data-for="passportCheck" className="check" />
                      <ReactTooltipStyled id="passportCheck" place="right">
                        <span>проверен</span>
                      </ReactTooltipStyled>
                    </AreaInfo>
                  </>
                ) : (
                  <>
                    {!isDocumentExists(documents) && (
                      <AreaInfo>
                        <AlertSvg data-tip="" data-for="passportAlert" className="alert" />
                        <ReactTooltipStyled id="passportAlert" place="right">
                          <span>Загрузите документ</span>
                        </ReactTooltipStyled>
                      </AreaInfo>
                    )}
                  </>
                )}
              </>
            )}
          </FormHeader>
          {!contentHidden && showMore && (
            <>
              <SeriaNumber
                id={`${keyComp}_serianumber`}
                tabIndex="12"
                required
                label="Серия, номер"
                defaultValue={values.serianumber}
                val={values.serianumber}
                mask={InputMask.PASSPORT_SERIA_NUMBER}
                unmask
                onChange={(val) => {
                  changeHandler({
                    serianumber: val,
                    series: val.slice(0, 4) || '',
                    number: val.slice(4, val.length) || '',
                  });
                }}
                onComplete={() => {
                  setFocusAt(`${keyComp}_issueDate_input`);
                }}
                onBlur={() => validation(`${keyComp}.serianumber`)}
                error={getError(`${keyComp}.serianumber`)}
                disabled={disabled}
              />
              <PassportDate
                id={`${keyComp}_issueDate`}
                tabIndex="13"
                name="issueDate"
                required
                label="Дата выдачи"
                defaultValue={values.issueDate}
                val={values.issueDate}
                mask={InputMask.DATE_BEFORE_TODAY_NEW}
                onChange={({ issueDate }) => changeHandler({ issueDate })}
                onComplete={() => {
                  setFocusAt(`${keyComp}_issuerCode_input`);
                }}
                onBlur={() => validation(`${keyComp}.issueDate`)}
                error={getError(`${keyComp}.issueDate`) || getMvdError()}
                disabled={disabled}
              />
              <IssuerCode
                id={`${keyComp}_issuerCode`}
                mask={InputMask.PASSPORT_DEPARTMENT_CODE2}
                unmask
                tabIndex="14"
                required
                label="Код подразделения"
                type="fms_unit"
                daDataClosed={true}
                defaultValue={values.issuerCode.replace('-', '')}
                onChange={(obj) => {
                  if (obj.data) {
                    changeHandler({
                      issuerCode: obj.data && obj.data.code ? obj.data.code : '',
                      issuer: obj.data && obj.data.name ? obj.data.name : values.issuer,
                    });
                    setFocusAt(`${keyComp}_birthPlace_input`);
                  } else {
                    changeHandler({ issuerCode: help.convertIssuerCode(obj.value) });
                  }
                }}
                onBlur={() => validation(`${keyComp}.issuerCode`)}
                error={getError(`${keyComp}.issuerCode`) || getMvdError()}
                disabled={disabled}
              />
              <Issuer
                id={`${keyComp}_issuer`}
                mask={InputMask.ALL_RUS}
                tabIndex="15"
                required
                type="fms_unit"
                label="Кем выдано"
                daDataClosed={true}
                defaultValue={values.issuer}
                onChange={(obj) => {
                  if (obj.data) {
                    changeHandler({
                      issuerCode: (obj.data && obj.data.code) || values.issuerCode,
                      issuer: obj.data && obj.data.name ? obj.data.name : obj.value,
                    });
                  } else {
                    changeHandler({ issuer: obj.value.toUpperCase().trim() });
                  }
                }}
                onBlur={() => validation(`${keyComp}.issuer`)}
                error={getError(`${keyComp}.issuer`) || getMvdError()}
                disabled={disabled}
              />
              <BirthdayPlace
                id={`${keyComp}_birthPlace`}
                mask={InputMask.ALL_RUS_BIRTH_PLACE}
                tabIndex="16"
                required
                name="birthPlace"
                label="Место рождения"
                defaultValue={values.birthPlace}
                val={values.birthPlace}
                onChange={(val) => {
                  changeHandler(help.convertBirthPlaceVal(val));
                }}
                onBlur={() => validation(`${keyComp}.birthPlace`)}
                error={getError(`${keyComp}.birthPlace`) || getMvdError()}
                disabled={disabled}
              />
              <Birthday
                id={`${keyCompPersonal}_birthDate`}
                tabIndex="17"
                required
                name="birthDate"
                label="Дата рождения"
                defaultValue={valuesPersonal.birthDate}
                val={valuesPersonal.birthDate}
                mask={InputMask.DATE_BEFORE_TODAY_CUSTOMER}
                unmask
                onChange={({ birthDate }) => {
                  const date = birthDate.length === 2 ? '' : birthDate;
                  changeHandlerPersonal({ birthDate: date });
                }}
                onBlur={() => validation(`${keyCompPersonal}.birthDate`)}
                error={getError(`${keyCompPersonal}.birthDate`) || getMvdError()}
                disabled={disabled}
              />
              <Gender
                name="gender"
                items={genderList}
                checked={valuesPersonal.gender ? valuesPersonal.gender.id : null}
                onClick={({ gender }) =>
                  changeHandlerPersonal(
                    {
                      gender: genderList.find((g) => g.id === gender),
                    },
                    true
                  )
                }
                disabled={disabled}
              />
            </>
          )}

          {!contentHidden &&
            (errors['passport.serianumber'] !== MESS_SERNUMBER_MVD ? null : (
              <FormSubheaderPassportMVD>
                <AlertSvg />
                <span>Проверьте правильность паспортных данных. Такого паспорта не существует</span>
              </FormSubheaderPassportMVD>
            ))}
        </FormGridRow>
      )}
    </>
  );
};

export default React.memo(
  PassportForm,
  (prevProps, nextProps) => prevProps.persona.id === nextProps.persona.id
);
