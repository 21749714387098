import React from 'react';
import { useSelector } from 'react-redux';
import Widget from 'components/primitives/widget';
import { UnknownCarPng } from 'assets/img';
import { Content, Image, Top } from './style';

export const CarWidget = ({ className = '' }) => {
  const { carInstance } = useSelector((state) => state.carInstance);

  const { brand, model, year, offerPrice, image, isNew } = carInstance;

  const carName = brand && model ? `${brand} ${model}` : '';
  const carImage = image || UnknownCarPng;
  const isNewString = isNew ? 'Новый автомобиль' : 'Автомобиль с пробегом';

  return (
    <Widget className={className}>
      <Top>
        <b>{carName}</b>
      </Top>
      <Content>
        <p>{year ? `${year}г. ${isNewString}` : ''}</p>
        <p>{(offerPrice || '').toLocaleString()} &#8381;</p>
      </Content>
      <Image src={carImage} />
    </Widget>
  );
};
