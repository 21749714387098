import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  faPlusCircle,
  faClipboardList,
  // faCog,
  faChartBar,
  faUserAstronaut,
  faSignOutAlt,
  faBullhorn,
  faBook,
} from '@fortawesome/free-solid-svg-icons';
import NavButton from './NavButton';
import { EPoints } from '../../../assets/img';
import * as ePointsStore from 'store/ePoints';
import * as metrica from 'metrika/mainpage';
import { providerRoles } from 'constants/index';
import { getDealer } from 'store/user/selectors';

const dumb = () => {};

const Nav = ({ userRole = 0, onClick = dumb, currFid, onLogout = () => {} }) => {
  const ePoints = useSelector((store) => store.ePoints);
  const store = useSelector((store) => store);
  const { id: userId, dealer: dealerId, dealers } = store.user;
  const rootDispatch = useDispatch();
  const history = useHistory();

  const [dealer, setDealer] = useState(null);

  useEffect(() => {
    if (dealerId && dealers.length !== 0) {
      const dealer = getDealer(store);

      setDealer(dealer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, dealerId, dealers]);

  const onHandleRoute = () => {
    if (dealer.allowedOnline) {
      history.push('/start');
    }

    metrica.toMainPageTrack();
  };

  return (
    <div className="sidebar-nav__redline">
      <div className="nav-group top">
        <NavButton
          classList={['logo']}
          childType="img"
          src="e.svg"
          onClick={onHandleRoute}
          hoverText="На Главную"
        />

        {/*(isList || isSearch) && <NavButton
          onClick={onClick}
          childType="icon"
          src={faSearch}
          classList={['search']}
        />*/}
        {[90, 91, 3197].includes(parseInt(userRole, 10)) && (
          <NavButton
            onClick={onClick}
            childType="icon"
            src={faPlusCircle}
            fid="new"
            active={currFid === 'new'}
            hoverText="Создать"
            dataTest="plusButton"
          />
        )}
        <NavButton
          onClick={onClick}
          childType="icon"
          src={faClipboardList}
          fid="orders"
          active={currFid === 'orders'}
          hoverText="Заявки"
        />
        {providerRoles.includes(parseInt(userRole, 10)) && (
          <NavButton
            onClick={onClick}
            childType="icon"
            src={faBook}
            fid="provider"
            active={currFid === 'provider'}
            hoverText="Реквизиты"
          />
        )}
        <div className="beamerTrigger" id="beamerButton">
          <NavButton childType="icon" src={faBullhorn} hoverText="Что нового?" />
        </div>
      </div>
      <div className="nav-group center">
        {/*[91, 3197, 3213, 3406].includes(parseInt(userRole, 10)) && (
          <NavButton
            childType="icon"
            src={faUniversity}
            onClick={onClick}
            fid="banks"
            active={currFid === 'banks'}
          />
        )*/}
        {ePoints?.programs?.length > 0 && (
          <NavButton
            classList={['ePoints']}
            childType="img"
            src={EPoints}
            fid="epoints"
            onMouseOver={() => {
              rootDispatch(ePointsStore.toggleEPointPopup(true));
            }}
            onMouseOut={() => {
              rootDispatch(ePointsStore.toggleEPointPopup(false));
            }}
          />
        )}
        {/*<NavButton
          childType="icon"
          src={faNewspaper}
          onClick={onClick}
          fid="news"
          active={currFid === 'news'}
        />
        <NavButton
          childType="icon"
          src={faArrowAltCircleRight}
          onClick={onClick}
          fid="oldecred"
          active={currFid === 'oldecred'}
          hoverText="Старая система"
        />*/}
      </div>
      <div className="nav-group bottom">
        {![90].includes(parseInt(userRole, 10)) && (
          <NavButton
            onClick={onClick}
            childType="icon"
            src={faChartBar}
            fid="reports"
            active={currFid === 'reports'}
            hoverText="Отчеты"
          />
        )}
        {/*![90, 91, 3370].includes(parseInt(props.userRole, 10)) &&<NavButton
              onClick={onClick}
              childType='icon'
              src={faShoppingBasket}
              fid='market'
              active={currFid === 'market'}
            />*/}
        {/* {[3197, 93, 3213, 3406].includes(parseInt(userRole, 10)) && (
          <NavButton
            onClick={onClick}
            childType="icon"
            src={faCog}
            fid="settings"
            active={currFid === 'settings'}
            hoverText="Настройки"
          />
        )} */}
        <NavButton
          onClick={onClick}
          classList={['nav-button--increased-height']}
          childType="icon"
          src={faUserAstronaut}
          fid="user"
          active={currFid === 'user'}
          hoverText="Пользователь"
        />
        <NavButton
          onClick={onLogout}
          name="exit"
          childType="icon"
          src={faSignOutAlt}
          fid="exit"
          active={currFid === 'exit'}
          hoverText="Выйти"
        />
      </div>
    </div>
  );
};

export default Nav;
