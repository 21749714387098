import {
  convertDate,
  convertOptionToSysName,
  convertSysNameToSelectOption,
} from 'utils/dataMappers/mappers';
import { abstractAddressFromAPIToState, abstractAddressFromStateToAPI } from './abstractAddress';

export const propertyInitialState = {
  cars: {},
  realestate: {},
};

export const propertyFromAPIToState = (
  customer,
  { carAcquisitionTypeList, realtyObtainingList, realtyTypeList }
) => {
  const { cars, estates } = customer.property;
  return {
    cars: cars.reduce(
      (obj, c) => ({
        ...obj,
        [c.id]: {
          id: c.id,
          carNumber: c.plateNumber,
          brand: c.brandAndModel,
          model: c.model,
          year: c.year,
          vin: c.vin,
          method: convertSysNameToSelectOption(c.acquisitionTypeSysName, carAcquisitionTypeList),
          isPleged: c.isPleged,
        },
      }),
      {}
    ),
    realestate: estates.reduce(
      (obj, e) => ({
        ...obj,
        [e.id]: {
          id: e.id,
          address: abstractAddressFromAPIToState(e.address),
          area: e.area,
          isSharedOwnership: true,
          realtyType: convertSysNameToSelectOption(e.realtyTypeSysName, realtyTypeList),
          purchaseDate: convertDate(e.purchaseDate),
          purchaseMethod: convertSysNameToSelectOption(
            e.purchaseMethodSysName,
            realtyObtainingList
          ),
          sharePercent: e.sharePercent,
          isPleged: e.isMortgaged,
        },
      }),
      {}
    ),
  };
};

export const propertyFromStateToAPI = (person) => {
  const { cars, realestate } = person;
  return {
    property: {
      cars: Object.keys(cars).map((id) => {
        const c = cars[id];
        return {
          id: c.id || null,
          plateNumber: c.carNumber || '',
          brandAndModel: c.brand || '',
          model: c.model || '',
          year: (c.year && c.year.toString()) || '',
          vin: c.vin || '',
          acquisitionTypeSysName: convertOptionToSysName(c.method),
          isPleged: c.isPleged || false,
        };
      }),
      estates: Object.keys(realestate).map((id) => {
        const e = realestate[id];
        return {
          id: e.id || null,
          address: abstractAddressFromStateToAPI(e.address),
          area: parseInt(e.area) || 0,
          isSharedOwnership: e.isSharedOwnership,
          realtyTypeSysName: convertOptionToSysName(e.realtyType),
          purchaseDate: e.purchaseDate || '',
          purchaseMethodSysName: convertOptionToSysName(e.purchaseMethod),
          sharePercent: parseInt(e.sharePercent) || 0,
          isMortgaged: e.isPleged || false,
        };
      }),
    },
  };
};
