export const OPENED_STATES = ['new', 'new-credit-calculation'];
export const QUEUE_STATES = ['queue', 'queue-financed', 'queue-agreement', 'sent-to-the-bank'];
export const REQUESTED_STATES = [
  'request-info-underwriting',
  'request-info-deal',
  'request-info-financed',
];
export const DECLINE_STATES = [
  'customer-decline-before',
  'declined',
  'customer-decline-after',
  'archive',
];
export const APPROVED_STATES = [
  'request-credit-agreement',
  'client-invited-deal',
  'approved',
  'approved-with-if',
  'financed',
  'closed',
  'contract-issue',
  'request-for-loan-issue',
  'archive-financed', // сборный статус из lastState + reason
];

const statesColorMap = {
  white: OPENED_STATES,
  yellow: QUEUE_STATES,
  blue: REQUESTED_STATES,
  red: DECLINE_STATES,
  green: APPROVED_STATES,
};

export const statusColorMapReverse = () => {
  let reverseMap = {};
  for (let key in statesColorMap) {
    const inverse = statesColorMap[key].reduce((acc, curr) => {
      acc[curr] = key;
      return acc;
    }, {});
    reverseMap = { ...reverseMap, ...inverse };
  }
  return reverseMap;
};

// Roles with a preset dealer (we get one dealer from the back)
// Other roles include a filter for all dealers
export const ROLES_WITH_SPECIFIC_DEALER = [90, 91];

export const INITIAL_DEALER_ID = 101;

export const IN_WORK = 'in-work';
export const LOST = 'lost';
export const LIST = 'list';
export const SEARCH = 'search';
export const LastState = {
  'in-work': [
    '4',
    '5',
    '6',
    '7',
    '8',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
  ],
  'list': [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '24',
    '28',
    '29',
  ],
  'lost': ['7', '8'],
  'search': ['*'],
};

export const LastStateVisible = {
  'in-work': ['4', '5', '6', '7', '10', '11', '12', '13', '14', '15', '24'],
  'list': [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '9',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '24',
    '28',
    '29',
  ],
  'lost': [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '9',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
    '28',
    '29',
  ],
  'search': ['*'],
};

export const LastStateMutable = {
  'in-work': ['4', '5', '6', '7', '8', '10', '11', '12', '13', '14', '15', '24'],
  'list': [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '24',
    '28',
    '29',
  ],
  'lost': [''],
  'search': ['*'],
};

export const LastStateProducts = {
  'in-work': [''],
  'list': [''],
  'lost': [''],
  'search': [''],
};

export const DealerMap = {
  'in-work': ['*'],
  'list': ['current'],
  'lost': ['*'],
  'search': ['*'],
};

export const applicationsRolesMap = {
  'lost': [3213, 93, 3197, 3370],
  'list': [3406, 3213, 93, 91, 90, 3197, 3370],
  'in-work': [3406, 3213, 93, 91, 3197, 3370],
  'search': [3406, 3213, 93, 91, 90, 3197, 3370],
};

export const MERCURY_HEARTBEAT = 300000;

export const ORDER_LAMP = {
  white: 'Доступные для подачи банки',
  green: 'Одобрено',
  blue: 'Запрос банка',
  yellow: 'Ожидание',
  red: 'Отказ',
};

//статусы заявок, которые необходимо отображать в столбце кредит вместо кружков
export const APPLICATION_STATES_TO_DISPLAY_INSTEAD_OF_BALLS = {
  'client-invited-deal': 'green',
  'request-credit-agreement': 'green',
  'request-info-deal': '#0175C9',
  'contract-issue': 'green',
  'request-for-loan-issue': 'green',
  'financed': 'green',
  'closed': 'green',
  'archive': 'red',
  'archive-financed': 'green', // сборный статус из lastState + reason
  'queue-agreement': '#FFD700',
  'queue-financed': '#FFD700',
  'queue-agreement-error': '#FFD700',
  'queue-financed-error': '#FFD700',
  'request-info-financed': '#0175C9',
};

export const APPLICATION_SOURCE_UNDEFINED = 100;

export const APPLICATION_SOURCE = {
  0: 'Отдел кредитования',
  1: 'Кредит онлайн',
  2: 'Отдел продаж (наличные)',
  3: 'Отдел продаж',
  4: 'Встречный поток от банков',
  APPLICATION_SOURCE_UNDEFINED: 'Неопределено',
};

// статусы для заявки исключая отправки и прочее, чисто результат
export const keyApp = [
  'request-info-underwriting',
  'declined',
  'approved',
  'approved-with-if',
  'request-info-deal',
  'contract-issue',
  'financed',
  'request-info-financed',
];

export const newAnketaDealers = [527, 526];
export const newAnketaRoles = [91];
export const providerRoles = [91, 3197, 3213];

export const carMapper = {
  'LADA (ВАЗ)': 272,
};

export const DEFAULT_ERROR_MESSAGE = 'Попробуйте еще раз или обратитесь в службу поддержки';

export const GLOBAL_ANKETA_CALC_OPTIONS = ['Полный расчёт', 'Предварительный расчёт'];

export const MNEMONICS_HTML = {
  '&amp;': '&',
  '&AMP;': '&',
  '&lt;': '<',
  '&gt;': '>',
  '&quot;': '"',
  '&QUOT;': '"',
  '&#039;': "'",
};
