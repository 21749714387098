import React from 'react';

import { getBankLogo } from './helper';

export const BankLogo = ({ id, type, title = '', ...rest }) => {
  const bankLogo = getBankLogo(id, type);

  if (!bankLogo) {
    return null;
  }

  return <img src={bankLogo} alt="bank logo" {...rest} title={title} />;
};

export default BankLogo;
