import moment from 'moment';
import { pasportConfidenceFields } from 'utils/constants/documents';

export const defaultSelectOption = {
  id: 0,
  value: '',
  sysName: '',
};

export const convertSysNameToSelectOption = (sysName, referenceBook) =>
  referenceBook.find((o) => o.sysName === sysName) || defaultSelectOption;

export const convertOptionToSysName = (option) => option && option.sysName;

export const convertDate = (dateString) => {
  if (dateString === '-0001-11-30' || !dateString) {
    return '';
  } else {
    if (dateString.includes('.')) {
      return dateString;
    }

    const converted = moment(dateString, 'YYYY-MM-DD').format('DD.MM.YYYY');

    return converted === 'Invalid date' ? '' : converted;
  }
};

export const isPersonMerried = (person) =>
  person.personalInfo &&
  person.personalInfo.familyState &&
  person.personalInfo.familyState.id === 77;

export const isCustomerMerried = (customer) =>
  customer.personalInformation && customer.personalInformation.maritalStatusSysName === 'married';

export const isDocumentExists = (documents, docType = ['passport']) => {
  if (!documents) return false;
  for (let doc of documents) {
    if (doc.type && docType.includes(doc.type.sysName)) {
      return true;
    }
  }
  return false;
};

export const isDocumentClassExists = (
  documents,
  docType = ['passport_main', 'passport_main_handwritten']
) => {
  if (!documents) return false;
  for (let doc of documents) {
    if (doc.classification && docType.includes(doc.classification.type)) {
      return true;
    }
  }
  return false;
};

export const getPasportConfidenceErros = (documents) => {
  const result = {};

  for (let doc of documents) {
    if (
      doc?.type?.id !== 30 ||
      !['passport_main', 'passport_registration'].includes(doc?.classification?.type) ||
      !Object.keys(doc?.recognition?.confidence || {}).length
    ) {
      continue;
    }

    const docType = doc.classification.type;
    Object.keys(doc.recognition.confidence)
      .filter((val) => {
        if (!pasportConfidenceFields[docType] || !pasportConfidenceFields[docType][val]) {
          return false;
        }

        return doc.recognition.confidence[val] < pasportConfidenceFields[docType][val]?.confidence;
      })
      .forEach((val) => {
        if (!result[pasportConfidenceFields[docType][val].field]) {
          result[pasportConfidenceFields[docType][val].field] = 'Проверьте данное поле';
        }
      });
  }

  return result;
};

export const isPasportConfidenceCorrect = (documents) => {
  if (!documents) {
    return null;
  }

  return Object.keys(getPasportConfidenceErros(documents)).length === 0;
};
