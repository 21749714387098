import React, { useEffect, useRef, useCallback } from 'react';

import { useIntersectionObserver } from 'hooks/useIntersectionObserver';

export const InView = ({ data = null, onShow = () => {}, onHide = () => {} }) => {
  const ref = useRef();
  const observer = useIntersectionObserver(ref);

  const showHandler = useCallback(() => onShow(data), [onShow, data]);
  const hideHandler = useCallback(() => onHide(data), [onHide, data]);

  useEffect(() => {
    observer.mount(showHandler, hideHandler);
    return () => observer.unmount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div ref={ref} />;
};
