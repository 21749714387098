import React, { useState, useEffect, useRef } from 'react';
import cn from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { getUser, userLogout, getDealers } from 'store/user';
import NavFloatDataContext from './NavFloatDataContext.js';

import NavRedline from './NavRedline';
import NavDetail from './NavDetail';
import NavFloat from './NavFloat';
import {
  BanksLinks,
  ReportsLinks,
  OrdersLinks,
  SettingsLinks,
  UserLinks,
  CalcLinks,
  ProviderLinks,
} from './submenus';
import './style.scss';
import { logout } from 'data-providers/authProvider.js';

const Nav = (props) => {
  const {
    isNavFixed = false,
    // userLogout = () => {},
    userRole = '',
    history,
  } = props;
  // const bhistory = useHistory
  const rootDispatch = useDispatch();
  const { loanLoaded, applicationId } = useSelector((store) => store.startPage);
  const startPage = useSelector((store) => store.startPage);

  const [isDetailPanelActive, setActiveDetailPanel] = useState(false);
  const [isFloatPanelActive, setActiveFloatPanel] = useState(false);
  const [isBackdropActive, setActiveBackdrop] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [selected, setSelectedElement] = useState({ id: null, fid: null });
  const [submenu, setSubmenu] = useState(null);
  const [currFid, setCurrFid] = useState(null);
  const role = useSelector(({ user }) => user.role || -1);
  const { error: startPageError } = useSelector((state) => state.startPage);

  const refNavFloat = useRef();

  useEffect(() => {
    rootDispatch(getUser());
    // rootDispatch(getDealers());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (role !== -1) {
      rootDispatch(getDealers());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role]);

  useEffect(() => {
    if (!loanLoaded && applicationId) {
      history.push(`/worksheet/${applicationId}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loanLoaded, applicationId]);

  useEffect(() => {
    if (!startPage.ensuranceLoaded && startPage.customer?.id) {
      // history.push('/worksheet/create-new');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startPage]);

  useEffect(() => {
    allClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location.pathname, startPageError]);

  if (isNavFixed && !isFloatPanelActive && isBackdropActive) {
    setActiveBackdrop(false);
  }
  if (!isNavFixed && !isBackdropActive && (isFloatPanelActive || isDetailPanelActive)) {
    setActiveBackdrop(true);
  }

  const onClick = () => {
    setActiveFloatPanel(false);
    if (!isNavFixed) {
      setActiveDetailPanel(!isDetailPanelActive);
      setActiveBackdrop(!isBackdropActive);
      setCurrFid(null);
    }
  };

  const allClose = () => {
    setActiveFloatPanel(false);
    if (!isNavFixed) {
      setActiveDetailPanel(false);
      setActiveBackdrop(false);
      setCurrFid(null);
    }
  };

  const selectActiveElement = ({ fid }) => {
    setCurrFid(fid);
  };

  const closeFloat = ({ fid }) => {
    setCurrFid(fid);
    if (isNavFixed) {
      setActiveBackdrop(false);
    }
    setActiveFloatPanel(false);
  };

  const detailCloseFloat = (e) => {
    e.preventDefault();
    closeFloat({});
  };

  const clickExitHandler = () => {
    rootDispatch({ type: 'SET_SEARCH', payload: '' });
    rootDispatch(userLogout());
    logout();
    // userLogout();
  };

  const selectMenuItemHandler = () => {
    // TODO:
  };

  const handleChangeRoute = (link) => {
    if (link.indexOf('http') > -1) {
      window.location.href = link;
    }
    if (link !== history.location.pathname) {
      history.push(link);
    }
  };

  const openFloat = ({ fid }) => {
    setCurrFid(fid);
    changeSubmenu({ fid });

    if (isNavFixed) {
      setActiveBackdrop(true);
    }

    if (isDetailPanelActive) {
      setActiveFloatPanel(true);
    }
  };

  const changeSubmenu = ({ fid }) => {
    switch (fid) {
      case 'reports':
        setSubmenu(<ReportsLinks {...props} currFid={currFid} onSelect={selectMenuItemHandler} />);
        break;
      case 'banks':
        setSubmenu(<BanksLinks currFid={currFid} onSelect={selectMenuItemHandler} />);
        break;
      case 'orders':
        setSubmenu(
          <OrdersLinks userRole={userRole} currFid={currFid} onSelect={selectMenuItemHandler} />
        );
        break;
      case 'settings':
        setSubmenu(<SettingsLinks currFid={currFid} onSelect={selectMenuItemHandler} />);
        break;
      case 'user':
        setSubmenu(<UserLinks currFid={currFid} onSelect={selectMenuItemHandler} />);
        break;
      case 'new':
        setSubmenu(
          <CalcLinks
            currFid={currFid}
            onSelect={selectMenuItemHandler}
            onClick={handleChangeRoute}
          />
        );
        break;
      case 'provider':
        setSubmenu(
          <ProviderLinks userRole={userRole} currFid={currFid} onSelect={selectMenuItemHandler} />
        );
        break;
      default:
        setSubmenu(null);
        break;
    }
  };

  return (
    <div className="sidebar-nav">
      <div className="sidebar-nav__container">
        <div
          onClick={allClose}
          className={cn('sidebar-nav__backdrop', { active: isBackdropActive })}
        />
        <NavFloatDataContext.Provider value={{ active: isFloatPanelActive, refNavFloat }}>
          <NavFloat
            active={isFloatPanelActive}
            closeFloat={closeFloat}
            submenu={submenu}
            ref={refNavFloat}
          />
        </NavFloatDataContext.Provider>
        <NavDetail
          closeFloat={closeFloat}
          openFloat={openFloat}
          onClick={detailCloseFloat}
          active={isDetailPanelActive}
          onItemClick={selectActiveElement}
          onLogout={clickExitHandler}
          currFid={currFid}
          allClose={allClose}
        />
        <NavRedline
          onClick={onClick}
          onLogout={clickExitHandler}
          closeFloat={closeFloat}
          currFid={currFid}
          userRole={role}
        />
      </div>
    </div>
  );
};

export default Nav;
