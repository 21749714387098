import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import {
  insAnketaChangeByKey,
  insAnketaDeleteError,
  insAnketaSwitchOwnerDriverCheckbox,
  insAnketaClearEmptyDrivers,
  insAnketaSwitchOwnerInsurerCheckbox,
} from 'store/insuranceAnketa';
import { assistanceChangeByKey } from 'store/assistanceAnketa';
import Owner from './owner';
import { DRIVERS_OVERFLOW_ERR_MESSAGE, MAX_DRIVERS } from '../../constants';
import { onBlurBlockHelper } from '../../helpers/blockHelpers';

const fn = () => {};

const OwnerWrapper = ({
  anketaState,
  onScroll = fn,
  validate = fn,
  clearDriverLicense = fn,
  returnSavedDriverLicense = fn,
}) => {
  const rootDispatch = useDispatch();
  const { addToast, toastStack } = useToasts();

  const { isDriver, kaskoInsurerIsDriver, drivers, scrollTo, currentSavedData } = anketaState;
  const { currentMultidrive } = useSelector((store) => store.insuranceCalculation);
  const allDriversLimit = MAX_DRIVERS - (Number(isDriver) || Number(kaskoInsurerIsDriver));

  const createToast = () => {
    if (!toastStack.length) {
      addToast(DRIVERS_OVERFLOW_ERR_MESSAGE, { appearance: 'success', autoDismiss: false });
    }
  };

  const blurHandler = async ({ value, state }) => {
    const key = Object.keys(value)[0];

    if (key === 'gender') {
      return rootDispatch(insAnketaChangeByKey({ key: 'gender', value: value.gender }));
    }

    const { values, errors } = await onBlurBlockHelper({ value, state });

    if (!values && !errors) {
      return;
    }

    rootDispatch(insAnketaChangeByKey(values));
    if (['fio', 'bday', 'insurerPhone'].includes(values.key)) {
      rootDispatch(assistanceChangeByKey(values));
    }

    validate({ value, state }, true);
  };

  const ownerInsurerSwitchHandler = () => {
    rootDispatch(insAnketaSwitchOwnerInsurerCheckbox());
  };

  const ownerDriverSwitcherHandler = () => {
    rootDispatch(insAnketaClearEmptyDrivers());

    if (!isDriver && drivers.length >= allDriversLimit) {
      return createToast();
    }

    if (isDriver) {
      returnSavedDriverLicense();
    }

    rootDispatch(insAnketaSwitchOwnerDriverCheckbox());
  };

  const switchDriverLicenseHandler = ({ isFirstLicense }) => {
    const keys = ['prevLicenseSeriaNum', 'prevLicenseDate'];

    if (isFirstLicense) {
      clearDriverLicense();
    } else {
      keys.forEach((key) => {
        if (currentSavedData) {
          rootDispatch(insAnketaChangeByKey({ key, value: currentSavedData[key] }));
        }
        rootDispatch(insAnketaDeleteError({ key }));
      });
    }

    rootDispatch(insAnketaChangeByKey({ key: 'isFirstLicense', value: isFirstLicense }));
  };

  return (
    <Owner
      anketaState={anketaState}
      multidrive={currentMultidrive}
      scrollTo={scrollTo}
      onScroll={onScroll}
      onSwitchDriverLicense={switchDriverLicenseHandler}
      onSwitchOwnerDriver={ownerDriverSwitcherHandler}
      onSwitchOwnerInsurer={ownerInsurerSwitchHandler}
      onBlur={blurHandler}
    />
  );
};

export default OwnerWrapper;
