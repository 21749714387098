import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Loader } from 'components/primitives/loader';
import DealItem from './DealItem/DealItem';
import { EmptyState } from 'components/modules/ApplicationNotifications/components/EmptyState';
import { EmptyFilter } from './DealItem/EmptyFilter';
import SearchItem from './SearchItem/SearchItem';

import { InputMask, unmaskValue } from 'utils/inputMask';
import { convertCoinsToString } from 'utils/convertPrice';
import { getCarVinInfo } from 'data-providers/carInstanceProvider';
import { schemaVin } from 'components/modules/Calculations/components/CarDetail/validator/validations';
import { setVinInfoFromMercury } from 'store/mainpage';
import { initialState } from 'store/mainpage/initialState';
import * as metrica from 'metrika/mainpage';

import { CloseCrossSvg } from 'assets/img';
import CarPreloaderGif from 'assets/img/car-preloader.gif';
import { PALETTE } from 'styles/constants';
import * as S from './style';

const popupBlocks = {
  block0: false,
  block1: false,
  block2: false,
};

export default function VinCheckInput({ title, buttonText }) {
  const rootDispatch = useDispatch();

  const { id: userId, dealer: dealerId, dealers } = useSelector((store) => store.user);
  const { vinInfo } = useSelector((store) => store.mainPage);
  const isLoading = useSelector((state) => state.notifications.fetch);
  const list = useSelector((store) => store.notifications.appsMainPage);

  const [value, setValue] = useState('');
  const [vinError, setVinError] = useState('');
  const [vinFetchError, setVinFetchError] = useState(false);
  const [vinCheckPopup, setVinCheckPopup] = useState(false);
  const [isLoadingVin, setIsLoadingVin] = useState(false);
  const [restrictions, setRestrictions] = useState([]);
  const [searchHistoryPopup, setSearchHistoryPopup] = useState(false);
  const [sortedList, setSortedList] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [dealer, setDealer] = useState(null);
  const [blocks, setBlocks] = useState({
    ...popupBlocks,
  });
  const [searchHistory, setSearchHistory] = useState(
    JSON.parse(localStorage.getItem('vinFetchHistory')) || []
  );
  const [disabledVin, setDisabledVin] = useState(false);

  const filteredList = sortedList.filter((data) =>
    data.customer.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const now = new Date();
  const lastDay = new Date(now);

  useEffect(() => {
    lastDay.setDate(lastDay.getDate() - 1);
    const filteredList = Object.values(list).filter((item) => new Date(item.date) > lastDay);
    const sortedList = filteredList.sort(
      (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
    );

    setSortedList(sortedList);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);

  useEffect(() => {
    if (userId && vinInfo.data?.carScore?.vin && vinInfo.data?.carScore?.isCompleted) {
      setVinCheckPopup(true);
      setOpened('block0', 'block1');
      setRestrictions(
        [
          vinInfo.data.carScore.pledges,
          vinInfo.data.carScore.taxi,
          vinInfo.data.carScore.carsharing,
          vinInfo.data.carScore.restrictions,
          vinInfo.data.carScore.seriousDamage,
        ].filter((item) => item)
      );

      setIsLoadingVin(false);
    }

    if (userId && vinInfo.data?.errorInfo) {
      // Пока на бэке есть обработка только одной ошибки, все остальные могут приходить в рандомном виде, вплоть до синтаксических ошибок на php, решение временное.
      const errMessage =
        'К сожалению, данный VIN-номер не найден. Пожалуйста, проверьте корректность введенных данных.';

      vinInfo.data.errorInfo === errMessage
        ? setVinError(vinInfo.data.errorInfo)
        : setVinError('Ошибка запроса, попробуйте еще раз');

      setVinFetchError(true);
      rootDispatch(setVinInfoFromMercury(initialState.vinInfo));
      setIsLoadingVin(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vinInfo.data?.carScore?.isCompleted, vinInfo.data?.errorInfo]);

  useEffect(() => {
    if (dealerId && dealers.length !== 0) {
      const dealer = dealers.find((dealer) => dealer.id === dealerId);

      setDealer(dealer);
    }
  }, [userId, dealerId, dealers]);

  const validateVin = async (userId, vinToValidate) => {
    try {
      await schemaVin.validate({ vin: vinToValidate });
      if (userId && vinToValidate) {
        await getCarVinInfo(userId, vinToValidate);
        setIsLoadingVin(true);
      }

      let searchHistoryArray = JSON.parse(localStorage.getItem('vinFetchHistory')) || [];

      const now = new Date();
      const formatedDate = now.toLocaleString('ru-RU');

      searchHistoryArray = searchHistoryArray.filter((item) => {
        const dayPeriod = 7;
        const itemDate = new Date(item.date);
        const nowDate = new Date();
        const timeDiff = Math.abs(itemDate - nowDate);
        const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

        return daysDiff <= dayPeriod;
      });

      searchHistoryArray.push({ vin: vinToValidate, formatedDate, date: now });

      localStorage.setItem('vinFetchHistory', JSON.stringify(searchHistoryArray));

      setSearchHistory(searchHistoryArray);

      setVinError('');
      setVinFetchError(false);
    } catch (err) {
      setVinError(err.message);
    }
  };

  const clickHandler = (e) => {
    e.stopPropagation();
  };

  const handleCheckButtonClick = (userId, vin) => {
    if (dealer.allowedOnline) {
      validateVin(userId, vin);
    } else {
      setDisabledVin(true);
      setValue('');
      setVinError('');
    }

    if (buttonText === 'Проверить') {
      metrica.checkVinButtonTrack();
    } else {
      metrica.confirmVinButtonTrack();
    }
  };

  const keyPressHandler = (event) => {
    if (event.key === 'Enter') {
      handleCheckButtonClick(userId, value);
    }
  };

  const closeHandler = () => {
    rootDispatch(setVinInfoFromMercury(initialState.vinInfo));

    setValue('');
    setVinError('');
    setVinFetchError(false);
    setVinCheckPopup(false);

    setBlocks({
      ...popupBlocks,
    });
  };

  const changeHandler = (event) => {
    const val = unmaskValue(event.vin);

    const sanitizedValue = val.toUpperCase().replace(/[^A-Z0-9]/g, '');

    if (sanitizedValue.length <= 17) {
      setValue(sanitizedValue);
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const setOpened = (nameOne, nameTwo) => {
    setBlocks({
      ...popupBlocks,
      [nameOne]: false,
      [nameTwo]: true,
    });
  };

  return (
    <S.Wrap>
      <S.Title>{title}</S.Title>
      <S.Lable>VIN номер автомобиля:</S.Lable>
      <S.InputWrap>
        <S.Input
          name="vin"
          placeholder={'1XXXX111111111111'}
          val={value}
          mask={InputMask.VIN_NEW}
          onClick={clickHandler}
          onChange={changeHandler}
          onKeyPress={keyPressHandler}
          error={vinError}
        />
        <S.CloseButton onClick={closeHandler}>
          <CloseCrossSvg />
        </S.CloseButton>
        <S.CheckButton
          onClick={() => {
            handleCheckButtonClick(userId, value);
          }}
        >
          {buttonText}
        </S.CheckButton>
      </S.InputWrap>

      <S.SearchHistory
        onClick={() => {
          metrica.searchHistoryButtonTrack();
          setSearchHistoryPopup(true);
        }}
        hasFetchError={vinFetchError}
        hasValidateError={vinError}
      >
        <u>История поиска</u>
      </S.SearchHistory>

      {searchHistoryPopup && (
        <S.PopupStyled
          onClose={() => {
            setSearchHistoryPopup(false);
          }}
          contentStyle={{ borderRadius: '20px', backgroundColor: PALETTE.white }}
        >
          <S.SearchWrapper>
            <S.SearchContainer>
              {searchHistory.map((item, index) => (
                <SearchItem key={`${item.userId}_${index}`} data={item} />
              ))}
            </S.SearchContainer>
          </S.SearchWrapper>
        </S.PopupStyled>
      )}

      {vinCheckPopup && (
        <S.PopupStyled
          onClose={closeHandler}
          contentStyle={{ borderRadius: '20px', backgroundColor: PALETTE.white }}
        >
          <S.PopupChildren>
            <S.VinTitle>VIN номер автомобиля:</S.VinTitle>
            <S.VinNumber>{vinInfo.data.carScore.vin}</S.VinNumber>
            {blocks.block1 && (
              <>
                <S.VinInfoWrapper>
                  <S.VinLineWrapper>
                    <S.LeftInfo>Максимальная стоимость</S.LeftInfo>
                    <S.RightInfo>
                      {convertCoinsToString(vinInfo.data.carScore.maxPrice, false)}
                    </S.RightInfo>
                  </S.VinLineWrapper>
                  <S.VinLineWrapper>
                    <S.LeftInfo>Рекомендованная цена</S.LeftInfo>
                    <S.RightInfo>
                      {convertCoinsToString(vinInfo.data.carScore.avgMarketPrice, false)}
                    </S.RightInfo>
                  </S.VinLineWrapper>
                  <S.VinLineWrapper>
                    {vinInfo.data.carScore.pledges ? (
                      <>
                        <S.LeftInfo>Автомобиль в залоге</S.LeftInfo>
                        <S.RightInfo>
                          <S.CheckWrapper>
                            <S.StyledUnCheckRed />
                          </S.CheckWrapper>
                        </S.RightInfo>
                      </>
                    ) : (
                      <>
                        <S.LeftInfo>Автомобиль не в залоге</S.LeftInfo>
                        <S.RightInfo>
                          <S.CheckWrapper>
                            <S.StyledCheckGreen />
                          </S.CheckWrapper>
                        </S.RightInfo>
                      </>
                    )}
                  </S.VinLineWrapper>
                  <S.VinLineWrapper>
                    <S.LeftInfo>Лицензия такси или каршеринг</S.LeftInfo>
                    <S.RightInfo>
                      {vinInfo.data.carScore.taxi || vinInfo.data.carScore.carsharing ? (
                        <S.CheckWrapper>
                          <S.StyledUnCheckRed />
                        </S.CheckWrapper>
                      ) : (
                        <S.CheckWrapper>
                          <S.StyledCheckGreen />
                        </S.CheckWrapper>
                      )}
                    </S.RightInfo>
                  </S.VinLineWrapper>
                  <S.VinLineWrapper>
                    <S.LeftInfo>Ограничения на регистрационные действия</S.LeftInfo>
                    <S.RightInfo>
                      {vinInfo.data.carScore.restrictions ? (
                        <S.CheckWrapper>
                          <S.StyledUnCheckRed />
                        </S.CheckWrapper>
                      ) : (
                        <S.CheckWrapper>
                          <S.StyledCheckGreen />
                        </S.CheckWrapper>
                      )}
                    </S.RightInfo>
                  </S.VinLineWrapper>
                  <S.VinLineWrapper>
                    <S.LeftInfo>ДТП тотал</S.LeftInfo>
                    <S.RightInfo>
                      {vinInfo.data.carScore.seriousDamage ? (
                        <S.CheckWrapper>
                          <S.StyledUnCheckRed />
                        </S.CheckWrapper>
                      ) : (
                        <S.CheckWrapper>
                          <S.StyledCheckGreen />
                        </S.CheckWrapper>
                      )}
                    </S.RightInfo>
                  </S.VinLineWrapper>
                </S.VinInfoWrapper>
                <S.ApproveCreditButton
                  onClick={() => {
                    setOpened('block1', 'block2');
                  }}
                >
                  {restrictions.length === 0
                    ? 'Оформить цифровую сделку'
                    : 'Все равно оформить цифровую сделку'}
                </S.ApproveCreditButton>
              </>
            )}

            {blocks.block2 && (
              <S.VinInfoWrapper>
                <S.SearchInputWrapper>
                  <S.SearchInput
                    type="text"
                    placeholder="Введите имя"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </S.SearchInputWrapper>
                {isLoading ? (
                  <Loader color="none" show={true} fixed={false} />
                ) : (
                  <S.DealsContainer>
                    {filteredList.map((data) => (
                      <DealItem key={data.id} data={data} />
                    ))}

                    {sortedList.length === 0 && <EmptyState />}
                    {filteredList.length === 0 && <EmptyFilter />}
                  </S.DealsContainer>
                )}
              </S.VinInfoWrapper>
            )}
          </S.PopupChildren>
        </S.PopupStyled>
      )}

      {isLoadingVin && (
        <S.LoaderWrapper>
          <img width="150" height="150" src={CarPreloaderGif} alt="" />
        </S.LoaderWrapper>
      )}

      {disabledVin && (
        <S.PopupStyled
          onClose={() => setDisabledVin(false)}
          contentStyle={{ borderRadius: '20px', backgroundColor: PALETTE.white }}
        >
          <S.DisabledVin>
            <S.Title>Доступно только для дилеров с подключенной цифровой сделкой.</S.Title>
            <S.Title>
              Узнать больше о цифровой сделке можно{' '}
              <a href="https://www.ecredit.one/nopaper" target="_blank" rel="noopener noreferrer">
                здесь
              </a>
              .
            </S.Title>
          </S.DisabledVin>
        </S.PopupStyled>
      )}
    </S.Wrap>
  );
}
