import styled from 'styled-components';
import { PALETTE } from 'styles/constants';
import BankLogo from 'components/common/BankLogo';
import * as UK from 'components/primitives/content';

export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 20%;
  padding: 24px;
  margin-left: 16px;
  background-color: ${PALETTE.white};
  border-radius: 20px;
  flex-grow: 1;

  @media (max-width: 1200px) {
    width: 250px;
  }
`;

export const DealersBanksWrapper = styled.div`
  margin-bottom: 5px;
  overflow-y: auto;
  height: 70vh;
  flex-grow: 1;
  width: 100%;
`;

export const Title = styled(UK.Title1)`
  margin-bottom: 28px;

  @media (max-width: 1200px) {
    font-size: 1.1rem;
  }

  @media (max-width: 1100px) {
    font-size: 0.9rem;
  }

  @media (max-width: 1000px) {
    font-size: 0.8rem;
  }
`;

export const BanksContainer = styled.div`
  display: flex;
  height: 60px;
  align-items: center;
  border-bottom: 1px solid ${PALETTE.grey9};
`;

export const BankItem = styled(BankLogo)`
  width: 23px;
  height: 23px;
  margin-right: 15px;
`;

export const BankName = styled(UK.Title2)`
  font-size: 14px;
  font-weight: 400;
  margin: 0px;

  @media (max-width: 1200px) {
    font-size: 0.8rem;
  }

  @media (max-width: 1100px) {
    font-size: 0.7rem;
  }
`;
