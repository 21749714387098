import { ASSISTANCE_WORKFLOW_STATES } from 'constants/assistance';

export const statuses = {
  calculation: {
    label: 'Расчет',
    color: 'white',
  },
  consultation: {
    label: 'Консультация',
    color: 'yellow',
  },
  payment: {
    label: 'Оплата',
    color: 'green',
  },
  issued: {
    label: 'Выпущен',
    color: 'green',
  },
  declined: {
    label: 'Отказ',
    color: 'red',
  },
  error: {
    label: 'Ошибка',
    color: 'blue',
  },
};

export const clearState = {
  name: '',
  value: '',
  color: '',
};

export const declineData = {
  step: 1,
  content: null,
  loading: false,
};

export const errorData = {
  step: 1,
  content: null,
  loading: false,
};

export const newData = {
  step: 0,
  content: null,
  loading: false,
};

export const statusByEosagoWorkflowState = {
  [ASSISTANCE_WORKFLOW_STATES.new]: null,
  [ASSISTANCE_WORKFLOW_STATES.offers_requested]: 'calculation',
  [ASSISTANCE_WORKFLOW_STATES.offers_received]: 'calculation',
  [ASSISTANCE_WORKFLOW_STATES.offer_selected]: 'consultation',
  [ASSISTANCE_WORKFLOW_STATES.agreement_requested]: 'consultation',
  [ASSISTANCE_WORKFLOW_STATES.agreement_succeed]: 'payment',
  [ASSISTANCE_WORKFLOW_STATES.issue_requested]: 'payment',
  [ASSISTANCE_WORKFLOW_STATES.issued]: 'issued',
  [ASSISTANCE_WORKFLOW_STATES.offers_declined]: 'declined',
  [ASSISTANCE_WORKFLOW_STATES.declined]: 'declined',
  [ASSISTANCE_WORKFLOW_STATES.agreement_declined]: 'declined',
  [ASSISTANCE_WORKFLOW_STATES.issue_decline]: 'declined',
  [ASSISTANCE_WORKFLOW_STATES.client_decline]: 'declined',
  [ASSISTANCE_WORKFLOW_STATES.offers_error]: 'error',
  [ASSISTANCE_WORKFLOW_STATES.error]: 'error',
  [ASSISTANCE_WORKFLOW_STATES.agreement_error]: 'error',
  [ASSISTANCE_WORKFLOW_STATES.issue_error]: 'error',
};
