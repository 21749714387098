import { traefikRequest, agent as request } from 'utils/request';
import throwCustomError from 'utils/throwCustomError';
import * as urls from 'constants/url';

export const postFile = async (file) => {
  try {
    const formData = new FormData();
    formData.append('file[]', file);

    const params = {
      classification: 'skip',
    };

    formData.append(
      'payload',
      new Blob([JSON.stringify(params)], { type: 'application/json' }),
      'payload.json'
    );

    const res = await request
      .post(urls.POST_FILE_URL(), formData, {
        headers: {
          'content-type': 'application/json',
          'accept': 'application/json',
        },
      })
      .catch(throwCustomError);

    return res?.data && res.data[0] ? res.data[0] : null;
  } catch (err) {
    console.error(err.message);
    throw err;
  }
};

export const postFiles = async (files = [], classification = 'all_uploaded_files') => {
  try {
    if (files.length === 0) {
      return;
    }
    const formData = new FormData();
    files.forEach((file) => {
      formData.append('file[]', file);
    });

    const params = {
      classification,
    };

    formData.append(
      'payload',
      new Blob([JSON.stringify(params)], { type: 'application/json' }),
      'payload.json'
    );

    const res = await request
      .post(urls.POST_FILE_URL(), formData, {
        headers: {
          'content-type': 'application/json',
          'accept': 'application/json',
        },
      })
      .catch(throwCustomError);

    return res?.data;
  } catch (err) {
    console.error(err.message);
    throw err;
  }
};

export const uploadQrFiles = async ({ files = [], fileType, applicationId, token }) => {
  if (files.length === 0) {
    return;
  }

  const formData = new FormData();
  formData.append('fileType', fileType);
  formData.append('applicationId', applicationId);
  formData.append('token', token);
  files.forEach((file) => {
    formData.append('files[]', file);
  });

  const res = await traefikRequest
    .post(urls.QR_CODE_FILE_UPLOAD_URL, formData, {
      headers: {
        'content-type': 'application/json',
        'accept': 'application/json',
      },
    })
    .catch(throwCustomError);
  return res?.data;
};
