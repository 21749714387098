import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useToasts } from 'react-toast-notifications';

import { loadProfilePDF } from 'store/anketa';
import { loadFiles } from 'data-providers/documentProvider';
import { isDocumentExists } from 'utils/dataMappers/mappers';

import { FileUpload } from './FileUpload';
import { AnketaChevronStyled } from '../../style';
import { FormContainer, FormHeader } from '../style';
import {
  GridStyled,
  DocSignHelp,
  DocSignHelp2,
  LinkContainer,
  DocLink,
  AgreementLoadArea,
  PrinterGreenSvgStyled,
} from './style';

const linksDoc = {
  'personal-data': [
    { name: 'Согласие', type: 'personal_data_credit_history_no_pep' },
    { name: 'Согласие', type: 'agreement_bki_no_pep' },
  ],
  'pep-sopd': [{ name: 'Согласие СОПД ПЭП', type: 'personal_data' }],
  'pep-agreement': [{ name: 'Соглашение ПЭП', type: 'electronic_document' }],
};

export const SignDocuments = ({ data, errors, contentHidden = false }) => {
  const rootDispatch = useDispatch();
  const { anketa } = useSelector((state) => state);
  const { saved, pdf, documents, showSignDocuments, isPEPSignature } = useSelector(
    (state) => state.anketa || {}
  );

  const { addToast, removeAllToasts } = useToasts();
  const [showMore, setShowMore] = useState(true);

  const { persons, currentPersonId } = data;
  const appId = Number(anketa.application.id);
  const persona = persons[currentPersonId];

  const handleRedirect = ({ type }) => {
    if (hasPersonalError()) {
      addToast('Необходимо заполнить персональные данные', {
        appearance: 'warning',
        autoDismiss: true,
      });
    } else if (data && saved) {
      addToast('Идет генерация документа', {
        appearance: 'success',
        autoDismiss: false,
      });

      rootDispatch(
        loadProfilePDF({
          param: `generate=${type}`,
          persona: persona,
          applicationId: parseInt(appId),
        })
      );
    }
  };

  useEffect(() => {
    setShowMore(showSignDocuments);
  }, [showSignDocuments]);

  useEffect(() => {
    if (pdf) {
      removeAllToasts();
      var link = document.createElement('a');
      link.href = pdf;
      link.click();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdf]);

  const updateFiles = (callback = () => {}) => {
    loadFiles(appId).then((data) => {
      rootDispatch({
        type: 'UPLOAD_COMMON',
        payload: {
          documents: data,
        },
      });
      callback();
    });
  };

  const hasPersonalError = () => {
    if (!errors) return false;
    const errs = Object.keys(errors).map((f) => f.replaceAll(/\[\d\]/gu, ''));
    return errs.some(
      (ef) =>
        ef.includes('personalInfo.fullName') || ef.includes('addressReg') || ef.includes('passport')
    );
  };

  const agreementLoadHandler = () => {
    if (!contentHidden) {
      handleRedirect(linksDoc['personal-data'][isPEPSignature ? 1 : 0]);
    }
  };

  const pepHandler = (event) => {
    event.preventDefault();

    const docType = event.currentTarget?.dataset?.doctype;
    if (contentHidden || !linksDoc[docType]) {
      return;
    }

    handleRedirect(linksDoc[docType][0]);
  };

  return (
    <FormContainer id="sign">
      <FormHeader
        style={{ marginBottom: showMore ? 0 : 30, cursor: 'pointer' }}
        collapsed={!showMore}
        onClick={() => {
          setShowMore(!showMore);
        }}
      >
        {isPEPSignature
          ? 'Подписать Согласие БКИ у клиента'
          : 'Подписать бумажное Согласие у клиента'}

        <AnketaChevronStyled
          style={{ marginLeft: 30, transform: showMore ? 'rotate(0)' : 'rotate(90deg)' }}
          className="chevron"
        />
      </FormHeader>
      {showMore && (
        <>
          <DocSignHelp>
            {isPEPSignature
              ? 'Распечатайте и подпишите у клиента Согласие БКИ'
              : 'Распечатайте и подпишите у клиента Согласие на обработку персональных данных и БКИ'}
          </DocSignHelp>

          <GridStyled>
            <AgreementLoadArea disabled={contentHidden} onClick={agreementLoadHandler}>
              <PrinterGreenSvgStyled />
              Скачать Согласие
            </AgreementLoadArea>
            <FileUpload
              id="sign_personal-data"
              classification="personal-data"
              applicationId={appId}
              currentPersonId={currentPersonId}
              onLoadFiles={updateFiles}
              disabled={contentHidden || isDocumentExists(documents, ['personal-data'])}
              title={
                isDocumentExists(documents, ['personal-data'])
                  ? 'Подписанное Согласие'
                  : 'Загрузить подписанное Согласие'
              }
              contentHidden={contentHidden}
            />
          </GridStyled>

          {isPEPSignature ? (
            <LinkContainer>
              <DocLink href="#" onClick={pepHandler} data-doctype="pep-sopd">
                Согласие СОПД ПЭП
              </DocLink>
              <DocLink href="#" onClick={pepHandler} data-doctype="pep-agreement">
                Соглашение ПЭП
              </DocLink>
            </LinkContainer>
          ) : (
            <DocSignHelp2>
              Оповестите клиента о смс от банка на подтверждение запроса в ПФР
            </DocSignHelp2>
          )}
        </>
      )}
    </FormContainer>
  );
};

SignDocuments.propTypes = {
  type: PropTypes.number,
};

SignDocuments.defaultProps = {
  type: 0,
};
