import React from 'react';
import { useSelector } from 'react-redux';
import AssistanceWidget from './assistance';
import { ASSISTANCE_WORKFLOW_STATES } from 'constants/assistance';
import { applicationsListSorter, ordersListMapper } from './helper';

const fn = () => {};

const Wrapper = ({ orders, onClick = fn }) => {
  const errorStates = ['offers_error', 'issue_error', 'client_decline', 'canceled'];
  const { applications, nameplate } = useSelector((state) => state.assistanceCalculation);

  const aswCalcApplicationsArr = Object.values(applications);
  const sortedApplications = aswCalcApplicationsArr.length
    ? aswCalcApplicationsArr.sort(applicationsListSorter)
    : orders.map(ordersListMapper).sort((a, b) => b.orderId - a.orderId);

  const hasManyApplications = sortedApplications.length > 1;
  const currentApplication = sortedApplications[0];

  let actualApplication =
    hasManyApplications && errorStates.includes(currentApplication?.state?.sysName)
      ? sortedApplications.find((application) => !errorStates.includes(application.state.sysName))
      : currentApplication;

  if (
    sortedApplications.every((application) => errorStates.includes(application?.state?.sysName))
  ) {
    actualApplication = currentApplication;
  }

  const { state, offers, selectedOffer } = actualApplication || {};
  let title = '';

  const isRedButton =
    !!offers?.length && offers.every((offer) => Object.keys(offer.paymentMethods).length === 0);

  if (
    (state || nameplate)?.sysName === ASSISTANCE_WORKFLOW_STATES.offer_selected &&
    selectedOffer
  ) {
    title =
      selectedOffer.title || selectedOffer.companyName || selectedOffer?.insuranceCompanyName || '';
  }

  if (
    [ASSISTANCE_WORKFLOW_STATES.agreement_succeed, ASSISTANCE_WORKFLOW_STATES.issued].includes(
      state?.sysName
    )
  ) {
    title = selectedOffer?.title || selectedOffer?.insuranceCompanyName || '';
  }

  return (
    <AssistanceWidget
      state={state || nameplate || {}}
      title={title}
      offers={offers}
      isRedButton={isRedButton}
      onClick={onClick}
    />
  );
};

export default Wrapper;
