import React from 'react';

import { formatRgbColor } from 'utils/helpers';
import { Wrapper } from './style';

const State = ({ className = '', title = '', color = '' }) => {
  if (!title || !color) {
    return null;
  } else {
    return (
      <Wrapper className={className} color={formatRgbColor(color)} text={title} title={title} />
    );
  }
};

State.displayName = 'State';

export default State;
