import { put, select, all, call, takeLatest } from 'redux-saga/effects';
import assignWith from 'lodash/assignWith';

import * as provider from 'data-providers/assistanceAnketaProvider';
import { isFillCustomer } from 'utils/helpers';
import { DEFAULT_ERROR_MESSAGE } from 'constants/index';

import * as actions from './constants';
import * as Helper from './helper';
import * as SaveHelper from './saveHelper';
import backToFrontMapper from './backToFrontMapper';

// import { getProfile } from 'store/anketa';

function* getCustomersByApplicationId({ payload: { applicationId, product } }) {
  try {
    yield put({ type: actions.ASSISTANCE_SET_CUSTOMERS_FETCH, payload: 'pending' });

    const nodes = yield call(provider.getCustomersList, { applicationId, product });

    yield put({ type: actions.ASSISTANCE_SET_NODES, payload: { nodes } });

    const allActiveCustomerIds = Helper.getUniqueCustomerIds({ nodes });

    const allCustomers = yield all(
      allActiveCustomerIds.map((customerId) => call(provider.getCustomer, { customerId }))
    );

    allCustomers.forEach((customer) => {
      if (!isFillCustomer(customer.personalInformation)) {
        customer.personalInformation.name = '';
        customer.personalInformation.surname = '';
        customer.personalInformation.middleName = '';
      }
    });

    const assistanceAnketa = yield select((store) => store.assistanceAnketa);
    const mappedInsurer = backToFrontMapper({ customers: allCustomers, nodes });
    const combinedData = assignWith({}, mappedInsurer, assistanceAnketa, (a, b) =>
      !b ? a : undefined
    );

    yield put({
      type: actions.ASSISTANCE_SET_CURRENT_SAVED_DATA,
      payload: { currentSavedData: combinedData },
    });
    yield put({
      type: actions.ASSISTANCE_SET_CUSTOMER,
      payload: { mappedInsurer: combinedData, filledCustomers: allCustomers, applicationId },
    });
  } catch (err) {
    yield put({
      type: actions.ASSISTANCE_SET_NETWORK_ERROR,
      payload: err.customDescription || DEFAULT_ERROR_MESSAGE,
    });
  } finally {
    yield put({ type: actions.ASSISTANCE_SET_CUSTOMERS_FETCH, payload: 'done' });
  }
}

function* assistancePatchCarInstance({ payload: { anketaId, isCashCustomer } }) {
  try {
    yield* SaveHelper.saveCarDetail({ payload: { anketaId, isCashCustomer } });
  } catch (err) {
    console.log(`patchCarinStanceError ***\r\n${err}`);
  }
}

export function* watchAssistanceGetCustomers() {
  yield takeLatest(actions.ASSISTANCE_GET_CUSTOMERS_IDS, getCustomersByApplicationId);
}

export function* watchAssistancePatchCarInstance() {
  yield takeLatest(actions.ASSISTANCE_PATCH_CAR_INSTANCE, assistancePatchCarInstance);
}
