import React from 'react';
import { useSelector } from 'react-redux';

import { PALETTE } from 'styles/constants';
import { APPLICATION_STATES_TO_DISPLAY_INSTEAD_OF_BALLS, ORDER_LAMP } from 'constants/index';
import { statusMapSelector } from 'selectors';

import { LampItem } from '../../../LampItem';
import { SemaphoreWrapper } from './style';

const getStateColor = (application, closeReason) => {
  const lastState = application?.lastState?.state || '';
  const reasonState = closeReason?.state || '';

  const stateName =
    lastState === 'archive' && reasonState === 'financed' ? 'archive-financed' : lastState;

  return APPLICATION_STATES_TO_DISPLAY_INSTEAD_OF_BALLS[stateName];
};

export const Semaphore = ({ application, closeReason, isShowEmpty }) => {
  const statusMap = useSelector(statusMapSelector);

  const { dealStates: data, dealStatesByName } = application;

  if (isShowEmpty) {
    return (
      <>
        <SemaphoreWrapper>
          {Array(5)
            .fill('')
            .map((lamp, i) => (
              <LampItem key={i} color={PALETTE.grey3} />
            ))}
        </SemaphoreWrapper>
      </>
    );
  }

  const lastStateName = application?.lastState?.stateSecondName;
  const lastState = application?.lastState?.state;

  if (!lastState) {
    return null;
  }

  const stateColor = getStateColor(application, closeReason);
  const reasonForClosure = statusMap.get(closeReason?.state);

  const showBalls = Object.keys(APPLICATION_STATES_TO_DISPLAY_INSTEAD_OF_BALLS).includes(lastState);

  return (
    <>
      {!showBalls ? (
        <SemaphoreWrapper>
          {Object.keys(ORDER_LAMP).map((color, c) =>
            data[color].banks.length ? (
              <LampItem
                key={`_lamp_${c + 1}`}
                label={ORDER_LAMP[color]}
                color={PALETTE[`${color}1`]}
                popover={data[color].banks}
              />
            ) : (
              <LampItem key={`_lamp_${c + 1}`} color={PALETTE.grey3} />
            )
          )}
        </SemaphoreWrapper>
      ) : (
        <LampItem
          label={lastStateName}
          color={stateColor}
          popover={stateColor === 'red' ? data['red'].banks : dealStatesByName[lastState]}
          pure
          showState
          subLabel={reasonForClosure || ''}
        />
      )}
    </>
  );
};
