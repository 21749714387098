import React, { useState, useEffect } from 'react';
import debounce from 'lodash/debounce';

import * as S from './style';

const HoverNotification = ({
  componentRef = null,
  text = 'default text',
  disabled = false,
  appearDuration = 250,
}) => {
  const [position, setPosition] = useState(null);
  const [leave, setLeave] = useState(false);

  useEffect(() => {
    let ref = componentRef;
    if (ref?.current) {
      const debouncedSetPosition = debounce(({ x, y }) => {
        setPosition(() => ({ x, y }));
      }, appearDuration);

      const mouseOverListener = (evt) => {
        debouncedSetPosition({ x: evt.clientX, y: evt.clientY });
      };

      const unlockListener = () => {
        setLeave(() => false);
        setPosition(() => null);
      };

      const leaveListener = () => {
        setLeave(() => true);
      };

      ref.current.addEventListener('mouseleave', leaveListener);
      ref.current.addEventListener('mouseover', unlockListener);
      ref.current.addEventListener('mouseover', mouseOverListener);

      return () => {
        if (ref.current?.removeEventListener) {
          ref.current.removeEventListener('mouseleave', leaveListener);
          ref.current.removeEventListener('mouseover', unlockListener);
          ref.current.removeEventListener('mouseover', mouseOverListener);
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentRef?.current, componentRef?.current?.addEventListener]);

  if (leave || disabled) {
    return null;
  }

  return <S.Item position={position}>{text}</S.Item>;
};

export default HoverNotification;
