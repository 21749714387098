const callbacks = new WeakMap();

const observerHandler = (items) => {
  items.forEach((item) => {
    const handlers = callbacks.get(item.target);
    if (handlers) {
      (item.isIntersecting ? handlers.showHandler : handlers.hideHandler)();
    }
  });
};

const mountHandler = (elem, onShow, onHide) => {
  if (!elem) {
    return;
  }

  callbacks.set(elem, {
    showHandler: onShow || (() => {}),
    hideHandler: onHide || (() => {}),
  });

  observer.observe(elem);
};

const ummountHandler = (elem) => {
  if (!elem) {
    return;
  }

  observer.unobserve(elem);
  callbacks.delete(elem);
};

const observer = new IntersectionObserver(observerHandler, { root: null, threshold: 0 });

export const useIntersectionObserver = (ref) => {
  return {
    mount: (onShow, onHide) => mountHandler(ref.current, onShow, onHide),
    unmount: () => ummountHandler(ref.current),
  };
};
