import React, { useState, useEffect } from 'react';

import { hourFormat } from 'utils/dateUtils';
import { Wrapper, Time, Clock } from './style';

export const Timer = ({ time = 0, onTimerEnd = () => {}, ...rest }) => {
  const [localTime, setLocalTime] = useState(time);
  const [timeStr, setTimeStr] = useState(hourFormat(time));

  useEffect(() => {
    setLocalTime(time);
    const interval = setInterval(() => {
      setLocalTime((prev) => {
        if (prev > 0) {
          return prev - 1;
        } else {
          clearInterval(interval);
          onTimerEnd();
          return 0;
        }
      });
    }, 1000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time]);

  useEffect(() => {
    setTimeStr(hourFormat(localTime));
  }, [localTime]);

  return (
    <Wrapper {...rest}>
      <Time>
        <Clock>{timeStr}</Clock>
      </Time>
    </Wrapper>
  );
};

Timer.displayName = 'Timer';

export default Timer;
