import { get, post } from 'data-providers/common';
import request from 'utils/request';

export const loadFiles = async (applicationId) => {
  try {
    const data = await get(`applications/${applicationId}/documents`);
    return data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

export const updateClassify = async (applicationId, documentId, entryId, type) => {
  try {
    const data = await post(`applications/${applicationId}/documents/${documentId}/classify`, {
      entryName: 'customer',
      entryId,
      type,
    });
    return data;
  } catch (err) {
    console.error(err);
  }
};

export const deleteFile = async (applicationId, documentId, entryId, type) => {
  try {
    const data = await post(`applications/${applicationId}/documents/${documentId}/remove`, {
      entryName: 'customer',
      entryId,
      type,
    });
    return data;
  } catch (err) {
    console.error(err);
  }
};

export const getRecognizeFile = async (params) => {
  try {
    const data = await get('documents/recognition/status', { page: 1, perPage: 40, ...params });
    return data;
  } catch (err) {
    console.error(err);
  }
};

export const getUploadQrCode = async (type, id) => {
  try {
    const url = `${process.env.REACT_APP_TRAEFIK_URL}/api/v1/loan-issue/qr/generate/${type}/${id}`;
    return await request.get(url, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    });
  } catch (err) {
    console.error(err);
  }
};
