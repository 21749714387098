import styled from 'styled-components';
import { DragDropArea } from 'components/primitives/drag-drop-area';

export const DragDropStyled = styled(DragDropArea)`
  grid-area: fup;
  height: 85px;
  border-radius: 5px;
  border: ${(p) => (p.process !== 3 ? '2px dashed #FFD700' : '1px solid #228B22')};
  background-color: ${(p) =>
    p.process !== 3 ? 'rgba(255, 215, 0, 0.17)' : 'rgba(233, 243, 233, 1)'};
  svg {
    width: 30px;
    margin-right: ;
  }
  cursor: ${(p) => (p.contentHidden ? 'not-allowed' : 'pointer')};
`;
