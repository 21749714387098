import styled from 'styled-components';
import { PALETTE } from 'styles/constants';

export const StatusStyled = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: ${(p) => p.color};
  border: 1px solid
    ${(p) =>
      p.color.toLowerCase() === '#ffffff' || p.color.toLowerCase() === '#f7f7f7'
        ? PALETTE.grey2
        : 'transparent'};
  color: ${(p) =>
    p.color.toLowerCase() === '#ffffff' ||
    p.color.toLowerCase() === '#f7f7f7' ||
    p.color.toLowerCase() === PALETTE.yellow1.toLowerCase()
      ? PALETTE.black
      : PALETTE.white};
  font-size: 12px;
  white-space: nowrap;
`;
