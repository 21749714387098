import { WatsapSvg } from 'assets/img';
import styled from 'styled-components';
import { PALETTE } from 'styles/constants';

export const WatsapLogo = styled(WatsapSvg)`
  margin-right: 7px;
  width: 15px;
  height: 15px;
`;

export const PhoneLink = styled.a`
  color: ${PALETTE.green1};
  cursor: pointer;

  &:hover {
    color: ${PALETTE.green1};
  }
`;

export const Wraper = styled.div`
  display: flex;
  align-items: center;
`;
