export const CAR_STATE = [
  {
    id: 1,
    value: 'Новый',
    dataTest: 'carNew',
  },
  {
    id: 2,
    value: 'С пробегом',
    dataTest: 'carUsed',
  },
];

export const BLOCK_CAR_SELECT = {
  name: 'BLOCK_CAR_SELECT',
  fields: ['vin'],
};

export const BLOCK_MAIN = {
  name: 'main',
  fields: ['brand', 'model', 'version', 'price', 'year'],
};

export const BLOCK_PARAMETRS = {
  name: 'parametrs',
  fields: [
    'enginePower',
    'mileage',
    'bodyNumber',
    'engineVolume',
    'engineNumber',
    'permittedMaxWeight',
  ],
};

export const BLOCK_DOCS = {
  name: 'docs',
  fields: ['pts'],
};

export const MIN_PRICE = 10000;

export const AUTO_PARAMS = {
  enginePower: 'enginePower',
  engineNumber: 'engineNumber',
  bodyNumber: 'bodyNumber',
  permittedMaxWeight: 'permittedMaxWeight',
  engineVolume: 'engineVolume',
  glonasNumber: 'glonasNumber',
  mileage: 'mileage',
};

export const GLONAS = {
  dataPresent: 'Номер Эра-ГЛОНАСС',
  dataMissing: 'Номер Эра-ГЛОНАСС отсутствует',
};

export const BRAND_RECOMEND_PRCT = {
  6: 1.1,
  188: 1.1,
  225: 1.1,
};
