import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useRouteMatch } from 'react-router-dom';
import OutsideClickHandler from 'react-outside-click-handler';

import { toggleCameraPopup, togglePhotoPopup } from 'store/deal';
import { getFilesPhoto, getPhotoUrl, canUploadPhoto } from '../../../Loan/helpers';
import {
  AvatarSvgStyled,
  CameraSvgStyled,
  Wrapper,
  CameraWrapper,
  UploadOptions,
  UploadOption,
  PhotoOptionIcon,
  UploadOptionIcon,
} from './styles';

const UserWidgetAvatar = ({ avatarSize = 64, iconSize = 24, canEdit = true }) => {
  const params = useParams();
  const { path } = useRouteMatch();
  const rootDispatch = useDispatch();
  const deal = useSelector((store) => store.deal);
  const [photoUrl, setPhotoUrl] = useState(null);
  const [isOptionsShown, setIsOptionsShown] = useState(false);
  const isLoan = path === '/worksheet/:id/loan-issue';
  const isCreateNew = isLoan && params.id === 'create-new';
  const shouldShowChangePhoto = useMemo(() => {
    if (deal.id && isLoan && !isCreateNew) {
      return canEdit && canUploadPhoto(deal?.state);
    }
    return deal && canEdit;
  }, [deal, canEdit, isLoan, isCreateNew]);

  useEffect(() => {
    if (deal) {
      const photos = getFilesPhoto(deal.files);
      if (photos && photos.length > 0) {
        setPhotoUrl(getPhotoUrl(photos[0]));
      } else {
        setPhotoUrl(null);
      }
    }
  }, [deal]);

  const handleClick = () => {
    setIsOptionsShown(true);
  };

  const hideOptions = () => {
    setIsOptionsShown(false);
  };

  const handleTakePhoto = () => {
    rootDispatch(toggleCameraPopup(true));
    hideOptions();
  };

  const handleUploadPhoto = () => {
    rootDispatch(togglePhotoPopup(true));
    hideOptions();
  };

  return (
    <OutsideClickHandler onOutsideClick={hideOptions}>
      <Wrapper size={avatarSize}>
        {photoUrl && <img src={photoUrl} alt="avatar" />}
        {!photoUrl && <AvatarSvgStyled size={avatarSize} />}
        {shouldShowChangePhoto && (
          <CameraWrapper>
            <CameraSvgStyled size={iconSize} onClick={handleClick} />
            {isOptionsShown && (
              <UploadOptions>
                <UploadOption onClick={handleTakePhoto}>
                  <PhotoOptionIcon /> Сфотографировать
                </UploadOption>
                <UploadOption onClick={handleUploadPhoto}>
                  <UploadOptionIcon /> Загрузить
                </UploadOption>
              </UploadOptions>
            )}
          </CameraWrapper>
        )}
      </Wrapper>
    </OutsideClickHandler>
  );
};

export default UserWidgetAvatar;
