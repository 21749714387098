import React, { forwardRef } from 'react';
import cn from 'classnames';

const NavFloat = forwardRef(({ submenu = null, active = true }, ref) => (
  <div className={cn(['sidebar-nav__float', { active }])} ref={ref}>
    {submenu}
  </div>
));

NavFloat.displayName = 'NavFloat';

export default NavFloat;
