export const INS_ANKETA_SET_ERROR = 'insurance/anketa/set-error';
export const INS_ANKETA_INSURER_SET_ERROR = 'insurance/anketa/insurer-set-error';
export const INS_ANKETA_DELETE_ERROR = 'insurance/anketa/delete-error';
export const INS_ANKETA_INSURER_DELETE_ERROR = 'insurance/anketa/insurer-delete-error';
export const INS_ANKETA_SET_FETCH = 'insurance/anketa/set-fetch';
export const INS_ANKETA_SET_INSURER_DADATA_INFO = 'insurance/anketa/set-insurer-dadata-info';
export const INS_ANKETA_SET_KASKO_INSURER_DADATA_INFO =
  'insurance/anketa/set-kasko-insurer-dadata-info';
export const INS_ANKETA_CHANGE_BY_KEY = 'insurance/anketa/chnage-by-key';
export const INS_ANKETA_INSURER_CHANGE_BY_KEY = 'insurance/anketa/kasko-insurer-chnage-by-key';
export const INS_ANKETA_CHANGE_DRIVER = 'insurance/anketa/chnage-driver';
export const INS_ANKETA_CHANGE_DRIVER_WERRORS = 'insurance/anketa/chnage-driver-werrors';
export const INS_ANKETA_ADD_DRIVER = 'insurance/anketa/add-driver';
export const INS_ANKETA_ADD_CONFIDANT = 'insurance/anketa/add-confidant';
export const INS_ANKETA_DELETE_DRIVER = 'insurance/anketa/delete-driver';
export const INS_ANKETA_DROP_DRIVER = 'insurance/anketa/drop-driver';
export const INS_ANKETA_CHANGE = 'insurance/anketa/chnage';
export const INS_ANKETA_DROP_ALL_ERRORS = 'insurance/anketa/drop-all-errors';
export const INS_ANKETA_DROP_ALL = 'insurance/anketa/drop-all';
export const INS_ANKETA_SET_ERRORS = 'insurance/anketa/set-errors';
export const INS_ANKETA_INSURER_SET_ERRORS = 'insurance/anketa/insurer-set-errors';
export const INS_ANKETA_CHANGE_BY_KEY_WERRORS = 'insurance/anketa/chnage-by-key-werrors';
export const INS_ANKETA_SET_CUSTOMER_SHORT = 'insurance/anketa/set-customer-short';
export const INS_ANKETA_SET_CUSTOMER = 'insurance/anketa/set-customer';
export const INS_ANKETA_GET_CUSTOMERS_IDS = 'insurance/anketa/get-customers-ids';
export const INS_ANKETA_SET_CUSTOMERS_FETCH = 'insurance/anketa/set-customers-fetch';
export const INS_ANKETA_PATCH_CUSTOMER = 'insurance/anketa/patch-customers-ids';
export const INS_ANKETA_NEW_FILLED_CUSTOMERS = 'insurance/anketa/new-filled-customers';
export const INS_ANKETA_EXTRACT_DRIVER = 'insurance/anketa/extract-driver';
export const INS_ANKETA_CLEAR_EMPTY_DRIVERS = 'insurance/anketa/clear-empty-drivers';
// ?
export const INS_ANKETA_VALIDATE = 'insurance/anketa/validate';
export const INS_ANKETA_SWITCH_OWNER_DRIVER_CHECKBOX =
  'insurance/anketa/switch-owner-driver-checkbox';
export const INS_ANKETA_SWITCH_OWNER_INSURER_CHECKBOX =
  'insurance/anketa/switch-insurer-driver-checkbox';
export const INS_ANKETA_SWITCH_INSURER_IS_DRIVER_CHECKBOX =
  'insurance/anketa/switch-insurer-is-driver-checkbox';
export const INS_ANKETA_SET_NODES = 'insurance/anketa/set-nodes';
export const INS_ANKETA_SET_DRIVERS = 'insurance/anketa/set-drivers';
export const INS_ANKETA_SET_CURRENT_SAVED_DATA = 'insurance/anketa/set-current-saved-data';
export const INS_ANKETA_SET_SAVED_INSURER = 'insurance/anketa/set-saved-insurer';
export const INS_ANKETA_SAVE_SET_FETCH = 'insurance/anketa/save-set-fetch';
export const INS_ANKETA_SET_NETWORK_ERROR = 'insurance/anketa/set-network-error';

export const INS_ANKETA_SCROLL_TO = 'insurance/anketa/scroll-to';
export const INS_ANKETA_CLEAR_SCROLL_TO = 'insurance/anketa/clear-scroll-to';
export const INS_ANKETA_SWITCH_CALC_OPTION = 'insurance/anketa/switch-calc-option';

export const INS_ANKETA_CLEAR_ALL_DRIVERS = 'insurance/anketa/clear-all-drivers';
export const INS_ANKETA_RETURN_SAVED_DRIVERS = 'insurance/anketa/return-saved-drivers';
export const INS_ANKETA_RETURN_SAVED_INSURER = 'insurance/anketa/return-saved-insurer';

export const INS_ANKETA_SET_ENABLED = 'insurance/anketa/set-enabled';
export const INS_ANKETA_SET_DISABLED = 'insurance/anketa/set-disabled';

export const EOW_ANKETA_CREATE_FIRST_ORDER = 'EOW_ANKETA_CREATE_FIRST_ORDER';
export const EOW_ANKETA_SET_MAIN_FETCH = 'EOW_ANKETA_SET_MAIN_FETCH';
