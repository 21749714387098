const initialState = {
  id: null,
  createdBy: null,
  modifiedAt: null,
  createdAt: null,
  stateComment: null,
  assigmentAgreement: true,
  state: {},

  services: [],
  files: [],
  credit: {
    productId: null,
    providerOuterId: null,
    providerName: '',
    productName: '',
    payment: 0,
    rateInContract: 0,
    downPayment: 0,
    requestedSum: 0,
    term: 0,
    buybackSum: 0,
    creditCarSum: 0,
    creditInsuranceSum: 0,
    residual: 0,
    creditSumMax: 0,
    contractNumber: null,
    contractDate: null,
    recipient: null,
    ndsFlag: 1,
    recipients: [],
  },

  carInstanceId: null,
  dealerId: null,

  fetch: false,
  error: '',
  errors: {},
  showVinRecognitionPopup: false,

  shortInfo: {
    id: null,
    createdBy: null,
    modifiedAt: null,
    bankName: '',
    payment: 0,

    fetch: false,
    error: '',
  },
  timers: [],
};

export default initialState;
