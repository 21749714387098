import React, { Component, createElement } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withRouter } from 'react-router-dom';
import { withStyles, createStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import { Carrotquest } from 'components/modules/Widgets/External/Carrotquest';

const styles = () =>
  createStyles({
    root: {
      height: '100%',
      minHeight: '100%',
    },
    appFrame: {
      height: '100%',
      minHeight: '100%',
    },
    content: {
      marginLeft: 52,
      height: '100%',
      minHeight: '100%',
    },
    contentWithSidebar: {
      height: '100%',
      minHeight: '100%',
    },
    fixed: {
      marginLeft: 310,
    },
  });

/* eslint-disable no-unused-vars */
const sanitizeRestProps = ({
  appBar,
  dispatch,
  staticContext,
  history,
  location,
  match,
  menu,
  setSidebar,
  ...props
}) => props;
/* eslint-enable no-unused-vars */

class Layout extends Component {
  state = { hasError: false, errorMessage: null, errorInfo: null };

  constructor(props) {
    super(props);
    /**
     * Reset the error state upon navigation
     *
     * @see https://stackoverflow.com/questions/48121750/browser-navigation-broken-by-use-of-react-error-boundaries
     */
    props.history.listen(() => {
      if (this.state.hasError) {
        this.setState({ hasError: false });
      }
    });
  }

  componentDidCatch(errorMessage, errorInfo) {
    this.setState({ hasError: true, errorMessage, errorInfo });
  }

  render() {
    const { children, classes, className, error, sidebar, title, setSidebar, ...props } =
      this.props;
    const { hasError, errorMessage, errorInfo } = this.state;
    const Sidebar = sidebar;

    return (
      <div className={classnames('layout', classes.root, className)} {...sanitizeRestProps(props)}>
        <div className={classes.appFrame}>
          {/*appBarEl*/}
          {setSidebar && <Sidebar {...this.props} />}

          <main className={classes.contentWithSidebar}>
            <div className={classes.content}>
              {hasError
                ? createElement(error, {
                    error: errorMessage,
                    errorInfo,
                    title,
                  })
                : children}
            </div>
          </main>
          <Carrotquest />
        </div>
      </div>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  classes: PropTypes.object,
  className: PropTypes.string,
  customRoutes: PropTypes.array,
  history: PropTypes.object.isRequired,
  logout: PropTypes.element,
  open: PropTypes.bool,
  title: PropTypes.node,
};

const EnhancedLayout = compose(
  connect(),
  withRouter,
  withStyles(styles, { name: 'ecred-layout' })
)(Layout);

export default EnhancedLayout;
