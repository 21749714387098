import styled, { css } from 'styled-components';
import { CheckVinGreen, UnCheckVinRed } from 'assets/img';
import { PALETTE } from 'styles/constants';
import { Popup } from 'components/primitives/popup';
import * as UK from 'components/primitives/content';

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;
export const Title = styled(UK.Title2)`
  font-weight: 500;
  line-height: 1.1;
  font-size: 20px;
  margin-bottom: 24px;
`;

export const Lable = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: ${PALETTE.grey13};
  margin-bottom: 8px;
`;

export const InputWrap = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;

export const Input = styled(UK.Input)`
  flex: 1;
  background-color: ${(p) => (p.disabled ? PALETTE.grey3 : PALETTE.grey9)};
  border-color: ${(p) => (p.disabled ? PALETTE.grey3 : PALETTE.grey9)};
  height: 40px;
  border-radius: 8px;
  margin-right: 16px;
`;

export const CloseButton = styled.button`
  position: absolute;
  right: 130px;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 100%;
  padding: 0px;
  cursor: pointer;
  border: none;
  background: none;

  & > svg {
    width: 10px;
    height: 10px;
  }

  & path {
    ${(p) => (p.mainPageSearch ? `fill: ${PALETTE.grey12};` : `fill: ${PALETTE.grey2};`)}
  }

  &:hover path {
    fill: ${PALETTE.red1};
  }
`;

export const CheckButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 0px;
  width: 120px;
  height: 40px;
  border: 1px solid ${PALETTE.red5};
  border-radius: 8px;
  background-color: ${PALETTE.red5};
  color: ${PALETTE.white};
  cursor: pointer;

  &:hover {
    background-color: ${PALETTE.red6};
    border: 1px solid ${PALETTE.red6};
  }
  &:active {
    background-color: ${PALETTE.red7};
    border-color: ${PALETTE.red7};
  }
  ${(props) =>
    props.disabled &&
    css`
      background-color: ${PALETTE.grey14};
      color: ${PALETTE.grey15};
      border: 1px solid ${PALETTE.grey14};
      cursor: not-allowed;

      &:hover {
        background-color: ${PALETTE.grey14};
        color: ${PALETTE.grey15};
      }

      &:active {
        background-color: ${PALETTE.grey14};
        color: ${PALETTE.grey15};
        border-color: ${PALETTE.grey14};
        transform: none;
      }
    `}
`;

export const SearchHistory = styled(Lable)`
  margin-bottom: 0px;
  margin-top: ${(p) => (p.hasFetchError ? '40px' : p.hasValidateError ? '14px' : '8px')};
  cursor: pointer;
`;

export const PopupChildren = styled.div`
  min-height: 565px;
  width: 620px;
  padding: 32px;
`;

export const VinTitle = styled.div`
  font-size: 12px;
  line-height: 14px;
  color: ${PALETTE.grey13};
  margin-bottom: 16px;
`;

export const VinNumber = styled.div`
  font-size: 20px;
  line-height: 23.5px;
  font-weight: 500;
  padding-bottom: 32px;
`;

export const VinInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 100%;
  max-height: 400px;
`;

export const VinLineWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid ${PALETTE.grey9};
  padding: 16px 0 16px 0;
`;

export const LeftInfo = styled.div`
  font-size: 14px;
  line-height: 15.4px;
`;

export const RightInfo = styled.div`
  font-size: 14px;
  line-height: 16.4px;
  font-weight: 500;
`;

export const CheckWrapper = styled.div`
  width: 14px;
  height: 14px;
  margin-left: 5px;
`;

export const StyledUnCheckRed = styled(UnCheckVinRed)``;

export const StyledCheckGreen = styled(CheckVinGreen)``;

export const ApproveCreditButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  min-height: 40px;
  margin: 32px auto 16px;
  padding: 12px 32px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.4px;
  border: 1px solid ${PALETTE.red5};
  border-radius: 8px;
  background-color: ${PALETTE.red5};
  color: ${PALETTE.white};
  cursor: pointer;
`;

export const DealsContainer = styled.div`
  overflow-y: auto;
  scrollbar-width: thin;
`;

export const SearchInputWrapper = styled.div`
  width: 100%;
  height: 44px;
  padding: 10px 14px 14px 12px;
  background-color: ${PALETTE.grey9};
  border-radius: 7px;
  margin-bottom: 16px;
`;

export const SearchInput = styled.input`
  width: 90%;
  font-size: 14px;
  line-height: 16.4px;
  border: none;
  color: ${PALETTE.grey13};
  background-color: ${PALETTE.grey9};
  padding: 0;
  margin: auto 0;
`;

export const SearchWrapper = styled(VinInfoWrapper)``;

export const SearchContainer = styled(DealsContainer)`
  margin: 40px 10px 20px 20px;
`;

export const LoaderWrapper = styled.div`
  position: absolute;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 16px;
`;

export const DisabledVin = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 300px;
  width: 620px;
  padding: 70px;
`;

export const PopupStyled = styled(Popup)`
  & div > svg {
    padding: 14px;
  }
`;
