import * as Helper from './createBubblesArrayHelper';

export const cutFio = (fio = '') => {
  const [surname, name] = fio.split(' ');

  return `${surname || ''} ${name || ''}`.trim();
};

export const getKBMStr = (kbm) => {
  if (!kbm) {
    return '';
  }

  return `${kbm}`.replace(/\./g, ',');
};

export const createBubblesArray = (anketaState) => {
  const prepared = Helper.prepareData(anketaState);
  const result = Helper.createByPreparedData(prepared).filter(
    (el) => el.id !== anketaState.id && !el.isDeleted
  );

  return result;
};
