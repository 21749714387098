import React from 'react';
import PropTypes from 'prop-types';
import { ContainerStyled } from './style';

export const Container = (props) => {
  const { children, className } = props;

  return <ContainerStyled className={className}>{children}</ContainerStyled>;
};

Container.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
};

Container.defaultProps = {
  children: PropTypes.any,
  className: '',
};
