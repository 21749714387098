import React, { useContext } from 'react';
import { ListItem, FixedListItem, ErrorBlock } from './style';
import { Loader } from '../../../../primitives/loader';
import NavFloatDataContext from '../../NavFloatDataContext';

const cutStr = (str) => {
  const cut = str.slice(0, 48);

  return {
    equal: str === cut,
    str: str === cut ? str : `${cut}...`,
  };
};

const ListContent = ({
  error,
  isLoading,
  numberDealer,
  hoverDealer,
  arraySearchData,
  fixedListItemCoordinates,
  mouseEnterHandlerCreator = () => {},
  clickHandlerCreator = () => {},
  mouseLeaveHandler = () => {},
}) => {
  const navFloatDataObj = useContext(NavFloatDataContext);

  if (error.isError) {
    return <ErrorBlock>{`Error: ${error.stringMessage}`}</ErrorBlock>;
  }

  if (isLoading) {
    return <Loader show={true} color="white" />;
  }

  const hoverDealerElement = hoverDealer &&
    navFloatDataObj.active &&
    fixedListItemCoordinates &&
    !cutStr(hoverDealer.name).equal && (
      <FixedListItem
        selected={hoverDealer.id === numberDealer}
        x={fixedListItemCoordinates.x}
        y={fixedListItemCoordinates.y}
        onMouseLeave={mouseLeaveHandler}
        onClick={clickHandlerCreator(hoverDealer)}
      >
        {hoverDealer.name}
      </FixedListItem>
    );

  return (
    <>
      {arraySearchData.map((dealer) => {
        const cutObj = cutStr(dealer.name);

        return (
          <ListItem
            selected={dealer.id === numberDealer}
            withFullText={hoverDealer && hoverDealer.id === dealer.id && !cutObj.equal}
            key={dealer.id}
            onClick={clickHandlerCreator(dealer)}
            onMouseEnter={mouseEnterHandlerCreator(dealer)}
            title={dealer.name}
          >
            {cutObj.str}
          </ListItem>
        );
      })}
      {hoverDealerElement}
    </>
  );
};

export default ListContent;
