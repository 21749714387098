import React from 'react';

import * as S from '../style';

export const СonductionSpeed = ({ title, time = [] }) => {
  return (
    <S.СonductionSpeedWrapper>
      <S.TopWrap>
        <S.Title>{title}</S.Title>
        <S.ChangeTimeBlock>
          <S.GraphickArrowIco />
          {`- ${time[2]}`}
        </S.ChangeTimeBlock>
      </S.TopWrap>
      <S.TimeWrapper>
        <S.BigText>{time[0]}</S.BigText>
        <S.SmallText>мин</S.SmallText>
        {time[1] && (
          <>
            <S.BigText>{time[1]}</S.BigText>
            <S.SmallText>сек</S.SmallText>
          </>
        )}
      </S.TimeWrapper>
    </S.СonductionSpeedWrapper>
  );
};
