import styled from 'styled-components';
import { GraphickArrow } from 'assets/img';
import * as UK from 'components/primitives/content';
import { PALETTE } from 'styles/constants';

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;

  @media (max-width: 1200px) {
    width: 70%;
  }

  @media (max-width: 1000px) {
    width: 60%;
  }
`;

export const MetricaContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  gap: 16px;
`;

export const СonductionSpeedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 182px;
  width: 30%;
  margin-bottom: 16px;
  padding: 20px;
  border-radius: 16px;
  background-color: ${PALETTE.white};

  @media (max-width: 1200px) {
    width: 47%;
  }

  @media (max-width: 1000px) {
    width: 45%;
  }
`;

export const MinApprovalWrapper = styled(СonductionSpeedWrapper)``;

export const WineCheckWraper = styled(СonductionSpeedWrapper)`
  position: relative;

  width: ${(p) => (p.width ? p.width : '40%')};
  height: ${(p) => (p.height ? p.height : '')};
`;

export const Title = styled(UK.Title2)`
  font-weight: 500;
  line-height: 1.1;
  font-size: 14px;
  width: 170px;
  margin-bottom: 0px;

  @media (max-width: 1200px) {
    font-size: 0.8rem;
  }

  @media (max-width: 1100px) {
    font-size: 0.7rem;
  }

  @media (max-width: 1000px) {
    font-size: 0.6rem;
  }
`;

export const TimeWrapper = styled.div`
  display: flex;
  align-self: center;
  align-items: baseline;
  margin: auto 0;

  @media (max-width: 1200px) {
    font-size: 1.4rem;
  }

  @media (max-width: 1100px) {
    font-size: 1.2rem;
  }

  @media (max-width: 1000px) {
    font-size: 0.9rem;
  }
`;

export const BigText = styled.span`
  font-size: 48px;
  font-weight: 600;
  margin-right: 8px;
`;

export const SmallText = styled.span`
  font-size: 16px;
  font-weight: 400;
  margin-right: 8px;
  color: ${PALETTE.grey13};
`;

export const ChangeTimeBlock = styled.div`
  display: flex;
  align-items: center;
  padding: 4px;
  border-radius: 4px;
  background-color: ${PALETTE.green5};
  color: ${PALETTE.green6};
`;

export const TopWrap = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const GraphickArrowIco = styled(GraphickArrow)`
  margin-right: 4px;
`;
