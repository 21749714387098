import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { saveDealerId, saveRoleId } from 'data-providers/authProvider';
import Layout from './Layout';

const LayoutWithTheme = ({ theme: themeOverride, ...props }) => {
  const { dealer: dealerId } = useSelector((state) => state.user);
  const { role: roleId } = useSelector((state) => state.user);

  const themeProp = useRef(themeOverride);

  const [theme, setTheme] = useState(createTheme(themeOverride));

  useEffect(() => {
    if (themeProp.current !== themeOverride) {
      themeProp.current = themeOverride;
      setTheme(createTheme(themeOverride));
    }
  }, [themeOverride, themeProp, theme, setTheme]);

  useEffect(() => {
    if (dealerId) {
      saveDealerId(dealerId);
    }
  }, [dealerId]);

  useEffect(() => {
    if (roleId) {
      saveRoleId(roleId);
    }
  }, [roleId]);

  return (
    <ThemeProvider theme={theme}>
      <Layout {...props} />
    </ThemeProvider>
  );
};

LayoutWithTheme.propTypes = {
  theme: PropTypes.object,
};

LayoutWithTheme.defaultProps = {
  theme: {},
};

export default LayoutWithTheme;
