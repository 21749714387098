import React from 'react';
import { useSelector } from 'react-redux';

const ACTIVE_CLASS = 'report-page-menu__link-active';
const DEF_CLASS = 'submenu-link';

const linksData = [
  {
    title: 'Настройки аккаунта',
    to: `${process.env.REACT_APP_STAGE_URL}/#settings`,
    activeClass: ACTIVE_CLASS,
    excroles: [90, 91, 92, 93, 126, 127, 3197, 3213, 3370, 3406],
  },
  {
    title: 'Настройки комиссий',
    to: `${process.env.REACT_APP_STAGE_URL}/#settings`,
    activeClass: ACTIVE_CLASS,
    excroles: [90, 91, 3370],
  },
  {
    title: 'Настройки целевых показателей',
    to: `${process.env.REACT_APP_STAGE_URL}/#settings`,
    activeClass: ACTIVE_CLASS,
    excroles: [90, 91, 3370],
  },
  {
    title: 'Трампограмма',
    to: `${process.env.REACT_APP_STAGE_URL}/#settings`,
    activeClass: ACTIVE_CLASS,
    excroles: [90, 91, 3370],
  },
];

const createLinks = (role) =>
  linksData
    .filter((link) => !link.excroles.includes(role))
    .map((link) => (
      <a href={link.to} className={DEF_CLASS} key={link.title}>
        {link.title}
      </a>
    ));

const SettingsLink = () => {
  const role = useSelector((store) => store.user.role || -1);
  const links = createLinks(role);
  return (
    <>
      <div className="nav-group top"></div>
      <div className="nav-group middle"></div>
      <div className="nav-group bottom">{links}</div>
    </>
  );
};

export default SettingsLink;
