import React from 'react';
import PropTypes from 'prop-types';
import { Item, Wrapper } from './styles';

/**
 * Pagination
 * @param {string} className
 * @param {number} pages
 * @param {number} currentPage
 * @param {number} visiblePages
 * @param previousText
 * @param nextText
 * @param itemText
 * @param onClick
 * @param {string} flexPosition
 * @returns {*}
 * @constructor
 */
export const Pagination = ({
  className = '',
  pages = 1,
  currentPage = 1,
  visiblePages = 4,
  toFirst = null,
  previousText = null,
  nextText = null,
  toLast = null,
  itemText = () => {},
  onClick = () => {},
  leftShift = '',
  ItemElement = Item,
}) => {
  const handleClick = (page) => {
    if (page !== currentPage) {
      onClick(page);
    }
  };

  const showPage = (page) => {
    return (
      currentPage === page ||
      (page >
        currentPage -
          Math.ceil(visiblePages / 2) -
          (Math.ceil(visiblePages / 2) + currentPage > pages
            ? Math.abs(Math.ceil(visiblePages / 2) + currentPage - pages)
            : 0) &&
        page <
          currentPage +
            Math.ceil(visiblePages / 2) +
            (currentPage - Math.ceil(visiblePages / 2) < 0
              ? Math.abs(currentPage - Math.ceil(visiblePages / 2))
              : 0))
    );
  };

  return (
    <Wrapper className={className} leftShift={leftShift}>
      {toFirst && (
        <ItemElement disabled={currentPage === 0} clear={toFirst} onClick={() => handleClick(1)}>
          {toFirst}
        </ItemElement>
      )}
      {previousText && (
        <ItemElement
          disabled={currentPage <= 1}
          clear={previousText}
          onClick={() => handleClick(currentPage - 1)}
        >
          {previousText}
        </ItemElement>
      )}
      {[...new Array(pages)].map((p, index) => {
        const currentIndex = index + 1;
        const isActive = currentIndex === currentPage;

        return (
          showPage(currentIndex) && (
            <ItemElement
              active={isActive}
              clear={itemText({ item: currentIndex, active: isActive })}
              onClick={() => handleClick(currentIndex)}
              key={index}
            >
              {itemText({ item: currentIndex, active: isActive }) || currentIndex}
            </ItemElement>
          )
        );
      })}
      {nextText && (
        <ItemElement
          disabled={currentPage >= pages}
          clear={nextText}
          onClick={() => handleClick(currentPage + 1)}
        >
          {nextText}
        </ItemElement>
      )}
      {toLast && (
        <ItemElement
          disabled={currentPage === pages}
          clear={toLast}
          onClick={() => handleClick(pages)}
        >
          {toLast}
        </ItemElement>
      )}
    </Wrapper>
  );
};

Pagination.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.number,
  currentPage: PropTypes.number,
  visiblePages: PropTypes.number,
  toFirst: PropTypes.any,
  previousText: PropTypes.any,
  nextText: PropTypes.any,
  toLast: PropTypes.any,
  onClick: PropTypes.func,
};
