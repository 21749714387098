import { stringify } from 'query-string';

import request from 'utils/request';
import HttpError from 'utils/errors/HttpError';
import throwCustomError from 'utils/throwCustomError';

import {
  PRINT_DOCUMENT_URL,
  SUBSIDY_TYPES_URL,
  SUBSIDY_CALCULATION_URL,
  ISSUED_PRODUCTS_URL,
} from '../constants/url';

const calcCoreUrl = process.env.REACT_APP_CALC_CORE_URL;

export const fetchDataCalculation = async (params) => {
  Object.keys(params).forEach((key) => {
    if (key.startsWith('policyData')) {
      delete params[key];
    }
  });
  delete params.policyData;
  try {
    const url = `${calcCoreUrl}/v1/api/calc/all?${
      params
        ? `${stringify(params, {
            arrayFormat: 'bracket',
          })}`
        : ''
    }`;

    const results = await request.get(url, {
      headers: {
        'accept': 'application/json',
        'content-type': 'application/json',
      },
    });
    if (!results || (results.status && results.status !== 200)) {
      return null;
    }

    return results.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const fetchPartnerBanks = async (partnerId) => {
  try {
    const results = await request.get(`${calcCoreUrl}/v1/api/banks?dealer_id=${partnerId}`, {
      headers: {
        'accept': 'application/json',
        'content-type': 'application/json',
      },
    });
    if (!results || (results.status && results.status !== 200)) {
      return null;
    }

    return results.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const fetchBanksPriority = async (dealerId) => {
  try {
    const res = await request.get(`/api/dealers/${dealerId}/bank-priority`);

    if (res.status !== 200) {
      throw new HttpError(`status ${res.status}`);
    }
    if (!res.data) {
      return null;
    }

    return res.data;
  } catch (err) {
    console.error(err.message);
    throw err;
  }
};

export const fetchProducts = async (dealer) => {
  let url = `${process.env.REACT_APP_API_ENTRYPOINT}/api/dealer/config/${dealer}`;

  if (process.env.REACT_APP_MODE === 'dev') {
    url = `${process.env.REACT_APP_LOCAL_API_URL}/api/dealer/config/${dealer}`;
  }

  try {
    const stored = localStorage.getItem('token');

    if (stored) {
      const res = await fetch(url, {
        headers: {
          Authorization: `Bearer ${JSON.parse(stored).token}`,
        },
      });
      if (!res || !res?.ok) {
        return null;
      }

      const data = await res.json();
      if (!data || !data?.result) {
        return null;
      }

      return data;
    }
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const fetchDops = async (dealerId) => {
  try {
    const res = await request
      .get(`/api/products/dealer-products-others?dealerId=${dealerId}`)
      .catch(throwCustomError);

    if (res.status !== 200) {
      throw new HttpError(`status ${res.status}`);
    }
    if (!res.data) {
      return null;
    }

    return res.data;
  } catch (err) {
    console.error(err.message);
    throw err;
  }
};

export const getCalculatorParams = async (id) => {
  try {
    const url = `/api/order/loan-approval/${id}/last-calculation-data`;

    let res = await request.get(url).catch((error) => error?.response);

    if (res?.status !== 200) {
      res = await request.get(url).catch((error) => throwCustomError(error));
    }

    if (!res?.data?.payload) {
      return null;
    }

    return {
      calculationParams: res.data.payload?.calculationParams,
      calculationResult: res.data.payload?.calculationResult,
      selectedProgramId: res.data.payload?.selectedProgramId,
      clientState: res.data.payload?.clientState,
      applicationId: res.data.payload?.applicationId,
    };
  } catch (err) {
    console.error(err.message);
    throw err;
  }
};

export const approval = async (data) => {
  try {
    const res = await request
      .post('/api/order/loan-approval/calculation', data, { withCredentials: true })
      .catch(throwCustomError);

    if (res.status !== 200) {
      throw new HttpError(`status ${res.status}`);
    }

    return res.data || null;
  } catch (err) {
    console.error(err.message);
    throw err;
  }
};

export const fetchBankPdf = async (data) => {
  try {
    const res = await request
      .post(PRINT_DOCUMENT_URL, data, { withCredentials: true })
      .catch(throwCustomError);

    if (res.status !== 200) {
      throw new HttpError(`status ${res.status}`);
    }

    return res?.data || null;
  } catch (err) {
    console.error(err.message);
    throw err;
  }
};

export const fetchSubsidyTypes = async () => {
  try {
    const res = await request.get(SUBSIDY_TYPES_URL).catch(throwCustomError);

    if (res.status !== 200) {
      throw new HttpError(`status ${res.status}`);
    }

    return res?.data || null;
  } catch (err) {
    console.error(err.message);
    // throw err;
  }
};

export const fetchSubsidyCalculation = async (id, modelId, price) => {
  try {
    const res = await request
      .get(SUBSIDY_CALCULATION_URL(id, modelId, price))
      .catch(throwCustomError);

    if (res.status !== 200) {
      throw new HttpError(`status ${res.status}`);
    }

    return res?.data || null;
  } catch (err) {
    console.error(err.message);
    // throw err;
  }
};

export const logPicker = async (id, changeId) => {
  try {
    const res = await request
      .post(`/api/calculation/set-change-id/${id}/${changeId}`)
      .catch(throwCustomError);

    if (res.status !== 200) {
      throw new HttpError(`status ${res.status}`);
    }
  } catch (err) {
    console.error(err.message);
  }
};

export const getIssuedData = async (productId, applicationId) => {
  try {
    const res = await request
      .get(ISSUED_PRODUCTS_URL(productId, applicationId))
      .catch(throwCustomError);

    if (res.status !== 200) {
      throw new HttpError(`status ${res.status}`);
    }

    return res?.data || null;
  } catch (err) {
    console.error(err.message);
  }
};
