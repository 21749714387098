import styled from 'styled-components';

import { PALETTE } from 'styles/constants';
import { CameraFlatSvg, DownloadCloudSvg } from 'assets/img';
import { BlueButton } from 'components/primitives/content';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
`;

export const FileInput = styled.input`
  display: none;
`;

export const LoaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const IconWrapper = styled.label`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-direction: column;
`;

export const CameraIcon = styled(CameraFlatSvg)`
  width: 100%;
  height: auto;

  path {
    fill: ${PALETTE.blue5};
  }
`;

export const Error = styled.div`
  color: ${PALETTE.red1};
  position: relative;
  bottom: 40px;
  font-size: 30px;
  text-align: center;
`;

export const FilesList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px;
  font-size: 30px;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const UploadedFile = styled.a`
  margin-bottom: 20px;
  display: flex;
  width: 100%;
  text-decoration: underline;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
`;

export const CloudIcon = styled(DownloadCloudSvg)`
  margin-left: 15px;
  height: 100%;
  width: auto;
`;

export const UploadMore = styled(BlueButton)`
  font-size: 30px;
  height: auto;
  padding: 30px;
  white-space: nowrap;
`;
