import React from 'react';
import { useDispatch } from 'react-redux';
import { schemaCarDetail } from 'components/modules/Calculations/components/CarDetail/validator/validations';
import {
  updateErrors,
  dropErrors,
  scrollTo,
  openCarInstance,
  openCarInstanceDetails,
} from 'store/carInstance';

import * as CarDetailHelper from 'components/modules/Calculations/components/CarDetail/helper';
import * as ValidationHelper from 'components/modules/Calculations/components/CarDetail/validator/validationHelper';

const fn = () => {};

const WithValidateCarDetail = ({ WrappedComponent, props, schema = schemaCarDetail }) => {
  const rootDispatch = useDispatch();

  const validateCarDetail = async (
    carInstance,
    callback = fn,
    ignoredErrors = [],
    hiddenFields = [],
    ignoredErrorsExceptions = []
  ) => {
    const prepared = ValidationHelper.getCarInstanceForValidation(carInstance, hiddenFields);

    try {
      await schema.validate(prepared, { abortEarly: false });

      rootDispatch(dropErrors());
      callback();

      return true;
    } catch (err) {
      const objectedErrors = ValidationHelper.collectObjectedErrors(err);
      const objErrorsKeys = Object.keys(objectedErrors);

      const keys = objErrorsKeys.filter(
        ValidationHelper.createErrorsFilter(objectedErrors, ignoredErrors, ignoredErrorsExceptions)
      );

      rootDispatch(openCarInstance());
      const carDetailFields = [
        'enginePower',
        'engineNumber',
        'bodyNumber',
        'permittedMaxWeight',
        'engineVolume',
        'pts',
      ];
      const carDetailErrors = keys.filter((err) => carDetailFields.includes(err));
      if (carDetailErrors.length > 0) {
        rootDispatch(openCarInstanceDetails());
      }

      const blockName = CarDetailHelper.getBlockNameByFieldsNames(keys);

      const newErrors = objErrorsKeys.reduce(
        (acc, key) => {
          if (!keys.includes(key)) {
            if (typeof acc[key] === 'string') {
              return { ...acc, [key]: '' };
            }
            if (typeof acc[key] === 'object') {
              return {
                ...acc,
                [key]: Object.keys(acc[key]).reduce(
                  (innerAcc, innerKey) => ({ ...innerAcc, [innerKey]: '' }),
                  {}
                ),
              };
            }
          }

          return acc;
        },
        { ...objectedErrors }
      );

      if (!blockName) {
        rootDispatch(updateErrors(newErrors));
        callback();

        return true;
      }

      rootDispatch(scrollTo(blockName));
      rootDispatch(updateErrors(newErrors));

      return false;
    }
  };

  return <WrappedComponent {...props} validateCarDetail={validateCarDetail} />;
};

export default WithValidateCarDetail;
