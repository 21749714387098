import { SET_REFERENCE_BOOKS } from './constants';

const initState = {
  acquisitionList: [],
  carAcquisitionTypeList: [],
  carConditionList: [],
  characterPositionList: [],
  companyCountList: [],
  educationList: [],
  employerIndustryBranchList: [],
  employerNewTypeList: [],
  employerTypeList: [],
  employmentFormList: [],
  employmentTypeList: [],
  fioChangeReasonsList: [],
  genderList: [],
  legalFormList: [],
  martialStatusesList: [],
  positionTypeList: [],
  profitApprovedRusList: [],
  realtyObtainingList: [],
  realtyStateList: [],
  realtyTypeList: [],
  trusteeRelations: [],
  rolesList: [],
  secondDocumentTypesList: [],
  socialStatusList: [],
  spouseSocialStatusList: [],
};

export default function (state = initState, action) {
  switch (action.type) {
    case SET_REFERENCE_BOOKS:
      return action.payload;
    default:
      return state;
  }
}
