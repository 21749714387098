import {
  abstractAddressFromAPIToState,
  abstractAddressFromStateToAPI,
  abstractAddressInitialState,
} from './abstractAddress';
import { convertDate } from 'utils/dataMappers/mappers';

export const livingAddressInitialState = {
  addressLive: {
    ...abstractAddressInitialState,
    regDate: '',
  },
};

export const livingAddressFromAPIToState = (customer) => ({
  addressLive: {
    ...abstractAddressFromAPIToState(customer.livingAddress.address),
    regDate: convertDate(customer.livingAddress.livingFrom),
  },
});

export const livingAddressFromStateToAPI = (person) => ({
  livingAddress: {
    livingFrom: person.addressLive.regDate || '',
    address: abstractAddressFromStateToAPI(person.addressLive),
  },
});
