export const GET_CAR_INSTANCE = 'GET_CAR_INSTANCE';
export const SET_CAR_INSTANCE = 'SET_CAR_INSTANCE';
export const PATCH_CAR_INSTANCE = 'PATCH_CAR_INSTANCE';
export const SET_FETCH_CAR_INSTANCE = 'SET_FETCH_CAR_INSTANCE';
export const SET_ERROR_CAR_INSTANCE = 'SET_ERROR_CAR_INSTANCE';
export const UPDATE_ERRORS_CAR_INSTANCE = 'UPDATE_ERRORS_CAR_INSTANCE';
export const DROP_ERRORS_CAR_INSTANCE = 'DROP_ERRORS_CAR_INSTANCE';
export const DROP_ONE_ERROR_CAR_INSTANCE = 'DROP_ONE_ERROR_CAR_INSTANCE';
export const VALIDATE_CAR_INSTANCE = 'car-instance/validate';
export const FORCE_VALIDATE_CAR_INSTANCE = 'car-instance/force-validate-complete';
export const SET_PATCHIG_CAR_INSTANCE = 'car-instance/patching';
export const SCROLL_TO_INSTANCE = 'car-instance/scroll-to';
export const CLEAR_SCROLL_TO_INSTANCE = 'car-instance/clear-scroll-to';
export const CLEAR_CAR_INSTANCE = 'car-instance/clear';
export const UPDATE_CAR_INSTANCE_ID = 'car-instance/update-id';
export const GET_CAR_INSTANCE_BY_ID = 'car-instance/get-by-id';
export const SET_FETCH_CAR_INFO = 'cr-instance/set-fetch-car-info';
export const CHANGE_CAR_INSTANCE = 'car-instance/change';
export const PATCH_IS_NEW = 'car-instance/patch-is-new';
export const GET_DEALERS_PARAMS = 'car-instance/get-dealers-params';
export const CHANGE_DEALERS_PARAMS = 'car-instance/change-dealers-params';
export const SET_FETCH_DEALERS_PARAMS = 'car-instance/set-fetch-dealers-params';
export const SET_IMAGE = 'car-instance/set-image';
export const DECLINE_DROP_ERRORS = 'car-instance/decline-drop-errors';
export const OPEN_BLOCK = 'car-instance/open-block';
export const CLOSE_BLOCK = 'car-instance/close-block';
export const OPEN_DETAILS = 'car-instance/open-details';
export const CLOSE_DETAILS = 'car-instance/close-details';
export const DISABLE = 'car-instance/disable';
export const ENABLE = 'car-instance/enable';
export const SET_CAR_REQUIRED_FIELDS = 'car-instance/set-car-required-fields';
export const SET_CAR_HIDDEN_FIELDS = 'car-instance/set-car-hidden-fields';
export const SET_CAR_DISABLE_FIELDS = 'car-instance/set-car-disable-fields';
export const DELETE_CAR_DISABLE_FIELDS = 'car-instance/reset-car-disable-fields';

export const PHASES = ['brand', 'model', 'version'];

export const FIELDS = {
  brand: 'brand',
  model: 'model',
  version: 'version',
  isNew: 'isNew',
  year: 'year',
  price: 'price',
  offerPrice: 'offerPrice',
  enginePower: 'enginePower',
  engineNumber: 'engineNumber',
  engineVolume: 'engineVolume',
  bodyNumber: 'bodyNumber',
  permittedMaxWeight: 'permittedMaxWeight',
  mileage: 'mileage',
  carIssueDate: 'carIssueDate',
  glonasNumber: 'glonasNumber',
  vin: 'vin',
  pts: {
    series: 'pts.series',
    number: 'pts.number',
    issueDate: 'pts.issueDate',
  },
  diagnosticCard: {
    beginDate: 'diagnosticCard.beginDate',
    endDate: 'diagnosticCard.endDate',
    number: 'diagnosticCard.number',
  },
  catalogBlock: 'catalogBlock',
  contract: {
    number: 'contract.number',
    date: 'contract.date',
  },
  recipient: 'recipient',
  invoice: {
    number: 'invoice.number',
    date: 'invoice.date',
  },
  bankName: 'bankName',
  bic: 'bic',
  accountNumber: 'accountNumber',
  inn: 'inn',
  ndsFlag: 'ndsFlag',
  ndsAmount: 'ndsAmount',
};
