import React, { useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CarForm from './components/CarForm';
import Insurer from './components/Insurer';
import { Loader } from 'components/primitives/loader';
import ErrorPopup from 'components/primitives/error-popup';
import { schemaInsuranceNew, schemaOnlyInsurerNew } from './vlidator/validations';
import { collectObjectedErrors } from './vlidator/vlidator';
import {
  assistanceDropAllErrors,
  assistanceSetErrors,
  assistanceGetCustomersId,
  assistanceSetNetworkError,
  assistanceSetError,
  assistanceDeleteError,
  assistanceScrollToCar,
  assistanceClearScroll,
  assistanceSwitchCalcOption,
  assistanceSetEnabled,
  assistanceSetDisabled,
  aswAnketaSetMainFetch,
  assistancePatchCarInstance,
} from 'store/assistanceAnketa';
import {
  setCarInstance,
  updateErrors,
  getCarInstanceById,
  setCarRequiredFields,
  setCarHiddenFields,
  enableCarInstance,
  disableCarInstance,
  deleteCarDisableFields,
} from 'store/carInstance';
import { FIELDS } from 'store/carInstance/constants';
import { getProfile } from 'store/anketa';
import { GLOBAL_ANKETA_CALC_OPTIONS } from 'constants/index';

import * as ValidationHelper from './vlidator/vlidator';
import * as PositionHelper from 'utils/documentPosition';
import * as ScrollHelper from './helpers/scrollHelper';
import * as S from './style';
import { createProductData, isNeedDisableASWFieldsByOrders } from './helpers/helper';
import { isNeedDisableASWFields } from '../Assistance/helper';
import {
  aswCalculateRequest,
  getAssistanceCalcApplicatioIdByOrders as createWorksheet,
} from 'data-providers/assistanceCalculationProvider';
import { createASWRequestParams } from 'data-providers/helper';
import {
  ASSISTANCE_CALC_SET_ID,
  ASSISTANCE_CALC_SET_ERROR,
} from 'store/assistanceCalculation/constants';
import { frontToBackCustomer } from 'store/assistanceAnketa/frontToBackMapper';
import { patchCustomer } from 'data-providers/assistanceAnketaProvider';
import { aswRefreshCalculationsByAppsIds } from 'store/assistanceCalculation';
import { getWorksheetById } from 'data-providers/applicationsProvider';
import { ASW_STATES } from 'store/assistanceCalculation/resources';
import { storeItem } from 'store/startPage';

const fn = () => {};

const carRequiredFields = [
  FIELDS.brand,
  FIELDS.model,
  FIELDS.year,
  FIELDS.price,
  FIELDS.enginePower,
  FIELDS.pts.number,
  FIELDS.pts.issueDate,
  FIELDS.mileage,
];

const AssistanceAnketa = ({ id, validateCarDetail = fn }) => {
  const rootDispatch = useDispatch();
  const history = useHistory();
  const params = useParams();

  const isCashCustomer = window.location.href.includes('/assistance/anketa');

  const { carInstance } = useSelector((state) => state.carInstance);
  const carInstanceId = carInstance?.id;
  const dealerId = useSelector((state) => state.user.dealer);
  const creditId = useSelector((state) => state.startPage?.application?.ids?.credit);
  const initialCustomer = useSelector(({ startPage }) => startPage?.customer || {});
  const storedAssistanceData = useSelector((state) => state.assistanceAnketa);
  const { applications, workSheetId } = useSelector((state) => state.assistanceCalculation);
  const startPageApplication = useSelector((state) => state.startPage?.application);
  const orders = useSelector((state) => state.startPage?.application?.orders);
  const assistanceOrders = useMemo(() => {
    return (orders || []).filter((order) => order.productId === 'assistance');
  }, [orders]);

  const { mainFetch, networkError, errors, calcOption } = storedAssistanceData;
  const isDisabledAnketaFields = storedAssistanceData.isDisabled;
  const isCreatingNewWorksheet = params.id === 'create-new' || !assistanceOrders.length;

  const carDetailValidateHiddenFields = useMemo(() => {
    const fields = [];
    if (calcOption === GLOBAL_ANKETA_CALC_OPTIONS[1]) {
      fields.push(FIELDS.vin);
      fields.push(FIELDS.pts.number);
      fields.push(FIELDS.pts.issueDate);
    }
    return fields;
  }, [calcOption]);

  const redirectToASWCalc = (workSheetId) => {
    history.push(`/worksheet/${workSheetId}/assistance`);
  };

  useEffect(() => {
    PositionHelper.scrollTo(0, 0);
    rootDispatch(assistanceSwitchCalcOption(GLOBAL_ANKETA_CALC_OPTIONS[0]));

    rootDispatch(setCarRequiredFields(carRequiredFields));

    return () => {
      rootDispatch(setCarRequiredFields([]));
      rootDispatch(setCarHiddenFields([]));
      rootDispatch(enableCarInstance());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fields = [
      FIELDS.engineNumber,
      FIELDS.bodyNumber,
      FIELDS.permittedMaxWeight,
      FIELDS.engineVolume,
      FIELDS.glonasNumber,
      FIELDS.offerPrice,
    ];
    if (calcOption === GLOBAL_ANKETA_CALC_OPTIONS[1]) {
      fields.push(FIELDS.vin);
      fields.push(FIELDS.pts);
    }
    rootDispatch(setCarHiddenFields(fields));
    rootDispatch(deleteCarDisableFields([FIELDS.version]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calcOption]);

  useEffect(() => {
    if (creditId) {
      rootDispatch(getProfile(creditId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creditId]);

  useEffect(() => {
    // блокировка/разблокировка полей CarInstance и анкеты
    const aswCalcApplicationsArr = Object.values(applications);

    // Если пользователь сразу открыл ссылку на анкету или обновил эту страницу,
    // то applications будет пустым и нет необходимости запрашивать данные по ним,
    // т.к. все нужные для блокировки полей данные можно взять из orders.
    // Если он перешёл в анкету из расчёта, то берём актуальную информацию
    // по заявкам из applications
    const needDisableFields = aswCalcApplicationsArr.length
      ? isNeedDisableASWFields(aswCalcApplicationsArr)
      : isNeedDisableASWFieldsByOrders(assistanceOrders);

    rootDispatch(needDisableFields ? disableCarInstance() : enableCarInstance());
    rootDispatch(needDisableFields ? assistanceSetDisabled() : assistanceSetEnabled());

    // Получение данных кастомера
    const assistanceApplicationId = assistanceOrders[0]?.body?.applicationId;
    const insuranceApplicationId = (orders || []).find((order) => order.productId === 'insurance')
      ?.body?.applicationId;

    const { applicationId, product } = createProductData(
      assistanceApplicationId,
      insuranceApplicationId,
      creditId
    );

    if (applicationId && product) {
      rootDispatch(assistanceGetCustomersId({ applicationId, product }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applications, orders, isDisabledAnketaFields]);

  // only for new route
  const leaveAnketa = (id) => {
    const _carInstanceId = carInstanceId || localStorage.getItem('carinstanceId');

    if (_carInstanceId) {
      rootDispatch(getCarInstanceById(_carInstanceId));
    }

    redirectToASWCalc(id || workSheetId || params.id);
  };

  const validateInsurer = async ({ value, state }) => {
    const key = Object.keys(value)[0];

    const dropError = () => {
      rootDispatch(assistanceDeleteError({ key }));
    };

    const setError = (currentErrors) => {
      rootDispatch(assistanceSetError({ key, value: currentErrors[key] }));
    };

    const schemaInsurer = schemaOnlyInsurerNew;

    return ValidationHelper.validate(schemaInsurer, state, dropError, setError);
  };

  const scrollHandler = () => {
    rootDispatch(assistanceClearScroll());
  };

  const validateBeforeSave = async () => {
    const schema = schemaInsuranceNew;

    try {
      await schema.validate(storedAssistanceData, { abortEarly: false });

      rootDispatch(assistanceDropAllErrors());

      return null;
    } catch (err) {
      const result = { ...errors, ...collectObjectedErrors(err) };
      rootDispatch(assistanceSetErrors({ errors: result }));
      return result;
    }
  };

  const getCalculationHandler = async () => {
    const isValid = await validateCarDetail(carInstance, fn, [], carDetailValidateHiddenFields);

    const errorObject = await validateBeforeSave();
    const blockName = ScrollHelper.getBlockNameByErrors(errorObject, [], []);

    if (!isValid) {
      return redirectToASWCalc(workSheetId || params.id);
    }

    if (blockName) {
      rootDispatch(assistanceScrollToCar(blockName));
    }

    if (errorObject || blockName || !id) {
      return;
    }

    rootDispatch(aswAnketaSetMainFetch(true));

    let applicationIdFromCreatedWorksheet = null;
    let createdWorksheetId = null;

    if (isCreatingNewWorksheet) {
      const { applicationId, clientId, id, workSheetId } = await createWorksheet({
        dealerId,
        carInstanceId,
        customerId: initialCustomer.id,
      });

      applicationIdFromCreatedWorksheet = applicationId;
      createdWorksheetId = workSheetId;

      rootDispatch({
        type: ASSISTANCE_CALC_SET_ID,
        payload: { applicationId, clientId, id, workSheetId },
      });
    }

    rootDispatch(assistancePatchCarInstance(carInstanceId));

    const aswCustomer = frontToBackCustomer({ ...storedAssistanceData }, initialCustomer);
    // перед calculate обязательно надо запатчить кастомера.
    // В случае с asw, пустой кастомер с корректным id уже есть в startPage
    await patchCustomer(aswCustomer);

    const actionAfterCalculate = (workSheetId = params.id) => {
      redirectToASWCalc(workSheetId);
      rootDispatch(aswAnketaSetMainFetch(false));
    };

    if (isCreatingNewWorksheet) {
      await aswCalculateRequest(
        createASWRequestParams(applicationIdFromCreatedWorksheet, {
          policyStartDate: new Date().toISOString(),
        })
      ).catch((err) => {
        rootDispatch({
          type: ASSISTANCE_CALC_SET_ERROR,
          payload: {
            key: 'creatingWorksheet',
            value: err?.errorTraefikData?.message || err?.customDescription || 'Ошибка расчёта',
          },
        });
      });

      // Временный хотфикс CREDIT-2931
      const { orders } = await getWorksheetById(createdWorksheetId);
      rootDispatch(
        storeItem({
          ...startPageApplication,
          orders,
        })
      );

      actionAfterCalculate(createdWorksheetId);
    } else {
      const aswCalcApplicationsArr = Object.values(applications);
      const aswAppsListIsEmpty = !aswCalcApplicationsArr.length;
      const excludedStateNames = new Set([ASW_STATES.issued, ASW_STATES.client_decline]);

      const applicationsIdsList = aswAppsListIsEmpty
        ? assistanceOrders
            .filter((el) => !excludedStateNames.has(el.body?.applicationState.sysName))
            .map((el) => el.body?.applicationId)
        : aswCalcApplicationsArr
            .filter((el) => !excludedStateNames.has(el.currentWorkflowState))
            .map((el) => el.id);

      rootDispatch(aswRefreshCalculationsByAppsIds(applicationsIdsList, actionAfterCalculate));
    }
  };

  const onSubmitBtnClickAction = isDisabledAnketaFields
    ? () => {
        redirectToASWCalc(params.id);
      }
    : getCalculationHandler;

  const errorCloseHandler = () => {
    rootDispatch(assistanceSetNetworkError());
  };

  const closeHandler = () => {
    if (isCashCustomer) {
      return leaveAnketa();
    }

    if (workSheetId) {
      redirectToASWCalc(workSheetId);
    }
  };

  if (mainFetch) {
    return <Loader show={true} color="white" />;
  }

  return (
    <S.Wrapper>
      <S.CloseButton onClick={closeHandler} />
      <CarForm
        id={id}
        isFullCalc={calcOption === GLOBAL_ANKETA_CALC_OPTIONS[0]}
        onClickFullCalc={({ fullCalculation }) => {
          const itemSwitch = GLOBAL_ANKETA_CALC_OPTIONS[Number(!fullCalculation)];

          rootDispatch(assistanceSwitchCalcOption(itemSwitch));

          if (itemSwitch === GLOBAL_ANKETA_CALC_OPTIONS[1]) {
            rootDispatch(
              setCarInstance({
                pts: { ...carInstance.pts },
                vin: carInstance.vin,
              })
            );
            rootDispatch(
              updateErrors({
                pts: { series: '', number: '', issueDate: '' },
                vin: '',
              })
            );
          }
        }}
      />
      <Insurer
        anketaState={storedAssistanceData}
        onScroll={scrollHandler}
        validate={validateInsurer}
      />
      <S.SubmitButtonsWrapper>
        <S.SubmitButton onClick={onSubmitBtnClickAction}>
          {isDisabledAnketaFields ? 'Вернуться на расчёт' : 'Оформить'}
        </S.SubmitButton>
      </S.SubmitButtonsWrapper>
      {!!networkError && <ErrorPopup errorMessage={networkError} onClose={errorCloseHandler} />}
    </S.Wrapper>
  );
};

export default AssistanceAnketa;
