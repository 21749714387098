import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch, useHistory } from 'react-router-dom';

import { providerRoles } from 'constants/index';

import * as S from './style';

export const Navigation = () => {
  const history = useHistory();
  let { url } = useRouteMatch();

  const isAuth = useSelector((store) => store.user.isAuth);
  const userRole = useSelector((store) => store.user.role);

  useEffect(() => {
    if (!isAuth || !userRole) {
      return;
    }
    if (!providerRoles.includes(userRole)) {
      history.push('/applications/list');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRole]);

  return (
    <S.Container>
      <S.Link to={`${url}/all`}>Все реквизиты</S.Link>
      <S.Link to={`${url}/car`}>За автомобиль, услуги и оборудование</S.Link>
      <S.Link to={`${url}/insurance`}>За страховки</S.Link>
    </S.Container>
  );
};
