import request from 'utils/request';

export const fetchAutocode = async (params) => {
  try {
    const baseUrl = `${process.env.REACT_APP_TRAEFIK_URL}`;
    let res = await request.post(`${baseUrl}/api/v1/car/details`, params, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    });

    return res.status === 202 || null;
  } catch (err) {
    console.error(err.message);
    throw err;
  }
};
