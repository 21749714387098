export const TOGGLE_OPEN = 'applications/toggle-open';
export const APPLICATIONS_LOAD = 'applications/applications-load';
export const APPLICATIONS_LOADED = 'applications/applications-loaded';
export const APPLICATION_RELOAD = 'applications/application-reload';
export const SEARCH_APPLICATIONS_LOAD = 'applications/search-applications-load';
export const SEARCH_APPLICATIONS_LOADED = 'applications/search-applications-loaded';
export const CHANGE_PARAMS = 'applications/change-params';
export const CHANGE_SEARCH_PARAMS = 'applications/change-search-params';
export const APPLICATIONS_SET_FETCH = 'applications/set-fetch';
export const APPLICATIONS_ADDED = 'applications/applications-add';
export const SEARCH_APPLICATIONS_ADDED = 'applications/search-applications-add';
export const APPLICATIONS_SET_SEARCH_FETCH = 'applications/set-search-fetch';
export const UPDATE_APPLICATION_ITEM = 'applications/update-application-item';
export const EXPAND_APPLICATION = 'applications/expand-application';
export const RELOAD_EXPANDED_APPLICATION = 'applications/reload-expanded-application';
export const DROP_APPLICATIONS = 'applications/drop-applications';
export const POST_APPLICATION_COMMENT = 'applications/post-application-comment';
export const SET_APPLICATION_PREVPATH = 'applications/set-application-prevpath';
export const APPLICATIONS_CLOSE_REASONS_CATALOG_LOADED =
  'applications/applications-close-reasons-catalog-loaded';
export const DROP_LIST_APPLICATIONS = 'applications/drop-list-application';
export const POST_APPLICATION_COMMENT_STORE = 'applications/post-application-comment-store';
export const GET_DETAIL_PARAMS = 'applications/get-detail-params';
export const CHANGE_DETAIL_PARAMS = 'applications/change-detail-params';
export const CREDIT_APPROVEDS_DATA = 'applications/credit-approved-data';
export const CREDIT_APPROVED_DATA_LOAD = 'applications/approved-data-load';
export const CAR_INSTANCE_DATA_LOAD = 'applications/car-data-load';
export const CAR_INSTANCE_DATA = 'applications/car-instance-data';
