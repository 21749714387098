import React from 'react';
import PropTypes from 'prop-types';
import { DeleteGreySvg } from 'assets/img';
import * as S from './style.js';

export const FormHeader = ({ onAction = null, title, payload }) => {
  const actionHandler = () => {
    onAction && onAction(payload);
  };

  return (
    <S.HeaderContainer>
      <S.Header>{title}</S.Header>

      {onAction && (
        <S.ActionButton onClick={actionHandler}>
          <DeleteGreySvg />
        </S.ActionButton>
      )}
    </S.HeaderContainer>
  );
};

FormHeader.propTypes = {
  onAction: PropTypes.oneOfType([PropTypes.func, PropTypes.oneOf([null])]),
  title: PropTypes.string,
  payload: PropTypes.element,
};
