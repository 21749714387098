import styled from 'styled-components';
import { PALETTE } from 'styles/constants';

export const Wrapper = styled.div`
  position: absolute;
  top: 100%;
  width: 100%;
  border: 1px solid ${PALETTE.grey2};
  border-top: 0;
  max-height: 200px;
  overflow-x: hidden;
  background-color: ${PALETTE.white};
  z-index: 100 !important;
`;

export const SelectButton = styled.button`
  width: 100%;
  border: 0;
  padding: 8px 0;
  padding-left: 13px;
  font-size: 12px;
  text-align: left;
  line-height: 150%;
  background-color: ${PALETTE.white};
  cursor: pointer;
  border: 0;
  text-align: left;
  &:focus,
  &:hover {
    background-color: #e7e7e7;
  }
`;

export const PseudoButton = styled.button`
  position: absolute;
  width: 1px;
  height: 1px;
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
`;
