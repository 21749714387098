import React from 'react';
import { InputMask } from 'utils/inputMask';
import { DaDataInput } from './style';

export default function DaDataFio({
  label = 'Фамилия, имя, отчество',
  required = true,
  mask = InputMask.RUS_NAME_NEW,
  structure = ['fullName', 'lastName', 'firstName', 'middleName'],
  dataTest = 'daDataFio',
  changeHandler = () => {},
  ...rest
}) {
  const dadataChangeHandler = (event) => {
    return changeHandler({ ...event, fromDadata: event.fromDadata || false });
  };

  return (
    <DaDataInput
      label={label}
      required={required}
      mask={mask}
      structure={structure}
      dataTest={dataTest}
      changeHandler={dadataChangeHandler}
      {...rest}
    />
  );
}
