import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { PulseLoader } from 'react-spinners';
import { convertPriceToString } from 'utils/convertPrice';

import {
  CarDetailCell,
  DetailItemCarName,
  DetailItemParamsRow,
  DetailParamCell,
  WerticalWraper,
} from './style';
import { convertValueToStandartNumber, getCarName, isShowCarYear } from '../../helpers';
import DetailItemCell from './DetailItemCell';

const loader = <PulseLoader size={10} color={'#b5b5b5'} loading={true} />;

const DetailItemParams = ({ application }) => {
  const initialState = { vinLoad: true, praceLoad: true };

  const [isLoading, setIsLoading] = useState(initialState);
  const dealId = application.ids.credit;
  const carlId = application?.carInstance?.id;

  const itemParams = useSelector((store) => store?.applications?.detailParams[dealId]?.data);
  const carInstance = useSelector((store) => store?.applications?.carInstanceData[carlId]);

  const price = convertValueToStandartNumber(itemParams?.price);
  const initialFeeMoney = convertValueToStandartNumber(itemParams?.initial_fee_money);
  const payment = convertValueToStandartNumber(itemParams?.payment);
  const dealerDopSum = convertValueToStandartNumber(parseFloat(itemParams?.dealer_dop_sum));
  const bankDopSum = convertValueToStandartNumber(itemParams?.bank_dop_sum);

  const carName = getCarName(application?.carInstance);
  const year = carInstance?.data.year;
  const vin = carInstance?.data.vin;
  const carPrice = convertPriceToString(carInstance?.data?.offerPrice);
  const isShowYear = isShowCarYear(carInstance?.data);

  useEffect(() => {
    const checks = [
      { variable: vin, flagName: 'vinLoad' },
      { variable: carPrice, flagName: 'praceLoad' },
    ];

    const updatedLoadingState = checks.reduce(
      (prev, { variable, flagName }) => {
        if (variable !== undefined) {
          prev[flagName] = false;
        }
        return prev;
      },
      { ...initialState }
    );

    setIsLoading(updatedLoadingState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vin, carPrice]);

  return (
    <>
      <DetailItemParamsRow hasCarYear={isShowYear}>
        <CarDetailCell>
          <DetailItemCell label={'Запрошено'} />

          <DetailItemCarName txtCap={true} black={true}>
            <span>{`${carName.brand} ` || ''}</span>
            <span style={{ whiteSpace: 'nowrap' }}>{carName.model || ''}</span>
            <span>
              {year ? ', ' : ''} {year || ''}
            </span>
          </DetailItemCarName>

          {isLoading.praceLoad ? (
            loader
          ) : (
            <DetailItemCell
              label={'Стоимость:'}
              value={carPrice ? `${carPrice} ₽` : '-'}
              black={true}
            />
          )}
          {isLoading.vinLoad
            ? loader
            : vin && <DetailItemCell label={'VIN:'} value={vin} black={true} wordBreak={true} />}
        </CarDetailCell>

        <WerticalWraper>
          <DetailParamCell>
            Сумма <br /> кредита
          </DetailParamCell>
          <DetailParamCell>{price}</DetailParamCell>
        </WerticalWraper>

        <WerticalWraper>
          <DetailParamCell>
            Первоначальный <br /> взнос
          </DetailParamCell>
          <DetailParamCell>{initialFeeMoney || '—'}</DetailParamCell>
        </WerticalWraper>

        <WerticalWraper>
          <DetailParamCell>Срок, мес. </DetailParamCell>
          <DetailParamCell>{itemParams?.period || '—'}</DetailParamCell>
        </WerticalWraper>

        <WerticalWraper>
          <DetailParamCell>Ставка </DetailParamCell>
          <DetailParamCell>{itemParams?.interest_rate || '—'}</DetailParamCell>
        </WerticalWraper>

        <WerticalWraper>
          <DetailParamCell>
            Ежемесячный <br /> платеж
          </DetailParamCell>
          <DetailParamCell>{payment || '—'}</DetailParamCell>
        </WerticalWraper>

        <WerticalWraper>
          <DetailParamCell>
            Фин. продукты <br /> дилера
          </DetailParamCell>
          <DetailParamCell>{dealerDopSum}</DetailParamCell>
        </WerticalWraper>

        <WerticalWraper>
          <DetailParamCell>
            Фин. продукты <br /> банка
          </DetailParamCell>
          <DetailParamCell>{bankDopSum === '0' ? '—' : bankDopSum}</DetailParamCell>
        </WerticalWraper>

        <WerticalWraper>
          <DetailParamCell>
            Макс. сумма <br /> кредита
          </DetailParamCell>
          <DetailParamCell>{'—'}</DetailParamCell>
        </WerticalWraper>

        {isShowYear && (
          <WerticalWraper>
            <DetailParamCell>
              Макс. год <br /> выпуска
            </DetailParamCell>
            <DetailParamCell>{'—'}</DetailParamCell>
          </WerticalWraper>
        )}
      </DetailItemParamsRow>
    </>
  );
};

export default DetailItemParams;
