import { onlyNumber } from 'utils/stringUtils';

export const hasAnketaErrors = (anketaErrors) => {
  if (!anketaErrors) {
    return false;
  }

  const errors = Object.keys(anketaErrors).map((f) => f.replaceAll(/\[\d\]/gu, ''));
  return errors.some((e) => {
    return (
      e.includes('personalInfo.fullName') || e.includes('addressReg') || e.includes('passport')
    );
  });
};

export const getCustomer = (anketaData) => {
  if (!anketaData?.currentPersonId) {
    return null;
  }

  return (anketaData.persons || {})[anketaData.currentPersonId] || null;
};

export const saveDocument = (url) => {
  const link = document.createElement('a');
  link.href = url;
  link.click();
};

export const checkCustomer = (customer) =>
  !!customer?.contacts?.mobilePhone && !!customer?.personalInfo?.fullName;

export const getSmsParams = (customer) => ({
  phone: onlyNumber(customer?.contacts?.mobilePhone || ''),
  fullName: customer?.personalInfo?.fullName || '',
  inn: customer?.secondDocument?.taxRegistration?.inn || '',
  snils: customer?.secondDocument?.snils?.number || '',
  serieAndNumber: customer?.passport?.serianumber || '',
  issuedBy: customer?.passport?.issuer || '',
  issuedDate: customer?.passport?.issueDate || '',
  subdivisionCode: customer?.passport?.issuerCode || '',
  dateOfBirth: customer?.personalInfo?.birthDate || '',
  placeOfBirth: customer?.passport?.birthPlace || '',
  registrationAddress: customer?.addressReg?.fiasValue || '',
});

export const calculateRemainingTime = (startTime = 0, duration = 0) => {
  const currentTime = Math.floor(Date.now() / 1000);
  const elapsedTime = currentTime - startTime;
  return Math.max(duration - elapsedTime, 0);
};
