import { INSURE_GET, INSURE_SET, INSURE_UPDATE } from './constants';

const insuranceState = {
  values: {
    // начальные значения формы
    new: true,
    creditCar: false,
    useAntiTheftSystem: false, // назначать когда будет апи по противоугонкам
    antiTheftSystemModel: '', // выпилить когда будет апи
    usageMonthsPerYear: 12,
    driversInfo: {
      amount: 1,
      drivers: [
        {
          id: 1,
          gender: 0,
          owner: true,
          insurer: true,
        },
      ],
    },

    // не используемые в форме
    bodyNumber: '',
    chassisNumber: '',
    prolongation: false, // Признак пролонгации
    previousContractNumber: '', // Номер предыдущего договора КАСКО
    previousPolicyFromOtherInsCompanyExpirationDate: '', // Дата окончания действия полиса в другой страховой компании
    previousPolicyScan: '', // Скан действующего полиса в формате base64
    referenceCollect: false, // Сбор справок
    noReference: false, // Без справок

    // additions (изменить значение когда раскидается на блоки)
    theft: true,
    damage: true,
    gap: true,
    tires: true,
    accident: true,
    paintwork: true,
    fallenObjects: true,
    evacuation: true,
    selfIgnition: true,
    disaster: true,
    stealing: true,
    autolaunch: false,
    registrationKladr: '7700000000000',
    gettingKladr: '7700000000000',

    // излишки от формы
    multidrive: false,

    // token потом выпилить
    params: {
      key: 'lhkasdlkh134lkh123lkk',
    },
  },

  offers: [],
  driversAtInsurance: {},
  kaskoRisk: null,
  loading: false,
  errors: null,
};

export default function (state = insuranceState, action) {
  switch (action.type) {
    case INSURE_GET:
      return { ...state, ...action.payload };
    case INSURE_SET:
      return { ...state, ...action.payload };
    case INSURE_UPDATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
