import React, { useState, Suspense, lazy, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, Route, useRouteMatch, useHistory, useParams } from 'react-router-dom';
import cn from 'classnames';
import animateScrollTo from 'animated-scroll-to';

import {
  selectCustomerId,
  selectDealerId,
  selectAssistanceClientId,
} from 'store/assistanceCalculation/selectors';
import { selectInsuranceClientId } from 'store/insuranceCalculation/selectors';
import { isUseNewCalculator } from 'store/user/selectors';
import { patchCarInstance, clearScroll } from 'store/carInstance';
import { CarPreloaderGif, SovkombankStrakhovanie } from 'assets/img';
import Status from 'components/primitives/policy/status';
import { BLOCK_TABS_SELECT } from '../Calculation/constants';

import { tabOpenTrack } from 'metrika/eosago';

import { Wrapper, Header, Tab, ServicesWrapper, Loader, TabState, TabLogo } from './style';
import { createNewParams, getCurrentTab, getOrderStates } from './helper';
import { TabsHeaders } from './resources';
import { ASW_STATES } from 'store/assistanceCalculation/resources';
import { EOW_STATES } from 'store/insuranceCalculation/resources';

const AssistanceComponent = lazy(() => import('../AssistanceWrapper'));
const InsuranceComponent = lazy(() => import('../InsuranceWrapper'));
const CreditComponent = lazy(() => import('../CreditWrapper'));

const getLazyComponentByIndex = (index, props = {}) => {
  switch (index) {
    case 0: {
      return <CreditComponent {...props} />;
    }
    case 1: {
      return <AssistanceComponent {...props} />;
    }
    case 2: {
      return <InsuranceComponent {...props} />;
    }
    default:
      return null;
  }
};

const Tabs = ({ eosagoRef }) => {
  const ref = useRef();
  const rootDispatch = useDispatch();
  const { path } = useRouteMatch();
  const params = useParams();

  const dealerId = useSelector(selectDealerId);
  const customerId = useSelector(selectCustomerId);
  const { carInstance, scrollTo } = useSelector((state) => state.carInstance);
  const { application = {} } = useSelector((state) => state.startPage);
  const assistanceCalculation = useSelector((state) => state.assistanceCalculation);
  const insuranceCalculation = useSelector((state) => state.insuranceCalculation);
  const assistanceClientId = useSelector(selectAssistanceClientId);
  const insuranceClientId = useSelector(selectInsuranceClientId);
  const isCalculator = useSelector(isUseNewCalculator);
  const deal = useSelector((store) => store.deal);
  const initialParams = [carInstance.id, customerId, dealerId];

  const [currentTab, setCurrentTab] = useState(getCurrentTab(path));

  const [isPushToAssistance, setIsPushToAssistance] = useState(false);
  const [isPushToInsurance, setIsPushToInsurance] = useState(false);
  const [assistanceQueryParams, setAssistanceQueryParams] = useState(
    createNewParams(...initialParams)
  );
  const [insuranceQueryParams, setInsuranceQueryParams] = useState(
    createNewParams(...initialParams)
  );

  const history = useHistory();

  useEffect(() => {
    if (
      [
        '/worksheet/create-new/calculator',
        '/worksheet/:id/home',
        '/worksheet/:id/calculator',
        '/worksheet/:id/loan-issue',
      ].includes(path)
    ) {
      setIsPushToAssistance(false);
      setIsPushToInsurance(false);
      setCurrentTab(1);
    } else if (['/worksheet/:id/assistance', '/worksheet/create-new/assistance'].includes(path)) {
      setIsPushToInsurance(false);
      setCurrentTab(2);
    } else if (['/worksheet/:id/insurance', '/worksheet/create-new/insurance'].includes(path)) {
      setCurrentTab(3);
    } else if (path.includes('/create-new')) {
      setIsPushToAssistance(false);
      setCurrentTab(0);
    }
  }, [path]);

  useEffect(() => {
    if (isPushToAssistance && assistanceClientId) {
      rootDispatch(patchCarInstance({ ...carInstance }, assistanceClientId, false, [], {}, true));
      if (params.id) {
        history.push(`/worksheet/${params.id}/assistance`);
      } else {
        history.push(`/worksheet/create-new/assistance`);
      }
      setIsPushToAssistance(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPushToAssistance, assistanceClientId]);

  useEffect(() => {
    if (isPushToInsurance && insuranceClientId) {
      rootDispatch(patchCarInstance({ ...carInstance }, insuranceClientId, false, [], {}, true));
      if (params.id) {
        history.push(`/worksheet/${params.id}/insurance`);
      } else {
        history.push('/worksheet/create-new/insurance');
      }
      setIsPushToInsurance(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPushToInsurance, insuranceClientId]);

  useEffect(() => {
    if (currentTab === 2 && carInstance?.id && customerId && dealerId) {
      const paramsChanged =
        assistanceQueryParams.carInstanceId !== carInstance?.id ||
        assistanceQueryParams.customerId !== customerId ||
        assistanceQueryParams.dealerId !== dealerId;

      if (paramsChanged) {
        setAssistanceQueryParams(createNewParams(...initialParams));
      }
    }
    if (currentTab === 3 && carInstance?.id && customerId && dealerId) {
      const paramsChanged =
        insuranceQueryParams.carInstanceId !== carInstance?.id ||
        insuranceQueryParams.customerId !== customerId ||
        insuranceQueryParams.dealerId !== dealerId;

      if (paramsChanged) {
        setInsuranceQueryParams(createNewParams(...initialParams));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab, carInstance?.id, customerId, dealerId]);

  useEffect(() => {
    if (scrollTo) {
      if (scrollTo === BLOCK_TABS_SELECT) {
        animateScrollTo(ref.current);
        rootDispatch(clearScroll());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollTo]);

  const tabClickHandler = (tab) => {
    if (currentTab === tab.id && tab.id !== 2 && tab.id !== 3) {
      return;
    }

    switch (tab.id) {
      case 1: {
        setCurrentTab(tab.id);
        if (!path.includes('/worksheet/:id') || !params?.id || params?.id === 'create-new') {
          history.push('/worksheet/create-new/calculator');
          break;
        }

        const ids = application?.ids;

        if (ids?.loan) {
          history.push(`/worksheet/${params.id}/loan-issue`);
          break;
        }

        history.push(`/worksheet/${params.id}/${isCalculator ? 'calculator' : 'home'}`);
        break;
      }

      case 2: {
        setCurrentTab(2);
        tabOpenTrack();
        setIsPushToAssistance(true);

        if (path.includes('/worksheet/:id') && !isNaN(params?.id)) {
          history.push(`/worksheet/${params?.id}/assistance`);
        } else {
          history.push('/worksheet/create-new/assistance');
        }

        setAssistanceQueryParams(createNewParams(...initialParams));
        break;
      }

      case 3: {
        setCurrentTab(3);
        tabOpenTrack();
        setIsPushToInsurance(true);

        if (path.includes('/worksheet/:id') && !isNaN(params?.id)) {
          history.push(`/worksheet/${params?.id}/insurance`);
        } else {
          history.push('/worksheet/create-new/insurance');
        }

        setInsuranceQueryParams(createNewParams(...initialParams));
        break;
      }

      default:
        break;
    }
  };

  const createTabs = () => {
    const { stateLoan, stateAssistance, stateInsurance } = getOrderStates(
      application.orders,
      deal,
      assistanceCalculation,
      insuranceCalculation
    );

    return TabsHeaders.map((tab) => (
      <Tab
        className={cn({ active: currentTab === tab.id })}
        key={tab.link}
        active={currentTab === tab.id}
        onClick={() => tabClickHandler(tab)}
      >
        {tab.id === 3 && (
          <div>
            <TabLogo src={SovkombankStrakhovanie} />
            {tab.label}
          </div>
        )}
        {tab.id !== 3 && tab.label}
        {tab.id === 3 && stateInsurance?.name !== EOW_STATES.new && (
          <Status status={stateInsurance} />
        )}
        {tab.id === 2 && stateAssistance?.name !== ASW_STATES.new && (
          <Status status={stateAssistance} />
        )}
        {tab.id === 1 && application?.ids?.loan && (
          <TabState title={stateLoan?.value} color={stateLoan?.color} />
        )}
      </Tab>
    ));
  };

  const createRoutes = (ref) => {
    if (currentTab) {
      return (
        <>
          {TabsHeaders.map((tab, index) => (
            <React.Fragment key={tab.link}>
              {tab.routeLinks.map((link) => (
                <Route
                  key={link}
                  path={link}
                  exact
                  render={() => getLazyComponentByIndex(index, { tabsRef: ref })}
                />
              ))}
            </React.Fragment>
          ))}
        </>
      );
    }

    return null;
  };

  return (
    <ServicesWrapper ref={ref}>
      <Wrapper>
        <Header ref={eosagoRef}>{createTabs()}</Header>
        {assistanceCalculation.fetchPrelist === 'pending' && currentTab === 2 && (
          <Loader show={true} color="white">
            <img width="250" height="250" src={CarPreloaderGif} alt="" />
          </Loader>
        )}
        <Suspense
          fallback={
            <Loader show={true} color="white">
              <img width="250" height="250" src={CarPreloaderGif} alt="" />
            </Loader>
          }
        >
          <Switch>{createRoutes(ref)}</Switch>
        </Suspense>
      </Wrapper>
    </ServicesWrapper>
  );
};

export default Tabs;
