/* eslint-disable no-undef */
import axios from 'axios';
import * as localStorage from 'utils/local-storage';
import handler401 from './handler401';

export const GET = 'get';
export const POST = 'post';
export const PUT = 'put';
export const PATCH = 'patch';
export const DELETE = 'delete';

let URL = `${process.env.REACT_APP_API_ENTRYPOINT}`;
if (process.env.REACT_APP_MODE === 'dev') {
  URL = `${process.env.REACT_APP_LOCAL_API_URL}`;
}

const Agent = (baseURL = URL) => {
  let headers = {
    'Content-Type': 'application/json',
    'accept': 'application/json',
    'withCredentials': true,
  };

  const _agent = axios.create({
    baseURL,
    headers,
  });

  _agent.interceptors.request.use((config) => {
    const stored = localStorage.getItem('token');
    if (stored) {
      config.headers = {
        ...headers,
        ...config.headers,
        Authorization: `Bearer ${stored.token}`,
      };
    }
    return config;
  });

  _agent.interceptors.response.use(
    (response) => {
      if (
        response?.data?.success !== undefined &&
        !response?.data?.success &&
        response?.data?.code === 404
      ) {
        handler401();
      } else {
        return response;
      }
    },
    (error) => {
      if (error.response && 401 === error.response.status) {
        handler401();
      } else {
        return Promise.reject(error);
      }
    }
  );

  return _agent;
};

export const agent = Agent();

export default agent;
