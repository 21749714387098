import React, { useState, useEffect, useRef } from 'react';
import animateScrollTo from 'animated-scroll-to';
import { InputMask } from 'utils/inputMask';
import { FormHeader } from '../../../Form/FormHeader';
import { GenderSelector } from '../../../GenderSelector';
import { Alert } from 'components/primitives/alert';
import { fioWithMiddlenameRegexp, fioRegexp } from '../../../../vlidator/regexps';
import { PERSONAL_INFO } from '../../../../constants';
import { genderToNum } from '../../../../helpers/genderHelper';
import { removeExtraSpaces } from 'utils/stringUtils';

import * as ScrollHelper from '../../../../helpers/scrollHelper';
import * as S from './style';

const fn = () => {};

const DriverInfo = ({
  counter = 1,
  driver,
  disabled = false,
  scrollTo = null,
  errors = {},
  onDelete = null,
  onBlur = null,
  onScroll = fn,
}) => {
  const ref = useRef();

  const [state, setState] = useState({});
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    const scrollToOnlyDriver =
      scrollTo === ScrollHelper.getDriverBlockName(driver.id, PERSONAL_INFO.name);

    if (ref && ref.current && scrollToOnlyDriver) {
      animateScrollTo(ref.current);
      onScroll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollTo]);

  useEffect(() => {
    setState({ ...driver });
  }, [driver]);

  const onBlurHandler = async (value) => {
    if (onBlur) {
      onBlur({ value, state });
    }
  };

  const onBlurFioHandler = async (value) => {
    onBlurHandler(value);
  };

  const alertAgreeHandler = () => {
    setShowAlert(false);

    const newFio = `${state.fio.trim()}`;
    const newState = { ...state, fio: newFio };

    setState(newState);

    onBlur({ value: { fio: newFio }, state: newState });
  };

  const alertCancelHandler = () => {
    setShowAlert(false);
  };

  const handleSelect = ({ fio }) => {
    const iGender = fio?.data?.gender || (state.gender ? 'FEMALE' : 'MALE') || 'MALE';
    const gender = genderToNum(iGender);

    setState({ ...state, fio: fio.query, gender });

    if (onBlur) {
      onBlur({ state: { ...state, fio: fio.query }, value: { fio: fio.query } });
      onBlur({ state: { ...state, gender }, value: { gender } });
    }
  };

  return (
    <S.FormContainer ref={ref} first={counter === 1}>
      <S.HeaderWrapper>
        <FormHeader title={`Водитель #${counter}`} onAction={!disabled ? onDelete : null} />
      </S.HeaderWrapper>

      <S.FioInput
        required
        name="fio"
        type="fio"
        error={!!errors.fio}
        errorMessage={errors.fio}
        label="Фамилия, имя, отчество"
        disabled={disabled}
        mask={InputMask.RUS_NAME}
        onBlur={onBlurFioHandler}
        value={state.fio}
        onChange={({ fio }) => {
          setState({ ...state, fio: removeExtraSpaces(fio.value) });
        }}
        onSelect={handleSelect}
        showAlert={() => {
          if (!fioWithMiddlenameRegexp.test(state.fio) && fioRegexp.test(state.fio)) {
            setShowAlert(true);
          }
        }}
      />

      <S.GenderSelectorWrapper>
        <GenderSelector
          value={state.gender}
          disabled={disabled}
          onChange={({ gender }) => {
            if (onBlur) {
              onBlur({ value: { gender }, state: { ...state, gender } });
            }

            setState({ ...state, gender });
          }}
        />
      </S.GenderSelectorWrapper>

      <S.BdayInput
        required
        isControlledComponent
        name="bday"
        unmask={true}
        label="Дата рождения"
        disabled={disabled}
        mask={InputMask.DATE_BEFORE_TODAY_NEW}
        onBlur={onBlurHandler}
        val={state.bday}
        error={errors?.bday}
        onChange={({ bday }) => {
          setState({ ...state, bday });
        }}
      />
      {showAlert && (
        <Alert
          title={`Заполните ФИО Водителя ${counter}`}
          desc={'Если у него отсутствует отчество, нажмите ОК'}
          agreeText={'ОК'}
          cancelText={'Отмена'}
          onAgree={alertAgreeHandler}
          onCancel={alertCancelHandler}
        />
      )}
    </S.FormContainer>
  );
};

export default DriverInfo;
