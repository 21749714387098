export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGGED_IN = 'USER_LOGGED_IN';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT';
export const USER_LOGIN_ERROR = 'USER_LOGIN_ERROR';
export const SET_USER_TFA = 'SET_USER_TFA';
export const USER_SET_FETCH = 'USER_SET_FETCH';
export const GET_USER = 'GET_USER';
export const GET_DEALERS = 'GET_DEALERS';

export const USER_CHECKAUTH = 'USER_CHECKAUTH';
export const USER_CHECK = 'USER_CHECK';
export const USER_LOAD = 'USER_LOAD';
export const USER_LOADED = 'USER_LOADED';

export const CHANGE_LOCALE = 'CHANGE_LOCALE';
export const CHANGE_ACTIVE_DEALER = 'CHANGE_ACTIVE_DEALER';
export const CHANGE_ACTIVE_ROLE = 'CHANGE_ACTIVE_ROLE';
export const ACTIVE_DEALER_CHANGED = 'ACTIVE_DEALER_CHANGED';
export const ACTIVE_ROLE_CHANGED = 'ACTIVE_ROLE_CHANGED';
export const DEALERS_SET_FETCH = 'DEALERS_SET_FETCH';
export const UPDATE_USER = 'UPDATE_USER';

export const CHANGE_SYSTEM_STATE = 'CHANGE_SYSTEM_STATE';
export const SET_SYS_STATE = 'SET_SYS_STATE';
export const GET_SYS_STATE = 'GET_SYS_STATE';
export const NAVBAR_CLOSED = 'NAVBAR_CLOSED';
export const USER_CODE = 'USER_CODE';
export const AWAIT_CODE = 'AWAIT_CODE';
export const USER_DROP_ERROR = 'USER_DROP_ERROR';
