import { convertOptionToSysName, convertSysNameToSelectOption } from 'utils/dataMappers/mappers';

export const detailsInitialState = {
  details: {
    birthDate: '',
    creditSubjectCode: '',
    fullName: '',
    hasForeignRelations: false,
    hasLongTermVisa: false,
    hasRelationToPublicPerson: false,
    hasRelationToUsa: false,
    isCurrentCitizenOnly: false,
    isCurrentTaxOnly: true,
    isPublicPerson: false,
    jobOrganizationName: '',
    jobPosition: '',
    taxCountries: '',
    typeOfRelationToPublicPerson: { id: 0, value: '', sysName: '' },
    foreignTaxNumber: '',
  },
};

export const detailsFromAPIToState = (details, { trusteeRelations }) => ({
  details: {
    birthDate: details.birthDate || '',
    creditSubjectCode: '',
    fullName: details.fullName || '',
    hasForeignRelations: details.hasForeignRelations || false,
    hasLongTermVisa: details.hasLongTermVisa || false,
    hasRelationToPublicPerson: details.hasRelationToPublicPerson || false,
    hasRelationToUsa: details.hasRelationToUsa || false,
    isCurrentCitizenOnly: details.isCurrentCitizenOnly || false,
    isCurrentTaxOnly: details.isCurrentTaxOnly || false,
    isPublicPerson: details.isPublicPerson || false,
    jobOrganizationName: details.jobOrganizationName || '',
    jobPosition: details.jobPosition || '',
    taxCountries: (details.taxCountries && details.taxCountries.join(', ')) || '',
    typeOfRelationToPublicPerson: convertSysNameToSelectOption(
      details.typeOfRelationToPublicPerson,
      trusteeRelations
    ),
    foreignTaxNumber: details.foreignTaxNumber || '',
  },
});

export const detailsFromStateToAPI = (person) => {
  const details = person.details;
  return {
    details: {
      birthDate: (details && details.birthDate) || '',
      creditSubjectCode: '',
      fullName: (details && details.fullName) || '',
      hasForeignRelations: (details && details.hasForeignRelations) || false,
      hasLongTermVisa: (details && details.hasLongTermVisa) || false,
      hasRelationToPublicPerson: (details && details.hasRelationToPublicPerson) || false,
      hasRelationToUsa: (details && details.hasRelationToUsa) || false,
      isCurrentCitizenOnly: (details && details.isCurrentCitizenOnly) || false,
      isCurrentTaxOnly: (details && details.isCurrentTaxOnly) || false,
      isPublicPerson: (details && details.isPublicPerson) || false,
      jobOrganizationName: (details && details.jobOrganizationName) || '',
      jobPosition: (details && details.jobPosition) || '',
      taxCountries: (details && details.taxCountries.split(', ')) || '',
      typeOfRelationToPublicPerson:
        (details && convertOptionToSysName(details.typeOfRelationToPublicPerson)) || '',
      foreignTaxNumber: details.foreignTaxNumber || '',
    },
  };
};
