/* eslint-disable no-undef */
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import debounce from 'lodash/debounce';

import { POST_APPLICATION_COMMENT } from 'store/applications/constants';
import { ApplicCommentContainer, ApplicCommentTextarea } from './style';

export const ApplicComment = ({ application }) => {
  const [comment, setComment] = useState(application.comment);
  const dispatch = useDispatch();

  const debouncedSubmit = useCallback(
    debounce((newComment) => {
      dispatch({
        type: POST_APPLICATION_COMMENT,
        payload: {
          applicationId: application.id,
          comment: newComment,
        },
      });
    }, 1000),
    [dispatch, application.id]
  );

  const onChange = (event) => {
    const newComment = event.target.value;
    setComment(newComment);
    debouncedSubmit(newComment);
  };

  return (
    <ApplicCommentContainer>
      <ApplicCommentTextarea
        onChange={onChange}
        placeholder="Оставить комментарий к заявке"
        value={comment}
        rows="2"
      />
    </ApplicCommentContainer>
  );
};
