import { put, select, call } from 'redux-saga/effects';

import * as carInstanceActions from 'store/carInstance/constants';
import * as carInstanceProvider from 'data-providers/carInstanceProvider';

export function* saveCarDetail({ payload: { anketaId, isCashCustomer } }) {
  const { carInstance } = yield select((store) => store.carInstance);

  const updateCarInstance = isCashCustomer
    ? carInstanceProvider.updateCarInstanceCustomer
    : carInstanceProvider.updateCarInstance;

  const newCarInstance = yield call(updateCarInstance, {
    carInstance: {
      ...carInstance,
      price: Number(carInstance.price) || 0,
      enginePower: Number(carInstance.enginePower) || 0,
      engineNumber: carInstance.engineNumber || '',
      bodyNumber: carInstance.bodyNumber || '',
      permittedMaxWeight: Number(carInstance.permittedMaxWeight) || 0,
      engineVolume: Number(carInstance.engineVolume) || 500,
      glonasNumber: carInstance.glonasNumber || '',
    },
    id: anketaId,
  });

  const models = yield select((store) => store.carReference.models);
  const model = models.find((item) => item.name === newCarInstance.model);

  const image = model?.image ? model?.image : null;

  yield put({
    type: carInstanceActions.SET_CAR_INSTANCE,
    payload: { carInstance: { ...newCarInstance, image } },
  });
}
