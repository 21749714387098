export const SET_FILTERS = 'SET_FILTERS';
export const DROP_FILTER = 'DROP_FILTER';
export const ALL_FILTERS_LOADED = 'ALL_FILTERS_LOADED';
export const LOAD_FILTERS = 'filters/load';
export const RELOAD_FILTERS = 'filters/reload';
export const FILTERS_CHECKED_BY_NAME = 'FILTERS_CHECKED_BY_NAME';
export const FILTERS_DISABLED_BY_NAME = 'FILTERS_DISABLED_BY_NAME';
export const TOGGLE_FILTERS_DISABLE = 'TOGGLE_FILTERS_DISABLE';
export const SET_SEARCH = 'SET_SEARCH';
export const SELECT_ALL_DEALERS = 'SELECT_ALL_DEALERS';
export const DROP_FILTERS = 'DROP_FILTERS';
export const LOAD_DEALERS = 'LOAD_DEALERS';
export const SET_SORTING = 'filters/set-sorting';
export const RESET_SORTING = 'filters/reset-sorting';
export const SET_FILTER_TOUCH = 'filters/touch';
export const SET_FILTER_UNTOUCH = 'filters/untouch';
