import styled, { css } from 'styled-components';

export const Item = styled.div`
  flex-shrink: 0;
  ${(props) =>
    props.position &&
    css`
      position: fixed;
      top: ${props.position.y - 14}px;
      left: ${props.position.x + 14}px;
    `}
  z-index: 1001;
  display: flex;
  ${(props) => !props.position && 'display: none;'}
  justify-content: center;
  align-items: center;
  padding: 5px 5px;
  border: 1px solid #a8a8a8;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  background: #fff;
  color: #000;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  border-radius: 5px;
`;
