import { genderReversMap } from './genderHelper';
import * as MapperHelper from './mapperHelper';

export const frontToBackCustomer = (fc, model = {}) => {
  model.personalInformation = {
    ...model?.personalInformation,
    ...MapperHelper.fioSplit(fc.fio),
    genderSysName: genderReversMap[fc.gender]?.toLowerCase() ?? genderReversMap[0],
    birthDate: MapperHelper.dateFormmatter(fc.bday, true),
  };

  model.contacts = {
    ...model?.contacts,
    mobilePhone: fc.insurerPhone,
    email: fc.insurerEmail,
  };

  model.passport = {
    ...model?.passport,
    ...MapperHelper.passportSplit(fc.insurerPassportSerialNum),
    issueDate: MapperHelper.dateFormmatter(fc.insurerPassportDate, true),
    issuerCode: fc.insurerIssuerCode,
    issuer: fc.insurerIssuer,
    birthPlace: fc.insurerBirthPlace,
  };

  model.registrationAddress = {
    ...model?.registrationAddress,
    address: {
      ...model?.registrationAddress?.address,
      ...fc.insurerDadataInfo,
      indexNumber: fc.insurerIndex,
      fiasValue: fc.insurerAddress ?? '',
      buildingNumber: fc.insurerDadataInfo?.building,
    },
  };

  return model;
};
