import { useState, useEffect } from 'react';
import { AnketaProvider } from './anketaProvider';
import { CustomerProvider } from './customerProveder';
import { insurerProvider } from './insurerProvider';

const DefaultCustomerInfo = {
  alt: 'Фамилия Имя',
  name: 'Фамилия Имя',
  phone: '+7 (___) __-__-__',
  customerId: undefined,
  applicationId: undefined,
  isActive: false,
  isPhone: false,
};

export const useCustomerProvider = (location, { customerArgs, anketaArgs, additionalProduct }) => {
  const [customerInfo, setCustomerInfo] = useState({ ...DefaultCustomerInfo });

  useEffect(() => {
    if (location.pathname.includes('/assistance') || location.pathname.includes('/insurance')) {
      return setCustomerInfo(insurerProvider(additionalProduct));
    }

    if (location.pathname.includes('anketa')) {
      return setCustomerInfo(AnketaProvider(anketaArgs.data));
    }

    if (
      location.pathname.includes('worksheet') ||
      location.pathname.includes('create-new') ||
      location.pathname.includes('application')
    ) {
      return setCustomerInfo(CustomerProvider(customerArgs));
    }

    setCustomerInfo({ ...DefaultCustomerInfo });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    location.pathname,
    customerArgs,
    additionalProduct,
    anketaArgs?.data?.personalInfo?.lastName, // eslint-disable-line react-hooks/exhaustive-deps
    anketaArgs?.data?.personalInfo?.firstName, // eslint-disable-line react-hooks/exhaustive-deps
    anketaArgs?.data?.contacts?.mobilePhone, // eslint-disable-line react-hooks/exhaustive-deps
  ]);

  return customerInfo;
};
