export const calculatorFiltersType = {
  'С КАСКО': 'withKASKO',
  'Без КАСКО': 'withoutKASKO',
  'Два документа': 'twodocuments',
  'DIRECT': 'direct',
  'Остаточный платеж': 'op',
  'С подтверждением дохода': 'income',
  'Без СЖ банка': 'withoutinsurance',
};

export const viewDocumentsType = {
  'passport': 'view-passport',
  'driving-licence': 'view-vu',
};

export const downloadDocumentsType = {
  'passport': 'download-passport',
  'driving-licence': 'download-vu',
};
