import React from 'react';
import { PALETTE } from 'styles/constants';
import styled from 'styled-components';

const CloseButtonStyled = styled.button`
  position: relative;
  width: 15px;
  height: 15px;
  border: 0;
  cursor: pointer;
  background: white;
  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    background: #b5b5b5;
    width: 15px;
    height: 2px;
    border-radius: 1px;
    transform-origin: center;
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
  &:focus:before,
  &:focus:after {
    background: ${PALETTE.grey7};
  }
`;

const fn = () => {};

const CloseButton = ({ onClick = fn, ...rest }) => {
  const clickHandler = (evt) => {
    evt.target.blur();
    onClick(evt);
  };

  return <CloseButtonStyled type="button" onClick={clickHandler} {...rest} />;
};

export default CloseButton;
