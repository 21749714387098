import styled, { css } from 'styled-components';
import { PALETTE } from 'styles/constants';
import { alignY } from 'styles/mixins';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: ${(p) => (p.disabled ? 'none' : null)};
  cursor: ${(p) => (p.disabled ? 'default' : 'pointer')};
  opacity: ${(p) => (p.disabled ? '0.7' : '1')};
`;

export const Toggle = styled.div`
  position: relative;
  width: 34px;
  height: 14px;
  background-color: ${PALETTE.grey2};
  border-radius: 15px;
  margin: 0 10px;
  &:before {
    position: absolute;
    content: '';
    width: 20px;
    height: 20px;
    ${alignY()};
    will-change: left;
    transition: 30ms left linear;
    left: ${(p) => (p.active ? '15px' : '0')};
    background-color: ${(p) => (p.active || p.alwaysActive ? PALETTE.green1 : PALETTE.grey1)};
    border-radius: 50%;
  }
`;

export const Label = styled.span`
  ${(p) =>
    p.double &&
    css`
      width: 50%;
      &:first-child {
        text-align: right;
      }
    `};
  user-select: none;
  color: ${(p) => (p.active ? PALETTE.black : PALETTE.grey1)};
`;
