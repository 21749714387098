export const convertToBackEndFormat = (frontDate) => {
  if (!frontDate) {
    return '';
  }

  const [day, month, year] = frontDate.split('.');

  if (!day || !month || !year) {
    return '';
  }

  return `${year}-${month}-${day}`;
};

export const convertFromBackEndFormat = (backDate) => {
  if (!backDate) {
    return '';
  }

  if (!/\d{4}-\d{2}-\d{2}/.test(backDate)) {
    return backDate;
  }

  const [year, month, day] = backDate.split('-');

  return `${day}.${month}.${year}`;
};
