import React, { useState, useEffect, useRef } from 'react';
import animateScrollTo from 'animated-scroll-to';
import PropTypes from 'prop-types';
import * as S from './style';
import { InputMask } from 'utils/inputMask';
import { PASSPORT } from '../../../../constants';

const fn = () => {};

const Passport = ({
  data,
  disabled = false,
  scrollTo = null,
  errors = {},
  onBlur = null,
  onScroll = fn,
}) => {
  const ref = useRef();

  const [state, setState] = useState({ ...data });
  const [selectState, setSelectState] = useState({
    insurerIssuerCode: data.insurerIssuerCode,
    insurerIssuer: data.insurerIssuer,
  });
  const [validationErrors, setValidationErrors] = useState({});
  const [newIssuedByState, setNewIssuedByState] = useState({});

  useEffect(() => {
    if (ref && ref.current && scrollTo === PASSPORT.name) {
      animateScrollTo(ref.current);
      onScroll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollTo]);

  useEffect(() => {
    setValidationErrors(errors);
  }, [errors]);

  useEffect(() => {
    setState(data);
    setSelectState({
      insurerIssuerCode: data.insurerIssuerCode,
      insurerIssuer: data.insurerIssuer,
    });
  }, [data]);

  const onBlurHandler = async (value) => {
    if (onBlur) {
      onBlur({ value, state });
    }
  };

  const onBlurDepartmentCodeAndIssuesBy = async () => {
    if (onBlur) {
      onBlur({ value: { insurerIssuerCode: state.insurerIssuerCode }, state });
      onBlur({ value: { insurerIssuer: state.insurerIssuer }, state });
    }
  };

  const selectHandler = async (item) => {
    const name = Object.keys(item)[0];
    const value = item[name];

    setSelectState({
      insurerIssuerCode: value.data.code,
      insurerIssuer: value.data.name,
    });

    if (onBlur) {
      onBlur({
        value: { insurerIssuerCode: value.data.code },
        state: { ...state, insurerIssuerCode: value.data.code },
      });
      onBlur({
        value: { insurerIssuer: value.data.name },
        state: { ...state, insurerIssuer: value.data.name },
      });
    }
  };

  const closeDropDownHandler = () => {
    setNewIssuedByState({ show: false });
  };

  return (
    <S.Wrapper ref={ref}>
      <S.Title>Паспорт</S.Title>
      <S.SeriesAndNumber
        name="insurerPassportSerialNum"
        isControlledComponent
        label="Серия, номер"
        disabled={disabled}
        val={state.insurerPassportSerialNum.replace(/\s/g, '')}
        error={validationErrors?.insurerPassportSerialNum}
        mask={InputMask.PASSPORT_SERIA_NUMBER}
        unmask
        onBlur={onBlurHandler}
        onChange={({ insurerPassportSerialNum }) => {
          setState({
            ...state,
            insurerPassportSerialNum: insurerPassportSerialNum.replace(/_/g, ''),
          });
        }}
        required
      />
      <S.DateOfIssue
        name="insurerPassportDate"
        isControlledComponent
        label="Дата выдачи"
        disabled={disabled}
        val={state.insurerPassportDate}
        error={validationErrors?.insurerPassportDate}
        mask={InputMask.DATE_BEFORE_TODAY_NEW}
        unmask
        onBlur={onBlurHandler}
        onChange={({ insurerPassportDate }) => {
          setState({ ...state, insurerPassportDate });
        }}
        required
      />
      <S.DepartmentCode
        name="insurerIssuerCode"
        label="Код подразделения"
        type="passport"
        disabled={disabled}
        value={selectState.insurerIssuerCode.replace(/-/g, '')}
        error={validationErrors?.insurerIssuerCode}
        mask={InputMask.PASSPORT_DEPARTMENT_CODE2}
        unmask
        valueBuilder={(item) => item?.data?.code || item.value}
        onSelect={selectHandler}
        onBlur={onBlurDepartmentCodeAndIssuesBy}
        onChange={({ insurerIssuerCode }) => {
          const dataResponse = insurerIssuerCode.data
            ? { insurerIssuer: insurerIssuerCode.data.name }
            : {};

          setState({
            ...state,
            ...dataResponse,
            insurerIssuerCode: insurerIssuerCode.value,
          });
        }}
        closeDropDownAfterOutsideClick={false}
        required
        newState={newIssuedByState}
        onCloseDropDown={closeDropDownHandler}
      />
      <S.IssuesBy
        name="insurerIssuer"
        label="Кем выдан"
        type="passport"
        disabled={disabled}
        mask={InputMask.ALL_RUS}
        unmask
        value={selectState.insurerIssuer}
        error={validationErrors?.insurerIssuer}
        onSelect={selectHandler}
        onBlur={onBlurDepartmentCodeAndIssuesBy}
        onChange={({ insurerIssuer }) => {
          const dataResponse = insurerIssuer.data
            ? { insurerIssuerCode: insurerIssuer.data.code }
            : {};

          setState({
            ...state,
            ...dataResponse,
            insurerIssuer: insurerIssuer.value,
          });
        }}
        closeDropDownAfterOutsideClick={false}
        required
        newState={newIssuedByState}
        onCloseDropDown={closeDropDownHandler}
      />
      <S.BirthPlace
        name="insurerBirthPlace"
        isControlledComponent
        label="Место рождения"
        disabled={disabled}
        val={state.insurerBirthPlace}
        error={validationErrors?.insurerBirthPlace}
        mask={InputMask.ALL_RUS}
        unmask
        onBlur={onBlurHandler}
        onChange={({ insurerBirthPlace }) => {
          setState({ ...state, insurerBirthPlace: insurerBirthPlace.replace(/_/g, '') });
        }}
      />
    </S.Wrapper>
  );
};

Passport.propTypes = {
  counter: PropTypes.number,
  title: PropTypes.string,
  errors: PropTypes.object,
  data: PropTypes.object,
  onBlur: PropTypes.oneOfType([PropTypes.func, PropTypes.oneOf([null])]),
};

export default Passport;
