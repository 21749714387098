import React from 'react';

import BetaTestBanner from './components/BetaTestBanner';

export const UpperContent = () => {
  return (
    ///MAINPAGE-86 временно заменяем баннер
    <BetaTestBanner />

    // <S.Container onClick={() => window.open('https://www.ecredit.one/nopaper', '_blank')}>
    //   <S.BannerWrapper src={MainPageBanner} />
    // </S.Container>
  );
};
