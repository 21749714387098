import { put, select, takeLatest, call, all } from 'redux-saga/effects';
import * as actions from './constants';
import * as provider from 'data-providers/insuranceProvider';
import { DEFAULT_ERROR_MESSAGE } from 'constants/index';
import { disableCarInstance } from 'store/carInstance';
import { createEOWRequestParams } from 'data-providers/helper';
import { EOW_NAMEPLATES, EOW_STATES, fetchNames } from './resources';
import { saveCustomers } from 'store/insuranceAnketa/saveHelper';
import { eowAnketaSetMainFetch, insAnketaSetNetworkError } from 'store/insuranceAnketa';
import {
  eowCalcSetApplication,
  eowCalcSetDealerLegals,
  eowCalcSetFetch,
  eowCalcSetMainFetch,
  eowCalcSetPopupData,
  eowCalcUpdApplication,
  insuranceSetBanksList,
  insuranceSetSelectedBank,
} from '.';

const fn = () => {};

function* selectOffer({ payload: { selectedOfferData, successCallback = fn } }) {
  try {
    yield put(eowCalcSetFetch(selectedOfferData.applicationId, fetchNames.selectOffer, true));

    const { result, applicationId } = yield call(provider.selectOfferRequest, selectedOfferData);

    yield put(
      eowCalcSetApplication({
        applicationId,
        applicationData: result,
      })
    );

    successCallback();
  } catch (err) {
    if (err?.errorTraefikData) {
      yield put(eowCalcSetPopupData(selectedOfferData.applicationId, err.errorTraefikData));
    } else {
      // старая нетронутая логика
      yield put({
        type: actions.INS_SET_ERROR,
        payload: {
          key: 'selectOffer',
          value: err.customDescription || DEFAULT_ERROR_MESSAGE,
        },
      });
    }
  } finally {
    yield put(eowCalcSetFetch(selectedOfferData.applicationId, fetchNames.selectOffer, false));
  }
}

function* getAgreementData({ payload: { agreementRequestData } }) {
  try {
    yield put(eowCalcSetFetch(agreementRequestData.applicationId, fetchNames.agreement, true));

    // для блокирования анкеты и каринстанса до перехода в agreement_succeed
    yield put(
      eowCalcUpdApplication({
        applicationId: agreementRequestData.applicationId,
        newData: {
          currentWorkflowState: EOW_STATES.agreement_requested,
        },
      })
    );

    const { result, applicationId } = yield call(provider.agreementRequest, agreementRequestData);

    yield put(
      eowCalcSetApplication({
        applicationId,
        applicationData: result,
      })
    );
  } catch (err) {
    yield put(
      eowCalcUpdApplication({
        applicationId: agreementRequestData.applicationId,
        newData: {
          currentWorkflowState: EOW_STATES.offer_selected,
        },
      })
    );

    if (err?.errorTraefikData) {
      yield put(eowCalcSetPopupData(agreementRequestData.applicationId, err.errorTraefikData));
    } else {
      // старая нетронутая логика
      yield put({
        type: actions.INS_SET_ERROR,
        payload: {
          key: 'agreementDraft',
          value: err.customDescription || DEFAULT_ERROR_MESSAGE,
        },
      });
    }
  } finally {
    yield put(eowCalcSetFetch(agreementRequestData.applicationId, fetchNames.agreement, false));
  }
}

function* getPolicy({ payload: { getPolicyRequestData } }) {
  try {
    yield put(eowCalcSetFetch(getPolicyRequestData.applicationId, fetchNames.getPolicy, true));

    const { result, applicationId } = yield call(provider.getPolicyRequest, getPolicyRequestData);

    yield put(
      eowCalcSetApplication({
        applicationId,
        applicationData: result,
      })
    );

    yield put(disableCarInstance());
  } catch (err) {
    if (err?.errorTraefikData) {
      yield put(eowCalcSetPopupData(getPolicyRequestData.applicationId, err.errorTraefikData));
    } else {
      // старая нетронутая логика
      yield put({
        type: actions.INS_SET_ERROR,
        payload: {
          key: 'policyQuery',
          value: err.customDescription || DEFAULT_ERROR_MESSAGE,
        },
      });
    }
  } finally {
    yield put(eowCalcSetFetch(getPolicyRequestData.applicationId, fetchNames.getPolicy, false));
  }
}

function* clientDecline({ payload: { applicationId, callback = fn } }) {
  try {
    yield put(eowCalcSetFetch(applicationId, fetchNames.clientDecline, true));

    const {
      result: { state },
      applicationId: responseAppId,
    } = yield provider.eowClientDeclineRequest({ applicationId });

    yield put(
      eowCalcUpdApplication({
        applicationId: responseAppId,
        newData: { state, currentWorkflowState: state.sysName },
      })
    );
  } catch (err) {
    if (err?.errorTraefikData) {
      yield put(eowCalcSetPopupData(applicationId, err.errorTraefikData));
    } else {
      // старая нетронутая логика
      yield put({
        type: actions.INS_SET_ERROR,
        payload: {
          key: 'clientDecline',
          value: err.customDescription || DEFAULT_ERROR_MESSAGE,
        },
      });
    }
  } finally {
    yield put(eowCalcSetFetch(applicationId, fetchNames.clientDecline, false));
    callback();
  }
}

function* cancellation({
  payload: { applicationId, successCallback, errorCallback, finallyCallback },
}) {
  try {
    yield provider.eowCancellationRequest(applicationId);
    successCallback();
  } catch (err) {
    console.log('CANCELLATION ERROR ***', err);
    errorCallback();
  }
  finallyCallback();
}

function* fillOldAnketaData({ payload: { applicationId, clientId, id, workSheetId } }) {
  try {
    yield put({
      type: actions.INS_SET_ID,
      payload: { applicationId, clientId, id, workSheetId },
    });

    yield put({
      type: 'start-page/store-clientId',
      payload: clientId,
    });

    yield* saveCustomers({
      payload: { anketaId: workSheetId, isCashCustomer: true, throwAfter: true },
    });
  } catch (err) {
    // старая нетронутая логика
    console.log('oldAnketa', err);
    yield put({
      type: actions.INS_SET_ERROR,
      payload: {
        key: 'oldAnketa',
        value: err.customDescription || DEFAULT_ERROR_MESSAGE,
      },
    });
  }
}

function* refreshCalcAndPatchCustomers({ payload }) {
  try {
    yield put(eowAnketaSetMainFetch(true));

    yield* saveCustomers({
      payload: { anketaId: payload.workSheetId, isCashCustomer: true, throwAfter: true },
    });

    const { applicationId } = yield select((store) => store.insuranceAnketa);

    yield* refreshCalc({
      payload: {
        dataForCalculate: createEOWRequestParams(applicationId, {
          policyStartDate: new Date().toISOString(),
          bankId: payload.bankId,
          isInCredit: payload.isInCredit,
        }),
      },
    });

    const applicationData = yield provider.getApplicationDataRequest(applicationId);

    yield put(
      eowCalcSetApplication({
        applicationId: applicationData.id,
        applicationData,
      })
    );

    payload.callback(payload.workSheetId);
  } catch (err) {
    // старая нетронутая логика
    console.log('recalcRecustomers', err);
    yield put({
      type: actions.INS_SET_ERROR,
      payload: {
        key: 'recalcRecustomers',
        value: err.customDescription || DEFAULT_ERROR_MESSAGE,
      },
    });
  } finally {
    yield put(eowAnketaSetMainFetch(false));
  }
}

function* createNewInsuranceOrder({ payload }) {
  try {
    yield put(eowCalcSetMainFetch(fetchNames.calculate, true));
    yield put(eowAnketaSetMainFetch(true));

    const {
      data: { applicationId, clientId, id, workSheetId },
    } = yield provider.eowAddNewOrder(payload);

    if (payload.withPatchCustomers) {
      yield* fillOldAnketaData({ payload: { applicationId, clientId, id, workSheetId } });
    }

    yield provider.eowCalculateRequest(
      createEOWRequestParams(applicationId, {
        bankId: payload.bankId,
        isInCredit: payload.isInCredit,
        policyStartDate: new Date().toISOString(),
      })
    );

    const applicationData = yield provider.getApplicationDataRequest(applicationId);

    yield put(
      eowCalcSetApplication({
        applicationId: applicationData.id,
        applicationData,
      })
    );

    if (payload.withPatchCustomers) {
      payload.callback(workSheetId);
    }
  } catch (err) {
    console.error('createNewInsuranceOrder ERROR:\r\n', err);
    const errorMessage =
      err.errorTraefikData?.message || err.customDescription || DEFAULT_ERROR_MESSAGE;

    yield put(insAnketaSetNetworkError(errorMessage));
    yield put({
      type: actions.INS_SET_ERROR,
      payload: { key: 'calulationList', value: errorMessage },
    });
  } finally {
    yield put(eowCalcSetMainFetch(fetchNames.calculate, false));
    yield put(eowAnketaSetMainFetch(false));
  }
}

function* refreshCalc({ payload: { dataForCalculate } }) {
  try {
    yield put(eowCalcSetFetch(dataForCalculate.applicationId, fetchNames.calculate, true));
    yield put(
      eowCalcUpdApplication({
        applicationId: dataForCalculate.applicationId,
        newData: { currentWorkflowState: EOW_STATES.offers_requested },
      })
    );

    const {
      data: { applicationId, result },
    } = yield provider.eowCalculateRequest(dataForCalculate);

    yield put(
      eowCalcUpdApplication({
        applicationId,
        newData: {
          offers: result,
          selectedOffer: null,
          state: EOW_NAMEPLATES.offers_requested,
        },
      })
    );

    yield put(eowCalcSetPopupData(dataForCalculate.applicationId, null));
  } catch (err) {
    console.error(`calculation Error: ${err}`);
    if (err?.errorTraefikData) {
      yield put(eowCalcSetPopupData(dataForCalculate.applicationId, err.errorTraefikData));
    }
  } finally {
    yield put(eowCalcSetFetch(dataForCalculate.applicationId, fetchNames.calculate, false));
  }
}

function* refreshCalculationsByAppsIdsList({ payload: { applicationsIdsList, callback } }) {
  try {
    const policyStartDate = new Date().toISOString();
    yield all(
      applicationsIdsList.map((applicationId) =>
        call(
          provider.eowCalculateRequest,
          createEOWRequestParams(applicationId, { policyStartDate })
        )
      )
    );

    callback();
  } catch (err) {
    console.error(`refreshCalculationsByAppsIdsList Error: ${err}`);
  }
}

function* addEOWFile({ payload: { applicationId, fileId, documentType, ext } }) {
  try {
    const file = yield provider.addFileEOWRequest(applicationId, fileId, documentType, ext);
    const targetApplicationFiles = yield select(
      (store) => store.insuranceCalculation.applications[applicationId]?.requiredDocuments || null
    );

    if (file && targetApplicationFiles) {
      const updatedFiles = [...targetApplicationFiles];
      updatedFiles.find((el) => el.id === file.id).fileId = file.fileId;
      yield put(
        eowCalcUpdApplication({ applicationId, newData: { requiredDocuments: updatedFiles } })
      );
    }
  } catch (err) {
    console.error(`addFileEOWRequest Error: ${err}`);
  }
}

function* delEOWFile({ payload: { applicationId, id } }) {
  try {
    const deletedFile = yield provider.delFileEOWRequest(id);

    const targetApplicationFiles = yield select(
      (store) => store.insuranceCalculation.applications[applicationId]?.requiredDocuments || null
    );

    if (targetApplicationFiles) {
      const updatedFiles = [...targetApplicationFiles];
      updatedFiles.find((el) => el.id === deletedFile.id).fileId = deletedFile.fileId;
      yield put(
        eowCalcUpdApplication({ applicationId, newData: { requiredDocuments: updatedFiles } })
      );
    }
  } catch (err) {
    console.error(`delFileEOWRequest Error: ${err}`);
  }
}

function* updateDealerLegals({ payload: { dealerId } }) {
  try {
    const { dealerLegals = [] } = yield provider.getDealerLegalsRequest(dealerId);
    const filteredLegals = dealerLegals.filter(
      (el) => !['7743578549', '9710000298'].includes(el.inn)
    );
    const mappedLegals = filteredLegals.map((el) => ({ ...el, value: el.name }));
    yield put(eowCalcSetDealerLegals(dealerId, mappedLegals));
  } catch (err) {
    console.error(`updateDealerLegals Error: ${err}`);
  }
}

function* eowReloadDocuments({ payload: { applicationId, finallyCallback } }) {
  try {
    yield provider.reloadEOWDocumentsRequest(applicationId);
  } catch (err) {
    console.log('RELOAD DOCUMENTS ERROR ***', err);
  }
  finallyCallback();
}

function* requestIdByOrder({ payload }) {
  try {
    const insuranceCalculation = yield provider.getInsuranceCalculationApplicatioIdByOrders(
      payload
    );
    const { applicationId, clientId, id, workSheetId } = insuranceCalculation;

    yield put({
      type: actions.INS_SET_ID,
      payload: { applicationId, clientId, id, workSheetId },
    });

    yield put({
      type: 'start-page/store-clientId',
      payload: clientId,
    });

    if (payload.callback) {
      yield payload.callback(insuranceCalculation);
    }
  } catch (err) {
    console.log(err);
    yield put({
      type: actions.INS_SET_ERROR,
      payload: {
        key: 'network',
        value: err.customDescription || DEFAULT_ERROR_MESSAGE,
      },
    });
  }
}

function* getBanks({ payload }) {
  try {
    if (payload.dealerId) {
      let banksList = [];
      if (payload.priority) {
        banksList = yield provider.getBanksList(payload.dealerId, payload.priority);
      } else {
        banksList = yield provider.getBanksList(payload.dealerId);
      }
      yield put(insuranceSetBanksList(banksList));
      const { selectedBank } = yield select((store) => store.insuranceCalculation);
      const selectedBankId = selectedBank?.id;
      const selectedBankWithName =
        selectedBankId && banksList.find((el) => el.id === selectedBankId);
      if (selectedBankWithName) {
        const { bankName, ...rest } = selectedBankWithName;
        yield put(insuranceSetSelectedBank({ ...rest, value: bankName }));
      }
    }
  } catch (err) {
    console.error(`getBanks Error: ${err}`);
  }
}

export function* watchEowSelectOffer() {
  yield takeLatest(actions.EOW_CALC_SELECT_OFFER_REQUEST, selectOffer);
}

export function* watchEowAgreement() {
  yield takeLatest(actions.EOW_CALC_AGREEMENT_REQUEST, getAgreementData);
}

export function* watchEowGetPolicy() {
  yield takeLatest(actions.EOW_CALC_GET_POLICY_REQUEST, getPolicy);
}

export function* watchEowClientDecline() {
  yield takeLatest(actions.EOW_CALC_CLIENT_DECLINE_REQUEST, clientDecline);
}

export function* watchEowRefreshCalc() {
  yield takeLatest(actions.EOW_CALC_REFRESH_CALC_REQUEST, refreshCalc);
}

export function* watchEowRefreshCalculationsByOrders() {
  yield takeLatest(
    actions.EOW_CALC_REFRESH_CALCS_BY_APPS_IDS_REQUESTS,
    refreshCalculationsByAppsIdsList
  );
}

export function* watchEowCancellation() {
  yield takeLatest(actions.EOW_CALC_CANCELLATION_REQUEST, cancellation);
}

export function* watchEowReloadDocuments() {
  yield takeLatest(actions.EOW_CALC_RELOAD_DOCS_REQUEST, eowReloadDocuments);
}

export function* watchEowAddFile() {
  yield takeLatest(actions.EOW_CALC_ADD_FILE, addEOWFile);
}

export function* watchEowDelFile() {
  yield takeLatest(actions.EOW_CALC_DEL_FILE, delEOWFile);
}

export function* watchEowAddNewInsuranceOrder() {
  yield takeLatest(actions.EOW_CALC_ADD_EOW_ORDER_REQUEST, createNewInsuranceOrder);
}

export function* watchEowRecalcRecustomers() {
  yield takeLatest(actions.EOW_CALC_RECALC_RECUSTOMERS, refreshCalcAndPatchCustomers);
}

export function* watchEowUpdateDealerLegals() {
  yield takeLatest(actions.EOW_CALC_UPD_DEALER_LEGALS, updateDealerLegals);
}

export function* watchInsuranceRequestIdByOrder() {
  //spGetApplicationId
  yield takeLatest(actions.INS_REQUEST_BYORDER, requestIdByOrder);
}

export function* watchInsuranceGetBanks() {
  yield takeLatest(actions.INS_GET_BANKS_LIST, getBanks);
}
