import React from 'react';
import PropTypes from 'prop-types';
import { Text } from './style';

export const Link = (props) => {
  const { href, disabled, children, className, onClick } = props;

  return (
    <Text disabled={disabled} className={className} onClick={onClick} href={href && href}>
      {children}
    </Text>
  );
};

Link.propTypes = {
  href: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.any,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

Link.defaultProps = {
  href: null,
  disabled: false,
  children: PropTypes.any,
  className: '',
  onClick: () => {},
};
