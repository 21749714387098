import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Loader } from 'components/primitives/loader';
import { readAppNotification } from 'store/notifications';
import { setSearch } from 'store/filters';
import { toggleOpen } from 'store/applications';
import { notificationClickTrack } from 'metrika/applications';

import { Notification } from './components/Notification';
import { EmptyState } from './components/EmptyState';

import * as S from './style';

export const ApplicationNotifications = ({ className }) => {
  const rootDispatch = useDispatch();
  const history = useHistory();

  const list = useSelector(({ notifications }) => notifications.apps);
  const isLoading = useSelector(({ notifications }) => notifications.fetch);

  const [sortedList, setSortedList] = useState([]);

  useEffect(() => {
    setSortedList(Object.values(list).sort((a, b) => b.date - a.date));
  }, [list]);

  const clickHandler = (appId) => {
    rootDispatch(readAppNotification(appId));
    rootDispatch(setSearch(appId));
    rootDispatch(toggleOpen(appId));
    notificationClickTrack();
    history.push('/applications/search');
  };

  return (
    <S.Wrapper className={className}>
      <S.Title>Одобрения</S.Title>

      {isLoading ? (
        <Loader color="none" show={true} fixed={false} />
      ) : (
        <S.Container>
          {sortedList.map((data) => (
            <Notification key={data.id} data={data} onClick={clickHandler} />
          ))}

          {sortedList.length === 0 && <EmptyState />}
        </S.Container>
      )}
    </S.Wrapper>
  );
};
