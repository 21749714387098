import React from 'react';
import Contacts from './components/Contacts';
import Passport from './components/Passport';
import Address from './components/Address';
import { DriverLicense } from '../Driver/components/DriversLicense';
import { RoleSwitcher } from './components/OwnerDriverSwitcher';
import { PersonalInfo } from '../PersonalInfo';

import * as Form from '../Form/style';

const fn = () => {};

const Owner = ({
  anketaState,
  multidrive,
  scrollTo,
  onSwitchDriverLicense = fn,
  onSwitchOwnerDriver = fn,
  onSwitchOwnerInsurer = fn,
  onBlur = fn,
  onScroll = fn,
}) => {
  const innerProps = {
    data: anketaState,
    scrollTo,
    errors: anketaState.errors,
    disabled: anketaState.isDisabled,
    onBlur,
    onScroll,
  };

  return (
    <Form.BlockWrapper>
      <Form.Header>Анкета Клиента</Form.Header>

      <RoleSwitcher
        onSwitchDriver={onSwitchOwnerDriver}
        onSwitchInsurer={onSwitchOwnerInsurer}
        checkedDriver={anketaState.isDriver}
        checkedInsurer={anketaState.isInsurer}
        disabled={multidrive || anketaState.isDisabled}
      />

      <PersonalInfo {...innerProps} title="Личная информация" />
      <Contacts {...innerProps} />
      <Passport {...innerProps} />
      <Address {...innerProps} />

      {anketaState.isDriver && (
        <DriverLicense
          driver={anketaState}
          disabled={anketaState.isDisabled}
          errors={anketaState.errors}
          onBlur={onBlur}
          onSwitchCheckbox={onSwitchDriverLicense}
        />
      )}
    </Form.BlockWrapper>
  );
};

export default Owner;
