export default {
  company: 'E-Credit',
  defaultTitle: 'еКредит. Весь F&I в одной платформе',
  primary: '#ca0000',
  secondary: '#474b55',
  favicon: require('./favicon.ico'),
  manifest: '/theme/ecredit/manifest.json',
  logo: '/theme/ecredit/logo256.png',

  APP_DEFAULT_LOCALE: 'ru',
};
