import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as ePointsStore from 'store/ePoints';

import * as S from './styles';

const EPointsPopup = () => {
  const rootDispatch = useDispatch();
  const ePoints = useSelector((store) => store.ePoints);

  useEffect(() => {
    rootDispatch(ePointsStore.loadEPointsPrograms());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <S.PopupStyled hideCloseBtn={true} turnOffBluring={true}>
      <div>Баланс: </div>
      <S.Points>{`${(ePoints && ePoints.total.toLocaleString('ru')) || 0} еБаллов`}</S.Points>
      <div>Заведи заявку и получи еБаллы</div>
      <div>1 заявка = 50 еБаллов = 50₽</div>
    </S.PopupStyled>
  );
};

export default EPointsPopup;
