import moment from 'moment';

import { GET_FILE_URL } from 'constants/url';
import * as localStorage from 'utils/local-storage';
import {
  FILE_TYPE_PHOTO,
  FILE_TYPE_DKP,
  FILE_TYPE_PTS,
  FILE_TYPE_APP_SCAN,
  FILE_TYPE_MULTIAPP_SCAN,
  FILE_TYPE_INVOICE_OSTATOK,
  FILE_TYPE_CHEK_BUY_KASKO_DEALER,
  FILE_TYPE_INVOICE_KASKO,
  FILE_TYPE_INVOICE_SG,
  FILE_TYPE_INVOICE_GAP,
  FILE_TYPE_POLIS_KASKO_DEALER,
  FILE_TYPE_POLIS_PPI_DEALER,
  FILE_TYPE_POLIS_GAP_DEALER,
  FILE_TYPE_INVOICE_CARD,
  FILE_TYPE_INVOICE_EXT_WARRANTY,
  FILE_TYPE_INVOICE_DMS,
  FILE_TYPE_INVOICE_LEGAL_ASSISTANCE,
  FILE_TYPE_INVOICE_TELEMEDICINE,
  FILE_TYPE_INVOICE_SERVICE,
  FILE_TYPE_INVOICE_TO,
  FILE_TYPE_INVOICE_SURETY,
  FILE_TYPE_INVOICE_SSS,
  FILE_TYPE_INVOICE_OTHER,
  FILE_TYPE_PAYMENT_FIRST_PROOF,
} from 'constants/deal';
import { FIELDS as CAR_FIELDS } from 'store/carInstance/constants';

const isState = (state, stateList) => (stateList.indexOf(state?.name) >= 0 ? true : false);

const getFilesByType = (files, type) => {
  if (files) {
    return files
      .filter((f) => f.typeId === type)
      .sort((a, b) => {
        const dateFormat = 'DD.MM hh:mm';
        const m1 = moment(a.createdAt, dateFormat);
        const m2 = moment(b.createdAt, dateFormat);
        return m1.isBefore(m2) ? 1 : -1;
      });
  }

  return null;
};

const getFileUrl = (file) => {
  if (file) {
    return GET_FILE_URL(file.fileId);
  }

  return null;
};

export const prepareApprovalDealData = (deal, credit, services, agreement) => {
  return {
    ...deal,
    credit,
    services: services.map((s) => ({
      ...s,
      ndsFlag: s?.recipient?.ndsFlag,
      type: { name: s?.type?.name || '' },
      recipientId: s?.recipient?.id,
      serviceId: s?.serviceId < 0 ? null : s.serviceId,
    })),
    assigmentAgreement: agreement,
  };
};

export const prepareRecalculationDealData = (calcData, services) => {
  const dealerDops = services.filter((s) => s?.type?.name === 'dealer_dop');
  const othersInApplication = dealerDops.map((dop) => {
    return {
      cnt: 1,
      id: Number(dop.serviceId),
      name: dop.service,
      price: dop.sum,
    };
  });
  const calculationResult = {
    [calcData.selectedProgramId]: calcData?.calculationResult?.[calcData.selectedProgramId],
  };
  return {
    ...calcData,
    calculationParams: {
      ...calcData.calculationParams,
      other_price: calcData?.calculationParams?.other_price || 0,
      others_in_application: othersInApplication,
    },
    calculationResult,
  };
};

export const prepareCarInstanceData = (carInstance = {}) => {
  return {
    ...carInstance,
    glonasNumber: carInstance.glonasNumber || 'Отсутствует',
  };
};

export const prepareDealData = (deal, credit, dealServices, agreement, dealSigningDate) => {
  const services = dealServices.map((s) => {
    if (s?.type?.name === 'optional_equipment') {
      return { ...s, serviceId: null };
    } else {
      return s;
    }
  });
  return {
    ...deal,
    credit,
    services,
    assigmentAgreement: agreement,
    dealSigningDate: moment(dealSigningDate, 'DD.MM.YYYY').format('YYYY-MM-DD'),
  };
};

export const isNew = (state) => isState(state, ['new']);
export const isCalculation = (state) =>
  isState(state, ['final_parameters', 'recalculation_desired']);
export const isRecalculation = (state) =>
  isState(state, [
    'final_parameters',
    'recalculation_desired',
    'awaiting_manual_final_params',
    'hash_check_failed',
  ]);
export const isManualFinalParams = (state) => isState(state, ['awaiting_manual_final_params']);
export const isWaitProgress = (state) =>
  isState(state, [
    'approval_requested',
    'reapproval_requested',
    'approval_request_received',
    'reapproval_request_received',
    'approved',
    'documentation_requested',
  ]);
export const isDocumentsProgress = (state) =>
  isState(state, [
    'request_on_approval',
    'request_on_reapproval',
    'request_on_documents',
    'reload_documents_required',
  ]);
export const isFinalDocumentsProgress = (state) =>
  isState(state, ['documentation_received', 'request_on_finance']);
export const isWaitFinalDocuments = (state) => isState(state, ['finance_requested', 'link_sent']);
export const isPhoneVerification = (state) =>
  isState(state, [
    'phone_verification',
    'phone_verification_requested',
    'phone_verification_received',
  ]);
export const isLinkSent = (state) => isState(state, ['link_sent']);
export const isFinanced = (state) => isState(state, ['financed']);
export const isClosed = (state) => isState(state, ['closed']);
export const isContractSign = (state) => isState(state, ['contract_sign']);
export const isDecline = (state) =>
  isState(state, [
    'customer_decline',
    'bank_decline',
    'reapproval_bank_decline',
    'phone_verification_declined',
  ]);
export const isCustomerDecline = (state) => isState(state, ['customer_decline']);
export const isReloadDocumentsRequired = (state) => isState(state, ['reload_documents_required']);
export const isChangedReapprovalDeal = (deal) =>
  deal?.state?.name === 'request_on_reapproval' && Number(deal?.changeDealCounter || 0) > 1;
export const isEditable = (state) =>
  isState(state, ['final_parameters', 'recalculation_desired', 'awaiting_manual_final_params']);

export const isBackAvailable = (state, deal) => {
  const res = isState(state, [
    'request_on_approval',
    'request_on_reapproval',
    'approved',
    'request_on_documents',
    'documentation_received',
    'request_on_finance',
    'customer_decline',
    'bank_decline',
    'reapproval_bank_decline',
    'phone_verification',
    'phone_verification_requested',
    'phone_verification_received',
    'phone_verification_declined',
    'awaiting_manual_final_params',
    'reload_documents_required',
  ]);

  const isManualFinanced = isState(state, ['financed']) && deal?.isManualDeal;

  return res || isManualFinanced;
};

export const isHashCheckFailed = (state) => {
  return state?.name === 'hash_check_failed';
};

export const isFileExist = (files, id) => !!files.find((f) => f.fileId === id);

export const getFilesPhoto = (files) => getFilesByType(files, FILE_TYPE_PHOTO);
export const getFilesDkp = (files) => getFilesByType(files, FILE_TYPE_DKP);
export const getFilesPts = (files) => getFilesByType(files, FILE_TYPE_PTS);
export const getFilesAppScan = (files) => {
  const retFiles = [
    ...(getFilesByType(files, FILE_TYPE_APP_SCAN) || []),
    ...(getFilesByType(files, FILE_TYPE_MULTIAPP_SCAN) || []),
  ];

  return retFiles.length === 0 ? null : files;
};

export const getPhotoUrl = (file) => getFileUrl(file);
export const getDkpUrl = (file) => getFileUrl(file);
export const getPtsUrl = (file) => getFileUrl(file);

export const canUploadPhoto = (state) =>
  isState(state, ['final_parameters', 'recalculation_desired', 'awaiting_manual_final_params']);
export const isNeedValidateFiles = (state) => isState(state, ['documentation_received']);

export const isViewInfoBlock = (state) =>
  isState(state, [
    'customer_decline',
    'bank_decline',
    'reapproval_bank_decline',
    'phone_verification_declined',
  ]);
export const isViewNewsBlock = (state) => !isViewInfoBlock(state);
export const isViewRecalculationBlock = (state) => isViewNewsBlock(state) && !isNew(state);
export const isViewInProgressBlock = (state) =>
  isViewRecalculationBlock(state) && !isRecalculation(state);
export const isViewDocumentsBlock = (state) => {
  const states = [
    'approval_requested',
    'reapproval_requested',
    'approval_request_received',
    'reapproval_request_received',
    'bank_decline',
    'approved',
    'documentation_requested',
    'documentation_received',
    'finance_requested',
    'request_on_finance',
    'link_sent',
    'financed',
    'customer_decline',
    'closed',
    'reapproval_bank_decline',
    'phone_verification',
    'phone_verification_requested',
    'contract_sign',
    'contract_requested',
    'contract_received',
  ];
  return isState(state, states);
};

export const shouldShowDocumentInfo = (state) =>
  (isViewInProgressBlock(state) &&
    !isWaitProgress(state) &&
    !isDocumentsProgress(state) &&
    !isPhoneVerification(state)) ||
  isClosed(state);

export const getLocalDealData = (worksheetId) => {
  const data = localStorage.getItem('dealsData');
  return data ? data[worksheetId] || {} : {};
};

export const setLocalDealData = (worksheetId, data) => {
  const dealsData = localStorage.getItem('dealsData');
  const dealData = dealsData ? dealsData[worksheetId] || {} : {};
  const newData = { ...dealsData, [worksheetId]: { ...dealData, ...data } };
  localStorage.saveItem('dealsData', newData);
};

export const deleteLocalDealData = (worksheetId) => {
  const dealsData = localStorage.getItem('dealsData');
  if (dealsData) {
    delete dealsData[worksheetId];
    localStorage.saveItem('dealsData', dealsData);
  }
};

export const getEditableCarParams = (state) => {
  const stateList = [
    'request_on_approval',
    'request_on_documents',
    'request_on_finance',
    'request_on_reapproval',
  ];
  if (stateList.includes(state.name)) {
    return [
      'vin',
      'enginePower',
      'bodyNumber',
      'engineNumber',
      'mileage',
      'permittedMaxWeight',
      'engineVolume',
      'glonasNumber',
      'pts.number',
      'pts.issueDate',
    ];
  } else {
    return [];
  }
};

export const getRequiredCarParams = (state) => {
  switch (state?.name) {
    case 'awaiting_manual_final_params':
      return ['vin', 'brand', 'model', 'year', 'price'];
    default:
      return ['vin', 'pts.number', 'pts.issueDate', 'brand', 'model', 'year', 'price', 'mileage'];
  }
};

export const getEditableCreditParams = (state) => {
  const stateList = [
    'request_on_approval',
    'request_on_documents',
    'request_on_finance',
    'request_on_reapproval',
  ];
  if (stateList.includes(state.name)) {
    return [
      'contractDate',
      'contractNumber',
      'recipient',
      'ndsFlag',
      'ndsAmount',
      'invoiceNumber',
      'invoiceDate',
    ];
  } else {
    return [];
  }
};

export const getRequiredCreditParams = (state) => {
  switch (state?.name) {
    case 'awaiting_manual_final_params':
      return [];
    default:
      return ['contractNumber', 'contractDate', 'recipient', 'ndsFlag'];
  }
};

export const getEditableServiceParams = (state) => {
  const stateList = [
    'request_on_approval',
    'request_on_documents',
    'request_on_finance',
    'request_on_reapproval',
  ];
  if (stateList.includes(state.name)) {
    return [
      'polisNumber',
      'recipient',
      'term',
      'ndsFlag',
      'ndsAmount',
      'expiration',
      'invoiceNumber',
      'invoiceDate',
    ];
  } else {
    return [];
  }
};

export const getRequiredServiceParams = (state) => {
  switch (state?.name) {
    case 'awaiting_manual_final_params':
      return [];
    default:
      return ['term', 'polisNumber', 'recipientId', 'ndsFlag', ''];
  }
};

export const getRecipientList = (deal) =>
  (deal?.credit?.recipients || [])
    .filter((i) => ['DEALER', 'INSUR'].includes(i.code))
    .map((r) => ({
      id: r.id,
      value: `${r.opfShort} “${r.nameShort}“`,
      description: `Р\\С ${r.accountNumber}`,
      bankName: String(r.bankName || '').trim(),
      bic: String(r.bic || '').trim(),
      acc: String(r.accountNumber || '').trim(),
      inn: String(r.legalInn || '').trim(),
      code: r.code,
      carfinId: r.carfinId || null,
      ndsFlag: r.ndsFlag,
      ndsRate: r.ndsRate,
    }));

export const getBankProductList = (bankData = {}) => {
  const services = [];
  const serviceTypes = ['kasko_insurance', 'gap_insurance', 'life_insurance'];
  const mappedTypes = {
    kasko_insurance: 'insurance_kasko',
    gap_insurance: 'insurance_gap',
    life_insurance: 'insurance_ppi',
  };

  serviceTypes.forEach((type) => {
    const data = Object.keys(bankData)
      .filter((key) => {
        return key.startsWith(type);
      })
      .reduce((acc, cur) => {
        acc[cur] = bankData[cur];
        return acc;
      }, {});

    const progId = data[`${type}_prog_id`];
    if (progId) {
      services.push({
        type: mappedTypes[type],
        term: data[`${type}_term`],
        serviceId: progId,
        polisNumber: (bankData?.policyData?.[type] || {})?.policyNumber,
        expiration: (bankData?.policyData?.[type] || {})?.expiration,
      });
    }
  });

  const dops = Object.entries(bankData?.bank_dop || []);
  dops.forEach(([id, term]) => {
    services.push({
      type: 'bank_dop',
      term,
      serviceId: id,
      polisNumber: (bankData?.policyData?.bank_dop?.[id] || {})?.policyNumber,
      expiration: (bankData?.policyData?.bank_dop?.[id] || {})?.expiration,
    });
  });

  return services;
};

export const getDefaultDealDate = () => {
  return moment(new Date().toISOString()).format('DD.MM.YYYY');
};

export const checkRequiredKasko = (program) => {
  if (!program) {
    return false;
  }
  const isKaskoRequired = program?.calculation?.kasko_required === 1;
  const hasKasko = Boolean(program?.calculation?.kasko_id && program?.calculation?.kasko_price);
  if (isKaskoRequired) {
    return hasKasko;
  }

  return true;
};

export const checkSubsidy = (program) => {
  if (program?.discount_car_gos_name || program?.calculation.discount_car_gos_val) {
    return Boolean(program?.discount_car_gos_name && program?.calculation.discount_car_gos_val);
  }
  return true;
};

export const shouldShowCarFiles = (state) => {
  return isState(state, [
    'awaiting_manual_final_params',
    'approval_requested',
    'reapproval_requested',
    'approval_request_received',
    'reapproval_request_received',
    'reapproval_bank_decline',
    'request_on_approval',
    'request_on_reapproval',
    'approved',
    'request_on_documents',
    'documentation_requested',
    'documentation_received',
    'finance_requested',
    'request_on_finance',
    'link_sent',
    'financed',
    'customer_decline',
    'closed',
    'phone_verification',
    'phone_verification_requested',
    'phone_verification_received',
  ]);
};

export const getCarInstanceHiddenFields = (deal) => {
  return deal.isManualDeal
    ? [
        CAR_FIELDS.pts.number,
        CAR_FIELDS.pts.issueDate,
        CAR_FIELDS.enginePower,
        CAR_FIELDS.engineVolume,
        CAR_FIELDS.engineNumber,
        CAR_FIELDS.permittedMaxWeight,
        CAR_FIELDS.bodyNumber,
        CAR_FIELDS.contract.number,
        CAR_FIELDS.ndsFlag,
        CAR_FIELDS.ndsAmount,
        CAR_FIELDS.bankName,
      ]
    : [CAR_FIELDS.contract.date, CAR_FIELDS.bankName];
};

export const getProductHiddenFields = (deal) => {
  return deal.isManualDeal
    ? [
        'count',
        'polisNumber',
        'recipient',
        'invoiceNumber',
        'invoiceDate',
        'ndsFlag',
        'ndsAmount',
        'bic',
        'accountNumber',
        'inn',
        'polisNumber',
        'halvaPolicyNumber',
        'halvaExpireDate',
      ]
    : ['count'];
};

export const getSaveDealErrors = (data, calcData) => {
  const errors = [];
  if (!data.carInstance.offerPrice) {
    errors.push('Укажите цену авто');
  }
  if (!data.carInstance.year) {
    errors.push('Выберите год авто');
  }
  if (!data.carInstance.brand) {
    errors.push('Выберите марку авто');
  }
  if (!data.carInstance.model) {
    errors.push('Выберите модель авто');
  }
  if (!calcData?.calculationParams?.period || calcData?.calculationParams?.period === -1) {
    errors.push('Выберите срок кредита');
  }
  if (Object.keys(calcData?.calculationResult || {})?.length === 0) {
    errors.push('Нет программ подходящих под условия. Измените параметры сделки');
  }

  return errors;
};

export const shouldUseTimer = (state) => {
  const states = [
    'approval_requested',
    'reapproval_requested',
    'approval_request_received',
    'reapproval_request_received',
    'documentation_requested',
  ];

  return states.includes(state?.name);
};

export const getTimer = (state, timers) => {
  let timerType;
  switch (state?.name) {
    case 'approval_requested':
    case 'reapproval_requested':
    case 'approval_request_received':
    case 'reapproval_request_received':
      timerType = 'awaiting_approval';
      break;
    case 'documentation_requested':
      timerType = 'awaiting_documents';
      break;
    default:
      timerType = null;
  }

  const timer = timers.find((t) => t.type === timerType);
  return timer?.timeLeft && timer?.timeLeft > 0 ? timer?.timeLeft : 0;
};

export const getContractSignUploadDocTypes = () => {
  return [
    FILE_TYPE_PHOTO,
    FILE_TYPE_DKP,
    FILE_TYPE_PTS,
    FILE_TYPE_INVOICE_OSTATOK,
    FILE_TYPE_CHEK_BUY_KASKO_DEALER,
    FILE_TYPE_INVOICE_KASKO,
    FILE_TYPE_INVOICE_SG,
    FILE_TYPE_INVOICE_GAP,
    FILE_TYPE_POLIS_KASKO_DEALER,
    FILE_TYPE_POLIS_PPI_DEALER,
    FILE_TYPE_POLIS_GAP_DEALER,
    FILE_TYPE_INVOICE_CARD,
    FILE_TYPE_INVOICE_EXT_WARRANTY,
    FILE_TYPE_INVOICE_DMS,
    FILE_TYPE_INVOICE_LEGAL_ASSISTANCE,
    FILE_TYPE_INVOICE_TELEMEDICINE,
    FILE_TYPE_INVOICE_SERVICE,
    FILE_TYPE_INVOICE_TO,
    FILE_TYPE_INVOICE_SURETY,
    FILE_TYPE_INVOICE_SSS,
    FILE_TYPE_INVOICE_OTHER,
    FILE_TYPE_PAYMENT_FIRST_PROOF,
  ];
};

export const getNdsAmount = (sum = 0, rate = 0) => {
  const amount = (sum * rate) / (100 + rate);
  return Math.round(amount * 100) / 100;
};
