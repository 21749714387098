import React, { useEffect, useState } from 'react';
import equal from 'deep-equal';
import { useSelector } from 'react-redux';
import { InputMask } from 'utils/inputMask';
import { Alert } from 'components/primitives/alert';

import {
  ChangeFioToogle,
  ChildrenCount,
  FormGridRow,
  MaritalStatus,
  FioUser,
  PrevFioUser,
  CellPhone,
  Education,
} from './style';

const fioChangeReasonMarried = {
  id: 3981,
  sysName: 'change-reason-marriage',
  value: 'В связи с регистрацией брака',
};

export const PersonalForm = ({
  onChange,
  persona,
  validate,
  errors,
  anketaSave,
  setFocusAt,
  confidenceErros,
  clearConfidenceErros,
  contentHidden = false,
  disabled = false,
}) => {
  const keyComp = 'personalInfo';
  const keyCompContacts = 'contacts';
  const keyCompWork = 'work';
  const [values, setValues] = useState(null);
  const [valuesWork, setValuesWork] = useState(null);
  const [valuesContacts, setValuesContacts] = useState(null);
  const [showAlert, setShowAlert] = useState(null);
  const [middleAlert, setMiddleAlert] = useState({ middleName: true, prevMiddleName: true });
  const [error, setError] = useState({});
  const { martialStatusesList, educationList } = useSelector((state) => state.referenceBooks);
  const { fiasId, regDate } = persona.addressReg;
  const alertConfig = {
    ...['middleName', 'prevMiddleName'].reduce(
      (obj, key) => ({
        ...obj,
        [key]: {
          key,
          title: 'Заполните ФИО чётко в соответствии с паспортом',
          desc: 'Если у вас отсутствует отчество нажмите ОК',
          agreeText: 'Ок',
          cancelText: 'Отмена',
          onAgree: () => agreeMiddleName(),
          onCancel: () => cancelMiddleName(),
        },
      }),
      {}
    ),
    fio: {
      key: 'fio',
      title: (
        <>
          Ого! Кажется, мы по-другому распознали документы.
          <br />
          <br />
          Проверьте, пожалуйста
        </>
      ),
      desc: null,
      agreeText: 'Ок',
      cancelText: null,
      dataTest: 'okButton',
      onAgree: () => agreeFio(),
    },
  };

  const changeHandler = (val) => {
    let stateVal = { ...values };
    if (Array.isArray(val)) {
      val.forEach((value) => {
        stateVal = { ...value, ...stateVal };
      });
    } else {
      stateVal = { ...values, ...val };
    }

    if (val.familyState) {
      if (val.familyState.id !== 77) {
        stateVal.yearsMarried = 0;
      } else {
        stateVal.yearsMarried = 1;
      }
    }

    if (val.isFioChanged) {
      stateVal.changeFioReason = fioChangeReasonMarried;
    } else {
      stateVal.changeFioReason = {};
    }

    setValues(stateVal);
    setMiddleAlert(Object.keys(middleAlert).reduce((obj, key) => ({ ...obj, [key]: true }), {}));
    onChange({ values: stateVal, id: persona.id, key: keyComp });
  };

  const changeHandlerContacts = (val) => {
    let stateVal = { ...valuesContacts };
    if (Array.isArray(val)) {
      val.forEach((value) => {
        stateVal = { ...value, ...stateVal };
      });
    } else {
      stateVal = { ...valuesContacts, ...val };
    }
    setValuesContacts(stateVal);
    onChange({ values: stateVal, id: persona.id, key: keyCompContacts });
  };

  const changeHandlerWork = (val) => {
    let stateVal = { ...valuesWork };
    if (Array.isArray(val)) {
      val.forEach((value) => {
        stateVal = { ...value, ...stateVal };
      });
    } else {
      stateVal = { ...valuesWork, ...val };
    }
    setValuesWork(stateVal);
    onChange({ values: stateVal, id: persona.id, key: keyCompWork });
  };

  const validation = (path, withSave = true) => {
    setError((prev) => ({ ...prev, [path]: errors[path] || null }));
    clearConfidenceErros(path);
    if (withSave) anketaSave();
  };

  const startExtendedValidation = (type, key) => {
    values[key] === '' && middleAlert[key] && !values.noMiddle && setShowAlert(alertConfig[key]);

    validation(type);
  };

  const getError = (path) => {
    if (validate) {
      return errors[path] || error[path];
    }
    return error[path];
  };

  const agreeMiddleName = () => {
    const key =
      showAlert && showAlert.key && showAlert.key.indexOf('prev') === -1
        ? ['middleName', 'fullName']
        : ['prevMiddleName', 'prevFullName'];
    const noMiddle = '';
    if (!key) {
      return;
    }
    const fullName = `${values[key[1]]} ${noMiddle}`;

    changeHandler({ [key[0]]: noMiddle, [key[1]]: fullName.replace(/ {2}/g, ' '), noMiddle: true });
    setShowAlert(null);
  };

  const agreeFio = () => {
    const element = document.querySelector(`#${keyComp}_fullName`);
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
    setShowAlert(null);
  };

  const cancelMiddleName = () => {
    if (showAlert) {
      setMiddleAlert({ ...middleAlert, [showAlert.key]: false });
      setShowAlert(null);
    }
  };

  useEffect(() => {
    if (persona && !equal(values, persona[keyComp])) {
      // обработка распознования ФИО если не одинаковы данные
      const { lastName, firstName, middleName } = persona[keyComp];
      if (values) {
        if (
          values.lastName.toLowerCase() !== lastName.toLowerCase() ||
          values.firstName.toLowerCase() !== firstName.toLowerCase() ||
          (values.middleName.toLowerCase() !== middleName.toLowerCase() && values.noMiddle)
        ) {
          setShowAlert(alertConfig.fio);
        }
      } else {
        if (!persona.applicationParams.lastModifiedAt) {
          setMiddleAlert(
            Object.keys(middleAlert).reduce((obj, key) => ({ ...obj, [key]: false }), {})
          );
        }
      }
      setValues(persona[keyComp]);
    }
    if (persona && valuesContacts !== persona[keyCompContacts]) {
      setValuesContacts(persona[keyCompContacts]);
    }
    if (persona && valuesWork !== persona[keyCompWork]) {
      setValuesWork(persona[keyCompWork]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [persona]);

  useEffect(() => {
    setError((prev) => ({ ...prev, ...confidenceErros }));
  }, [confidenceErros]);

  useEffect(() => {
    if (!validate) {
      setError(
        Object.keys(error).reduce(
          (obj, key) => ({ ...obj, [key]: errors[key] || confidenceErros[key] || null }),
          {}
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, validate]);

  useEffect(() => {
    if ((fiasId || regDate) && values?.familyState.id === 0) {
      validation(`${keyComp}.familyState.sysName`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fiasId, regDate]);

  return (
    <>
      {values && (
        <FormGridRow>
          <FioUser
            changeHandler={(obj) => {
              obj.fullName = obj.fullName.toUpperCase();
              obj.firstName = obj.firstName.toUpperCase();
              obj.lastName = obj.lastName.toUpperCase();
              obj.middleName = obj.middleName.toUpperCase();
              changeHandler(obj);
            }}
            id={`${keyComp}_fullName`}
            error={getError(`${keyComp}.fullName`)}
            tabIndex={'-1'}
            valueGender={values.gender}
            required
            label={'Фамилия*, имя*, отчество (необязательно)'}
            defaultValue={values.fullName}
            onBlur={() => {
              startExtendedValidation(`${keyComp}.fullName`, 'middleName');
            }}
            dataTest="fio"
          />
          <CellPhone
            id={`${keyCompContacts}_mobilePhone`}
            tabIndex="1"
            required
            name="mobilePhone"
            label="Мобильный телефон"
            defaultValue={
              contentHidden ? InputMask.CELLPHONE_PRIVATE_NEW[0].hidden : valuesContacts.mobilePhone
            }
            val={
              contentHidden ? InputMask.CELLPHONE_PRIVATE_NEW[0].hidden : valuesContacts.mobilePhone
            }
            mask={
              contentHidden
                ? InputMask.CELLPHONE_PRIVATE_NEW[0].hidden
                : InputMask.CELLPHONE_PRIVATE_NEW
            }
            onChange={({ mobilePhone }) =>
              mobilePhone === '+7 (___) ___-__-__'
                ? changeHandlerContacts({ mobilePhone: '' })
                : changeHandlerContacts({ mobilePhone })
            }
            onBlur={() => validation(`${keyCompContacts}.mobilePhone`)}
            error={getError(`${keyCompContacts}.mobilePhone`)}
            disabled={disabled || contentHidden}
          />
          <ChangeFioToogle
            name="isFioChanged"
            label="Ранее ФИО было изменено"
            onClick={(obj) => {
              changeHandler(obj);
              if (obj.isFioChanged) {
                setFocusAt(`${keyComp}_prevFullName_input`);
              }
            }}
            checked={values.isFioChanged}
            error={getError(`${keyComp}.fullName`)}
            disabled={disabled}
          />
          {values.isFioChanged && (
            <>
              <PrevFioUser
                changeHandler={changeHandler}
                structure={['prevFullName', 'prevLastName', 'prevFirstName', 'prevMiddleName']}
                id={`${keyComp}_prevFullName`}
                error={getError(`${keyComp}.prevFullName`)}
                tabIndex={'2'}
                valueGender={values.gender}
                required
                label={'Фамилия*, имя*, отчество (необязательно)'}
                defaultValue={values.prevFullName}
                onBlur={() => {
                  startExtendedValidation(`${keyComp}.prevFullName`, 'prevMiddleName');
                }}
                disabled={disabled}
              />
            </>
          )}
          <MaritalStatus
            id={`${keyComp}_familyState_sysName`}
            tabIndex="5"
            required
            name="familyState"
            onlyid={false}
            label="Семейное положение"
            defaultValue={values.familyState}
            // val={values.familyState}
            list={martialStatusesList && martialStatusesList.filter((item) => item.id !== 81)}
            onChange={({ familyState }) => {
              changeHandler({ familyState });
            }}
            onBlur={() => {
              validation(`${keyComp}.familyState.sysName`, false);
              validation(`${keyComp}.spouse`, false);
              validation(`${keyComp}.trustees`);
            }}
            error={getError(`${keyComp}.familyState.sysName`)}
            disabled={disabled}
          />
          <ChildrenCount
            id={`${keyComp}_childrenCount`}
            tabIndex="6"
            required
            name="childrenCount"
            label="Кол-во детей младше 21"
            defaultValue={values.childrenCount}
            val={values.childrenCount}
            mask={InputMask.INTEGER}
            onChange={(obj) => {
              const childrenCount = obj.childrenCount ?? 0;
              changeHandler({
                ...obj,
                childrenCount,
                dependentCount: childrenCount,
              });
            }}
            onBlur={() => validation(`${keyComp}.childrenCount`)}
            error={getError(`${keyComp}.childrenCount`)}
            disabled={disabled}
          />
          <Education
            id={`${keyCompWork}_educationType_sysName`}
            tabIndex="63"
            required
            name="educationType"
            onlyid={false}
            label="Образование"
            defaultValue={valuesWork.educationType}
            list={educationList}
            onChange={(val) => changeHandlerWork(val)}
            onBlur={() => validation(`${keyCompWork}.educationType.sysName`)}
            error={getError(`${keyCompWork}.educationType.sysName`)}
            disabled={disabled}
          />
        </FormGridRow>
      )}
      {showAlert && (
        <Alert
          title={showAlert.title}
          desc={showAlert.desc}
          agreeText={showAlert.agreeText}
          cancelText={showAlert.cancelText}
          agreeDataTest={showAlert.dataTest}
          onAgree={showAlert.onAgree}
          onCancel={showAlert.onCancel}
        />
      )}
    </>
  );
};
