import styled, { css } from 'styled-components';
import { InputMaskDaData } from 'components/primitives/input-mask-dadata-new';
import { InputMaskCustomComponent } from '../InputMaskCustomComponent';

// здесь собираем стилизации для примитивов инпуты, свитчи, чекбоксы и пр
export const InputMaskStyled = styled(InputMaskCustomComponent)`
  border-radius: 5px;
  ${(props) =>
    props.disabled &&
    css`
      border: none;
    `}
`;

export const InputMaskDaDataStyled = styled(InputMaskDaData)`
  border-radius: 5px;
  height: 56px;
`;

// блоки
// блок формы (рамка)
export const BlockWrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid #7ebfed;
  padding: 30px 0;
  margin-bottom: 30px;
`;

// заголовок блока
export const Header = styled.h1`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #000000;
  margin-bottom: 30px;
`;

export const SubBlockWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const SubHeader = styled.h2`
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 110%;
  margin-bottom: 15px;
  color: #000000;
`;
