import { ASW_STATES } from 'store/assistanceCalculation/resources';

export const isNeedDisableASWFieldsByOrders = (ordersList) => {
  // функция за один проход по orders определяет необходимость
  // блокировки/разблокировки полей каринстанса и анкеты в ASW
  if (!ordersList.length) {
    return false;
  }

  let offersAppsQty = 0;

  for (const order of ordersList) {
    const applicationState = order.body.applicationState.sysName;

    if (ASW_STATES.enabledCarInstanceAnketa.has(applicationState)) {
      offersAppsQty++;
      continue;
    }

    if (applicationState === ASW_STATES.client_decline) {
      continue;
    }

    return true;
  }

  return !offersAppsQty;
};

export const createProductData = (...ids) => {
  const products = ['assistance', 'insurance', 'credit'];
  const targetIdIndex = ids.findIndex((el) => !!el);

  return {
    applicationId: ids[targetIdIndex],
    product: products[targetIdIndex],
  };
};
