export const LINKS = [
  {
    keys: ['documents'],
    href: 'upload',
    title: 'Загрузка документов',
    count: 0,
  },
  {
    keys: [
      'trustees',
      'spouse',
      'addressReg',
      'addressLive',
      'livingAddressProperty',
      'contacts',
      'oldPassport',
      'passport',
      'personalInfo',
      'secondDocument.currentSecondDocumentType',
      'secondDocument.driverLicense',
      'secondDocument.internationalPassport',
      'secondDocument.militaryID',
      'secondDocument.taxRegistration',
      'secondDocument.snils',
    ],
    href: 'personalInfo',
    title: 'Личная информация',
    count: 0,
  },
  {
    keys: ['sign'],
    href: 'sign',
    title: 'Согласие клиента',
    count: 0,
  },
  {
    keys: ['work', 'profit'],
    href: 'work',
    title: 'Работа и Доход',
    count: 0,
  },
];
