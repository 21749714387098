import React from 'react';
import { useSelector } from 'react-redux';
import { ReactTooltipStyled } from 'components/modules/MainAnketa/style';
import { handleCommentary } from 'utils/handleCommentary';
import { splitString } from 'utils/common';
import { PALETTE } from 'styles/constants';
import {
  DealItemApprovedContainer,
  CommentBankIco,
  DealItemStatusContainer,
  DetailCell,
  BankLogoIcon,
  DifferenceIco,
  SentBankIco,
  SentBankOkIco,
  TextWraper,
} from './style';
import * as helpers from '../../helpers';
import ProductInfo from './ProductInfo';
import { IGNORED_BANKS } from './constants';

export default function DealItem({ deal, carInstance, applicationId, color }) {
  const itemParams = useSelector((store) => store?.applications?.detailParams[applicationId]);

  const { comment: statusComment, bank_msg } = deal?.AdditionalData?.statusData;
  const handleStatusСomment = handleCommentary(statusComment).replaceAll('<br>', ' ');

  const bankId = Number(deal.bank.id);
  const state = deal.state.state;
  const apprData = deal?.AdditionalData?.approvData?.data[0] || {};
  const isShowYear = helpers.isShowCarYear(carInstance, deal);

  const {
    comment: aprovComment,
    credit_sum,
    down_payment,
    term,
    monthly,
    max_limit,
    rate,
    bank_dop_sum,
    dealer_dop_sum,
    dealer_details = {},
    bank_details = {},
  } = apprData;

  const handleApproveComment = aprovComment
    ? handleCommentary(aprovComment).replaceAll('<br>', ' ')
    : '';

  const { boldPrice, boldInitialMoney, boldPeriod, boldRate, boldDealerSum } = helpers.compareObj(
    itemParams,
    apprData
  );

  return (
    <>
      {!helpers.checkApproveObj(apprData) ? (
        <DealItemStatusContainer color={color}>
          <DetailCell>
            <BankLogoIcon type="cps" id={bankId} />
            {deal.bank.name || '—'}
          </DetailCell>
          {state === 'sent-to-the-bank' || state === 'queue' ? (
            bank_msg ? (
              <DetailCell>
                <SentBankOkIco />
                <TextWraper>
                  {handleCommentary(bank_msg).replaceAll('<br>', ' ') || helpers.checkStatus(state)}
                </TextWraper>
              </DetailCell>
            ) : (
              <DetailCell>
                <SentBankIco /> <TextWraper>{helpers.checkStatus(state)}</TextWraper>
              </DetailCell>
            )
          ) : (
            <DetailCell>
              {state === 'new'
                ? helpers.checkStatus(state)
                : handleApproveComment || handleStatusСomment || helpers.checkStatus(state)}
            </DetailCell>
          )}
        </DealItemStatusContainer>
      ) : (
        <DealItemApprovedContainer color={color} hasCarYear={isShowYear}>
          <DetailCell>
            <BankLogoIcon type="cps" id={bankId} /> {deal?.bank.name || '—'}
          </DetailCell>

          <DetailCell boldPrice={boldPrice}>
            {helpers.convertValueToStandartNumber(credit_sum)} {boldPrice && <DifferenceIco />}
          </DetailCell>

          <DetailCell boldInitialMoney={boldInitialMoney}>
            {helpers.convertValueToStandartNumber(down_payment)}{' '}
            {boldInitialMoney && <DifferenceIco />}
          </DetailCell>

          <DetailCell boldPeriod={boldPeriod}>
            {term || '—'} {boldPeriod && <DifferenceIco />}
          </DetailCell>

          <DetailCell boldRate={boldRate}>
            {rate ? `${rate}%` : '—'} {boldRate && <DifferenceIco />}
          </DetailCell>

          <DetailCell>{helpers.convertValueToStandartNumber(monthly)}</DetailCell>

          <DetailCell boldDealerSum={boldDealerSum}>
            {helpers.convertValueToStandartNumber(dealer_dop_sum)}
            {boldDealerSum && Object.keys(dealer_details).length <= 1 && <DifferenceIco />}
            {Object.keys(dealer_details).length > 1 && (
              <ProductInfo id={`${deal.id}_dealerDetail`} productList={dealer_details} />
            )}
          </DetailCell>

          <DetailCell>
            {helpers.convertValueToStandartNumber(bank_dop_sum)}
            {Object.keys(bank_details).length > 1 && (
              <ProductInfo
                id={`${deal.id}_bankDetail`}
                productList={bank_details}
                bankProduct={true}
              />
            )}
          </DetailCell>

          <DetailCell>{helpers.convertValueToStandartNumber(max_limit)}</DetailCell>

          {isShowYear && (
            <DetailCell boldDealerSum={deal.carYearMax > 0}>{deal.carYearMax || '—'}</DetailCell>
          )}

          {aprovComment && !IGNORED_BANKS.includes(bankId) && (
            <DetailCell>
              <CommentBankIco data-tip="" data-for={`${deal.id}_comment`} />
              <ReactTooltipStyled id={`${deal.id}_comment`} place="left" arrowColor={PALETTE.grey1}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: splitString(handleCommentary(aprovComment), 70),
                  }}
                />
              </ReactTooltipStyled>
            </DetailCell>
          )}
        </DealItemApprovedContainer>
      )}
    </>
  );
}
