import React from 'react';
import plural from 'plural-ru';
import cloneDeep from 'lodash/cloneDeep';
import PolicyWidget from 'components/primitives/policy';
import { declineData, errorData, newData, clearState } from './constants';
import { ASSISTANCE_WORKFLOW_STATES } from 'constants/assistance';
import { sortOffersByPriority } from './helper';
import * as S from './style';

const fn = () => {};

const AssistanceWidget = ({ state, title = '', isRedButton = false, offers, onClick = fn }) => {
  const offersToShow = 2;
  const sortedOffers = cloneDeep(offers ?? []).sort(sortOffersByPriority);
  const renderOffers = sortedOffers.slice(0, offersToShow);
  const moreOffers = sortedOffers.length > offersToShow ? sortedOffers.length - offersToShow : 0;

  const assistanceStatuses = {
    [ASSISTANCE_WORKFLOW_STATES.new]: newData,
    [ASSISTANCE_WORKFLOW_STATES.offers_requested]: {
      step: 1,
      content: (
        <>
          <div>
            {renderOffers.map((offer) => (
              <S.Offer key={offer.id}>
                <b>{offer.title || offer.companyName || offer.insuranceCompanyName}</b>
              </S.Offer>
            ))}
            {moreOffers ? (
              <S.Offer>
                {plural(moreOffers, '+%d предложение', '+%d предложения', '+%d предложений')}
              </S.Offer>
            ) : null}
          </div>
          <S.Button onClick={onClick}>Показать предложения</S.Button>
        </>
      ),
      loading: false,
    },
    [ASSISTANCE_WORKFLOW_STATES.offers_received]: {
      step: 1,
      content: (
        <>
          {!!title && <S.CompanyName>{title}</S.CompanyName>}
          <S.Button isRedButton={isRedButton} onClick={onClick}>
            Показать предложения
          </S.Button>
        </>
      ),
      loading: false,
    },
    [ASSISTANCE_WORKFLOW_STATES.offer_selected]: {
      step: 2,
      content: (
        <>
          {!!title && <S.CompanyName>{title}</S.CompanyName>}
          <S.Button isRedButton={isRedButton} onClick={onClick}>
            Выпустить
          </S.Button>
        </>
      ),
      loading: false,
    },
    [ASSISTANCE_WORKFLOW_STATES.agreement_requested]: {
      step: 2,
      content: null,
      loading: true,
    },
    [ASSISTANCE_WORKFLOW_STATES.agreement_succeed]: {
      step: 3,
      content: (
        <>
          {!!title && <S.CompanyName>{title}</S.CompanyName>}
          <S.Button isRedButton={isRedButton} onClick={onClick}>
            Выпустить
          </S.Button>
        </>
      ),
      loading: false,
    },
    [ASSISTANCE_WORKFLOW_STATES.issue_requested]: {
      step: 3,
      content: null,
      loading: true,
    },
    [ASSISTANCE_WORKFLOW_STATES.issued]: {
      step: 4,
      content: <>{!!title && <S.CompanyName>{title}</S.CompanyName>}</>,
      loading: false,
    },
    [ASSISTANCE_WORKFLOW_STATES.offers_declined]: declineData,
    [ASSISTANCE_WORKFLOW_STATES.declined]: declineData,
    [ASSISTANCE_WORKFLOW_STATES.agreement_declined]: declineData,
    [ASSISTANCE_WORKFLOW_STATES.issue_decline]: declineData,
    [ASSISTANCE_WORKFLOW_STATES.client_decline]: declineData,
    [ASSISTANCE_WORKFLOW_STATES.offers_error]: errorData,
    [ASSISTANCE_WORKFLOW_STATES.error]: errorData,
    [ASSISTANCE_WORKFLOW_STATES.agreement_error]: errorData,
    [ASSISTANCE_WORKFLOW_STATES.issue_error]: errorData,
  };

  const assistanceStatusData = assistanceStatuses[state.sysName] || newData;
  // The status can't be show if the state of policy is new
  const assistanceState = state?.sysName !== ASSISTANCE_WORKFLOW_STATES.new ? state : clearState;
  if (!assistanceState.name) {
    assistanceState.name = assistanceState.sysName;
  }

  return (
    <PolicyWidget
      title={'Карты'}
      state={assistanceState}
      step={assistanceStatusData.step}
      loading={assistanceStatusData.loading}
      content={assistanceStatusData.content}
    />
  );
};

export default AssistanceWidget;
